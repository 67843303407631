import ErrorText from '../../../../components/errortext';
import { JobDetails } from '../../../../components/JobEdit/JobDetails';
import { Label, Container } from './TabsShareComponents';
import { DetailsTabProps } from '../../types/types';
import { Job } from 'types/types';
import Editor from 'components/Editor/index.jsx';
import auth from 'utils/auth';
import JobSectionSelection from 'components/JobEdit/jobsectionselection';
import { ChangeEvent } from 'react';

type VisibiltyRequiredType = 'required' | 'visible' | 'required_internal' | 'visible_internal';

export default function DetailsTab({
  job,
  setJob,
  errors,
  updateField,
  handleTextareaChange,
}: DetailsTabProps): React.ReactElement {
  const resetDate = (field: string) => {
    const jobCopy = { ...job };
    if (field === 'deadline') {
      jobCopy['deadline'] = null;
    } else {
      jobCopy['start_date'] = null;
    }
    setJob(jobCopy);
  };

  const updateJobDate = (field: string, date: string) => {
    const jobCopy = { ...job };
    if (new Date(date) || date === '') {
      if (field === 'deadline') {
        jobCopy['deadline'] = new Date(date).toISOString();
      } else {
        jobCopy['start_date'] = new Date(date).toISOString();
      }
      setJob(jobCopy);
    } else {
      return;
    }
  };

  const updateJob = (jobCopy: Job) => {
    setJob(jobCopy);
  };

  const updateJobSectionSettings = (
    event: ChangeEvent<HTMLInputElement>,
    sectionValue: number,
    setting: VisibiltyRequiredType
  ) => {
    const value = event.target.checked;

    setJob(previousState => {
      const updateSection = previousState['role_sections'].find(r => r.section === sectionValue);

      if (!updateSection) return previousState;

      const updateSectionCopy = { ...updateSection };

      updateSectionCopy[setting] = value;
      // If required is on, visible must also be on
      if (value && setting.indexOf('required') !== -1) {
        if (setting === 'required_internal') {
          updateSectionCopy['visible_internal'] = value;
        } else {
          updateSectionCopy['visible'] = value;
        }
      }
      // If visible is turned off, required must also be off
      if (!value && setting.indexOf('visible') !== -1) {
        if (setting === 'visible_internal') {
          updateSectionCopy['required_internal'] = value;
        } else {
          updateSectionCopy['required'] = value;
        }
      }

      return {
        ...previousState,
        role_sections: previousState.role_sections.map(section => {
          if (section.section !== sectionValue) {
            return section;
          } else {
            return updateSectionCopy;
          }
        }),
      };
    });
  };

  return (
    <Container>
      <JobDetails
        job={job}
        isDistrictAdmin={true} // all users are district admin
        updateJob={(j: Job) => updateJob(j)}
        updateField={updateField}
        updateJobDate={updateJobDate}
        resetDate={resetDate}
        errors={errors}
        isTemplate={job.is_template}
      />
      <div>
        {/* Role Templates don't require a description */}
        <Label>Job Description{!job.is_template && '*'}</Label>
        <div className="form-field jobedit-textarea">
          <Editor
            onChange={value => handleTextareaChange(value, 'description')}
            placeholder={'What will they be doing?'}
            value={job.description || ''}
          />
        </div>
        {!job.is_template && errors.job_description && (
          <ErrorText className="mt1" message={'Please add a job description.'} />
        )}
        <Label>Benefits</Label>
        <div className="form-field jobedit-textarea mb4">
          <Editor
            onChange={value => handleTextareaChange(value, 'benefits')}
            placeholder={'Describe the benefits you offer.'}
            value={job.benefits || ''}
          />
        </div>
        {!auth.inPostingOnlyDistrict() && (
          <div className="job-section-component-outside mb4">
            <JobSectionSelection
              job={job}
              updateJobSectionSettings={updateJobSectionSettings}
              updateField={updateField}
            />
          </div>
        )}
      </div>
    </Container>
  );
}
