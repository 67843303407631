import { Component } from 'react';
import axios from 'axios';
import _ from 'lodash';

import JobsList from '../components/JobsList';
import auth from '../utils/auth';
import { showTotalFailure } from '../utils/message';
import { credentialSubjects, grades } from '../utils/enums';
import districtAPI from 'api/districtAPI';
import { withRouter } from 'react-router-dom';

class RequisitionRolesListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '...', // Displayed to user
      roles: [],
      allRoles: [],
      query: null,
      subjects: [],
      grades: [],
      sortBy: '',
      descending: true,
      isSchoolUser: auth.isSchoolUser(),
      showSpinner: false,
      isRequisition: true,
      categories: [],
      // Used to do immediate (non-debounced) refresh if user clicks outside of the selection area
      awaitingRefresh: false,
    };
    this.user = auth.getUser();
    this.districtId = this.user.profile.district.id;
    const { search } = this.props.location;
    const query = new URLSearchParams(search);
    this.query = query;
  }

  componentDidMount() {
    this.fetchCategories(this.districtId);
    districtAPI.getDistrict(this.districtId).then(district => this.setState({ district }));
    document.body.classList.add('requisition-roles-page');
  }

  controlledRefresh = _.debounce(() => {
    if (this.state.awaitingRefresh) {
      this.refresh();
    }
  }, 2000);

  componentWillUnmount() {
    this.ignoreLastFetch = true;
    document.body.classList.remove('requisition-roles-page');
  }

  fetchCategories = () => {
    axios
      .get('/api/categories/', { params: { district_id: this.districtId } })
      .then(r => {
        this.setCategoriesAndGrades(r.data);
      })
      .catch(err => console.log(err));
  };

  setCategoriesAndGrades = categoriesList => {
    /** Set categories from db instead of using enum as done previously
     */
    const allSubjects = [];
    categoriesList.forEach(c => {
      c.subcategories.forEach(s => {
        allSubjects.push(s.id);
      });
    });

    const allGrades = [];
    grades().forEach(item => {
      allGrades.push(item.value);
    });

    if (!this.ignoreLastFetch) {
      this.setState(
        {
          subjects: allSubjects,
          grades: allGrades,
          categories: categoriesList,
          query: this.props.query || '',
        },
        this.fetchRoles
      );
    }
  };

  fetchRoles = () => {
    this.startSpinner();
    let query = this.state.query;
    // Only get active roles on this page
    // let page = this.state.page;
    axios
      .get('/api/search/districtroles/', {
        params: {
          query: query,
          district: this.districtId,
          by_posting_roles_page: true,
          // page: page + 1
        },
      })
      .then(r => {
        // filter roles before setting roles state
        if (!this.ignoreLastFetch) {
          this.setState({ allRoles: r.data.results, roles: r.data.results }, this.filterRoles);
          this.stopSpinner();
        }
      });
  };

  refresh = () => {
    this.startSpinner();
    // Change awaitingRefresh so the debounced refresh would fire
    this.setState({ awaitingRefresh: false });
    axios
      .get(`/api/search/districtroles/`, {
        params: {
          query: this.state.query,
          district: this.districtId,
          subjects: this.state.subjects,
          grades: this.state.grades,
          by_posting_roles_page: true,
        },
      })
      .then(r => {
        this.stopSpinner();
        this.setState({
          roles: r.data.results,
        });
      })
      .catch(err => {
        this.stopSpinner();
        showTotalFailure(err);
      });
  };
  // Clears ALL search conditions: query, job category, grades
  resetRoles = textOnly => {
    if (textOnly) {
      this.setState(
        {
          query: null,
        },
        () => {
          this.refresh();
        }
      );
    } else {
      this.setState(
        {
          query: null,
          subjects: [],
          grades: [],
          sortBy: '',
          descending: true,
        },
        () => {
          this.refresh();
        }
      );
    }
  };

  updateMultiSelect = (values, fieldName) => {
    var updateCategory = this.state[fieldName];
    updateCategory = values.map(value => value.value);
    this.setState({ [fieldName]: updateCategory, awaitingRefresh: true }, () => {
      this.controlledRefresh();
    });
  };

  filterByGrades = () => {
    const finalRoles = [];
    this.state.roles.forEach(role => {
      role.grades.forEach(roleGrade => {
        if (this.state.grades.indexOf(roleGrade) !== -1) {
          finalRoles.push(role);
        }
      });
    });
    this.setState({
      roles: finalRoles,
    });
  };

  orderRoles = sortBy => {
    // If user clicked on same sort, switch ascending/descending
    let direction = true;
    let multiplier = -1;
    if (this.state.sortBy === sortBy) {
      direction = !this.state.descending;
      multiplier = direction ? -1 : 1;
    }
    // Order the roles
    let newOrder = this.state.roles.sort((a, b) => {
      // Sort the dates; order by having a start_date, then closest start_date
      if (sortBy === 'start_date' || sortBy === 'created') {
        if (a[sortBy] === null && b[sortBy] !== null) return 1;
        if (a[sortBy] !== null && b[sortBy] === null) return -1;
        if (new Date(a[sortBy]) > new Date(b[sortBy])) return -1 * multiplier;
        if (new Date(a[sortBy]) < new Date(b[sortBy])) return 1 * multiplier;
        return 0;
      } else if (sortBy === 'subjects') {
        // No subject roles appear last
        if ((a[sortBy] === null || a[sortBy].length === 0) && b[sortBy] !== null) return 1;
        if (a[sortBy] !== null && (b[sortBy] === null || b[sortBy].length === 0)) return -1;
        if (
          (a[sortBy] === null || a[sortBy].length === 0) &&
          (b[sortBy] === null || b[sortBy].length === 0)
        )
          return 0;

        // Sort by alphabetical of 'label' of first subject they submitted
        let resultSubjectA = credentialSubjects().filter(obj => {
          return obj.value === a.subjects[0];
        });

        let resultSubjectB = credentialSubjects().filter(obj => {
          return obj.value === b.subjects[0];
        });

        if (resultSubjectA[0].label < resultSubjectB[0].label) return 1 * multiplier;
        if (resultSubjectA[0].label > resultSubjectB[0].label) return -1 * multiplier;
      } else if (sortBy.indexOf('app_count') !== -1) {
        const key = sortBy.split('-')[1];
        const valueA = a['app_count'][key];
        const valueB = b['app_count'][key];
        if (valueA < valueB) return -1 * multiplier;
        if (valueA > valueB) return 1 * multiplier;
        return 0;
      } else {
        // Sorts everything else
        const valueA =
          a[sortBy] && typeof a[sortBy] === 'string' ? a[sortBy].toLowerCase() : a[sortBy];
        const valueB =
          b[sortBy] && typeof b[sortBy] === 'string' ? b[sortBy].toLowerCase() : b[sortBy];
        if (valueA < valueB) return -1 * multiplier;
        if (valueA > valueB) return 1 * multiplier;
        return 0;
      }
      return 0;
    });
    // Set state to match
    this.setState(prevState => ({
      sortBy: sortBy,
      roles: newOrder,
      descending: direction,
    }));
  };

  startSpinner = () => {
    this.setState({ showSpinner: true });
  };

  stopSpinner = () => {
    this.setState({ showSpinner: false });
  };

  render() {
    return (
      <div className="jobslist-container">
        <JobsList
          name={this.state.name}
          roles={this.state.roles}
          query={this.state.query}
          queryUpdate={query => this.setState(query, this.refresh)}
          subjects={this.state.subjects}
          orderRoles={this.orderRoles}
          sortBy={this.state.sortBy}
          resetRoles={this.resetRoles}
          updateMultiSelect={this.updateMultiSelect}
          grades={this.state.grades}
          isRequisition={this.state.isRequisition}
          isSchoolUser={this.state.isSchoolUser}
          categories={this.state.categories}
          awaitingRefresh={this.state.awaitingRefresh}
          refresh={this.refresh}
          district={this.state.district}
          showSpinner={this.state.showSpinner}
        />
      </div>
    );
  }
}

export default withRouter(RequisitionRolesListContainer);
