import { StrictMode, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  ChoiceDropdown,
  useChoiceDropdown,
} from '../../../PipelineMetricsDashboard/components/ChoiceDropdown';
import { DashboardHeaderContainerV2 } from './DashboardHeaderContainerV2';
import { HIRING_SEASON_CHOICES } from '../../../PipelineMetricsDashboard/constants';
import { setQueryStringValue, getQueryStringValue } from 'utils/util';
import districtsAPI from 'api/districtsAPI';

import { stateDashboardSegmentTypes, vacancyStatusOptions, parentOrganizations } from 'utils/enums';
import { getFilterOptions } from 'features/StateDataDashboards/utils';

const VACANCY_STATUS_CHOICES = Object.entries(vacancyStatusOptions).map(([key, label]) => ({
  id: parseInt(key),
  value: parseInt(key),
  label,
}));

export const DataDashboardContainerV2 = ({
  title,
  stateCode,
  applicantCount = 0,
  vacancyCount = 0,
  setDistrictsSelected,
  setHiringSeasonsSelected,
  setJobSubcategoriesSelected,
  setVacancyStatusesSelected = () => {},
  showJobSubcategoriesDropdown = false,
  showVacancyStatusDropdown = false,
}) => {
  const [districtOptions, setDistrictOptions] = useState([]);
  const [jobCategoryAndSubcategoryOptions, setJobCategoryAndSubcategoryOptions] = useState([]);

  const CHOICE_DROPDOWN_HEIGHT = '56px';

  const initialSelectedHiringSeasons =
    getQueryStringValue('f_HS')
      ?.split(',')
      .map(o => parseInt(o, 10))
      .filter(o => !Number.isNaN(o)) || [];
  const initialSelectedDistricts =
    getQueryStringValue('f_D')
      ?.split(',')
      .map(o => parseInt(o, 10))
      .filter(o => !Number.isNaN(o)) || [];

  const initialSelectedCategories =
    getQueryStringValue('f_SC')
      ?.split(',')
      .map(o => parseInt(o, 10))
      .filter(o => !Number.isNaN(o)) || [];

  const initialSelectedVacancyStatus = getQueryStringValue('f_VS')
    ?.split(',')
    .map(o => parseInt(o, 10))
    .filter(o => !Number.isNaN(o)) || [0];

  const hiringSeasonDropdownState = useChoiceDropdown({
    choices: HIRING_SEASON_CHOICES,
    initialSelectedChoiceIds: initialSelectedHiringSeasons,
  });

  const districtDropdownChoices = districtOptions;

  const districtDropdownState = useChoiceDropdown({
    choices: districtDropdownChoices,
    initialSelectedChoiceIds: initialSelectedDistricts,
  });

  const jobSubcategoryDropdownState = useChoiceDropdown({
    choices: jobCategoryAndSubcategoryOptions,
    initialSelectedChoiceIds: initialSelectedCategories,
  });

  const vacancyStatusDropdownState = useChoiceDropdown({
    choices: VACANCY_STATUS_CHOICES,
    initialSelectedChoiceIds: initialSelectedVacancyStatus,
  });

  useEffect(() => {
    const getJobCategoryAndSubcategoryOptions = async () =>
      await getFilterOptions(stateDashboardSegmentTypes.subcategory.value, stateCode);

    getJobCategoryAndSubcategoryOptions().then(filterOptions =>
      setJobCategoryAndSubcategoryOptions(filterOptions)
    );
    districtsAPI.fetchDistrictsByState(stateCode).then(districts => {
      setDistrictOptions(districts.map(d => ({ id: d.id, value: d.id, label: d.name })));
    });
  }, [stateCode]);

  useEffect(() => {
    setQueryStringValue('districts', districtDropdownState.selectedChoiceIds);
    setDistrictsSelected(districtDropdownState.selectedChoiceIds);

    setQueryStringValue('hiring_seasons', hiringSeasonDropdownState.selectedChoiceIds);
    setHiringSeasonsSelected(hiringSeasonDropdownState.selectedChoiceIds);

    setJobSubcategoriesSelected(jobSubcategoryDropdownState.selectedChoiceIds);
    setQueryStringValue('subcategories', jobSubcategoryDropdownState.selectedChoiceIds);

    setVacancyStatusesSelected(vacancyStatusDropdownState.selectedChoiceIds);
    setQueryStringValue('vacancies', vacancyStatusDropdownState.selectedChoiceIds);
  }, [
    districtDropdownState,
    hiringSeasonDropdownState,
    jobSubcategoryDropdownState,
    vacancyStatusDropdownState,
    setDistrictsSelected,
    setHiringSeasonsSelected,
    setJobSubcategoriesSelected,
    setVacancyStatusesSelected,
  ]);

  return (
    <StrictMode>
      <DashboardContainer>
        <DashboardHeaderContainerV2 title={title} />
        <DashboardFilters showVacancyFilter={showVacancyStatusDropdown}>
          <ApplicantCountContainer>
            {!!applicantCount && (
              <Typography>
                {applicantCount} {applicantCount === 1 ? 'applicant' : 'applicants'}
              </Typography>
            )}
            {!!vacancyCount && (
              <Typography>
                {vacancyCount} {vacancyCount === 1 ? 'vacancy' : 'vacancies'}
              </Typography>
            )}
          </ApplicantCountContainer>
          <ChoiceDropdown
            label="Hiring Season"
            choices={HIRING_SEASON_CHOICES}
            {...hiringSeasonDropdownState}
            height={CHOICE_DROPDOWN_HEIGHT}
            searchBarPlaceholder="Search hiring season"
          />
          <ChoiceDropdown
            label="Organization"
            choices={districtDropdownChoices}
            {...districtDropdownState}
            height={CHOICE_DROPDOWN_HEIGHT}
            searchBarPlaceholder="Search organization"
          />
          {showJobSubcategoriesDropdown && (
            <ChoiceDropdown
              label="Job Category"
              choices={jobCategoryAndSubcategoryOptions}
              {...jobSubcategoryDropdownState}
              height={CHOICE_DROPDOWN_HEIGHT}
              searchBarPlaceholder="Search job categories"
            />
          )}
          {showVacancyStatusDropdown && (
            <ChoiceDropdown
              label="Vacancy Status"
              choices={VACANCY_STATUS_CHOICES}
              {...vacancyStatusDropdownState}
              height={CHOICE_DROPDOWN_HEIGHT}
              searchBarPlaceholder="Search status"
            />
          )}
        </DashboardFilters>
      </DashboardContainer>
    </StrictMode>
  );
};

const DashboardContainer = styled.main`
  max-width: 1160px;
  margin: 20px auto 40px;
  padding: 0 42px;

  font-size: 15px;

  // Reduce side padding for small screens
  @media screen and (max-width: 768px) {
    padding: 0 10px;
  }
`;

const DashboardFilters = styled.div`
  margin-top: 29px;
  margin-bottom: 14px;

  display: grid;
  grid-template-columns: ${({ showVacancyFilter }) =>
    showVacancyFilter ? '15% 21.5% 21.5% 21.5% 20.5%' : '19% 27% 27% 27%'};

  & > *:not(:last-child) {
    margin-right: 18px;
  }

  // Collapse dropdowns into a column for small screens.
  @media screen and (max-width: 768px) {
    grid-template-columns: 100%;

    & > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 14px;
    }
  }
`;

const ApplicantCountContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Typography = styled.h2`
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  margin-left: 14px;
  margin-left: 0;
`;
