import ReactTooltip from 'react-tooltip';

import { refStatus } from 'utils/enums';

const referenceMap = {
  not_started: 'Unstarted',
  pending: 'Pending',
  complete: 'Complete',
};

export default function ReferenceStatusPreview({ references, urlPrefix, userId, applicationId }) {
  let status = 'not_started';
  let complete_count = 0;
  let pending_count = 0;
  let not_started_count = 0;
  references.forEach(reference => {
    if (reference.status === refStatus.pending || reference.status === refStatus.draft) {
      status = 'pending';
      pending_count += 1;
    } else if (reference.status === refStatus.complete) {
      complete_count += 1;
    } else {
      not_started_count += 1;
    }
  });

  if (complete_count > 0) {
    if (complete_count === references.length) {
      status = 'complete';
    } else {
      status = 'pending';
    }
  }
  if (status === 'pending') {
    return (
      <div
        onClick={() =>
          window.open(
            `/${urlPrefix}/profile/${userId}/?application=${applicationId}&references=true`,
            '_blank'
          )
        }
      >
        <span
          data-tip
          data-for="reference-check"
          style={{ borderBottom: '1px solid', cursor: 'pointer' }}
        >
          Reference Check Pending
        </span>
        <ReactTooltip id="reference-check" effect="solid">
          <div>
            <p style={{ color: 'white' }}>{pending_count} pending</p>
            <p style={{ color: 'white' }}>{complete_count} complete</p>
            <p style={{ color: 'white' }}>{not_started_count} not started</p>
          </div>
        </ReactTooltip>
      </div>
    );
  } else {
    return (
      <div
        onClick={() =>
          window.open(
            `/${urlPrefix}/profile/${userId}/?application=${applicationId}&references=true`,
            '_blank'
          )
        }
      >
        <span style={{ borderBottom: '1px solid', cursor: 'pointer' }}>
          Reference Check {referenceMap[status]}
        </span>
      </div>
    );
  }
}
