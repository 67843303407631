import styled from 'styled-components';
import { color, border } from 'styled-system';

import * as DashboardWidget from './components/DashboardWidget';
import * as Toggle from './components/Toggle';
import { ReactComponent as RightArrow } from '../../assets/right-arrow.svg';
import { ChoiceDropdown, useChoiceDropdown } from './components/ChoiceDropdown';
import { DIVERSITY_CHOICES } from './constants';
import { useHiringConversionMetrics } from './useHiringConversionMetrics';
import auth from 'utils/auth';
import { appStatusType } from 'utils/enums';
import { theme } from 'ui-kit';

const HiringConversionMetricsWidget = ({
  hiringSeasons,
  schools,
  subcategories,
  applicationStatuses,
}) => {
  const toggleState = Toggle.useToggleState();

  const diversityDropdownState = useChoiceDropdown({
    choices: DIVERSITY_CHOICES,
    initialSelectedChoiceIds: DIVERSITY_CHOICES.map(choice => choice.id),
  });

  const { data, isLoading } = useHiringConversionMetrics({
    hiringSeasons,
    schools,
    subcategories,
    genders: DIVERSITY_CHOICES.filter(choice => choice.group === 'gender')
      .filter(choice => !choice.isGroupHeader)
      .filter(choice => diversityDropdownState.selectedChoiceIds.includes(choice.id))
      .map(choice => choice.value),
    ethnicities: DIVERSITY_CHOICES.filter(choice => choice.group === 'enthnicity')
      .filter(choice => !choice.isGroupHeader)
      .filter(choice => diversityDropdownState.selectedChoiceIds.includes(choice.id))
      .map(choice => choice.value),
  });

  const hiringPoolStatus = applicationStatuses.find(
    status => status.status_type === appStatusType.hiring_pool
  );
  const hiredStatus = applicationStatuses.find(
    status => status.status_type === appStatusType.hired
  );
  let hiringPoolLabel = hiringPoolStatus ? hiringPoolStatus.label : 'Hiring Pool';
  let hiredLabel = hiredStatus ? hiredStatus.label : 'Hired';

  // TODO: Add Loading Spinner

  const leadsDataset = toggleState.modeOneIsActive
    ? data.prospects.pass_rate
    : data.prospects.pass_time;
  const applicationsDataset = toggleState.modeOneIsActive
    ? data.applications.pass_rate
    : data.applications.pass_time;

  const diversityFilterIsOn =
    diversityDropdownState.selectedChoiceIds.length !== 0 &&
    diversityDropdownState.selectedChoiceIds.length !== DIVERSITY_CHOICES.length;

  let notEnoughData = false;
  if (diversityFilterIsOn) {
    // if any value is 1 or fewer, don't show the chart
    Object.entries(applicationsDataset).forEach(([key, value]) => {
      if (value <= 1) {
        notEnoughData = true;
      }
    });
  }

  let message;
  if (notEnoughData) {
    message = 'Not enough data. Check your filters and try again.';
  }

  return (
    <>
      <DashboardWidget.Heading>Hiring conversions</DashboardWidget.Heading>
      <MetricsWidgetBody>
        <DashboardWidget.BodyActions>
          <ChoiceDropdown
            label="Demographics"
            choices={DIVERSITY_CHOICES}
            {...diversityDropdownState}
          />
          <DashboardWidget.BodyActionPulledRight>
            <Toggle.Wrapper>
              <Toggle.ToggleItem
                isActive={toggleState.modeOneIsActive}
                onClick={toggleState.setModeOneIsActive}
              >
                Pass %
              </Toggle.ToggleItem>
              <Toggle.ToggleItem
                isActive={toggleState.modeTwoIsActive}
                onClick={toggleState.setModeTwoIsActive}
              >
                Days
              </Toggle.ToggleItem>
            </Toggle.Wrapper>
          </DashboardWidget.BodyActionPulledRight>
        </DashboardWidget.BodyActions>
        <DashboardWidget.BodyContent isLoading={isLoading}>
          {message ? (
            <Placeholder>{message}</Placeholder>
          ) : (
            <MetricItemsContainer>
              {auth.hasOnboardingModule() && (
                <MetricItems>
                  <MetricItemWrapper>
                    <MetricItem
                      color={theme.uiColors.reds.full}
                      bg={theme.uiColors.reds.xlight}
                      border={`2px solid ${theme.uiColors.reds.full}`}
                    >
                      <MetricItemValue>
                        {toggleState.modeOneIsActive ? leadsDataset.sourced : leadsDataset.outreach}
                      </MetricItemValue>
                      <MetricItemLabel>
                        {toggleState.modeOneIsActive ? 'Leads Sourced' : 'Until Outreach'}
                      </MetricItemLabel>
                    </MetricItem>
                    <RightArrow />
                  </MetricItemWrapper>
                  <MetricItemWrapper>
                    <MetricItem
                      color={theme.uiColors.purples.full}
                      bg={theme.uiColors.purples.xlight}
                      border={`2px solid ${theme.uiColors.purples.full}`}
                    >
                      <MetricItemValue>{leadsDataset.contacted}</MetricItemValue>
                      <MetricItemLabel>
                        {toggleState.modeOneIsActive ? 'Leads Contacted' : 'Until Contacted'}
                      </MetricItemLabel>
                      {toggleState.modeOneIsActive && (
                        <MetricItemSmallText>
                          (
                          {(leadsDataset.sourced > 0
                            ? leadsDataset.contacted / leadsDataset.sourced
                            : 0
                          ).toLocaleString('en-US', {
                            style: 'percent',
                            minimumFractionDigits: 1,
                          })}
                          )
                        </MetricItemSmallText>
                      )}
                    </MetricItem>
                    <RightArrow />
                  </MetricItemWrapper>
                  <MetricItemWrapper>
                    <MetricItem
                      color={theme.uiColors.blues.full}
                      bg={theme.uiColors.blues.xlight}
                      border={`2px solid ${theme.uiColors.blues.full}`}
                    >
                      <MetricItemValue>{leadsDataset.converted}</MetricItemValue>
                      <MetricItemLabel>
                        {toggleState.modeOneIsActive ? 'Leads Converted' : 'Until Converted'}
                      </MetricItemLabel>
                      {toggleState.modeOneIsActive && (
                        <MetricItemSmallText>
                          (
                          {(leadsDataset.sourced > 0
                            ? leadsDataset.converted / leadsDataset.sourced
                            : 0
                          ).toLocaleString('en-US', {
                            style: 'percent',
                            minimumFractionDigits: 1,
                          })}
                          )
                        </MetricItemSmallText>
                      )}
                    </MetricItem>
                  </MetricItemWrapper>
                </MetricItems>
              )}
              <MetricItems>
                <MetricItemWrapper>
                  <MetricItem
                    color={theme.uiColors.reds.full}
                    bg={theme.uiColors.reds.xlight}
                    border={`2px solid ${theme.uiColors.reds.full}`}
                  >
                    <MetricItemValue>
                      {toggleState.modeOneIsActive
                        ? applicationsDataset.applied
                        : applicationsDataset.screened}
                    </MetricItemValue>
                    <MetricItemLabel>
                      {toggleState.modeOneIsActive ? 'Applications Submitted' : 'Until Screened'}
                    </MetricItemLabel>
                  </MetricItem>
                  <RightArrow />
                </MetricItemWrapper>
                <MetricItemWrapper>
                  <MetricItem
                    color={theme.uiColors.purples.full}
                    bg={theme.uiColors.purples.xlight}
                    border={`2px solid ${theme.uiColors.purples.full}`}
                  >
                    <MetricItemValue>{applicationsDataset.hiring_pool}</MetricItemValue>
                    {/* TODO: Get district named status */}
                    <MetricItemLabel>
                      {`${toggleState.modeTwoIsActive ? 'Until ' : ''}${hiringPoolLabel}`}
                    </MetricItemLabel>
                    {toggleState.modeOneIsActive && (
                      <MetricItemSmallText>
                        (
                        {(applicationsDataset.applied > 0
                          ? applicationsDataset.hiring_pool / applicationsDataset.applied
                          : 0
                        ).toLocaleString('en-US', {
                          style: 'percent',
                          minimumFractionDigits: 1,
                        })}
                        )
                      </MetricItemSmallText>
                    )}
                  </MetricItem>
                  <RightArrow />
                </MetricItemWrapper>
                <MetricItemWrapper>
                  <MetricItem
                    color={theme.uiColors.blues.full}
                    bg={theme.uiColors.blues.xlight}
                    border={`2px solid ${theme.uiColors.blues.full}`}
                  >
                    <MetricItemValue>{applicationsDataset.hired}</MetricItemValue>
                    <MetricItemLabel>
                      {`${toggleState.modeTwoIsActive ? 'Until ' : ''}${hiredLabel}`}
                    </MetricItemLabel>
                    {toggleState.modeOneIsActive && (
                      <MetricItemSmallText>
                        (
                        {(applicationsDataset.applied > 0
                          ? applicationsDataset.hired / applicationsDataset.applied
                          : 0
                        ).toLocaleString('en-US', {
                          style: 'percent',
                          minimumFractionDigits: 1,
                        })}
                        )
                      </MetricItemSmallText>
                    )}
                  </MetricItem>
                </MetricItemWrapper>
              </MetricItems>
            </MetricItemsContainer>
          )}
        </DashboardWidget.BodyContent>
      </MetricsWidgetBody>
    </>
  );
};

export default HiringConversionMetricsWidget;

// Allow height to grow to contain wrapping items
const MetricsWidgetBody = styled(DashboardWidget.Body)`
  height: 100%;
  min-height: 290px;
`;

// Allow items to wrap on small screens
const MetricItemsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  padding-bottom: 20px;

  width: 100%;
`;

const MetricItems = styled.div`
  display: flex;
  flex-flow: row nowrap;

  margin: 10px 0;
`;

const MetricItemWrapper = styled.div`
  display: flex;

  & > svg {
    width: 10px;
    height: 10px;

    fill: #444444;

    margin-top: 36px;
    margin-left: 6px;
    margin-right: 6px;
  }
`;

const metricItemHeight = '152px';
const MetricItem = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  position: relative;

  width: 120px;
  height: ${metricItemHeight};
  padding: 10px;
  border-radius: 6px;

  @media screen and (max-width: 768px) {
    padding: 5px;
    width: 72px;
  }

  ${color}
  ${border}
`;

const MetricItemValue = styled.div`
  font-size: 40px;
  font-weight: 600;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

const MetricItemLabel = styled.div`
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
`;

const MetricItemSmallText = styled.div`
  position: absolute;
  top: calc(${metricItemHeight} + 5px);
  font-size: 20px;
  color: #999;
`;

const Placeholder = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
