import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { grid } from 'styled-system';
import ReactTooltip from 'react-tooltip';

import auth from 'utils/auth';
import VacanciesGraph from '../vacanciesgraph';
import VacanciesWidget from '../vacancieswidget';
import Vacancies from './Vacancies';
import { DownloadIconGrey } from 'ui-kit/icons';
import { AlertDialog } from 'ui-kit';
import dashboardMetricsAPI from 'api/dashboardMetricsAPI';

class Dashboard extends Component {
  static propTypes = {
    adminUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
    unplacedCandidates: PropTypes.arrayOf(PropTypes.object).isRequired,
    vacancies: PropTypes.arrayOf(PropTypes.object).isRequired,
    // spinner used while initial fetch is running
    loadingSpinner: PropTypes.bool.isRequired,
    // function that shows spinner under rendered list while
    // additional list items are fetching (for infinite scroll UX)
    loadingMessage: PropTypes.func.isRequired,
    getVacanciesDebounced: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
    resetFilters: PropTypes.func.isRequired,
    positionsNumerator: PropTypes.number.isRequired,
    positionsDenominator: PropTypes.number.isRequired,
    currentSeasonObj: PropTypes.object.isRequired,
    schoolsFilter: PropTypes.array.isRequired,
    rolesFilter: PropTypes.array.isRequired,
    subcategoriesFilter: PropTypes.array.isRequired,
    hiringSeasonFilter: PropTypes.array.isRequired,
    schoolOptions: PropTypes.array.isRequired,
    roleOptions: PropTypes.array.isRequired,
    subcategoryOptions: PropTypes.array.isRequired,
    hiringSeasonOptions: PropTypes.array.isRequired,
    updateMultiSelectFilter: PropTypes.func.isRequired,
    onSelectAllChoices: PropTypes.func.isRequired,
    onClearAllChoices: PropTypes.func.isRequired,
    updateVacancyInContainerState: PropTypes.func.isRequired,
    filteredHiringSeasons: PropTypes.array.isRequired,
    vacancyHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
    postVacancy: PropTypes.func.isRequired,
    putVacancy: PropTypes.func.isRequired,
    sortBy: PropTypes.func.isRequired,
    pairCandidateWithVacancy: PropTypes.func.isRequired,
    deleteVacancy: PropTypes.func.isRequired,
    removeCandidateFromVacancy: PropTypes.func.isRequired,
    totalLineItems: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { isAlertOpen: false };
  }

  updateGraph = (fieldName, fieldValue, active) => {
    /** this.state[fieldName] should always be an array. If active is true,
     * the filter option that was clicked is active and should be turned off, which means we
     * have to remove fieldValue from the array instead of adding it.
     */
    let updatedField;
    if (active) {
      // filter is active and the user wants to turn it off. Find the element and remove.
      updatedField = this.state[fieldName].filter(e => e !== fieldValue);
    } else {
      updatedField = this.state[fieldName];
      updatedField.push(fieldValue);
    }
    this.setState({ [fieldName]: updatedField });
  };

  requestDashboardData = () => {
    dashboardMetricsAPI.downloadVacancyDashboardData();
  };

  render() {
    const totalVacancies = Number(this.props.positionsDenominator.toFixed(2));
    return (
      <div className="dashboard">
        {auth.isSuperAdminUser() && (
          <DownloadIconContainer>
            <DownloadIconGrey
              data-tip
              data-for="download-dashboard"
              data-place="top"
              data-delay-show="100"
              onClick={() => {
                this.setState({ isAlertOpen: true });
              }}
            />
            <ReactTooltip id="download-dashboard" effect="solid">
              <span style={{ color: 'white' }}>Download raw data</span>
            </ReactTooltip>
          </DownloadIconContainer>
        )}

        {/* break to two columns on large screens */}
        <ChartSection gridTemplateColumns={['1fr', '1fr', '1fr', '1fr', '1fr 1fr']}>
          <div width={1}>
            <ChartTitle>Vacancy fill rate</ChartTitle>
            <ChartContainer>
              <VacanciesWidget
                filled={Number(this.props.positionsNumerator.toFixed(2))}
                vacancies={totalVacancies}
              />
            </ChartContainer>
          </div>
          <div width={1}>
            <ChartTitle>Vacancies over time</ChartTitle>
            <VacanciesGraph
              vacancyHistory={this.props.vacancyHistory}
              selectedSeasons={this.props.hiringSeasonFilter}
              currentSeason={this.props.currentSeasonObj.value}
            />
          </div>
        </ChartSection>

        <div className="section vacancies">
          <Vacancies
            vacancies={this.props.vacancies}
            unplacedCandidates={this.props.unplacedCandidates}
            currentSeasonObj={this.props.currentSeasonObj}
            adminUsers={this.props.adminUsers}
            loadingSpinner={this.props.loadingSpinner}
            getVacanciesDebounced={this.props.getVacanciesDebounced}
            search={this.props.search}
            hasMore={this.props.hasMore}
            resetFilters={this.props.resetFilters}
            totalLineItems={this.props.totalLineItems}
            schoolsFilter={this.props.schoolsFilter}
            rolesFilter={this.props.rolesFilter}
            subcategoriesFilter={this.props.subcategoriesFilter}
            hiringSeasonFilter={this.props.hiringSeasonFilter}
            schoolOptions={this.props.schoolOptions}
            roleOptions={this.props.roleOptions}
            categories={this.props.categories}
            subcategoryOptions={this.props.subcategoryOptions}
            hiringSeasonOptions={this.props.hiringSeasonOptions}
            updateMultiSelectFilter={this.props.updateMultiSelectFilter}
            onSelectAllChoices={this.props.onSelectAllChoices}
            onClearAllChoices={this.props.onClearAllChoices}
            filteredHiringSeasons={this.props.filteredHiringSeasons}
            removeCandidateFromVacancy={this.props.removeCandidateFromVacancy}
            deleteVacancy={this.props.deleteVacancy}
            pairCandidateWithVacancy={this.props.pairCandidateWithVacancy}
            updateVacancyInContainerState={this.props.updateVacancyInContainerState}
            postVacancy={this.props.postVacancy}
            putVacancy={this.props.putVacancy}
            sortBy={this.props.sortBy}
          />
        </div>
        {this.props.loadingMessage()}
        <AlertDialog
          isOpen={this.state.isAlertOpen}
          onClose={() => {
            this.requestDashboardData();
            this.setState({ isAlertOpen: false });
          }}
          text="This may take a few minutes. You will receive an email when your export is complete."
          buttonText="Got it!"
          title="Data Download"
        />
      </div>
    );
  }
}

export default Dashboard;

const ChartSection = styled.div`
  ${grid}

  display: grid;
  grid-gap: 1rem;
  align-items: center;

  margin: 2rem 0;
`;

const ChartContainer = styled.div`
  width: 100%;
  background-color: white;
  border: 1px solid #dcdcdc;
`;

const ChartTitle = styled.div`
  font-size: 18px;
  color: rgba(57, 60, 73, 0.81);

  margin-bottom: 8px;
`;

const DownloadIconContainer = styled.div`
  position: relative;

  & > svg {
    cursor: pointer;
    position: absolute;
    bottom: -10px;
    right: 0;
  }
`;
