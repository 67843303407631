import useSWR from 'swr';
import axios from 'axios';

const fetcher = url => axios.get(url).then(response => response.data);

export default function useAvailableHellosignTemplates({ candidateId }) {
  const { data } = useSWR(
    `/api/hellosign_templates/available_to_candidate/?candidate_id=${candidateId}`,
    fetcher
  );

  const templateOptionsLoading = data === undefined;

  return { templateOptions: data ?? [], templateOptionsLoading };
}
