import { useEffect, useState } from 'react';
import styled from 'styled-components';
import auth from '../../utils/auth';
import { tagColors } from '../../utils/enums';
import { getTagProperty } from './util';

import {
  AltModal,
  Button,
  SingleClickDropdown,
  Input,
  Label,
  ConfirmationDialog,
} from '../../ui-kit';
import GreenToggle from './GreenToggleTag';

export default function CustomTagModal({ isOpen, onClose, onSave, onDelete, editTag, error = '' }) {
  const [tag, setTag] = useState(editTag);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // handler for modal label color dropdown
  const handleLabelColorChoice = value => {
    setTag({
      ...tag,
      label_color: value,
    });
  };

  // handler for modal visibility and tag type switches
  const handleSwitchToggle = e => {
    const key = e.target.name;
    const value = e.target.value;

    switch (key) {
      case 'Candidates':
        if (tag.prospect_type && !value) {
          // spec: if tag is prospects_only, remove district_users and school_admins access.
          setTag({
            ...tag,
            candidate_type: value,
            district_user_visibility: false,
            school_admin_visibility: false,
          });
        } else {
          setTag({
            ...tag,
            candidate_type: value,
          });
        }
        break;
      case 'Prospects':
        setTag({
          ...tag,
          prospect_type: value,
        });
        break;
      case 'District Admin':
        setTag({
          ...tag,
          district_admin_visibility: value,
        });
        break;
      case 'District User':
        setTag({
          ...tag,
          district_user_visibility: value,
        });
        break;
      case 'School Admin':
        setTag({
          ...tag,
          school_admin_visibility: value,
        });
        break;
      default:
        setTag({
          ...tag,
          super_admin_visibility: value,
        });
    }
  };

  useEffect(() => setTag(editTag), [editTag]);

  function cancel() {
    setHasError(false);
    onClose();
  }

  function remove() {
    onDelete(tag);
  }

  function save() {
    if (validateTag()) {
      const cleanTag = Object.assign({}, tag); // Don't mess with state directly.
      if (!cleanTag.label) cleanTag.label = cleanTag.value;
      onSave(cleanTag);
    }
  }

  function confirmRemove(decision) {
    // Are you sure about that?
    setIsConfirmationOpen(false);
    if (decision) remove();
  }

  function validateTag() {
    let isValid = true;

    if (!tag.label) {
      // Tag label should not be blank
      isValid = false;
      setHasError(true);
      setErrorMessage('Tag name is required');
      return;
    }

    return isValid;
  }

  function onChange(e) {
    const key = e.target.name.slice('tag_'.length);
    const value = e.target.value;

    const newSource = {};
    newSource[key] = value;

    setTag(prevState => {
      return {
        ...prevState,
        ...newSource,
      };
    });
  }

  const isEdit = !!editTag.id && !editTag.immutable;

  return isConfirmationOpen ? (
    <ConfirmationDialog
      resultHandler={confirmRemove}
      title="Delete tag"
      text="Deleting this tag will remove it from all profiles. This action cannot be undone."
      backLabel="Back to Edit"
      confirmLabel="Permanently Delete"
      isOpen={isOpen}
    />
  ) : (
    <AltModal.AltModal isOpen={isOpen} onClose={cancel} closeOnClickOutside={false}>
      <AltModal.Title>New Tag</AltModal.Title>
      <AltModal.Body>
        <FormRow>
          <StyledLabel>Tag Name</StyledLabel>
          <Input
            type="text"
            placeholder="Create your tag name"
            name="tag_label"
            value={tag.label}
            onChange={onChange}
            minLength="1"
            maxLength="35"
            width="100%"
            required
          />
          {hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </FormRow>
        <FormInnerContainer>
          <FormRow>
            <StyledLabel>Tag Visibility</StyledLabel>
            <StyledSubtitle>Which admin users can see this tag</StyledSubtitle>
            <GreenToggle
              disabled={true}
              toggleIsOn={tag.super_admin_visibility}
              label="Super Admin"
              toggleFunction={handleSwitchToggle}
            />
            <GreenToggle
              toggleIsOn={tag.district_admin_visibility}
              label="District Admin"
              toggleFunction={handleSwitchToggle}
            />
            {/*
             * SPEC: If a tag is marked as a prospect tag ONLY
             * (its not also a candidate tag), then the
             * School Admin and District User toggles under
             * visibility should be hidden or switched off.
             *
             */}
            {!tag.candidate_type && tag.prospect_type ? null : (
              <>
                <GreenToggle
                  toggleIsOn={tag.district_user_visibility}
                  label="District User"
                  toggleFunction={handleSwitchToggle}
                />
                <GreenToggle
                  toggleIsOn={tag.school_admin_visibility}
                  label="School Admin"
                  toggleFunction={handleSwitchToggle}
                />
              </>
            )}
          </FormRow>
          {/* Spec: section should only be shown if district has prospect module */}
          {auth.hasProspectsModule() && (
            <>
              <FormRow>
                <StyledLabel>Tag Type</StyledLabel>
                <StyledSubtitle>Which profiles is this tag applicable to</StyledSubtitle>
                <GreenToggle
                  toggleIsOn={tag.candidate_type}
                  label="Candidates"
                  margin="10px 0"
                  toggleFunction={handleSwitchToggle}
                />
                <GreenToggle
                  toggleIsOn={tag.prospect_type}
                  label="Prospects"
                  margin="10px 0"
                  toggleFunction={handleSwitchToggle}
                />
              </FormRow>
            </>
          )}
        </FormInnerContainer>
        <FormRow>
          <StyledLabel>Tag Color</StyledLabel>
          <StatusDropdownContainer>
            <SingleClickDropdown.Dropdown
              // renders currently selected color
              label={
                <DropdownLabelContainer>
                  <StatusColor color={getTagProperty(tag, false, true)} />
                  <DropdownLabelText>{getTagProperty(tag, true)}</DropdownLabelText>
                </DropdownLabelContainer>
              }
              noLabelMargin={true}
              showColor={true}
              paddingLeft={'0.1em;'}
            >
              {tagColors().map(color => (
                <StyledListItem key={color.key} onClick={() => handleLabelColorChoice(color.key)}>
                  <StatusColor color={color.hexCode} />
                  <ColorText>{color.label}</ColorText>
                </StyledListItem>
              ))}
            </SingleClickDropdown.Dropdown>
          </StatusDropdownContainer>
        </FormRow>
      </AltModal.Body>
      <StyledActions>
        <Button variant="secondary" onClick={cancel}>
          Cancel
        </Button>
        {isEdit && (
          <Button
            border="None"
            backgroundColor="rgba(0,0,0,0)"
            color="#c2c2c2"
            onClick={() => setIsConfirmationOpen(true)}
            padding="0"
          >
            Delete tag
          </Button>
        )}
        <SaveButton variant="primary" onClick={save}>
          Create Tag
        </SaveButton>
      </StyledActions>
    </AltModal.AltModal>
  );
}

const ErrorMessage = styled.div`
  color: #00b88d;
  font-size: 15px;
  line-height: 20px;
`;

const FormRow = styled.div`
  margin-bottom: 44px;
  grid-column- & + ${ErrorMessage} {
    margin-top: -21px;
  }
`;

const FormInnerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const StyledActions = styled(AltModal.Actions)`
  display: grid;
  grid-template-columns: 25% 15% 35% 25%;
  padding: 40px;
`;

const SaveButton = styled(Button)`
  grid-column: 4;
`;

const StyledLabel = styled(Label)`
  font-size: 19px;
  line-height: 26px;
  margin-bottom: 14px;
`;

const StyledSubtitle = styled.span`
  color: #a6a7ad;
  font-size: 15px;
  line-height: 20px;
`;

const StatusDropdownContainer = styled.div`
  width: 150px;
  position: relative;
  display: flex;
  align-items: center;
`;

const StatusColor = styled.span`
  border-radius: 50%;
  margin-right: 8px;
  height: 20px;
  width: 20px;
  background-color: ${props => props.color};
`;

const ColorText = styled.span`
  margin-left: 8px;
  font-size: 15px;
`;

const StyledListItem = styled(SingleClickDropdown.ListItem)`
  padding: 6px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background-color: var(--gray);
  }

  &:first-child {
    padding-top: 12px;
  }

  &:last-child {
    padding-bottom: 12px;
  }
`;

const DropdownLabelContainer = styled.div`
  padding: 6px 12px;
  display: flex;
  align-items: center;
`;

const DropdownLabelText = styled.span`
  margin-left: 8px;
  font-size: 15px;
`;
