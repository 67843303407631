import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { AltModal as Modal } from 'ui-kit';
import { Actions } from './JobAlerts/JobAlertsModal.styles';
import { useTheme } from '@mui/material/styles';

interface StateTalentModalProps {
  onMaybeClose: () => void;
  onNoThanksClose: () => void;
}

const StateTalentModal: React.FC<StateTalentModalProps> = ({ onMaybeClose, onNoThanksClose }) => {
  const theme = useTheme()
  return (
    <Modal.AltModal isOpen={true} onClose={onMaybeClose} data-testid="modal" small={true}>
      <HeaderTitle>Create your Talent Profile</HeaderTitle>
      <ModalBody>
        Want to work in education in Indiana? Fill out your profile on the state’s new Educator
        Talent Marketplace to match with roles that fit your interests.
        <br />
        <br />
        Learn more&nbsp;
        <TextLink
          href="https://intercom.help/nimble-hiring/en/articles/7885065-indiana-educator-talent-marketplace"
          target="_blank"
          rel="noopener noreferrer"
        >
          here.
        </TextLink>
      </ModalBody>
      <Modal.Actions style={{ backgroundColor: theme.palette.white.main }}>
        <ButtonContainer>
          <BaseButton onClick={onMaybeClose}>Maybe later</BaseButton>
          <NoThanksButton onClick={onNoThanksClose}>No thanks</NoThanksButton>
          <ContinueLink onClick={onNoThanksClose} to="/preferences">
            Continue to Nimble
          </ContinueLink>
        </ButtonContainer>
      </Modal.Actions>
    </Modal.AltModal>
  );
};

export default StateTalentModal;

const BaseButton = styled('button')(({ theme }) => ({
  backgroundColor: 'transparent',
  border: `1px solid ${theme.palette.gray.light}`,
  borderRadius: '2px',
  color: theme.palette.text.secondary,
  fontSize: '15px',
  fontWeight: theme.typography.fontWeightMediumBold,
  lineHeight: '24px',
  padding: theme.spacing(1, 2),
}));

const ButtonContainer = styled(Actions)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  padding: 0,
}));

const ContinueLink = styled(Link)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  borderRadius: '2px',
  color: theme.palette.white.main,
  fontSize: '16px',
  padding: theme.spacing(1, 2),
  textAlign: 'center',
  justifyItems: 'center',
  '&:hover': {
    color: theme.palette.white.main,
  },
}));

const HeaderTitle = styled(Modal.Title)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '18px',
  fontWeight: theme.typography.fontWeightMediumBold,
}));

const ModalBody = styled(Modal.Body)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  borderBottom: `1px solid ${theme.palette.gray.darker}`,
  color: theme.palette.text.primary,
  fontSize: '16px',
  lineHeight: '25px',
  padding: theme.spacing(2, 3),
  paddingTop: theme.spacing(2.5),
}));

const NoThanksButton = styled(BaseButton)({
  border: 'none',
});

const TextLink = styled('a')(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'underline',
}));
