import { useState, useCallback } from 'react';
import styled from 'styled-components';
import { flexbox, layout } from 'styled-system';
import axios from 'axios';

import { scorecardQType } from 'utils/enums';
import ErrorText from 'components/errortext';

import ReferenceEditorQuestions from 'components/ReferenceModal/ReferenceEditor/ReferenceEditorQuestions';

import referenceCheckAPI from 'api/referenceCheckAPI';
import { useLocation } from 'react-router-dom';

const isAttachment = answer => answer.question_type === scorecardQType.attachment;

export default function SubmitReferenceEditor({ referenceTemplate, setSubmitted }) {
  const location = useLocation();

  const getMaxCumulativeScore = useCallback((referenceTemplate, question) => {
    let maxScore = 0;
    // Loop through other questions in the template and add them to the sum
    // if specified by "include_rating_questions" or "include_rubric_questions"
    referenceTemplate.questions.forEach(item => {
      if (item.question_type === scorecardQType.rating && question.include_rating_questions) {
        maxScore += item.scale;
      }
      if (item.question_type === scorecardQType.rubric && question.include_rubric_questions) {
        // scoreToAdd will be the largest number in the rubric, which is found by looking
        // at the "number" property of the last column
        let scoreToAdd = item.rubric_columns[item.rubric_columns.length - 1].number;
        maxScore += scoreToAdd;
      }
    });

    return maxScore;
  }, []);

  const makeNewReferenceCheckFromTemplate = useCallback(
    referenceTemplate => {
      const referenceCheck = {
        answers: [],
        include_cumulative_score: false,
      };

      referenceTemplate.questions.forEach(question => {
        // Copy over the question, but not its id. This is easier than
        // copying each field besides id explicitly.
        const newAnswer = {
          ...question,
          question_id: question.id,
        };
        delete newAnswer.id;

        switch (question.question_type) {
          case scorecardQType.multiple_choice:
            newAnswer.mc_answer = [];
            break;
          case scorecardQType.nimble:
          case scorecardQType.text:
            newAnswer.answer_text = '';
            break;
          case scorecardQType.rating:
            newAnswer.answer_rating = null;
            break;
          case scorecardQType.direction_text:
            // no answer for direction text type, it's just a set of
            // directions for the editor to read.
            break;
          case scorecardQType.rubric:
            // answer_rubric will store the number of the selection.
            // If the question is not required and the editor did not select anything,
            // it will be null.
            newAnswer.answer_rubric = null;
            break;
          case scorecardQType.final_recommendation:
            // Options are yes and no, with maybe being an option if the admin
            // includes it in the template. Start off with nothing selected (null).
            // final recommendation information is stored at the referenceCheck level
            // because there can only be one recommendation max per referenceCheck.
            referenceCheck.include_final_recommendation = true;
            referenceCheck.include_maybe_option = question.include_maybe_option;
            referenceCheck.show_answer_on_preview = question.show_answer_on_preview;
            referenceCheck.final_recommendation = null;
            break;
          case scorecardQType.cumulative_score:
            // similar to final recommendation, there can only be one cumulative score per
            // referenceCheck, so cumulative score information is stored at the referenceCheck level.
            referenceCheck.include_cumulative_score = true;
            referenceCheck.include_rating_questions = question.include_rating_questions;
            referenceCheck.include_rubric_questions = question.include_rubric_questions;
            referenceCheck.show_total_on_preview = question.show_total_on_preview;
            referenceCheck.cumulative_score = 0;
            referenceCheck.max_cumulative_score = getMaxCumulativeScore(
              referenceTemplate,
              question
            );
            break;
          case scorecardQType.attachment:
            // Start with no value in the attachment
            newAnswer.attachment = '';
            break;
          default:
            break;
        }
        referenceCheck.answers.push(newAnswer);
      });

      return referenceCheck;
    },
    [getMaxCumulativeScore]
  );

  const [referenceCheck, setReferenceCheck] = useState(
    makeNewReferenceCheckFromTemplate(referenceTemplate)
  );
  const [errorMessage, setErrorMessage] = useState('');

  const updateField = (e, index) => {
    const updatedReferenceCheck = { ...referenceCheck };
    updatedReferenceCheck.answers[index].answer_text = e.target.value;

    setReferenceCheck(updatedReferenceCheck);
    setErrorMessage('');
  };

  const updateRating = (e, index) => {
    const updatedReferenceCheck = { ...referenceCheck };
    updatedReferenceCheck.answers[index].answer_rating = e.target.value;
    const cumulativeScore = getCumulativeScore(updatedReferenceCheck);
    updatedReferenceCheck.cumulative_score = cumulativeScore;

    setReferenceCheck(updatedReferenceCheck);
    setErrorMessage('');
  };

  const updateMultipleChoiceField = (e, index, isMultiSelect) => {
    const updatedReferenceCheck = { ...referenceCheck };
    const multipleChoiceAnswers = updatedReferenceCheck.answers[index].mc_answer;

    const foundIndex = multipleChoiceAnswers.findIndex(a => a === e.target.value);
    if (foundIndex > -1) {
      // answer is already selected, so remove it
      multipleChoiceAnswers.splice(foundIndex, 1);
    } else {
      // Answer is not already selected.
      // If it's not a multi-select, we have to deselect the other options.
      if (!isMultiSelect) {
        // setting length to 0 empties the array without reassigning it to a new one
        multipleChoiceAnswers.length = 0;
      }
      // add in new answer at end.
      multipleChoiceAnswers.push(e.target.value);
    }

    setReferenceCheck(updatedReferenceCheck);
    setErrorMessage('');
  };

  const updateFinalRecommendation = value => {
    const updatedReferenceCheck = { ...referenceCheck };
    updatedReferenceCheck.final_recommendation = value;
    setReferenceCheck(updatedReferenceCheck);
    setErrorMessage('');
  };

  const updateRubricSelection = (value, index) => {
    const updatedReferenceCheck = { ...referenceCheck };
    updatedReferenceCheck.answers[index].answer_rubric = value;
    const cumulativeScore = getCumulativeScore(updatedReferenceCheck);
    updatedReferenceCheck.cumulative_score = cumulativeScore;

    setReferenceCheck(updatedReferenceCheck);
    setErrorMessage('');
  };

  const uploadAttachment = (fileData, index) => {
    const updatedReferenceCheck = { ...referenceCheck };
    updatedReferenceCheck.answers[index].attachment = fileData;
    setReferenceCheck(updatedReferenceCheck);
    setErrorMessage('');
  };

  const removeAttachment = (e, index) => {
    e.preventDefault();
    const updatedReferenceCheck = { ...referenceCheck };
    updatedReferenceCheck.answers[index].attachment = null;
    setReferenceCheck(updatedReferenceCheck);
    setErrorMessage('');
  };

  const getCumulativeScore = referenceCheck => {
    let cumulativeScore = 0;

    // Loop through other answers in the Reference/Scorecard and add them to the sum
    // if specified by "include_rating_questions" or "include_rubric_questions"
    referenceCheck.answers.forEach(item => {
      if (item.question_type === scorecardQType.rating && referenceCheck.include_rating_questions) {
        cumulativeScore += Number(item.answer_rating) ?? 0;
      }
      if (item.question_type === scorecardQType.rubric && referenceCheck.include_rubric_questions) {
        cumulativeScore += item.answer_rubric ?? 0;
      }
    });

    return cumulativeScore;
  };

  const onSave = () => {
    try {
      checkForErrors();
    } catch (error) {
      setErrorMessage(error.message);
      return;
    }

    setEmptyRatingAnswers();
    createReferenceCheck();
  };

  const checkForErrors = () => {
    referenceCheck.answers.forEach(answer => {
      if (answer.question_type === scorecardQType.rating) {
        checkRating(answer);
      }
      if (
        answer.is_required &&
        ((answer.question_type === scorecardQType.attachment && !answer.attachment) ||
          (answer.question_type === scorecardQType.text && !answer.answer_text) ||
          (answer.question_type === scorecardQType.nimble && !answer.answer_text) ||
          (answer.question_type === scorecardQType.multiple_choice && !answer.mc_answer.length) ||
          (answer.question_type === scorecardQType.rubric && answer.answer_rubric === null) ||
          (answer.question_type === scorecardQType.final_recommendation &&
            !referenceCheck.final_recommendation))
      ) {
        throw new Error('Please fill out all required fields.');
      }
    });
  };

  const checkRating = answer => {
    /**
     * Rating answers will be one of the following:
     * 1. Empty string (if number typed in and then backspaced)
     * 2. null
     * 3. A number (possibly not in the allowed range (0 - <answer.scale>))
     */
    if (answer.answer_rating === '') {
      if (answer.is_required) {
        throw new Error('Please fill out all required fields.');
      }
    } else if (answer.answer_rating === null) {
      if (answer.is_required) {
        throw new Error('Please fill out all required fields.');
      }
    } else {
      // The answer is a number, so make sure it's in the allowed range.
      // We don't want to allow a draft save that has negative numbers or
      // crazy high numbers.
      let rating = Number(answer.answer_rating);
      if (rating > answer.scale || rating < 0) {
        throw new Error('Rating score must be in the allowed range.');
      }
    }
  };

  const setEmptyRatingAnswers = () => {
    // The backend stores empty ratings as null, but they're sometimes empty strings
    // on the frontend due to html input values. Set all to null.
    const newAnswers = referenceCheck.answers.map(answer =>
      answer.answer_rating === '' ? { ...answer, answer_rating: null } : answer
    );
    referenceCheck.answers = newAnswers;
  };

  const createReferenceCheck = () => {
    // make a copy of referenceCheck so the answers aren't affected by the split below
    const finalReferenceCheck = { ...referenceCheck };

    // Answers are split into two groups: attachment answers and non-attachment answers.
    // This is because attachment answers are submitted as form data and weird results
    // were occurring when trying to submit all answers (even non attachments) as form data.
    const attachmentAnswers = finalReferenceCheck.answers.filter(isAttachment);
    finalReferenceCheck.answers = finalReferenceCheck.answers.filter(
      answer => !isAttachment(answer)
    );

    const searchParams = new URLSearchParams(location.search);
    const signature = searchParams.get('signature');
    axios
      .post('/api/submit_reference/', { signature, ...finalReferenceCheck })
      .then(async response => {
        const newReferenceCheck = response.data;

        const updatedReferenceCheck = {
          // take id and other fields from newReferenceCheck
          ...newReferenceCheck,
          // take answers from existing referenceCheck in case the user made edits while
          // the reference check was saving.
          answers: referenceCheck.answers,
        };

        const answerMapping = {};

        // wait for all attachments to post before moving on
        for (let answer of attachmentAnswers) {
          const formData = getFormData(answer);
          const newAttachment = await referenceCheckAPI.createAttachment(
            newReferenceCheck.id,
            formData
          );
          // store the attachments so we can access them easily below by looking
          // up question_id.
          answerMapping[newAttachment.question_id] = newAttachment;
        }

        // At this point, new answers have been created but they don't have ids.
        // They can't be added as they are because they're not in the proper order
        // due to them being sent as two separate groups (see above). So iterate
        // over the answers in state, which are in order, and find their id that way.
        updatedReferenceCheck.answers = updatedReferenceCheck.answers.map(answer => {
          if (isAttachment(answer)) {
            return answerMapping[answer.question_id];
          } else {
            // find answer using question_id and add the id to our answer in state
            const answerWithId = newReferenceCheck.answers.find(
              newAnswer => newAnswer.question_id === answer.question_id
            );
            return {
              id: answerWithId.id,
              ...answer,
            };
          }
        });

        // now that all answers have an id, save the answer order
        await referenceCheckAPI.setAnswerOrder(updatedReferenceCheck);

        setSubmitted(true);
      });
  };

  const getFormData = answer => {
    const formData = new FormData();
    if (answer.attachment instanceof File) {
      formData.append('attachment', answer.attachment, answer.attachment.name);
    } else {
      // file is empty
      formData.append('attachment', '');
    }
    formData.append('is_required', answer.is_required);
    formData.append('question_id', answer.question_id);
    formData.append('question_type', answer.question_type);
    formData.append('question_text', answer.question_text);
    formData.append('attachment_directions', answer.attachment_directions);
    return formData;
  };

  return (
    <div className="scorecard-request">
      <ReferenceEditorQuestions
        referenceCheck={referenceCheck}
        updateField={updateField}
        updateFinalRecommendation={updateFinalRecommendation}
        updateMultipleChoiceField={updateMultipleChoiceField}
        updateRating={updateRating}
        updateRubricSelection={updateRubricSelection}
        uploadAttachment={uploadAttachment}
        removeAttachment={removeAttachment}
      />

      <FooterButtonsGridContainer justifyItems={['start', 'start', 'end']}>
        <ErrorText message={errorMessage} />
        <SubmitButton width={[1, 1, '186px']} onClick={onSave} disabled={errorMessage !== ''}>
          {'Complete & Submit'}
        </SubmitButton>
      </FooterButtonsGridContainer>
    </div>
  );
}

const SubmitButton = styled.button(
  {
    height: '50px',

    background: '#00B88D',
    borderRadius: '2px',
    border: 'none',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '22px',

    color: '#ffffff',
    justifySelf: 'end',
  },
  layout
);

const FooterButtonsGridContainer = styled.div(
  {
    display: 'grid',
    gridGap: '16px',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
  },
  flexbox
);
