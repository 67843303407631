export const PROD_URL = 'app.hirenimble.com';
export const STAGING_URL = 'dev.hirenimble.com';

export const nimbleConnectUrlFlag = 'connect';

export const MISSOURI_STATE_CODE = 'mo';

export const CAROUSEL_SLIDE_TYPES = {
  IMAGE_WITH_TEXT: 'image_with_text',
  QUOTE: 'quote',
};
