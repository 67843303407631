import { Component } from 'react';

import PropTypes from 'prop-types';

import ReactTooltip from 'react-tooltip';

import CustomFieldModal from './CustomFieldModal';

import { customFieldPermission } from '../../utils/enums';
import { DeleteButton } from 'sharedComponents/Buttons/DeleteButton';
import { EditButton } from 'sharedComponents/Buttons/EditButton';
import { SettingsRow } from 'components/SettingsRow';

const fieldPermission = customFieldPermission().reduce((obj, item) => {
  obj[item.label] = item.value;
  return obj;
}, {});

export default class CustomProfileFieldRow extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    fieldObj: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    adminUsers: PropTypes.array.isRequired,
  };

  state = {
    showModal: false,
  };

  saveRow = fieldObj => {
    this.setState({ showModal: false });
    this.props.onSave(fieldObj, this.props.index);
  };

  deleteObject = e => {
    e.stopPropagation();
    this.props.onDelete(this.props.fieldObj.id);
  };

  renderShownOn = fieldObj => {
    let str = '';
    if (fieldObj.visibility_qv === true) {
      str += 'Quick view';

      if (fieldObj.visibility_fp === true) {
        str += ' + profile';
      }
    } else if (fieldObj.visibility_fp === true) {
      str += 'Profile';
    }

    return str;
  };

  renderAccessUsers = (fieldObj, permission) => {
    let {
      super_admin_access,
      district_admin_access,
      district_user_access,
      school_admin_access,
      individual_users_view_only,
      individual_users_edit_and_view,
    } = fieldObj;

    // turns to true once the first user or user type is added. The rest will comprise the
    // "and 4 others" part, as well as the tooltip str (visible on hover).
    let strFilled = false;
    let str = '';
    let tooltipStr = '';
    // will be used for the "and 4 others" section.
    let count = 0;
    // don't need to add super admins to count since they will always be listed
    // if included.
    // eg "Super admins + 3 others"
    if (super_admin_access === permission) {
      str += 'Super admins';
      strFilled = true;
    }
    if (district_admin_access === permission) {
      if (strFilled === false) {
        str += 'District admins';
        strFilled = true;
      } else {
        tooltipStr += ', District admins';
        count += 1;
      }
    }
    if (district_user_access === permission) {
      if (strFilled === false) {
        str += 'District users';
        strFilled = true;
      } else {
        tooltipStr += ', District users';
        count += 1;
      }
    }
    if (school_admin_access === permission) {
      if (strFilled === false) {
        str += 'School admins';
        strFilled = true;
      } else {
        tooltipStr += ', School admins';
        count += 1;
      }
    }

    if (permission === fieldPermission['View only'] && individual_users_view_only.length > 0) {
      individual_users_view_only.forEach(u => {
        if (strFilled === false) {
          str += u.name;
          strFilled = true;
        } else {
          tooltipStr += ', ' + u.name;
          count += 1;
        }
      });
    }

    if (
      permission === fieldPermission['View & edit'] &&
      individual_users_edit_and_view.length > 0
    ) {
      individual_users_edit_and_view.forEach(u => {
        if (strFilled === false) {
          str += u.name;
          strFilled = true;
        } else {
          tooltipStr += ', ' + u.name;
          count += 1;
        }
      });
    }

    // remove comma from beginning
    tooltipStr = tooltipStr.slice(2);

    return (
      <span>
        {str}
        {count > 0 && (
          <span>
            <span data-tip data-for={`custom-field-${fieldObj.id}`}>
              &nbsp;and {count} more
            </span>
            <ReactTooltip id={`custom-field-${fieldObj.id}`} effect="solid">
              {tooltipStr}
            </ReactTooltip>
          </span>
        )}
      </span>
    );
  };

  render() {
    let { fieldObj } = this.props;

    return (
      <>
        <SettingsRow
          cta={
            <>
              <EditButton />
              <DeleteButton onClick={this.deleteObject} />
            </>
          }
          onClick={() => this.setState({ showModal: true })}
        >
          <div className="status-label cp-title">{fieldObj.title}</div>
          <span className="cp-shown-on">{this.renderShownOn(fieldObj)}</span>
          <span className="cp-edit">
            {this.renderAccessUsers(fieldObj, fieldPermission['View & edit'])}
          </span>
          <span className="cp-view" style={{ flex: '0 0 20%' }}>
            {this.renderAccessUsers(fieldObj, fieldPermission['View only'])}
          </span>
        </SettingsRow>
        {this.state.showModal && (
          <CustomFieldModal
            fieldObj={fieldObj}
            show={this.state.showModal}
            onHide={() => this.setState({ showModal: false })}
            onSave={this.saveRow}
            adminUsers={this.props.adminUsers}
          />
        )}
      </>
    );
  }
}
