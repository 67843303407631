import { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import auth from 'utils/auth';
import { checkInternal } from 'utils/util';
import { getApplicationForViewByPosting } from 'utils/candidateUtils';
import { appStatusType, colorMapping } from 'utils/enums';
import { prospectStatus } from 'utils/prospectenums';

import CandidateNotes from './CandidateNotes';
import ScorecardModal from 'components/ScorecardModal';

import rightCaret from 'assets/icon_right_caret_grey.svg';
import plusSign from 'assets/icon-plus-with-circle-grey.svg';
import minusSign from 'assets/icon-minus-with-circle-grey.svg';
import internalIcon from 'assets/icons/icon-internal.svg';
import ReactTooltip from 'react-tooltip';
import {
  DocumentIcon,
  GreenDocumentIcon,
  GreenScorecardIcon,
  StarIcon,
  RefreshIcon,
} from 'ui-kit/icons';
import { ReactComponent as ScorecardIcon } from 'assets/icon-scorecard.svg';
import { withRouter } from 'react-router-dom';

class CandidateRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showScorecardModal: false,
      showNotes: false,
    };

    this.urlPrefix = auth.isSchoolUser() ? '/school/' : '/district/';
    this.isSchoolUser = auth.isSchoolUser();
    this.checkInternal = checkInternal.bind(this);
  }

  static propTypes = {
    toggleQuickProfile: PropTypes.func.isRequired,
    candidate: PropTypes.object.isRequired,
    toggleShowingApplications: PropTypes.func,
    isRequisition: PropTypes.bool,
    isSchoolUser: PropTypes.bool,
    viewByPostingRoleId: PropTypes.number,
    selected: PropTypes.bool.isRequired,
    toggleCandidateSelect: PropTypes.func.isRequired,
    districtRoleId: PropTypes.string,
    onProspectList: PropTypes.bool,
    adminUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
    index: PropTypes.number.isRequired,
    // not required in View All
    incrementNoteCount: PropTypes.func,
    incrementScorecardCount: PropTypes.func,
  };

  componentDidMount() {
    ReactTooltip.rebuild();
  }

  getCandidateStatus() {
    let { candidate } = this.props;
    // Limit to the applications just at this school if school admin
    let allApps = candidate.applications;
    if (
      this.isSchoolUser &&
      candidate.allRelevantApplicationsForSchoolAdmin &&
      candidate.allRelevantApplicationsForSchoolAdmin.length > 0
    ) {
      allApps = candidate.allRelevantApplicationsForSchoolAdmin;
    }

    let allAppsCopy = allApps.slice().map(a => {
      if (a.new_status.status_type === appStatusType.archived) {
        a.new_status._order = -3;
      }
      return a;
    });

    let furthestStatus = null;
    if (allAppsCopy.length > 0) {
      furthestStatus = Math.max(...allAppsCopy.map(a => a.new_status._order));
    }
    // find furthest new_status
    let furthestStatusApp = allAppsCopy.find(a => a.new_status._order === furthestStatus);
    let furthestStatusObj = furthestStatusApp && furthestStatusApp.new_status;

    return (
      furthestStatusObj && (
        <span className="candidate-status overflow-ellipsis">
          <span
            className={'status-circle ' + colorMapping[furthestStatusObj.color] + ' inline-block'}
            style={{ marginBottom: '1px' }}
          />
          <span className="furthest-status">{furthestStatusObj.label}</span>
        </span>
      )
    );
  }

  getCandidateStatusViewByPosting(applicationOnViewByPosting) {
    let statusColorSetting = colorMapping[applicationOnViewByPosting.new_status.color];
    return (
      <span className="candidate-status">
        <span
          className={`status-circle ${statusColorSetting} inline-block`}
          style={{ marginBottom: '-1px' }}
        />
        {applicationOnViewByPosting.new_status.label}
      </span>
    );
  }

  toggleQuickProfile(event, applicationOnViewByPosting, candidate) {
    // Don't try to open the application list (it's hidden)
    event.preventDefault();
    event.stopPropagation();
    // Pass along the one application viewing to quickview
    this.props.toggleQuickProfile(applicationOnViewByPosting, candidate);
  }

  handleClick = (event, candidate, applicationOnViewByPosting) => {
    if (event.target.name === 'selected') {
      return;
    }

    if (this.props.isRequisition || this.props.onProspectList) {
      this.toggleQuickProfile(event, applicationOnViewByPosting, candidate);
    } else {
      this.props.toggleShowingApplications(candidate, event);
    }
  };

  renderStatus = applicationOnViewByPosting => {
    if (this.props.isRequisition) {
      return this.getCandidateStatusViewByPosting(applicationOnViewByPosting);
    } else if (!this.props.onProspectList) {
      return this.getCandidateStatus();
    } else {
      return this.getProspectStatus();
    }
  };

  getProspectStatus = () => {
    const prospectStatusObjects = this.prospectStatusObject();
    const statusObject = prospectStatusObjects[this.props.candidate.status];
    return this.getProspectStatusJSX(statusObject);
  };

  prospectStatusObject = () =>
    prospectStatus().reduce((obj, item) => {
      obj[item.value] = {
        color: item.color,
        label: item.label,
      };
      return obj;
    }, {});

  getProspectStatusJSX = statusObject => (
    <span className="candidate-status overflow-ellipsis">
      <span
        className={'status-circle ' + statusObject.color + ' inline-block'}
        style={{ marginBottom: '-1px' }}
      />
      <span className="furthest-status">{statusObject.label}</span>
    </span>
  );

  getProspectSource = () => {
    let source = this.props.candidate.district_source;
    if (!source || source === 'N/A' || source === 'none') {
      return '';
    } else {
      return source.label;
    }
  };

  getClassNameProspectSource = () => {
    let source = this.props.candidate.district_source;
    if (source.label === 'Talent Marketplace') {
      return 'talent-marketplace';
    } else {
      return '';
    }
  };

  getApplications = () => {
    return this.isSchoolUser
      ? this.props.candidate.allRelevantApplicationsForSchoolAdmin || []
      : this.props.candidate.applications;
  };

  getIconsToShow = () => {
    const applications = this.getApplications() ?? [];

    let showStarIcon = false;
    let showRetentionIcon = false;

    applications.forEach(a => {
      if (a.top_candidate === true) {
        showStarIcon = true;

        // only show high retention nudge for top candidates
        if (a.high_retention === true) {
          showRetentionIcon = true;
        }
      }
    });

    return {
      showStarIcon,
      showRetentionIcon,
    };
  };

  handleScorecardClick = e => {
    e.stopPropagation();

    const isLinkedToAuthUser = (auth.getUser().linked_users || []).includes(
      this.props.candidate.id
    );

    // Only open the scorecard modal if on view by posting page. The scorecard
    // modal requires knowledge of an application in order to get job title and
    // application attachments used in the scorecard request process. If a user
    // clicked the icon on candidates > view all, we would't know which application
    // they wanted to use.
    if (this.props.isRequisition && !isLinkedToAuthUser) {
      this.setState({ showScorecardModal: true });
    }
  };

  handleNoteClick = e => {
    e.stopPropagation();

    const isLinkedToAuthUser = (auth.getUser().linked_users || []).includes(
      this.props.candidate.id
    );

    // Only open the notes modal if on view by posting page. The notes
    // modal requires knowledge of an application in order to create a link to
    // the candidate's full profile when they tag someone. If a user
    // clicked the icon on candidates > view all, we would't know which application
    // they wanted to use.
    if (this.props.isRequisition && !isLinkedToAuthUser) {
      this.setState({ showNotes: true });
    }
  };

  closeNotes = () => {
    this.setState({ showNotes: false });
  };

  getScorecardCount = applicationOnViewByPosting => {
    let scorecardCount = 0;
    scorecardCount += this.props.candidate.scorecard_count;

    if (this.props.isRequisition && applicationOnViewByPosting) {
      scorecardCount += applicationOnViewByPosting.district_application
        ? applicationOnViewByPosting.district_application.scorecard_count
        : applicationOnViewByPosting.scorecard_count;
    }

    return scorecardCount;
  };

  render() {
    const { candidate } = this.props;

    if (!candidate.profile && !this.props.onProspectList) {
      return <div />;
    }

    const applications = this.getApplications();
    const { showStarIcon, showRetentionIcon } = this.getIconsToShow();

    // Set the application viewing for easier access if on view by posting page (there will only be one relevant app)
    let applicationOnViewByPosting;
    if (this.props.isRequisition) {
      applicationOnViewByPosting = getApplicationForViewByPosting(candidate, this.isSchoolUser);
    }

    let updated = this.props.onProspectList ? candidate.modified : candidate.profile.updated;

    const isLinkedToAuthUser = (auth.getUser().linked_users || []).includes(candidate.id);

    const scorecardCount = this.getScorecardCount(applicationOnViewByPosting);

    return (
      <Row
        onClick={event => {
          this.handleClick(event, candidate, applicationOnViewByPosting);
        }}
        onProspectList={this.props.onProspectList}
        onRequisitionList={this.props.isRequisition}
      >
        <div className="candidate-select-box">
          <input
            disabled={isLinkedToAuthUser}
            type="checkbox"
            name="selected"
            value={this.props.selected || ''}
            checked={this.props.selected || ''}
            onChange={e => this.props.toggleCandidateSelect(e, candidate.id)}
          />
        </div>

        <div className="full-name overflow-ellipsis">{candidate.name}</div>

        {!this.props.onProspectList && (
          <InsightsGridContainer>
            <FirstColumn>
              {showStarIcon && (
                <div data-tip data-for="top-candidate" data-place="bottom" data-delay-show="100">
                  <StarIcon />
                </div>
              )}
            </FirstColumn>
            <SecondColumn>
              {showRetentionIcon && (
                <div data-tip data-for="high-retention" data-place="bottom" data-delay-show="100">
                  <RefreshIcon />
                </div>
              )}
            </SecondColumn>
          </InsightsGridContainer>
        )}

        {this.renderStatus(applicationOnViewByPosting)}

        {!this.props.isRequisition && !this.props.onProspectList && (
          <span className="candidate-app-count">
            <div>
              {applications.length}
              {this.checkInternal(candidate, auth.getUser().profile.district.id, false) && (
                <div
                  className="internal-icon pointer"
                  data-tip
                  data-for="internal"
                  data-place="bottom"
                  data-delay-show="100"
                >
                  <img src={internalIcon} alt="internal" />
                </div>
              )}
            </div>
          </span>
        )}

        {this.props.onProspectList && (
          <div className="candidate-action source overflow-ellipsis">
            <span className={this.getClassNameProspectSource()}>{this.getProspectSource()}</span>
          </div>
        )}

        <span className="candidate-action">
          {moment(updated)
            .fromNow()
            .replace('months', 'mos')}
        </span>

        {!this.props.onProspectList && !isLinkedToAuthUser && (
          <IconContainer>
            {this.props.isRequisition && (
              <>
                {scorecardCount > 0 ? (
                  <GreenScorecardIcon
                    data-tip={scorecardCount}
                    data-for="scorecard_count"
                    data-place="bottom"
                    data-delay-show="100"
                    style={{ width: '18px', height: '18px' }}
                    onClick={this.handleScorecardClick}
                  />
                ) : (
                  <ScorecardIcon
                    style={{ width: '18px', height: '18px' }}
                    onClick={this.handleScorecardClick}
                  />
                )}
              </>
            )}

            <RelativeDiv>
              {candidate.note_count > 0 ? (
                <GreenDocumentIcon
                  data-tip={candidate.note_count}
                  data-for="note_count"
                  data-place="bottom"
                  data-delay-show="100"
                  style={{ width: '17px', height: '18px' }}
                  onClick={this.handleNoteClick}
                />
              ) : (
                <DocumentIcon
                  style={{ width: '17px', height: '18px' }}
                  onClick={this.handleNoteClick}
                />
              )}
              {this.state.showNotes && (
                <CandidateNotes
                  candidate={candidate}
                  application={applicationOnViewByPosting}
                  onClose={this.closeNotes}
                  adminUsers={this.props.adminUsers}
                  index={this.props.index}
                  incrementNoteCount={this.props.incrementNoteCount}
                />
              )}
            </RelativeDiv>
          </IconContainer>
        )}

        <span className="candidate-app-expander">
          {this.props.isRequisition || this.props.onProspectList ? (
            <img src={rightCaret} alt="arrow" />
          ) : (
            <img
              style={candidate.expanded ? { marginBottom: '4px' } : {}}
              src={candidate.expanded ? minusSign : plusSign}
              alt="plus sign"
            />
          )}
        </span>
        {this.state.showScorecardModal && (
          <ScorecardModal
            user={candidate}
            application={applicationOnViewByPosting}
            show={this.state.showScorecardModal}
            onHide={() => this.setState({ showScorecardModal: false })}
            adminUsers={this.props.adminUsers}
            incrementScorecardCount={this.props.incrementScorecardCount}
          />
        )}
      </Row>
    );
  }
}

export default withRouter(CandidateRow);

const Row = styled.div`
  display: grid;
  grid-template-columns: ${({ onProspectList }) =>
    onProspectList ? '70px 1fr 1fr' : '70px 2fr 1fr 2fr'};
  align-items: center;

  padding: 0 8px;
  height: 48px;
  min-width: 500px;
  font-size: 15px;
  color: rgba(57, 60, 73, 0.8);
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;

  &:hover {
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.1);
  }

  ${({ onRequisitionList }) =>
    onRequisitionList ? 'span.candidate-app-expander { top: 11px; }' : ''}

  @media screen and (min-width: 768px) {
    grid-template-columns: ${({ onProspectList }) =>
      onProspectList ? '70px 1.5fr 1.5fr 1fr 1fr' : '70px 1.5fr 1fr 1.5fr 1fr 1fr'};
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: ${({ onProspectList, onRequisitionList }) =>
      onProspectList
        ? '70px 160px 159px 175px 1fr'
        : onRequisitionList
        ? '70px 175px 105px 192px 125px 30px 1fr'
        : '70px 168px 98px 136px 90px 125px 30px 1fr'};
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: ${({ onProspectList, onRequisitionList }) =>
      onProspectList
        ? '70px 200px 191px 240px 1fr'
        : onRequisitionList
        ? '70px 225px 105px 250px 125px 30px 1fr'
        : '70px 185px 105px 190px 95px 125px 1fr'};
  }
`;

const InsightsGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 30px);
  grid-gap: 12px;
  height: 18px;
`;

const FirstColumn = styled.div`
  grid-column: 1 / span 1;
`;

const SecondColumn = styled.div`
  grid-column: 2 / span 1;
`;

const RelativeDiv = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
`;

const IconContainer = styled.div`
  // don't show icons on smaller screens because they don't fit on the line item.
  // users can click into the quick profile to access notes and scorecards.
  display: none;

  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 18px 18px;
    grid-gap: 10px;
  }
`;
