import { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorText from '../errortext';
import auth from 'utils/auth';
import searchGlassIcon from '../../assets/icon_search_glass_white.svg';
import Button from '../common/Button';
import { Link, withRouter } from 'react-router-dom';

class CandidatesListHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasProspectModule: false,
    };
  }
  static propTypes = {
    daysAgo: PropTypes.string,
    setCurrentQuery: PropTypes.func.isRequired,
    totalCandidateCount: PropTypes.number.isRequired,
    onProspectList: PropTypes.bool.isRequired,
    onViewByPosting: PropTypes.bool.isRequired,
    query: PropTypes.string.isRequired,
    resetFilters: PropTypes.func.isRequired,
    elasticSearchError: PropTypes.bool.isRequired,
    onTalentMarketplace: PropTypes.bool.isRequired,
    defaultSort: PropTypes.string.isRequired,
    defaultOrder: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const hasProspectModule = auth.getUser()?.profile?.district.has_prospects_module;
    this.setState({ hasProspectModule: hasProspectModule });
  }

  onKeyPress = event => {
    if (event.key === 'Enter') {
      this.props.setCurrentQuery({ query: this.props.query }, true);
    }
  };

  render() {
    const { daysAgo } = this.props;

    return (
      <div style={{ width: '100%', padding: '0 48px' }}>
        <div className="candidate-count-div" style={{ marginTop: '22px' }}>
          <div className="candidate-count-inner-div">
            <div className="mr2">
              {`${this.props.totalCandidateCount} ${
                this.props.onProspectList
                  ? 'Prospect'
                  : this.props.onTalentMarketplace
                  ? 'Talent Profile'
                  : 'Candidate'
              }${this.props.totalCandidateCount !== 1 ? 's' : ''}`}
            </div>
            {daysAgo && (
              <div className="cl-filter-tag">
                <div className="cl-filter-tag-left">{`${daysAgo}+ days`}</div>
                <div
                  className="cl-filter-tag-right"
                  onClick={() => {
                    this.props.setCurrentQuery({ days_ago: '' }, true);
                  }}
                >
                  x
                </div>
              </div>
            )}
          </div>
          <div>
            <button className="clear-all-filters" onClick={this.props.resetFilters}>
              Clear All Filters
            </button>
            {this.props.onProspectList && this.state.hasProspectModule && (
              <Link to="/prospectentry" rel="noopener noreferrer" target="_blank" className="ml1">
                <Button className="bold">+ New Prospect</Button>
              </Link>
            )}
          </div>
        </div>
        <div
          className={`candidateslist-search-container ${this.props.onViewByPosting ? '' : 'mb1'}`}
        >
          <div className="search-box-div">
            <input
              type="text"
              id="cl-search-input"
              placeholder={`Search by candidate or keyword`}
              value={this.props.query}
              onChange={event => {
                const queryObject = {
                  query: event.target.value,
                };

                if (queryObject.query) {
                  // Clear the sort when the user adds a search term because the results
                  // will be ordered by search relevance.
                  queryObject.orderBy = '';
                } else {
                  // When clearing the search term, set the sort to its default

                  queryObject.orderBy = this.props.defaultSort;
                  queryObject.orderDir = this.props.defaultOrder;
                }

                this.props.setCurrentQuery(queryObject);
              }}
              onKeyPress={this.onKeyPress}
              data-hj-whitelist // Hotjar whitelist
            />
          </div>
          <button
            className="cl-search-btn"
            onClick={() => this.props.setCurrentQuery({ query: this.props.query }, true)}
          >
            <img src={searchGlassIcon} alt="search" />
          </button>
        </div>
        {this.props.elasticSearchError && (
          <div className="mb1">
            <ErrorText message="Please narrow your search in order to see results." />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(CandidatesListHeader);
