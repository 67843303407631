export function getApplicationForViewByPosting(candidate, isSchoolUser) {
  // Determine which application to show on View by Posting.
  // The backend has been refactored to only send back the application and/or
  // schoolapplication that relates to the role the user is viewing. If the
  // candidate has a schoolapplication and is being viewed by a school admin,
  // show it, else show the district application.
  if (isSchoolUser && candidate.schoolapplications?.length > 0) {
    return candidate.schoolapplications[0];
  }

  return candidate.applications[0];
}

export const getATSCredentials = credentials => {
  if (credentials && Array.isArray(credentials)) {
    return credentials.filter(
      credential => !credential.subject || credential.subject.show_in_ats === true
    );
  } else {
    return [];
  }
};
