import './bulkemail.css';

import { Component } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { bytesToSize } from '../../utils/util';
import { DropdownWithInputFilter, Input, Label, EmailSelect } from 'ui-kit';
import { handleVariableClick } from '../../utils/emailutils';
import { EmailTemplateVariables } from '../EmailTemplates/EmailTemplateVariables';
import ErrorText from '../errortext';
import iconClose from '../../assets/icon-close-x.svg';
import iconPaperclip from '../../assets/icon-paperclip.svg';
import { UploadFileHelperMessage } from 'components/uploadFileHelperMessage';

export default class BulkEmailCompose extends Component {
  static propTypes = {
    emailTemplate: PropTypes.object.isRequired,
    emailTemplatesList: PropTypes.array.isRequired,
    updateEmailTemplateSelection: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleUploads: PropTypes.func.isRequired,
    subjectError: PropTypes.bool,
    messageError: PropTypes.bool,
    jobTitle: PropTypes.string.isRequired,
    onProspectList: PropTypes.bool.isRequired,
    selectionSize: PropTypes.number,
    cc: PropTypes.array,
    bcc: PropTypes.array,
    handleCc: PropTypes.func,
    handleBcc: PropTypes.func,
    searchOptions: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const cc = props.cc || [];
    const bcc = props.bcc || [];

    this.handleVariableClick = handleVariableClick.bind(this);
    this.state = {
      uploads: [],
      // To field can't be changed in compose. only trimmed down on next step:
      to: [
        {
          label: `${props.selectionSize} ${this.props.onProspectList ? 'Prospect' : 'Candidate'}${
            this.props.selectionSize === 1 ? '' : 's'
          }`,
          value: '',
          isValid: true,
        },
      ],
      // Other fields pass through
      cc: cc,
      isCcVisible: cc.length > 0,
      bcc: bcc,
      isBccVisible: bcc.length > 0,
    };
  }

  getCandidateVariables = () => {
    let arr = [];
    if (this.props.onProspectList) {
      ['Prospect first name', 'Prospect last name'].forEach(field => arr.push(field));
    } else {
      [
        'Candidate first name',
        'Candidate last name',
        'Candidate phone',
        'Job title',
      ].forEach(field => arr.push(field));
    }

    return arr;
  };

  clearEmailTemplate = () => {
    this.props.updateEmailTemplateSelection({
      target: { value: null },
    });
  };

  handleEmailTemplateChange = value => {
    // updateEmailTemplateSelection calls e.target.value,
    // so format this argument accordingly.
    this.props.updateEmailTemplateSelection({
      target: { value },
    });
  };

  /* ---- attachment functions ---- */
  clickUploadInput = () => {
    this.upload.click();
  };

  uploadAttachment = event => {
    let attachment = event.target.files[0];
    let uploads = this.state.uploads;
    let uploadError = '';

    /* ensure no empty files are uploaded */
    if (attachment?.size === 0) {
      uploadError = 'Upload is empty, please try again';
      this.setState({ uploadError });
      return;
    }

    /* no reasonable file upload should be greater than 5MB in size */
    if (attachment.size > 5242880) {
      uploadError = 'Attachments must be smaller than 5MB';
      this.setState({ uploadError });
      return;
    }

    /* only proper file types can be uploaded */
    if (
      !['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png'].includes(
        attachment.name
          .split('.')
          .pop()
          .toLowerCase()
      )
    ) {
      uploadError = 'Please upload one of the following file types: .pdf, .doc, .docx, .jpg, .png';
      this.setState({ uploadError });
      return;
    }

    /**
     * ~ 30 characters are prepended to file name (/attachments/None/(timestamp)), and max_length
     * is 255, so ensure input file name is no more than 215 characters, to be safe.
     */

    if (attachment.name.length > 215) {
      uploadError = 'Please ensure all attachment file names are fewer than 215 characters';
      this.setState({ uploadError });
      return;
    }

    // if user clicks cancel, attachment will be undefined
    if (attachment) {
      uploads.push(attachment);
      this.props.handleUploads(uploads);
      this.setState({ uploadError: '' });
    }
  };

  removeAttachment = index => {
    let uploads = this.state.uploads;
    uploads.splice(index, 1);
    this.setState({ uploads, uploadError: '' });
  };
  /* ---- end attachment functions ---- */

  setCcList = options => {
    this.props.handleCc(options);
    this.setState({ cc: options });
  };

  setBccList = options => {
    this.props.handleBcc(options);
    this.setState({ bcc: options });
  };

  render() {
    return (
      <div>
        <div className="bulk-email-padding">
          <Label>Select Template</Label>
          <DropdownWithInputFilter
            placeholder="Type or use drop down to select template"
            options={this.props.emailTemplatesList
              .filter(e => e.reference_check === false)
              .map(template => ({ value: template.id, label: template.title }))}
            value={this.props.emailTemplate?.id ?? ''}
            handleChange={this.handleEmailTemplateChange}
            onClear={this.clearEmailTemplate}
            boxShadow={false}
          />
        </div>
        <div className="bulk-email-padding">
          <Label>Recipients</Label>

          <RecipientsControl>
            {!this.state.isCcVisible && (
              <button
                onClick={() => {
                  this.setState({ isCcVisible: !this.state.isCcVisible });
                }}
              >
                + Add Cc
              </button>
            )}

            {!this.state.isBccVisible && (
              <button onClick={() => this.setState({ isBccVisible: !this.state.isBccVisible })}>
                + Add Bcc
              </button>
            )}
          </RecipientsControl>

          <RecipientsContainer>
            <SelectLabel>To:</SelectLabel>
            <EmailSelect initialOptions={this.state.to} isDisabled={true} />

            {this.state.isCcVisible && (
              <>
                <SelectLabel>Cc:</SelectLabel>
                <EmailSelect
                  initialOptions={this.state.cc}
                  loadOptions={this.props.searchOptions}
                  onChange={this.setCcList}
                />
              </>
            )}

            {this.state.isBccVisible && (
              <>
                <SelectLabel>Bcc:</SelectLabel>
                <EmailSelect
                  initialOptions={this.state.bcc}
                  loadOptions={this.props.searchOptions}
                  onChange={this.setBccList}
                />
              </>
            )}
          </RecipientsContainer>
        </div>

        <div className="bulk-email-padding">
          <Label>Subject Line</Label>
          <Input
            width="100%"
            type="text"
            name="subject"
            placeholder="Email subject"
            value={this.props.emailTemplate.subject || ''}
            onChange={this.props.handleChange}
            boxShadow={false}
          />
          {this.props.subjectError && <ErrorText message="Please add a subject" />}
        </div>

        <div className="bulk-email-padding">
          <div className="bulk-email-content-container">
            <div className="template-content-div relative">
              <StyledReactQuill
                ref={el => {
                  this.quillRef = el;
                }}
                type="text"
                name="content"
                placeholder={`Email message`}
                value={this.props.emailTemplate.content || ''}
                onChange={value => this.props.handleChange(value)}
              />

              <UploadButton>
                <input
                  ref={input => (this.upload = input)}
                  type="file"
                  style={{ height: '0px', width: '0px', overflow: 'hidden' }}
                  onChange={this.uploadAttachment}
                  multiple
                />
                <img
                  className="pointer mr1"
                  src={iconPaperclip}
                  alt="attach file"
                  onClick={this.clickUploadInput}
                  data-tip
                  data-for="email-attachment"
                />

                <ReactTooltip id="email-attachment" effect="solid">
                  <span>Attach files</span>
                </ReactTooltip>
              </UploadButton>
            </div>
            <EmailTemplateVariables
              handleVariableClick={this.handleVariableClick}
              candidateVariables={this.getCandidateVariables()}
              senderVariables={[
                'Sender first name',
                'Sender last name',
                'School name',
                'District name',
              ]}
              onProspectList={this.props.onProspectList}
            />
          </div>
          {this.props.messageError && <ErrorText message="Please add a message" />}

          {this.state.uploads.map((upload, i) => (
            <AttachmentRow key={i}>
              <div>
                <AttachmentName>{upload.name}</AttachmentName>
                &nbsp;&nbsp;
                <span>({bytesToSize(upload.size)})</span>
              </div>
              <img
                style={{ width: '10px', cursor: 'pointer' }}
                src={iconClose}
                alt="close"
                onClick={() => this.removeAttachment(i)}
              />
            </AttachmentRow>
          ))}
          {this.state.uploadError && <ErrorText message={this.state.uploadError} />}
          <UploadFileHelperMessage />
        </div>
      </div>
    );
  }
}

const RecipientsContainer = styled.div`
  width: 91%;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  padding: 5px 5px 0;
`;

const RecipientsControl = styled.div`
  float: right;
  width: 65px;

  & > button {
    color: #00b88d;
    display: block;
    border: none;
    background: none;
    margin: 0;
    padding: 0;
  }
`;

const SelectLabel = styled.span`
  display: inline-box;
  line-height: 24px;
  font-size: 14px;

  margin-left: 5px;
  width: 30px;
  float: left;
`;

const StyledReactQuill = styled(ReactQuill)`
  border: 1px solid #dcdcdc;
  background: #fff;
  padding-bottom: 25px;
  border-radius: 3px;
  max-width: 630px;

  &:hover {
    border-color: #dcdcdc !important;
  }

  .ql-editor {
    min-height: 360px;
  }

  .ql-editor.ql-blank::before {
    color: rgba(57, 60, 73, 0.3);
  }
`;

const UploadButton = styled.div`
  position: absolute;
  bottom: 10px;
  left: 20px;
`;

const AttachmentRow = styled.div`
  font-size: 13px;
  background-color: #f4f4f4;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  padding: 7px 10px;
  margin-right: 236px;

  &:first-child {
    margin-top: 40px;
  }
`;

const AttachmentName = styled.span`
  max-width: 300px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: bottom;
`;
