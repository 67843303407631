import styled from 'styled-components';

import { Body } from 'ui-kit/Modal';
import { Input } from 'ui-kit';
import { AttachmentIcon } from 'ui-kit/icons';

import HeaderInformation from './HeaderInformation';

export default function Attachment({
  questionText,
  setQuestionText,
  attachmentDirections,
  setAttachmentDirections,
  internalRequirementsSpecified,
  handleItemMatrixChange,
  itemMatrix,
}) {
  return (
    <Body>
      <HeaderInformation
        Icon={AttachmentIcon}
        title="Attachment"
        subtext="Allows the candidate to upload a file like a cover letter"
        internalRequirementsSpecified={internalRequirementsSpecified}
        handleItemMatrixChange={handleItemMatrixChange}
        itemMatrix={itemMatrix}
      />
      <Header>Attachment name</Header>
      <Input
        type="text"
        value={questionText}
        placeholder="e.g. Cover letter"
        onChange={e => setQuestionText(e.target.value)}
        maxLength="55"
        width={1}
      />
      <HeaderWithMarginTop>Directions to the candidate</HeaderWithMarginTop>
      <DirectionsTextarea
        value={attachmentDirections}
        placeholder="e.g. Please share a one page document telling us why you’d like to work here"
        onChange={e => setAttachmentDirections(e.target.value)}
      />
    </Body>
  );
}

const Header = styled.p`
  font-size: 16px;
  color: #444444;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 6px;
`;

const HeaderWithMarginTop = styled(Header)`
  margin-top: 1rem;
`;

const DirectionsTextarea = styled.textarea`
  background-color: #fff;
  padding: 15px;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  min-height: 150px;

  color: #8e8d8d;

  resize: vertical;

  &::placeholder {
    color: #999999;
    opacity: 0.4;
  }
`;
