import { Component } from 'react';
import PropTypes from 'prop-types';

import LanguageProficiencyFilterDrop from './LanguageProficiencyFilterDrop';

import iconUpCaretGreen from 'assets/icons/FilterIcons/icon-up-caret-with-circle-green.svg';
import iconDownCaretGreen from 'assets/icons/FilterIcons/icon-down-caret-with-circle-green.svg';
import iconUpCaret from 'assets/icons/FilterIcons/icon-up-caret-with-circle-grey.svg';
import iconDownCaret from 'assets/icons/FilterIcons/icon-down-caret-with-circle-grey.svg';

import { languageList, languageProficiency, languageProficiencyFilterMap } from 'utils/enums';

const proficiencyReverseMap = {
  0: 'a',
  1: 'b',
  2: 'c',
  3: 'd',
  4: 'e',
};

export default class CandidateLanguageFilter extends Component {
  static propTypes = {
    updateMultiSelectFilter: PropTypes.func.isRequired,
    updateMultiSelectFilterWithMultipleValues: PropTypes.func.isRequired,
    language_list: PropTypes.array.isRequired,
    selectAll: PropTypes.func.isRequired,
    clearAll: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
    };
  }

  toggleOptions = () => {
    this.setState((state, props) => ({ showOptions: !state.showOptions }));
  };

  selectAll = e => {
    e.stopPropagation();
    let language_list = [];

    // selectAll function pushes in object.value for each
    // object in the array. so nest each object inside the
    // 'value' field.
    languageList().forEach(l => {
      // if languageProficiency changes, make sure to change it here
      ['a', 'b', 'c', 'd', 'e'].forEach(i => {
        language_list.push({ value: l.value + i });
      });
    });
    this.props.selectAll('language_list', language_list);
  };

  clearAll = e => {
    e.stopPropagation();
    this.props.clearAll('language_list');
  };

  handleChange = (e, optionObject, option) => {
    if (!e.target.checked) {
      // user is unchecking the filter
      delete optionObject[option.value];
    } else {
      optionObject[option.value] = [0, 1, 2, 3, 4];
    }

    // push in strings in the following form: '1a', '1b', '999a'
    let language_list = [];
    for (let [key, valueArr] of Object.entries(optionObject)) {
      valueArr.forEach(v => {
        language_list.push(key + proficiencyReverseMap[v]);
      });
    }

    const optionsLength = languageList().length * languageProficiency().length;
    this.props.updateMultiSelectFilterWithMultipleValues(
      'language_list',
      language_list,
      optionsLength
    );
  };

  getProficiencyValues = option => {
    // filter to only proficiency values for the current language
    // eg: ['1a', '1b', '2b', '3c'] filters to ['1a', '1b'] for the english filter.
    // then remove the number so only the letter is left, and use
    // the mapping to map it to a proficiency number.
    // eg: '1c' -> 'c' -> 3
    return this.props.language_list
      .filter(item => Number(item.slice(0, -1)) === option.value)
      .map(item => languageProficiencyFilterMap()[item.slice(-1)]);
  };

  render() {
    /**
     * example language_list and resulting optionObject:
     * language_list = ['1a', '1b', '1d', '3c', '999a', '999d', '999e']
     * optionObject:
     * {
     *  1: [0,1,3],
     *  3: [2],
     *  999: [0,3,4]
     * }
     */
    let language_list = this.props.language_list.slice();
    let optionObject = {};

    let proficiency;
    let number;
    let letter;
    language_list.forEach(str => {
      //split each item into number and letter. 999a -> 999, a
      number = str.slice(0, -1); // 999
      letter = str.slice(-1); // a
      // change the letter into its languageProficiency number,
      // according to the languageProficiencyFilterMap
      proficiency = languageProficiencyFilterMap()[letter];
      // that number is a key in the storage object,
      // push the new proficiency number into that key's value,
      // which should be an array
      if (optionObject.hasOwnProperty(number)) {
        optionObject[number].push(proficiency);
      } else {
        optionObject[number] = [proficiency];
      }
    });

    // default is to have all filters selected, so we only need to show
    // the filter as active if they change something, which would make both allSelected
    // and noneSelected false.
    let allSelected = language_list.length === languageList().length * languageProficiency().length;
    let noneSelected = language_list.length === 0;

    let isActive = !(allSelected || noneSelected);

    return (
      <div className={`new-cl-filter ${isActive ? 'cl-active' : ''}`}>
        <div className="cl-filter-header" onClick={this.toggleOptions}>
          <p className={isActive ? 'cl-active' : ''}>Candidate Language</p>
          <img
            src={
              this.state.showOptions && isActive
                ? iconUpCaretGreen
                : !this.state.showOptions && isActive
                ? iconDownCaretGreen
                : this.state.showOptions
                ? iconUpCaret
                : iconDownCaret
            }
            alt=""
          />
        </div>
        <div className={`selection-list ${this.state.showOptions ? 'show' : ''}`}>
          <div className="option select-all">
            <span className="drop-select-all" onClick={this.selectAll}>
              Select All
            </span>
            <span className="drop-select-all" onClick={this.clearAll}>
              Clear All
            </span>
          </div>
          {languageList().map((option, i) => (
            <div key={i} className="option">
              <label className="container">
                <input
                  type="checkbox"
                  checked={optionObject.hasOwnProperty(option.value)}
                  onChange={e => this.handleChange(e, optionObject, option)}
                />
                <span className="checkmark" />
                {option.label}
              </label>
              {optionObject.hasOwnProperty(option.value) && (
                <LanguageProficiencyFilterDrop
                  updateMultiSelectFilter={this.props.updateMultiSelectFilter}
                  values={this.getProficiencyValues(option)}
                  languageEnum={option.value}
                  proficiencyReverseMap={proficiencyReverseMap}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
