import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import {
  referenceStatus,
  refContactType,
  refContactTypeLabel,
  salutation,
  refStatus,
} from '../../../utils/enums';
import Dropdown from 'react-dropdown';
import trashIcon from '../../../assets/icons/icon_trash.svg';
import moment from 'moment';

const salutationReadable = salutation().reduce((obj, item) => {
  obj[item.value] = item.label;
  return obj;
}, {});

export default class ReferenceRow extends Component {
  static propTypes = {
    row: PropTypes.number.isRequired,
    reference: PropTypes.object.isRequired,
    openModalByDistrictReference: PropTypes.func.isRequired,
    openModalForNewReference: PropTypes.func.isRequired,
    updateReference: PropTypes.func.isRequired,
    editMode: PropTypes.bool.isRequired,
    removeReference: PropTypes.func.isRequired,
    requests: PropTypes.array.isRequired,
    referenceChecks: PropTypes.array.isRequired,
    disableContactMethodDropdown: PropTypes.bool,
  };

  handleDropdownClick = option => {
    this.props.updateReference(this.props.row, 'contact_type', option.value);
    this.props.openModalForNewReference(option.value, this.props.reference);
  };

  formatPhoneNumber = phoneNumberString => {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumberString;
  };

  render() {
    let { reference, requests } = this.props;
    let map = referenceStatus()[reference.status];
    let salutation = `${
      salutationReadable[reference.salutation] ? salutationReadable[reference.salutation] + ' ' : ''
    }`;
    let fullName = `${salutation}${reference.name}`;
    let options = [];
    let phone = this.formatPhoneNumber(reference.phone);
    let lastRequest;
    for (let i = requests.length - 1; i >= 0; i--) {
      if (requests[i].email === reference.email) {
        lastRequest = requests[i];
        break;
      }
    }

    if (phone) {
      options.push({ value: refContactType.phone, label: `Phone: ${phone.substring(0, 7)}...` });
    }
    if (reference.email) {
      options.push({
        value: refContactType.email,
        label: `Email: ${reference.email.substring(0, 7)}...`,
      });
    }

    if (this.props.editMode === true) {
      return (
        <StyledRow>
          <td>
            <StyledInput
              value={reference.name || ''}
              onChange={e => this.props.updateReference(this.props.row, 'name', e.target.value)}
            />
          </td>
          <td>
            <StyledInput
              value={reference.title || ''}
              onChange={e => this.props.updateReference(this.props.row, 'title', e.target.value)}
            />
          </td>
          <td>
            <StyledInput
              value={reference.organization || ''}
              onChange={e =>
                this.props.updateReference(this.props.row, 'organization', e.target.value)
              }
            />
          </td>
          <td>
            <StyledInput
              value={reference.relationship || ''}
              onChange={e =>
                this.props.updateReference(this.props.row, 'relationship', e.target.value)
              }
            />
          </td>
          <td>
            <StyledInput
              className="years-known"
              value={reference.years_known || ''}
              type="number"
              min="0"
              max="100"
              onChange={e =>
                this.props.updateReference(this.props.row, 'years_known', e.target.value)
              }
            />
          </td>
          <td>
            <StyledInput
              value={reference.email || ''}
              type="email"
              onChange={e => this.props.updateReference(this.props.row, 'email', e.target.value)}
            />
          </td>
          <td>
            <StyledInput
              value={reference.phone || ''}
              onChange={e => this.props.updateReference(this.props.row, 'phone', e.target.value)}
            />
          </td>
          <td>
            <span className="">
              <img src={map.icon} alt="" />
              &nbsp;&nbsp;{map.label}
            </span>
          </td>
          <td>
            <ActionContainer>
              {reference.contact_type !== refContactType.none ? (
                <div>
                  <ReferenceMethodButton>
                    <ReferenceMethodText>
                      <div>{refContactTypeLabel[reference.contact_type]}</div>
                      <div
                        className="pointer"
                        onClick={() =>
                          this.props.updateReference(
                            this.props.row,
                            'contact_type',
                            refContactType.none
                          )
                        }
                      >
                        x
                      </div>
                    </ReferenceMethodText>
                  </ReferenceMethodButton>
                </div>
              ) : (
                <ReferenceDropdown>
                  <DropdownContainer>
                    <Dropdown
                      styles={{
                        control: base => ({
                          ...base,
                          height: 30,
                          minHeight: 30,
                        }),
                      }}
                      options={options}
                      onChange={option => this.handleDropdownClick(option)}
                      value={reference.contact_type}
                      placeholder="Select"
                      disabled={this.props.disableContactMethodDropdown}
                    />
                  </DropdownContainer>
                </ReferenceDropdown>
              )}
              <div
                onClick={() => this.props.removeReference(reference.id)}
                data-tip
                data-for="delete"
                data-place="bottom"
                data-delay-show="100"
              >
                <img src={trashIcon} alt="delete" />
                <ReactTooltip id="delete" effect="solid">
                  Delete
                </ReactTooltip>
              </div>
            </ActionContainer>
          </td>
        </StyledRow>
      );
    } else {
      return (
        <tr>
          <td>
            <StyledCell>
              <span data-tip data-for={`full-name-${reference.id}`}>
                {fullName || ''}
              </span>
            </StyledCell>
            {fullName && (
              <ReactTooltip id={`full-name-${reference.id}`} effect="solid" place="top">
                {fullName}
              </ReactTooltip>
            )}
          </td>
          <td>
            <StyledCell>
              <span data-tip data-for={`title-${reference.id}`}>
                {reference.title || ''}
              </span>
            </StyledCell>
            {reference.title && (
              <ReactTooltip id={`title-${reference.id}`} effect="solid" place="top">
                {reference.title}
              </ReactTooltip>
            )}
          </td>
          <td>
            <StyledCell>
              <span data-tip data-for={`organization-${reference.id}`}>
                {reference.organization || ''}
              </span>
            </StyledCell>
            {reference.organization && (
              <ReactTooltip id={`organization-${reference.id}`} effect="solid" place="top">
                {reference.organization}
              </ReactTooltip>
            )}
          </td>
          <td>
            <StyledCell>
              <span data-tip data-for={`relationship-${reference.id}`}>
                {reference.relationship || ''}
              </span>
            </StyledCell>
            {reference.relationship && (
              <ReactTooltip id={`relationship-${reference.id}`} effect="solid" place="top">
                {reference.relationship}
              </ReactTooltip>
            )}
          </td>
          <td>
            <StyledCell>
              <span data-tip data-for={`years-known-${reference.id}`}>
                {reference.years_known ? `${reference.years_known} yr` : ''}
              </span>
            </StyledCell>
            {reference.years_known && (
              <ReactTooltip id={`years-known-${reference.id}`} effect="solid" place="top">
                {`${reference.years_known} yr`}
              </ReactTooltip>
            )}
          </td>
          <td>
            <StyledCell>
              <span data-tip data-for={`email-${reference.id}`}>
                {reference.email || ''}
              </span>
            </StyledCell>
            {reference.email && (
              <ReactTooltip id={`email-${reference.id}`} effect="solid" place="top">
                {reference.email}
              </ReactTooltip>
            )}
          </td>
          <td>
            {' '}
            <StyledCell>
              <span data-tip data-for={`phone-${reference.id}`} className="ref-phone">
                {phone || ''}
              </span>
            </StyledCell>
            {phone && (
              <ReactTooltip id={`phone-${reference.id}`} effect="solid" place="top">
                {phone}
              </ReactTooltip>
            )}
          </td>
          <td>
            {reference.status === refStatus.pending && lastRequest ? (
              <span data-tip data-for={`status-${reference.id}`}>
                <img src={map.icon} alt="" />
                &nbsp;&nbsp;{map.label}
                <ReactTooltip id={`status-${reference.id}`} effect="solid">
                  <span>Last request sent on</span>
                  &nbsp;
                  {moment(lastRequest.created).format('l LT')}
                </ReactTooltip>
              </span>
            ) : (
              <span>
                <img src={map.icon} alt="" />
                &nbsp;&nbsp;{map.label}
              </span>
            )}
          </td>
          <td>
            {reference.contact_type !== refContactType.none ? (
              <div>
                <ReferenceMethodButton>
                  <ReferenceMethodText>
                    <div>{refContactTypeLabel[reference.contact_type]}</div>
                    <div
                      className="pointer"
                      onClick={() =>
                        this.props.updateReference(
                          this.props.row,
                          'contact_type',
                          refContactType.none
                        )
                      }
                    >
                      x
                    </div>
                  </ReferenceMethodText>
                </ReferenceMethodButton>
              </div>
            ) : (
              <ReferenceDropdown>
                <Dropdown
                  styles={{
                    control: base => ({
                      ...base,
                      height: 30,
                      minHeight: 30,
                    }),
                  }}
                  options={options}
                  onChange={option => this.handleDropdownClick(option)}
                  value={reference.contact_type}
                  className="contact-method-dropdown"
                  placeholder="Select"
                  disabled={this.props.disableContactMethodDropdown}
                />
              </ReferenceDropdown>
            )}
          </td>
        </tr>
      );
    }
  }
}

const StyledInput = styled.input`
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid;
  margin: 10px 10px 10px 0px;
  width: 80%;
  -webkit-inner-spin-button {
    opacity: 1;
  }
`;

const StyledCell = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 10px 5px 10px 0px;
`;

const StyledRow = styled.tr`
  margin: 10px;
`;

const ReferenceMethodButton = styled.div`
  background-color: black;
  color: white;
  border-radius: 2px;
  margin: 0px 10px 0px 0px;
  width: 80px;
  height: 30px;
`;

const ReferenceDropdown = styled.div`
  margin: 0px 10px 0px 0px;
  width: 80px;
`;

const ReferenceMethodText = styled.div`
  display: flex;
  align-items: center;
  width: 80px;
  height: 30px;
  background-color: #000;
  color: #fff;
  border-radius: 3px;
  justify-content: space-evenly;
  .pointer {
    cursor: pointer;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DropdownContainer = styled.div`
  max-width: 100%;
  max-height: 40px;
`;
