import axios from 'axios';
import { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import auth from '../../utils/auth';
import closeIcon from '../../assets/icon-close.svg';
import { useOnClickOutside } from 'hooks';
import theme from 'ui-kit/theme';
import ErrorText from '../errortext';
import {
  AssignmentContainerProps,
  ContainerProps,
  HRBPAssignmentProps,
  SelectionListProps,
} from './types';

const defaultErrorState = { hasError: false, message: null };

const HRBPAssignment: React.FC<HRBPAssignmentProps> = ({
  districtAndSuperAdmins,
  schoolOptions,
  schools,
  hrbpAssignments,
  hasError,
  onSave,
  job,
}) => {
  const [searchValueUser, setSearchValueUser] = useState('');
  const [searchValueSchool, setSearchValueSchool] = useState('');
  const [showOptions, setShowOptions] = useState({ schoolIndex: -1, userIndex: -1 });
  const [error, setError] = useState(defaultErrorState);
  const [errorIndices, setErrorIndices] = useState([]);

  /*** Conditional rendering booleans ***/
  const hasSchoolRoles = schools.length > 0;
  const hasSchoolGroups = schoolOptions.findIndex(s => s.isGroupHeader) > -1;

  /*** useOnClickOutside Hook Initialization ***/
  const closeDropdowns = useCallback(() => setShowOptions({ schoolIndex: -1, userIndex: -1 }), []);
  // @ts-expect-error for some reason we're passing showOptions, which is an object, and useOnClickOutside is expecting boolean
  const closeNodeOnClickOutside = useOnClickOutside(closeDropdowns, showOptions);

  /*** Delete school-level hrbp assigments when changed to district role ***/
  useEffect(() => {
    if (!hasSchoolRoles && hrbpAssignments.length > 1) {
      for (let i = 0; i < hrbpAssignments.length; i++) {
        deleteHRBP(i);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*** Add ErrorText if user Saves with unassigned values ***/
  useEffect(() => {
    const checkForErrors = () => {
      if (hrbpAssignments.length > 0 && hasSchoolRoles) {
        const errorIndicesCopy = [];
        hrbpAssignments.forEach((assignment, index) => {
          if (
            hrbpAssignments.length === 1 &&
            (assignment.admin_users.length === 0 || assignment.schools.length === 0) &&
            assignment.admin_users.length !== assignment.schools.length
          ) {
            errorIndicesCopy.push(0);
          }
          if (
            hrbpAssignments.length > 1 &&
            (assignment.admin_users.length === 0 || assignment.schools.length === 0)
          ) {
            errorIndicesCopy.push(index);
          }
        });
        if (errorIndicesCopy.length === 0) {
          setError(defaultErrorState);
        } else {
          setError({ hasError: true, message: 'Please select at least one user and one school.' });
          setErrorIndices(errorIndicesCopy);
        }
      }
    };
    if (hasError) {
      checkForErrors();
    } else {
      setError(defaultErrorState);
      setErrorIndices([]);
    }
  }, [hasError, hrbpAssignments, hasSchoolRoles]);

  /*** Nesterd Selects Functions ***/
  const schoolIds = schoolOptions.map(i => i.id);
  const schoolOptionsCopy = [...schoolOptions];

  // filter out schools in groupHeader that are archived
  schoolOptionsCopy.map(i => {
    if (i.isGroupHeader) {
      if ('schools' in i) {
        i.schools = i.schools.filter(s => schoolIds.includes(s));
      }
    }
    return i;
  });

  const getNestedSchoolGroups = () => {
    const ids = filteredSchools.map(item => item.id);

    const filtered = schoolOptionsCopy.filter(s => {
      const schoolExists = 'schools' in s ? s.schools.some(s => ids.includes(s)) : false;
      return (s.isGroupHeader && schoolExists) || filteredSchools.includes(s);
    });

    const groupHeaders = filtered.filter(s => s.isGroupHeader);
    let nestedSchoolGroups = [];

    // Make sure nested items are added to all school groups they are a part of!
    for (const group of groupHeaders) {
      nestedSchoolGroups.push(group);
      const schoolsInGroup = [];
      if ('schools' in group) {
        group.schools.map(s => {
          if (filtered.find(i => i.id === s && !i.isGroupHeader)) {
            schoolsInGroup.push(filtered.find(i => i.id === s && !i.isGroupHeader));
          }
          return s;
        });
      }
      schoolsInGroup.sort((a, b) => a.name.localeCompare(b.name));
      nestedSchoolGroups = nestedSchoolGroups.concat(schoolsInGroup);
    }

    const unGroupedFiltered = filtered
      .filter(s => !s.isGroupHeader && (!s.school_groups || s.school_groups.length === 0))
      .sort((a, b) => a.name.localeCompare(b.name));

    const unGroupedAll = schoolOptions
      // @ts-expect-error error says it's uncallable
      .filter(s => !s.isGroupHeader && (!s.school_groups || s.school_groups.length === 0))
      .sort((a, b) => a.name.localeCompare(b.name));

    if (unGroupedFiltered.length > 0) {
      unGroupedFiltered.unshift({
        id: 'groupless',
        name: 'No Group',
        isGroupHeader: true,
        schools: unGroupedAll.map(item => item.id),
      });
    }
    return nestedSchoolGroups.concat(unGroupedFiltered);
  };

  const allSubOptionsChecked = (obj, index, field) => {
    // if Option has no suboptions, make sure unchecked
    if (!obj[index]) return;
    if (obj[field].length === 0) return false;
    const itemIsCurrentlySelected = item => {
      return hrbpAssignments[index][field].includes(item);
    };

    return obj[field].every(itemIsCurrentlySelected);
  };

  const unselectAllSubOptions = (obj, index, field) => {
    const hrbpCopy = hrbpAssignments;
    const withGroupUnselected = hrbpCopy[index][field].filter(i => !obj[field].includes(i));
    hrbpCopy[index][field] = withGroupUnselected;
    onSave(hrbpCopy);
  };

  const selectAllSubOptions = (obj, index, field) => {
    const hrbpCopy = hrbpAssignments;
    const withGroupSelected = hrbpCopy[index].schools.concat(obj[field]);
    hrbpCopy[index][field] = [...new Set(withGroupSelected)];
    onSave(hrbpCopy);
  };

  /*** HRBP CRUD Operations  ***/
  const addHRBP = () => {
    onSave([...hrbpAssignments, { admin_users: [], schools: [], role: job.id }]);
  };

  const updateHRBP = (value, field, index) => {
    if (hrbpAssignments.length === 0) {
      const newAssignment = {
        admin_users: [],
        role: job?.id,
        schools: [],
        [field]: [Number(value)],
      };
      onSave([newAssignment]);
    } else {
      const newAssignments = hrbpAssignments.map((assignment, i) => {
        if (i !== index) {
          return assignment;
        }

        const isChecked = assignment[field].includes(Number(value));

        return {
          ...assignment,
          [field]: isChecked
            ? assignment[field].filter(id => id !== Number(value))
            : [...assignment[field], Number(value)],
        };
      });

      onSave(newAssignments);
    }
  };

  // delete is the only CRUD operation using the hrbp-assignment endpoint.
  const deleteHRBP = index => {
    const itemId = hrbpAssignments[index]?.id;
    if (itemId) {
      try {
        axios.delete(`/api/hrbp-assignment/${itemId}`);
      } catch (error) {
        if (error instanceof Error) {
          setError({ hasError: true, message: error.message });
          throw new Error(error.message);
        }
      }
    }
    const hrbpCopy = hrbpAssignments;
    hrbpCopy.splice(index, 1);
    onSave(hrbpCopy);
    if (errorIndices) {
      const errorIndicesCopy = errorIndices;
      errorIndicesCopy.splice(index, 1);
      setErrorIndices(errorIndicesCopy);
    }
  };

  /*** Input search logic ***/

  let filteredUsers = districtAndSuperAdmins ? [...districtAndSuperAdmins] : [];
  let filteredSchools = schoolOptions ? schoolOptionsCopy : [];

  if (searchValueUser) {
    filteredUsers = filteredUsers.filter(
      option => option.name.toLowerCase().indexOf(searchValueUser.toLowerCase()) !== -1
    );
  }
  if (searchValueSchool) {
    filteredSchools = filteredSchools.filter(
      option => option.name.toLowerCase().indexOf(searchValueSchool.toLowerCase()) !== -1
    );
  }
  /*** Error + disable-checkbox Functions ***/
  const dupeCheckUser = (itemId, item, index) => {
    const found = hrbpAssignments.findIndex(
      a => a.admin_users?.includes(itemId) && a.schools?.find(s => item.schools.includes(s))
    );
    if (found >= 0 && found !== index) return true;
    return false;
  };

  const dupeCheckSchool = (itemId, item, index) => {
    const found = hrbpAssignments.findIndex(
      a => a.admin_users.find(a => item.admin_users.includes(a)) && a.schools.includes(itemId)
    );
    if (found >= 0 && found !== index) return true;
    return false;
  };

  const dupeCheckSchoolGroup = (option, item, index) => {
    const found = hrbpAssignments.findIndex(
      a =>
        a.admin_users.find(a => item.admin_users.includes(a)) &&
        a.schools.find(s => option.schools.includes(s))
    );
    if (found >= 0 && found !== index) return true;
    return false;
  };

  const resetSearch = field => {
    field === 'userIndex' ? setSearchValueUser('') : setSearchValueSchool('');
  };

  const getInputText = (array, parent, fieldText) => {
    if (!array.length && hasSchoolRoles) {
      return `Select ${fieldText}`;
    } else if (!array.length && !hasSchoolRoles) {
      return `Start typing to assign all district applications for this role`;
    } else if (array.length === 1) {
      // make sure to filter out groupHeaders for schools....
      return `${parent.filter(i => !i.isGroupHeader).find(i => array.includes(i.id))?.name || ''}`;
    } else {
      return `${array?.length} ${fieldText} selected`;
    }
  };

  const toggleOptions = (field, index) => {
    const isClosing = showOptions[field] === index;
    resetSearch(field);

    setShowOptions(previousState => ({
      ...previousState,
      [field]: isClosing ? -1 : index,
    }));
  };

  const dummyHrbp = [{ admin_users: [], schools: [], role: job?.id }];

  // This component relies on there being an empty HRBP. Because empty HRBPs are deleted after saving a job,
  // the select wasn't rendering bc it's gated by there being HRBPs.
  const hrbpsToUse = hrbpAssignments.length > 0 ? hrbpAssignments : dummyHrbp;

  return (
    <>
      <div ref={closeNodeOnClickOutside}>
        {hrbpsToUse.map((item, index) => {
          return (
            <AssignmentContainer
              key={index}
              firstRow={index === 0}
              lastRow={index === hrbpAssignments.length - 1}
            >
              <SelectionContainer>
                <Container>
                  <Dropdown onClick={() => toggleOptions('userIndex', index)}>
                    <p>{getInputText(item.admin_users, districtAndSuperAdmins, 'assignees')}</p>
                    <DownCaret fillColor={theme.uiColors.black} />
                  </Dropdown>
                  <SelectionList showOptions={showOptions.userIndex === index}>
                    <InputFilterContainer>
                      <InputFilter
                        type="text"
                        placeholder="Search users"
                        value={searchValueUser}
                        onChange={e => setSearchValueUser(e.target.value)}
                      />
                      <ClearInput onClick={() => setSearchValueUser('')}>×</ClearInput>
                    </InputFilterContainer>
                    {filteredUsers
                      .sort(
                        (a, b) =>
                          Number(b.id === auth.getUser().id) - Number(a.id === auth.getUser().id)
                      )
                      .map((option, idx) => (
                        <Option key={idx}>
                          <label className="container">
                            <input
                              type="checkbox"
                              checked={item.admin_users?.includes(option.id)}
                              value={option.id}
                              disabled={dupeCheckUser(option.id, item, index)}
                              onChange={evt => updateHRBP(evt.target.value, 'admin_users', index)}
                            />
                            <span className="checkmark"></span>
                            {option.name}
                            {auth.getUser().id === option.id && <UserBadge>YOU</UserBadge>}
                          </label>
                        </Option>
                      ))}
                  </SelectionList>
                </Container>
                {hasSchoolRoles && (
                  <SchoolContainer>
                    <Container>
                      <Dropdown onClick={() => toggleOptions('schoolIndex', index)}>
                        <p>{getInputText(item.schools, schoolOptionsCopy, 'schools')}</p>
                        <DownCaret fillColor={theme.uiColors.black} />
                      </Dropdown>
                      <SelectionList showOptions={showOptions.schoolIndex === index}>
                        <InputFilterContainer>
                          <InputFilter
                            type="text"
                            placeholder="Search schools"
                            value={searchValueSchool}
                            onChange={e => setSearchValueSchool(e.target.value)}
                          />
                          <ClearInput onClick={() => setSearchValueSchool('')}>×</ClearInput>
                        </InputFilterContainer>
                        {!hasSchoolGroups &&
                          schoolOptionsCopy.map((option, idx) => (
                            <Option key={idx}>
                              <label className="container">
                                <input
                                  type="checkbox"
                                  // TODO: update this when types are worked out
                                  checked={item.schools.includes(option.id as number)}
                                  value={option.id}
                                  disabled={dupeCheckSchool(option.id, item, index)}
                                  onChange={evt => updateHRBP(evt.target.value, 'schools', index)}
                                />
                                <span className="checkmark"></span>
                                {option.name}
                              </label>
                            </Option>
                          ))}
                        {hasSchoolGroups &&
                          getNestedSchoolGroups().map((option, idx) => {
                            return (
                              <div key={idx}>
                                {option.isGroupHeader ? (
                                  <Option>
                                    <label className="container">
                                      <input
                                        type="checkbox"
                                        checked={allSubOptionsChecked(option, index, 'schools')}
                                        onChange={() => {
                                          allSubOptionsChecked(option, index, 'schools')
                                            ? unselectAllSubOptions(option, index, 'schools')
                                            : selectAllSubOptions(option, index, 'schools');
                                        }}
                                        disabled={dupeCheckSchoolGroup(option, item, index)}
                                      />
                                      <span className="checkmark"></span>
                                      {option.name}
                                    </label>
                                  </Option>
                                ) : (
                                  <IndentedOption key={idx}>
                                    <label className="container">
                                      <input
                                        type="checkbox"
                                        checked={item.schools.includes(option.id)}
                                        value={option.id}
                                        onChange={evt =>
                                          updateHRBP(evt.target.value, 'schools', index)
                                        }
                                        disabled={dupeCheckSchool(option.id, item, index)}
                                      />
                                      <span className="checkmark"></span>
                                      {option.name}
                                    </label>
                                  </IndentedOption>
                                )}
                              </div>
                            );
                          })}
                      </SelectionList>
                    </Container>
                    <div>
                      {index > 0 && (
                        <StyledCloseIcon
                          src={closeIcon}
                          onClick={() => deleteHRBP(index)}
                          alt="close_icon"
                        />
                      )}
                    </div>
                  </SchoolContainer>
                )}
              </SelectionContainer>
              {errorIndices.includes(index) && (
                <ErrorContainer>
                  <ErrorText message={error?.message} />
                </ErrorContainer>
              )}
            </AssignmentContainer>
          );
        })}
      </div>
      {hasSchoolRoles && (
        <ButtonContainer>
          <WhiteActionButton onClick={addHRBP}>+ Add Assignee(s)</WhiteActionButton>
        </ButtonContainer>
      )}
    </>
  );
};

export default HRBPAssignment;

const Container = styled.div<ContainerProps>`
  width: 100%;
  border: 1px solid ${props => (props.isActive ? props.highlightColor : '#C4C4C4')};
  font-size: 15px;
  color: #5c5e69;
  background-color: #ffffff;
  height: 50px;
  border-radius: 3px;
  display: flex;
  margin: 0px 10px;
  align-items: center;
  position: relative;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const StyledCloseIcon = styled.img`
  margin: 0px 30px 0px 10px;
`;

const SchoolContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Dropdown = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px 10px;
  width: 100%;
  height: 100%;
`;

const SelectionContainer = styled.div`
  display: flex;
`;

const ErrorContainer = styled.div`
  padding: 3px 10px;
`;

const SelectionList = styled.div<SelectionListProps>`
  display: ${props => (props.showOptions ? 'block' : 'none')};

  position: absolute;
  top: 50px;
  left: -1px;
  background-color: #ffffff;
  border: 1px solid #c4c4c4;
  padding: 7px 12px;
  width: calc(100% + 2px);

  z-index: 5;

  max-height: 500px;
  overflow: auto;
`;

const AssignmentContainer = styled.div<AssignmentContainerProps>`
  display: flex;
  background-color: #f1f1f1;
  flex-direction: column;
  padding: 10px 10px;
  border-top-left-radius: ${props => (props.firstRow ? '12px' : '0px')};
  border-top-right-radius: ${props => (props.firstRow ? '12px' : '0px')};
  border-bottom-left-radius: ${props => (props.lastRow ? '12px' : '0px')};
  border-bottom-right-radius: ${props => (props.lastRow ? '12px' : '0px')};
  border-top: ${props => (props.firstRow ? '0px' : '1px solid rgba(0, 0, 0, 0.1)')};
`;
const Option = styled.div`
  cursor: pointer;
  font-size: 15px;

  &:first-child {
    padding-top: 8px;
  }

  &:last-child {
    padding-bottom: 8px;
  }

  .container {
    display: block;
    position: relative;
    padding-left: 26px;
    margin-bottom: 8px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: normal;

    &.option-disabled {
      color: $light-grey;

      &:hover input ~ .checkmark {
        background-color: $green;
      }
    }

    /* Hide the browser's default checkbox */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 1px;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: white;
      border: 1px solid #e5e5e5;

      /* Create the checkmark/indicator (hidden when not checked) */
      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 6px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
      background-color: #e5e5e5;
    }

    /* When the checkbox is checked, add a green background */
    input:checked ~ .checkmark {
      background-color: #00b88d;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
      display: block;
    }
  }
`;

const IndentedOption = styled(Option)`
  padding-left: 16px;
`;

const InputFilterContainer = styled.div`
  position: relative;
  display: flex;

  margin-bottom: 16px;
`;

const InputFilter = styled.input`
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  padding: 8px;
  padding-right: 24px;
  margin: 0;
  width: 100%;
`;

const ClearInput = styled.span`
  position: absolute;
  right: 9px;
  top: 3px;

  font-size: 21px;
  cursor: pointer;
`;

const DownCaret = ({ fillColor }) => {
  return (
    <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.29708 5.17736e-06L5.5 4.20292L9.70292 5.17736e-06L11 1.29709L5.5 6.79709L0 1.29709L1.29708 5.17736e-06Z"
        fill={fillColor}
      />
    </svg>
  );
};

const UserBadge = styled.span`
  background-color: #7b5196;
  color: white;
  padding: 2px 5px;
  width: 70px;
  font-weight: bold;
  border-radius: 40px;
  font-size: 12px;
  margin-left: 5px;
  vertical-align: center;
`;

const WhiteActionButton = styled.button`
  background-color: white;
  font-size: 14px;
  margin-top: 16px;
  float: left;
  color: #00b88d;
  border-radius: 3px;
  border: 1px solid #00b88d;
  padding: 0 20px;
  height: 50px;
  ${props => (props.disabled ? 'cursor: not-allowed' : '')};
  ${props => (props.disabled ? 'opacity: 0.7' : '')};
`;
