import { Link } from 'react-router-dom';
import { Component } from 'react';
import rightCaret from '../../../assets/icon_right_caret_grey.svg';

import Moment from 'moment';
import PropTypes from 'prop-types';

export default class JobRowRequisition extends Component {
  static propTypes = {
    job: PropTypes.object.isRequired,
    isSchoolUser: PropTypes.bool || false,
    district: PropTypes.object,
  };

  renderDateColumn(date) {
    if (!date) return 'Not specified';
    return (
      <div>
        <div>{Moment(date).format('l')}</div>
      </div>
    );
  }

  queryParams = () => {
    const queryParams = [];
    if (this.props.isSchoolUser) {
      queryParams.push(`dr=${this.props.job.district_role_id}`);
    }

    if (this.props.job.is_treated) {
      queryParams.push('aci=t');
    }

    if (queryParams.length > 0) {
      return '?' + queryParams.join('&');
    } else {
      return '';
    }
  };

  render() {
    const { job } = this.props;
    const routeType = this.props.isSchoolUser ? 'school' : 'district';

    let jobboardTitle = '';
    // Show jobboard title for districts with many "subdistricts" (aka multiple jobboards)
    if (this.props.district && this.props.district.has_subdistricts && job.jobboards?.length > 0) {
      jobboardTitle = job.jobboards[0].internal_title
        ? job.jobboards[0].internal_title
        : job.jobboards[0].title;
    }

    return (
      <Link
        className="list-row list-item job-row"
        to={`/${routeType}/requisition-view/${job.id}${this.queryParams()}`}
      >
        <div className="job-row-count">{this.props.index + 1}</div>
        <div className="job-row-title">
          <div>{job.title}</div>
          <div className="sub-text">{jobboardTitle}</div>
        </div>
        <div className="job-row-date">{this.renderDateColumn(job.created)}</div>
        <div className={'job-row-applicants new ' + (job.app_count.new ? 'has-applications' : '')}>
          <span>{job.app_count.new} new</span>
        </div>
        <div className="job-row-applicants in-progress">{job.app_count.in_progress}</div>
        <div className="job-row-applicants total">{job.app_count.total}</div>
        <div className="job-row-apply">
          <img src={rightCaret} className="job-row-apply-caret" alt="right-caret" />
        </div>
      </Link>
    );
  }
}
