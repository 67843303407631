import { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import { taskStatus } from 'utils/enums';
import CompleteIcon from 'assets/icons/TaskIcons/green-circle-checkmark.svg';
import IncompleteIcon from 'assets/icons/TaskIcons/incomplete-circle.svg';
import OverdueIcon from 'assets/icons/TaskIcons/overdue-red-circle.svg';

export default class CandidateTasksRow extends Component {
  static propTypes = {
    task: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    updateCandidateTask: PropTypes.func.isRequired,
  };

  renderAssignedUsers = task => {
    const assigned_users = task.universal_task.assigned_users;
    if (!assigned_users || assigned_users.length === 0) {
      return null;
    }

    return (
      <span>
        by {assigned_users[0].name}
        {assigned_users.length > 1 && (
          <span>
            <span data-tip data-for={`assigned-users-${task.id}`}>
              &nbsp;and {assigned_users.length - 1} more
            </span>
            <ReactTooltip id={`assigned-users-${task.id}`} effect="solid">
              {assigned_users
                .map(u => u.name)
                .slice(1)
                .join(', ')}
            </ReactTooltip>
          </span>
        )}
      </span>
    );
  };

  renderCompletedBy = task => {
    return (
      <span>
        &nbsp;(completed by{' '}
        {task.universal_task.candidate_assigned ? 'Candidate' : task.completed_by} on{' '}
        {moment(task.completed_by_date).format('M/D/YYYY')})
      </span>
    );
  };

  render() {
    let { task } = this.props;
    return (
      <div className="task-row">
        <div className="flex">
          <img
            className="mr1 pointer"
            style={{
              height: '21px',
              width: '20px',
            }}
            src={
              task.status === taskStatus.complete
                ? CompleteIcon
                : moment(Date.now()).isAfter(task.due_date)
                ? OverdueIcon
                : IncompleteIcon
            }
            alt=""
            onClick={() => this.props.updateCandidateTask(task, 'put')}
          />
          <div>
            <span className={task.status === taskStatus.complete ? 'strikethrough' : ''}>
              {task.universal_task.title}
            </span>
            {task.status === taskStatus.incomplete && (
              <span>
                &nbsp; - due on {moment(task.due_date).format('M/D/YYYY')}{' '}
                {this.renderAssignedUsers(task)}
              </span>
            )}
            {task.status === taskStatus.complete &&
              task.completed_by &&
              task.completed_by_date &&
              this.renderCompletedBy(task)}
          </div>
        </div>
        {task.status === taskStatus.complete && (
          <span className="pointer" onClick={() => this.props.updateCandidateTask(task, 'delete')}>
            x
          </span>
        )}
      </div>
    );
  }
}
