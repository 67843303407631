import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { StyledListFilter } from 'ui-kit';
import CategoriesFilter from './CategoriesFilter';
import GradesFilter from './GradesFilter';
import LocationsFilter from './LocationsFilter';
import CurrentUserWithBadgeListFilter from 'ui-kit/CurrentUserWithBadgeListFilter';
import SetAsDefaultButton from 'components/CandidatesList/CandidatesFilters/SetAsDefault';

import { roleStatusFilter } from '../enums';
import { hiringSeasons, grades as gradesEnum } from 'utils/enums';
import auth from 'utils/auth';
import { useRouteMatch } from 'react-router-dom';
import { InternalJobsFiltersProps } from './types';

const InternalJobsFilters: React.FC<InternalJobsFiltersProps> = ({
  categories,
  categoryOptions,
  clearAll,
  district_users,
  districtUserOptions,
  grades,
  hiring_seasons,
  hrbp_assignees,
  hrbpAssigneeOptions,
  hrbpFieldName,
  internal_external,
  job_owners,
  job_status_list,
  jobOwnerOptions,
  locationOptions,
  locations,
  selectAll,
  setAllCategories,
  setAllGrades,
  setAllLocations,
  setCategories,
  setGrades,
  setLocations,
  updateMultiSelectFilter,
}) => {
  const [shouldShowJobStatus, setShouldShowJobStatus] = useState(true);
  const templatesMatch = useRouteMatch('/district/templates');

  useEffect(() => {
    if (templatesMatch) {
      // do not show the status filter on templates page
      setShouldShowJobStatus(false);
    } else {
      setShouldShowJobStatus(true);
    }
  }, [templatesMatch]);

  const isCategoriesFilterActive = () => {
    const optionsLength = categoryOptions.reduce((total, currentItem) => {
      total += currentItem.subcategories.length;
      return total;
    }, 1); // start with one because we add the "N/A" option
    const allSelected = categories.length === optionsLength;
    const noneSelected = categories.length === 0;
    const isActive = !(allSelected || noneSelected);
    return isActive;
  };

  const isGradesFilterActive = () => {
    // start with one because we add the "N/A" option
    const allSelected = grades.length === gradesEnum().length + 1;
    const noneSelected = grades.length === 0;
    const isActive = !(allSelected || noneSelected);
    return isActive;
  };

  const isLocationsFilterActive = () => {
    // Each group of locations might contain duplicates:
    let seenLocations = [];
    locationOptions.forEach(o => {
      seenLocations = [...o.sublocations.map(l => l.id), ...seenLocations];
    });
    const optionsLength = [...new Set(seenLocations)].length + 1; // +1 is for the N/A
    const allSelected = locations.length === optionsLength;
    const noneSelected = locations.length === 0;
    const isActive = !(allSelected || noneSelected);
    return isActive;
  };

  // posting-only district jobs don't have a pending status, nor active_closed
  const statusFilterOptions = auth.inPostingOnlyDistrict()
    ? roleStatusFilter()
        .filter(s => s.value !== 'pending')
        .filter(s => s.value !== 'active_closed')
    : roleStatusFilter();

  const shouldShowJobStatusFilterActive =
    job_status_list?.length !== 0 && job_status_list?.length !== statusFilterOptions?.length;

  return (
    <FilterContainer>
      <div>
        {shouldShowJobStatus && (
          <StyledListFilter
            handleChange={updateMultiSelectFilter}
            displayName={'Job Status'}
            fieldName={'job_status_list'}
            values={job_status_list}
            options={statusFilterOptions}
            selectAll={selectAll}
            clearAll={clearAll}
            isActive={shouldShowJobStatusFilterActive}
          />
        )}
        {/* only super/district admins can view this filter */}
        {auth.isDistrictAdmin(auth.getUser()) && (
          <CurrentUserWithBadgeListFilter
            handleChange={updateMultiSelectFilter}
            displayName="HR/Onboarding Partner"
            fieldName={hrbpFieldName}
            values={hrbp_assignees}
            options={hrbpAssigneeOptions}
            selectAll={selectAll}
            clearAll={clearAll}
            isActive={hrbp_assignees.length && hrbp_assignees.length !== hrbpAssigneeOptions.length}
          />
        )}

        <CategoriesFilter
          categories={categories}
          setCategories={setCategories}
          setAllCategories={setAllCategories}
          categoryOptions={categoryOptions}
          isActive={isCategoriesFilterActive()}
        />
        <GradesFilter
          grades={grades}
          setGrades={setGrades}
          setAllGrades={setAllGrades}
          isActive={isGradesFilterActive()}
        />
        <StyledListFilter
          handleChange={updateMultiSelectFilter}
          displayName="Hiring Season"
          fieldName={'hiring_seasons'}
          values={hiring_seasons}
          options={hiringSeasons(false, true)}
          selectAll={selectAll}
          clearAll={clearAll}
          isActive={hiring_seasons.length !== 0 && hiring_seasons.length !== hiringSeasons().length}
        />
        {!auth.inPostingOnlyDistrict() && (
          <StyledListFilter
            handleChange={updateMultiSelectFilter}
            displayName="Internal/External"
            fieldName="internal_external"
            values={internal_external}
            options={[
              { value: 'internal', label: 'Internal only' },
              { value: 'external', label: 'All applicants' },
            ]}
            selectAll={selectAll}
            clearAll={clearAll}
            isActive={internal_external.length !== 0 && internal_external.length !== 2}
          />
        )}

        {!auth.inPostingOnlyDistrict() && (
          <StyledListFilter
            handleChange={updateMultiSelectFilter}
            displayName="Job Owner"
            fieldName="job_owners"
            values={job_owners}
            options={jobOwnerOptions}
            selectAll={selectAll}
            clearAll={clearAll}
            isActive={job_owners.length !== 0 && job_owners.length !== jobOwnerOptions.length}
            showSearchInput
          />
        )}

        {!auth.isDistrictUserOnly() && !auth.inPostingOnlyDistrict() && (
          <StyledListFilter
            handleChange={updateMultiSelectFilter}
            displayName="District User"
            fieldName="district_users"
            values={district_users}
            options={districtUserOptions}
            selectAll={selectAll}
            clearAll={clearAll}
            // add one because of the "N/A" option we're adding
            isActive={
              district_users.length !== 0 && district_users.length !== districtUserOptions.length
            }
            showSearchInput
          />
        )}
        {auth.isDistrictUser() && (
          <>
            {auth.inPostingOnlyDistrict() ? (
              <StyledListFilter
                handleChange={updateMultiSelectFilter}
                displayName="Location"
                fieldName="schools"
                values={locations}
                options={locationOptions}
                selectAll={selectAll}
                clearAll={clearAll}
                // add one because of the "N/A" option we're adding
                isActive={locations.length !== 0 && locations.length !== locationOptions.length}
                showSearchInput
              />
            ) : (
              <LocationsFilter
                locations={locations}
                setLocations={setLocations}
                setAllLocations={setAllLocations}
                locationOptions={locationOptions}
                isActive={isLocationsFilterActive()}
              />
            )}
          </>
        )}
        <SetAsDefaultButton />
      </div>
    </FilterContainer>
  );
};

export default InternalJobsFilters;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  border-right: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;

  @media screen and (min-width: 768px) {
    position: fixed;
    top: 70px;
    overflow: auto;
    height: calc(100vh - 70px);
    width: 250px;
    min-width: 250px;
  }
`;
