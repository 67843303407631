import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Box, Grid, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';
import { PrimaryButton } from 'sharedComponents/Buttons';
import FilterValueTags from './Filters/FilterValueTags';
import UserDropdown from '../../components/NavBar/userDropdown';
import IDOElogo from 'assets/state_logos/idoe_new_logo.png';
import OECOSLlogo from 'assets/state_logos/OECOSL_reducedsize.png';
import { StateJobBoardHeaderProps } from './types';
import auth from 'utils/auth';

export const StateJobBoardHeader: React.FC<StateJobBoardHeaderProps> = ({
  actions,
  filterValues,
  activeFilters,
  error,
  dropdownProps,
  count,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const match = useRouteMatch<{ id: string }>('/jobs/state/:id');
  const rawStateCode = match?.params.id;
  const stateCode = rawStateCode?.toUpperCase();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const user = auth.getUser()

  const performSearch = () => {
    actions.setQuery(searchTerm);
  };

  const onKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      performSearch();
    }
  };

  const pathToReturnTo = `${location.pathname}`;

  return (
    <div>
      <TopContainer>
        <Stack direction={'row'}>
          <LogoContainer>
            <LogoItem>
              <StyledLogo1 src={IDOElogo} alt="IDOE logo" />
            </LogoItem>
            <LogoItem>
              <StyledLogo2 src={OECOSLlogo} alt="OECOSL logo" />
            </LogoItem>
          </LogoContainer>
        </Stack>
        {!user ? (
          <CandidateLoginButton
            to={{
              pathname: '/login',
              state: {
                previousLocation: pathToReturnTo,
              },
            }}
          >
            {isMobile ? 'Login' : 'Candidate Login'}
          </CandidateLoginButton>
        ) : (
          <UserDropdown {...dropdownProps} />
        )}
      </TopContainer>
      <Container container spacing={1}>
        <Grid item xs={12} md={12}>
          {stateCode === 'IN' ? (
            <Title variant="h1">Indiana Educator Job Board</Title>
          ) : (
            <Title>School Personnel Job Search</Title>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <JobsSubtitle>{count} JOBS</JobsSubtitle>
        </Grid>
        <FilterInputContainer item xs={12}>
          <SearchInputContainer>
            <SearchInput
              variant="outlined"
              placeholder={
                isMobile
                  ? 'Search title, keywords, or organization'
                  : 'Search by job title, keywords, or organization'
              }
              value={searchTerm}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
              onKeyPress={onKeyPress}
              data-testid=""
            />
            <PrimaryButton
              onClick={performSearch}
              size="extraLarge"
              sx={PrimaryButtonStyles(theme)}
              loadingSpinnerColor={'secondary'}
            >
              <SearchIcon sx={{ justifySelf: 'center' }} />
            </PrimaryButton>
          </SearchInputContainer>
        </FilterInputContainer>
        <StyledFilterValueTags item xs={12}>
          <FilterValueTags
            actions={actions}
            filterValues={filterValues}
            activeFilters={activeFilters}
            error={error}
          />
        </StyledFilterValueTags>
      </Container>
    </div>
  );
};

const CandidateLoginButton = styled(Link)(({ theme }) => ({
  alignItems: 'center',
  border: `1px solid ${theme.palette.gray.darker}`,
  borderRadius: '0px 0px 2px 2px',
  backgroundColor: theme.palette.white.main,
  color: theme.palette.text.secondary,
  display: 'flex',
  fontSize: '15px !important',
  fontWeight: theme.typography.fontWeightMediumBold,
  height: '48px',
  lineHeight: '24px',
  padding: theme.spacing(1, 4),
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px !important',
    padding: theme.spacing(1, 2),
  },
}));

const TopContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(3.75, 3.75, 2.5, 3.75),
  backgroundColor: theme.palette.white.main,
}));

const Container = styled(Grid)(({ theme }) => ({
  display: 'block',
  borderTop: `1px solid ${theme.palette.gray.darker}`,
  gridTemplateRows: '102px minmax(48px, auto) 74px minmax(35px, 1fr)',
  gridColumnGap: theme.spacing(2.5),
  alignItems: 'center',
  backgroundColor: theme.palette.white.main,
  padding: theme.spacing(.5, 3),
}));

const LogoContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
}));

const LogoItem = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledLogo1 = styled('img')(({ theme }) => ({
  height: '80px',
  minWidth: '226px',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '226px',
  [theme.breakpoints.down('md')]: {
    height: '60px',
    minWidth: '180px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '40px',
    minWidth: '120px',
  },
}));

const StyledLogo2 = styled('img')(({ theme }) => ({
  height: '65px',
  minWidth: '210px',
  margin: theme.spacing(1.25, 0, 0, 5),
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '210px',
  [theme.breakpoints.down('md')]: {
    height: '60px',
    minWidth: '180px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '40px',
    minWidth: '120px',
    margin: theme.spacing(0.375, 0, 0, 1.5),
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '24px',
  fontWeight: theme.typography.fontWeightMediumBold,
  paddingLeft: theme.spacing(0),
  minWidth: '100%',
  whiteSpace: 'nowrap',
}));

const JobsSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '12px',
  fontWeight: theme.typography.fontWeightLight,
  padding: 0,
  paddingBottom: theme.spacing(1),
}));

const FilterInputContainer = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}));

const SearchInputContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}));

const SearchInput = styled(TextField)(() => ({
  flexGrow: 1,
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px 0 0 4px',
    border: '1.1px',
    height: '55px',
    padding: '0 14px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiInputBase-input': {
    height: '100%',
    boxSizing: 'border-box',
    padding: '0',
    display: 'flex',
    alignItems: 'center',
  },
}));

const StyledFilterValueTags = styled(Grid)(() => ({
  paddingTop: '10px',
}));

const PrimaryButtonStyles = theme => ({
  background: theme.palette.blue.darker,
  height: '54.5px',
  width: '55px',
  padding: 0,
  borderRadius: '0 4px 4px 0',
  boxSizing: 'border-box',
  minWidth: 'auto',
  display: 'flex',
  justifyContent: 'center',
});
