import { Component } from 'react';
import auth from '../../utils/auth';
import { user_type, schoolStatus } from '../../utils/enums';

import PropTypes from 'prop-types';
import DistrictAccountUsers from './districtaccountusers';
import DistrictAccountModal from './districtaccountmodal';
import DistrictAccountSchools from './districtaccountschools';

const SCHOOLSTATUS = schoolStatus().reduce((obj, item) => {
  obj[item.value] = item.key;
  return obj;
}, {});

export default class DistrictAccount extends Component {
  static propTypes = {
    createUser: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    deactivateUser: PropTypes.func.isRequired,
    reactivateUser: PropTypes.func.isRequired,
    district: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    schools: PropTypes.array.isRequired,
    updateDistrict: PropTypes.func.isRequired,
    updateSchool: PropTypes.func.isRequired,
    createSchool: PropTypes.func.isRequired,
    deleteSchool: PropTypes.func.isRequired,
  };
  static defaultProps = {
    district: {},
    schools: [],
    users: [],
  };

  state = {
    displayModal: false,
    district: this.props.district,
    errors: {},
  };

  user = auth.getUser();

  showModal = e => {
    e.stopPropagation();
    this.setState({ displayModal: true });
  };

  hideModal = () => {
    this.setState({ displayModal: false });
  };

  onSave = district => {
    this.props
      .updateDistrict(district)
      .then(() => {
        this.setState({ displayModal: false });
      })
      .catch(error => {
        this.setState({ errors: error.response.data });
      });
  };

  render() {
    return (
      <div
        className="flex mt2"
        style={{
          maxWidth: '1200px',
          padding: '0 40px 0 12px',
          margin: 'auto',
          marginTop: '2rem',
        }}
      >
        <div className="flex-2 ml2">
          <div className="card no-padding district-header">
            <div>
              <h3 className="mb1">{this.props.district.name}</h3>
              <h4>
                {this.props.district.city}, {this.props.district.state}
              </h4>
            </div>
            {user_type().find(u => u.key === this.user.profile.user_type).value ===
              'super_admin' && (
              <div className="districtaccount-edit-container">
                <button className="edit-button" onClick={this.showModal}>
                  Edit
                </button>
              </div>
            )}
          </div>
          <DistrictAccountSchools
            createSchool={this.props.createSchool}
            deleteSchool={this.props.deleteSchool}
            updateSchool={this.props.updateSchool}
            schools={this.props.schools}
          />
          <DistrictAccountUsers
            createUser={this.props.createUser}
            updateUser={this.props.updateUser}
            deactivateUser={this.props.deactivateUser}
            reactivateUser={this.props.reactivateUser}
            district={this.props.district}
            schools={this.props.schools.filter(s => s.status === SCHOOLSTATUS.active)}
            users={this.props.users}
          />
        </div>
        <DistrictAccountModal
          district={this.props.district}
          displayModal={this.state.displayModal}
          errors={this.state.errors}
          onHide={this.hideModal}
          onSave={this.onSave}
        />
      </div>
    );
  }
}
