import useSWR from 'swr';
import axios from 'axios';

import SignerTaskRow from './SignerTaskRow';

const fetcher = url => axios.get(url).then(response => response.data);

export default function CandidateSignerTasks({ candidate }) {
  const { data } = useSWR(
    `/api/hellosign/candidate_signature_tasks/?candidate_id=${candidate.id}`,
    fetcher
  );

  if (data === undefined) {
    return null;
  }

  return (
    <>
      {data.map(task => (
        <SignerTaskRow key={task.signature_id} task={task} />
      ))}
    </>
  );
}
