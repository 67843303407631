import { Component } from 'react';

import PropTypes from 'prop-types';

import { degreeField, degreeSubjects, universities } from '../../../utils/enums';
import moment from 'moment';

export default class Education extends Component {
  static propTypes = {
    education: PropTypes.arrayOf(Object).isRequired,
    simpleCard: PropTypes.bool,
  };

  static defaultProps = {
    simpleCard: false,
  };

  getDegree(value) {
    value = parseInt(value, 10);
    const option = degreeField().find(o => o.value === value);
    return option && option.label;
  }
  /*
   * simpleCard is rendered on applicationconfirmation and full profile
   * !simpleCard type is used on candidate application
   */
  render() {
    return (
      <div className="simple-card-container">
        {this.props.education.map((row, i) => {
          let school = universities().find(u => u.value === parseInt(row['university'], 10));
          if (school) {
            school = school.label;
          } else if (row['school'] && row['school'].hasOwnProperty('name')) {
            school = row['school']['name'];
          } else {
            school = row['school'];
          }
          let major = degreeSubjects().find(u => u.value === parseInt(row['major_enum'], 10));
          major = major ? major.label : row['major'];

          return this.props.simpleCard ? (
            <div className="simple-card mb1" key={i}>
              <div>
                <p>
                  {/* {`${this.getDegree(row['degree']) && row['major'] ? `,` : ''} */}
                  {`${this.getDegree(row['degree']) || ''}`}
                  {', ' + school || ''}
                </p>
                <p>{`${major || ''}`}</p>
              </div>
              <div>
                <span className="flex-1">
                  {`${
                    moment(row['start_date']).isValid()
                      ? moment(row['start_date']).format('MM/D/YYYY')
                      : ''
                  } ${
                    moment(row['end_date']).isValid()
                      ? `- ${moment(row['end_date']).format('MM/D/YYYY')}`
                      : ''
                  }`}
                </span>
              </div>
            </div>
          ) : (
            <div className="added-row" key={i}>
              <span className="block flex-1 bold">
                <h5>{school || ''}</h5>
                <p>{`${major || ''}${this.getDegree(row['degree']) && major ? `,` : ''}
                ${this.getDegree(row['degree']) || ''}`}</p>
              </span>
              <span className="flex-1">
                {`${
                  moment(row['start_date']).isValid()
                    ? moment(row['start_date']).format('MM/D/YYYY')
                    : ''
                } ${
                  moment(row['end_date']).isValid()
                    ? `- ${moment(row['end_date']).format('MM/D/YYYY')}`
                    : ''
                }`}
              </span>
            </div>
          );
        })}
      </div>
    );
  }
}
