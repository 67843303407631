import { SettingsRow } from 'components/SettingsRow';
import { ViewButton } from 'sharedComponents/Buttons';
import styled from 'styled-components';
import { color, typography } from 'styled-system';
import { getTagProperty } from './util';

function CustomTagRow({ tag, openModal }) {
  const tagColor = getTagProperty(tag);
  /**
   * Determines which tag types to display.
   */
  const getTagType = () => {
    const { candidate_type, prospect_type } = tag;

    if (candidate_type && prospect_type) {
      return 'Candidate & Prospect';
    } else if (candidate_type) {
      return 'Candidate';
    } else {
      return 'Prospect';
    }
  };

  /**
   * Determines which visibility type strings to display.
   *
   * NOTE: Super Admin will always have access to the tag.
   *
   */
  const getTagVisibility = () => {
    const { district_admin_visibility, district_user_visibility, school_admin_visibility } = tag;

    const tags = [
      {
        label: 'All',
        isSelected:
          district_admin_visibility && district_user_visibility && school_admin_visibility,
      },
      {
        label: 'District Admin',
        isSelected: district_admin_visibility,
      },
      {
        label: 'District Users',
        isSelected: district_user_visibility,
      },
      {
        label: 'School Admin',
        isSelected: school_admin_visibility,
      },
    ];
    let string = 'Super Admin';

    // Iterate through tag visibility options to determine final string
    for (let tag of tags) {
      const { label, isSelected } = tag;

      // If every user_type can view tag we return 'All' and break loop
      if (label === 'All' && isSelected) {
        return label;
      }

      if (label === 'District Admin' && isSelected) {
        string += `, ${label}`;
      } else if (label === 'District Users' && isSelected) {
        string += `, ${label}`;
      } else if (label === 'School Admin' && isSelected) {
        string += `, ${label}`;
      }
    }

    return string;
  };

  return (
    <SettingsRow cta={<ViewButton />} onClick={() => openModal(tag)} sx={{ height: '60px' }}>
      <TagNameText backgroundColor={tagColor}>
        <TagNameLabel backgroundColor={tagColor}>{tag.label}</TagNameLabel>
      </TagNameText>
      <ListText color="#909090" fontSize="14px">
        {getTagVisibility()}
      </ListText>
      <TagTypeText color="#909090" fontSize="14px">
        {getTagType()}
      </TagTypeText>
    </SettingsRow>
  );
}

export default CustomTagRow;

const TagNameText = styled.p`
  font-size: 15px;
  padding: 15.5px 0 10px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 0 0 25%;

  // change text to white on purple or blue background
  color: ${props =>
    props.backgroundColor === '#7B5196' || props.backgroundColor === '#304B7D' ? '#fff' : '#444'};
`;

const TagNameLabel = styled.span`
  padding: 6px 10px;
  width: 119px;
  text-align: center;
  background-color: ${props => props.backgroundColor};
`;

const ListText = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 0 0 25%;

  ${color};
  ${typography};
`;

const TagTypeText = styled.p`
  padding-left: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 0 0 25%;

  ${color}
  ${typography}
`;
