import styled from 'styled-components';

import { ToggleItemProps } from '../types/index';

export const Wrapper = styled.div`
  display: flex;

  font-size: 13px;

  cursor: pointer;
`;

export const ToggleItem = styled.div<ToggleItemProps>`
  width: 102px;
  padding: 4px;
  height: 28px;
  text-align: center;
  user-select: none;

  background-color: ${props =>
    props.isActive ? props.theme.uiColors.greens.full : props.theme.colors.pureWhite};
  color: ${props => (props.isActive ? props.theme.colors.pureWhite : '#444')};
  border: 1px solid
    ${props => (props.isActive ? props.theme.uiColors.greens.full : props.theme.uiColors.lightgray)};

  font-weight: ${props => (props.isActive ? 600 : 'normal')};

  :first-child {
    border-radius: 8px 0px 0px 8px;
    border-right: none;
  }

  :last-child {
    border-radius: 0px 8px 8px 0;
    border-left: none;
  }
`;
