import { useState } from 'react';
import styled from 'styled-components';
import { Input } from 'ui-kit';
import { Label } from '../TabsShareComponents';
import LocationsModal from '../../../../../components/JobEdit/LocationsModal';
import { LocationsObject } from '../../../types/types';
import { JobErrors, Job, School } from 'types/types';

interface LocationSelectProps {
  job: Job;
  setJob: (job: Job) => void;
  errors: JobErrors;
  setErrors: (errors: JobErrors) => void;
  schoolOptions: School[];
  schoolsSelected: School[];
  setSchoolsSelected: (schools: School[]) => void;
}

const LocationsSelect: React.FC<LocationSelectProps> = ({
  job,
  setJob,
  errors,
  setErrors,
  schoolOptions,
  schoolsSelected,
  setSchoolsSelected,
}) => {
  const [locationsModalOpen, setLocationsModalOpen] = useState(false);

  const getLocationsObject = () => {
    const locations: LocationsObject = {
      test: {
        vacancies: 0,
        checked: false,
        visibleToAdmins: false,
      },
    };

    schoolsSelected.forEach(s => {
      locations[s.name] = {
        vacancies: s.ftes_total,
        checked: true,
        visibleToAdmins: s.visible_to_school_admin,
      };
    });

    // schoolOptions has the full list of school names, so use it to fill the rest with 0.
    schoolOptions.forEach(so => {
      if (!so.isGroupHeader) {
        if (!Object.hasOwnProperty.call(locations, so.name)) {
          locations[so.name] = {
            vacancies: 0,
            checked: false,
            visibleToAdmins: false,
          };
        }

        if (!locations[so.name].checked) {
          locations[so.name].visibleToAdmins = false;
        }
        locations[so.name].groups = so.school_groups || [];
      }
    });

    return locations;
  };

  const isDistrictRole = district_role_ftes => district_role_ftes > 0;

  const onSaveLocations = (updatedLocations: LocationsObject, district_role_ftes: number) => {
    const updateJob = { ...job };

    if (isDistrictRole(district_role_ftes)) {
      updateJob.ftes_total = Number(district_role_ftes);
      setSchoolsSelected([]);

      if (job.hrbp_assignments[0]) {
        updateJob.hrbp_assignments = [
          { admin_users: [...job.hrbp_assignments[0].admin_users], schools: [], role: job?.id },
        ];
      }

      const updateErrors = {
        ...errors,
        hrbp_assignments: false,
      };
      setJob(updateJob);
      setErrors(updateErrors);
    } else {
      let selectedSchools = [...schoolsSelected];
      for (const [schoolName, school] of Object.entries(updatedLocations)) {
        const selectedSchool = schoolOptions.find(s => s.name === schoolName);
        const originalFtes = selectedSchool?.ftes_total ? selectedSchool.ftes_total : 0;

        if (selectedSchool !== undefined) {
          selectedSchool.ftes_total = Number(school.vacancies);
          selectedSchool.visible_to_school_admin = school.visibleToAdmins;

          if (originalFtes < selectedSchool.ftes_total) {
            selectedSchool.has_unfilled_vacancies = true;
          }
          selectedSchools.push(selectedSchool);
        }
      }

      selectedSchools = selectedSchools.filter(
        s =>
          s.ftes_total &&
          s.ftes_total > 0 &&
          updatedLocations[s.name] &&
          updatedLocations[s.name]?.checked
      );
      const uniqueSelectedSchools = [...new Map(selectedSchools.map(s => [s['id'], s])).values()];
      setSchoolsSelected(uniqueSelectedSchools);
    }
    setLocationsModalOpen(false);
  };

  const renderSchoolLocations = () => {
    /**
     * return a string with the associated schools, and something like "+ 3 more" if
     * the string is too long (> 90 characters).
     */
    if (schoolsSelected?.length === 0) {
      return '';
    }
    let locationString = '';
    let stringLength = 0;
    let additionalSchools = 0;
    for (const school of schoolsSelected) {
      stringLength += school.name.length;
      if (stringLength < 90) {
        locationString += school.name + ', ';
      } else {
        additionalSchools += 1;
      }
    }
    const additionalSchoolsString = additionalSchools > 0 ? ` + ${additionalSchools} more` : '';
    const finalString = `${locationString.slice(0, -2)}${additionalSchoolsString}`;
    return finalString;
  };

  return (
    <div>
      <Label>Location(s)</Label>
      <LocationsGridContainer onClick={() => setLocationsModalOpen(true)}>
        <Input
          width={1}
          style={{ backgroundColor: '#fff' }}
          type="text"
          placeholder="No locations selected"
          value={renderSchoolLocations() || 'District Level'}
          disabled
          autoFocus
        />
        <SubmitButton
          data-testid="locations-modal-open-button"
          onClick={() => setLocationsModalOpen(true)}
        >
          Edit Locations
        </SubmitButton>
      </LocationsGridContainer>
      {locationsModalOpen && (
        <LocationsModal
          show={locationsModalOpen}
          onHide={() => setLocationsModalOpen(false)}
          onSave={onSaveLocations}
          schoolOptions={schoolOptions}
          locations={getLocationsObject()}
          districtVacancies={job.ftes_total}
        />
      )}
    </div>
  );
};

export default LocationsSelect;

const LocationsGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 139px;
  grid-gap: 16px;
`;
const GreyActionButton = styled.button`
  background-color: #cacaca;
  font-size: 14px;
  margin-right: 16px;
  float: right;
  color: white;
  border-radius: 3px;
  border: none;
  padding: 0 20px;
  height: 50px;
  ${props => (props.disabled ? 'cursor: not-allowed' : '')};
  ${props => (props.disabled ? 'opacity: 0.7' : '')};
`;
const SubmitButton = styled(GreyActionButton)`
  margin-right: 0;
  background-color: #00b88d;
  z-index: 1;
`;
