import { BaseNimbleAPI } from './baseAPI';

class StateJobboardsAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/statejobboardsubscriptions' };
  }

  /**
   * Subscribe to a state jobboard
   */
  async subscribe(stateCode, email, subcategoryStateIds, districtIds, token) {
    const subcategories = subcategoryStateIds.map(id => ({ state_id: id }));
    const districts = districtIds.map(id => ({ id }));
    const state_code = stateCode.toUpperCase();

    const url = `/subscribe/`;
    const response = await this.post(url, {
      email,
      state_code,
      subcategories,
      districts,
      token,
    });

    return response.data;
  }

  /**
   * Update a subscription to a state jobboard
   */
  async update(alertId, stateCode, email, subcategoryStateIds, districtIds, token) {
    const subcategories = subcategoryStateIds.map(id => ({ state_id: id }));
    const districts = districtIds.map(id => ({ id }));
    const state_code = stateCode.toUpperCase();

    const url = `/subscribe/`;
    const response = await this.post(url, {
      id: alertId,
      email,
      state_code,
      subcategories,
      districts,
      token,
    });
    return response.data;
  }

  /**
   * Delete a state jobboard, alert
   */
  async unsubscribe(alertId, stateCode, token) {
    const state_code = stateCode.toUpperCase();
    const id = alertId;

    const url = `/unsubscribe/`;
    const response = await this.post(url, {
      state_code,
      id,
      token,
    });
    return response.data;
  }

  /**
   * Get subscription status to state jobboard from a token
   */
  async subscriptions(token) {
    const params = { token };
    const url = `/subscriptions/`;
    const response = await this.get(url, { params });
    return response.data;
  }

  /**
   * Confirms an email subscription through params. This function only called if confirm = True.
   */
  async confirm(token) {
    const confirm = true;
    const url = `/confirm/`;
    const response = await this.post(url, { token, confirm });
    return response.data;
  }
}

export default new StateJobboardsAPI();
