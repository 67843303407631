import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import auth from 'utils/auth';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';

import FinalRecommendationPreview from './FinalRecommendationPreview';
import CumulativeScorePreview from './CumulativeScorePreview';
import warningTriangle from 'assets/icons/warning_triangle.svg';
import { theme } from 'ui-kit';
import { RightCaretIcon } from 'ui-kit/icons';
import { roleStatuses } from 'utils/enums';

export default function ScorecardListCard({ scorecard, onView, onComplete, updateScorecardsList }) {
  const user = auth.getUser();
  const [roleOptions, setRoleOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);

  useEffect(() => {
    setRoleOptions(
      scorecard.roleOptions.map(application => {
        const activeStatus = roleStatuses().filter(status => {
          return status.value === 'active';
        })[0];
        const status = roleStatuses().filter(status => {
          const optionStatus = application.role
            ? application.role.status
            : application.role__status;

          return status.key === optionStatus;
        })[0];

        if (status.key === activeStatus.key) {
          if ((application.role?.active_status || application.role__active_status) === 1) {
            status.label = 'active, open';
            status.color = theme.uiColors.greens.full;
          } else {
            status.label = 'active, closed';
            status.color = theme.uiColors.yellows.full;
          }
        }

        const option = {
          value: application.id,
          label: (
            <RoleChoiceLabel>
              <span>{application.role ? application.role.title : application.role__title}</span>
              <RoleChoiceStatusLabel statusColor={status.color}>
                {status.label.toLowerCase()}
              </RoleChoiceStatusLabel>
            </RoleChoiceLabel>
          ),
        };

        if (option.value === scorecard.application) {
          setSelectedRole(option);
        }

        return option;
      })
    );
  }, [scorecard, setRoleOptions, setSelectedRole]);

  const updateScorecardRole = useCallback(
    selectedRole => {
      // Don't send anything else but what needs to be changed:
      const payload = {
        id: scorecard.id,
        is_universal: false,
        application: selectedRole.value,
      };
      axios
        .put(`/api/scorecards/${scorecard.id}/update_scorecard_application/`, payload)
        .then(resp => {
          updateScorecardsList(resp.data);
          setSelectedRole(selectedRole);
        });
    },
    [scorecard, setSelectedRole, updateScorecardsList]
  );

  const renderActionButton = scorecard => {
    let buttonLabel;
    let func;

    if (scorecard.scorecard_complete) {
      buttonLabel = 'View';
      func = onView;
    } else if (user.email === scorecard.submitted_by) {
      buttonLabel = 'Finish scorecard';
      func = onComplete;
    } else {
      // shouldn't get here because other users' drafts are not shown
      return null;
    }
    return (
      <ActionButton onClick={() => func(scorecard)}>
        {buttonLabel}
        &nbsp;&nbsp;
        <RightCaretIcon height="8px" />
      </ActionButton>
    );
  };

  // scorecards submitted by anonymous users through email flow will not have
  // the submitted_by_name field
  const submitted_label = scorecard.submitted_by_name
    ? scorecard.submitted_by_name
    : scorecard.submitted_by;

  const colourStyles = props => ({
    singleValue: (styles, state) => ({
      ...styles,
      color: '#999',
      fontWeight: 800,
    }),
    valueContainer: (styles, state) => ({
      ...styles,
      paddingLeft: '0',
      paddingRight: '0',
    }),
    control: styles => ({
      ...styles,
      backgroundColor: theme.uiColors.white,
      border: `none`,
      boxShadow: props.boxShadow || 'none',
      borderRadius: '3px',
      minHeight: '20px',
      '&:hover': {
        border: 'none',
        backgroundColor: '#EEEFF2',
      },
    }),
    option: (styles, state) => ({
      ...styles,
      background: state.isFocused
        ? theme.uiColors.notquitewhite
        : state.isSelected
        ? theme.uiColors.gray
        : styles.background,
      // color: state.isFocused || state.isSelected ? theme.uiColors.white : styles.color
    }),
    dropdownIndicator: (styles, state) => ({
      display: 'none',
    }),
    indicatorSeparator: (styles, state) => ({
      display: 'none',
    }),
  });

  const CustomSelect = props => <Select styles={colourStyles(props)} {...props} />;

  return (
    <Card>
      {auth.isSuperAdminUser() && scorecard.is_universal && (
        <RoleWarningLabel>This scorecard is not assigned to a role</RoleWarningLabel>
      )}
      {renderActionButton(scorecard)}
      <ScorecardTitle>{scorecard.template_name ?? 'Scorecard'}</ScorecardTitle>
      <GreyText data-tip data-for={`submitted_by_${scorecard.id}`}>
        Submitted by {submitted_label}
      </GreyText>
      <ReactTooltip id={`submitted_by_${scorecard.id}`} effect="solid">
        <span>{submitted_label}</span>
      </ReactTooltip>
      <GreyText>{new Date(scorecard.created).toLocaleString()}</GreyText>
      <HorizontalLine />
      {scorecard.include_final_recommendation && scorecard.show_answer_on_preview && (
        <FinalRecommendationPreview parentObject={scorecard} />
      )}
      {scorecard.include_cumulative_score && scorecard.show_total_on_preview && (
        <CumulativeScorePreview parentObject={scorecard} />
      )}
      {!scorecard.scorecard_complete && (
        <DraftWarningContainer>
          <img src={warningTriangle} alt="warning-triangle" />
          <DraftLabel>Draft</DraftLabel>
          <DraftWarning>(only visible to you)</DraftWarning>
        </DraftWarningContainer>
      )}

      {auth.isSuperAdminUser() && (
        <RoleControl>
          <RoleControlLabel>
            Assign to {scorecard.application ? 'a different ' : ' '}role:
          </RoleControlLabel>
          <CustomSelect
            value={selectedRole}
            onChange={updateScorecardRole}
            options={roleOptions}
            isSearchable={false}
          ></CustomSelect>
        </RoleControl>
      )}
    </Card>
  );
}

const Card = styled.div({
  height: '263px',
  width: '385px',
  position: 'relative',
  padding: '25px 20px',
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
  borderRadius: '3px',
  backgroundColor: '#FFFFFF',
});

const ActionButton = styled.button({
  height: '19px',
  padding: '0 4px',
  position: 'absolute',
  top: '7px',
  right: '7px',
  color: '#979797',
  border: '1px solid #979797',
  borderRadius: '3px',
  fontSize: '14px',
  backgroundColor: '#FFFFFF',
  opacity: '0.8',
  display: 'flex',
  alignItems: 'center',
});

const Text = styled.p({
  margin: '4px 0',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

const ScorecardTitle = styled(Text)({
  fontWeight: '600',
  fontSize: '15px',
  lineHeight: '20px',
  color: '#444444',
});

const GreyText = styled(Text)({
  fontSize: '14px',
  lineHeight: '19px',
  color: '#999999',
});

const HorizontalLine = styled.hr({
  margin: '12px 0',
});

const DraftWarningContainer = styled.div({
  position: 'absolute',
  bottom: '7px',
  left: '10px',
  display: 'flex',
  alignItems: 'center',
});

const DraftWarning = styled.p({
  color: '#EF5675',
  fontSize: '12px',
  marginLeft: '4px',
});

const DraftLabel = styled(DraftWarning)({
  fontWeight: 600,
});

const RoleControlLabel = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #999999;
`;

const RoleWarningLabel = styled.div`
  color: ${props => props.theme.uiColors.reds.full};
  position: absolute;
  top: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 270px;
`;

const RoleChoiceLabel = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 100px;
`;

const RoleChoiceStatusLabel = styled.span`
  color: ${props => props.statusColor};
  font-style: italic;
  text-align: right;
`;

const RoleControl = styled.div`
  padding-top: 1em;

  [class*='singleValue'] ${RoleChoiceStatusLabel} {
    display: none;
  }
`;
