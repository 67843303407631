import styled from 'styled-components';

import { GreenToggle } from 'ui-kit';

export default function FinalRecommendation({
  questionText,
  setQuestionText,
  includeMaybeOption,
  setIncludeMaybeOption,
  showAnswerOnPreview,
  setShowAnswerOnPreview,
}) {
  return (
    <>
      <h5 className="bold">Directions</h5>
      <input
        className="form-field full-width mb3 mt16px box-style"
        type="text"
        placeholder="e.g. Should we pass this person into the hiring pool?"
        value={questionText}
        onChange={e => setQuestionText(e.target.value)}
      />
      <ToggleContainer>
        <GreenToggle
          toggleIsOn={includeMaybeOption}
          toggleFunction={() => setIncludeMaybeOption(prevValue => !prevValue)}
          label='Include "maybe" option'
        />
      </ToggleContainer>
      <ToggleContainer>
        <GreenToggle
          toggleIsOn={showAnswerOnPreview}
          toggleFunction={() => setShowAnswerOnPreview(prevValue => !prevValue)}
          label="Show answer on preview"
        />
      </ToggleContainer>
    </>
  );
}

const ToggleContainer = styled.div`
  margin-bottom: 17px;

  &:last-child {
    margin-bottom: 0;
  }
`;
