import { ChildrenContainer, ModalContainer, Title, Top } from './styles';
import { Modal as MuiModal } from '@mui/material';
import { ModalProps } from './types';
import { nimbleTheme } from 'theme';

export const Modal: React.FC<ModalProps> = ({
  ctaButtons,
  children,
  closeButton,
  open,
  onClose,
  sx,
  title,
}) => {
  return (
    <MuiModal open={open} onClose={(event, reason) => onClose(event, reason)} sx={sx}>
      <ModalContainer theme={nimbleTheme} className="modalBody">
        <Top>
          <Title data-testid="modal-title" id="modal-modal-title">
            {title}
          </Title>
          {closeButton}
        </Top>
        <ChildrenContainer>{children}</ChildrenContainer>
        {ctaButtons}
      </ModalContainer>
    </MuiModal>
  );
};
