import { Component } from 'react';

import PropTypes from 'prop-types';

import { languageList, languageProficiency } from '../../../utils/enums';

export default class Languages extends Component {
  static propTypes = {
    languages: PropTypes.arrayOf(Object).isRequired,
  };

  getItem(list, value) {
    value = parseInt(value, 10);
    const option = list.find(o => o.value === value);
    return option && option.label;
  }

  render() {
    return (
      <div>
        {this.props.languages.map((row, i) => (
          <div className="added-row" key={i}>
            <span className="flex-2">{this.getItem(languageList(), row['language'])}</span>
            <span className="flex-2">{this.getItem(languageProficiency(), row['status'])}</span>
          </div>
        ))}
      </div>
    );
  }
}
