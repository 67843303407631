// import './deletemodal.css';

import { Component } from 'react';
import styled from 'styled-components';

import { AltModal, Button } from 'ui-kit';
import PropTypes from 'prop-types';

export default class DeleteModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    submitLabel: PropTypes.string.isRequired,
    cancelLabel: PropTypes.string,
    buttonColorRed: PropTypes.bool,
  };

  render() {
    return (
      <AltModal.AltModal isOpen={this.props.show} onClose={this.props.onHide}>
        <AltModal.Title>
          <div className="delete-modal-title">
            <h3>{this.props.title}</h3>
          </div>
        </AltModal.Title>
        <AltModal.Body>
          <div className="delete-modal-section">
            <div>
              <h4 className="delete-modal-header" style={{ lineHeight: '150%' }}>
                {this.props.message}
              </h4>
            </div>
          </div>
        </AltModal.Body>
        <AltModal.Actions>
          <ButtonContainer>
            {this.props.cancelLabel ? (
              <Button variant="secondary" onClick={this.props.onHide}>
                {this.props.cancelLabel}
              </Button>
            ) : (
              <div />
            )}
            <div />
            <Button
              variant={this.props.buttonColorRed ? 'delete' : 'primary'}
              onClick={this.props.onSave}
              className="delete-modal-button"
            >
              {this.props.submitLabel}
            </Button>
          </ButtonContainer>
        </AltModal.Actions>
      </AltModal.AltModal>
    );
  }
}

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-gap: auto;
`;
