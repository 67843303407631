import { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ReferenceRow from './ReferenceRow';
import ReferenceModal from '../../ReferenceModal';
import pencilIcon from '../../../assets/icon-pencil.svg';
import { handleOutsideClickOnValidForm } from '../../../utils/util';
import ErrorText from '../../errortext';

import usersAPI from 'api/usersAPI';
import referenceCheckAPI from 'api/referenceCheckAPI';

export default class References extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      viewRefModalOpen: false,
      // will be either phone (0) or email (1)
      referenceType: null,
      editMode: false,
      error: '',
      // if the user selects an option from the dropdown on a row,
      // keep track of the row so that reference is auto
      // selected in the modal.
      row: null,
      // when selectedReference is not null, opening the ReferenceModal will show
      // that reference automatically
      selectedReference: null,
      selectedDistrictReference: null,
      referenceChecks: [],
    };
  }

  static propTypes = {
    user: PropTypes.object.isRequired,
    adminUsers: PropTypes.array.isRequired,
    applicationBeingViewed: PropTypes.object.isRequired,
    emailTaggedUsers: PropTypes.func.isRequired,
    updateReference: PropTypes.func.isRequired,
    emailTemplatesList: PropTypes.array.isRequired,
    removeReference: PropTypes.func.isRequired,
    setReferencesError: PropTypes.func.isRequired,
    referencesError: PropTypes.string.isRequired,
    fetchReferences: PropTypes.func.isRequired,
    references: PropTypes.array.isRequired,
    fetchEvents: PropTypes.func,
    referenceParam: PropTypes.number,
    referenceParamSeen: PropTypes.bool.isRequired,
    markReferenceParamSeen: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.fetchReferenceChecks();
  }

  componentDidUpdate() {
    // if reference is passed in query params, open the profile page with the modal open
    if (
      this.props.referenceParam !== null &&
      !this.props.referenceParamSeen &&
      !this.state.viewRefModalOpen &&
      this.props.references.length > 0
    ) {
      this.props.markReferenceParamSeen();
      this.openModalByReferenceCheck();
    }
  }

  // opens modal with reference check showing
  openModalByReferenceCheck = () => {
    let referenceCheck = this.state.referenceChecks.find(
      refChk => refChk.id === Number(this.props.referenceParam)
    );
    if (referenceCheck) {
      this.setState({
        selectedReference: referenceCheck,
        selectedDistrictReference: null,
        viewRefModalOpen: true,
      });
    }
  };

  // opens modal with reference check showing, but found by DistrictReference id
  openModalByDistrictReference = (referenceType, selectedDistrictReference) => {
    let referenceCheck = this.state.referenceChecks.find(
      refChk => refChk.reference_id === selectedDistrictReference.id
    );
    if (referenceCheck) {
      this.setState({
        referenceType,
        selectedReference: referenceCheck,
        selectedDistrictReference: null,
        viewRefModalOpen: true,
      });
    }
  };

  openModalForNewReference = (referenceType, selectedDistrictReference) => {
    this.setState({
      referenceType,
      selectedDistrictReference,
      selectedReference: null,
      viewRefModalOpen: true,
    });
  };

  // opens modal with list view showing
  openModalToList = () => {
    if (!this.props.referencesError) {
      this.setState({
        viewRefModalOpen: true,
        referenceType: null,
        selectedReference: null,
        selectedDistrictReference: null,
      });
    }
  };

  updateReferenceType = type => {
    this.setState({ referenceType: type });
  };

  outsideClickListener = ev => handleOutsideClickOnValidForm(this.node, ev, this.toggleEditMode);

  isValidEmail = email => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  checkReferencesForErrors = () => {
    for (let i = 0; i < this.props.references.length; i++) {
      if (!this.props.references[i].name) {
        this.props.setReferencesError('Please add a name for each reference');
        return;
      }
      if (!this.props.references[i].phone && !this.props.references[i].email) {
        this.props.setReferencesError('Please add a phone or email for each reference');
        return;
      }
      if (
        this.props.references[i].email &&
        this.props.references[i].email.length > 0 &&
        this.props.references[i].email !== ''
      ) {
        let isValidEmail = this.isValidEmail(this.props.references[i].email);
        if (!isValidEmail) {
          this.props.setReferencesError('At least one email address provided is invalid');
          return;
        }
      } else {
        this.props.setReferencesError('');
      }
    }
    this.props.setReferencesError('');
  };

  checkReferenceRow = (row, fieldName, value) => {
    this.props.updateReference(row, fieldName, value);
    this.checkReferencesForErrors();
  };

  toggleEditMode = () => {
    let editMode = !this.state.editMode;
    if (editMode === false) {
      this.checkReferencesForErrors();
    }
    if (editMode) {
      document.addEventListener('click', this.outsideClickListener, { capture: true });
    } else {
      document.removeEventListener('click', this.outsideClickListener, false);
    }
    this.setState({ editMode });
  };

  addReference = () => {
    if (!this.state.editMode) {
      this.toggleEditMode();
    }

    // only update if there are no reference errors
    if (!this.props.referencesError) {
      this.props.updateReference(null);
    }
  };

  fetchReferenceChecks = () => {
    this.setState({ loading: true });
    usersAPI
      .fetchReferenceChecks(this.props.user.id)
      .then(referenceChecks => {
        if (!this.ignoreLastFetch) {
          this.setState({ referenceChecks });
        }
        this.checkReferencesForErrors();
      })
      .finally(() => this.setState({ loading: false }));
  };

  deleteReferenceCheck = referenceCheckId => {
    let referenceChecks = [...this.state.referenceChecks];
    let index = referenceChecks.findIndex(s => s.id === referenceCheckId);
    if (index > -1) {
      referenceChecks.splice(index, 1);
    }
    this.setState({ referenceChecks });
    referenceCheckAPI.destroy(referenceCheckId).then(
      // re-fetch references on the full profile page to show the new status
      // without this deleted reference/scorecard
      this.props.fetchReferences
    );
  };

  render() {
    return (
      <div id="references-section" ref={node => (this.node = node)}>
        <div className="flex space-between">
          <div>
            <h4 className="inline-block">References</h4>
            {(this.props.references.length > 0 || this.props.user.requests.length > 0) && (
              <p className="view-references" onClick={this.openModalToList}>
                (View pending & completed references)
              </p>
            )}
          </div>
          {this.props.references.length > 0 && (
            <div className="edit-references" onClick={this.toggleEditMode}>
              <img src={pencilIcon} alt="edit" />
            </div>
          )}
        </div>
        {this.state.viewRefModalOpen && (
          <ReferenceModal
            user={this.props.user}
            application={this.props.applicationBeingViewed}
            show={this.state.viewRefModalOpen}
            onHide={() => this.setState({ viewRefModalOpen: false })}
            adminUsers={this.props.adminUsers}
            emailTaggedUsers={this.props.emailTaggedUsers}
            roleTitle={
              this.props.applicationBeingViewed ? this.props.applicationBeingViewed.role.title : ''
            }
            updateReference={this.props.updateReference}
            emailTemplatesList={this.props.emailTemplatesList}
            referenceType={this.state.referenceType}
            updateReferenceType={this.updateReferenceType}
            references={this.props.references}
            fetchEvents={this.props.fetchEvents}
            selectedReference={this.state.selectedReference}
            selectedDistrictReference={this.state.selectedDistrictReference}
            referenceChecks={this.state.referenceChecks}
            deleteReferenceCheck={this.deleteReferenceCheck}
            fetchReferenceChecks={this.fetchReferenceChecks}
            loading={this.state.loading}
          />
        )}
        <StyledTable>
          <thead>
            <ReferenceHeaderContainer>
              <ReferenceHeader>Name</ReferenceHeader>
              <ReferenceHeader>Title</ReferenceHeader>
              <ReferenceHeader>Org</ReferenceHeader>
              <ReferenceHeader>Relationship</ReferenceHeader>
              <ReferenceHeader>Known</ReferenceHeader>
              <ReferenceHeader>Email</ReferenceHeader>
              <ReferenceHeader>Phone</ReferenceHeader>
              <ReferenceHeader>Status</ReferenceHeader>
              <ReferenceHeader>Method</ReferenceHeader>
            </ReferenceHeaderContainer>
          </thead>
          <tbody>
            {this.props.references.map((ref, i) => (
              <ReferenceRow
                key={i}
                row={i}
                reference={ref}
                openModalByDistrictReference={this.openModalByDistrictReference}
                openModalForNewReference={this.openModalForNewReference}
                updateReference={this.checkReferenceRow}
                editMode={this.state.editMode}
                removeReference={this.props.removeReference}
                requests={this.props.user.requests}
                referenceChecks={this.state.referenceChecks}
                disableContactMethodDropdown={this.props.referencesError ? true : false}
              />
            ))}
          </tbody>
        </StyledTable>
        {this.props.references.length === 0 && (
          <div className="mt2" style={{ paddingBottom: '2rem' }}>
            <em>No references submitted by candidate</em>
          </div>
        )}
        <div className="add-reference-div">
          <div>
            <ErrorText className="error_text" message={this.props.referencesError} />
          </div>
          <AddReferenceButton onClick={this.addReference}>+ Add reference</AddReferenceButton>
        </div>
      </div>
    );
  }
}

const AddReferenceButton = styled.button`
  color: #00b88d;
  border: none;
  padding: 16px 0;
  background-color: white;
`;

const ReferenceHeaderContainer = styled.tr`
  color: #999999;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  background-color: white;
  padding: 10px;
`;

const ReferenceHeader = styled.th`
  padding: 15px 0px;
  font-weight: lighter;
`;
const StyledTable = styled.table`
  width: 100%;
  margin-top: 10px;
  table-layout: fixed;
`;
