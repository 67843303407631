import { Component } from 'react';

import JobEdit from 'components/JobEdit';
import axios from 'axios';

import _ from 'lodash';

import { currentHiringSeason, appStatusType } from '../../utils/enums';
import { DistrictUserJobCreateContainerState } from './types';
import { roleStatuses, getJobStatusColor } from '../../utils/enums';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import applicationStatusesAPI from '../../api/applicationStatusesAPI';
import auth from '../../utils/auth';
import CustomScorecardListAPI from 'api/customScorecardListAPI';
import nimbleQuestionsAPI from 'api/nimbleQuestionsAPI';

const statuses = _.keyBy(roleStatuses(), 'value');

const initialErrorState = {
  link_to_ats: '',
};

class DistrictUserJobCreateContainer extends Component<
  RouteComponentProps,
  DistrictUserJobCreateContainerState
> {
  ignoreLastFetch: boolean;

  constructor(props) {
    super(props);
    this.state = {
      statusColor: '',
      questionBank: [],
      job: {
        title: '',
        grades: [],
        questions: [],
        status: 10,
        district_users: [auth.getUser().id],
        hiring_season: currentHiringSeason(),
        statuses_available: [],
      },
      activelySubmitting: false,
      newApplicationStatuses: [],
      apiResponseErrors: { ...initialErrorState },
      customScorecardsAvailable: [],
      customScorecardsSelected: [],
    };
    this.ignoreLastFetch = false;
  }

  componentDidMount() {
    nimbleQuestionsAPI.getAll().then(questionBank => {
      if (!this.ignoreLastFetch) {
        this.setState({ questionBank });
      }
    });

    applicationStatusesAPI
      .getAll({
        districtId: auth.getUser().profile.district.id,
        statusTypes: undefined,
      })
      .then(newApplicationStatuses => {
        this.setState({ newApplicationStatuses });
      });

    CustomScorecardListAPI.fetchCustomScorecards().then(data => {
      this.setState({
        customScorecardsAvailable: data.sort((a, b) => {
          if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
          if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
          return 0;
        }),
      });
    });
  }

  setStatusColor = () => {
    this.setState({ statusColor: getJobStatusColor(this.state.job.status) });
  };

  onSave = childRole => {
    this.setState({ activelySubmitting: true });
    const putJob = childRole;
    putJob.status = statuses.draft.key;
    // Set all statuses selected by default except draft
    putJob.statuses_available = this.state.newApplicationStatuses.filter(
      s => s.status_type !== appStatusType.draft
    );
    return axios.post('/api/role/', putJob).then(() => {
      this.setState({ activelySubmitting: false });
      if (!this.ignoreLastFetch) {
        this.props.history.push('/district/jobslist?f_JS=draft');
      }
    });
  };

  onSubmit = childRole => {
    this.setState({ activelySubmitting: true });
    const putJob = childRole;
    putJob.status = statuses.submitted.key;
    putJob.submitted_by = auth.getUser().id;
    // Set all statuses selected by default except draft
    putJob.statuses_available = this.state.newApplicationStatuses.filter(
      s => s.status_type !== appStatusType.draft
    );
    return axios.post('/api/role/', putJob).then(() => {
      this.setState({ activelySubmitting: false });
      if (!this.ignoreLastFetch) {
        this.props.history.push('/district/jobslist?f_JS=pending');
      }
    });
  };

  clearAPIResponseErrors = () => {
    this.setState({ apiResponseErrors: { ...initialErrorState } });
  };

  componentWillUnmount() {
    this.ignoreLastFetch = true;
  }

  render() {
    return (
      this.state.job && (
        <JobEdit
          job={this.state.job}
          statusColor={this.state.statusColor}
          questionBank={this.state.questionBank}
          onSave={this.onSave}
          onSubmit={this.onSubmit}
          onSubmitLabel="Submit to Admin"
          pageTitle={`Request Job at ${auth.getUser().profile.district.name}`}
          activelySubmitting={this.state.activelySubmitting}
          apiResponseErrors={this.state.apiResponseErrors}
          clearAPIResponseErrors={this.clearAPIResponseErrors}
          customScorecards={this.state.customScorecardsAvailable}
          customScorecardsSelected={this.state.customScorecardsSelected}
        />
      )
    );
  }
}

export default withRouter(DistrictUserJobCreateContainer);
