import { user_type } from './enums';
import auth from './auth';
import { PROD_URL } from 'utils/constants';
import { User } from 'containers/CurrentUserPermissionsProvider';

export interface IntercomUser {
  app_id: number | string;
  user_id: number | string;
  hostname: string;
  name: string;
  email: string;
  user_type: string;
  user_hash: string;
  created_at: number | string;
  current_application: 'classic' | 'connect';
  hide_default_launcher: boolean;
  company?: {
    id: number | string;
    name: string;
    parent_organization: string;
    posting_only: boolean | null;
  };
}

export const createOrUpdateIntercomUser = (
  user: User,
  application_name: 'classic' | 'connect'
): void => {
  const app_id = window.location.hostname === PROD_URL ? 'm1noxx4g' : 'kbgzm71v';
  const date_joined = Math.floor(new Date(user.date_joined as string).getTime() / 1000);
  const user_type_label = user_type().filter(userType => {
    return userType.key === user.profile.user_type;
  })[0].label;

  const userObj: IntercomUser = {
    app_id: app_id,
    user_id: user.id,
    hostname: window.location.hostname, //environment
    name: user.name, // Full name
    email: user.email, // Email address
    user_type: user_type_label, // User type label
    created_at: date_joined, // Signup date as a Unix timestamp
    user_hash: user.intercom_hmac, // HMAC using SHA-256,
    current_application: application_name,
    hide_default_launcher: false,
  };

  if (!auth.isCandidateUser(user)) {
    userObj.company = {
      id: user.profile.district?.id || '',
      name: user.profile.district?.name || '',
      parent_organization: user.profile.district?.parent_organization || '',
      posting_only: user.profile.district?.posting_only,
    };
  }
  window.Intercom('boot', userObj);
};

export const shutdownIntercom = (): void => {
  window.Intercom('shutdown', { hide_default_launcher: true });
};
