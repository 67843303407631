import { Component } from 'react';

import { AnchorTagHeader } from 'sharedComponents/Header/AnchorTagHeader';
import { ConfirmationDialog } from 'sharedComponents/Dialog/ConfirmationDialog';
import { EmailTemplateListProps, EmailTemplateListState } from './types';
import { ErrorSnackbar, SuccessSnackbar } from 'sharedComponents/Snackbar';
import { getSortedTemplates } from './utils';
import EmailTemplateAPI from 'api/emailTemplateAPI';
import EmailTemplateModal from './EmailTemplateModal';
import EmailTemplateRow from './EmailTemplateRow';


export default class EmailTemplateList extends Component<
  EmailTemplateListProps,
  EmailTemplateListState
> {
  constructor(props) {
    super(props);
    this.state = {
      // when a template is selected for edit, set this value to that template
      emailTemplate: {
        id: '',
        title: '',
        subject: '',
        content: '',
        view_permissions: 0,
      },
      showEmailTemplateModal: false,
      showConfirmationModal: false,
      showSuccessToast: false,
      showErrorToast: false,
      emailTypes: [],
    };
  }
  componentDidMount = () => {
    const isSuperAdminWithAppConfFF =
      this.props.isSuperAdmin && this.props.hasApplicationConfirmationFF;

    EmailTemplateAPI.getAll()
      .then(response => {
        if (isSuperAdminWithAppConfFF) {
          this.setState({ emailTypes: response });
        } else {
          const responseToSave = response.filter(item => item.value !== 'applicationConfirmation');
          this.setState({ emailTypes: responseToSave });
        }
      })
      .catch(() => this.setState({ showErrorToast: true }));
  };

  openEmailTemplateModal = id => {
    /** the email template modal can be opened by clicking the edit icon
     * in an emailTemplateRow, or by clicking the 'New Email Template' button
     * on this component. If clicking the new email template button, id will
     * be null. If clicking from an existing row, find its id in
     * props.emailTemplateList and set it in state.
     */
    const emailTemplate = id
      ? this.props.emailTemplateList.find(e => e.id === id)
      : // if id is null, send down an empty emailTemplate
        { id: '', title: '', subject: '', content: '', view_permissions: 0 };
    this.setState({ emailTemplate, showEmailTemplateModal: true });
  };

  onHide = () => {
    // If the user cancels the confirmation modal, and cancels the template modal,
    // the body would be left with an overflow style, preventing the user from scrolling.
    this.setState({ showEmailTemplateModal: false }, () => {
      if (document.body.style.overflow === 'hidden') {
        document.body.style.overflow = 'auto';
      }
    });
  };

  onDelete = () => {
    // show confirmation:
    this.setState({ showConfirmationModal: true, showEmailTemplateModal: false });
  };

  onConfirmedDelete = confirmed => {
    if (confirmed) {
      this.props.deleteEmailTemplate(this.state.emailTemplate?.id);
      this.setState({ showConfirmationModal: false, showEmailTemplateModal: false });
    } else {
      this.setState({ showConfirmationModal: false, showEmailTemplateModal: true });
    }
  };

  handleShowSuccessToast = () => this.setState({ showSuccessToast: true });

  handleShowErrorToast = () => this.setState({ showErrorToast: true });

  render() {
    const types = this.props.emailTemplateList.filter(template => {
      return template.type === 'Application confirmation';
    });
    const hasApplicationConfirmationTemplate = types.length > 0;
    const sortedTemplates = getSortedTemplates(this.props.emailTemplateList);

    return (
      <div className="mb2">
        <div className="mt2">
          <AnchorTagHeader
            onClick={this.openEmailTemplateModal.bind(this, null)}
            buttonText="+ New Email Template"
            title="Email Templates"
            id="emailTemplates"
          />

          {sortedTemplates.map((e, index) => {
            return (
              <EmailTemplateRow
                key={index}
                emailTemplate={e}
                openEmailTemplateModal={this.openEmailTemplateModal}
              />
            );
          })}
        </div>
        {this.state.showEmailTemplateModal && (
          <EmailTemplateModal
            show={this.state.showEmailTemplateModal}
            emailTemplate={this.state.emailTemplate}
            emailTypes={this.state.emailTypes}
            onHide={this.onHide}
            onDelete={this.onDelete}
            onSave={this.props.saveEmailTemplate}
            isDistrictAdmin={this.props.isDistrictAdmin}
            handleShowErrorToast={this.handleShowErrorToast}
            handleShowSuccessToast={this.handleShowSuccessToast}
            hasApplicationConfirmationTemplate={hasApplicationConfirmationTemplate}
          />
        )}
        {this.state.showConfirmationModal && (
          <ConfirmationDialog
            resultHandler={this.onConfirmedDelete}
            title="Delete Email Template"
            text="Are you sure you want to delete this email template? This action cannot be undone."
            backLabel="Back to Edit"
            confirmLabel="Permanently Delete"
            isOpen={this.state.showConfirmationModal}
          />
        )}
        <SuccessSnackbar
          open={this.state.showSuccessToast}
          onClose={() => this.setState({ showSuccessToast: false })}
          message="Email template successfully saved"
          sx={{ marginTop: 0 }}
        />
        <ErrorSnackbar
          open={this.state.showErrorToast}
          onClose={() => this.setState({ showErrorToast: false })}
          message="Something went wrong when saving the email template"
        />
      </div>
    );
  }
}
