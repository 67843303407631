import { Component } from 'react';

import PropTypes from 'prop-types';
import DeactivateModal from './deactivatemodal';
import SchoolAccountModal from '../SchoolAccount/schoolaccountmodal';
import { schoolStatus } from '../../utils/enums';
import { useSchoolGroups } from 'hooks/data/useSchoolGroups';

import SchoolIcon from '../../assets/icons/AccountManagementIcons/school_icon.svg';
import EditIcon from '../../assets/icons/AccountManagementIcons/edit-icon.svg';
import IconCaretDown from '../../assets/icon-down-caret-grey.svg';
import IconCaretUp from '../../assets/icon-up-caret-green.svg';
import XError from '../../assets/x-error.svg';

import auth from '../../utils/auth';

const SCHOOLSTATUSES = schoolStatus().reduce((obj, item) => {
  obj[item.value] = item.key;
  return obj;
}, {});

export default class DistrictAccountSchools extends Component {
  static propTypes = {
    createSchool: PropTypes.func.isRequired,
    deleteSchool: PropTypes.func.isRequired,
    updateSchool: PropTypes.func.isRequired,
    schools: PropTypes.array.isRequired,
  };

  state = {
    selectedSchool: {},
    displayCreate: false,
    errors: {},
    showArchivedSchools: false,
    showArchiveModal: false,
    showWarning: false,
  };

  editSchool = school => this.setState({ selectedSchool: school });

  hideModal = () => this.setState({ selectedSchool: {}, displayCreate: false });

  handleCreateSchool = school => {
    this.hideModal();
    this.props.createSchool(school);
  };

  handleUpdateSchool = school => {
    this.hideModal();
    this.props.updateSchool(school);
  };

  toggleActiveSchools = () => {
    this.setState(prevState => {
      return { showArchivedSchools: !prevState.showArchivedSchools };
    });
  };

  openArchiveModal = () => {
    this.setState(prevState => {
      let showWarning = false;
      if (prevState.selectedSchool.school_admins_exist) {
        // user is trying to archive a school that has admin users, so show a modal
        // telling them to remove the users first.
        showWarning = true;
      }
      return {
        showWarning: showWarning,
        showArchiveModal: true,
      };
    });
  };

  closeArchiveModal = () => {
    this.setState({
      showWarning: false,
      showArchiveModal: false,
    });
  };

  archiveSchool = () => {
    let school = Object.assign({}, this.state.selectedSchool);
    school.status = SCHOOLSTATUSES.archived;
    this.setState({ selectedSchool: school });
    this.props.updateSchool(school);
  };

  unarchiveSchool = school => {
    school.status = SCHOOLSTATUSES.active;
    this.props.updateSchool(school);
  };

  renderArchiveModal = school => {
    if (this.state.showWarning) {
      // the user tried to archive a school that has admins associated with it.
      // render this modal to tell them to get their act together.
      // eng note: this is funky because the user is already two modals deep
      // and the second one is based on school data.
      return (
        <DeactivateModal
          show={this.state.showArchiveModal}
          // hide both archived modal and school edit modal
          onHide={() => {
            this.closeArchiveModal();
            this.hideModal();
          }}
          // if they click back, toggle the archive modal but don't
          // close the school edit modal
          onCancel={this.closeArchiveModal}
          // the save button just says "Got it!" and doesn't actually save,
          // since the message told them there are school admins still attached
          // to this school. so just close the modals.
          onSave={() => {
            this.closeArchiveModal();
            this.hideModal();
          }}
          header={'Archive School'}
          mainText={'You cannot archive a school with assigned School Admins.'}
          subText={'Please reassign School Admins at this school then try again.'}
          saveButtonText={'Got it!'}
          backButtonText={'Back'}
          icon={SchoolIcon}
        />
      );
    } else {
      return (
        <DeactivateModal
          show={this.state.showArchiveModal}
          onHide={() => {
            this.closeArchiveModal();
            this.hideModal();
          }}
          // same behavior as onHide for this modal
          onCancel={() => {
            this.closeArchiveModal();
            this.hideModal();
          }}
          onSave={() => {
            this.archiveSchool();
            this.closeArchiveModal();
            this.hideModal();
          }}
          header={'Archive School'}
          mainText={`Are you sure you want to archive ${school.name}?`}
          subText={'This can be undone by clicking "Show Archived Schools" any time.'}
          saveButtonText={'Archive This School'}
          backButtonText={'Cancel'}
          icon={SchoolIcon}
        />
      );
    }
  };

  render() {
    return (
      <div className="card no-padding">
        <div className="header-div">
          <div className="flex">
            <img className="mr1" src={SchoolIcon} alt="" />
            <h3>Manage Schools</h3>
          </div>
          <button className="edit-button" onClick={() => this.setState({ displayCreate: true })}>
            + Add school
          </button>
        </div>
        <div className="districtaccount-row header schools">
          <div className="name">School Name</div>
          <div style={{ minWidth: '20px' }}></div>
          <div className="location">School Location</div>
          {/* Only district/super admins in non-posting-only districts can view groups  */}
          {auth.isDistrictAdmin() && !auth.inPostingOnlyDistrict() && (
            <div className="groups">Groups</div>
          )}
        </div>
        {this.props.schools
          .filter(s => s.status === SCHOOLSTATUSES.active)
          .map(school => (
            <div key={school.id} className="districtaccount-row schools">
              <p className="name">{school.name}</p>
              <p style={{ minWidth: '20px' }}>
                {!school.geocoded_location && (
                  <img src={XError} alt="error" style={{ width: '8px' }} />
                )}
              </p>
              <p className="location">{school.location}</p>
              {/* Only district/super admins in non-posting-only districts can view groups */}
              {auth.isDistrictAdmin() && !auth.inPostingOnlyDistrict() && (
                <SchoolGroupsCell schoolId={school.id} />
              )}
              <img
                className="edit"
                src={EditIcon}
                alt="edit"
                onClick={() => this.editSchool(school)}
              />
              {this.state.selectedSchool.id === school.id && (
                <SchoolAccountModal
                  school={school}
                  displayModal={
                    this.state.selectedSchool.id === school.id && !this.state.showArchiveModal
                  }
                  errors={this.state.errors}
                  onHide={this.hideModal}
                  onSave={this.handleUpdateSchool}
                  openArchiveModal={this.openArchiveModal}
                />
              )}
              {this.state.selectedSchool.id === school.id && this.renderArchiveModal(school)}
            </div>
          ))}
        {auth.isSuperAdminUser() &&
          this.props.schools.filter(s => s.status === SCHOOLSTATUSES.archived).length > 0 && (
            <div>
              <div
                className={`toggle-div ${this.state.showArchivedSchools ? 'expanded' : ''}`}
                onClick={this.toggleActiveSchools}
              >
                {this.state.showArchivedSchools ? 'Hide' : 'Show'}{' '}
                {this.props.schools.filter(s => s.status === SCHOOLSTATUSES.archived).length}{' '}
                archived schools
                <img
                  src={this.state.showArchivedSchools ? IconCaretUp : IconCaretDown}
                  alt="show deactivated users"
                />
              </div>
              <div
                className={`card no-padding additional-section ${
                  this.state.showArchivedSchools ? 'expanded' : ''
                }`}
              >
                {this.props.schools
                  .filter(s => s.status === SCHOOLSTATUSES.archived)
                  .map(school => (
                    <div key={school.id} className="districtaccount-row schools">
                      <h4 className="name">{school.name}</h4>
                      <p className="location">{school.location}</p>
                      <span className="reactivate" onClick={() => this.unarchiveSchool(school)}>
                        Unarchive school
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          )}
        {this.state.displayCreate && (
          <SchoolAccountModal
            school={{ name: '', location: '', school_groups: [] }}
            displayModal={this.state.displayCreate}
            errors={this.state.errors}
            onHide={this.hideModal}
            onSave={this.handleCreateSchool}
          />
        )}
      </div>
    );
  }
}

/**
 * Bit of a randomly ghetto way to truncate a string.
 */
const truncateString = (string, maxLength, delimiter) => {
  let removedCount = 0;
  if (string.length > maxLength) {
    for (let i = string.length - 1; i > maxLength; i--) {
      if (string.charAt(i) === ',') {
        removedCount = removedCount + 1;
      }
    }
    let truncatedString = string.substring(0, maxLength);
    truncatedString = truncatedString.substring(0, truncatedString.lastIndexOf(delimiter));
    return truncatedString;
  } else {
    return string;
  }
};

/**
 * Displays a list of the school groups of a school.
 *
 * Custom truncation as per requirements:
 * "Group One, Group Two, and x more...."
 * This was a bit hard to do in a correct way, so this is a pretty ghetto implementation
 * Ideally, we would want to draw the container, get the size of the container,
 * and then figure out how many px that size can hold and truncate accordingly.
 * This ghetto way pretty much only works on desktop, but it's good enough for now.
 */
const SchoolGroupsCell = ({ schoolId }) => {
  const schoolGroups = useSchoolGroups();

  const taggedGroups = schoolGroups.data.filter(schoolGroup =>
    schoolGroup.schools.includes(schoolId)
  );
  let listOfGroupNames = taggedGroups.map(group => group.name).join(', ');

  // Business Requirements are to show X, Y, and 2 more....
  // Instead of just normal truncation.
  // Found it a bit difficult to try render the string and find the size in px
  // So just hardcoding 30 here because it looks alright and probably good enough
  // Until this page gets a redesign to
  let truncatedString = truncateString(listOfGroupNames, 30, ',');
  const occurences = (truncatedString.match(/, /g) || []).length;
  if (occurences < taggedGroups.length - 1) {
    truncatedString = truncatedString + `, and ${taggedGroups.length - occurences - 1} more...`;
  }

  return <p className="groups">{truncatedString}</p>;
};
