import React from 'react';
import { Chip } from 'sharedComponents/Chip';
import { ConnectBadgeLabels } from 'features/MarketplaceV2/utils/connectEnums';
import { nimbleTheme } from 'theme';
import { SecondarySchoolTagsBadgeProps } from './types';

export const SecondarySchoolTagsBadge: React.FC<SecondarySchoolTagsBadgeProps> = ({ tagLabel }) => {
  return (
    <Chip
      label={`● ${ConnectBadgeLabels[tagLabel]}`}
      variant="filled"
      sx={{
        color: nimbleTheme.palette.blue.accents.blue60,
        fontWeight: nimbleTheme.typography.fontWeightMedium,
        marginX: nimbleTheme.spacing(1),
        border: `1px solid ${nimbleTheme.palette.blue.accents.blue60}`,
        background: nimbleTheme.palette.blue.accents.blue10,
      }}
    />
  );
};
