import '../../CandidatesList/QuickProfileCandidate/copyappmodal.css';

import { Component } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ErrorText from '../../errortext';
import { appStatusType } from '../../../utils/enums';
import { StyledSelect } from 'ui-kit';
import Dropdown from 'react-dropdown';
import LoadingSpinner from 'components/loadingSpinner';

import schoolsAPI from 'api/schoolsAPI';

export default class SchoolApplicationsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schools: props.schoolapplications.map(s => {
        return {
          school_id: s.school.id,
          school_name: s.school.name,
          app_status_id: s.new_status.id,
          app_status_label: s.new_status.label,
        };
      }),
      schoolOptions: [],
      error: '',
      staging: {
        school_id: '',
        school_name: '',
        app_status_id: '',
        app_status_label: '',
      },
      isSubmitting: false,
    };
  }

  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    schoolapplications: PropTypes.array.isRequired,
    role: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    newApplicationStatuses: PropTypes.array.isRequired,
    replaceCandidateInState: PropTypes.func.isRequired,
    fetchEvents: PropTypes.func.isRequired,
  };

  componentDidMount() {
    schoolsAPI.fetchActiveSchools().then(schools => {
      this.setState({ schoolOptions: schools.map(s => ({ value: s.id, label: s.name })) });
    });
  }

  handleChange = (obj, index) => {
    let schools = this.state.schools;
    schools[index].app_status_id = obj.value;
    schools[index].app_status_label = obj.label;
    this.setState({
      schools,
      error: '',
    });
  };

  removeLineItem = index => {
    let schools = this.state.schools;
    schools.splice(index, 1);
    this.setState({ schools });
  };

  addItem = () => {
    let schools = this.state.schools.slice();
    let staging = Object.assign({}, this.state.staging);
    if (staging.school_id === '') {
      this.setState({ error: 'Please select a school to add.' });
      return;
    }
    // make sure that school is not already in the list
    for (let i = 0; i < schools.length; i++) {
      if (schools[i].school_id === staging.school_id) {
        this.setState({ error: 'That school has already been added.' });
        return;
      }
    }

    let defaultStatus = this.props.newApplicationStatuses.find(
      s => s.status_type === appStatusType.hiring_pool
    );
    if (!defaultStatus) {
      // If hiring pool is not an available status on this role, find the first status post
      // hiring pool.
      defaultStatus = this.props.newApplicationStatuses.find(
        s => s.status_type === appStatusType.post_hiring_pool
      );
    }

    if (staging.app_status_id === '') {
      // default to hiring pool status if none selected
      staging.app_status_id = defaultStatus.id;
      staging.app_status_label = defaultStatus.label;
    }

    schools.push(staging);

    let newStagingItem = {
      school_id: '',
      school_name: '',
      app_status_id: defaultStatus.id,
      app_status_label: defaultStatus.label,
    };
    this.setState({ schools, staging: newStagingItem });
  };

  handleStagingChange = e => {
    let staging = Object.assign({}, this.state.staging);
    let status = this.props.newApplicationStatuses.find(s => s.id === Number(e.target.value));
    staging.app_status_id = status.id;
    staging.app_status_label = status.label;
    this.setState({ staging, error: '' });
  };

  onSave = () => {
    let schools = this.state.schools;
    // totally unnecessary optimization but hey it's already here
    // on second thought, some of our users could have lots of schools, so this is totally
    // necessary!
    let uniqueSet = new Set(); // use a set (O(1) lookup time) to keep track of duplicates
    for (let i = 0; i < schools.length; i++) {
      if (schools[i].school_id === '') {
        this.setState({ error: 'Please select a school for each line item.' });
        return;
      }
      if (uniqueSet.has(Number(schools[i].school_id))) {
        this.setState({ error: 'Please ensure the target schools are unique.' });
        return;
      }
      uniqueSet.add(Number(schools[i].school_id));
    }

    this.saveSchoolApps();
  };

  saveSchoolApps = () => {
    this.setState({ isSubmitting: true });

    const schoolsList = this.state.schools;
    const role_id = this.props.role.id;
    /* remove schoolapplications that no longer exist */
    const removeSchoolApps = this.props.schoolapplications.filter(
      schoolapp => !schoolsList.map(s => Number(s.school_id)).includes(schoolapp.school.id)
    );

    const schoolsToAdd = schoolsList.filter(s => {
      /** search candidate's existing schoolapplications to see if she already has
       * a schoolapplication in given status for given school. If so, no need to
       * send to backend.
       */
      const index = this.props.schoolapplications.findIndex(
        sa => sa.school.id === Number(s.school_id) && sa.new_status.id === Number(s.app_status_id)
      );
      return index === -1;
    });

    // send over the rest to update or create
    if (schoolsToAdd.length > 0 || removeSchoolApps.length > 0) {
      axios
        .put('/api/schoolapplications/manual_update_school_apps/', {
          candidate_id: this.props.user.id,
          schools_to_add: schoolsToAdd,
          schools_to_delete: removeSchoolApps.map(s => s.id),
          district_role_id: role_id,
        })
        .then(({ data }) => {
          this.props.replaceCandidateInState(data);
          this.props.onHide();
          this.props.fetchEvents();
        });
    }
  };

  handleMultiSelectChange = obj => {
    let staging = Object.assign({}, this.state.staging);
    staging.school_id = obj ? obj.value : '';
    staging.school_name = obj ? obj.label : '';
    this.setState({ staging, error: '' });
  };

  render() {
    let statusOptions = this.props.newApplicationStatuses.filter(
      s => s.status_type >= appStatusType.hiring_pool && s.school_admin_view_access === true
    );

    // only show onboarding status options if at least one schoolapp is in hired or
    // an onboarding status.
    if (
      this.props.schoolapplications.findIndex(a =>
        [appStatusType.hired, appStatusType.onboarding].includes(a.new_status.status_type)
      ) === -1
    ) {
      statusOptions = statusOptions.filter(s => s.status_type !== appStatusType.onboarding);
    }

    // set options to relevant format
    statusOptions = statusOptions.map(s => {
      return { label: s.label, value: s.id };
    });

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        bsSize="large"
        dialogClassName="copy-app-modal edit-schools"
      >
        <Modal.Header />
        <Modal.Body>
          <div className="copy-app-modal-title">
            <h3>Select Schools</h3>
          </div>
          <span className="copy-app-modal-close" onClick={this.props.onHide}>
            (X) CLOSE
          </span>
          <div className="edit-schools-modal-section">
            <h4>
              {`Candidate application currently visible to ${this.state.schools.length} school${
                this.state.schools.length === 1 ? '' : 's'
              }`}
            </h4>
            <div className="edit-schools-modal-school-list">
              {this.state.schools.map((item, index) => (
                <div className="edit-schools-modal-school-row" key={index}>
                  <div className="edit-schools-school-name">{item.school_name}</div>

                  <Dropdown
                    options={statusOptions}
                    onChange={obj => this.handleChange(obj, index)}
                    value={item.app_status_label}
                    className="permissions-dropdown"
                  />

                  <div
                    className="jobedit-question-delete remove-line-item"
                    onClick={() => this.removeLineItem(index)}
                  >
                    <span className="Select-clear">×</span>
                  </div>
                </div>
              ))}
            </div>
            <h4>Add school</h4>
            <div className="edit-schools-staging-row">
              <StyledSelect
                border="0"
                boxShadow="0 2px 2px 0 rgba(0, 0, 0, 0.1)"
                className="flex-1 mr1"
                options={this.state.schoolOptions}
                placeholder="Search by school"
                onChange={this.handleMultiSelectChange}
                isClearable
              />
              <div className="select-form-field jobedit-select-div copy-app-select-role">
                <select
                  type="text"
                  name="status"
                  value={this.state.staging.app_status_id}
                  onChange={this.handleStagingChange}
                >
                  {statusOptions.map((statusObj, i) => (
                    <option key={i} value={statusObj.value}>
                      {statusObj.label}
                    </option>
                  ))}
                </select>
              </div>
              <AddButton onClick={this.addItem}>Add</AddButton>
            </div>

            <hr />

            <div className="flex flex-wrap space-between">
              <button className="edit-schools-button" onClick={this.props.onHide}>
                Cancel
              </button>
              <div className="edit-schools-flex-center">
                <div className="mr1">
                  {this.state.error && <ErrorText message={this.state.error} />}
                </div>
                <button
                  className="edit-schools-button save"
                  onClick={this.onSave}
                  disabled={this.state.isSubmitting}
                >
                  {this.state.isSubmitting ? (
                    <LoadingSpinner margin={0} fontSize={1} />
                  ) : (
                    'Save & Close'
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const AddButton = styled.button`
  background-color: #bdbdbd;
  border: 0;
  color: white;
  width: 94px;
  border-radius: 3px;
  font-size: 14px;
  height: 50px;
`;
