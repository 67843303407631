import { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

class ProspectInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: '',
      lname: '',
      email: '',
      errors: {},
    };
  }

  static propTypes = {
    submitProspect: PropTypes.func.isRequired,
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    this.showInputError(event.target.name);
  };

  // TODO this should all be HTML5 form validation or some other plugin.
  showInputError = refName => {
    let errorText = '';
    const validity = this.refs[refName].validity;
    const isPassword = refName === 'password';
    const isPasswordConfirm = refName === 'passwordConfirm';
    if (isPasswordConfirm) {
      if (this.refs.password.value !== this.refs.passwordConfirm.value) {
        this.refs.passwordConfirm.setCustomValidity('Passwords do not match');
      } else {
        this.refs.passwordConfirm.setCustomValidity('');
      }
    }

    if (!validity.valid) {
      if (validity.valueMissing) {
        errorText = 'This is a required field';
      } else if (validity.typeMismatch) {
        errorText = 'Please use a valid email address';
      } else if (isPassword && validity.patternMismatch) {
        errorText = 'Password must be 6 or more characters.';
      } else if (isPasswordConfirm && validity.customError) {
        errorText = 'Passwords do not match.';
      }
    }

    this.setState({
      errors: { [refName]: errorText },
    });
  };

  checkFormValid = () => _.every(this.refs, r => r.validity.valid === true);

  handleSubmit(event) {
    event.preventDefault();
    if (!this.checkFormValid()) {
      console.log('Login form is invalid');
      return;
    }

    this.props
      .submitProspect(this.state.fname, this.state.lname, this.state.email)
      .catch(response => this.setState({ errors: { global: response } }))
      .then(() => {
        this.setState({
          fname: '',
          lname: '',
          email: '',
        });
      });
    event.preventDefault();
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit.bind(this)} method="post">
        <div className="login-form-bottom-section">
          <div>
            <div className="flex form-name-container">
              <div className="form-entry half">
                <label>
                  <input
                    name="fname"
                    ref="fname"
                    type="text"
                    placeholder="First Name"
                    value={this.state.fname || ''}
                    required
                    onChange={this.handleChange}
                    className="first-name"
                  />
                </label>
              </div>
              <div className="form-entry half">
                <label>
                  <input
                    name="lname"
                    ref="lname"
                    type="text"
                    placeholder="Last Name"
                    value={this.state.lname || ''}
                    required
                    onChange={this.handleChange}
                    className="last-name"
                  />
                </label>
              </div>
            </div>
            <p className="error-text half">{this.state.errors.fname || this.state.errors.lname}</p>
          </div>
          <div className="form-entry">
            <label>
              <input
                name="email"
                ref="email"
                type="email"
                placeholder="Email Address"
                value={this.state.email || ''}
                required
                onChange={this.handleChange}
                className="email-address"
              />
              <p className="error-text">{this.state.errors.email}</p>
            </label>
          </div>
          <input className="btn btn-success" type="submit" value="Submit" />
          {this.state.errors.global && <p className="global-error">{this.state.errors.global}</p>}
        </div>
      </form>
    );
  }
}

export default ProspectInformation;
