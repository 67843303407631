import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { moreInfoOnClickHandler } from 'features/MarketplaceV2/features/ExpressInterest/utils';
import { Inter } from 'features/MarketplaceV2/features/JobDiscovery/utils';
import { ExpressInterestButton } from '../Buttons/ExpressInterestButton';
import { SaveSchoolButton } from '../Buttons/SaveSchoolButton';
import { SchoolCardProps } from '../types';
import { OnPlatformBadge } from '../InfoBadges/OnPlatformBadge';
import { RecommendedBadge } from '../InfoBadges/RecommendedBadge';
import { renderSecondarySchoolTags } from 'features/MarketplaceV2/features/JobDiscovery/utils';

//Mobile header should be updated with new changes as well further down in this file

export const Header: React.FC<SchoolCardProps> = ({ school, actions }) => {
  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: '-10px',
      }}
    >
      <Stack direction="row" alignItems="center">
        <SchoolNameContainer href="#" onClick={() => moreInfoOnClickHandler(school, actions, '0')}>
          <SchoolName>{school?.name}</SchoolName>
        </SchoolNameContainer>
        {school.is_recommended && <RecommendedBadge />}
        {school?.principal?.on_connect && <OnPlatformBadge />}
        {renderSecondarySchoolTags(school)}
      </Stack>
      <Stack direction={'row'} spacing={'8px'}>
        <SaveSchoolButton school={school} actions={actions} />
        <ExpressInterestButton school={school} actions={actions} />
      </Stack>
    </Stack>
  );
};

const SchoolNameContainer = styled(Link)(() => ({
  flexDirection: 'row',
  color: '#101828',
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const MobileHeader: React.FC<SchoolCardProps> = ({ school, actions }) => {
  return (
    <Stack
      direction="row"
      gap={'8px'}
      alignItems={'center'}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
        width: '100%',
        marginBottom: '16px',
      }}
    >
      <SchoolNameContainer href="#" onClick={() => moreInfoOnClickHandler(school, actions, '0')}>
        <SchoolNameMobile>{school?.name}</SchoolNameMobile>
      </SchoolNameContainer>
      <SaveSchoolButton
        school={school}
        actions={actions}
        size="small"
        sx={{
          borderRadius: '8px',
          minWidth: '28px',
          width: '28px',
          height: '28px',
          padding: 0,
          alignItems: 'center',
        }}
      />
    </Stack>
  );
};

const SchoolName = styled(Typography)(() => ({
  fontFamily: Inter,
  fontSize: '18px',
  fontWeight: 500,
  color: '#101828',
}));

const SchoolNameMobile = styled(Typography)(() => ({
  fontFamily: Inter,
  fontSize: '18px',
  fontWeight: 500,
  Color: '#101828',
  alignItems: 'center',
  padding: 'auto',
}));
