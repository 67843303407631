import styled from 'styled-components';
import { SingleClickDropdown } from 'ui-kit';
import { appStatusType } from 'utils/enums';

const StatusDropdown = ({ application, handleStatusChange, showColor }) => (
  <SingleClickDropdown.Dropdown
    label={application.status.label || 'Select a status'}
    border={true}
    boxShadow={false}
    showColor={showColor}
    paddingLeft="2.5em"
  >
    {application.role.statuses_available
      ?.filter(status => status.status_type !== appStatusType.draft)
      .map(status => (
        <StyledListItem key={status.id} onClick={() => handleStatusChange(status)}>
          {status.label}
        </StyledListItem>
      ))}
  </SingleClickDropdown.Dropdown>
);

export default StatusDropdown;

const StyledListItem = styled(SingleClickDropdown.ListItem)`
  padding: 6px 12px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray);
  }

  &:first-child {
    padding-top: 12px;
  }

  &:last-child {
    padding-bottom: 12px;
  }
`;
