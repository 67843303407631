import { getQueryStringValue } from 'utils/util';
import auth from 'utils/auth';

export const HRBP_ASSIGNEE_FIELD_NAME = 'hrbp_assignees';

export const emptyFilterState = {
  query: '',
  sortField: '',
  sortDescending: false,
  grades: [],
  categories: [],
  hiring_seasons: [],
  internal_external: [],
  job_owners: [],
  job_status_list: [],
  hrbp_assignees: [],
  district_users: [],
  schools: [],
};

export const fetchAndSetQueryParameters = (isTemplate, setState) => {
  const { search } = location;
  const queryParameters = new URLSearchParams(search);
  let job_status_list = [];
  const isPostingOnlyNoTemplates = auth.inPostingOnlyDistrict() && !isTemplate;

  if (isPostingOnlyNoTemplates || isTemplate) {
    job_status_list = [];
  }

  let categories = [];
  let grades = [];
  let hiring_seasons = [];
  let internal_external = [];
  let job_owners = [];
  let district_users = [];
  let schools = [];
  let hrbp_assignees = [];

  // Parse query strings for any default search params
  const jobStatusListQueryString = getQueryStringValue('f_JS');
  const categoriesQueryString = getQueryStringValue('f_CA');
  const gradesQueryString = getQueryStringValue('f_G');
  const hiringSeasonsQueryString = getQueryStringValue('f_HS');
  const internalExternalQueryString = getQueryStringValue('f_IX');
  const jobOwnersQueryString = getQueryStringValue('f_JO');
  const districtUsersQueryString = getQueryStringValue('f_DU');
  const schoolsQueryString = getQueryStringValue('f_S');
  const hrbpAssigneesQueryString = getQueryStringValue('f_HA');

  if (jobStatusListQueryString) {
    job_status_list = jobStatusListQueryString.split(',');
  }

  if (job_status_list.includes('active_closed') && auth.inPostingOnlyDistrict()) {
    job_status_list = job_status_list.filter(value => value !== 'active_closed');
  }

  if (categoriesQueryString) {
    categories = categoriesQueryString.split(',').map(i => {
      if (i === 'N/A') return i;
      return Number(i);
    });
  }

  if (gradesQueryString) {
    grades = gradesQueryString.split(',').map(i => {
      if (i === 'N/A') return i;
      return Number(i);
    });
  }

  if (hiringSeasonsQueryString) {
    hiring_seasons = hiringSeasonsQueryString.split(',').map(i => Number(i));
  }

  if (internalExternalQueryString) {
    internal_external = internalExternalQueryString.split(',');
  }

  if (jobOwnersQueryString) {
    job_owners = jobOwnersQueryString.split(',').map(i => {
      if (i === 'N/A') return i;
      return Number(i);
    });
  }

  if (districtUsersQueryString) {
    district_users = districtUsersQueryString.split(',').map(i => {
      if (i === 'N/A') return i;
      return Number(i);
    });
  }

  if (schoolsQueryString) {
    schools = schoolsQueryString.split(',').map(i => {
      if (i === 'N/A') return i;
      return Number(i);
    });
  }

  if (hrbpAssigneesQueryString) {
    hrbp_assignees = hrbpAssigneesQueryString.split(',').map(i => parseInt(i));
  }

  setState(previousState => ({
    ...previousState,
    query: queryParameters.get('query') || '',
    job_status_list,
    grades,
    categories,
    hiring_seasons,
    internal_external,
    job_owners,
    district_users,
    schools,
    hrbp_assignees,
  }));
};
