import { Component } from 'react';
import styled from 'styled-components';

import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Tooltip from '../../assets/tooltip.svg';
import ReactTooltip from 'react-tooltip';
import { appStatusType } from '../../utils/enums';
import LocationsModalSearch from '../common/LocationsModalSearch';
import { searchStringInObjectKeys } from '../../utils/locationsModalUtil';

export default class StatusModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationsObject: {},
      archiveDistrictRoleSelected: false,
      archiveOtherApps: true,
      offerDeclinedDistrictRoleSelected: false,
      locationsObjectShowing: {},
      allSchoolsSelected: false,
    };
  }

  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    schoolapplications: PropTypes.arrayOf(Object),
    updateSchoolApp: PropTypes.func.isRequired,
    onSave: PropTypes.func,
    desiredStatus: PropTypes.object.isRequired,
    updateUserStatus: PropTypes.func.isRequired,
    userId: PropTypes.number,
    applicationBeingQuickviewed: PropTypes.object,
  };

  componentDidMount() {
    const locationsObject = {};
    this.props.schoolapplications.forEach(s => {
      locationsObject[s.school.name] = 0;
    });
    this.setState({ locationsObject, locationsObjectShowing: locationsObject });
  }

  componentWillUnmount() {
    this.ignoreLastFetch = true;
  }
  // On save, update any of the schools that were checked
  handleSave = () => {
    // See which schools were selected
    let schoolsChecked = Object.entries(this.state.locationsObject).filter(schoolName => {
      return schoolName[1] > 0;
    });

    let schoolappsToModify = [];
    schoolsChecked.forEach(school => {
      this.props.schoolapplications.forEach(schoolapp => {
        if (school[0] === schoolapp.school.name) {
          schoolappsToModify.push(schoolapp);
        }
      });
    });
    // Archiving district application if selected that button OR all school applications are selected
    if (this.state.archiveDistrictRoleSelected || this.state.offerDeclinedDistrictRoleSelected) {
      const application_to_update = this.props.applicationBeingQuickviewed
        ? this.props.applicationBeingQuickviewed.id
        : '';

      this.props.updateUserStatus({
        candidate_id: this.props.user.id,
        target_status: this.props.desiredStatus.id,
        application_to_update,
      });
    } else {
      if (schoolappsToModify.length > 0) {
        // multiple school apps to update but no notifications. So update all here.
        schoolappsToModify.forEach(s => {
          this.props.updateSchoolApp({
            candidate_id: this.props.user.id,
            application_id: s.id,
            target_status: this.props.desiredStatus.id,
            archiveOtherApps: this.state.archiveOtherApps,
          });
        });
      }
    }
    // Save with container function
    this.props.onHide();
  };

  updateCheckbox = event => {
    const districtAppTargetStatusType = this.isStatusType('archived')
      ? 'archiveDistrictRoleSelected'
      : 'offerDeclinedDistrictRoleSelected';
    let updateDistrictAppTargetStatusType = this.state[districtAppTargetStatusType];
    const updateLocations = this.state.locationsObject;
    // If it's on 'hired' status, only one box can be checked
    if (this.isStatusType('hired')) {
      const statusBeforeRevert = updateLocations[event.target.name];
      Object.keys(this.state.locationsObject).forEach(location => {
        updateLocations[location] = 0;
      });
      if (statusBeforeRevert > 0) {
        updateLocations[event.target.name] = 0;
      } else {
        updateLocations[event.target.name] = 1;
      }
      this.setState({ locationsObject: updateLocations });
    } else {
      if (updateLocations[event.target.name] > 0) {
        updateLocations[event.target.name] = 0;
        // If unchecking any school, uncheck the district application
        updateDistrictAppTargetStatusType = false;
      } else {
        updateLocations[event.target.name] = 1;
      }
      this.setState({
        locationsObject: updateLocations,
        [districtAppTargetStatusType]: updateDistrictAppTargetStatusType,
      });
    }
  };

  /* Archiving a district application will check all the school applications */
  toggleDistrictRole = event => {
    const name = event.target.name;
    const changingTo = !this.state[name];
    // If checked the box, check all the schools too
    if (changingTo) {
      const updateLocations = this.state.locationsObject;
      Object.keys(updateLocations).forEach(location => {
        updateLocations[location] = 1;
      });
      this.setState({ locationsObject: updateLocations });
    }
    this.setState({
      [name]: changingTo,
    });
  };

  toggleArchiveOtherApps = () => {
    this.setState({ archiveOtherApps: !this.state.archiveOtherApps });
  };

  schoolSearchChange = value => {
    this.setState({
      locationsObjectShowing: searchStringInObjectKeys(value, this.state.locationsObject),
    });
  };

  schoolOpeningRow = (schoolName, openPositions) => {
    return (
      <div className="flex locations-modal-row-container" key={schoolName}>
        <div className="locations-modal-name">
          <input
            type="checkbox"
            name={schoolName}
            value={openPositions > 0}
            checked={openPositions > 0}
            onChange={event => this.updateCheckbox(event)}
          />
          &nbsp;&nbsp;&nbsp;
          <span className="bold">{schoolName}</span>
        </div>
      </div>
    );
  };

  schoolAttachedList = () => {
    if (Object.keys(this.state.locationsObject).length === 0) {
      return (
        <div className="flex ml2">
          No schools yet! Visit the {/* couldn't get space to work here, so added it with css */}
          <Link to="/district/account/" className="account-link">
            account
          </Link>
          page to add schools.
        </div>
      );
    } else {
      return (
        <div className="location-scroll-div">
          {Object.entries(this.state.locationsObject)
            .sort()
            .map(([schoolName, openPositions]) => {
              return this.state.locationsObjectShowing.hasOwnProperty(schoolName)
                ? this.schoolOpeningRow(schoolName, openPositions)
                : null;
            })}
        </div>
      );
    }
  };

  handleSelectAllSchools = e => {
    let updatedLocations = {};
    let updatedLocationsShowing = {};
    if (e.target.checked) {
      Object.entries(this.state.locationsObject).forEach(([schoolName, openPosition]) => {
        const position = openPosition > 0 ? openPosition : 1;
        updatedLocations[schoolName] = position;
        if (this.state.locationsObjectShowing.hasOwnProperty(schoolName)) {
          updatedLocationsShowing[schoolName] = position;
        }
      });
    } else {
      Object.entries(this.state.locationsObject).forEach(([schoolName, openPosition]) => {
        updatedLocations[schoolName] = 0;
        if (this.state.locationsObjectShowing.hasOwnProperty(schoolName)) {
          updatedLocationsShowing[schoolName] = 0;
        }
      });
    }

    this.setState({
      locationsObject: updatedLocations,
      locationsObjectShowing: updatedLocationsShowing,
      allSchoolsSelected: !this.state.allSchoolsSelected,
    });
  };

  isStatusType = type => {
    // We check this a lot in this component. Example usage to check if desired status is archived:
    // if (isStatusType('archived')) { ... }
    return this.props.desiredStatus.status_type === appStatusType[type];
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        bsSize="large"
        dialogClassName="locations-modal status-change-modal"
      >
        <Modal.Header />
        <Modal.Body>
          <div className="locations-modal-title">
            <h3>Select Locations</h3>
            {this.isStatusType('hired') && (
              <div className="hired-advisory">
                You may only select one location for "hired" status.
              </div>
            )}
          </div>
          <span className="locations-modal-close" onClick={this.props.onHide}>
            (X) CLOSE
          </span>
          <div className="locations-modal-section">
            <div className="flex locations-modal-row-container">
              <div className="locations-modal-name">
                {this.props.schoolapplications[0]
                  ? this.props.schoolapplications[0].role.title
                  : null}
              </div>
            </div>

            {this.isStatusType('archived') && (
              <div className="locations-modal-name">
                <input
                  type="checkbox"
                  value={this.state.archiveDistrictRoleSelected > 0}
                  checked={this.state.archiveDistrictRoleSelected > 0}
                  onChange={event => this.toggleDistrictRole(event)}
                  name="archiveDistrictRoleSelected"
                />
                &nbsp;&nbsp;&nbsp;
                <span className="bold">Archive district application</span>
                <img className="mbhalf" src={Tooltip} alt="tooltip" data-tip data-for="category" />
                <ReactTooltip id="category" effect="solid">
                  <span>
                    By archiving the district-level application, you not only archive all current
                    school applications, but you also remove this candidate from future
                    consideration for this role.
                  </span>
                </ReactTooltip>
              </div>
            )}
            <LocationsModalSearch schoolSearchChange={this.schoolSearchChange} />
            <StyledSelectAll>
              Select all schools
              <input
                type="checkbox"
                checked={this.state.allSchoolsSelected}
                onClick={this.handleSelectAllSchools}
              />
            </StyledSelectAll>
            {this.schoolAttachedList()}
            <div className="flex modal-footer-container">
              <button
                className="locations-modal-button locations-modal-save"
                onClick={this.handleSave}
              >
                Save & Close
              </button>
              {this.isStatusType('hired') && (
                <div>
                  <input
                    type="checkbox"
                    value={this.state.archiveOtherApps}
                    checked={this.state.archiveOtherApps}
                    onChange={this.toggleArchiveOtherApps}
                  />
                  <span>Archive all other applications</span>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const StyledSelectAll = styled.div`
  padding: 1.5em;

  > input {
    margin: 0 0 0 1em;
  }
`;
