import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Basics from './Basics';
import CandidateTasks from '../UniversalTasks/CandidateTasks';
import CustomCandidateFields from '../CustomCandidateFields';
import LoadingSpinner from '../loadingSpinner';
import ProfileTimeline from './ProfileTimeline/ProfileTimeline';
import InternalCandidateNotes from './InternalCandidateNotes';
import QuickProfileApplicationSection from './ProfileApplicationSection/QuickProfileApplicationSection';
import References from './References';
import ActivitiesFeed from './ActivitiesFeed';
import LinkProfileSection from './LinkProfilesSection';
import auth from '../../utils/auth';

import { appStatusType } from 'utils/enums';

import rightCaret from '../../assets/icon_right_caret_grey.svg';
import LinkedProfile from './LinkedProfile';

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activitiesFeedMode: false,
      referenceParamSeen: false,
    };

    this.getUser = auth.getUser();
    this.hasOnboardingModule = auth.hasOnboardingModule();
  }

  static propTypes = {
    adminUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
    sources: PropTypes.arrayOf(PropTypes.object).isRequired,
    user: PropTypes.object.isRequired,
    updateSchoolApp: PropTypes.func.isRequired,
    updateUserStatus: PropTypes.func.isRequired,
    events: PropTypes.array.isRequired,
    scorecardParam: PropTypes.number,
    referenceParam: PropTypes.number,
    removeLocationQuery: PropTypes.func,
    scorecardSeen: PropTypes.bool,
    emailTemplatesList: PropTypes.array.isRequired,
    printBool: PropTypes.bool.isRequired,
    incrementScorecardCount: PropTypes.func.isRequired,
    newApplicationStatuses: PropTypes.array.isRequired,
    updateReference: PropTypes.func.isRequired,
    replaceCandidateInState: PropTypes.func.isRequired,
    updateCandidateInState: PropTypes.func.isRequired,
    fetchEvents: PropTypes.func.isRequired,
    updateCandidateTask: PropTypes.func.isRequired,
    fetchUser: PropTypes.func,
    references: PropTypes.array.isRequired,
    removeReference: PropTypes.func.isRequired,
    setReferencesError: PropTypes.func.isRequired,
    referencesError: PropTypes.string.isRequired,
    fetchReferences: PropTypes.func.isRequired,
    onBirthdayChange: PropTypes.func.isRequired,
    updateInternalCandidateNote: PropTypes.func.isRequired,
    emailTaggedUsers: PropTypes.func.isRequired,
  };

  markReferenceParamSeen = () => {
    this.setState({ referenceParamSeen: true });
  };

  render() {
    if (!this.props.user.id) {
      return <LoadingSpinner margin={3} />;
    }

    const isDraft = this.props.user.application.new_status.status_type === appStatusType.draft;
    const { ssn_required, birthday_required } = this.getUser.profile.district;

    // Limit the available application statuses by those available to its role
    const newApplicationStatuses = this.props.newApplicationStatuses.filter(s =>
      this.props.user.application.role.statuses_available.includes(s.id)
    );

    const isLinkedToAuthUser = auth.getUser().linked_users.includes(this.props.user.id);
    if (isLinkedToAuthUser) {
      return <LinkedProfile user={this.props.user} />;
    }

    return (
      <div className="full-profile-container">
        {this.state.activitiesFeedMode && (
          <div onClick={() => this.setState({ activitiesFeedMode: false })} className="pointer mb1">
            <img className="activity-feed-arrow" src={rightCaret} alt="arrow" /> Back to{' '}
            {this.props.user.first_name}'s profile
          </div>
        )}
        <div>
          <Basics
            user={this.props.user}
            updateUserStatus={this.props.updateUserStatus}
            adminUsers={this.props.adminUsers}
            sources={this.props.sources}
            emailTaggedUsers={this.props.emailTaggedUsers}
            updateSchoolApp={this.props.updateSchoolApp}
            scorecardParam={this.props.scorecardParam}
            removeLocationQuery={this.props.removeLocationQuery}
            scorecardSeen={this.props.scorecardSeen}
            emailTemplatesList={this.props.emailTemplatesList}
            printBool={this.props.printBool}
            isDraft={isDraft}
            incrementScorecardCount={this.props.incrementScorecardCount}
            newApplicationStatuses={newApplicationStatuses}
            replaceCandidateInState={this.props.replaceCandidateInState}
            updateCandidateInState={this.props.updateCandidateInState}
            fetchEvents={this.props.fetchEvents}
            events={this.props.events}
            fetchUser={this.props.fetchUser}
            activitiesFeedMode={this.state.activitiesFeedMode}
            applicationBeingQuickviewed={this.props.user.application}
          />

          {this.state.activitiesFeedMode && (
            <ActivitiesFeed user={this.props.user} events={this.props.events} />
          )}

          {!this.state.activitiesFeedMode && (
            <TwoColumnGridContainer>
              <InnerSection>
                <HeaderLabel>Internal Notes</HeaderLabel>
                <InternalCandidateNotes
                  notes={this.props.user.internal_candidate_notes}
                  updateNote={this.props.updateInternalCandidateNote}
                  adminUsers={this.props.adminUsers}
                />
              </InnerSection>
              <InnerSection>
                <HeaderLabel>Activity Feed</HeaderLabel>
                <ProfileTimeline
                  events={this.props.events}
                  user={this.props.user}
                  isAbridged={true}
                />
                <div
                  className="underline center mt2 pointer"
                  onClick={() => this.setState({ activitiesFeedMode: true })}
                >
                  View all activity
                </div>
              </InnerSection>
            </TwoColumnGridContainer>
          )}

          {/* reference section */}
          {!isDraft && !this.state.activitiesFeedMode && (
            <Section>
              <References
                user={this.props.user}
                references={this.props.references}
                adminUsers={this.props.adminUsers}
                applicationBeingViewed={this.props.user.application}
                emailTaggedUsers={this.props.emailTaggedUsers}
                emailTemplatesList={this.props.emailTemplatesList}
                updateReference={this.props.updateReference}
                removeReference={this.props.removeReference}
                setReferencesError={this.props.setReferencesError}
                referencesError={this.props.referencesError}
                fetchReferences={this.props.fetchReferences}
                fetchEvents={this.props.fetchEvents}
                referenceParam={this.props.referenceParam}
                referenceParamSeen={this.state.referenceParamSeen}
                markReferenceParamSeen={this.markReferenceParamSeen}
              />
            </Section>
          )}

          <Section>
            <CustomCandidateFields
              ssn_required={ssn_required}
              birthday_required={birthday_required}
              user={this.props.user}
              onBirthdayChange={this.props.onBirthdayChange}
            />
          </Section>

          {this.props.user.tasks.length > 0 && (
            <Section>
              <CandidateTasks
                user={this.props.user}
                updateCandidateTask={this.props.updateCandidateTask}
              />
            </Section>
          )}

          {!isDraft && (
            <Section>
              <QuickProfileApplicationSection
                user={this.props.user}
                districtApplication={this.props.user.application}
                isCandidateList
              />
            </Section>
          )}

          {auth.isSuperAdminUser() && (
            <Section>
              <LinkProfileSection user={this.props.user} />
            </Section>
          )}
        </div>
      </div>
    );
  }
}

const Section = styled.div`
  padding: 26px;
  margin-bottom: 1rem;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.095);
`;

const InnerSection = styled(Section)`
  margin-bottom: 0;
`;

const TwoColumnGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;

  margin-bottom: 1rem;
`;

const HeaderLabel = styled.h4`
  color: #444444;
  font-size: 19px;
  font-weight: 400;
  margin-bottom: 1rem;
`;
