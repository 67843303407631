import PropTypes from 'prop-types';
import styled from 'styled-components';
import { layout, space } from 'styled-system';

import CustomReferenceRow from './CustomReferenceRow';
import { Link, useHistory } from 'react-router-dom';
import { AnchorTagHeader } from 'sharedComponents/Header/AnchorTagHeader';
import { SectionMedium } from 'components/CustomScorecards/CustomScorecardList';

export default function CustomReferenceList({
  referenceList,
  defaultReferenceId,
  setDefaultReferenceId,
  clearDefaultReferenceId,
}) {
  const history = useHistory();
  const onDefaultReferenceChange = (templateId, isSelected) => {
    isSelected ? setDefaultReferenceId(templateId) : clearDefaultReferenceId();
  };

  const handleClick = () => history.push('/createreference');

  return (
    <SettingsPageSection>
      <AnchorTagHeader
        title="Reference Forms"
        buttonText="+ New Reference Form"
        onClick={handleClick}
        id="referenceForms"
      />

      <HeaderContainer>
        <SectionLarger>Title</SectionLarger>
        <SectionMedium>Question Type</SectionMedium>
        <Section>Visible To</Section>
        <Section>My Default</Section>
      </HeaderContainer>
      {referenceList.length === 0 && (
        <EmptyNotificationContainer>
          <EmptyNotificationPlaceholderText>
            Click "+ New Reference Form" button above to create new reference
          </EmptyNotificationPlaceholderText>
        </EmptyNotificationContainer>
      )}
      {referenceList.map((reference, index) => (
        <CustomReferenceRow
          key={index}
          reference={reference}
          isDefaultReference={defaultReferenceId === reference.id}
          onDefaultReferenceChange={isSelected =>
            onDefaultReferenceChange(reference.id, isSelected)
          }
        />
      ))}
    </SettingsPageSection>
  );
}

CustomReferenceList.propTypes = {
  referenceList: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultReferenceId: PropTypes.number,
  setDefaultReferenceId: PropTypes.func.isRequired,
  clearDefaultReferenceId: PropTypes.func.isRequired,
};

const SettingsPageSection = styled.div`
  margin-top: 41px;
`;

const EmptyNotificationContainer = styled.div`
  height: 208px;
  border: 1px dashed #949494;
  border-top: none;
  border-radius: 0 0 3px 3px;
  display: flex;
  align-items: center;
`;

const EmptyNotificationPlaceholderText = styled.p`
  font-family: Open Sans;
  font-size: 17px;
  color: #bdbdbd;
  margin: 0 auto;
`;

const HeaderContainer = styled.div`
  color: #999999;
  background-color: #ffffff;
  display: flex;
  line-height: 16px;
  font-size: 12px;
  padding: 7px 16px;
  border: 1px solid #eee;
`;

const Section = styled.p`
  flex: 0 0 20%;
  padding-right: 16px;
`;

const SectionLarger = styled(Section)`
  flex: 0 0 32%;
`;

const StyledLink = styled(Link)`
  justify-self: end;
  background-color: #00b88d;
  color: white;
  border: none;
  border-radius: 3px;
  height: 50px;
`;
