import {
  internalList,
  appStatusType,
  grades,
  preferenceCategories,
  languageList,
  employmentTypeAndHours,
  languageProficiency,
  roleStatusesForSearchFilters,
  prospectSources,
} from './enums';
import { prospectStatus } from './prospectenums';
import auth from './auth';
import searchAPI from 'api/searchAPI';
import prospectListAPI from 'api/prospectListAPI';

/*
 * These functions are shared by the candidateslist and requistion_role containers
 */

export function fetchCandidates(
  startSpinner,
  paginate = true,
  districtId,
  // If the user wants to select all users in the entire list, including ones that are not on
  // the page, fetch the ids of all by passing only_return_result_ids=true. This will use
  // the same filters but it will return a list of ids instead of candidates.
  only_return_result_ids = false
) {
  if (startSpinner) {
    this.startSpinner();
  }
  // For the export feature when we get all candidate data, we can't setState during the render
  let page_size = 10000;
  let page = 0;
  if (paginate === true) {
    // cl-page-size: how many candidates on each page
    page_size = 15;
    page = this.state.page;
    this.setState({ fetchCandidatesRunning: true });
  }

  const {
    days_ago,
    query,
    experienceStart,
    experienceEnd,
    orderBy,
    orderDir,
    roleId,
    newApplicationStatuses,
    job_subject_length,
    tag_ids_length,
    hrbp_assignees_length,
    credentialOptions,
    universal_tasks,
    visible_templates,
    candidate_source_list_length,
  } = this.state;

  /** Make copies using slice, instead of directly setting equal to state.*/
  let status_list = this.state.status_list.slice();
  let job_status_list = this.state.job_status_list.slice();
  let internal_list = this.state.internal_list.slice();
  let cred_list = this.state.cred_list.slice();
  let job_subject_list = this.state.job_subject_list.slice();
  let task_list = this.state.task_list.slice();
  let tag_ids = this.state.tag_ids.slice();
  let hrbp_assignees = this.state.hrbp_assignees.slice();
  let hellosign_templates = this.state.hellosign_templates.slice();
  let multilingual_school = this.state.multilingual_school.slice();
  let title_1_school = this.state.title_1_school.slice();
  let turnaround_school = this.state.turnaround_school.slice();
  let selectedGrades = this.state.grades.slice();
  let preference_categories = this.state.preference_categories.slice();
  let language_list = this.state.language_list.slice();
  let employment_type_and_hours = this.state.employment_type_and_hours.slice();
  let school_preferences_is_open_to_other_options = this.state.schoolPreferenceFilters
    .isOpenToOtherOptions;
  let school_preferences_school_ids = this.state.schoolPreferenceFilters.selectedSchoolIds;
  const onboarding_locations = this.state.onboarding_locations;
  let candidate_source_list = this.state.candidate_source_list.slice();

  // some filter optimization below. If all filters are selected, we don't need to filter
  // on the backend since all will be returning. Compare lengths and replace with an empty
  // array if they're all selected.
  /*
   * In the case of statuses, when the page initially loads, there are no statusOptions and status_list values
   * therefore we want to do the optimization. As of 2/1/19 the `archived` status is excluded by default so the
   * optimization is only done if the `archived` status is NOT selected
   */
  // This condition will be met on the initial load of the page
  if (status_list.length === 0 && newApplicationStatuses.length === 0) {
    status_list = [];
  } else {
    const archivedStatus = this.state.statusOptions.find(
      so => so.status_type === appStatusType.archived
    );
    // Only make optimized call when not on view by posting page and when the only role
    // missing is archived (b/c the backend excludes archived applications by default)
    if (
      !roleId &&
      newApplicationStatuses.length - status_list.length < 2 &&
      archivedStatus &&
      status_list.indexOf(archivedStatus.id) === -1
    ) {
      status_list = [];
    }
  }

  if (job_status_list.length === roleStatusesForSearchFilters().length) {
    job_status_list = [];
  }

  if (selectedGrades.length === grades().length) {
    selectedGrades = [];
  }

  if (preference_categories.length === preferenceCategories().length) {
    preference_categories = [];
  }

  if (language_list.length === languageList().length * languageProficiency().length) {
    language_list = [];
  }

  if (employment_type_and_hours.length === employmentTypeAndHours().length) {
    employment_type_and_hours = [];
  }

  if (internal_list.length === internalList().length) {
    internal_list = [];
  }

  if (!districtId) {
    let user = auth.getUser();
    if (user && user.profile) {
      districtId = user.profile.district.id;
    }
  }

  if (job_subject_list.length === job_subject_length) {
    job_subject_list = [];
  }

  // tasks have 2 items each (complete and incomplete)
  if (task_list.length === universal_tasks.length * 2) {
    task_list = [];
  }

  if (tag_ids.length === tag_ids_length) {
    tag_ids = [];
  }

  if (hrbp_assignees.length === hrbp_assignees_length) {
    hrbp_assignees = [];
  }

  if (cred_list.length === credentialOptions.length) {
    cred_list = [];
  }

  // hellosign tasks have 2 items each (complete and incomplete)
  if (hellosign_templates.length === visible_templates.length * 2) {
    hellosign_templates = [];
  }

  if (multilingual_school.length === 3) {
    // length of 3 means all options are selected, so no need to send to backend
    multilingual_school = [];
  }
  if (title_1_school.length === 3) {
    // length of 3 means all options are selected, so no need to send to backend
    title_1_school = [];
  }
  if (turnaround_school.length === 3) {
    // length of 3 means all options are selected, so no need to send to backend
    turnaround_school = [];
  }

  if (candidate_source_list.length === candidate_source_list_length) {
    // all options are selected
    candidate_source_list = [];
  }

  const params = {
    query,
    days_ago,
    experienceStart,
    experienceEnd,
    status_list,
    job_status_list,
    internal_list,
    cred_list,
    job_subject_list,
    task_list,
    tag_ids,
    hrbp_assignees,
    hellosign_templates,
    page: page + 1,
    paginate: paginate,
    orderBy,
    orderDir,
    roleId,
    page_size,
    multilingual_school,
    title_1_school,
    turnaround_school,
    grades: selectedGrades,
    preference_categories,
    language_list,
    employment_type_and_hours,
    only_return_result_ids,
    candidate_source_list,
  };

  const allSchoolPrefsFiltersActive =
    school_preferences_school_ids.length === this.state.activeDistrictSchools.length &&
    school_preferences_is_open_to_other_options === true;

  const noSchoolPrefsFiltersActive =
    school_preferences_school_ids.length === 0 &&
    school_preferences_is_open_to_other_options === false;

  if (!allSchoolPrefsFiltersActive && !noSchoolPrefsFiltersActive) {
    // We only want to explicity filter this if not all schools are selected
    // or when it is the only selected option and no schools are selected.
    params.school_preferences_selected_schools = school_preferences_school_ids;
    params.school_preferences_is_open_to_other_options = school_preferences_is_open_to_other_options;
  }

  const allOnboardingLocationsSelected =
    onboarding_locations.length === this.state.activeDistrictSchools.length;

  const noOnboardingLocationsSelected = onboarding_locations.length === 0;

  if (!allOnboardingLocationsSelected && !noOnboardingLocationsSelected) {
    // We only want to explicity filter this if some but not all schools
    // are selected
    params.onboarding_locations = onboarding_locations;
  }

  // ****** End filter optimization ****** //

  return searchAPI
    .searchCandidates(params)
    .then(data => {
      if (this.ignoreLastFetch) return;

      if (paginate) {
        const incomingCandidates = data.results;
        let finalListOfCandidates = [];
        let finalListOfCandidatesSet = new Set();
        // if page > 1, start with candidates in state & add to it. Otherwise start with empty list
        if (data.page > 1) {
          this.state.candidates.forEach(c => {
            finalListOfCandidatesSet.add(c.id);
            finalListOfCandidates.push(c);
          });
        }

        incomingCandidates.forEach(candidate => {
          if (!finalListOfCandidatesSet.has(candidate.id)) {
            finalListOfCandidates.push(candidate);
          }
        });
        this.setState({
          candidates: finalListOfCandidates,
          page: data.page,
          more: data.next != null,
          fetchCandidatesCompleted: true,
          fetchCandidatesRunning: false,
          totalCandidateCount: data.count,
          elasticSearchError: false,
        });
        this.stopSpinner();
      } else {
        if (only_return_result_ids) {
          return data.all_result_ids;
        } else {
          return data.results;
        }
      }
    })
    .catch(error => {
      if (!searchAPI.isCancel(error)) {
        if (error.response && error.response.data && error.response.data.elasticsearch) {
          this.setState({ elasticSearchError: true });
          this.stopSpinner();
        }
        Promise.reject(error);
      }
    });
}

export function setSortFieldAndDirection(sortField, orderDir, cb) {
  this.setState({ orderBy: sortField, orderDir: orderDir }, () => {
    cb();
  });
}

const getCandidateSourceList = initialCandidateSources => {
  // District that do not have the prospects module but do have the talent marketplace should only see candidates
  // that have been converted from the talent marketplace
  if (!auth.hasProspectsModule()) {
    return ['Talent Marketplace'];
  }

  return [...initialCandidateSources];
};

export function fetchCandidatesDebounced() {
  /** this isn't a true debounce. We'll just wait until the spinner is turned off,
   * which means the page is done loading, so the effect is the same. This solves the
   * issue of scrolling while the next candidates are still loading, which causes duplicate
   * data to return.
   */
  let onProspectList = window.location.href.indexOf('prospectlist') !== -1;
  if (this.state.fetchCandidatesRunning === true) {
    return;
  } else {
    if (onProspectList) {
      this.fetchProspects(false);
    } else {
      this.fetchCandidates(false);
    }
  }
}

export function fetchProspects(startSpinner, paginate = true) {
  if (startSpinner) {
    this.startSpinner();
  }

  this.setState({ fetchCandidatesRunning: true });

  let page = 0;
  let page_size = 10000; // for exports
  if (paginate === true) {
    page = this.state.page;
    page_size = 20;
  }
  let { orderBy, orderDir, query } = this.state;

  let prospect_status_list = [...this.state.prospect_status_list];
  let candidate_source_list = getCandidateSourceList(this.state.candidate_source_list);
  let tag_ids = [...this.state.tag_ids];
  let tag_ids_length = this.state.tag_ids_length;

  if (prospect_status_list.length === prospectStatus().length) {
    prospect_status_list = [];
  }

  if (candidate_source_list.length === prospectSources().length) {
    candidate_source_list = [];
  }

  if (tag_ids.length === tag_ids_length) {
    tag_ids = [];
  }

  const params = {
    orderBy,
    orderDir,
    page: page + 1,
    page_size,
    paginate,
    query,
    prospect_status_list,
    candidate_source_list,
    tag_ids,
  };

  return prospectListAPI.searchProspects(params).then(data => {
    if (data.page === page + 1 && !this.ignoreLastFetch && paginate) {
      const incomingCandidates = data.results;
      let finalListOfCandidates;
      // if page > 1, start with candidates in state & add to it. Otherwise start with empty list
      if (data.page > 1) {
        finalListOfCandidates = this.state.candidates.slice();
      } else {
        finalListOfCandidates = [];
      }

      incomingCandidates.forEach(candidate => {
        if (!finalListOfCandidates.find(c => c.id === candidate.id)) {
          finalListOfCandidates.push(candidate);
        }
      });
      this.setState({
        candidates: finalListOfCandidates,
        page: data.page,
        more: data.next != null,
        fetchCandidatesCompleted: true,
        fetchCandidatesRunning: false,
        totalCandidateCount: data.count,
        showSpinner: false,
      });
    } else if (!paginate) {
      return data.results;
    }
  });
}
