import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Body } from 'ui-kit/Modal';
import { SchoolPreferencesQuestionIcon } from 'ui-kit/icons';
import { GreenToggle, Input } from 'ui-kit';

import HeaderInformation from './HeaderInformation';

import searchGlassIcon from 'assets/icon_search_glass.svg';

const SchoolPreferencesQuestion = ({
  isAutomaticList,
  selectedSchools,
  schoolOptions,
  onAutomaticListToggle,
  onSelectedSchoolChange,
  internalRequirementsSpecified,
  handleItemMatrixChange,
  itemMatrix,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  let schoolsToShow = schoolOptions;
  if (searchTerm) {
    schoolsToShow = schoolOptions.filter(
      school => school.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
    );
  }

  return (
    <Body>
      <HeaderInformation
        Icon={SchoolPreferencesQuestionIcon}
        title="School Preferences Question"
        subtext="Candidate can select schools they are interested in"
        internalRequirementsSpecified={internalRequirementsSpecified}
        handleItemMatrixChange={handleItemMatrixChange}
        itemMatrix={itemMatrix}
      />
      <GreenToggle
        toggleIsOn={isAutomaticList}
        toggleFunction={onAutomaticListToggle}
        label="Automatic list"
        subtext="Candidate only sees schools with open vacancies at the time of application"
      />
      <PaddedDiv>
        <LocationsModalSearchNewStyle
          placeholder="Start typing to find and add schools manually"
          schoolSearchChange={setSearchTerm}
        >
          <SelectedSchools>{`(${selectedSchools.length} school${
            selectedSchools.length !== 1 ? 's' : ''
          } currently selected)`}</SelectedSchools>
        </LocationsModalSearchNewStyle>
      </PaddedDiv>
      <SchoolsList>
        {schoolsToShow.map((school, index) => (
          <div key={index} className="flex" onClick={() => onSelectedSchoolChange(school)}>
            <input
              className="mr1"
              type="checkbox"
              checked={selectedSchools.includes(school.id)}
              readOnly={true}
            />
            <div className="bold overflow-ellipsis">{school.name}</div>
          </div>
        ))}
      </SchoolsList>
    </Body>
  );
};

const SchoolsList = styled.div`
  font-size: 14px;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 1rem;
  }

  // Shrink to single column on small screens
  @supports (display: grid) {
    @media screen and (max-width: 990px) {
      grid-template-columns: minmax(0, 1fr);
    }
  }
`;

const SelectedSchools = styled.div`
  font-size: 12px;
  color: #979797;
  margin: 5px 10px;
`;

const PaddedDiv = styled.div`
  padding: 40px 0;
  font-size: 14px;
`;

const LocationsModalSearchNewStyle = ({ schoolSearchChange, placeholder, children }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const updateSearchTerm = value => {
    setSearchTerm(value);
    schoolSearchChange(value);
  };

  return (
    <div>
      <div className="relative">
        <SearchGlassIcon src={searchGlassIcon} alt="search" />
        <Input
          type="text"
          id="cl-search-input"
          placeholder={placeholder || 'Start typing to narrow list of locations'}
          value={searchTerm}
          onChange={e => updateSearchTerm(e.target.value)}
          width={1}
          paddingLeft="45px"
          paddingRight="45px"
        />
        <ClearInput onClick={() => updateSearchTerm('')}>×</ClearInput>
      </div>
      {children}
    </div>
  );
};

LocationsModalSearchNewStyle.propTypes = {
  schoolSearchChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

const SearchGlassIcon = styled.img`
  position: absolute;
  left: 15px;
  top: 17px;
`;

const ClearInput = styled.span`
  position: absolute;
  right: 18px;
  top: 8px;

  font-size: 24px;
  font-weight: bold;
  color: silver;

  cursor: pointer;
`;

export { SchoolPreferencesQuestion, LocationsModalSearchNewStyle };
