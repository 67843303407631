import RolesAPI from 'api/rolesAPI';
import SchoolrolesAPI from 'api/schoolrolesAPI';
import moment from 'moment';

import { salarytype, employmentTypeValues } from 'utils/enums';

export function sortQuestionsAndAttachments(unsortedQuestionsAndAttachments) {
  // Ascending sort
  return [...unsortedQuestionsAndAttachments].sort((a, b) => a.order - b.order);
}

export function combineQuestionsAndAttachmentsAndSets(job) {
  // combining questions, custom_questions,attachments, + sets into one section to reduce
  // UI clutter, making code clutter 10x worse.
  let questionSetQuestions = [];
  const returnOrder = uuid => {
    const order = job.question_sets_order.find(qs => qs.uuid === uuid)?.order;
    return order;
  };

  job.question_sets.forEach(q =>
    q.items.forEach(i => {
      questionSetQuestions.push({ ...i, order: returnOrder(q.uuid) });
    })
  );
  job.role_question_sets.forEach(q =>
    q.items.forEach(i => {
      questionSetQuestions.push({ ...i, order: returnOrder(q.uuid) });
    })
  );

  let unorderedQuestionsAndAttachmentsAndSets = job.questions
    .concat(job.custom_questions)
    .concat(job.requiredapplicationattachment_set)
    .concat(questionSetQuestions);

  if (job.school_preferences_question) {
    unorderedQuestionsAndAttachmentsAndSets.push(job.school_preferences_question);
  }

  return sortQuestionsAndAttachments(unorderedQuestionsAndAttachmentsAndSets);
}

export function noVisibleAttachments(role) {
  // Internal
  if (this.checkInternal(this.getUser, role.district.id)) {
    if (role.custom_questions.filter(item => item.is_visible_internal).length > 0) {
      return false;
    }
    if (role.questions.filter(item => item.is_visible_internal).length > 0) {
      return false;
    }
    if (role.requiredapplicationattachment_set.filter(item => item.is_visible_internal) > 0) {
      return false;
    }
    if (role.school_preferences_question && role.school_preferences_question.is_visible_internal) {
      return false;
    }
    if (role.question_sets.find(s => s.items.filter(item => item.is_visible_internal).length > 0)) {
      return false;
    }
    if (
      role.role_question_sets.find(s => s.items.filter(item => item.is_visible_internal).length > 0)
    ) {
      return false;
    }
  } else {
    // External
    if (role.custom_questions.length > 0) {
      if (role.custom_questions.filter(cq => cq.is_visible_external).length > 0) {
        return false;
      }
    }
    if (role.questions.length > 0) {
      if (role.questions.filter(q => q.is_visible_external).length > 0) {
        return false;
      }
    }
  }
  if (role.requiredapplicationattachment_set.length > 0) {
    if (
      role.requiredapplicationattachment_set.filter(item => item.is_visible_external).length > 0
    ) {
      return false;
    }
  }

  if (role.school_preferences_question && role.school_preferences_question.is_visible_external) {
    return false;
  }
  if (role.question_sets.length > 0) {
    if (role.question_sets.find(s => s.items.filter(item => item.is_visible_external).length > 0)) {
      return false;
    }
  }
  if (role.role_question_sets.length > 0) {
    if (
      role.role_question_sets.find(s => s.items.filter(item => item.is_visible_external).length > 0)
    ) {
      return false;
    }
  }
  return true;
}

export function constructSchoolroleFromMergedRole(districtRole, roleBeingMerged) {
  const newSchoolrole = Object.assign({}, districtRole);
  newSchoolrole.school = roleBeingMerged.school;
  newSchoolrole.ftes_total = 1;
  newSchoolrole.district_role = districtRole.id;
  return newSchoolrole;
}

export const formatSalary = (salary_min, salary_max, salary_type) => {
  if (salary_min == null && salary_max == null) {
    return 'Not specified';
  }

  const salaryTypes = salarytype().reduce((obj, item) => {
    obj[item.value] = item.label;
    return obj;
  }, {});

  const formatSalaryValue = (salary) => {
    return salary.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: salary % 1 === 0 ? 0 : 2,
      maximumFractionDigits: 2,
    });
  };

  const formattedSalaryMin = salary_min != null ? formatSalaryValue(salary_min) : null;
  const formattedSalaryMax = salary_max != null ? formatSalaryValue(salary_max) : null;

  let salaryRange = '';
  if (formattedSalaryMin && formattedSalaryMax) {
    if (salary_min === salary_max) {
      salaryRange = formattedSalaryMin;
    } else {
      salaryRange = `${formattedSalaryMin} - ${formattedSalaryMax}`;
    }
  } else if (formattedSalaryMin) {
    salaryRange = formattedSalaryMin;
  } else if (formattedSalaryMax) {
    salaryRange = `Up to ${formattedSalaryMax}`;
  }

  const salaryTypeLabel = salary_type != null ? ` ${salaryTypes[salary_type]}` : '';

  return salaryRange + salaryTypeLabel;
};

export const getDeadline = deadline => {
  // Jobs close at 8PM PT / 11PM ET on the deadline date.
  const deadlineDate = new Date(deadline);
  deadlineDate.setUTCDate(deadlineDate.getDate() + 1);
  if (moment(deadlineDate).isDST()) {
    // if during DST, 3AM UTC is the time
    deadlineDate.setUTCHours(3);
  } else {
    // if outside DST, 4AM UTC is the time
    deadlineDate.setUTCHours(4);
  }
  deadlineDate.setUTCMinutes(0);
  deadlineDate.setUTCMilliseconds(0);

  return deadlineDate;
};

export const formatRoleType = fulltime => {
  if (!fulltime) {
    return '';
  }

  const obj = employmentTypeValues().find(i => i.value === fulltime);
  return obj.label;
};

export const getStatusesDisplayText = (nonDraftJobStatuses, applicationStatuses) => {
  if (nonDraftJobStatuses.length > 0 && applicationStatuses.length > 0) {
    if (nonDraftJobStatuses.length !== applicationStatuses.length) {
      return `Candidate Statuses (${nonDraftJobStatuses.length} out of ${applicationStatuses.length} selected)`;
    }
  }

  return 'Candidate Statuses';
};
