import { Component } from 'react';
import PropTypes from 'prop-types';

import iconUpCaretGreen from '../../../assets/icons/FilterIcons/icon-up-caret-with-circle-green.svg';
import iconDownCaretGreen from '../../../assets/icons/FilterIcons/icon-down-caret-with-circle-green.svg';
import iconUpCaret from '../../../assets/icons/FilterIcons/icon-up-caret-with-circle-grey.svg';
import iconDownCaret from '../../../assets/icons/FilterIcons/icon-down-caret-with-circle-grey.svg';

import {
  grades,
  early_childhood_grades,
  childhood_grades,
  middle_grades,
  secondary_grades,
} from '../../../utils/enums';

const EARLYCHILDHOOD = early_childhood_grades().map(grade => {
  return {
    value: grade.value,
    label: grade.label,
  };
});

const ELEMENTARY = childhood_grades().map(grade => {
  return {
    value: grade.value,
    label: grade.label,
  };
});

const MIDDLE = middle_grades().map(grade => {
  return {
    value: grade.value,
    label: grade.label,
  };
});

const SECONDARY = secondary_grades().map(grade => {
  return {
    value: grade.value,
    label: grade.label,
  };
});

export default class GradesFilter extends Component {
  static propTypes = {
    updateMultiSelectFilter: PropTypes.func.isRequired,
    updateMultiSelectFilterWithMultipleValues: PropTypes.func.isRequired,
    grades: PropTypes.array.isRequired,
    selectAll: PropTypes.func.isRequired,
    clearAll: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
    };
  }

  toggleOptions = () => {
    this.setState((state, props) => ({ showOptions: !state.showOptions }));
  };

  selectAll = e => {
    e.stopPropagation();
    this.props.selectAll('grades', grades());
  };

  clearAll = e => {
    e.stopPropagation();
    this.props.clearAll('grades');
  };

  gradesCheckedInSection = (gradeSet, section) => {
    // check if all grades in that section are checked
    for (let i = 0; i < section.length; i++) {
      if (!gradeSet.has(section[i].value)) {
        return false;
      }
    }
    return true;
  };

  updateGradesInSection = (e, gradeSet, section) => {
    e.stopPropagation();
    if (!e.target.checked) {
      section.forEach(g => gradeSet.delete(g.value));
    } else {
      section.forEach(g => gradeSet.add(g.value));
    }
    const values = [...gradeSet];
    const optionsLength = grades().length;
    this.props.updateMultiSelectFilterWithMultipleValues('grades', values, optionsLength);
  };

  render() {
    /** use a set for constant time lookup. Loop through props.values, which is a list of currently
     * active filters. Add each value to the set, and down below in the map function, check
     * if each item is in the set (set.has(o.value). This is faster than looping through
     * the values array during each iteration of map.
     * Also, send set.has(o.value) when the item is clicked, so that the container
     * knows if you're clicking an already active filter (in which case it will turn it off).
     */
    let selectedGrades = this.props.grades;
    const optionsLength = grades().length;
    let gradeSet = new Set();

    selectedGrades.forEach(g => {
      gradeSet.add(g);
    });

    // default is to have all filters selected, so we only need to show
    // the filter as active if they change something, which would make both allSelected
    // and noneSelected false.
    let allSelected = selectedGrades.length === optionsLength;
    let noneSelected = selectedGrades.length === 0;
    let isActive = !(allSelected || noneSelected);

    return (
      <div className={`new-cl-filter ${isActive ? 'cl-active' : ''} sub-filter`}>
        <div className="cl-filter-header" onClick={this.toggleOptions}>
          <p className={isActive ? 'cl-active' : ''}>Grade Levels</p>
          <img
            src={
              this.state.showOptions && isActive
                ? iconUpCaretGreen
                : !this.state.showOptions && isActive
                ? iconDownCaretGreen
                : this.state.showOptions
                ? iconUpCaret
                : iconDownCaret
            }
            alt=""
          />
        </div>
        <div className={`selection-list ${this.state.showOptions ? 'show' : ''}`}>
          <div className="option select-all">
            <span className="drop-select-all" onClick={this.selectAll}>
              Select All
            </span>
            <span className="drop-select-all" onClick={this.clearAll}>
              Clear All
            </span>
          </div>
          <div className="option">
            <label className="container">
              <input
                type="checkbox"
                checked={allSelected}
                onChange={allSelected ? this.clearAll : this.selectAll}
              />
              <span className="checkmark"></span>
              All Grades
            </label>
          </div>
          <div className="option">
            <label className="container">
              <input
                type="checkbox"
                checked={gradeSet.has(EARLYCHILDHOOD[0].value)}
                value={EARLYCHILDHOOD[0].value}
                onChange={() =>
                  this.props.updateMultiSelectFilter(
                    'grades',
                    EARLYCHILDHOOD[0].value,
                    gradeSet.has(EARLYCHILDHOOD[0].value),
                    optionsLength
                  )
                }
              />
              <span className="checkmark"></span>
              Early Childhood
            </label>
          </div>
          <div className="option">
            <label className="container">
              <input
                type="checkbox"
                checked={this.gradesCheckedInSection(gradeSet, ELEMENTARY)}
                onChange={e => this.updateGradesInSection(e, gradeSet, ELEMENTARY)}
              />
              <span className="checkmark"></span>
              All Elementary
            </label>
          </div>
          {childhood_grades().map((g, i) => (
            <div key={i} className="option indented">
              <label className="container">
                <input
                  type="checkbox"
                  checked={gradeSet.has(g.value)}
                  onChange={() =>
                    this.props.updateMultiSelectFilter(
                      'grades',
                      g.value,
                      gradeSet.has(g.value),
                      optionsLength
                    )
                  }
                />
                <span className="checkmark"></span>
                {g.label}
              </label>
            </div>
          ))}
          <div className="option">
            <label className="container">
              <input
                type="checkbox"
                checked={this.gradesCheckedInSection(gradeSet, MIDDLE)}
                onChange={e => this.updateGradesInSection(e, gradeSet, MIDDLE)}
              />
              <span className="checkmark"></span>
              All Middle School
            </label>
          </div>
          {middle_grades().map((g, i) => (
            <div key={i} className="option indented">
              <label className="container">
                <input
                  type="checkbox"
                  checked={gradeSet.has(g.value)}
                  onChange={() =>
                    this.props.updateMultiSelectFilter(
                      'grades',
                      g.value,
                      gradeSet.has(g.value),
                      optionsLength
                    )
                  }
                />
                <span className="checkmark"></span>
                {g.label}
              </label>
            </div>
          ))}
          <div className="option">
            <label className="container">
              <input
                type="checkbox"
                checked={this.gradesCheckedInSection(gradeSet, SECONDARY)}
                onChange={e => this.updateGradesInSection(e, gradeSet, SECONDARY)}
              />
              <span className="checkmark"></span>
              All High School
            </label>
          </div>
          {secondary_grades().map((g, i) => (
            <div key={i} className="option indented">
              <label className="container">
                <input
                  type="checkbox"
                  checked={gradeSet.has(g.value)}
                  onChange={() =>
                    this.props.updateMultiSelectFilter(
                      'grades',
                      g.value,
                      gradeSet.has(g.value),
                      optionsLength
                    )
                  }
                />
                <span className="checkmark"></span>
                {g.label}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
