import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  HideOnMobile,
  SpacerContainer,
  TemplateRowContainer,
  TextEllipsisContainer,
} from './styles';
import TemplateQuickActions from './TemplateQuickActions';
import { TemplateRowProps } from './types';

export const TemplateRow: React.FC<TemplateRowProps> = ({
  handleDelete,
  handleOpenModalClick,
  template,
  smallerLayout,
}) => {
  const history = useHistory();

  const handleEditClick = () => {
    history.push(`/district/template-edit/${template.id}`);
  };

  const Schools = () => {
    let word = 'None';
    let roles = [];
    if (template.schoolroles) {
      roles = template.schoolroles.filter(sr => sr.automatic_routing === true);

      if (roles.length === 1) {
        word = roles[0].school.name;
      } else if (roles.length > 1) {
        word = 'Multiple';
      }
    }

    if (word !== 'None') {
      return (
        <TextEllipsisContainer
          data-tip={roles.map(item => `${item.school.name} (${item.ftes_total})`).join(',<br/>')}
          data-for="job-locations"
        >
          {word}
        </TextEllipsisContainer>
      );
    } else {
      return <TextEllipsisContainer>{word}</TextEllipsisContainer>;
    }
  };

  const createdByString = template.created_by ? `${template.created_by.name}` : 'N/A';

  return (
    <TemplateRowContainer onClick={handleEditClick} smallerLayout={smallerLayout}>
      <SpacerContainer />
      <TextEllipsisContainer data-tip={template.title} data-for="full-title-tooltip">
        {template.title}
      </TextEllipsisContainer>
      <div className="districtjob-row-date">{moment(template.created).format('l')}</div>
      <HideOnMobile>
        <div>{createdByString}</div>
      </HideOnMobile>
      <HideOnMobile>
        <Schools />
      </HideOnMobile>
      <HideOnMobile>
        <div className="template-quick-actions">
          <TemplateQuickActions
            handleDelete={handleDelete}
            handleEditClick={handleEditClick}
            template={template}
            handleOpenModalClick={handleOpenModalClick}
          />
        </div>
      </HideOnMobile>
    </TemplateRowContainer>
  );
};
