import styled from 'styled-components';
import { border, layout, space } from 'styled-system';

export const DeprecatedInput = styled.input`
  ${border}
  ${layout}
  ${space}

  font-size: 14px;

  border: 0;
  box-shadow: 0px 2px 2px rgba(0,0,0,0.0954484);

  padding-left: 1.5em;
  padding-right: 1.5em;

  &::placeholder {
    color: rgba(57, 60, 73, 0.3);
  }
`;

DeprecatedInput.defaultProps = {
  height: '50px',
  marginTop: 0,
  marginBottom: 0
};
