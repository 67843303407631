import { useEffect } from 'react';
import useSWR from 'swr';
import axios from 'axios';

import LoadingSpinner from 'components/loadingSpinner';
import { makeHSClient, openHSiFrame } from 'utils/helloSignUtil';

import { showWarning } from 'utils/message';
import { useHistory, useRouteMatch } from 'react-router-dom';

const client = makeHSClient();

const fetcher = url => axios.get(url).then(response => response.data);

const HellosignEmbeddedSignin: React.FC = () => {
  const match = useRouteMatch<{ signatureid: string }>('/hellosign-embedded-sign/:signatureid');
  const params = match.params || { signatureid: undefined };
  const history = useHistory();

  const { data, isValidating, error } = useSWR(
    `/api/hellosign/signature_url/?signature_id=${params.signatureid}`,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  if (error) {
    if (error.response?.status === 400) {
      if (error.response.data?.detail === 'signature_canceled') {
        showWarning(
          'This signature request has been cancelled. Please reach out to the sender for more information.'
        );
      } else {
        showWarning('Document not found. Please email support@hirenimble.com for assistance.');
      }
    } else if (error.response.status === 409) {
      showWarning(
        'This document has already been signed. You will receive a copy when all signers have completed the process. If you need further assistance, please reach out to support@hirenimble.com.'
      );
    } else {
      showWarning(
        'Oops, there was an issue completing this request. Please email support@hirenimble.com for assistance.'
      );
    }
  }

  const onSuccess = () => {
    history.push('/');
  };

  useEffect(() => {
    if (data?.sign_url) {
      openHSiFrame(client, data.sign_url, null, onSuccess);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isValidating) {
    return <LoadingSpinner />;
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
      }}
      id="hellosign-iframe-entry"
    />
  );
};

export default HellosignEmbeddedSignin;
