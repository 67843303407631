import { Component } from 'react';
import axios from 'axios';
import _ from 'lodash';

import JobEdit from '../components/JobEdit';
import RoleForm from '../features/RoleForm/index';
import { roleStatuses, getJobStatusColor, questionType } from '../utils/enums';
import auth from '../utils/auth';
import { fetchApplicationStatuses } from '../utils/statusviewutils';
import districtAPI from 'api/districtAPI';
import schoolsAPI from 'api/schoolsAPI';
import rolesAPI from 'api/rolesAPI';
import nimbleQuestionsAPI from 'api/nimbleQuestionsAPI';
import schoolGroupsAPI from 'api/schoolGroupsAPI';
import CustomScorecardListAPI from 'api/customScorecardListAPI';
import { showTotalFailureAdmin } from 'utils/message';
import { scrollToElementById, ensureHttpPrefixIfValueIsNotEmail } from 'utils/util';
import { withRouter } from 'react-router-dom';
import { showWarning } from 'utils/message';
import FeatureContext from 'context/featureContext';

const statuses = _.keyBy(roleStatuses(), 'value');

const initialAPIErrorState = {
  link_to_ats: '',
  duplicate_title: '',
};

const generateDefaultState = isTemplate => ({
  job: null,
  statusColor: '',
  questionBank: [],
  schools: [],
  schoolOptions: [],
  activelySubmitting: false,
  districtUsers: [],
  districtAndSuperAdmins: [],
  jobBoardOptions: [],
  newApplicationStatuses: [],
  apiResponseErrors: { ...initialAPIErrorState },
  customScorecardsAvailable: [],
  customScorecardsSelected: [],
  isTemplate,
  isLoadingSchoolOptions: true,
  wasSuccessful: false,
});

class JobEditContainer extends Component {
  static contextType = FeatureContext;
  constructor(props) {
    super(props);
    this.state = generateDefaultState(props.isTemplate);
    this.fetchApplicationStatuses = fetchApplicationStatuses;
  }

  componentDidMount() {
    // Done this way so can call for all new state but not reload on 'save'
    this.loadStateOnMount();
  }

  componentDidUpdate(previousProps, previousState) {
    const currentId = this.props.match.params.id;
    const previousId = previousProps.match.params.id;
    const currentStatusNumber = this.state.job?.status;
    const previousStatusNumber = previousState.job?.status;

    const hasMergedRequestIntoJob = currentId !== previousId;
    const hasCreatedRoleFromRequest =
      currentStatusNumber !== previousStatusNumber && previousStatusNumber !== undefined;

    if (hasCreatedRoleFromRequest || hasMergedRequestIntoJob) {
      this.setState(generateDefaultState(this.props.isTemplate));
      this.loadStateOnMount();
      this.setState({ wasSuccessful: true });
    }
  }

  // The following three functions are called in Promise.all()
  loadStateOnMount = () => {
    const getRoles = new Promise((resolve, reject) => {
      // determine if we are editing a template or a role
      const BASE_URL = this.props.isTemplate ? '/api/role_templates' : '/api/role';

      axios
        .get(`${BASE_URL}/${this.props.match.params.id}/`)
        .then(r => {
          if (!this.ignoreLastFetch) {
            let job = r.data;
            let schools = [];

            // set schools for the role/template
            schools = job.schoolroles
              .filter(sr => sr.automatic_routing === true)
              .map(role => {
                let school = role.school;
                // add ftes_total property to the school object (from schoolrole object)
                // this.state.schools is what is used to keep track of schools that have a schoolrole
                // for this particular district role.
                school.ftes_total = role.ftes_total;
                school.visible_to_school_admin = role.visible_to_school_admin;
                school.has_unfilled_vacancies = role.has_unfilled_vacancies;
                return school;
              });

            this.setState(
              {
                job,
                schools,
              },
              () => {
                this.setStatusColor();
                resolve();
              }
            );
            // Get the application status options for the district
            this.fetchApplicationStatuses();
            // Now that we have a role, get the scorecards selected.
            CustomScorecardListAPI.fetchCustomScorecardsByRole(job.id).then(
              customScorecardsSelected => {
                this.setState({ customScorecardsSelected });
              }
            );
          }
        })
        .catch(err => {
          console.log(err.message);
          reject(err);
        });
    });

    const getSchoolOptions = schoolsAPI.fetchActiveSchools().then(schools => {
      schoolGroupsAPI
        .getSchoolGroups()
        .then(schoolGroups => {
          let schoolOptions = [];

          schoolGroups.forEach(g => {
            schoolOptions.push({ ...g, isGroupHeader: true, group: g.id });

            schools
              .filter(s => s.school_groups.includes(g.id))
              .forEach(s => schoolOptions.push({ ...s, group: g.id }));
          });

          schoolOptions = [...schoolOptions, ...schools.filter(s => s.school_groups?.length === 0)];

          if (!this.ignoreLastFetch) {
            this.setState({ schoolOptions });
          }
        })
        .then(() => {
          this.setState({ isLoadingSchoolOptions: false });
        })
        .catch(() => {
          this.setState({ isLoadingSchoolOptions: false });
        });
    });

    const getQuestions = nimbleQuestionsAPI.getAll().then(questionBank => {
      if (!this.ignoreLastFetch) {
        this.setState({
          questionBank,
        });
      }
    });

    const getCustomScorecards = CustomScorecardListAPI.fetchCustomScorecards().then(data => {
      this.setState({
        customScorecardsAvailable: data
          .sort((a, b) => {
            if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
            if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
            return 0;
          })
          .filter(scorecard => scorecard.view_permissions !== 'me_only'),
      });
    });

    // Call for the district users if user is district admin
    let getDistrictUsers;
    let getJobBoards;
    let fetchDistrictAdmins;
    if (auth.isDistrictAdmin()) {
      getDistrictUsers = new Promise((resolve, reject) => {
        axios
          .get('/api/user/get_district_users/')
          .then(r => {
            if (!this.ignoreLastFetch) {
              this.setState(
                {
                  districtUsers: r.data,
                },
                () => {
                  resolve();
                }
              );
            }
          })
          .catch(err => {
            console.log(err.message);
            reject(err);
          });
      });
      getJobBoards = new Promise((resolve, reject) => {
        axios
          .get('/api/jobboards/')
          .then(r => {
            if (!this.ignoreLastFetch) {
              this.setState(
                {
                  jobBoardOptions: r.data,
                },
                () => {
                  resolve();
                }
              );
            }
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
      fetchDistrictAdmins = new Promise((resolve, reject) => {
        let district_id = auth.getUser().profile.district.id;
        districtAPI
          .getDistrictAndSuperAdmins(district_id)
          .then(admins => {
            this.setState({ districtAndSuperAdmins: admins }, resolve);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      });
    } else {
      getDistrictUsers = new Promise((resolve, reject) => {
        resolve();
      });
      getJobBoards = new Promise((resolve, reject) => {
        resolve();
      });
      fetchDistrictAdmins = new Promise((resolve, reject) => {
        resolve();
      });
    }
    // Wait until all calls complete before hiding the spinner on 'save'
    Promise.all([
      getRoles,
      getSchoolOptions,
      getQuestions,
      getDistrictUsers,
      getJobBoards,
      fetchDistrictAdmins,
      getCustomScorecards,
    ])
      .then(r => {
        this.setState({ activelySubmitting: false });
      })
      .catch(err => {
        console.log('Aspect of Promise.all on line 81 failed:', err.message);
        throw err;
      });
  };

  setStatusColor = () => {
    this.setState({ statusColor: getJobStatusColor(this.state.job.status) });
  };

  onDelete = () => {
    return axios
      .delete(`/api/role/${this.state.job.id}/`)
      .then(r => this.props.history.push(`/district/jobslist`))
      .catch(err => console.log('FIXME'));
  };

  /** questionAndAttachments is coming up as a separate array because it contains
   * 3 separate models (CustomQuestion, Question, and RequiredApplicationAttachment),
   * but it's displayed as one ordered list to the user. The separate array is the
   * combined list, in order.
   */
  onSave = async (childRole, questionsAndAttachmentsAndSets) => {
    await this.updateRole(childRole, questionsAndAttachmentsAndSets, 'save', false);
  };

  /** questionAndAttachments is coming up as a separate array because it contains
   * 3 separate models (CustomQuestion, Question, and RequiredApplicationAttachment),
   * but it's displayed as one ordered list to the user. The separate array is the
   * combined list, in order.
   */
  onSubmit = async (childRole, questionsAndAttachmentsAndSets) => {
    await this.updateRole(childRole, questionsAndAttachmentsAndSets, 'preview', true);
  };

  /**
   * creating new onSave and onSubmit Functions for the tabbed job view introduces some repeated code.
   * However, reusing code required lots of random flags which ultimately made the code less readable
   * So, clarity > D.R.Y. in this case.
   */

  updateRoleRoleForm = async (
    childRole,
    questionsAndAttachmentsAndSets,
    schoolsSelected,
    isSubmittingAndPreviewing
  ) => {
    this.setState({ activelySubmitting: true });
    const putJob = this.createRoleRequest(childRole, questionsAndAttachmentsAndSets);
    const customScorecardsSelected = this.state.customScorecardsSelected;
    try {
      let updatedRole = await rolesAPI.update(this.state.job.id, putJob);

      showWarning(`${this.state.job.is_template ? 'Template' : 'Draft'} saved`, 1000);

      if (putJob?.internal_role_notes) {
        const newMentions = putJob.internal_role_notes?.filter(n =>
          n.hasOwnProperty('newly_tagged_users')
        );
        this.emailTaggedUsers(newMentions, updatedRole);
      }
      if (isSubmittingAndPreviewing) {
        await this.createSchoolRolesRoleForm(
          updatedRole.id,
          putJob.status,
          'preview',
          schoolsSelected
        );
      } else {
        await this.createSchoolRolesRoleForm(
          updatedRole.id,
          putJob.status,
          'save',
          schoolsSelected
        );
      }

      await this.updateCustomScorecards(updatedRole.id, customScorecardsSelected);
      // Patch response is not formatted correctly, so refetch the role.
      updatedRole = await rolesAPI.fetchRole(updatedRole.id);
      this.setState({ job: updatedRole, activelySubmitting: false });
    } catch (err) {
      if (err.response?.data?.link_to_ats) {
        this.setState(
          {
            apiResponseErrors: {
              link_to_ats: err.response.data?.link_to_ats,
            },
            activelySubmitting: false,
          },
          () => scrollToElementById('jobedit_link_to_ats')
        );
      } else if (err.response?.data?.title) {
        this.setState(
          {
            apiResponseErrors: {
              duplicate_title: err.response?.data?.title,
            },
            activelySubmitting: false,
          },
          () => scrollToElementById('title')
        );
      } else {
        showTotalFailureAdmin(
          'Oops, we were unable to save the job. Contact support@hirenimble.com for assistance.'
        );
        throw new Error(err);
      }
    }
  };

  onSaveRoleForm = async (childRole, questionsAndAttachmentsAndSets, schoolsSelected) => {
    await this.updateRoleRoleForm(
      childRole,
      questionsAndAttachmentsAndSets,
      schoolsSelected,
      false
    );
  };

  onSubmitRoleForm = async (childRole, questionsAndAttachmentsAndSets, schoolsSelected) => {
    await this.updateRoleRoleForm(childRole, questionsAndAttachmentsAndSets, schoolsSelected, true);
  };

  candidateWasHired = candidate => {
    let allApps = candidate.applications.concat(candidate.schoolapplications);
    const hiredApps = allApps.filter(app => {
      return app.status === 9;
    });
    return hiredApps.length > 0 ? true : false;
  };

  sendTagNotifications = tagObjects => {
    return axios.post('/api/send_tag_notifications/', tagObjects);
  };

  getUrl = job => {
    const host = window.location.hostname;
    const colon = host.indexOf('local') !== -1 ? ':' : '';
    const port = window.location.port;
    const location = `${window.location.protocol}//${host}${colon}${port}`;
    return `${location}/district/jobpreview/${job.id}#internal_notes`;
  };

  emailTaggedUsers = (notesWithNewTags, job) => {
    const tagObjects = [];
    notesWithNewTags.forEach(note => {
      let newlyTaggedUsers = [...new Set(note.newly_tagged_users)];
      for (let taggedUser of newlyTaggedUsers) {
        const url = this.getUrl(job);
        const tagObj = {
          user_id: +taggedUser,
          location: 'in a job posting',
          note_url: url,
          note_copy: note.text,
          role_title: job.title,
        };
        tagObjects.push(tagObj);
      }
    });
    this.sendTagNotifications(tagObjects);
  };

  createRoleRequest = (childRole, questionsAndAttachmentsAndSets) => {
    const putJob = childRole;
    putJob.status = statuses.draft.key;
    putJob.questions = [];
    putJob.custom_questions = [];
    putJob.requiredapplicationattachment_set = [];
    putJob.question_sets = [];
    putJob.role_question_sets = [];
    putJob.question_sets_order = [];
    putJob.school_preferences_question = null;

    questionsAndAttachmentsAndSets.forEach((item, index) => {
      item.order = index;

      if (item.is_qs) {
        switch (item.is_role_qs) {
          case true:
            putJob.role_question_sets.push(item);
            putJob.question_sets_order.push({
              uuid: item.uuid,
              order: item.order,
              is_role_qs: true,
            });
            break;
          default:
            putJob.question_sets.push(item);
            putJob.question_sets_order.push({
              uuid: item.uuid,
              order: item.order,
              is_role_qs: false,
            });
            break;
        }
      }
      switch (item.question_type) {
        case questionType.nimble:
          putJob.questions.push(item);
          break;
        case questionType.direction_text:
        case questionType.open_response:
        case questionType.yes_no:
        case questionType.multiple_choice:
        case questionType.statementCheckbox:
          putJob.custom_questions.push(item);
          break;
        case questionType.attachment:
        case questionType.videoLink:
          putJob.requiredapplicationattachment_set.push(item);
          break;
        case questionType.schoolPreferences:
          putJob.school_preferences_question = item;
          break;
        default:
          console.warn('Unknown question type', item.question_type);
      }
    });

    // If the job has a link to ATS, but it doesn't start with http or https,
    // add it here. If it's an email we do nothing.
    putJob.link_to_ats = ensureHttpPrefixIfValueIsNotEmail(putJob.link_to_ats);

    return putJob;
  };

  updateRole = async (childRole, questionsAndAttachmentsAndSets, updateType) => {
    // comment about sending dates up no longer necessary since we're using
    // childRole now. No more astonishment for Hank.

    if (!this.state.activelySubmitting) {
      this.setState({ activelySubmitting: true });

      const putJob = this.createRoleRequest(childRole, questionsAndAttachmentsAndSets);
      const customScorecardsSelected = this.state.customScorecardsSelected;

      try {
        const roleChange = await axios.put(`/api/role/${this.state.job.id}/`, putJob);
        // notify new users of their tag
        if (putJob?.internal_role_notes) {
          let newlyTaggedUsers = putJob?.internal_role_notes?.filter(note =>
            note.hasOwnProperty('newly_tagged_users')
          );
          let roleObj = roleChange.data;
          this.emailTaggedUsers(newlyTaggedUsers, roleObj);
        }

        await this.createSchoolRoles(roleChange.data.id, putJob.status, updateType);
        await this.updateCustomScorecards(this.state.job.id, customScorecardsSelected);
        // Needs to be reset after network request is completed and it's not actively submitting anymore
        this.setState({ activelySubmitting: false });
      } catch (err) {
        if (err.response.data?.link_to_ats) {
          this.setState(
            {
              apiResponseErrors: {
                link_to_ats: err.response.data?.link_to_ats,
              },
              activelySubmitting: false,
            },
            () => scrollToElementById('jobedit_link_to_ats')
          );
        } else if (err.response?.data?.title) {
          this.setState(
            {
              apiResponseErrors: {
                duplicate_title: err.response?.data?.title,
              },
              activelySubmitting: false,
            },
            () => scrollToElementById('title')
          );
        } else {
          // Any other error is unexpected so blow it all up - LOL
          showTotalFailureAdmin(
            'Oops, we were unable to save the job. Contact support@hirenimble.com for assistance.'
          );
          this.setState({ activelySubmitting: false });
          throw new Error(err);
        }
      }
    }
  };

  createSchoolRolesRoleForm = async (
    roleId,
    status,
    updateType,
    schoolRoles,
    isTemplate = false
  ) => {
    const initialSchoolroles = this.state.job.schoolroles.slice();
    const finalSchools = schoolRoles.slice();
    const finalSchoolsIds = schoolRoles.map(s => s.id);

    let promises = [];

    // Remove schoolRoles that no longer exist
    initialSchoolroles
      .filter(sr => sr.automatic_routing === true && !finalSchoolsIds.includes(sr.school.id))
      .forEach(sr => promises.push(axios.delete(`/api/schoolrole/${sr.id}/`)));

    let schoolsToAdd = [];
    let schoolrolesToUpdate = [];

    finalSchools.forEach(s => {
      let schoolrole = initialSchoolroles.find(sr => sr.school.name === s.name);
      if (schoolrole) {
        schoolrole.ftes_total = s.ftes_total;
        schoolrole.visible_to_school_admin = s.visible_to_school_admin;
        schoolrole.status = status;
        schoolrole.automatic_routing = true;
        schoolrolesToUpdate.push(schoolrole);
      } else {
        let role = Object.assign({}, this.state.job);
        role.school = s;
        role.ftes_total = s.ftes_total;
        role.automatic_routing = true;
        role.district_role = roleId;
        schoolsToAdd.push(role);
      }
    });
    if (schoolrolesToUpdate.length > 0) {
      promises.push(
        axios
          .post('/api/schoolrole/bulk_update/', schoolrolesToUpdate)
          .catch(err => console.log(err))
      );
    }
    if (schoolsToAdd.length > 0) {
      promises.push(
        axios.post('/api/schoolrole/', {
          schoolRoles: schoolsToAdd,
          districtRoleID: roleId,
        })
      );
    }
    await Promise.all(promises).then(() => {
      if (updateType === 'save' && this.props.isTemplate) {
        this.props.history.push(`/district/template-edit/${this.state.job.id}`);
      } else if (updateType === 'save' && !isTemplate) {
        this.props.history.push(`/district/jobedit/${this.state.job.id}`);
      } else {
        this.props.history.push(`/district/jobpreview/${this.state.job.id}`);
      }
    });
  };

  createSchoolRoles = async (roleID, status, updateType) => {
    let initialSchoolroles = this.state.job.schoolroles.slice();
    let finalSchools = this.state.schools.slice();
    // add DELETE, POST, and PUT axios calls here so we can use promise.all to delay
    // going to the next page until they are all complete.
    let promises = [];
    // remove schoolRoles that no longer exist
    initialSchoolroles
      .filter(sr => sr.automatic_routing === true && !finalSchools.includes(sr.school))
      .forEach(sr => promises.push(axios.delete(`/api/schoolrole/${sr.id}/`)));

    let schoolsToAdd = [];

    const schoolrolesToUpdate = [];
    finalSchools.forEach(s => {
      let schoolrole = initialSchoolroles.find(sr => sr.school.name === s.name);
      if (schoolrole) {
        schoolrole.ftes_total = s.ftes_total;
        schoolrole.visible_to_school_admin = s.visible_to_school_admin;
        schoolrole.status = status;
        schoolrole.automatic_routing = true;

        schoolrolesToUpdate.push(schoolrole);
      } else {
        let role = Object.assign({}, this.state.job);
        role.school = s;
        role.ftes_total = s.ftes_total;
        role.automatic_routing = true;
        role.district_role = roleID;
        schoolsToAdd.push(role);
      }
    });
    if (schoolrolesToUpdate.length > 0) {
      promises.push(
        axios
          .post('/api/schoolrole/bulk_update/', schoolrolesToUpdate)
          .catch(err => console.log(err))
      );
    }

    // add new
    if (schoolsToAdd.length > 0) {
      promises.push(
        axios.post('/api/schoolrole/', {
          schoolRoles: schoolsToAdd,
          districtRoleID: roleID,
        })
      );
    }

    await Promise.all(promises);
    if (updateType === 'save') {
      this.props.history.push(`/district/jobedit/${this.state.job.id}`);
    } else {
      this.props.history.push(`/district/jobpreview/${this.state.job.id}`);
    }
  };

  async updateCustomScorecards(roleId, scorecards) {
    const scorecardIds = scorecards.map(s => s.id);
    CustomScorecardListAPI.updateScorecardRoles(roleId, scorecardIds);
  }

  componentWillUnmount() {
    this.ignoreLastFetch = true;
  }

  clearAPIResponseErrors = () => {
    this.setState({ apiResponseErrors: { ...initialAPIErrorState } });
  };

  updateJobStatusAfterConverting(status) {
    const updatedJob = { ...this.state.job };
    updatedJob.status = status;
    this.setState({ job: updatedJob });
  }

  handleCloseSuccessToast() {
    this.setState({ wasSuccessful: false });
  }

  render() {
    return (
      this.state.job && (
        <>
          {auth.isDistrictAdmin() && this.props.isTemplate ? (
            <RoleForm
              isEditing={true}
              job={this.state.job} //Needed for fallback component, JobEdit
              jobData={this.state.job}
              statusColor={this.state.statusColor}
              questionBank={this.state.questionBank}
              onDelete={this.onDelete}
              onSave={this.onSave} //Needed for fallback component, JobEdit
              onSubmit={this.onSubmit} //Needed for fallback component, JobEdit
              onSaveRoleForm={this.onSaveRoleForm}
              onSubmitRoleForm={this.onSubmitRoleForm}
              onSaveRoleTemplateForm={this.onSaveRoleForm} // save function same for job-edit/template-edit
              onSubmitRoleTemplateForm={this.onSubmitRoleForm} // save function same for job-edit/template-edit (currently, not hooked up)
              schools={this.state.schools}
              schoolOptions={this.state.schoolOptions}
              onSubmitLabel={'Save & Preview'} //Needed for fallback component, JobEdit
              pageTitle="Edit Job" //Needed for fallback component, JobEdit
              districtUsers={this.state.districtUsers}
              districtAndSuperAdmins={this.state.districtAndSuperAdmins}
              jobBoardOptions={this.state.jobBoardOptions}
              applicationStatuses={this.state.newApplicationStatuses.filter(
                a => a.status_type !== 0
              )}
              apiResponseErrors={this.state.apiResponseErrors}
              clearAPIResponseErrors={this.clearAPIResponseErrors}
              activelySubmitting={this.state.activelySubmitting}
              customScorecards={this.state.customScorecardsAvailable}
              customScorecardsSelected={this.state.customScorecardsSelected}
              setCustomScorecardsSelected={s => this.setState({ customScorecardsSelected: s })}
              isLoadingSchoolOptions={this.state.isLoadingSchoolOptions}
            />
          ) : (
            <JobEdit
              job={this.state.job}
              statusColor={this.state.statusColor}
              questionBank={this.state.questionBank}
              onDelete={this.onDelete}
              onSave={this.onSave}
              onSubmit={this.onSubmit}
              onSubmitLabel={'Save & Preview'}
              schools={this.state.schools}
              schoolOptions={this.state.schoolOptions}
              districtUsers={this.state.districtUsers}
              districtAndSuperAdmins={this.state.districtAndSuperAdmins}
              jobBoardOptions={this.state.jobBoardOptions}
              applicationStatuses={this.state.newApplicationStatuses.filter(
                a => a.status_type !== 0
              )}
              apiResponseErrors={this.state.apiResponseErrors}
              clearAPIResponseErrors={this.clearAPIResponseErrors}
              activelySubmitting={this.state.activelySubmitting}
              customScorecards={this.state.customScorecardsAvailable}
              customScorecardsSelected={this.state.customScorecardsSelected}
              setCustomScorecardsSelected={s => this.setState({ customScorecardsSelected: s })}
              updateJobStatusAfterConverting={this.updateJobStatusAfterConverting.bind(this)}
              wasSuccessful={this.state.wasSuccessful}
              handleCloseSuccessToast={this.handleCloseSuccessToast.bind(this)}
            />
          )}
        </>
      )
    );
  }
}

export default withRouter(JobEditContainer);
