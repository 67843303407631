import './applicationconfirmation.css';

import { Component, useEffect, useState } from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/browser';

import WorkExperiences from './WorkExperiences';
import Education from './Education';
import Credentials from './Credentials';
import AdditionalReferences from './AdditionalReferences';
import Languages from './Languages';
import Exams from './Exams';
import Certificates from './Certificates';
import ApplicationProgressBar from '../Application/ApplicationProgressBar';
import LoadingSpinner from '../loadingSpinner';
import Subfooter from '../subfooter_poweredby';
import { checkInternal } from '../../utils/util';
import { showTotalFailure, showWarning } from '../../utils/message';
import auth from '../../utils/auth';
import { noVisibleAttachments } from '../../utils/roleutils';
import { questionType } from '../../utils/enums';
import { Logo } from 'ui-kit';
import { withRouter } from 'react-router-dom';
import SchoolsAPI from 'api/schoolsAPI';
import { getATSCredentials } from 'utils/candidateUtils';

import ApplicationsAPI from 'api/applicationsAPI';
import CandidateAPI from 'api/candidateAPI';
import UserAPI from 'api/usersAPI';

class ApplicationConfirmationComponent extends Component {
  constructor(props) {
    super(props);
    this.getUser = auth.getUser();
    this.state = {
      showSpinner: false,
      expanded: {
        experiences: false,
        education: false,
        credentials: false,
        references: false,
        languages: false,
        exams: false,
        certificates_held: false,
        additionalQuestions: false,
        videoLinkQuestions: false,
      },
      user: null,
      application: null,
      skipDocumentationPage: false,
      isInternalCandidate: false,
    };
    this.checkInternal = checkInternal.bind(this);
    const { search } = this.props.location;
    const searchParams = new URLSearchParams(search);
    const jobId = searchParams.get('job_id');
    this.state.job_id = jobId;
    this.noVisibleAttachments = noVisibleAttachments.bind(this);
  }

  componentDidMount() {
    document.body.classList.add('applicant-pages');
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    this.loadInitialData();
  }

  startSpinner = () => {
    this.setState({ showSpinner: true });
  };

  stopSpinner = () => {
    this.setState({ showSpinner: false });
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.startSpinner();
    // This should only switch the application status from -1 (draft) to 0 (district screen)
    // No application status update needed if just editing an existing application
    if (window.location.search.indexOf('ea') === -1) {
      await axios.put(
        `/api/applications/${this.state.application.id}/update_application_to_be_live/`,
        this.state.application
      );
    }

    this.stopSpinner();
    // Go to the applicant dashboard after submission
    const letters = window.location.search.indexOf('ea') !== -1 ? 'ea' : 'role_id';
    const queryString = `${letters}=${this.state.application.role.id}`;
    this.props.history.push(`/candidate-dashboard?fromConf=true&${queryString}`);
  };

  loadInitialData = async () => {
    try {
      const [fetchedUser, fetchedApplication] = await Promise.all([
        this.fetchUser(),
        this.fetchApplication(),
      ]);
      const role = fetchedApplication.role;

      let candidateSource = null;
      if (fetchedApplication.source?.id) {
        candidateSource = await this.getCandidateSource(
          fetchedApplication.source.id,
          role.district.id
        );
      }

      const shouldSkipDocumentationPage = this.checkIfWillSkipDocumentationPage(role);

      this.setState({
        user: fetchedUser,
        application: fetchedApplication,
        skipDocumentationPage: shouldSkipDocumentationPage,
        source: candidateSource,
      });
    } catch (error) {
      Sentry.captureException(error);
      showTotalFailure();
    }
  };

  fetchUser = async () => {
    try {
      const currentUser = this.getUser;
      const user = await UserAPI.fetchUser(currentUser.id);
      return user;
    } catch (error) {
      Sentry.captureException(error);
      showWarning();
    }
  };

  fetchApplication = async () => {
    try {
      const application = await ApplicationsAPI.getCandidateApplicationByRoleId(this.state.job_id);
      return application;
    } catch (error) {
      Sentry.captureException(error);
      showWarning();
    }
  };

  getCandidateSource = async (applicationSourceId, applicationDistrictId) => {
    try {
      const queryParams = {
        district_id: applicationDistrictId,
      };

      const candidateSource = await CandidateAPI.getCandidateSource(
        applicationSourceId,
        queryParams
      );
      return candidateSource;
    } catch (error) {
      Sentry.captureException(error);
      showWarning();
    }
  };

  checkIfWillSkipDocumentationPage = role => {
    let skipDocumentationPage = false;
    // If there are no addtl. questions or attachments or question sets we're going to skip the documentation page (page 3)
    // Addition 3/1/2019: or if it's an internal candidate and there are no questions/attachments required
    // for internal candidates
    if (this.noVisibleAttachments(role)) {
      skipDocumentationPage = true;
    }
    return skipDocumentationPage;
  };

  resume = () => {
    let resumeUrl = this.state.user.profile.resume;
    if (!resumeUrl) {
      return null;
    }
    let resumeToShow = resumeUrl.split('?')[0];
    resumeToShow = resumeToShow.split('/');
    resumeToShow = resumeToShow[resumeToShow.length - 1];
    return <span>Upload your resume: {resumeToShow}</span>;
  };

  toggleCardsSection = cardSection => {
    const currentState = this.state.expanded;
    currentState[cardSection] = !currentState[cardSection];
    const newStateObject = currentState;
    this.setState(newStateObject);
  };

  getNimbleQuestions = () => {
    const application = this.state.application;
    const questionSetNimbleQuestions = application.role.question_sets
      .filter(
        ({ nimble_rolequestion_set }) => nimble_rolequestion_set && nimble_rolequestion_set.length
      )
      .map(({ nimble_rolequestion_set }) => nimble_rolequestion_set)
      .flat();
    const questions = [...application.role.questions, ...questionSetNimbleQuestions];

    if (this.state.isInternalCandidate) {
      return questions.filter(q => q.is_visible_internal === true);
    } else {
      return questions.filter(q => q.is_visible_external === true);
    }
  };

  getAnsweredCustomQuestions = () => {
    const application = this.state.application;
    const questionSetCustomQuestions = application.role.question_sets
      .filter(({ custom_questions }) => custom_questions && custom_questions.length)
      .map(({ custom_questions }) => custom_questions)
      .flat();
    let questions = [...application.role.custom_questions, ...questionSetCustomQuestions];

    if (this.state.isInternalCandidate) {
      questions = questions.filter(q => q.is_visible_internal === true);
    } else {
      questions = questions.filter(q => q.is_visible_external === true);
    }

    const visibleAnswers = application.custom_answers.filter(answer =>
      questions.find(
        question => question.id === answer.question || question.id === answer.question.id
      )
    );

    visibleAnswers.forEach(answer => {
      answer.question = questions.find(
        question => question.id === answer.question || question.id === answer.question.id
      );
    });

    return visibleAnswers;
  };

  getAnsweredAttachmentQuestions = () => {
    const application = this.state.application;
    const answers = application.applicationattachment_set;
    const questionSetAttachmentQuestions = application.role.question_sets
      .filter(
        ({ requiredapplicationattachment_set }) =>
          requiredapplicationattachment_set && requiredapplicationattachment_set.length
      )
      .map(({ requiredapplicationattachment_set }) => requiredapplicationattachment_set)
      .flat();
    let questions = application.role.requiredapplicationattachment_set || [];
    questions = [...questions, ...questionSetAttachmentQuestions];

    if (this.state.isInternalCandidate) {
      questions = questions.filter(q => q.is_visible_internal === true);
    } else {
      questions = questions.filter(q => q.is_visible_external === true);
    }

    const visibleAnswers = answers.filter(answer =>
      questions.find(
        question =>
          question.id === answer.required_application_attachment ||
          question.id === answer?.required_application_attachment?.id
      )
    );

    visibleAnswers.forEach(answer => {
      answer.question = questions.find(
        question =>
          question.id === answer.required_application_attachment ||
          question.id === answer?.required_application_attachment?.id
      );
    });

    return visibleAnswers;
  };

  getAnsweredVideoLinkQuestions = () => {
    const application = this.state.application;

    const visibleAnswers = application.applicationattachmentvideourl_set.filter(answer =>
      this.state.isInternalCandidate
        ? answer.required_application_attachment.is_visible_internal === true
        : answer.required_application_attachment.is_visible_external === true
    );

    visibleAnswers.forEach(answer => {
      answer.question = answer.required_application_attachment;
    });

    return visibleAnswers;
  };

  getAnsweredSchoolPreferencesQuestion = () => {
    const application = this.state.application;
    const answer = application && application.school_preferences_answer;

    if (!answer) {
      return null;
    }

    if (this.state.isInternalCandidate === true) {
      if (answer.question.is_visible_internal) {
        return answer;
      }
    } else if (answer.question.is_visible_external) {
      return answer;
    }
  };

  renderSourceSection = () => {
    const candidateSource = this.state.source || this.state.application.source;
    if (candidateSource === 'none' || candidateSource === '') {
      return null;
    } else {
      const sourceObject = this.state.source;
      return <div>{`Found out about this role from: ${sourceObject?.label ?? ''}`}</div>;
    }
  };

  render() {
    if (!this.state.application || !this.state.user) {
      return (
        <LoadingSpinner
          margin={0}
          fontSize={2}
          message="Loading your application..."
          additionalClassname="bottom"
        />
      );
    }

    let nimbleQuestions = [];
    let attachmentsAndVideoLinkQuestions = [];
    let additionalQuestionAnswers = [];

    if (this.state.application.custom_answers) {
      additionalQuestionAnswers = [...this.getAnsweredCustomQuestions()];
    }

    if (this.state.application.applicationattachment_set) {
      attachmentsAndVideoLinkQuestions = [
        ...attachmentsAndVideoLinkQuestions,
        ...this.getAnsweredAttachmentQuestions(),
      ];
    }

    if (this.state.application.applicationattachmentvideourl_set) {
      attachmentsAndVideoLinkQuestions = [
        ...attachmentsAndVideoLinkQuestions,
        ...this.getAnsweredVideoLinkQuestions(),
      ];
    }

    if (this.state.application.role && this.state.application.role.questions) {
      nimbleQuestions = this.getNimbleQuestions();
    }

    if (this.getAnsweredSchoolPreferencesQuestion()) {
      additionalQuestionAnswers.push(this.getAnsweredSchoolPreferencesQuestion());
    }

    let atsCredentials = [];
    if (this.state.user) {
      atsCredentials = getATSCredentials(this.state.user.credentials);
    }

    nimbleQuestions = nimbleQuestions.sort((a, b) => a.order - b.order);
    attachmentsAndVideoLinkQuestions = attachmentsAndVideoLinkQuestions.sort(
      (a, b) => a.question.order - b.question.order
    );

    // filter out questions that don't have included_in_preview flag
    additionalQuestionAnswers = additionalQuestionAnswers
      .filter(answer => answer.question.included_in_preview)
      .sort((a, b) => a.question.order - b.question.order);

    return (
      <div className="application-confirmation">
        <div className="header-section">
          <div className="logo-container-outer">
            {this.state.application.role.jobboards[0]?.logo && (
              <Logo src={this.state.application.role.jobboards[0].logo} maxHeight={54} />
            )}
          </div>
          <h2 className="darker-text">Review your application</h2>
          <div>
            Review the information below before you submit. If needed, you can go back to previous
            pages to make edits before you submit.
          </div>
          {/* <div className="application-steps">Step 3 of 3</div> */}
          <ApplicationProgressBar
            step={4}
            skipDocumentationPage={this.state.skipDocumentationPage}
          />
        </div>
        <div className="application-confirmation-main-section">
          <div className="application-section application-confirmation-profile-info">
            <h4 className="darker-text">Your Profile Information</h4>
            <div className="flex">
              <div className="basic">
                <div>Name: {this.state.user.name}</div>
                <div>Email: {this.state.user.email}</div>
                <div>
                  Address: {this.state.user.profile.address_street},{' '}
                  {this.state.user.profile.address_city}, {this.state.user.profile.address_state}{' '}
                  {this.state.user.profile.address_zip}
                </div>
                <div>Cell Phone: {this.state.user.profile.phone_cell || 'Not provided'}</div>
                <div>
                  Work Phone:{' '}
                  {this.state.user.profile.phone_work.length > 0
                    ? this.state.user.profile.phone_work
                    : 'Not provided'}
                </div>
                {this.renderSourceSection()}
              </div>
            </div>
          </div>

          <div className="application-section application-confirmation-cards-section">
            {this.state.user.experiences && this.state.user.experiences.length > 0 ? (
              <div className="cards-section">
                <div
                  className="cards-expander-title experiences"
                  onClick={() => this.toggleCardsSection('experiences')}
                >
                  <span className="plus-sign">+</span>{' '}
                  <span className="darker-text">Work History</span> -{' '}
                  {this.state.user.experiences.length === 1
                    ? '1 Entry'
                    : `${this.state.user.experiences.length} Entries`}
                </div>
                <div
                  className={
                    this.state.expanded.experiences
                      ? 'application-confirmation-cards-expander experiences expanded'
                      : 'application-confirmation-cards-expander experiences'
                  }
                >
                  <WorkExperiences experiences={this.state.user.experiences} />
                </div>
              </div>
            ) : null}

            {this.state.user.languages.length > 0 ? (
              <div className="cards-section">
                <div
                  className="cards-expander-title languages"
                  onClick={() => this.toggleCardsSection('languages')}
                >
                  <span className="plus-sign">+</span>{' '}
                  <span className="darker-text">Languages</span> -{' '}
                  {this.state.user.languages.length === 1
                    ? '1 Entry'
                    : `${this.state.user.languages.length} Entries`}
                </div>
                <div
                  className={
                    this.state.expanded.languages
                      ? 'application-confirmation-cards-expander languages expanded'
                      : 'application-confirmation-cards-expander languages'
                  }
                >
                  <Languages languages={this.state.user.languages} />
                </div>
              </div>
            ) : null}

            {this.state.user.education && this.state.user.education.length > 0 ? (
              <div className="cards-section">
                <div
                  className="cards-expander-title education"
                  onClick={() => this.toggleCardsSection('education')}
                >
                  <span className="plus-sign">+</span>{' '}
                  <span className="darker-text">Education</span> -{' '}
                  {this.state.user.education.length === 1
                    ? '1 Entry'
                    : `${this.state.user.education.length} Entries`}
                </div>
                <div
                  className={
                    this.state.expanded.education
                      ? 'application-confirmation-cards-expander education expanded'
                      : 'application-confirmation-cards-expander education'
                  }
                >
                  <Education education={this.state.user.education} />
                </div>
              </div>
            ) : null}

            {this.state.user.exams && this.state.user.exams.length > 0 ? (
              <div className="cards-section">
                <div
                  className="cards-expander-title exams"
                  onClick={() => this.toggleCardsSection('exams')}
                >
                  <span className="plus-sign">+</span> <span className="darker-text">Exams</span> -{' '}
                  {this.state.user.exams.length === 1
                    ? '1 Entry'
                    : `${this.state.user.exams.length} Entries`}
                </div>
                <div
                  className={
                    this.state.expanded.exams
                      ? 'application-confirmation-cards-expander exams expanded'
                      : 'application-confirmation-cards-expander exams'
                  }
                >
                  <Exams exams={this.state.user.exams} />
                </div>
              </div>
            ) : null}

            {this.state.user.profile.certificates_held &&
            this.state.user.profile.certificates_held.length > 0 ? (
              <div className="cards-section">
                <div
                  className="cards-expander-title certificates_held"
                  onClick={() => this.toggleCardsSection('certificates_held')}
                >
                  <span className="plus-sign">+</span>{' '}
                  <span className="darker-text">Certificates Held</span> -{' '}
                  {this.state.user.profile.certificates_held.length === 1
                    ? '1 Entry'
                    : `${this.state.user.profile.certificates_held.length} Entries`}
                </div>
                <div
                  className={
                    this.state.expanded.certificates_held
                      ? 'application-confirmation-cards-expander certificates_held expanded'
                      : 'application-confirmation-cards-expander certificates_held'
                  }
                >
                  <Certificates
                    certificates_held={this.state.user.profile.certificates_held}
                    other_certificate_explanation={
                      this.state.user.profile.other_certificate_explanation
                    }
                  />
                </div>
              </div>
            ) : null}

            {(atsCredentials && atsCredentials.length > 0) ||
            this.state.user.profile.has_no_credentials ? (
              <div className="cards-section">
                <div
                  className="cards-expander-title credentials"
                  onClick={() => this.toggleCardsSection('credentials')}
                >
                  <span className="plus-sign">+</span>{' '}
                  <span className="darker-text">Credentials</span> -{' '}
                  {atsCredentials.length === 1 || this.state.user.profile.has_no_credentials
                    ? '1 Entry'
                    : `${atsCredentials.length} Entries`}
                </div>
                <div
                  className={
                    this.state.expanded.credentials
                      ? 'application-confirmation-cards-expander credentials expanded'
                      : 'application-confirmation-cards-expander credentials'
                  }
                >
                  <Credentials
                    credentials={atsCredentials}
                    hasNoCredentials={this.state.user.profile.has_no_credentials}
                  />
                </div>
              </div>
            ) : null}

            {this.state.user.additional_references &&
            this.state.user.additional_references.length > 0 ? (
              <div className="cards-section">
                <div
                  className="cards-expander-title references"
                  onClick={() => this.toggleCardsSection('references')}
                >
                  <span className="plus-sign">+</span>{' '}
                  <span className="darker-text">References</span> -{' '}
                  {this.state.user.additional_references.length === 1
                    ? '1 Entry'
                    : `${this.state.user.additional_references.length} Entries`}
                </div>
                <div
                  className={
                    this.state.expanded.references
                      ? 'application-confirmation-cards-expander references expanded'
                      : 'application-confirmation-cards-expander credentials'
                  }
                >
                  <AdditionalReferences
                    additionalReferences={this.state.user.additional_references}
                  />
                </div>
              </div>
            ) : null}

            {additionalQuestionAnswers.length > 0 && (
              <div className="cards-section">
                <div
                  className="cards-expander-title additionalQuestions"
                  onClick={() => this.toggleCardsSection('additionalQuestions')}
                >
                  <span className="plus-sign">+</span>{' '}
                  <span className="darker-text">Additional Questions</span> -{' '}
                  {`${additionalQuestionAnswers.length} Entr${
                    additionalQuestionAnswers.length === 1 ? 'y' : 'ies'
                  }`}
                </div>
                <div
                  className={
                    this.state.expanded.additionalQuestions
                      ? 'application-confirmation-cards-expander additionalQuestions expanded'
                      : 'application-confirmation-cards-expander additionalQuestions'
                  }
                >
                  <div className="mt1 ml2">
                    {additionalQuestionAnswers.map((answer, index) => {
                      const AnswerPreviewComponent =
                        answer.question.question_type === questionType.schoolPreferences
                          ? SchoolPrefrencesAnswerPreview
                          : CustomQuestionAnswerPreview;

                      return (
                        <div key={index} className="mb1">
                          <AnswerPreviewComponent
                            answer={answer}
                            application={this.state.application}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="application-section application-confirmation-attachments-section">
            <div>{this.state.user.profile ? this.resume() : null}</div>
            <div>
              {attachmentsAndVideoLinkQuestions.map((answer, index) => {
                if (answer.question.question_type === questionType.videoLink) {
                  return (
                    <VideoLinkQuestionAnswerPreview
                      key={index}
                      answer={answer}
                      question={answer.question}
                    />
                  );
                } else {
                  return (
                    <AttachmentQuestionAnswerPreview
                      key={index}
                      answer={answer}
                      question={answer.question}
                    />
                  );
                }
              })}
            </div>
          </div>

          <div className="application-sectionapplication-confirmation-questions-section">
            <div>
              {nimbleQuestions.map((question, index) => (
                <NimbleQuestionAnswerPreview
                  key={index}
                  question={question}
                  answer={this.state.user.answers.find(
                    a => a.question.id === question.nimblequestion_id
                  )}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="application-section application-confirmation-submit bottom-section flex">
          <form className="" encType="multipart/form-data" onSubmit={this.handleSubmit}>
            <div className="application-section application-confirmation-submit-content bottom-section-content">
              <h4 className="section-title">You're ready to submit your application</h4>
              <h4>Once you’ve submitted the application you can’t edit it again.</h4>
              <div>
                <input
                  id="go-back"
                  className="submit disabled btn-lrg go-back mr2"
                  type="submit"
                  value="Previous step"
                  onClick={() => {
                    const queryString = window.location.search.indexOf('ea') !== -1 ? '?ea=t' : '';
                    if (!this.state.skipDocumentationPage) {
                      this.props.history.push(
                        `/application-documentation/${this.state.job_id}/${queryString}`
                      );
                    } else {
                      this.props.history.push(`/application/${this.state.job_id}/${queryString}`);
                    }
                  }}
                />
                <input
                  className="submit btn-lrg"
                  id="submit-my-application-button"
                  type="submit"
                  value="Submit my application"
                  disabled={this.state.showSpinner}
                />

                {this.state.showSpinner && (
                  <LoadingSpinner
                    margin={0}
                    fontSize={2}
                    message="This may take a few seconds."
                    additionalClassname="bottom"
                  />
                )}
              </div>
            </div>
          </form>
          <Subfooter />
        </div>
      </div>
    );
  }
}

const CustomQuestionAnswerPreview = ({ answer }) => {
  let answerText;

  switch (answer.question.question_type) {
    case questionType.multiple_choice:
      answerText = answer.mc_answer === null ? 'No answer' : answer.mc_answer.join(', ');
      break;
    case questionType.open_response:
      answerText = answer.text || 'No answer';
      break;
    case questionType.yes_no:
      answerText = answer.yes === true ? 'Yes' : answer.yes === false ? 'No' : 'No answer';
      break;
    case questionType.statementCheckbox:
      if (answer.agreed_to_statement === true) {
        answerText = 'Yes (box checked)';
      } else if (answer.agreed_to_statement === false) {
        answerText = 'No (box not checked)';
      } else {
        answerText = 'No answer';
      }
      break;
    default:
      answerText = 'No answer';
  }

  return (
    <>
      <div>Q. {answer.question.prompt}</div>
      <div>A. {answerText}</div>
    </>
  );
};

const VideoLinkQuestionAnswerPreview = ({ answer, question }) => {
  return (
    <div>
      {`Upload your ${question.title}: `}
      <a href={answer.video_url} rel="noopener noreferrer" target="_blank">
        {answer.video_url}
      </a>
    </div>
  );
};

const AttachmentQuestionAnswerPreview = ({ answer, question }) => {
  let attachmentString = answer.attachment.split('?')[0];
  attachmentString = attachmentString.split('/');
  attachmentString = attachmentString[attachmentString.length - 1];

  let text;
  if (question) {
    text = `Upload your ${(question && question.title) || 'attachment'}: ${attachmentString}`;
  } else {
    text = `Upload your ${answer.title}: ${attachmentString}`;
  }

  return <div>{text}</div>;
};

const NimbleQuestionAnswerPreview = ({ answer, question }) => {
  return (
    <div className="mb1">
      <div>{question?.question ?? 'Question text not available'}</div>
      <div>{answer?.answer ?? 'No response.'}</div>
    </div>
  );
};

const SchoolPrefrencesAnswerPreview = answer => {
  const schoolIds = answer.answer.selected_schools;
  const isOpenToOtherOptions = answer.answer.is_open_to_other_options;
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  let districtId = answer.application.source.district;

  const findSelectedSchoolName = (schoolOptionsArray, schoolIdsArray) => {
    return schoolIdsArray.map(id => schoolOptionsArray.find(school => school.id === id));
  };

  useEffect(() => {
    if (schoolIds.length === 0) return; // If no school preferences, do not render

    SchoolsAPI.fetchActiveSchools(districtId).then(activeSchools => {
      setSchoolOptions(activeSchools);
      const selected = findSelectedSchoolName(activeSchools, schoolIds);
      setSelectedSchools(selected);
    });
  }, [districtId, schoolIds]);

  return (
    <>
      <div>Q. Selected School Preferences</div>
      {selectedSchools.length > 0 ? (
        <div>
          A.{' '}
          {selectedSchools
            .map(school => school?.name)
            .sort()
            .join(', ')}
        </div>
      ) : (
        <div>A. [No school preferences selected]</div>
      )}
      {isOpenToOtherOptions && (
        <div>I'm open to being contacted about opportunities at other locations</div>
      )}
    </>
  );
};

export default withRouter(ApplicationConfirmationComponent);
