import { useCallback, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import { useOnClickOutside } from 'hooks';
import auth from 'utils/auth';

import Nudges from 'features/TopCandidates/Nudges';
import ProfileTimelineTooltip from './ProfileTimeline/ProfileTimelineTooltip';
import StatusView from 'components/common/StatusView';
import HRBPView from './HRBP/index';

import { ReactComponent as IconDownCaretWithSquare } from 'assets/icon_down_caret_with_square.svg';
import { ReactComponent as ScorecardIcon } from 'assets/icon-scorecard.svg';
import { getScorecardCount } from 'utils/scorecardutils';

export default function CandidateHeaderSection({
  user,
  // application currently being viewed, can be a schoolapplication or district application
  application,
  schoolapplications = [],
  isDraft,
  openEmailModal,
  openScorecardModal,
  openCopyApplicationModal,
  events,
  updateUserStatus,
  updateSchoolApp,
  viewStatusModal,
  emailTemplatesList,
  adminUsers,
  newApplicationStatuses,
  districtApplicationBeingViewedBySchoolAdmin,
  openPrintDialogue,
}) {
  const [showActionDropdown, setShowActionDropdown] = useState(false);
  const toggleApplicationCopyButton = useCallback(
    () => setShowActionDropdown(!showActionDropdown),
    [showActionDropdown]
  );
  const closeDropdown = useCallback(() => setShowActionDropdown(false), []);
  const closeNodeOnClickOutside = useOnClickOutside(closeDropdown, showActionDropdown);

  const getLastUpdated = () => {
    let updated = null;
    let updated_by = null;
    if (application?.updated) {
      updated = application.updated;
      updated_by = application.updated_by;
    }
    return [updated, updated_by];
  };

  const [updated, updated_by] = getLastUpdated();
  const showLastUpdatedTooltip = events.length > 0;

  const showCopyApplicationButton = !isDraft && application && auth.isDistrictUser();

  const scorecard_count = getScorecardCount(application);

  return (
    <div>
      <HeaderContainer>
        <Header>{user.name}</Header>
        {!isDraft && (
          <div className="relative" ref={closeNodeOnClickOutside}>
            <IconDownCaretWithSquare onClick={toggleApplicationCopyButton} />
            <div className={`quickview-dropdown-div ${showActionDropdown ? 'expanded' : ''}`}>
              {application.permissions.send_email && (
                <div className="quickview-dropdown-btn" onClick={openEmailModal}>
                  Email
                </div>
              )}
              <div className="quickview-dropdown-btn" onClick={openPrintDialogue}>
                Download/Print
              </div>
              {showCopyApplicationButton && (
                <div className="quickview-dropdown-btn" onClick={openCopyApplicationModal}>
                  Copy Application
                </div>
              )}
            </div>
          </div>
        )}
      </HeaderContainer>
      <LargeGreySubHeaderText>{application.role.title}</LargeGreySubHeaderText>
      <OuterGridContainer>
        <InnerGridContainerTwoRows>
          {updated && (
            <div>
              <span
                data-tip
                data-for="last-updated"
                style={showLastUpdatedTooltip ? { borderBottom: '1px solid' } : {}}
              >
                Last updated
              </span>
              {` ${moment(updated).format('l')}${updated_by ? ` by ${updated_by}` : ''}`}
            </div>
          )}
          {showLastUpdatedTooltip && (
            <ReactTooltip id="last-updated" effect="solid" place="bottom">
              <ProfileTimelineTooltip events={events} user={user} />
            </ReactTooltip>
          )}
          {!isDraft && user.scorecard_count !== undefined && (
            <ScorecardHeaderContainer>
              <ScorecardIcon />
              <div id="scorecardCount">
                <span>{`${scorecard_count} interview scorecard${
                  scorecard_count === 1 ? '' : 's'
                }`}</span>
                <GreenTextButton onClick={openScorecardModal}>(add/view)</GreenTextButton>
              </div>
            </ScorecardHeaderContainer>
          )}
        </InnerGridContainerTwoRows>
        {/* Only show nudges for top candidates */}
        {application.top_candidate ? (
          <Nudges candidate={application} onFullProfile={true} />
        ) : (
          <div />
        )}
        <HRBPView
          hrbpAssignments={application.role.hrbp_assignments}
          schoolApplications={application.schoolapplications}
        />
      </OuterGridContainer>

      {!isDraft && (
        <StatusView
          user={user}
          applicationBeingQuickviewed={application}
          updateUserStatus={updateUserStatus}
          updateSchoolApp={updateSchoolApp}
          viewStatusModal={viewStatusModal}
          schoolapplications={schoolapplications}
          isSchoolUser={auth.isSchoolUser()}
          isDistrictUser={auth.isDistrictUser()}
          emailTemplatesList={emailTemplatesList}
          adminUsers={adminUsers}
          newApplicationStatuses={newApplicationStatuses}
          districtApplicationBeingViewedBySchoolAdmin={districtApplicationBeingViewedBySchoolAdmin}
        />
      )}
    </div>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Header = styled.p`
  font-size: 25px;
  font-weight: 600;
  line-height: 34px;
  color: #444444;

  margin-right: 1rem;
`;

const LargeGreySubHeaderText = styled.p`
  color: #777777;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;

  margin-top: 4px;
`;

const OuterGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 40px;

  margin: 22px 0;
  font-size: 15px;
  font-weight: 400;
`;

const InnerGridContainerThreeRows = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 30px);
  align-items: center;
`;

const InnerGridContainerTwoRows = styled(InnerGridContainerThreeRows)`
  grid-template-rows: repeat(2, 30px);

  div,
  span {
    color: #999999 !important;
  }

  .__react_component_tooltip.type-dark div,
  .__react_component_tooltip.type-dark span {
    color: #ffffff !important;
  }
`;

const GreenTextButton = styled.button`
  background-color: transparent;
  padding: 0;
  text-decoration: underline;
  border: none;
  margin-left: 4px;

  &:hover {
    color: #00b88d;
  }
`;

const ScorecardHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 18px 1fr;
  grid-gap: 6px;
  align-items: center;

  font-weight: 400;
`;
