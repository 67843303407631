import PropTypes from 'prop-types';
import styled from 'styled-components';

import CustomScorecardRow from './CustomScorecardRow';
import { useHistory } from 'react-router-dom';
import { AnchorTagHeader } from 'sharedComponents/Header/AnchorTagHeader';

export default function CustomScorecardList({
  scorecardlist,
  defaultScorecardId,
  setDefaultScorecardId,
  clearDefaultScorecardId,
}) {
  const history = useHistory();

  const onDefaultScorecardChange = (scorecardId, isSelected) => {
    isSelected ? setDefaultScorecardId(scorecardId) : clearDefaultScorecardId();
  };

  const handleClick = () => history.push('/createscorecard');
  return (
    <SettingsPageSection>
      <AnchorTagHeader
        id="scorecards"
        title="Scorecards"
        onClick={handleClick}
        buttonText="+ New Scorecard"
      />
      <HeaderContainer>
        <SectionLarger>Title</SectionLarger>
        <SectionMedium>Question Type</SectionMedium>
        <Section>Visible To</Section>
        <Section>My Default</Section>
      </HeaderContainer>
      {scorecardlist.length === 0 && (
        <EmptyNotificationContainer>
          <EmptyNotificationPlaceholderText>
            Click "+ New Scorecard" button above to create new scorecard form
          </EmptyNotificationPlaceholderText>
        </EmptyNotificationContainer>
      )}
      {scorecardlist.map((scorecard, index) => (
        <CustomScorecardRow
          key={index}
          scorecard={scorecard}
          isDefaultScorecard={defaultScorecardId === scorecard.id}
          onDefaultScorecardChange={isSelected =>
            onDefaultScorecardChange(scorecard.id, isSelected)
          }
        />
      ))}
    </SettingsPageSection>
  );
}

CustomScorecardList.propTypes = {
  scorecardlist: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultScorecardId: PropTypes.number,
  setDefaultScorecardId: PropTypes.func.isRequired,
  clearDefaultScorecardId: PropTypes.func.isRequired,
};

const SettingsPageSection = styled.div`
  margin-top: 41px;
`;

const EmptyNotificationContainer = styled.div`
  height: 208px;
  border: 1px dashed #949494;
  border-top: none;
  border-radius: 0 0 3px 3px;
  display: flex;
  align-items: center;
`;

const EmptyNotificationPlaceholderText = styled.p`
  font-family: Open Sans;
  font-size: 17px;
  color: #bdbdbd;
  margin: 0 auto;
`;

const HeaderContainer = styled.div`
  color: #999999;
  background-color: #ffffff;
  display: flex;
  line-height: 16px;
  font-size: 12px;
  padding: 7px 16px;
  border: 1px solid #eee;
`;

const Section = styled.p`
  flex: 0 0 20%;
  padding-right: 16px;
`;

const SectionLarger = styled(Section)`
  flex: 0 0 32%;
`;

export const SectionMedium = styled(Section)`
  flex: 0 0 25%;
`;
