export enum ConnectActionButtonsDataTestIds {
  EXPRESS_INTEREST_BUTTON = 'express-interest-button',
  SAVE_SCHOOL_BUTTON = 'connect-save-school-button',
}

export enum ConnectAlertsBannerDataTestIds {
  NOT_SUBSCRIBED_BANNER = 'connect-alerts-banner-not-subscribed',
  SUBSCRIBED_BANNER = 'connect-alerts-banner-subscribed',
}

export enum ConnectGatedResultsCardDataTestIds {
  SIGNUP_BUTTON = 'gated-results-card-signup-button',
}

export enum ConnectJobDiscoveryFiltersDataTestIds {
  DISTANCE_FILTER = 'connect-distance-filter-dropdown',
  GRADE_FILTER = 'connect-grade-level-filter',
  LOCATION_INPUT = 'connect-location-filter-input',
  SUBJECTS_FILTER = 'connect-subject-filter',
  UPDATE_DISTANCE_FROM_LOCATION_SUBMIT_BUTTON = 'connect-location-filter-submit-button',
}

export enum ConnectMarketingInputAndButtonDataTestIds {
  COMPLETE_PROFILE_BUTTON = 'connect-marketing-banner-complete-profile-button',
  EMAIL_INPUT = 'marketing-email-input-jobs-discovery',
  SIGN_UP_BUTTON = 'marketing-sign-up-button',
}
