import styled from 'styled-components';
import lockIcon from 'assets/icons/lock.svg';

export default function LinkedProfile({ user }) {
  return (
    <div className="full-profile-container">
      <Section>
        <LinkedHeader>{user.name}</LinkedHeader>
        <LinkedSubHeader>{user.application.role.title}</LinkedSubHeader>

        <LinkedMessage>
          <img alt="lock-icon" src={lockIcon} />
          <span>Looks like you don’t have access to view this profile</span>
        </LinkedMessage>
      </Section>
    </div>
  );
}

const Section = styled.div`
  padding: 26px;
  margin-bottom: 1rem;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.095);
`;

const LinkedHeader = styled.p`
  font-size: 25px;
  font-weight: 600;
  line-height: 34px;
  color: #444444;

  margin-right: 1rem;
`;

const LinkedSubHeader = styled.p`
  color: #777777;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;

  margin-top: 4px;
`;

const LinkedMessage = styled.div`
  background-color: ${props => props.theme.uiColors.greens.full};
  color: ${props => props.theme.uiColors.white};
  padding: 1rem;
  border-radius: 3px;
  margin-top: 50px;
  margin-bottom: 50px;

  > img {
    margin-right: 1rem;
    vertical-align: bottom;
  }
`;
