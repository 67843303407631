import './resetpassword.css';

import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import auth from '../../utils/auth';

import { NimbleLogoDarkText } from 'ui-kit/icons';

import styled from 'styled-components';

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      formIsValid: false,
      hideForm: false,
      password1: '',
      password2: '',
      pre_validation_error: '',
    };

    document.body.className += ` auth-pages`;
  }

  static propTypes = {
    resetPassword: PropTypes.func.isRequired,
    isConnect: PropTypes.bool,
  };

  componentWillUnmount() {
    let newClasses = document.body.className.replace('auth-pages', '');
    document.body.className = newClasses;
  }

  getUrlParameter = name => {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null ? '' : results[1];
  };

  uppercaseOrSpecialCharacterRule = inputValue => {
    const hasUppercase = /[A-Z]/.test(inputValue);
    // source: https://owasp.org/www-community/password-special-characters except for blank spaces
    const hasSpecialCharacter = /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/.test(inputValue);
    return hasUppercase || hasSpecialCharacter;
  };

  noEmptySpaceRule = inputValue => !inputValue.includes(' ');

  formIsValid = () => {
    this.setState({
      errors: {},
      pre_validation_error: '',
    });

    if (this.state.password1 !== this.state.password2) {
      this.setState({
        formIsValid: false,
        pre_validation_error: "Passwords don't match",
      });
      return;
    }

    if (this.state.password1.length < 10) {
      this.setState({
        formIsValid: false,
        pre_validation_error: 'Password is too short',
      });
      return;
    }

    if (!this.uppercaseOrSpecialCharacterRule(this.state.password1)) {
      this.setState({
        formIsValid: false,
        pre_validation_error:
          'Password must contain at least 1 uppercase letter or 1 special character',
      });
      return;
    }

    if (!this.noEmptySpaceRule(this.state.password1)) {
      this.setState({
        formIsValid: false,
        pre_validation_error: 'Password must not contain spaces',
      });
      return;
    }

    this.setState({ formIsValid: true });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value }, this.formIsValid);
  };

  onSuccess = response => {
    this.setState({
      hideForm: true,
      password1: '',
      password2: '',
    });
    // Force logout after resetting password.
    auth.logout();
  };

  onError = response => {
    this.setState({
      errors: response.data,
      formIsValid: false,
      pre_validation_error: '',
      password1: '',
      password2: '',
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.resetPassword(this.state.password1, this.onSuccess, this.onError);
  };

  render() {
    const isConnect = this.props.isConnect;

    return (
      <div className="App-container">
        <div className="login-page-nimble-logo-container">
          <NimbleLogoDarkText width="165px" height="77px" />
        </div>
        <div className="flex-2">
          <div className={'card ' + (this.state.hideForm ? '' : 'hidden')}>
            You can now{' '}
            <Link className="reset-login" to={isConnect ? '/connect' : '/login'}>
              {isConnect ? 'access Nimble Connect' : 'log in'}
            </Link>{' '}
            with your new password!
          </div>
          <div className={'App-container__content card ' + (this.state.hideForm ? 'hidden' : '')}>
            <div className="App-container__content__padding">
              <h2>Password Reset</h2>
              <hr className="mb2 mt1" />
              <form onSubmit={this.handleSubmit.bind(this)}>
                <div className="reset-password-input">
                  <input
                    name="password1"
                    ref="password1"
                    type="password"
                    placeholder="New password"
                    value={this.state.password1}
                    required
                    onChange={this.handleChange}
                    className="password"
                  />
                </div>
                <div className="reset-password-input">
                  <input
                    name="password2"
                    ref="password2"
                    type="password"
                    placeholder="Confirm new password"
                    value={this.state.password2}
                    required
                    onChange={this.handleChange}
                    className="password-confirm"
                  />
                </div>
                <StyledErrorText>
                  {this.state.pre_validation_error ||
                    this.state.errors.detail ||
                    this.state.errors.password?.join(' ').replace(/username/gi, 'email')}
                </StyledErrorText>
                {this.state.errors.signature &&
                this.state.errors.signature.includes('Expired signature') ? (
                  <div className="password-reset-link">
                    Oops, the time limit for this link has expired. Please click
                    <a href="/password_reset"> here</a> and enter your email address to get a new
                    link.
                  </div>
                ) : (
                  <StyledErrorText>{this.state.errors.signature}</StyledErrorText>
                )}
                <div>
                  <input
                    className={`reset-button btn ${
                      this.state.formIsValid ? 'btn-success' : 'btn-disabled'
                    }`}
                    type="submit"
                    value="Reset Password"
                    disabled={!this.state.formIsValid}
                  />
                </div>
              </form>
              <hr className="mt2 mb1" />
              <div className="password-requirements">
                Password must be at least 10 characters. It must include at least 1 uppercase letter
                or special character. It cannot be similar to your user information.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const StyledErrorText = styled.p`
  margin-left: 8px;
  font-size: 12px;
  font-weight: normal;
  color: #00b88d;
`;
