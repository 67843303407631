import { Body } from 'ui-kit/Modal';
import { NimbleLogoBlack } from 'ui-kit/icons';
import { DropdownWithInputFilter } from 'ui-kit';
import { ModalBodySectionHeader } from '../styledComponents';

import HeaderInformation from './HeaderInformation';

export default function NimbleQuestion({
  nimbleQuestionId,
  setNimbleQuestionId,
  questionBank,
  internalRequirementsSpecified,
  handleItemMatrixChange,
  itemMatrix,
}) {
  return (
    <Body>
      <HeaderInformation
        Icon={NimbleLogoBlack}
        title="Nimble Question"
        subtext="Candidate can respond in free form text"
        internalRequirementsSpecified={internalRequirementsSpecified}
        handleItemMatrixChange={handleItemMatrixChange}
        itemMatrix={itemMatrix}
      />
      <ModalBodySectionHeader>Select a Nimble question here:</ModalBodySectionHeader>
      <DropdownWithInputFilter
        placeholder="Start typing or select a question"
        options={questionBank.map(q => ({ value: q.id, label: q.question }))}
        value={nimbleQuestionId}
        handleChange={value => setNimbleQuestionId(value)}
        onClear={() => setNimbleQuestionId(null)}
        boxShadow={false}
      />
    </Body>
  );
}
