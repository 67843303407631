import axios from 'axios';
import auth from '../utils/auth';
import {
  appStatusType,
  internalList,
  grades,
  preferenceOptions,
  preferenceCategories,
  languageList,
  employmentTypeAndHours,
  roleStatusesForSearchFilters,
  USStates,
} from '../utils/enums';
import { showWarning } from './message';
import {
  deleteQueryStringValue,
  getQueryStringValue,
  setQueryStringValue,
  setQueryStringWithoutPageReload,
} from './util';
/*
 *   Utitily functions for application status updating
 *   Shared across quickprofile and full profile container and child components
 */

/*
 *  Get admins related to a user's district
 */
export function fetchAdmins(callback) {
  axios.get('/api/user/get_all_admins_in_district/').then(r => {
    const adminUsers = [];
    r.data.forEach(userObj => {
      userObj['display'] = `${userObj['first_name']} ${userObj['last_name']}`;
      adminUsers.push(userObj);
    });
    if (!this.ignoreLastFetch) {
      this.setState({ adminUsers: r.data }, () => {
        if (callback) callback();
      });
    }
  });
}

/**
 * get email templates for a certain user (used on candidateslist, reqlist, and profile)
 */
export function fetchEmailTemplates() {
  axios.get('/api/emailtemplates/', { params: { personal: false } }).then(r => {
    if (!this.ignoreLastFetch) {
      this.setState({ emailTemplatesList: r.data });
    }
  });
}

export function fetchCategories(district_id, setFilters = false) {
  /** get list of categories for given district */
  axios.get(`/api/categories/`, { params: { district_id } }).then(r => {
    let categories = r.data;
    let job_subject_list = [];
    if (setFilters) {
      categories.forEach(c => {
        c.subcategories.forEach(s => {
          job_subject_list.push(Number(s.id));
        });
      });
    }

    const filter_job_subject_list = getQueryStringValue('f_JSU');
    if (filter_job_subject_list) {
      job_subject_list = filter_job_subject_list.split(',').map(i => Number(i));
    }
    this.setState({
      categories,
      job_subject_list,
      job_subject_length: job_subject_list.length,
    });
  });
}

export function setSubcategories(district_id, setFilters = false) {
  /** get categories from db, then iterate through and set subcategories. The subcategories
   * are expected to be objects with a value property and label property.
   */

  axios.get(`/api/categories/`, { params: { district_id } }).then(r => {
    const categories = r.data || [];

    let subcats = [];
    let subcategoriesFilter = [];

    categories.forEach(c => {
      c.subcategories.forEach(s => {
        subcats.push({
          value: s.id,
          label: s.label,
        });
        if (setFilters) {
          subcategoriesFilter.push(s.id);
        }
      });
    });
    if (setFilters) {
      // setFilters is true when this function is called on the dashboard page.
      // we also want the subcategories in alphabetical order on that page.
      subcats = subcats.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
    }
    this.setState({
      categories: categories,
      subcategories: subcats,
      subcategoriesFilter: setFilters && subcategoriesFilter,
    });
  });
}

export function fetchApplicationStatuses(resolve, reject) {
  axios.get(`/api/application_statuses/`).then(r => {
    this.setState({ newApplicationStatuses: r.data });
    if (resolve) {
      resolve(r.data);
    }
  });
}

export function fetchCredentialOptions(resolve, reject) {
  // eventually district state should be a foreign key to state model
  let stateObj = USStates().filter(state => state.label === this.district.state)[0];
  axios.get(`/api/state/${stateObj.id}`).then(r => {
    let credentialOptionsFormatted = r.data.credential_subjects.map(item => {
      item['key'] = item.id;
      item['label'] = item.value;
      return item;
    });
    this.setState({ credentialOptions: credentialOptionsFormatted });
    if (resolve) {
      resolve(r.data);
    }
  });
}

export function isPostHiringPool(statusType) {
  /** Return true if given status is post hiring pool, otherwise return false. */
  return ![appStatusType.draft, appStatusType.pre_hiring_pool].includes(statusType);
}

export function getInitialFilters(district_id) {
  /** Filters are all selected by defaults, so push values into state.
   * Store lengths in state. When the lists are equal to the length (always true to begin
   * with), we don't have to search for filters since they're all returning, so just
   * pass an empty array in that case.
   */
  let status_list = [];
  let job_status_list = [0, 1, 2, 3];
  let internal_list = internalList().map(i => i.value);
  let cred_list = [];
  let multilingual_school = preferenceOptions().map(o => o.value);
  let title_1_school = preferenceOptions().map(o => o.value);
  let turnaround_school = preferenceOptions().map(o => o.value);

  let language_list = [];
  // special filter array for languages
  languageList().forEach(l => {
    // if languageProficiency changes, make sure to change it here
    ['a', 'b', 'c', 'd', 'e'].forEach(i => {
      language_list.push(l.value + i);
    });
  });

  let grades_list = grades().map(g => g.value);
  let preference_categories = preferenceCategories().map(c => c.value);
  let employment_type_and_hours = employmentTypeAndHours().map(e => e.value);

  let experienceStart = 0;
  let experienceEnd = 30;

  let isOpenToOtherOptions = true;
  let selectedSchoolIds = [];

  let task_list = [];
  let hellosign_templates = [];
  let job_subject_list = [];
  let onboarding_locations = [];
  let tag_ids = [];
  let hrbp_assignees = [];
  let candidate_source_list = [];

  // Parse query string and set state with contents
  const f_CS = getQueryStringValue('f_CS');
  const f_JS = getQueryStringValue('f_JS');
  const f_IE = getQueryStringValue('f_IE');
  const f_C = getQueryStringValue('f_C');
  const f_MS = getQueryStringValue('f_MS');
  const f_TI = getQueryStringValue('f_TI');
  const f_TS = getQueryStringValue('f_TS');
  const f_L = getQueryStringValue('f_L');
  const f_G = getQueryStringValue('f_G');
  const f_PC = getQueryStringValue('f_PC');
  const f_ET = getQueryStringValue('f_ET');
  const f_ES = getQueryStringValue('f_ES');
  const f_EE = getQueryStringValue('f_EE');
  const f_O = getQueryStringValue('f_O');
  const f_SS = getQueryStringValue('f_SS');
  const f_T = getQueryStringValue('f_T');
  const f_HT = getQueryStringValue('f_HT');
  const f_JSU = getQueryStringValue('f_JSU');
  const f_OL = getQueryStringValue('f_OL');
  const f_TG = getQueryStringValue('f_TG');
  const f_HA = getQueryStringValue('f_HA');
  const f_CSL = getQueryStringValue('f_CSL');

  // If there is a default filter "f_*", update that filter in state
  // with the default value.
  if (f_CS) status_list = f_CS.split(',').map(i => Number(i));
  if (f_JS) job_status_list = f_JS.split(',').map(i => Number(i));
  if (f_IE) internal_list = f_IE.split(',').map(i => Number(i));
  if (f_C) cred_list = f_C.split(',').map(i => Number(i));
  if (f_MS) multilingual_school = f_MS.split(',').map(i => Number(i));
  if (f_TI) title_1_school = f_TI.split(',').map(i => Number(i));
  if (f_TS) turnaround_school = f_TS.split(',').map(i => Number(i));
  if (f_L) language_list = f_L.split(',');
  if (f_G) grades_list = f_G.split(',').map(i => Number(i));
  if (f_PC) preference_categories = f_PC.split(',').map(i => Number(i));
  if (f_ET)
    employment_type_and_hours = f_ET.split(',').map(i => {
      // this field contains both strings and numbers. Employment type
      // is represented by the integers 1 and 2. Hours are represented by
      // strings, eg. "0-10".
      if (i === '1' || i === '2') return Number(i);
      return i;
    });
  if (f_ES) experienceStart = Number(f_ES);
  if (f_EE) experienceEnd = Number(f_EE);
  if (f_O) isOpenToOtherOptions = f_O === 'true';
  if (f_SS) selectedSchoolIds = f_SS.split(',').map(i => Number(i));
  if (f_T) task_list = f_T.split(',');
  if (f_HT) hellosign_templates = f_HT.split(',');
  if (f_JSU) job_subject_list = f_JSU.split(',').map(i => Number(i));
  if (f_OL) onboarding_locations = f_OL.split(',').map(i => Number(i));
  if (f_TG) tag_ids = f_TG.split(',').map(i => Number(i));
  if (f_HA) hrbp_assignees = f_HA.split(',').map(i => Number(i));
  if (f_CSL) candidate_source_list = f_CSL.split(',');

  return {
    status_list,
    internal_list,
    cred_list,
    job_status_list,
    multilingual_school,
    title_1_school,
    turnaround_school,
    language_list,
    grades_list,
    preference_categories,
    employment_type_and_hours,
    experienceStart,
    experienceEnd,
    isOpenToOtherOptions,
    selectedSchoolIds,
    task_list,
    hellosign_templates,
    job_subject_list,
    onboarding_locations,
    tag_ids,
    hrbp_assignees,
    candidate_source_list,
  };
}

export function updateExperienceState(experienceStart, experienceEnd) {
  if (experienceStart === 0) {
    deleteQueryStringValue('experienceStart');
  } else {
    setQueryStringValue('experienceStart', experienceStart);
  }
  if (experienceEnd === 30) {
    deleteQueryStringValue('experienceEnd');
  } else {
    setQueryStringValue('experienceEnd', experienceEnd);
  }
  this.setState({ experienceStart, experienceEnd }, this.search);
}

export function selectAll(fieldName, options) {
  /** select all filter options and set in state. Currently used for candidateslist filters
   * (status, int/ext candidates, credential, job subject, tasks, job_status_list,
   *  multilingual school, title 1 school, turnaround school, tag_ids, hrbp_assignees)
   */
  const values = [];
  // status list + cred list matched by 'id', not 'value'
  const lookup = fieldName === 'status_list' ? 'id' : fieldName === 'cred_list' ? 'id' : 'value';
  options.forEach((o, index) => {
    values.push(o[lookup]);
  });

  // Because selecting all disables the filter,
  // clear the URL of any related search param
  deleteQueryStringValue(fieldName);

  this.setState({ [fieldName]: values }, this.search);
}

export function clearAll(fieldName) {
  /** similar to above, but clearing field to an empty array (essentially deselecting all
   * filter options) */

  // clear URL of any related search param
  deleteQueryStringValue(fieldName);
  this.setState({ [fieldName]: [] }, this.search);
}

export function resetFilters() {
  let statusArray = this.state.newApplicationStatuses;
  if (this.isSchoolUser) {
    statusArray = statusArray.filter(s => s.school_admin_view_access === true);
  }
  let status_list = statusArray.map(s => s.id);
  let job_subject_list = [];
  let tag_ids = [];
  let hrbp_assignees = [];
  let cred_list = [];
  this.state.categories.forEach(c => {
    c.subcategories.forEach(s => {
      job_subject_list.push(Number(s.id));
    });
  });
  let task_list = [];
  this.state.universal_tasks.forEach(t => {
    task_list.push(String(t.id));
    task_list.push(`-${t.id}`);
  });

  let hellosign_templates = [];
  this.state.visible_templates.forEach(({ template_id }) => {
    hellosign_templates.push(template_id);
    hellosign_templates.push(`-${template_id}`);
  });

  let language_list = [];
  // special filter array for languages
  languageList().forEach(l => {
    // if languageProficiency changes, make sure to change it here
    ['a', 'b', 'c', 'd', 'e'].forEach(i => {
      language_list.push(l.value + i);
    });
  });

  let candidate_source_list = [];
  candidate_source_list = this.state.sources.map(source => source.value);

  const schoolPreferenceFilters = {
    isOpenToOtherOptions: true,
    selectedSchoolIds: this.state.activeDistrictSchools.map(school => school.id),
  };

  this.setState(
    {
      days_ago: '',
      experienceStart: 0,
      experienceEnd: 30,
      query: '',
      status_list,
      cred_list,
      job_subject_list,
      tag_ids,
      hrbp_assignees,
      task_list,
      hellosign_templates,
      language_list,
      job_status_list: roleStatusesForSearchFilters().map(r => r.value),
      internal_list: internalList().map(i => i.value),
      multilingual_school: preferenceOptions().map(o => o.value),
      title_1_school: preferenceOptions().map(o => o.value),
      turnaround_school: preferenceOptions().map(o => o.value),
      grades: grades().map(g => g.value),
      preference_categories: preferenceCategories().map(c => c.value),
      employment_type_and_hours: employmentTypeAndHours().map(e => e.value),
      schoolPreferenceFilters,
      candidate_source_list,
    },
    this.search
  );

  const dr = getQueryStringValue('dr');
  if (dr) {
    // for school admins on the view by posting page, we keep a search param
    // "dr" to keep track of the district role id
    setQueryStringWithoutPageReload(`?dr=${dr}`);
  } else {
    setQueryStringWithoutPageReload('');
  }
}

/** when viewing a candidate in quick view or full profile, you can click to complete
 * a task (or uncomplete it).
 */
export function updateCandidateTask(task, method, onHomePage) {
  /**
   * @param {object} task: Candidate task. Corresponds to CandidateTask model
   * @param {string} method: either 'put' or 'delete'
   * @param {number} candidate_id
   * @param {boolean} onHomePage
   */

  let newStatus = Number(!task.status); // flips 1 to 0 or 0 to 1. Corresponds to enum.
  let newTask = Object.assign({}, task);
  newTask.status = newStatus;

  if (onHomePage) {
    // important note: at this time, homepage tasks can only be completed (not un-completed).
    // Once a task is complete, it disappears from the homepage. This is different behavior from
    // quick view and full profile tasks, which don't disappear when completed.
    let user = auth.getUser();
    // uses special serializer in this case
    newTask.on_home_page = true;

    newTask.completed_by = user.first_name + ' ' + user.last_name;
    newTask.completed_by_date = new Date();
    // if on homepage, tasks are stored directly in state
    let tasks = this.state.tasks;
    let taskIndex = tasks.findIndex(t => t.id === newTask.id);
    if (taskIndex !== -1) {
      tasks[taskIndex] = newTask;
      this.setState(
        {
          tasks,
        },
        () => {
          // wait 1 second for visual purposes, then remove completed task.
          setTimeout(() => {
            tasks.splice(taskIndex, 1);
            this.setState({
              tasks,
            });
          }, 1000);
        }
      );
    }
  } else {
    // if on full profile, update the user object directly
    let user = this.state.user;
    let taskIndex = user.tasks.findIndex(t => t.id === newTask.id);
    if (taskIndex !== -1) {
      if (method === 'put') {
        user.tasks[taskIndex] = newTask;
      } else {
        user.tasks.splice(taskIndex, 1);
        newTask.visible = false;
      }
      this.setState({
        user,
      });
    }
  }

  axios
    .put(`/api/candidate_task/${newTask.id}/`, newTask)
    .then(() => this.fetchEvents && this.fetchEvents())
    .catch(() =>
      showWarning(
        'There was a problem updating this task. Please email support@hirenimble.com for assistance.',
        20000
      )
    );
}
