/*
 * Let's follow this strategy to name our enums:
 * 1. System name as prefix: ATS, Connect
 * 2. Next: Page name, e.g.: Home, SignIn, JobsList
 * 3. Next: DataTestIds
 *
 * For each attribute name:
 * 1. Next: Element Identifier, e.g.: submit-, email-, password-
 * 2. Next: Element type, e.g.: button, input, text, link
 *
 * For the value, we must follow this rule:
 * data-testIds should follow the pattern area-noun-component (ex. connect-express-interest-primary-button)
 * More context here: https://nimble-ed.slack.com/archives/C06DZ7FF3RQ/p1721922036641099
 */

export enum ATSDistrictHomeDataTestIds {
  GREETING_TEXT = 'ats-home-greeting-text',
}

export enum ATSNavbarDataTestIds {
  JOBS_LINK = 'ats-navbar-jobs-link',
}

export enum ATSJobsListDataTestIds {
  COUNT_TEXT = 'ats-jobs-list-count-text',
  ROW_TITLE_TEXT = 'ats-jobs-list-row-title-text',
  SEARCH_INPUT = 'ats-jobs-list-search-input',
  CATEGORY_DROPDOWN = 'ats-jobs-list-categories-dropdown',
  CATEGORY_CLEARALL = 'ats-jobs-list-categories-clearall',
  CATEGORY_OPTION = 'ats-jobs-list-categories-option',
}

export enum ATSTemplatesListDataTestIds {
  SEARCH_INPUT = 'ats-templates-list-search-input',
}

export enum ATSJobViewDataTestIds {
  TITLE_TEXT = 'ats-job-view-title-text',
}

export enum ATSSignInDataTestIds {
  EMAIL_INPUT = 'ats-sign-in-email-input',
  PASSWORD_INPUT = 'ats-sign-in-password-input',
  SUBMIT_BUTTON = 'ats-sign-in-submit-button',
}

export enum ATSSignUpDataTestIds {
  ACTIVATE_BUTTON = 'ats-sign-up-activate-button',
  FIRST_NAME_INPUT = 'ats-sign-up-first-name-input',
  LAST_NAME_INPUT = 'ats-sign-up-last-name-input',
  EMAIL_INPUT = 'ats-sign-up-email-input',
  EMAIL_CONFIRM_INPUT = 'ats-sign-up-email-confirm-input',
  PASSWORD_INPUT = 'ats-sign-up-password-input',
  PASSWORD_CONFIRM_INPUT = 'ats-sign-up-password-confirm-input',
  SUBMIT_BUTTON = 'ats-sign-up-submit-button',
  ERROR_TEXT = 'ats-sign-up-error-text',
}

export enum ATSCandidatePreferencesDataTestIds {
  TITLE_TEXT = 'ats-candidate-preferences-title-text',
  COMPLETE_PROFILE_TEXT = 'ats-candidate-preferences-complete-profile-text',
}

export enum ATSJobClosureNotificationTestIds {
  ADD_NOTIFICATION_BUTTON = 'ats-job-closure-add-notification-button',
  EDIT_OR_CREATE_NOTIFICATION_BUTTON = 'ats-closure-modal-button',
  HEADING = 'ats-job-closure-heading',
  JOB_CLOSURE_SECTION = 'ats-job-closure-notifications-section',
  MODAL = 'ats-job-closure-modal',
  MODAL_PRIMARY_BUTTON = 'ats-job-closure-modal-primary-button',
  MODAL_TITLE = 'ats-job-closure-modal-title',
}
