import { Box, CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SchoolCard } from 'features/MarketplaceV2/components/SchoolCard';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { School } from 'types/marketplaceV2Types';
import { SchoolsListProps } from 'types/marketplaceV2Types';
import { Inter } from '../utils';
import { ActionTypes } from 'features/MarketplaceV2/utils/connectEnums';
import { NoResultsFoundCard } from './NoResultsFoundCard';
import { EndOfResultsCard } from './EndOfResultsCard';
import { GatedResultsCard } from './GatedResultsCard';

export const JobDiscoverySchoolsList: React.FC<SchoolsListProps> = ({
  schools,
  page,
  isLoading,
  isFetchingNextPage,
  isLoadingInitialFilterResults,
  setIsFetchingNextPage,
  dispatch,
  hasMore,
  actions,
  count,
  isUserAuthenticated,
}) => {
  const generateNearbySchoolsText = (schools: School[]) => {
    if (!schools) {
      return '';
    } else if (count === 1) {
      return 'Showing 1 nearby school that matches your criteria';
    } else {
      return `Showing ${count} nearby schools matching your criteria`;
    }
  };

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && hasMore && !isFetchingNextPage && isUserAuthenticated) {
      setIsFetchingNextPage(true);
      dispatch({ type: ActionTypes.SET_PAGE, payload: page + 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const schoolsToRender = isUserAuthenticated ? schools : schools.slice(0, 5);

  return (
    <SchoolListContainer width={'100%'}>
      <SchoolsFoundSubHeader>{generateNearbySchoolsText(schools)}</SchoolsFoundSubHeader>
      {!isLoadingInitialFilterResults && schools?.length ? (
        <>
          {schoolsToRender.map(school => (
            <SchoolCard key={school.nces_id} school={school} actions={actions} />
          ))}
          <div id="load-more-results-trigger" ref={ref} />
          {(isLoading || isFetchingNextPage) && (
            <div style={{ textAlign: 'center', paddingTop: 24 }}>
              <CircularProgress />
            </div>
          )}
          {!hasMore && <EndOfResultsCard />}
          {!isUserAuthenticated && (
            <GatedResultsCard count={count} numberOfSchoolsRendered={schoolsToRender.length} />
          )}
        </>
      ) : (
        <NoResultsFoundCard isLoading={isLoading} />
      )}
    </SchoolListContainer>
  );
};

const SchoolListContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 1,
  width: '100%',
  justifyItems: 'center',
  justifyContent: 'space-evenly',
}));

const SchoolsFoundSubHeader = styled(Typography)(() => ({
  fontFamily: Inter,
  fontSize: '18px',
  fontWeight: '600',
  fontStyle: 'normal',
  lineHeight: '28px',
  padding: '12px 0px 24px',
}));
