import PropTypes from 'prop-types';
import styled from 'styled-components';

import { VacancyFilterDropdown } from './VacancyFilterDropdown';

export default function VacanciesFilters({
  updateMultiSelectFilter,
  schoolsFilter,
  rolesFilter,
  subcategoriesFilter,
  schoolOptions,
  roleOptions,
  subcategoryOptions,
  hiringSeasonFilter,
  hiringSeasonOptions,
  onSelectAllChoices,
  onClearAllChoices,
}) {
  return (
    <div className="candidateslist-filters vacancies">
      <FilterContainer>
        <VacancyFilterDropdown
          label="Hiring Season"
          choices={hiringSeasonOptions}
          selectedChoiceIds={hiringSeasonFilter}
          fieldName="hiringSeasonFilter"
          onToggleChoice={updateMultiSelectFilter}
          onSelectAllChoices={onSelectAllChoices}
          onClearAllChoices={onClearAllChoices}
          height="58px"
        />
        <VacancyFilterDropdown
          label="Schools"
          choices={schoolOptions}
          selectedChoiceIds={schoolsFilter}
          fieldName="schoolsFilter"
          onToggleChoice={updateMultiSelectFilter}
          onSelectAllChoices={onSelectAllChoices}
          onClearAllChoices={onClearAllChoices}
          height="58px"
        />
        <VacancyFilterDropdown
          label="Job Postings"
          choices={roleOptions}
          selectedChoiceIds={rolesFilter}
          fieldName="rolesFilter"
          onToggleChoice={updateMultiSelectFilter}
          onSelectAllChoices={onSelectAllChoices}
          onClearAllChoices={onClearAllChoices}
          height="58px"
        />
        <VacancyFilterDropdown
          label="Categories"
          choices={subcategoryOptions}
          selectedChoiceIds={subcategoriesFilter}
          fieldName="subcategoriesFilter"
          onToggleChoice={updateMultiSelectFilter}
          onSelectAllChoices={onSelectAllChoices}
          onClearAllChoices={onClearAllChoices}
          height="58px"
        />
      </FilterContainer>
    </div>
  );
}

VacanciesFilters.propTypes = {
  updateMultiSelectFilter: PropTypes.func.isRequired,
  schoolsFilter: PropTypes.array,
  rolesFilter: PropTypes.array,
  subcategoriesFilter: PropTypes.array,
  schoolOptions: PropTypes.array,
  roleOptions: PropTypes.array,
  subcategoryOptions: PropTypes.array,
  hiringSeasonFilter: PropTypes.array,
  hiringSeasonOptions: PropTypes.array,
  onSelectAllChoices: PropTypes.func.isRequired,
  onClearAllChoices: PropTypes.func.isRequired,
};

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;

  & > * {
    flex: 1;
    margin-right: 1rem;
  }

  & > div:last-child {
    margin-right: 0;
  }
`;
