import styled from 'styled-components';
import lockIcon from 'assets/icons/lock.svg';

export default function LinkedQuickProfile({ show, toggleVisibility, fullUser }) {
  return (
    <div>
      <div className="quick-profile-fade" hidden={!show} onClick={toggleVisibility} />
      <div className="quick-profile-container">
        <div className="flex-1 quick-profile-inside">
          <CloseButton onClick={toggleVisibility}>×</CloseButton>
          <div className="default-card">
            <LinkedHeader>{fullUser.name}</LinkedHeader>
            <LinkedSubHeader>
              {fullUser.application
                ? fullUser.application.role.title
                : fullUser.schoolapplication.role.title}
            </LinkedSubHeader>

            <LinkedMessage>
              <img alt="lock-icon" src={lockIcon} />
              Looks like you don’t have access to view this profile
            </LinkedMessage>
          </div>
        </div>
      </div>
    </div>
  );
}

const CloseButton = styled.span`
  position: absolute;
  top: 22px;
  right: 28px;
  font-size: 28px
  font-weight: bold;
  cursor: pointer;
  color: #A3A1B3 !important;

  @media screen and (max-width: 768px) {
    top: 10px;
    right: 8px;
    font-size: 24px;
  }
`;

const LinkedHeader = styled.p`
  font-size: 25px;
  font-weight: 600;
  line-height: 34px;
  color: #444444;

  margin-right: 1rem;
`;

const LinkedSubHeader = styled.p`
  color: #777777;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;

  margin-top: 4px;
`;

const LinkedMessage = styled.div`
  background-color: ${props => props.theme.uiColors.greens.full};
  color: ${props => props.theme.uiColors.white};
  padding: 1rem;
  border-radius: 3px;
  margin-top: 100px;

  > img {
    margin-right: 1rem;
    vertical-align: bottom;
  }
`;
