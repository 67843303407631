import HelloSign from 'hellosign-embedded';
import { isProductionEnvironment, isStagingEnvironment, isPreviewEnvironment } from './util';

export function makeHSClient() {
  // Local client ID
  let clientId = { clientId: 'a6bdbd713b133374fc16638b97043254' };
  // Stage
  if (isStagingEnvironment()) {
    clientId['clientId'] = '8625237c1017d9f0c22f78447a7784fe';
  }
  if (isPreviewEnvironment()) {
    clientId['clientId'] = '31f9c2c62219605aa917324a49b58c50';
  }
  // Prod
  if (isProductionEnvironment()) {
    clientId['clientId'] = '9de75ca68170cfe609a688dd59cd9ad1';
  }
  return new HelloSign(clientId);
}

export function openHSiFrame(client, url, onDelete, onNext) {
  client.once('cancel', () => {
    // Delete the template in our system if they cancel when making a new one
    if (onDelete) {
      onDelete();
    }
  });

  client.once('finish', onNext);

  client.open(url, {
    testMode: isProductionEnvironment() ? false : true,
    skip_signer_roles: 1,
    container: document.getElementById('hellosign-iframe-entry'),
    whiteLabeling: {
      primary_button_color: '#00b88d'
    }
  });
}
