import { visibleTo } from '../../utils/enums';

export const candidateVariables = [
  'Candidate first name',
  'Candidate last name',
  'Candidate phone',
  'Job title',
];

export const defaultErrorState = {
  name: false,
  subject: false,
  content: false,
};

export const getSortedTemplates = unsortedTemplates => {
  const templatesToReturn = [...unsortedTemplates];

  const applicationConfirmationIndex = templatesToReturn.findIndex(
    template => template.type === 'Application confirmation'
  );

  const indexHasBeenFound = applicationConfirmationIndex !== -1;

  if (indexHasBeenFound) {
    const itemToMove = templatesToReturn?.splice(applicationConfirmationIndex, 1);
    templatesToReturn.unshift(itemToMove);
  }

  return templatesToReturn.flat();
};

export const senderVariables = [
  'Sender first name',
  'Sender last name',
  'School name',
  'District name',
];

export const referenceVariables = [
  'Reference salutation',
  'Reference first name',
  'Reference last name',
  'Reference email',
  'Reference phone',
];

export const VISIBLE_TO = visibleTo().reduce((obj, item) => {
  obj[item.key] = item.label;
  return obj;
}, {});

export const VISIBLE_TO_VALUE = visibleTo().reduce((obj, item) => {
  /** mapping between value and key for visibleTo enum, as opposed to key and label like above */
  obj[item.value] = item.key;
  return obj;
}, {});

export const USER_VISIBILITY = visibleTo().reduce((obj, item) => {
  obj[item.key] = item.value;
  return obj;
}, {});
