import { Component } from 'react';
import PropTypes from 'prop-types';

import checkmark from '../../assets/icons/icon-white-checkmark.svg';
import loadingSwirl from '../../assets/icons/Ellipse.png';

export default class ApplicationProgressBar extends Component {
  static propTypes = {
    step: PropTypes.number.isRequired,
    editProfileMode: PropTypes.bool,
    skipDocumentationPage: PropTypes.bool,
  };

  createClassname = (type, num) => {
    // Color the element background green if completed
    let green = num <= this.props.step ? 'backgroundgreen' : '';
    return type === 'circle' ? `check-circle ${green}` : `line-section ${green}`;
  };

  createInnerImage = num => {
    if (num === this.props.step) {
      return <img src={loadingSwirl} alt="swirly" className="swirly" />;
    } else if (num < this.props.step) {
      return <img src={checkmark} alt="checkmark" />;
    }
  };

  render() {
    let addClasses = this.props.editProfileMode ? 'edit-profile-mode' : '';
    addClasses += this.props.skipDocumentationPage ? 'skip-documentation-page' : '';

    return (
      <div className={`application-progress-bar-container flex mt2 ${addClasses}`}>
        <div className="application-progress-bar flex">
          <div className={this.createClassname('circle', 1)}>{this.createInnerImage(1)}</div>
          <div className={this.createClassname('', 2)} />
          <div className={this.createClassname('circle', 2)}>{this.createInnerImage(2)}</div>
          <div className={this.createClassname('', 3)} />
          <div className={this.createClassname('circle', 3)}>{this.createInnerImage(3)}</div>
          <div className={this.createClassname('', 4)} />
          <div className={this.createClassname('circle', 4)}>{this.createInnerImage(4)}</div>
        </div>
        <div className="application-progress-bar-text flex mt1">
          <div>BASIC INFO</div>
          <div>EXPERIENCE</div>
          <div>DOCUMENTATION</div>
          <div>REVIEW & SUBMIT</div>
        </div>
      </div>
    );
  }
}
