import styled from 'styled-components';

export default function MultipleChoiceViewAnswer({ answer }) {
  return (
    <div>
      {answer.multi_select && (
        <div style={{ fontSize: '12px', marginTop: '4px' }}>Select as many as apply.</div>
      )}
      <GridContainer>
        {answer.mc_options.map((option, i) => (
          <Option key={i}>
            <input
              type="checkbox"
              name="multiple_choice"
              value={option}
              checked={answer.mc_answer?.includes(option) ?? false}
              readOnly
            />
            &nbsp;&nbsp;{option}
          </Option>
        ))}
      </GridContainer>
    </div>
  );
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 3px;
`;

const Option = styled.label`
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;

  color: #777777;
`;
