import ReactQuill from 'react-quill';
import { Body } from 'ui-kit/Modal';
import { DirectionTextIcon } from 'ui-kit/icons';
import styled from 'styled-components';

import { GreyText } from '../styledComponents';
import CheckboxContainerVisibilityOnly from './CheckboxContainerVisibilityOnly';

export default function TextQuestion({
  directions,
  setDirections,
  internalRequirementsSpecified,
  handleItemMatrixChange,
  itemMatrix,
}) {
  return (
    <Body>
      <HeaderContainer>
        <DirectionTextIcon style={{ alignSelf: 'center', width: '36px', height: '36px' }} />
        <VerticalFlexContainer>
          <Header>Direction Text</Header>
          <GreyText>
            Gives the candidate clear directions on how to fill out this job application
          </GreyText>
        </VerticalFlexContainer>
        <EndItem>
          {internalRequirementsSpecified && (
            <CheckboxContainerVisibilityOnly
              itemMatrix={itemMatrix}
              handleItemMatrixChange={handleItemMatrixChange}
            />
          )}
        </EndItem>
      </HeaderContainer>
      <>
        <Header>Type directions here:</Header>
        <ReactQuillContainer>
          <ReactQuillStyled
            value={directions || ''}
            onChange={value => {
              setDirections(value);
            }}
            placeholder="e.g. Complete and submit this form within 3 days of your interview"
          />
        </ReactQuillContainer>
      </>
    </Body>
  );
}

const Header = styled.p`
  font-size: 16px;
  color: #444444;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 6px;
`;

const ReactQuillContainer = styled.div`
  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    position: absolute;
    right: 0;
    width: 18px;
    height: 18px;
  }
`;

const ReactQuillStyled = styled(ReactQuill)`
  border: 0.5px solid #d7d7d7;
  border-radius: 3px;

  > div.ql-toolbar.ql-snow {
    border-radius: 3px 3px 0 0;
  }

  > div.ql-container.ql-snow {
    border-radius: 0 0 3px 3px;

    > .ql-editor.ql-blank::before {
      color: rgba(57, 60, 73, 0.3);
    }
  }
`;

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 54px 1fr 100px;

  margin-bottom: 2rem;
`;

const VerticalFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const EndItem = styled.div`
  justify-self: end;
`;
