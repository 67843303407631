import { ButtonProps } from './types';
import { Button } from '@mui/material';
import { CircularProgress } from '@mui/material';

export const PrimaryButton: React.FC<ButtonProps> = ({
  children,
  disabled,
  isLoading,
  onClick,
  size,
  startIcon,
  endIcon,
  dataTestId,
  loadingSpinnerColor = 'primary',
  sx,
}: ButtonProps) => {
  return (
    <Button
      data-testid={dataTestId || 'primary-button'}
      disabled={disabled}
      onClick={onClick}
      size={size}
      variant="contained"
      startIcon={!isLoading && startIcon}
      endIcon={!isLoading && endIcon}
      sx={sx}
    >
      {isLoading ? <CircularProgress color={loadingSpinnerColor} /> : children}
    </Button>
  );
};
