import { useState, StrictMode } from 'react';
import styled, { css } from 'styled-components';
import ReactTooltip from 'react-tooltip';

import auth from 'utils/auth';
import ApplicantDiversityWidget from './ApplicantDiversityWidget';
import ApplicationsPerVacancyWidget from './ApplicationsPerVacancyWidget';
import ApplicantVolumeWidget from './ApplicantVolumeWidget';
import ApplicantSourceWidget from './ApplicantSourceWidget';
import HiringConversionMetricsWidget from './HiringConversionMetricsWidget';
import OnboardingConversionMetricsWidget from './OnboardingConversionMetricsWidget';
import { ChoiceDropdown, useChoiceDropdown } from './components/ChoiceDropdown';
import { currentHiringSeason } from 'utils/enums';
import { HIRING_SEASON_CHOICES } from './constants';
import { DownloadIconGrey } from 'ui-kit/icons';
import { AlertDialog } from 'ui-kit';
import dashboardMetricsAPI from 'api/dashboardMetricsAPI';

const PipelineMetricsDashboard = ({ activeSchools, categories, applicationStatuses }) => {
  const schoolDropdownChoices = activeSchools;
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  let categoryDropdownChoices = [
    { id: 'N/A', value: 'N/A', label: 'No Category', isGroupHeader: true },
  ];
  categories.forEach(category => {
    categoryDropdownChoices.push({
      id: `category-${category.id}`,
      value: null,
      label: category.label,
      group: category.id,
      isGroupHeader: true,
    });
    category.subcategories.forEach(subcategory => {
      categoryDropdownChoices.push({
        id: `subcategory-${subcategory.id}`,
        value: subcategory.id,
        label: subcategory.label,
        group: category.id,
        isGroupHeader: false,
      });
    });
  });

  const hiringSeasonDropdownState = useChoiceDropdown({
    choices: HIRING_SEASON_CHOICES,
    initialSelectedChoiceIds: [currentHiringSeason()],
  });
  const schoolsDropdownState = useChoiceDropdown({
    choices: schoolDropdownChoices,
    initialSelectedChoiceIds: schoolDropdownChoices.map(({ id }) => id),
  });
  const categoriesDropdownState = useChoiceDropdown({
    choices: categoryDropdownChoices,
    initialSelectedChoiceIds: categoryDropdownChoices.map(({ id }) => id),
  });

  const sortedSelectedHiringSeasons = hiringSeasonDropdownState.selectedChoiceIds.sort(
    (a, b) => a - b
  );

  const selectedSubcategoryIds = categoryDropdownChoices
    .filter(choice => choice.value != null)
    .filter(choice => categoriesDropdownState.selectedChoiceIds.includes(choice.id))
    .map(({ value }) => value);

  return (
    <StrictMode>
      <DashboardContainer>
        {auth.isSuperAdminUser() && (
          <DownloadIconContainer>
            <DownloadIconGrey
              data-tip
              data-for="download-dashboard"
              data-place="top"
              data-delay-show="100"
              onClick={() => setIsAlertOpen(true)}
            />
            <ReactTooltip id="download-dashboard" effect="solid">
              <span style={{ color: 'white' }}>Download raw data</span>
            </ReactTooltip>
          </DownloadIconContainer>
        )}
      </DashboardContainer>
      <DashboardContainer>
        <DashboardFilters>
          <ChoiceDropdown
            label="Hiring Season"
            choices={HIRING_SEASON_CHOICES}
            {...hiringSeasonDropdownState}
            height="58px"
          />
          <ChoiceDropdown
            label="Schools"
            choices={schoolDropdownChoices}
            {...schoolsDropdownState}
            height="58px"
          />
          <ChoiceDropdown
            label="Job Categories"
            choices={categoryDropdownChoices}
            {...categoriesDropdownState}
            height="58px"
          />
        </DashboardFilters>
        <DashboardWidgets>
          <DashboardItem>
            <ApplicantDiversityWidget
              hiringSeasons={sortedSelectedHiringSeasons}
              schools={schoolsDropdownState.selectedChoiceIds}
              subcategories={selectedSubcategoryIds}
              applicationStatuses={applicationStatuses}
            />
          </DashboardItem>
          <DashboardItem>
            <ApplicantSourceWidget
              hiringSeasons={sortedSelectedHiringSeasons}
              schools={schoolsDropdownState.selectedChoiceIds}
              subcategories={selectedSubcategoryIds}
              applicationStatuses={applicationStatuses}
            />
          </DashboardItem>
          <DashboardItem fullWidth>
            <ApplicantVolumeWidget
              originalHiringSeasons={sortedSelectedHiringSeasons}
              schools={schoolsDropdownState.selectedChoiceIds}
              subcategories={selectedSubcategoryIds}
            />
          </DashboardItem>
          <DashboardItem fullWidth>
            <ApplicationsPerVacancyWidget
              hiringSeasons={sortedSelectedHiringSeasons}
              schools={schoolsDropdownState.selectedChoiceIds}
              subcategories={selectedSubcategoryIds}
            />
          </DashboardItem>
          <DashboardItem fullWidth>
            <HiringConversionMetricsWidget
              hiringSeasons={sortedSelectedHiringSeasons}
              schools={schoolsDropdownState.selectedChoiceIds}
              subcategories={selectedSubcategoryIds}
              applicationStatuses={applicationStatuses}
            />
          </DashboardItem>
          {/* Restrict access to districts that have purchased onboarding module */}
          {auth.hasOnboardingModule() && (
            <DashboardItem fullWidth>
              <OnboardingConversionMetricsWidget
                hiringSeasons={sortedSelectedHiringSeasons}
                schools={schoolsDropdownState.selectedChoiceIds}
                subcategories={selectedSubcategoryIds}
                applicationStatuses={applicationStatuses}
              />
            </DashboardItem>
          )}
        </DashboardWidgets>
      </DashboardContainer>
      <AlertDialog
        isOpen={isAlertOpen}
        onClose={() => {
          setIsAlertOpen(!isAlertOpen);
          dashboardMetricsAPI.downloadPipelineDashboardData();
        }}
        text="This may take a few minutes. You will receive an email when your export is complete."
        buttonText="Got it!"
        title="Data Download"
      />
    </StrictMode>
  );
};

export default PipelineMetricsDashboard;

const DashboardContainer = styled.main`
  max-width: 1160px;
  margin: 20px auto 40px;
  padding: 0 30px;

  font-size: 15px;

  // Reduce side padding for small screens
  @media screen and (max-width: 768px) {
    padding: 0 10px;
  }
`;

const DashboardFilters = styled.div`
  margin-top: 2em;
  margin-bottom: 2em;

  display: flex;
  justify-content: space-between;

  & > * {
    flex: 1;
  }

  & > *:not(:last-child) {
    margin-right: 1.5em;
  }

  // Collapse dropdowns into a column for small screens.
  @media screen and (max-width: 768px) {
    flex-direction: column;

    & > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 1em;
    }
  }
`;

const DashboardWidgets = styled.div`
  // 2 column layout for desktops
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 375px 375px minmax(375px, auto) 325px;
  grid-column-gap: 2em;
  grid-row-gap: 2em;

  // Single column for small screens or printing
  @media print, @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 375px 375px 375px minmax(375px, auto) 325px;
  }
`;

const DashboardItem = styled.div`
  display: flex;
  flex-direction: column;

  ${props =>
    props.fullWidth
      ? css`
          grid-column: 1/-1;
        `
      : ''};
`;

const DownloadIconContainer = styled.div`
  position: relative;
  height: 20px;

  & > svg {
    cursor: pointer;
    position: absolute;
    bottom: -10px;
    right: 0;
  }
`;
