import { Box, Button, styled } from '@mui/material';

export interface ActivityFeedContainerProps {
  shouldScroll: boolean;
}

export const ActivityFeedContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'shouldScroll',
})<ActivityFeedContainerProps>(({ shouldScroll }) => ({
  border: '1px solid #EAECF0',
  borderRadius: '16px',
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  maxHeight: '400px',
  overflowY: shouldScroll ? 'scroll' : 'inherit',
  scrollbarColor: 'transparent',
  padding: '24px',
}));

export const Author = styled(Box)({
  color: '#344054',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
});

export const AuthorDate = styled(Box)({
  display: 'flex',
});

export const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
});

export const Change = styled(Box)({
  color: '#475467',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '20px',
});

export const Container = styled(Box)({
  borderBottom: '1px solid #EAECF0',
  display: 'flex',
  padding: '16px 0',
  '&:first-of-type': {
    paddingTop: 0,
  },
  '&:nth-last-of-type(2)': {
    paddingBottom: 0,
    border: 'none',
  },
});

export const Date = styled(Box)({
  color: '#475467',
  fontSize: '12px',
  paddingLeft: '8px',
});

export const DateAndTime = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'right',
});

export const HeaderContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: '12px',
});

export const Left = styled(Box)({
  paddingRight: '12px',
});

export const LoadingBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
});

export const Right = styled(Box)({
  position: 'relative',
  top: '-5px',
});

export const Time = styled(Box)({
  fontSize: '12px',
});

export const ViewMoreButton = styled(Button)({
  border: '1px solid #D0D5DD',
  borderRadius: '8px',
  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  color: '#344054',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '20px',
  marginTop: '24px',
  padding: '10px 16px',
  width: '223px',
});
