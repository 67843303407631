import { Component } from 'react';

import PropTypes from 'prop-types';
import DeleteModal from '../JobEdit/DeleteModal';
import EditCategoryModal from './EditCategoryModal';
import auth from '../../utils/auth';
import { CaretIconButton } from 'features/Settings/SchoolGroups';
import { ViewButton } from 'sharedComponents/Buttons';
import { SettingsRow } from 'components/SettingsRow';
import { EditButton } from 'sharedComponents/Buttons/EditButton';
import { DeleteButton } from 'sharedComponents/Buttons/DeleteButton';
import { Box, styled } from '@mui/material';

export default class CategoriesRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editModalOpen: false,
      deleteModalOpen: false,
    };
  }
  static propTypes = {
    category: PropTypes.object.isRequired,
    categoryCount: PropTypes.number.isRequired,
    expanded: PropTypes.bool.isRequired,
    toggleExpand: PropTypes.func.isRequired,
    editCategory: PropTypes.func.isRequired,
    deleteCategory: PropTypes.func.isRequired,
  };

  openModal = e => {
    e.stopPropagation();
    this.setState({ editModalOpen: true });
  };

  openDeleteModal = e => {
    e.stopPropagation();
    this.setState({ deleteModalOpen: true });
  };

  deleteCategory = () => {
    this.props.toggleExpand();
    this.props.deleteCategory(this.props.category.id, null);
    this.closeDeleteModal();
  };

  closeDeleteModal = () => {
    this.setState({ deleteModalOpen: false });
  };

  render() {
    const categoriesCount = this.props.categoryCount;
    const subcategoriesCount = this.props.category.subcategories.length;

    return (
      <>
        <SettingsRow
          onClick={this.props.toggleExpand}
          cta={
            <CaretIconButton isActive={this.props.expanded}>
              <ViewButton />
            </CaretIconButton>
          }
        >
          <TextContainer>
            <span className="bold mr2" style={{ flex: 1 }}>
              {this.props.category.label}
            </span>
            {!auth.getUser().profile.district.indiana_district && this.props.expanded && (
              <span className={`category-row-edit ${this.props.expanded ? 'expanded' : ''}`}>
                <EditButton onClick={this.openModal} data-testid="category-edit-icon" />
                <DeleteButton onClick={this.openDeleteModal} />
              </span>
            )}

            <span style={{ marginRight: '80px' }}>
              {`${subcategoriesCount} ${
                subcategoriesCount === 1 ? 'subcategory' : 'subcategories'
              }`}
            </span>
          </TextContainer>
        </SettingsRow>

        {this.state.editModalOpen && (
          <EditCategoryModal
            label={this.props.category.label}
            entityName={'Category'}
            show={this.state.editModalOpen}
            onSave={label => this.props.editCategory(this.props.category.id, null, label)}
            onDelete={() => this.props.deleteCategory(this.props.category.id, null)}
            onHide={() => this.setState({ editModalOpen: false })}
          />
        )}
        {this.state.deleteModalOpen &&
          (categoriesCount > 1 ? (
            <DeleteModal
              show={this.state.deleteModalOpen}
              onHide={this.closeDeleteModal}
              onSave={this.deleteCategory}
              title={'Are you sure?'}
              message={'This will also delete its related subcategories.'}
              cancelLabel={'Cancel'}
              submitLabel={'Delete'}
            />
          ) : (
            <DeleteModal
              show={this.state.deleteModalOpen}
              onHide={this.closeDeleteModal}
              onSave={this.closeDeleteModal}
              title="Cannot delete last subcategory"
              message="Please note, you must have at least one subcategory. These help candidates find the jobs they’re looking for and help you filter and analyze your hiring data."
              submitLabel="Got it!"
            />
          ))}
      </>
    );
  }
}

const TextContainer = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});
