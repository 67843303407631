import Avatar from '@mui/material/Avatar';
import Hidden from '@mui/material/Hidden';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material';
import { useMediaQuery } from '@mui/material';

import { SchoolCardProps } from '../types';
import { Inter } from 'features/MarketplaceV2/features/JobDiscovery/utils';
import { ExpressInterestButton } from '../Buttons/ExpressInterestButton';
import { moreInfoOnClickHandler } from 'features/MarketplaceV2/features/ExpressInterest/utils';
import { SchoolInfoHighlightRow } from '../HighlightRows/HighlightRowSchoolInfo';
import { OnPlatformBadge } from '../InfoBadges/OnPlatformBadge';
import { RecommendedBadge } from '../InfoBadges/RecommendedBadge';
import { renderSecondarySchoolTags } from 'features/MarketplaceV2/features/JobDiscovery/utils';

export const BasicInfoSection: React.FC<SchoolCardProps> = ({
  school,
  isInterestModal,
  actions,
}) => {
  const hasPrincipal = !!school?.principal;
  const principalInitials = [school?.principal?.first_name[0], school?.principal?.last_name[0]];
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          alignItems: 'center',
          width: '100%',
          marginBottom: isInterestModal ? '0px' : isMobile ? '8px' : '16px',
        }}
      >
        {
          //leaving this line in for later logic when a principal has a profile photo
          /* <Avatar alt="principal avatar" src="" /> */
        }{' '}
        {hasPrincipal && (
          <Hidden smDown>
            <PrincipalContainer
              href="#"
              onClick={() => moreInfoOnClickHandler(school, actions, '0')}
              gap={1}
              sx={{
                alignItems: 'center',
                width: '100%',
                marginBottom: isInterestModal ? '0px' : '16px',
              }}
            >
              <Avatar
                sx={{
                  width: '32px',
                  height: '32px',
                  borderRadius: '200px',
                  background: '#E5ECFF',
                  color: '#475467',
                  fontFamily: Inter,
                  fontSize: '14px',
                  fontWeight: '600',
                }}
              >
                {principalInitials}
              </Avatar>
              <Typography sx={{ fontFamily: Inter, fontSize: '16px' }}>
                {`Principal ${school?.principal?.first_name} ${school?.principal?.last_name}`}
              </Typography>
            </PrincipalContainer>
          </Hidden>
        )}
        <Hidden smUp>
          <Stack direction={'row'} spacing={'8px'} gap={'4px'}>
            {!isInterestModal && <ExpressInterestButton school={school} actions={actions} />}

            {isInterestModal && hasPrincipal && (
              <Avatar
                sx={{
                  width: '32px',
                  height: '32px',
                  borderRadius: '200px',
                  background: '#E5ECFF',
                  color: '#475467',
                  fontFamily: Inter,
                  fontSize: '14px',
                  fontWeight: '600',
                }}
              >
                {principalInitials}
              </Avatar>
            )}
            {hasPrincipal && (
              <Typography
                sx={{
                  fontFamily: Inter,
                  fontSize: '16px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: 180,
                }}
                alignSelf={'center'}
              >
                {`${school?.principal?.first_name} ${school?.principal?.last_name}`}
              </Typography>
            )}
          </Stack>
        </Hidden>
      </Stack>
      <Hidden smUp>
        <div style={{ marginBottom: '6px' }}>{school.is_recommended && <RecommendedBadge />}</div>
      </Hidden>
      <Hidden smUp>
        <div style={{ marginBottom: '6px' }}>
          {school?.principal?.on_connect && <OnPlatformBadge />}
        </div>
      </Hidden>
      <Hidden smUp>{renderSecondarySchoolTags(school)}</Hidden>
      <SchoolInfoHighlightRow school={school} isInterestModal={isInterestModal} />
    </>
  );
};

const PrincipalContainer = styled(Link)(() => ({
  display: 'flex',
  flexDirection: 'row',
  color: '#475467',
  alignItems: 'center',
  textDecoration: 'none',
}));
