import { SxProps } from '@mui/material';
import { Alert as AlertMUI } from '@mui/material';
import { AlertInterface } from './types';

export const Alert: React.FC<AlertInterface> = ({ children, type='info', variant='filled', sx }) => {
  const customStyles: SxProps = {
    borderRadius: '8px',
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 600,
    ...sx,
  };

  if (type === 'info') {
    customStyles['backgroundColor'] = '#EDF2FF';
    customStyles['color'] = '#1F3D99';
  }

  return (
    <AlertMUI variant={variant} severity={type} sx={customStyles}>
      {children}
    </AlertMUI>
  );
};
