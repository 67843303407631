import { isEmpty } from 'lodash';
import { Component, ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react';
import ReactQuill, { QuillOptions } from 'react-quill';
import PropTypes from 'prop-types';
import { handleVariableClick } from 'utils/emailutils';

class Editor extends Component {
  static propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    additionalModules: PropTypes.object,
    ref: PropTypes.func,
    name: PropTypes.string,
    type: PropTypes.string,
  };

  constructor(props) {
    super(props);
  }

  COLORS = [
    '#000000',
    '#e60000',
    '#ff9900',
    '#ffff00',
    '#008a00',
    '#0066cc',
    '#9933ff',
    '#ffffff',
    '#facccc',
    '#ffebcc',
    '#ffffcc',
    '#cce8cc',
    '#cce0f5',
    '#ebd6ff',
    '#bbbbbb',
    '#f06666',
    '#ffc266',
    '#ffff66',
    '#66b966',
    '#66a3e0',
    '#c285ff',
    '#888888',
    '#a10000',
    '#b26b00',
    '#b2b200',
    '#006100',
    '#0047b2',
    '#6b24b2',
    '#444444',
    '#5c0000',
    '#663d00',
    '#666600',
    '#003700',
    '#002966',
    '#3d1466',
  ];

  modules = () => {
    let moduleOptions = {
      toolbar: [
        [{ header: [1, 2, 3, 4, false] }], // headings
        ['bold', 'italic', 'underline', 'link'], // toggled buttons
        [{ list: 'ordered' }, { list: 'bullet' }], // lists
        [{ script: 'sub' }, { script: 'super' }], // subscript, superscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent, indent
        [{ color: this.COLORS }, { background: this.COLORS }], // text color and text background color
        ['clean'], // remove formatting button
      ],
    };

    if (!isEmpty(this.props.additionalModules) && this.props.additionalModules.mention) {
      moduleOptions = {
        ...moduleOptions,
        ...this.props.additionalModules,
      };
    }

    return moduleOptions;
  };

  render() {
    return (
      <div>
        <ReactQuill
          ref={el => {
            this.quillRef = el;
          }}
          modules={this.modules()}
          value={this.props.defaultValue || this.props.value || ''}
          onChange={this.props.onChange}
          placeholder={this.props.placeholder}
          id={this.props.id || ''}
          name={this.props.name || ''}
          type={this.props.type || ''}
        />
      </div>
    );
  }
}

export default Editor;
