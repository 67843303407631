import styled from 'styled-components';
import PropTypes from 'prop-types';

import { scorecardQType } from 'utils/enums';

import TextAnswer from '../TextAnswer';
import MultipleChoiceAnswer from '../MultipleChoiceAnswer';
import RubricAnswer from '../RubricAnswer';
import CumulativeScoreAnswer from '../CumulativeScoreAnswer';
import FinalRecommendationAnswer from '../FinalRecommendationAnswer';
import DirectionsAnswer from '../DirectionsAnswer';
import RatingAnswer from '../RatingAnswer';
import AttachmentAnswer from '../AttachmentAnswer';

export default function ReferenceEditorQuestions({
  referenceCheck,
  updateField,
  updateFinalRecommendation,
  updateMultipleChoiceField,
  updateRating,
  updateRubricSelection,
  adminUsers,
  uploadAttachment,
  removeAttachment,
}) {
  const renderQuestion = answer => {
    if (
      answer.question_type === scorecardQType.rubric ||
      answer.question_type === scorecardQType.direction_text
    ) {
      // rubric and direction answers have their own question formats
      return;
    }
    let question = answer.question_text;
    if (answer.question_type === scorecardQType.cumulative_score) {
      question = 'Cumulative score:';
    }
    if (answer.question_type === scorecardQType.final_recommendation) {
      question = 'Do you recommend this candidate?';
    }

    return <QuestionText>{`${question} ${answer.is_required ? '*' : ''}`}</QuestionText>;
  };

  const renderAnswerSection = (answer, index) => {
    switch (answer.question_type) {
      case scorecardQType.rating:
        return <RatingAnswer answer={answer} index={index} updateRating={updateRating} />;
      case scorecardQType.nimble:
      case scorecardQType.text:
        return (
          <TextAnswer
            adminUsers={adminUsers}
            answer={answer}
            updateField={updateField}
            index={index}
          />
        );
      case scorecardQType.multiple_choice:
        return (
          <MultipleChoiceAnswer
            answer={answer}
            updateMultipleChoiceField={updateMultipleChoiceField}
            index={index}
          />
        );
      case scorecardQType.cumulative_score:
        return <CumulativeScoreAnswer parentObject={referenceCheck} />;
      case scorecardQType.direction_text:
        return <DirectionsAnswer answer={answer} />;
      case scorecardQType.final_recommendation:
        return (
          <FinalRecommendationAnswer
            answer={answer}
            parentObject={referenceCheck}
            updateFinalRecommendation={updateFinalRecommendation}
          />
        );
      case scorecardQType.rubric:
        return (
          <RubricAnswer
            answer={answer}
            updateRubricSelection={updateRubricSelection}
            index={index}
          />
        );
      case scorecardQType.attachment:
        return (
          <AttachmentAnswer
            answer={answer}
            uploadAttachment={uploadAttachment}
            removeAttachment={removeAttachment}
            index={index}
          />
        );
      default:
        return <div />;
    }
  };

  return (
    <>
      {referenceCheck.answers.map((answer, index) => (
        <AnswerItem key={index}>
          {renderQuestion(answer)}
          {renderAnswerSection(answer, index)}
        </AnswerItem>
      ))}
    </>
  );
}

ReferenceEditorQuestions.propTypes = {
  referenceCheck: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
  updateFinalRecommendation: PropTypes.func.isRequired,
  updateMultipleChoiceField: PropTypes.func.isRequired,
  updateRating: PropTypes.func.isRequired,
  updateRubricSelection: PropTypes.func.isRequired,
  // adminUsers not sent down through submit reference flow
  adminUsers: PropTypes.array,
  uploadAttachment: PropTypes.func.isRequired,
  removeAttachment: PropTypes.func.isRequired,
};

const QuestionText = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;

  color: #444444;
`;

const AnswerItem = styled.div`
  margin-bottom: 50px;
`;
