import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, SxProps } from '@mui/material';

export interface DeleteButtonProps {
  onClick: () => void;
  sx?: SxProps;
}

export const DeleteButton: React.FC<DeleteButtonProps> = ({ onClick, sx }) => {
  return (
    <IconButton onClick={onClick} sx={sx}>
      <DeleteIcon />
    </IconButton>
  );
};
