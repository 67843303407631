import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import EditReferenceQuestionModal from './EditReferenceQuestionModal';

import { ReactComponent as EditIcon } from '../../assets/icons/AccountManagementIcons/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/icon_trash.svg';

import { scorecardQuestionMapping } from '../../utils/enums';

export default function ReferenceQuestionRow({
  id,
  question,
  saveQuestion,
  questionBank,
  deleteQuestion,
}) {
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const getQuestionDetails = () => {
    let str = '';
    if (question.is_required) {
      str += 'required, ';
    }
    if (question.show_answer_on_preview || question.show_total_on_preview) {
      str += 'visible on preview, ';
    }
    if (str) {
      str = str.slice(0, str.length - 2);
      return ` (${str})`;
    }
  };

  const closeModalAndSaveQuestion = question => {
    setShowQuestionModal(false);
    saveQuestion(question);
  };

  const Icon = scorecardQuestionMapping[question.question_type].icon;
  return (
    <>
      <QuestionContainer>
        <QuestionType>
          <QuestionTypeIconContainer>
            <Icon />
          </QuestionTypeIconContainer>
          <p>
            {scorecardQuestionMapping[question.question_type].label}
            {getQuestionDetails()}
          </p>
        </QuestionType>
        <DeleteButton onClick={() => deleteQuestion(id)} />
        <QuestionText>
          {`${question.question_text}${question.scale ? ` (1 - ${question.scale})` : ''}`}
        </QuestionText>
        <EditButton onClick={() => setShowQuestionModal(true)} />
      </QuestionContainer>
      {showQuestionModal && (
        <EditReferenceQuestionModal
          show={showQuestionModal}
          onHide={() => setShowQuestionModal(false)}
          saveQuestion={closeModalAndSaveQuestion}
          question={question}
          questionBank={questionBank}
        />
      )}
    </>
  );
}

ReferenceQuestionRow.propTypes = {
  id: PropTypes.number.isRequired,
  question: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
  saveQuestion: PropTypes.func.isRequired,
  deleteQuestion: PropTypes.func.isRequired,
};

const QuestionContainer = styled.div`
  display: grid;
  grid-template-columns: 6fr 1fr;
  grid-template-areas:
    'question-type   delete-button'
    'question-text   edit-button';
  grid-column-gap: 8px;
  grid-row-gap: 12px;

  border-radius: 3px;
  box-shadow: var(--box-shadow);
  background-color: white;
  padding: 21px;

  &:hover {
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);
  }

  @media screen and (min-width: 768px) {
    @supports (display: grid) {
      grid-column-gap: 15px;
    }
  }
`;

const QuestionType = styled.div`
  grid-area: question-type;
  display: flex;
  align-items: center;

  color: #999999;
  font-size: 15px;
`;

const QuestionTypeIconContainer = styled.div`
  width: 19px;
  height: 19px;
  margin-right: 10px;
`;

const DeleteButton = styled(DeleteIcon)`
  grid-area: delete-button;
  justify-self: end;

  margin-right: 3px;
  margin-top: 2px;
  cursor: pointer;
`;

const QuestionText = styled.p`
  grid-area: question-text;

  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (min-width: 768px) {
    @supports (display: grid) {
      font-size: 18px;
    }
  }
`;

const EditButton = styled(EditIcon)`
  grid-area: edit-button;
  justify-self: end;

  margin-top: 3px;
  cursor: pointer;
`;
