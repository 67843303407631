import { Alert } from '@mui/material';
import { Snackbar } from './Snackbar';
import { SuccessSnackbarProps } from './types';

export const SuccessSnackbar: React.FC<SuccessSnackbarProps> = ({ message, onClose, open, sx }) => {
  return (
    <Snackbar onClose={onClose} open={open} message={message} duration={3000} sx={sx}>
      <Alert
        onClose={onClose}
        severity="success"
        sx={{ fontSize: '14px', width: '100%', fontWeight: 600 }}
        variant="filled"
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
