import { useState, useRef } from 'react';
import styled from 'styled-components';

import { CategoryDropdown } from './CategoryDropdown';
import { useOnKeyEvent } from 'hooks';
import { Modal, Input, Label } from 'ui-kit';
import { isValidEmail } from 'utils/emailutils';

export const JobAlertsSubscribeModal = ({
  isOpen,
  onClose,
  onSubscribeToJobAlerts,
  onUnsubscribeToJobAlerts,
  jobboardName,
  categories,
  emailState,
  setEmailState,
  selectedSubcategories,
  setSelectedSubcategories,
  isEditing,
}) => {
  const firstInputRef = useRef(null);
  const [isUnsubscribing, setIsUnsubscribing] = useState(false);
  const [unSubscribeFromAll, setUnSubscribeFromAll] = useState(false);

  const validateForm = () => {
    if (!emailState.email) {
      setEmailState(prevState => ({ ...prevState, error: 'Email is required.' }));
      return false;
    }

    if (!isValidEmail(emailState.email)) {
      setEmailState(prevState => ({ ...prevState, error: 'Please enter a valid email.' }));
      return false;
    }

    return true;
  };

  const onSubscribe = () => {
    const isValid = validateForm();
    if (!isValid) return;
    onSubscribeToJobAlerts();
  };

  const onUnsubscribe = () => {
    onUnsubscribeToJobAlerts(unSubscribeFromAll);
  };

  // Submit form on enter key
  useOnKeyEvent('Enter', 'keyup', isUnsubscribing ? onUnsubscribe : onSubscribe, isOpen);

  return (
    <Modal.Modal isOpen={isOpen} onClose={onClose} focusOnOpenRef={firstInputRef}>
      <Modal.Title>{isEditing ? 'Edit job alerts' : 'Subscribe to job alerts'}</Modal.Title>
      <Modal.Body>
        {(isUnsubscribing && (
          <UnsubscribeForm>
            <div>Unsubscribe from</div>
            <UnsubscribeLabel onClick={() => setUnSubscribeFromAll(false)}>
              <input
                onChange={() => setUnSubscribeFromAll(false)}
                type="radio"
                checked={!unSubscribeFromAll}
              />
              <div>
                <div>Alerts for {jobboardName}</div>
                <UnsubscribeLabelSmallText>
                  You will no longer receive any job alerts related to this organization
                </UnsubscribeLabelSmallText>
              </div>
            </UnsubscribeLabel>
            <UnsubscribeLabel onClick={() => setUnSubscribeFromAll(true)}>
              <input
                onChange={() => setUnSubscribeFromAll(true)}
                type="radio"
                checked={unSubscribeFromAll}
              />
              <div>
                <div>All Nimble job alerts</div>
                <UnsubscribeLabelSmallText>
                  You will no longer receive any job alerts from Nimble{' '}
                </UnsubscribeLabelSmallText>
              </div>
            </UnsubscribeLabel>
          </UnsubscribeForm>
        )) || (
          <SubscribeForm onSubmit={onSubscribe}>
            <Label htmlFor="job-alerts-email">
              Email{' '}
              <Input
                id="job-alerts-email"
                type="email"
                name="email"
                placeholder="me@schooldistrict.edu"
                required
                ref={firstInputRef}
                value={emailState.email}
                hasError={emailState.error}
                onChange={event => setEmailState({ email: event.target.value })}
                marginTop="1em"
              />
              {emailState.error && <FormInputError>{emailState.error}</FormInputError>}
            </Label>
            <Label htmlFor="job-alerts-categories">
              Job categories of interest
              <CategoryDropdown
                id="job-alerts-categories"
                categories={categories}
                selectedSubcategories={selectedSubcategories}
                setSelectedSubcategories={setSelectedSubcategories}
              />
            </Label>
          </SubscribeForm>
        )}
      </Modal.Body>
      <Modal.Actions>
        <Actions isEditing={isEditing} isUnsubscribing={isUnsubscribing}>
          <Button onClick={onClose}>Cancel</Button>
          {(isEditing && !isUnsubscribing && (
            <UnsubscribeTextButton onClick={() => setIsUnsubscribing(true)}>
              Unsubscribe from job alerts
            </UnsubscribeTextButton>
          )) || <div />}
          <PrimaryButton
            type="submit"
            onClick={isUnsubscribing ? onUnsubscribe : onSubscribe}
            disabled={emailState.error}
          >
            {isEditing ? 'Submit' : 'Subscribe'}
          </PrimaryButton>
        </Actions>
      </Modal.Actions>
    </Modal.Modal>
  );
};

const Actions = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto;
  grid-column-gap: 0.5em;
  grid-row-gap: 0.5em;
  align-items: center;

  // Collapse action buttons into a column for small screens
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    text-align: center;

    // Put the submit button on top of the cancel buttons
    & > button:last-child {
      grid-row: 1;
    }
  }
`;

const Button = styled.button`
  padding: 0.85em 2.5em;
  border-radius: 2px;

  font-size: 16px;

  background-color: #ffffff;
  color: #c2c2c2;
  border: 1px solid rgba(0, 0, 0, 0.12);
`;

const UnsubscribeTextButton = styled.span`
  font-size: 16px;
  background-color: #ffffff;
  color: #c2c2c2;

  padding: 0.5em;

  cursor: pointer;
`;

const PrimaryButton = styled(Button)`
  background-color: #c4c4c4;
  color: #ffffff;
`;

const SubscribeForm = styled.form`
  display: flex;
  flex-flow: row wrap;

  & > label {
    flex: 1;
  }

  & > label:not(:last-child) {
    margin-right: 1.176em;
  }

  // Collapse form elements into a column for small screens
  @media screen and (max-width: 768px) {
    & > label {
      flex-basis: 100%;
    }

    & > label:not(:last-child) {
      margin-right: 0;
      margin-bottom: 1.176em;
    }
  }
`;

const FormInputError = styled.div`
  margin-top: 0.25em;
  font-size: 13px;
  color: #cc0033;
`;

const UnsubscribeForm = styled.form`
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const UnsubscribeLabel = styled.label`
  display: flex;
  align-items: flex-start;

  font-weight: normal;
  font-size: 14px;

  input {
    margin-right: 1rem;
  }
`;

const UnsubscribeLabelSmallText = styled.div`
  color: #999999;
  font-size: 12px;
`;
