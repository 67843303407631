import { Component } from 'react';
import _ from 'lodash';

import JobEdit from '../components/JobEdit';
import { roleStatuses, getJobStatusColor } from '../utils/enums';
import auth from '../utils/auth';
import { currentHiringSeason } from '../utils/enums';
import SchoolrolesAPI from 'api/schoolrolesAPI';
import nimbleQuestionsAPI from 'api/nimbleQuestionsAPI';
import CustomScorecardListAPI from 'api/customScorecardListAPI';
import { useHistory, withRouter } from 'react-router-dom';

const statuses = _.keyBy(roleStatuses(), 'value');

const initialErrorState = {
  link_to_ats: '',
};

class SchoolJobCreateContainer extends Component {
  state = {
    statusColor: '',
    questionBank: [],
    job: {
      title: '',
      grades: [],
      questions: [],
      hiring_season: currentHiringSeason(),
    },
    activelySubmitting: false,
    customScorecardsAvailable: [],
    customScorecardsSelected: [],
    apiResponseErrors: { ...initialErrorState },
  };

  componentDidMount() {
    nimbleQuestionsAPI.getAll().then(questionBank => {
      if (!this.ignoreLastFetch) {
        this.setState({ questionBank });
      }
    });
    CustomScorecardListAPI.fetchCustomScorecards().then(data => {
      this.setState({
        customScorecardsAvailable: data
          .sort((a, b) => {
            if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
            if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
            return 0;
          })
          .filter(scorecard => scorecard.view_permissions !== 'me_only'),
      });
    });
  }

  setStatusColor = () => {
    this.setState({ statusColor: getJobStatusColor(this.state.job.status) });
  };

  onSave = putJob => {
    this.setState({ activelySubmitting: true });

    putJob.status = statuses.draft.key;
    SchoolrolesAPI.postSchoolrole(putJob).then(() => {
      this.setState({ activelySubmitting: false });
      if (!this.ignoreLastFetch) {
        this.props.history.push('/school/jobslist?f_JS=draft');
      }
    });
  };

  onSubmit = putJob => {
    this.setState({ activelySubmitting: true });
    putJob.status = statuses.submitted.key;
    putJob.submitted_by = auth.getUser().id;

    SchoolrolesAPI.postSchoolrole(putJob).then(() => {
      this.setState({ activelySubmitting: false });
      if (!this.ignoreLastFetch) {
        this.props.history.push('/school/jobslist?f_JS=pending');
      }
    });
  };

  componentWillUnmount() {
    this.ignoreLastFetch = true;
  }

  clearAPIResponseErrors = () => {
    this.setState({ apiResponseErrors: { ...initialErrorState } });
  };

  render() {
    return (
      this.state.job && (
        <JobEdit
          job={this.state.job}
          statusColor={this.state.statusColor}
          questionBank={this.state.questionBank}
          onSave={this.onSave}
          onSubmit={this.onSubmit}
          onSubmitLabel="Submit to Admin"
          pageTitle={`Request Job at ${auth.getUser().profile.school.name}`}
          activelySubmitting={this.state.activelySubmitting}
          customScorecards={this.state.customScorecardsAvailable}
          customScorecardsSelected={this.state.customScorecardsSelected}
          apiResponseErrors={this.state.apiResponseErrors}
          clearAPIResponseErrors={this.clearAPIResponseErrors}
        />
      )
    );
  }
}

export default withRouter(SchoolJobCreateContainer);
