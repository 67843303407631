import { useEffect } from 'react';
import styled from 'styled-components';
import useSWR from 'swr';

import { scorecardQType } from 'utils/enums';
import scorecardAPI from 'api/scorecardAPI';

import TextViewAnswer from 'components/ScorecardModal/ViewerAnswerTypes/TextViewAnswer';
import MultipleChoiceViewAnswer from 'components/ScorecardModal/ViewerAnswerTypes/MultipleChoiceViewAnswer';
import RubricViewAnswer from 'components/ScorecardModal/ViewerAnswerTypes/RubricViewAnswer';
import CumulativeScoreViewAnswer from 'components/ScorecardModal/ViewerAnswerTypes/CumulativeScoreViewAnswer';
import FinalRecommendationViewAnswer from 'components/ScorecardModal/ViewerAnswerTypes/FinalRecommendationViewAnswer';
import DirectionsViewAnswer from 'components/ScorecardModal/ViewerAnswerTypes/DirectionsViewAnswer';
import RatingViewAnswer from 'components/ScorecardModal/ViewerAnswerTypes/RatingViewAnswer';
import AttachmentViewAnswer from 'components/ScorecardModal/ViewerAnswerTypes/AttachmentViewAnswer';

import LoadingSpinner from 'components/loadingSpinner';
import { useRouteMatch } from 'react-router-dom';

export default function ScorecardPrintView(props) {
  const routeMatch = useRouteMatch('/view-scorecard/:id');
  const scorecardId = routeMatch?.params.id;

  if (!scorecardId) {
    throw new Error('Expected a scorecard but was not given one');
  }

  const KEY = `/api/scorecards/${scorecardId}`;
  const { data } = useSWR(KEY, () => scorecardAPI.fetch(scorecardId));

  useEffect(() => {
    if (data !== undefined) {
      // setTimeout ensures the content is fully rendered before opening
      // the print window
      setTimeout(window.print, 100);
    }
  }, [data]);

  if (data === undefined) {
    return <LoadingSpinner />;
  }

  const renderQuestion = item => {
    if (
      item.question_type === scorecardQType.rubric ||
      item.question_type === scorecardQType.direction_text
    ) {
      return null;
    }

    let question = '';
    if (item.question_type === scorecardQType.cumulative_score) {
      question = 'Cumulative score:';
    } else if (item.question_type === scorecardQType.final_recommendation) {
      question = 'Do you recommend this candidate?';
    } else {
      question = item.question_text;
    }

    return (
      <>
        <QuestionText>{question}</QuestionText>
        {item.is_required && <sup>*</sup>}
      </>
    );
  };

  const renderAnswer = answer => {
    switch (answer.question_type) {
      case scorecardQType.rating:
        return <RatingViewAnswer answer={answer} />;
      case scorecardQType.nimble:
      case scorecardQType.text:
        return <TextViewAnswer answer={answer} />;
      case scorecardQType.multiple_choice:
        return <MultipleChoiceViewAnswer answer={answer} />;
      case scorecardQType.cumulative_score:
        return <CumulativeScoreViewAnswer parentObject={data} />;
      case scorecardQType.direction_text:
        return <DirectionsViewAnswer answer={answer} />;
      case scorecardQType.final_recommendation:
        return <FinalRecommendationViewAnswer answer={answer} parentObject={data} />;
      case scorecardQType.rubric:
        return <RubricViewAnswer answer={answer} />;
      case scorecardQType.attachment:
        return <AttachmentViewAnswer answer={answer} urlBase="scorecard_answer" />;
      default:
        return <div />;
    }
  };

  return (
    <Container>
      <HeaderText>{data.template_name}</HeaderText>
      {data.answers.map(item => (
        <AnswerItem key={item.id}>
          {renderQuestion(item)}
          {renderAnswer(item)}
        </AnswerItem>
      ))}
    </Container>
  );
}

const Container = styled.div`
  max-width: 1120px;
  margin: auto;
`;

const HeaderText = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;

  color: #444444;

  margin-bottom: 24px;
  margin-top: 30px;
`;

const QuestionText = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;

  color: #444444;
`;

const AnswerItem = styled.div`
  margin-bottom: 50px;
`;
