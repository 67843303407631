import { Accordion } from 'sharedComponents/Accordion/Accordion';
import { Author, AuthorDate, Date, InternalNotesContainer, Message, Row } from '../styles';
import { JobViewV2InternalNotesProps, JobViewV2InternalNotesRowProps } from '../types';
import { makeAllUrlsValid } from 'utils/util';
import { Typography } from '@mui/material';
import moment from 'moment';

export const JobViewV2InternalNotesRow: React.FC<JobViewV2InternalNotesRowProps> = ({
  internalNote,
}) => {
  const author = internalNote.created_by.name;
  const date = moment(internalNote.created_at).format('MMM DD, h:mm a');
  const message = internalNote.text;

  return (
    <Row data-testid="internal-note">
      <AuthorDate data-testid="internal-note-signature">
        <Author data-testid="internal-note-author">{author}</Author>{' '}
        <Date variant="body3" data-testid="internal-note-date">
          {date}
        </Date>
      </AuthorDate>
      <Message
        data-testid="internal-note-message"
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: makeAllUrlsValid(message),
        }}
      />
    </Row>
  );
};

export const JobViewV2InternalNotes: React.FC<JobViewV2InternalNotesProps> = ({
  internalNotes,
}) => {
  return (
    <Accordion
      sx={{ minHeight: 0, padding: 0 }}
      defaultExpanded
      title={<Typography variant="subtitle">Internal Notes</Typography>}
      dataTestId="jobviewv2-internal-notes"
    >
      <InternalNotesContainer className="ql-editor" data-testid="internal-notes">
        {internalNotes.map(internalNote => (
          <JobViewV2InternalNotesRow key={internalNote.id} internalNote={internalNote} />
        ))}
      </InternalNotesContainer>
    </Accordion>
  );
};
