import styled from 'styled-components';

function CheckboxContainer({ handleItemMatrixChange, itemMatrix }) {
  const {
    isVisibleExternal,
    isVisibleInternal,
    isRequiredExternal,
    isRequiredInternal,
  } = itemMatrix;

  return (
    <CheckboxContainerOuter>
      <GridItemHeader />
      <GridItemHeader>visible</GridItemHeader>
      <GridItemHeader>required</GridItemHeader>
      <GridItem>external</GridItem>
      <DivWithAllBorders>
        <input
          type="checkbox"
          name="isVisibleExternal"
          style={{ margin: '0' }}
          value={isVisibleExternal}
          checked={isVisibleExternal}
          onChange={handleItemMatrixChange}
          // disable input if required external is true
          disabled={isRequiredExternal}
        />
      </DivWithAllBorders>
      <DivWithNoLeftBorder>
        <input
          type="checkbox"
          name="isRequiredExternal"
          style={{ margin: '0' }}
          value={isRequiredExternal}
          checked={isRequiredExternal}
          onChange={handleItemMatrixChange}
        />
      </DivWithNoLeftBorder>
      <GridItem>internal</GridItem>
      <DivWithNoTopBorder>
        <input
          type="checkbox"
          name="isVisibleInternal"
          style={{ margin: '0' }}
          value={isVisibleInternal}
          checked={isVisibleInternal}
          onChange={handleItemMatrixChange}
          // disable input if required internal is true
          disabled={isRequiredInternal}
        />
      </DivWithNoTopBorder>
      <DivWithNoTopOrLeftBorder>
        <input
          type="checkbox"
          name="isRequiredInternal"
          style={{ margin: '0' }}
          value={isRequiredInternal}
          checked={isRequiredInternal}
          onChange={handleItemMatrixChange}
        />
      </DivWithNoTopOrLeftBorder>
    </CheckboxContainerOuter>
  );
}

export default CheckboxContainer;

const CheckboxContainerOuter = styled.div`
  display: grid;
  grid-template-columns: 70px repeat(2, 62px);
  grid-template-rows: 30px repeat(2, 40px);

  font-size: 14px;
`;

const GridItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GridItemHeader = styled(GridItem)`
  align-self: start;
`;

const DivWithAllBorders = styled(GridItem)`
  border: 1px solid rgba(57, 60, 73, 0.5);
`;

const DivWithNoLeftBorder = styled(DivWithAllBorders)`
  border-left: 0;
`;

const DivWithNoTopBorder = styled(DivWithAllBorders)`
  border-top: 0;
`;

const DivWithNoTopOrLeftBorder = styled(DivWithAllBorders)`
  border-left: 0;
  border-top: 0;
`;
