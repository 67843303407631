import {
  USStates,
  stateDashboardSegmentTypes,
  gender,
  race_ethnicity,
  grades,
  gradeLevelsUpdated,
  gradeMapping,
  employment_type,
} from '../../utils/enums';
import categoriesAPI from '../../api/categoriesAPI';
import stateAPI from '../../api/stateAPI';

import { LabelItem } from './types';

interface Choices {
  id: string;
  value: number | null;
  label: string;
  group: number;
  isGroupHeader: boolean;
}

export const defaultFilters = {
  hiring_seasons: [], // enums
  districts: [], // dynamic
  employment_type: [], // enums
  status: [], // dynamic
  grade: [], // enums
  subcategory: [], // dynamic
  certification_area: [], // dyanmic
  gender: [], // enums
  race_ethnicty: [],
  epp: [],
  nces: [],
};

export const getLabelText = (item: LabelItem[] | boolean | undefined): string => {
  if (typeof item === 'boolean' || !item || item.length === 0) {
    return 'No options selected';
  } else if (item.length === 1) {
    return '1 option selected';
  } else {
    return `${item.length} options selected`;
  }
};

export const groupedSegments = [
  stateDashboardSegmentTypes.grade.value,
  stateDashboardSegmentTypes.subcategory.value,
];

const formatChoices = choices => {
  const choicesFormatted = choices.map(item => ({
    id: parseInt(item.value),
    value: parseInt(item.value),
    label: item.label,
  }));
  return choicesFormatted;
};

const fetchAndFormatCategoriesGrouped = async stateCode => {
  // TODO: This will need to be revisted once more states are added (not to use state_id)
  const response = await categoriesAPI.fetchCategoriesByState(stateCode);
  const categoryDropdownChoices: Choices[] = [];
  response.forEach(category => {
    categoryDropdownChoices.push({
      id: `p-${category.id}`,
      value: null,
      label: category.label,
      group: category.id,
      isGroupHeader: true,
    });
    category.subcategories.forEach(subcategory => {
      categoryDropdownChoices.push({
        id: `${subcategory.state_id}`,
        value: subcategory.state_id,
        label: subcategory.label,
        group: category.id,
        isGroupHeader: false,
      });
    });
  });
  return categoryDropdownChoices;
};

export const getArrayOfsubItems = (array: string[]): number[] => {
  return array.filter(i => !i.includes('p-')).map(i => parseInt(i));
};
const fetchAndFormatCredentials = async stateCode => {
  const stateId = USStates().find(s => s.label === stateCode)?.id;
  const response = await stateAPI.fetchState(stateId);
  const stateDataFormatted = response.credential_subjects.map(c => ({
    id: c.id,
    value: c.id,
    label: c.label,
  }));
  return stateDataFormatted;
};

const fetchAndFormatGradesGrouped = () => {
  const gradesDropdownChoices: Choices[] = [];
  gradeLevelsUpdated().forEach(level => {
    gradesDropdownChoices.push({
      id: `p-${level.value}`,
      value: null,
      label: level.label,
      group: level.value,
      isGroupHeader: true,
    });
    const gradeEnums = gradeMapping()[level.key] || [];
    const gradesInLevel = grades().filter(g => gradeEnums.includes(g.value));
    gradesInLevel.forEach(grade => {
      gradesDropdownChoices.push({
        id: `${grade.value}`,
        value: grade.value,
        label: grade.label,
        group: level.value,
        isGroupHeader: false,
      });
    });
  });
  return gradesDropdownChoices;
};

export const getFilterOptions = async (value: number, stateCode: string) => {
  if (value === stateDashboardSegmentTypes.grade.value) {
    const options = await fetchAndFormatGradesGrouped();
    return options;
  } else if (value === stateDashboardSegmentTypes.gender.value) {
    return await formatChoices(gender());
  } else if (value === stateDashboardSegmentTypes.raceEthnicity.value) {
    return await formatChoices(race_ethnicity());
  } else if (value === stateDashboardSegmentTypes.employmentType.value) {
    return await formatChoices(employment_type());
  } else if (value === stateDashboardSegmentTypes.credential.value) {
    return await fetchAndFormatCredentials(stateCode);
  } else if (value === stateDashboardSegmentTypes.subcategory.value) {
    const options = await fetchAndFormatCategoriesGrouped(stateCode);
    return options;
  }
  return undefined;
};
