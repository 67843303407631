import styled from 'styled-components';

import { ReactComponent as DeleteIcon } from '../../assets/icons/icon_trash.svg';

export default function RubricQuestion({
  questionText,
  setQuestionText,
  rubricColumns,
  setRubricColumns,
}) {
  const addNewColumn = () => {
    const updatedColumns = [...rubricColumns];
    const lastColumnNumber = updatedColumns[updatedColumns.length - 1].number;
    const newColumnNumber = lastColumnNumber + 1;
    updatedColumns.push({
      number: newColumnNumber,
      label: '',
      description: '',
    });
    setRubricColumns(updatedColumns);
  };

  const removeLastColumn = () => {
    const updatedColumns = [...rubricColumns];
    const lastColumn = updatedColumns.length - 1;
    updatedColumns.splice(lastColumn, 1);
    setRubricColumns(updatedColumns);
  };

  const setColumnLabel = (e, index) => {
    const updatedColumns = [...rubricColumns];
    updatedColumns[index].label = e.target.value;
    setRubricColumns(updatedColumns);
  };

  const setColumnDescription = (e, index) => {
    const updatedColumns = [...rubricColumns];
    updatedColumns[index].description = e.target.value;
    setRubricColumns(updatedColumns);
  };

  const updateColumnNumbers = event => {
    // firstNumber will be either 0 or 1
    const firstNumber = Number(event.target.value);
    const updatedColumns = rubricColumns.map((column, index) => ({
      ...column,
      // we allow users to decide if the rubric ratings start with 0 or 1,
      // so we have to adjust for that
      number: firstNumber === 0 ? index : index + 1,
    }));
    setRubricColumns(updatedColumns);
  };

  const showDeleteButton = index => {
    return index === rubricColumns.length - 1 && rubricColumns.length > 2;
  };

  return (
    <>
      <Header>Dimension name</Header>
      <input
        className="form-field full-width box-style"
        type="text"
        placeholder="e.g. Detail oriented"
        value={questionText}
        onChange={e => setQuestionText(e.target.value)}
      />
      <HeaderContainer>
        <Header>Rating names and descriptions</Header>
        {rubricColumns.length < 6 && (
          <AddNewColumnButton onClick={addNewColumn}>+ Add new column</AddNewColumnButton>
        )}
      </HeaderContainer>
      <RubricGridContainer numberOfColumns={rubricColumns.length}>
        {rubricColumns.map((item, index) => (
          <RubricColumnContainer key={item.number}>
            {index === 0 ? (
              <RubricColumnNumberSelect value={item.number} onChange={updateColumnNumbers}>
                <option value={0}>0</option>
                <option value={1}>1</option>
              </RubricColumnNumberSelect>
            ) : (
              <RubricColumnNumberDisplay>{item.number}</RubricColumnNumberDisplay>
            )}
            <RubricLabelInput
              type="text"
              placeholder="e.g. effective"
              value={item.label}
              onChange={e => setColumnLabel(e, index)}
            />
            <RubricDescriptionTextarea
              type="text"
              placeholder="e.g. candidate fully masters this competency"
              value={item.description}
              onChange={e => setColumnDescription(e, index)}
            />
            {showDeleteButton(index) && (
              <RemoveIconContainer onClick={removeLastColumn}>
                <RemoveColumnIcon />
              </RemoveIconContainer>
            )}
          </RubricColumnContainer>
        ))}
      </RubricGridContainer>
    </>
  );
}

const Header = styled.p`
  font-size: 16px;
  color: #444444;
  font-weight: 600;
  line-height: 40px;
`;

const HeaderContainer = styled.div`
  margin-top: 28px;
  margin-bottom: 2rem;

  @media screen and (min-width: 600px) {
    display: grid;
    grid-template-columns: minmax(238px, 1fr) 145px;
    grid-column-gap: 16px;
    height: 40px;
    align-items: center;

    margin-bottom: 2px;
  }
`;

const AddNewColumnButton = styled.span`
  cursor: pointer;
  color: #00b88d;
  justify-self: end;
`;

const RubricGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (min-width: 991px) {
    grid-template-columns: ${props => `repeat(${props.numberOfColumns}, minmax(0, 1fr))`};
    grid-column-gap: 14px;
  }
`;

const RubricColumnContainer = styled.div`
  display: grid;
  grid-template-rows: 30px 50px minmax(155px, auto) 20px;
  grid-row-gap: 11px;

  width: 100%;
`;

const RubricColumnNumberSelect = styled.select`
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.0954484);
  border-radius: 3px;
  border: none;

  font-size: 14px;

  text-indent: 50%;
`;

const RubricColumnNumberDisplay = styled.div`
  font-size: 14px;
  text-align: center;
  line-height: 30px;
`;

const RubricLabelInput = styled.input`
  background-color: #fff;
  padding: 15px;
  border: 0;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  height: 50px;
  width: 100%;
  margin: 0 !important;

  font-size: 16px;
  color: #777777;

  &::placeholder {
    color: #999999;
    opacity: 0.4;
  }
`;

const RubricDescriptionTextarea = styled.textarea`
  background-color: #fff;
  padding: 15px;
  border: 0;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  font-size: 12px;
  line-height: 16px;

  color: #8e8d8d;

  resize: vertical;

  &::placeholder {
    color: #999999;
    opacity: 0.4;
  }
`;

const RemoveIconContainer = styled.div`
  text-align: center;
`;

const RemoveColumnIcon = styled(DeleteIcon)`
  height: 14px !important;
  width: 14px !important;

  cursor: pointer;
`;
