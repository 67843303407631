import {
  CheckmarkBlackBox,
  TextIcon,
  SchoolPreferencesQuestionIcon,
  YesNoIcon,
  NimbleLogoBlack,
  MultipleChoiceIcon,
  AttachmentIcon,
  VideoIcon,
  DirectionTextIcon
} from 'ui-kit/icons';

export const questionSetItemOptions = () => [
  {
    key: 'direction_text',
    value: 'question_type/direction_text',
    label: 'Direction Text',
    icon: DirectionTextIcon,
    info: 'Gives the candidate clear directions on how to fill out this job application'
  },
  {
    key: 'text',
    value: 'question_type/open_response',
    label: 'Text Question',
    icon: TextIcon,
    info: 'Candidate can respond in free form text'
  },
  {
    key: 'multiple_choice',
    value: 'question_type/multiple_choice',
    label: 'Multiple Choice',
    icon: MultipleChoiceIcon,
    info: 'Candidate can select one option',
    info2: 'Candidate can select multiple options'
  },
  {
    key: 'nimble',
    value: 'question_type/nimble',
    label: 'Nimble Question',
    icon: NimbleLogoBlack,
    info: 'Candidate can respond in free form text'
  },
  {
    key: 'attachment',
    value: 'question_type/attachment',
    label: 'Attachment',
    icon: AttachmentIcon
  },
  {
    key: 'yes_no',
    value: 'question_type/yes_no',
    label: 'Yes/No Question',
    icon: YesNoIcon,
    info: 'Candidate can select "yes" or "no"'
  },
  {
    key: 'school_preferences',
    value: 'question_type/school_preferences',
    label: 'School Preferences',
    icon: SchoolPreferencesQuestionIcon,
    info: 'Candidate can select schools they are interested in'
  },
  {
    key: 'video_link',
    value: 'question_type/video_link',
    label: 'Video Link Question',
    icon: VideoIcon,
    info: 'Candidate can add a link to an interview video'
  },
  {
    key: 'statement_checkbox',
    value: 'question_type/statement_checkbox',
    label: 'Statement Checkbox',
    icon: CheckmarkBlackBox,
    info: 'Asks the candidate to check the box to agree to a statement'
  }
];

export const questionSetItemMapping = questionSetItemOptions().reduce((obj, item) => {
  obj[item.value] = { ...item };
  return obj;
}, {});
