import { Component } from 'react';
import axios from 'axios';
import _ from 'lodash';

import Dashboard from '../components/Dashboard/index';
import { fetchAdmins } from '../utils/statusviewutils';
import { currentHiringSeason, hiringSeasons, monthNumberToAbbreviation } from '../utils/enums';
import auth from '../utils/auth';
import { showWarning } from '../utils/message';
import schoolsAPI from 'api/schoolsAPI';
import schoolGroupsAPI from 'api/schoolGroupsAPI';
import { withRouter } from 'react-router-dom';

let currentSeasonObj = hiringSeasons(true, true).find(s => s.current_season);

// first month in hiring season is october
const monthOrder = {
  10: 0,
  11: 1,
  12: 2,
  1: 3,
  2: 4,
  3: 5,
  4: 6,
  5: 7,
  6: 8,
  7: 9,
  8: 10,
  9: 11,
};

class DashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminUsers: [],
      unplacedCandidates: [],
      vacancies: [],
      page: 0,
      hasMore: false,
      loadingSpinner: false,
      fetchVacanciesRunning: false,
      fetchVacanciesCompleted: false,
      positionsNumerator: 0,
      positionsDenominator: 0,
      schoolsFilter: [],
      subcategoriesFilter: [],
      rolesFilter: [],
      hiringSeasonFilter: currentSeasonObj ? [currentSeasonObj.value] : [],
      schoolOptions: [],
      roleOptions: [],
      hiringSeasonOptions: [],
      categories: [],
      subcategories: [], // these are the options used for the category filter
      vacancyHistory: [],
      orderBy: 'role',
      orderDescending: false,
      totalLineItems: 0,
    };
    this.user = auth.getUser();
    this.isSchoolUser = auth.isSchoolUser();
    this.districtId = this.user.profile.district.id;
    this.fetchAdmins = fetchAdmins.bind(this);
    /** filter out 'N/A' hiring season, and only allow user to select past hiring seasons. */
    this.filteredHiringSeasons = hiringSeasons(true, true).filter(
      s => s.label !== 'N/A' && s.value <= currentSeasonObj.value
    );

    const { search } = this.props.location;
    const searchParams = new URLSearchParams(search);
    const pid = searchParams.get('pid');
    const spid = searchParams.get('spid');
    this.state.pid = pid;
    this.state.spid = spid;
  }

  componentDidMount() {
    this.fetchUnplacedCandidates();
    this.setSubcategories(this.districtId, true);
    this.fetchAdmins();
    this.getSchoolOptions(); // This will call `getVacancies` as a callback.
    this.getRoles();
  }

  componentWillUnmount() {
    this.ignoreLastFetch = true;
  }

  search = () => {
    // position id (pid) and schoolposition id (spid) should only be used
    // when the user first comes to the page. any subsequent calls won't use
    // them, so make sure they are clear.
    this.pushQueryLocation({ pid: '', spid: '' });
    this.setState(
      {
        vacancies: [],
        page: 0,
        more: false,
        fetchVacanciesCompleted: false,
      },
      this.getVacancies
    );
  };

  setSubcategories = (district_id, setFilters = false) => {
    axios.get(`/api/categories/`, { params: { district_id } }).then(r => {
      // Mimic the school + schoolsGroups setup

      let categoryOptions = [];
      let subcategoriesFilter = [];

      [{ id: 'N/A', label: 'No category', subcategories: [] }, ...r.data].forEach(c => {
        categoryOptions.push({
          id: c.id,
          label: c.label,
          value: c.id,
          group: 'g' + c.id,
          isGroupHeader: true,
        });
        if (setFilters) subcategoriesFilter.push(c.id);

        c.subcategories.forEach(s => {
          categoryOptions.push({
            id: s.id,
            label: s.label,
            value: s.id,
            group: 'g' + c.id,
          });
          if (setFilters) subcategoriesFilter.push(s.id);
        });
      });

      this.setState({
        subcategories: categoryOptions,
        subcategoriesFilter: setFilters && subcategoriesFilter,
      });
    });
  };

  getVacancies = (startSpinner = true) => {
    let {
      page,
      hiringSeasonFilter,
      rolesFilter,
      roleOptions,
      schoolsFilter,
      schoolOptions,
      subcategoriesFilter,
      subcategories,
      orderBy,
      orderDescending,
    } = this.state;

    this.setState({ fetchVacanciesRunning: true });

    // start spinner on all new fetches. this will be false
    // for the debounced vacancies fetch, which isn't a new
    // fetch but one that grabs more results and adds them
    // to the bottom of the list.
    if (startSpinner) {
      this.setState({ loadingSpinner: true });
    }

    // optimizations. don't send filter if they have all options
    // selected or no options selected since we send back all results
    // in those cases.
    if (hiringSeasonFilter.length === hiringSeasons().length) {
      hiringSeasonFilter = [];
    }

    if (schoolsFilter.length === schoolOptions.length) {
      schoolsFilter = [];
    } else {
      // Remove list headers.
      schoolsFilter = schoolsFilter.filter(s => String(s)[0] !== 'g');
    }

    if (rolesFilter.length === roleOptions.length) {
      rolesFilter = [];
    }

    if (subcategoriesFilter.length === subcategories.length) {
      subcategoriesFilter = [];
    }

    axios
      .get('/api/positions/', {
        params: {
          page: Number(page) + 1,
          // only present if user was tagged on a district vacancy
          position_id: this.props.pid,
          // only present if user was tagged on a school vacancy
          schoolposition_id: this.props.spid,
          hiring_season_list: hiringSeasonFilter,
          // if school user, only return vacancies for that user's school by default
          schools_list: schoolsFilter,
          roles_list: rolesFilter,
          category_list: subcategoriesFilter,
          orderBy,
          orderDescending,
        },
      })
      .then(r => {
        if (
          (_.isEqual(hiringSeasonFilter, this.state.hiringSeasonFilter) ||
            ([0, hiringSeasons().length].includes(hiringSeasonFilter.length) &&
              [0, hiringSeasons().length].includes(this.state.hiringSeasonFilter.length))) &&
          (_.isEqual(rolesFilter, this.state.rolesFilter) ||
            ([0, this.state.roleOptions.length].includes(rolesFilter.length) &&
              [0, this.state.roleOptions.length].includes(this.state.rolesFilter.length))) &&
          (_.isEqual(subcategoriesFilter, this.state.subcategoriesFilter) ||
            ([0, this.state.subcategories.length].includes(subcategoriesFilter.length) &&
              [0, this.state.subcategories.length].includes(
                this.state.subcategoriesFilter.length
              ))) &&
          page === this.state.page &&
          orderBy === this.state.orderBy &&
          orderDescending === this.state.orderDescending &&
          !this.ignoreLastFetch
        ) {
          let { history, page, more, total_line_items } = r.data;
          let incomingVacancies = r.data.results;
          let filledPositions = r.data.numerator;
          let totalPositions = r.data.denominator;

          let existingVacancies = [];
          if (page > 1) {
            existingVacancies = [...this.state.vacancies];
          }
          let finalVacancies = this.addIncomingVacancies(existingVacancies, incomingVacancies);

          let updatedHistory = this.addMonthToHistory(history);
          this.sortHistoryInPlace(updatedHistory);
          const finalHistory = this.removeFutureMonths(updatedHistory);

          this.setState({
            loadingSpinner: false,
            vacancies: finalVacancies,
            page: page,
            hasMore: more,
            fetchVacanciesCompleted: true,
            fetchVacanciesRunning: false,
            positionsNumerator: filledPositions,
            positionsDenominator: totalPositions,
            vacancyHistory: finalHistory,
            totalLineItems: total_line_items,
          });
        }
      });
  };

  getVacanciesDebounced = () => {
    if (this.state.fetchVacanciesRunning === true) {
      return;
    } else {
      this.getVacancies(false);
    }
  };

  fetchUnplacedCandidates = () => {
    const hiring_season = this.state.hiringSeasonFilter;
    axios
      .get('/api/unplaced_candidates/', {
        params: {
          hiring_season,
        },
      })
      .then(r => {
        const unplacedCandidates = r.data;
        this.setState({ unplacedCandidates });
      });
  };

  getSchoolOptions = () => {
    return schoolsAPI.fetchActiveSchools().then(activeSchools => {
      schoolGroupsAPI.getSchoolGroups().then(schoolGroups => {
        let schoolOptions = [];
        let schoolsFilter = [];
        const hasOwnSchool = this.isSchoolUser && !this.props.pid;

        schoolOptions.push({ id: -1, value: -1, label: 'Non-school vacancies' });
        if (!hasOwnSchool) {
          schoolsFilter.push(-1);
        }

        if (schoolGroups.length === 0) {
          activeSchools.forEach(a => {
            schoolOptions.push({
              id: a.id,
              value: a.id,
              label: a.name,
            });

            if (!hasOwnSchool) {
              schoolsFilter.push(a.id);
            }
          });
        } else {
          schoolGroups.forEach(g => {
            schoolOptions.push({
              id: g.id,
              value: 'g' + g.id,
              label: g.name,
              isGroupHeader: true,
              group: g.id,
            });
            if (!hasOwnSchool) {
              schoolsFilter.push('g' + g.id);
            }

            activeSchools.forEach(a => {
              if (a.school_groups.includes(g.id)) {
                schoolOptions.push({
                  id: a.id,
                  value: a.id,
                  label: a.name,
                  group: g.id,
                });

                if (!hasOwnSchool) {
                  schoolsFilter.push(a.id);
                }
              }
            });
          });

          schoolOptions.push({
            id: 'g0',
            value: 'g0',
            label: 'No group',
            isGroupHeader: true,
            group: -1,
          });
          if (!hasOwnSchool) {
            schoolsFilter.push('g0');
          }

          activeSchools.forEach(a => {
            if (a.school_groups.length === 0) {
              schoolOptions.push({
                id: a.id,
                value: a.id,
                label: a.name,
                group: -1,
              });

              if (!hasOwnSchool) {
                schoolsFilter.push(a.id);
              }
            }
          });
        }

        if (hasOwnSchool && this.user.profile.school.id) {
          schoolsFilter = [this.user.profile.school.id];
        }

        this.setState({ schoolOptions, schoolsFilter }, this.getVacancies);
      });
    });
  };

  getRoles = () => {
    // if district user, only get roles for which the user is tagged
    axios
      .get('/api/search/districtroles', {
        params: {
          vacancy_dashboard: true,
          job_status_list: 'active_open,active_closed',
        },
      })
      .then(r => {
        let allRoles = r.data.results;
        let rolesFilter = allRoles.map(role => role.id);
        let roleOptions = allRoles.map(role => {
          return {
            value: role.id,
            label: role.title,
            ...role,
          };
        });
        this.setState({ roleOptions, rolesFilter });
      })
      .catch(err => {
        console.log(err);
      });
  };

  pushQueryLocation = newQuery => {
    const nextSearch = new URLSearchParams(this.props.location.search);
    Object.entries(newQuery).forEach(({ key, value }) => {
      nextSearch.set(key, value);
    });

    const nextLocation = {
      ...this.props.location,
      search: nextSearch.toString(),
    };

    this.props.history.push(location);
  };

  addIncomingVacancies = (existingVacancies, incomingVacancies) => {
    let finalVacancies = [...existingVacancies];
    incomingVacancies.forEach(incomingVacancy => {
      if (!finalVacancies.find(vacancy => this.vacanciesMatch(vacancy, incomingVacancy))) {
        finalVacancies.push(incomingVacancy);
      }
    });
    return finalVacancies;
  };

  addMonthToHistory = vacancyHistory => {
    return vacancyHistory.map(item => ({
      ...item,
      // show the word 'Today' for today's item on the chart
      monthAbbr: item.today ? 'Today' : monthNumberToAbbreviation[item.month],
    }));
  };

  sortHistoryInPlace = history => {
    history.sort((a, b) => {
      if (monthOrder[a.month] === monthOrder[b.month]) {
        // if months are equal, put the one with today=true last
        if (a.today) {
          return 1;
        } else {
          return -1;
        }
      }
      return monthOrder[a.month] - monthOrder[b.month];
    });
  };

  removeFutureMonths = history => {
    /** Remove entries for months that have not occurred yet this hiring season */
    // find index of the current month in history
    const todaysEntryIndex = history.findIndex(entry => entry.today === true);

    // set the count for this hiring season to null for all months that come after
    // todaysEntryIndex this hiring season
    const currentVacanciesFieldName = `unfilled_vacancies_${currentHiringSeason()}`;
    return history.map((item, index) => {
      if (index > todaysEntryIndex) {
        return {
          ...item,
          [currentVacanciesFieldName]: null,
        };
      } else {
        // return the unchanged item for months coming before today in the hiring season
        return item;
      }
    });
  };

  candidateIsOnAVacancy = candidate => {
    const foundCandidate = this.state.vacancies.find(
      vacancy => vacancy.candidate && vacancy.candidate.id === candidate.id
    );
    if (foundCandidate) {
      return true;
    } else {
      return false;
    }
  };

  removeCandidateFromVacancy = (vacancy, event) => {
    event.stopPropagation();
    const data = {
      is_district_position: this.isDistrictPosition(vacancy),
      candidate_id: null,
    };
    this.updateFillRateNumerator(vacancy.ftes * -1);
    this.patchVacancy(vacancy, data);
  };

  pairCandidateWithVacancy = (candidate_id, vacancy) => {
    const data = {
      is_district_position: this.isDistrictPosition(vacancy),
      candidate_id: candidate_id,
    };
    this.patchVacancy(vacancy, data);
    this.updateFillRateNumerator(vacancy.ftes);
  };

  isDistrictPosition = vacancy => {
    if (vacancy.school_role) {
      return false;
    } else {
      return true;
    }
  };

  isSchoolPosition = vacancy => {
    return !this.isDistrictPosition(vacancy);
  };

  updateFillRateNumerator = ftes => {
    this.setState(prevState => {
      const positionsNumerator = prevState.positionsNumerator + ftes;
      // Numerator should never be below zero.
      if (positionsNumerator >= 0) {
        return { positionsNumerator };
      }
    });
  };

  updateFillRateDenominator = ftes => {
    this.setState(prevState => {
      return {
        positionsDenominator: prevState.positionsDenominator + ftes,
      };
    });
  };

  postVacancy = async newVacancy => {
    axios
      .post('/api/positions/', newVacancy)
      .then(this.updateStateWithPostResponse)
      .catch(err => {
        console.log(err);
        if (err.response && err.response.data) {
          showWarning(err.response.data.detail, 7000);
        }
      });
  };

  putVacancy = async updatedVacancy => {
    axios
      .put(`/api/position/${updatedVacancy.id}/`, updatedVacancy)
      .then(this.updateStateWithResponse);
  };

  patchVacancy = async (vacancy, data) => {
    axios.patch(`/api/position/${vacancy.id}/`, data).then(response => {
      this.updateStateWithResponse(response);
      this.fetchUnplacedCandidates();
    });
  };

  deleteVacancy = async vacancy => {
    if (vacancy.school_role) {
      return this.deleteSchoolVacancy(vacancy);
    } else {
      return this.deleteDistrictVacancy(vacancy);
    }
  };

  deleteSchoolVacancy = async vacancy => {
    return axios.delete(`/api/schoolposition/${vacancy.id}/`).then(() => {
      this.handlePostDelete(vacancy);
      this.fetchUnplacedCandidates();
    });
  };

  deleteDistrictVacancy = async vacancy => {
    return axios.delete(`/api/position/${vacancy.id}/`).then(() => {
      this.handlePostDelete(vacancy);
      this.fetchUnplacedCandidates();
    });
  };

  updateStateWithPostResponse = response => {
    const updatedVacancy = response.data;
    this.addVacancyToState(updatedVacancy);
    if (this.isSchoolPosition(updatedVacancy)) {
      this.handleRelatedDistrictVacancy(updatedVacancy);
    }
    this.updateFillRateDenominator(updatedVacancy.ftes);
  };

  handleRelatedDistrictVacancy = vacancy => {
    const district_role_id = vacancy.district_role.id;
    const relatedDistrictVacancy = this.getRelatedDistrictVacancy(district_role_id);
    if (relatedDistrictVacancy) {
      this.removeRelatedDistrictVacancy(vacancy);
    }
  };

  getRelatedDistrictVacancy = district_role_id => {
    return this.state.vacancies.find(
      vacancy => vacancy.district_role.id === district_role_id && !vacancy.school_role
    );
  };

  removeRelatedDistrictVacancy = vacancy => {
    if (vacancy.candidate) {
      this.updateFillRateNumerator(vacancy.ftes * -1);
    }
    this.updateFillRateDenominator(vacancy.ftes * -1);
    this.deleteVacancyFromState(vacancy);
  };

  updateStateWithResponse = response => {
    const updatedVacancy = response.data;
    this.updateFillRateIfNecessary(updatedVacancy);
    this.updateVacancyInContainerState(updatedVacancy);
  };

  updateFillRateIfNecessary = vacancy => {
    const fillRateDifference = this.getFillRateDifference(vacancy);
    if (fillRateDifference) {
      if (vacancy.candidate) {
        this.updateFillRateNumerator(fillRateDifference);
      }
      this.updateFillRateDenominator(fillRateDifference);
    }
  };

  getFillRateDifference = newVacancy => {
    const oldVacancy = this.state.vacancies.find(vacancy =>
      this.vacanciesMatch(vacancy, newVacancy)
    );
    const oldFTES = oldVacancy.ftes;
    const newFTES = newVacancy.ftes;
    const difference = newFTES - oldFTES;
    return difference;
  };

  handlePostDelete = vacancy => {
    this.deleteVacancyFromState(vacancy);
    if (vacancy.candidate) {
      this.updateFillRateNumerator(vacancy.ftes * -1);
    }
    this.updateFillRateDenominator(vacancy.ftes * -1);
  };

  decrementTotalLineItems = () => {
    this.setState(prevState => {
      const totalLineItems = prevState.totalLineItems - 1;
      if (totalLineItems >= 0) {
        return { totalLineItems };
      }
    });
  };

  incrementTotalLineItems = () => {
    this.setState(prevState => {
      return {
        totalLineItems: prevState.totalLineItems + 1,
      };
    });
  };

  addVacancyToState = updatedVacancy => {
    const vacancies = [...this.state.vacancies];
    vacancies.push(updatedVacancy);
    this.setState({ vacancies });
    this.incrementTotalLineItems();
  };

  updateVacancyInContainerState = updatedVacancy => {
    const vacancies = [...this.state.vacancies];
    const vacancyIndexInState = vacancies.findIndex(vacancy =>
      this.vacanciesMatch(vacancy, updatedVacancy)
    );
    if (vacancyIndexInState !== -1) {
      vacancies[vacancyIndexInState] = updatedVacancy;
      this.setState({ vacancies });
    }
  };

  deleteVacancyFromState = deletedVacancy => {
    const updateVacancies = this.state.vacancies.filter(vacancy =>
      this.vacanciesDontMatch(vacancy, deletedVacancy)
    );
    this.setState({ vacancies: updateVacancies });
    this.decrementTotalLineItems();
  };

  vacanciesDontMatch = (vacancy1, vacancy2) => {
    return !this.vacanciesMatch(vacancy1, vacancy2);
  };

  vacanciesMatch = (vacancy1, vacancy2) => {
    // two vacancies in state could theoretically have the same ID, with one
    // being a school vacancy and one being a district vacancy (sigh). So we
    // have to check the school_roles too.
    return (
      this.districtVacanciesMatch(vacancy1, vacancy2) ||
      this.schoolVacanciesMatch(vacancy1, vacancy2)
    );
  };

  districtVacanciesMatch = (vacancy1, vacancy2) => {
    return vacancy1.id === vacancy2.id && !vacancy1.school_role && !vacancy2.school_role;
  };

  schoolVacanciesMatch = (vacancy1, vacancy2) => {
    return vacancy1.id === vacancy2.id && vacancy1.school_role && vacancy2.school_role;
  };

  updateMultiSelectFilter = (fieldName, fieldValue, active) => {
    /** this.state[fieldName] should always be an array. If active is true,
     * the filter option that was clicked is active and should be turned off, which means we
     * have to remove fieldValue from the array instead of adding it.
     */
    let updatedField;

    /** As of early 2021, schools are now groups, so if fieldValue is an array, it's a multiple row update. */
    if (Array.isArray(fieldValue)) {
      // Group headers have value null, and we don't use them in subcategories:
      if (fieldName === 'subcategoriesFilter') fieldValue = fieldValue.filter(i => i !== null);
      if (active) {
        // Turning off a group (remove ids):
        // N/A is a special case:
        if (fieldValue.includes('N/A')) {
          updatedField = this.state[fieldName].filter(e => e !== 'N/A');
        } else {
          updatedField = this.state[fieldName].filter(e => !fieldValue.includes(e));
        }
      } else {
        // Turning on a group (add ids):
        // N/A is a special case:
        if (fieldValue.includes('N/A')) {
          updatedField = [...this.state[fieldName], 'N/A'];
        } else {
          updatedField = [...this.state[fieldName], ...fieldValue];
        }
      }
    } else {
      if (active) {
        // filter is active and the user wants to turn it off. Find the element and remove.
        updatedField = this.state[fieldName].filter(e => e !== fieldValue);
      } else {
        updatedField = this.state[fieldName];
        updatedField.push(fieldValue);
      }
    }

    this.setState({ [fieldName]: updatedField }, () => {
      this.search();
      if (fieldName === 'hiringSeasonFilter') {
        // changes to the hiring season filter will affect the unplaced candidates list,
        // so refetch on changes to that filer.
        this.fetchUnplacedCandidates();
      }
    });
  };

  onSelectAllChoices = (fieldName, options) => {
    const allChoiceIds = options.map(o => o.value);
    this.setState({ [fieldName]: allChoiceIds }, () => {
      this.search();
      if (fieldName === 'hiringSeasonFilter') {
        // changes to the hiring season filter will affect the unplaced candidates list,
        // so refetch on changes to that filer.
        this.fetchUnplacedCandidates();
      }
    });
  };

  onClearAllChoices = fieldName => {
    this.setState({ [fieldName]: [] }, () => {
      this.search();
      if (fieldName === 'hiringSeasonFilter') {
        // changes to the hiring season filter will affect the unplaced candidates list,
        // so refetch on changes to that filer.
        this.fetchUnplacedCandidates();
      }
    });
  };

  resetFilters = () => {
    this.setState(
      {
        schoolsFilter: [],
        subcategoriesFilter: [],
        rolesFilter: [],
      },
      () => {
        this.search();
        this.fetchUnplacedCandidates();
      }
    );
  };

  loadingMessage = () => {
    /** Spinner and message at bottom of page. Only show if at least one fetch has completed
     * (fetchVacanciesCompleted) and another fetch is currently running (fetchVacanciesRunning)
     * and (more).
     */
    if (
      this.state.fetchVacanciesCompleted &&
      this.state.fetchVacanciesRunning &&
      this.state.hasMore
    ) {
      return (
        <div className="candidateslist-loading-message-div mt2">
          <i className="fa fa-circle-o-notch fast-spin fa-2x fa-fw" />
        </div>
      );
    } else {
      return null;
    }
  };

  sortBy = field => {
    this.setState(prevState => {
      const newState = {
        page: 0,
        hasMore: false,
      };
      if (field === prevState.orderBy) {
        // if the user clicked on the field that's already sorted, reverse the order.
        newState['orderDescending'] = !prevState.orderDescending;
      } else {
        newState['orderBy'] = field;
      }
      return newState;
    }, this.getVacancies);
  };

  render() {
    return (
      <Dashboard
        adminUsers={this.state.adminUsers}
        unplacedCandidates={this.state.unplacedCandidates}
        vacancies={this.state.vacancies}
        loadingSpinner={this.state.loadingSpinner}
        loadingMessage={this.loadingMessage}
        getVacanciesDebounced={this.getVacanciesDebounced}
        search={this.search}
        hasMore={this.state.hasMore}
        resetFilters={this.resetFilters}
        positionsNumerator={this.state.positionsNumerator}
        positionsDenominator={this.state.positionsDenominator}
        currentSeasonObj={currentSeasonObj}
        schoolsFilter={this.state.schoolsFilter}
        subcategoriesFilter={this.state.subcategoriesFilter}
        rolesFilter={this.state.rolesFilter}
        hiringSeasonFilter={this.state.hiringSeasonFilter}
        schoolOptions={this.state.schoolOptions}
        roleOptions={this.state.roleOptions}
        subcategoryOptions={this.state.subcategories}
        hiringSeasonOptions={hiringSeasons(true, true)}
        updateMultiSelectFilter={this.updateMultiSelectFilter}
        onSelectAllChoices={this.onSelectAllChoices}
        onClearAllChoices={this.onClearAllChoices}
        filteredHiringSeasons={this.filteredHiringSeasons}
        removeCandidateFromVacancy={this.removeCandidateFromVacancy}
        deleteVacancy={this.deleteVacancy}
        pairCandidateWithVacancy={this.pairCandidateWithVacancy}
        vacancyHistory={this.state.vacancyHistory}
        updateVacancyInContainerState={this.updateVacancyInContainerState}
        postVacancy={this.postVacancy}
        putVacancy={this.putVacancy}
        sortBy={this.sortBy}
        totalLineItems={this.state.totalLineItems}
      />
    );
  }
}

export default withRouter(DashboardContainer);
