export default {
  control: {
    backgroundColor: '#fff',
    marginBottom: '15px',
    height: '100px',
    overflow: 'auto',
    border: '0.5px solid #d7d7d7',
    borderRadius: '3px',
    marginTop: '6px'
  },

  highlighter: {
    overflow: 'auto'
  },

  '&multiLine': {
    input: {
      padding: 9,
      minHeight: 80,
      outline: 0,
      border: 0,
      overflow: 'auto'
    }
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      width: 225,
      maxHeight: 200,
      overflow: 'auto',
      position: 'absolute',
      bottom: 14,
      left: -19,
      boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.1)'
    },

    item: {
      padding: '8px',
      '&focused': {
        backgroundColor: '#f5f5f5'
      }
    }
  }
};
