import SearchIcon from '@mui/icons-material/Search';
import { Box, styled } from '@mui/material';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { Input } from 'sharedComponents/Input';

export interface SearchBarProps {
  placeholder: string;
  onInputChange: (value: string) => void;
  value?: string;
  dataTestId?: string;
  onSearch: (concatBool: boolean) => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  onSearch,
  onInputChange,
  placeholder,
  value,
  dataTestId,
}) => {
  const onKeyPress = event => {
    if (event.key === 'Enter') {
      onSearch(false);
    }
  };

  const handleInputChange = event => {
    onInputChange(event.target.value);
  };

  return (
    <Container>
      <Input
        placeholder={placeholder}
        value={value}
        onChange={handleInputChange}
        InputProps={{
          style: {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderRight: 'transparent',
          },
        }}
        testid={dataTestId}
        onKeyPress={onKeyPress}
      />
      <PrimaryButton
        onClick={() => onSearch(false)}
        sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, height: '53px' }}
      >
        <SearchIcon />
      </PrimaryButton>
    </Container>
  );
};

const Container = styled(Box)({
  backgroundColor: 'white',
  display: 'flex',
});
