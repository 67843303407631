import IconNotes from 'assets/icon_notes.svg';
import InternalCandidateNotes from 'components/Profile/InternalCandidateNotes';
import { InternalNotesProps } from './types';

const InternalNotes: React.FC<InternalNotesProps> = ({
  adminUsers,
  handleUpdateInternalCandidateNotes,
  notes,
}) => {
  return (
    <div className={`profile-section`}>
      <h4 className="section-header mb1 mr1 inline-block">Internal Notes</h4>
      <div className="profile-section-icon-div">
        <img src={IconNotes} alt="internal notes" />
      </div>
      <InternalCandidateNotes
        notes={notes ?? []}
        updateNote={handleUpdateInternalCandidateNotes}
        adminUsers={adminUsers}
      />
    </div>
  );
};

export default InternalNotes;
