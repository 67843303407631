import { Component } from 'react';
import PropTypes from 'prop-types';
import auth from 'utils/auth';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';

import { AltModal } from 'ui-kit';
import { getApplicationForViewByPosting } from 'utils/candidateUtils';
import ScorecardEditor from './ScorecardEditor';
import ScorecardList from './ScorecardList/scorecardlist';
import ScorecardViewer from './scorecardviewer';
import ScorecardNewRequest from './ScorecardNewRequest';

import usersAPI from 'api/usersAPI';
import scorecardAPI from 'api/scorecardAPI';
import CustomScorecardListAPI from 'api/customScorecardListAPI';
import { withRouter } from 'react-router-dom';

const LIST = 'list';
const CREATE = 'create';
const VIEW = 'view';
const EDIT = 'edit';
const COMPLETING = 'completing';
const REQUEST = 'request';

class ScorecardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: LIST,
      scorecard: {}, // scorecard containing answers to customScorecard questions
      customScorecards: [], // list of all custom scorecards for a district
      scorecards: [],
      requests: [],
      loading: false,
      requestSendingSpinner: false,
      requestSuccessful: false,
      lastSavedTime: null,
      defaultScorecardId: null,
    };
    this.user = auth.getUser();
    this.isSchoolUser = auth.isSchoolUser();
    this.districtId = this.user.profile.district.id;
  }

  static propTypes = {
    user: PropTypes.object.isRequired,
    application: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    emailTaggedUsers: PropTypes.func,
    // if this is passed down, automatically open that scorecard on page load
    scorecardParam: PropTypes.number,
    scorecardSeen: PropTypes.bool, // dont auto-open the scorecard if the user has already seen it
    removeLocationQuery: PropTypes.func,
    incrementScorecardCount: PropTypes.func,
    adminUsers: PropTypes.array.isRequired,
  };

  static defaultProps = {
    incrementScorecardCount: () => {},
  };

  componentDidMount() {
    this.fetchScorecards();
    this.fetchRequests();
    this.fetchCustomScorecards();
    this.getUserDefaultScorecardId();
  }

  componentWillUnmount() {
    this.ignoreLastFetch = true;
  }

  close = () => {
    this.setState({ mode: LIST, scorecard: null, requestSuccessful: false });
    this.props.onHide();
  };

  sendRequest = ({ recipients, custom_scorecard_id, custom_message, is_reminder }) => {
    this.setState({ requestSendingSpinner: true });
    axios
      .post(`/api/scorecardrequests/${this.props.user.id}/`, {
        recipients: recipients,
        custom_scorecard_id,
        message: custom_message,
        candidate_id: this.props.user.id,
        application_id: this.props.application.id,
        // kind of a hack, but application_id can be either a district
        // application id or a schoolapplication id depending on who's sending
        // it, so send clarifying information here
        is_school_application: this.props.application.role.district_role ? true : false,
        is_reminder,
      })
      .then(() => {
        this.fetchRequests();
        this.setState({ requestSendingSpinner: false, requestSuccessful: true });
        setTimeout(() => {
          if (!this.ignoreLastFetch) {
            this.removeCheckmark();
          }
        }, 5000);
      })
      .catch(err => {
        console.error(err);
        this.setState({ requestSendingSpinner: false });
      });
  };

  deleteScorecard = scorecardId => {
    scorecardAPI.destroy(scorecardId);
    let scorecards = this.state.scorecards;
    let index = scorecards.findIndex(s => s.id === scorecardId);
    if (index > -1) {
      scorecards.splice(index, 1);
    }
    this.setState({ scorecards, mode: LIST });
    this.props.incrementScorecardCount(this.props.user.id, -1);
  };

  getUserDefaultScorecardId = () => {
    return usersAPI
      .getDefaultScorecardId(this.user.id)
      .then(defaultScorecardId => this.setState({ defaultScorecardId }));
  };

  fetchCustomScorecards = () => {
    const role = this.props.application.role.district_role
      ? this.props.application.role.district_role
      : this.props.application.role.id;

    CustomScorecardListAPI.fetchCustomScorecardsByRole(role, true)
      .then(customScorecards => {
        if (!this.ignoreLastFetch) {
          this.setState({ customScorecards });
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  fetchRequests() {
    const applicationId = this.props.application.district_application
      ? this.props.application.district_application.id
      : this.props.application.id;

    axios.get(`/api/scorecardrequests/${this.props.user.id}/`).then(r => {
      if (!this.ignoreLastFetch) {
        this.setState({
          requests: r.data.filter(request => {
            return !request.application_id || request.application_id === applicationId;
          }),
        });
      }
    });
  }

  fetchScorecards = () => {
    this.setState({ loading: true });

    const applicationId = this.props.application.district_application
      ? this.props.application.district_application.id
      : this.props.application.id;

    usersAPI.getApplicationsForScorecards(this.props.user.id).then(applications => {
      usersAPI.fetchScorecards(this.props.user.id).then(scorecards => {
        if (!this.ignoreLastFetch) {
          const { scorecardParam, scorecardSeen, removeLocationQuery } = this.props;
          if (scorecardParam !== null && scorecardSeen === false) {
            // Automatically open scorecard if the user is coming from a 'tagged Scorecard' email
            const scorecard = scorecards.find(s => s.id === scorecardParam);
            if (scorecard) {
              this.setState(
                {
                  scorecard,
                  mode: VIEW,
                },
                () => removeLocationQuery('scorecard')
              );
            }
          }
          this.setState({
            scorecards: scorecards
              .map(scorecard => {
                return {
                  ...scorecard,
                  roleOptions: applications,
                };
              })
              .filter(scorecard => {
                return scorecard.is_universal || scorecard.application === applicationId;
              }),
            loading: false,
          });
        }
      });
    });
  };

  updateScorecardsList = scorecard => {
    this.setState(
      {
        scorecards: this.state.scorecards
          .map(s => {
            if (s.id === scorecard.id) {
              return { ...s, ...scorecard, roleOptions: this.props.user.applications };
            }
            return { ...s, roleOptions: this.props.user.applications };
          })
          .filter(scorecard => {
            return scorecard.is_universal || scorecard.application === this.props.application.id;
          }),
      },
      () => {
        console.log(this.state.scorecards, scorecard.id);
      }
    );
  };

  onBack = () => {
    this.setState({ mode: LIST, scorecard: {} });
  };

  showRequestSection = () => {
    this.setState({ mode: REQUEST });
  };

  removeCheckmark = () => {
    this.setState({ requestSuccessful: false });
  };

  onCreate = () => {
    if (this.state.customScorecards.length === 0) {
      return;
    }

    this.setState({
      scorecard: {},
      mode: CREATE,
    });
  };

  onEdit = scorecard => {
    this.setState({
      mode: EDIT,
      scorecard,
    });
  };

  onComplete = scorecard => {
    this.setState({
      mode: COMPLETING,
      scorecard,
    });
  };

  onView = scorecard => {
    this.setState({
      mode: VIEW,
      scorecard,
    });
  };

  scorecardPostSave = isAutosave => {
    if (isAutosave) {
      this.setState({ lastSavedTime: moment().format('MM/D/YYYY [at] hh:mm') });
    } else {
      this.fetchScorecards();
      this.setState({ mode: LIST });
    }
  };

  emailTaggedUsers = (recipientList, message, type, id) => {
    // Type is either 'note' or 'scorecard'. For display purposes.
    const tagObjects = [];

    recipientList.forEach(recipient => {
      const url = this.getUrl(recipient, type, id);
      const tagObj = {
        user_id: recipient.id,
        location: `in a ${type}`,
        note_url: url,
        note_copy: message,
        candidate_name: this.props.user.name,
      };
      tagObjects.push(tagObj);
    });

    this.sendTagNotifications(tagObjects);
  };

  getUrl = (recipient, type, id) => {
    const host = window.location.hostname;
    const colon = host.indexOf('local') !== -1 ? ':' : '';
    const port = window.location.port;
    const location = `${window.location.protocol}//${host}${colon}${port}`;

    const recipientUrlPrefix = `${recipient.profile.user_type >= 30 ? 'district' : 'school'}`;
    const application = getApplicationForViewByPosting(this.props.user, this.isSchoolUser);
    // If a param is present the scorecard modal will open when the user visits the page.
    let extraParam = `&scorecard=${id}`;

    // hacky, but the best (and maybe only?) way to tell if we're linking to a district
    // application or school application is to check the district_role property.
    if (application.role?.district_role) {
      // viewing a schoolapplication, so must be school admin
      return `${location}/school/school-profile/${this.props.user.id}?s_pk=${application.id}${extraParam}`;
    } else {
      // viewing a district application, can be any user type other than candidate
      return `${location}/${recipientUrlPrefix}/profile/${this.props.user.id}?application=${application.id}${extraParam}`;
    }
  };

  sendTagNotifications = tagObjects => {
    return axios.post('/api/send_tag_notifications/', tagObjects);
  };

  render() {
    return (
      <AltModal.AltModal isOpen={this.props.show} onClose={this.props.onHide}>
        <ScorecardListHeader candidate={this.props.user} lastSavedTime={this.state.lastSavedTime} />
        {this.state.mode === LIST && (
          <ScorecardList
            scorecards={this.state.scorecards}
            requests={this.state.requests}
            onCreate={this.onCreate}
            onComplete={this.onComplete}
            onView={this.onView}
            onCancel={this.props.onHide}
            onRequest={this.sendRequest}
            loading={this.state.loading}
            showRequestSection={this.showRequestSection}
            updateScorecardsList={this.updateScorecardsList}
            canCreateScorecards={!!this.state.customScorecards.length}
          />
        )}
        {this.state.mode === REQUEST && (
          <ScorecardNewRequest
            sendRequest={this.sendRequest}
            showRequestSection={this.showRequestSection}
            customScorecards={this.state.customScorecards}
            user={this.props.user}
            emailTemplatesList={this.props.emailTemplatesList}
            application={this.props.application}
            removeCheckmark={this.removeCheckmark}
            requestSuccessful={this.state.requestSuccessful}
            requestSendingSpinner={this.state.requestSendingSpinner}
            onBack={this.onBack}
            adminUsers={this.props.adminUsers}
          />
        )}
        {this.state.mode === VIEW && (
          <ScorecardViewer
            scorecard={this.state.scorecard}
            onBack={this.onBack}
            onEdit={this.onEdit}
          />
        )}
        {[EDIT, COMPLETING, CREATE].includes(this.state.mode) && (
          <ScorecardEditor
            mode={this.state.mode}
            scorecardToEdit={this.state.scorecard}
            // TODO: filter templates when permissions implemented
            customScorecards={this.state.customScorecards}
            adminUsers={this.props.adminUsers}
            onBack={this.onBack}
            deleteScorecard={this.deleteScorecard}
            onSubmitPage={false}
            scorecardPostSave={this.scorecardPostSave}
            emailTaggedUsers={this.emailTaggedUsers}
            candidate={this.props.user}
            incrementScorecardCount={this.props.incrementScorecardCount}
            defaultScorecardId={this.state.defaultScorecardId}
            application={
              this.props.application.district_application
                ? this.props.application.district_application.id
                : this.props.application.id
            }
          />
        )}
      </AltModal.AltModal>
    );
  }
}

export default withRouter(ScorecardModal);

const ScorecardListHeader = ({ candidate, lastSavedTime }) => (
  <AltModal.Title>
    <Header>
      Scorecards
      {candidate.name
        ? ` for ${candidate.name}`
        : candidate.first_name && candidate.last_name
        ? `for ${candidate.first_name} ${candidate.last_name}`
        : null}
    </Header>
    {lastSavedTime && <LightGreyText>(Last saved on {lastSavedTime})</LightGreyText>}
  </AltModal.Title>
);

const Header = styled.p`
  font-size: 20px;
  color: #000000;
`;

const LightGreyText = styled.span`
  font-size: 14px;
  color: #999999;
  margin-left: 26px;
`;
