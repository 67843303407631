import './interviewcard.css';

import { Component } from 'react';

import EmailModal from './EmailModal';
import InterviewModal from './interviewmodal';
import PropTypes from 'prop-types';
import ScorecardModal from './ScorecardModal';
import iconClock from '../assets/icon-clock.svg';
import moment from 'moment';
import auth from '../utils/auth';
import { scorecardType } from '../utils/enums';

export default class InterviewCard extends Component {
  static propTypes = {
    interview: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      interviewModalOpen: false,
      scorecardModalOpen: false,
    };
    this.isSchoolUser = auth.isSchoolUser();
  }

  onSave = (sendEmail, when) => {
    this.setState({
      interviewModalOpen: false,
    });
  };

  onChange = sendEmail => {
    this.setState({
      interviewModalOpen: false,
      emailModalOpen: sendEmail,
    });

    if (!sendEmail) {
      this.props.onChange();
    }
  };

  onSendEmail = () => {
    this.setState({ emailModalOpen: false });
    this.props.onChange();
  };

  onScorecardChange = () => {
    this.setState({ scorecardModalOpen: false });
    this.props.onChange();
  };

  getInterviewType(type) {
    switch (type) {
      case 0:
        return 'Phone Screen';
      case 1:
        return 'On Site';
      case 2:
        return 'Video Questions';
      default:
        return 'Other';
    }
  }

  render() {
    const now = new Date().getTime();
    const when = new Date(this.props.interview.when).getTime();
    const hasScorecards = this.props.interview.scorecards > 0;
    const completed = when < now;
    const reviewInterview = when < now + 30 * 60 * 1000;
    const prefix = this.isSchoolUser ? 'school' : '';

    return (
      <div className="interview-card">
        <div className="interview-card-top">
          <div className="pull-right">
            Interview Type
            <br />
            <span className="interview-card-subtext">
              {this.getInterviewType(this.props.interview.type)}
            </span>
          </div>
          <div>
            {this.props.interview[`${prefix}application`].candidate.name}
            <br />
            <img alt="icon-clock" src={iconClock} style={{ paddingRight: '6px' }} />
            <span className="interview-card-subtext">
              {moment.utc(this.props.interview.when).fromNow()}
            </span>
          </div>
        </div>
        <div>
          <div className="pull-right">
            <div
              className="btn interview-card-cancel"
              onClick={() => this.setState({ interviewModalOpen: true })}
              style={{
                visibility: completed ? 'hidden' : 'visible',
              }}
            >
              x cancel/reschedule
            </div>
            {!hasScorecards && reviewInterview && (
              <button
                className="btn interview-card-review"
                onClick={() => this.setState({ scorecardModalOpen: true })}
              >
                Review Interview
              </button>
            )}
            {hasScorecards && (
              <button
                className="btn interview-card-review"
                onClick={() => this.setState({ scorecardModalOpen: true })}
              >
                Edit Review
              </button>
            )}
          </div>
          <div>
            Applied For
            <br />
            <span className="interview-card-subtext">
              {this.props.interview[`${prefix}application`].role.title}
              <br />
              {(this.props.interview[`${prefix}application`].role.school &&
                this.props.interview[`${prefix}application`].role.school.name) ||
                this.props.interview[`${prefix}application`].role.district.name}
            </span>
          </div>
        </div>
        <InterviewModal
          application={this.props.interview[`${prefix}application`]}
          show={this.state.interviewModalOpen}
          onHide={() => this.setState({ interviewModalOpen: false })}
          onSave={this.onChange}
          onCancel={this.onChange}
        />
        <EmailModal
          recipientList={[this.props.interview[`${prefix}application`].candidate]}
          show={this.state.emailModalOpen}
          onHide={this.onSendEmail}
          onSend={this.onSendEmail}
          subject="Your interview has been changed."
          message="[interview details]"
        />
        <ScorecardModal
          user={this.props.interview[`${prefix}application`].candidate}
          application={this.props.interview[`${prefix}application`]}
          show={this.state.scorecardModalOpen}
          onHide={this.onScorecardChange}
          scorecard_type={scorecardType.scorecard}
        />
      </div>
    );
  }
}
