import { CumulativeScoreAnswerProps } from './types';

const CumulativeScoreAnswer: React.FC<CumulativeScoreAnswerProps> = ({ parentObject }) => {
  return (
    <p>
      <i>
        {parentObject.cumulative_score} out of {parentObject.max_cumulative_score} possible points
      </i>
    </p>
  );
};

export default CumulativeScoreAnswer;
