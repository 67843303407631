import styled from 'styled-components';
import { border, color, layout, space, typography, variant, flexbox } from 'styled-system';

/**
 * Boring button component that offers some variants defined in ui-kit/theme
 *
 * Usage:
 * <Button>Click Me!<Button>
 * <Button variant='primary'>Click Me!</Button>
 * <Button variant='secondary'>Click Me!</Button>
 */
export const Button = styled('button')(
  border,
  color,
  layout,
  space,
  typography,
  flexbox,
  variant({ scale: 'buttons' })
);

Button.defaultProps = {
  // This default padding is just what's used on the settings page,
  // likely not a good default prop going forward.
  padding: '0.85em 2.5em',
  borderRadius: '2px',
  fontSize: '14px'
};
