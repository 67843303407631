import { Component } from 'react';

import ProgressArc from 'progress-arc-component';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { typography } from 'styled-system';

import { theme } from 'ui-kit';

export default class VacanciesWidget extends Component {
  static propTypes = {
    filled: PropTypes.number,
    vacancies: PropTypes.number,
  };

  render() {
    let percent = Math.round((this.props.filled / this.props.vacancies) * 100 * 10) / 10 || 0;

    const vacanciesNumberToReturn = number => {
      if (Number.isInteger(number)) {
        return number;
      } else {
        return Number(number).toFixed(1);
      }
    };

    return (
      <div className="vacancies-section">
        <VacanciesProgressArc
          className="progress-circle"
          value={percent}
          arcColor={theme.uiColors.blues.full}
          arcBackgroundColor={theme.uiColors.reds.full}
          textColor={theme.uiColors.black}
          textVisible={false}
          radius={90}
        />
        <div className="progress-circle-label">
          <div className={`progress-circle-percent`}>{percent}%</div>
        </div>
        <div className="progress-circle-key">
          <GraphLabelLine>
            <StatusCircle color={theme.uiColors.blues.full} />
            <div>
              <GraphLabelNumber className="filled-vacancies">{this.props.filled}</GraphLabelNumber>
            </div>
            <div>
              &nbsp;
              {this.props.vacancies === 1 ? 'Filled vacancy' : 'Filled vacancies'}
            </div>
          </GraphLabelLine>
          <GraphLabelLine>
            <StatusCircle color={theme.uiColors.reds.full} />
            <div>
              <GraphLabelNumber className="unfilled-vacancies">
                {vacanciesNumberToReturn(this.props.vacancies - this.props.filled)}
              </GraphLabelNumber>
            </div>
            <div>
              &nbsp;
              {this.props.filled === 1 ? 'Unfilled vacancy' : 'Unfilled vacancies'}
            </div>
          </GraphLabelLine>
          <br />
          <GraphLabelLine>
            <TotalGraphLine>
              <GraphLabelNumber>{this.props.vacancies}</GraphLabelNumber>
            </TotalGraphLine>
            <GraphLabelNumber textAlign="left">Total vacancies</GraphLabelNumber>
          </GraphLabelLine>
        </div>
      </div>
    );
  }
}

const VacanciesProgressArc = styled(ProgressArc)`
  height: 11em !important;
  circle {
    stroke-width: 21;
  }
  text {
    font-size: 42px;
    font-weight: normal;
  }
`;

const GraphLabelLine = styled.div`
  font-size: 15px;
  display: grid;
  grid-template-columns: 35px 45px auto;
  margin-bottom: 8px;
`;

const TotalGraphLine = styled.div`
  grid-column-start: 2;
`;

const GraphLabelNumber = styled.div`
  font-weight: 800;
  text-align: center;

  ${typography}
`;

const StatusCircle = styled.span`
  display: inline-block;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  min-width: 8px;
  margin-right: 8px;
  margin-top: 4px;
  background-color: ${props => props.color};
`;
