import Stack from '@mui/material/Stack';
import { SecondaryButton } from 'sharedComponents/Buttons';
import { Vacancy } from 'types/marketplaceV2Types';
import { VacanciesSectionProps } from '../types';
import { VacancyRow } from './VacancyRow';
import { NoVacanciesSection } from './VacancyRow';
import ArrowForwardIosOutlined from '@mui/icons-material/ArrowForwardIosOutlined';
import { moreInfoOnClickHandler } from 'features/MarketplaceV2/features/ExpressInterest/utils';

export const VacanciesSectionButtonRows: React.FC<VacanciesSectionProps> = ({
  vacancies,
  actions,
  school,
}) => {
  if (vacancies?.length === 0) {
    return <NoVacanciesSection />;
  }

  return (
    <>
      <Stack direction={'column'} width={'100%'}>
        {vacancies?.map((vacancy: Vacancy) => {
          return (
            <SecondaryButton
              key={vacancy.uuid}
              onClick={() => moreInfoOnClickHandler(school, actions, vacancy.uuid)}
              sx={{
                margin: '4px 0px',
                justifyContent: 'space-between',
                textAlign: 'left',
                border: '1px solid #EAECF0',
                borderRadius: '8px',
                width: '100%',
                padding: '0px 12px 0px 0px',
              }}
              dataTestId="connect-job-card"
            >
              <VacancyRow vacancy={vacancy} />
              <ArrowForwardIosOutlined
                fontSize="small"
                sx={{ color: '#667085', alignSelf: 'center' }}
              />
            </SecondaryButton>
          );
        })}
      </Stack>
    </>
  );
};
