import { useEffect, useRef, useMemo } from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const portalRoot = document.getElementById('portal-root');

export const BaseModal = ({ children, large, small }) => {
  const modalRoot = useRef(null);
  const modalRef = useRef(null);

  if (modalRoot.current === null) {
    modalRoot.current = document.createElement('div');
    modalRoot.current.style.display = 'contents';
  }

  useEffect(() => {
    const modalEl = modalRef.current;

    portalRoot.appendChild(modalRoot.current);
    disableBodyScroll(modalEl);

    return () => {
      enableBodyScroll(modalEl);
      modalRoot.current.remove();
    };
  }, []);

  return (
    modalRoot.current &&
    ReactDOM.createPortal(
      <Overlay onClick={e => e.stopPropagation()}>
        <Container ref={modalRef} large={large} small={small}>
          {children}
        </Container>
      </Overlay>,
      modalRoot.current
    )
  );
};

const Overlay = styled.div`
  z-index: 100;

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.5);
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Container = styled.div`
  z-index: 101;

  position: absolute;
  top: 0;

  background-color: #ffffff;

  width: ${({ large, small }) => (large ? '1200px' : small ? '512px' : '850px')};
  border-radius: 3px;
  margin: 32px 0;

  @media screen and (max-width: ${({ large }) => (large ? '1200px' : '850px')}) {
    max-width: 95vw;
    margin: 16px 0;
  }

  animation: ${fadeIn} 300ms ease-in-out;
`;
