import { Component } from 'react';
import PropTypes from 'prop-types';
import { languageList, languageProficiency } from '../../../../utils/enums';

export default class Languages extends Component {
  static propTypes = {
    languages: PropTypes.array.isRequired,
  };

  renderLanguagePills(index, languageId, languageProficiencyId) {
    const { label } = languageList().find(l => l.value === languageId) || '';
    const { label: status } =
      languageProficiency().find(l => l.value === languageProficiencyId) || {};

    return (
      <span className="pill" key={index}>
        {`${label} ${label && status ? '-' : ''} ${status || ''}`}
      </span>
    );
  }

  renderSimpleListItem(index, languageId, languageProficiencyId, lengthOfArray) {
    const { label } = languageList().find(l => l.value === languageId) || '';
    const { label: status } =
      languageProficiency().find(l => l.value === languageProficiencyId) || {};

    const comma = index + 1 === lengthOfArray ? '' : ', ';

    return (
      <span className="" key={index}>
        {`${label} ${label && status ? '(' : ''}${status || ''}${
          label && status ? ')' : ''
        }${comma}`}
      </span>
    );
  }

  render() {
    const { languages } = this.props;
    return (
      <span className="">
        {languages.length > 0
          ? languages.map((language, index) =>
              this.renderSimpleListItem(index, language.language, language.status, languages.length)
            )
          : 'None'}
      </span>
    );
  }
}
