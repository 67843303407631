import { Box, TextField } from '@mui/material';

import { BlankModal } from 'sharedComponents/Modal';
import { JobClosureModalProps } from './types';
import { PrimaryButton, SecondaryButton } from 'sharedComponents/Buttons';
import { ATSJobClosureNotificationTestIds } from 'data-testids/ATS';

export const JobClosureModal: React.FC<JobClosureModalProps> = ({
  handleAdd,
  handleCloseModal,
  handleEdit,
  handleThresholdChange,
  hasExistingNotifications,
  isLoading,
  isModalActive,
  thresholdValue,
}) => {
  return (
    <BlankModal
      dataTestId={ATSJobClosureNotificationTestIds.MODAL}
      open={isModalActive}
      onClose={handleCloseModal}
      header={
        <Box data-testid={ATSJobClosureNotificationTestIds.MODAL_TITLE}>
          {hasExistingNotifications ? 'Edit Notification' : 'Create a Job Closure Notification'}
        </Box>
      }
      body={
        <Box>
          <Box>Send an email that this job is ready to be closed when:</Box>
          <Box sx={{ display: 'flex' }}>
            this number of candidates:{' '}
            <TextField defaultValue={thresholdValue} onChange={handleThresholdChange} />
          </Box>
          <Box>reach the status of: xxxxx</Box>
          <Box>Select users to notify: [xxxx]</Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <SecondaryButton onClick={handleCloseModal}>Cancel</SecondaryButton>
            <PrimaryButton
              disabled={isLoading}
              dataTestId={ATSJobClosureNotificationTestIds.MODAL_PRIMARY_BUTTON}
              onClick={hasExistingNotifications ? handleEdit : handleAdd}
            >
              {hasExistingNotifications ? 'Edit' : 'Create'}
            </PrimaryButton>
          </Box>
        </Box>
      }
    />
  );
};
