import styled from 'styled-components';

export const JobAlertsBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: sticky;
  top: 0;
  z-index: 3;

  min-height: 50px;
  color: #ffffff;
  background-color: #c4c4c4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  font-size: 18px;
  font-weight: bold;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  svg {
    height: 18px;
    fill: #fff;
    flex-shrink: 0;
  }

  @media screen and (max-width: 480px) {
    font-size: 14px;
    svg {
      height: 14px;
    }
  }
`;

export const JobAlertsBannerText = styled.div`
  flex: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0.5em 0.5em 0.5em 1.5em;

  cursor: pointer;
`;

const CloseButton = props => (
  <div {...props}>
    <span>×</span>
  </div>
);

export const JobAlertsBannerCloseButton = styled(CloseButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 50px;
  height: 100%;
  width: 50px;

  cursor: pointer;
`;
