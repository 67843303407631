import auth from './auth';
import { useLocation } from 'react-router-dom';
import { nimbleConnectUrlFlag } from 'utils/constants';

const candidate = 'candidate';
const superAdmin = 'super_admin';
const districtUser = 'district_user';
const schoolUser = 'school_user';
const schoolAdmin = 'school_admin';
const districtAdmin = 'district_admin';

const generateUserTypeToId = <T extends Record<string, number>>(input: T): T => input;

export const userTypeToId = generateUserTypeToId({
  [candidate]: 0,
  [schoolUser]: 10,
  [schoolAdmin]: 20,
  [districtUser]: 30,
  [districtAdmin]: 40,
  [superAdmin]: 50,
} as const);

type UserTypeToId = typeof userTypeToId;

export type UserType = keyof UserTypeToId;
export type UserTypeId = UserTypeToId[UserType];

const idSet = new Set(Object.values(userTypeToId));
const isUserTypeId = (id: number): id is UserTypeId => Set.prototype.has.call(idSet, id);

export const isUserType = (input: string): input is UserType =>
  Object.prototype.hasOwnProperty.call(userTypeToId, input);

export const getUserTypeById = (id: number): UserType => {
  if (!isUserTypeId(id)) {
    throw new Error('Unexpected id given');
  }

  const entry = Object.entries(userTypeToId).find(entry => entry[1] === id);

  if (!entry) throw new Error('No entry found');
  const [userType] = entry;

  if (!isUserType(userType)) throw new Error('Unexpected user type given');

  return userType;
};

export const superAdminUserType = [superAdmin] as const;
export const districtAdminUserTypes = [districtAdmin, superAdmin] as const;
export const districtUserTypes = [...districtAdminUserTypes, districtUser] as const;

const districtAdminUserTypesSet = new Set(districtAdminUserTypes);
const districtUserTypesSet = new Set(districtUserTypes);

type DistrictAdminUserType = typeof districtAdminUserTypes[number];
type DistrictUserType = typeof districtUserTypes[number];

export const schoolUserTypes = [schoolUser, schoolAdmin, superAdmin] as const;

export const dashboardsUserTypes = [
  districtUser,
  districtAdmin,
  superAdmin,
  schoolUser,
  schoolAdmin,
] as const;
export const dashboardsUsersTypeSet = new Set(dashboardsUserTypes);

export const checklistUserTypes = [districtAdmin, districtUser, schoolAdmin, superAdmin] as const;

export const schoolOrDistrictAuthUserTypes = [
  districtUser,
  districtAdmin,
  schoolAdmin,
  superAdmin,
] as const;

export const isDistrictAdminUserType = (userType: UserType): userType is DistrictAdminUserType =>
  Set.prototype.has.call(districtAdminUserTypesSet, userType);

export const isDistrictUserType = (userType: UserType): userType is DistrictUserType =>
  Set.prototype.has.call(districtUserTypesSet, userType);

export const isSchoolUserType = (userType: UserType): boolean =>
  userType === 'school_admin' || userType === 'school_user';

export const isCandidateUserType = (userType: UserType): boolean => userType === 'candidate';

export const getInvalidUserTypeRedirectPathname = (
  userType: UserType,
  hasApplications: boolean
): string => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const returnTo = searchParams.get('return_to');
  const isNimbleConnectSignupOrLogin = !!returnTo?.includes(nimbleConnectUrlFlag);
  switch (userType) {
    case 'candidate': {
      if (isNimbleConnectSignupOrLogin) {
        return '/connect';
      } else if (hasApplications) {
        return '/candidate-dashboard';
      } else {
        return '/preferences';
      }
    }
    case 'school_user':
    case 'school_admin': {
      return '/school/home';
    }

    case 'district_user':
    case 'district_admin':
    case 'super_admin': {
      return '/district/home';
    }

    default: {
      return '/login';
    }
  }
};

interface GetHomeRouteParameters {
  userType: UserType;
  hasApplications: boolean;
}

export const getHomeRoute = ({ userType, hasApplications }: GetHomeRouteParameters): string => {
  if (auth.inPostingOnlyDistrict()) {
    return '/district/jobslist';
  } else if (isDistrictUserType(userType)) {
    return '/district/home';
  } else if (isSchoolUserType(userType)) {
    return '/school/home';
  } else if (isCandidateUserType(userType)) {
    if (hasApplications) {
      return '/candidate-dashboard';
    } else {
      return '/preferences';
    }
  } else {
    return '/login';
  }
};
