import { useState } from 'react';
import styled from 'styled-components';

/**
 * A 2 item toggle switch
 * Usage:
 *
 *  import * as Toggle from './components/Toggle';
 *
 *  const toggleState = Toggle.useToggleState();
 *
 *  <Toggle.Wrapper>
 *    <Toggle.ToggleItem
 *      isActive={toggleState.modeOneIsActive}
 *      onClick={toggleState.setModeOneIsActive}
 *    >
 *      Mode One
 *    </Toggle.ToggleItem>
 *    <Toggle.ToggleItem
 *      isActive={toggleState.modeTwoIsActive}
 *      onClick={toggleState.setModeTwoIsActive}
 *    >
 *      Mode Two
 *    </Toggle.ToggleItem>
 *  </Toggle.Wrapper>
 */

//  Wrapper is a rounded square box
export const Wrapper = styled.div`
  display: flex;

  font-size: 13px;

  cursor: pointer;
`;

// Toggle item is green when active, white with a border when not active.
// TODO: Toggle shadows aren't done very well.
// 00B88D 3D20A3 1F662E 005A43
export const ToggleItem = styled.div`
  width: 67px;
  padding: 10px 5px;

  text-align: center;
  user-select: none;

  background-color: ${props =>
    props.isActive ? props.theme.uiColors.greens.full : props.theme.colors.pureWhite};
  color: ${props => (props.isActive ? props.theme.colors.pureWhite : '#444')};
  border: 1px solid
    ${props => (props.isActive ? props.theme.uiColors.greens.full : props.theme.uiColors.lightgray)};

  font-weight: ${props => (props.isActive ? 600 : 'normal')};

  :first-child {
    border-radius: 3px 0px 0px 3px;
    border-right: none;
  }

  :last-child {
    border-radius: 0px 3px 3px 0;
    border-left: none;
  }
`;

// 2 mode toggle state manager
export const useToggleState = () => {
  const [state, setState] = useState({
    modeOneIsActive: true,
    modeTwoIsActive: false
  });

  const setModeOneIsActive = () => {
    setState({
      modeOneIsActive: true,
      modeTwoIsActive: false
    });
  };

  const setModeTwoIsActive = () => {
    setState({
      modeOneIsActive: false,
      modeTwoIsActive: true
    });
  };

  return {
    modeOneIsActive: state.modeOneIsActive,
    modeTwoIsActive: state.modeTwoIsActive,
    setModeOneIsActive,
    setModeTwoIsActive
  };
};
