import { Component } from 'react';

import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ErrorText from '../errortext';
import ColorDropdown from './ColorDropdown';
import { appStatusType } from '../../utils/enums';

import auth from '../../utils/auth';

export default class ApplicationStatusModal extends Component {
  static propTypes = {
    status: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    emailTemplateList: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
    newApplicationStatuses: PropTypes.array.isRequired,
    isPreHiringPool: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      /** The user will be editing the application status in this modal, with the option
       * to save & close, or cancel. If they cancel, changes are not applied. That's why
       * we will store changes locally in state instead of sending changes up to parent state.
       * If the user clicks save & close, then send the changes up to parent state to be
       * sent to backend.
       */
      status: Object.assign({}, props.status),
      errorMessage: '',
    };
  }

  handleChange = e => {
    let status = this.state.status;
    let fieldName = e.target.name;
    status[fieldName] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    // if onboarding status is turned on, make sure school admin view and move access are
    // true and false, respectively.
    if (fieldName === 'status_type' && e.target.value === appStatusType.onboarding) {
      status.school_admin_view_access = true;
      status.school_admin_move_access = false;
    }

    // if archived switch is turned on, make sure school admin view access and move access
    // are false by default.
    if (fieldName === 'status_type' && e.target.value === appStatusType.archived) {
      status.school_admin_view_access = false;
      status.school_admin_move_access = false;
    }

    this.setState({ status, errorMessage: '' });
  };

  handleTemplateSelection = e => {
    let status = this.state.status;
    let list = this.props.emailTemplateList;
    let template = list.find(item => item.id === Number(e.target.value));

    if (template) {
      status.emailtemplate = Object.assign({}, template);
      this.setState({ status, errorMessage: '' });
    }
  };

  handleSwitch = () => {
    let status = Object.assign({}, this.state.status);
    status.email_bool = !this.state.status.email_bool;
    if (status.email_bool === false) {
      // when turning switch off, remove associated emailtemplate
      status.emailtemplate = null;
    }
    this.setState({ status });
  };

  handleArchivedSwitch = () => {
    let alreadyArchived = this.state.status.status_type === appStatusType.archived;
    if (!alreadyArchived) {
      // user is turning switch on, so set color to red and type to archived.
      this.handleChange({
        target: {
          name: 'color',
          value: 1, // red
        },
      });
      this.handleChange({
        target: {
          name: 'status_type',
          value: appStatusType.archived,
        },
      });
    } else {
      // if turning off archived button, revert to pre_hiring_pool and set color to green.
      this.handleChange({
        target: {
          name: 'status_type',
          value: appStatusType.pre_hiring_pool,
        },
      });
      this.handleChange({
        target: {
          name: 'color',
          value: 2, // green
        },
      });
    }
  };

  handleCheckbox = e => {
    /** the two if blocks below will ensure 'move' isn't only one checked, since you can't
     * have move access without also having view access.
     */
    if (
      e.target.name === 'school_admin_move_access' &&
      e.target.checked === true &&
      this.state.status.school_admin_view_access === false
    ) {
      // if the user selected move access, view access must also be selected
      this.handleChange({
        target: {
          name: 'school_admin_view_access',
          type: 'checkbox',
          checked: true,
        },
      });
    } else if (
      e.target.name === 'school_admin_view_access' &&
      e.target.checked === false &&
      this.state.status.school_admin_move_access === true
    ) {
      // if the user deselected view access, move access must also be deselected
      this.handleChange({
        target: {
          name: 'school_admin_move_access',
          type: 'checkbox',
          checked: false,
        },
      });
    }
    this.handleChange(e);
  };

  save = () => {
    let status = this.state.status;
    if (status.label === '') {
      this.setState({ errorMessage: 'Please add a step name.' });
      return;
    }
    // don't allow a status to have the same name as one that already exists
    const otherExistingStatuses = this.props.newApplicationStatuses.filter(
      existingStatus => existingStatus.id !== status.id
    );
    const existingLabels = otherExistingStatuses.map(statusObj => statusObj.label);
    if (existingLabels.includes(status.label)) {
      this.setState({ errorMessage: 'Another step with that name already exists.' });
      return;
    }
    this.props.onSave(status);
  };

  updateMultiSelect = (values, fieldName) => {
    let status = this.state.status;
    status[fieldName] = values.map(value => value.value);
    this.setState({ status });
  };

  schoolAdminAccessSection = status => {
    if (status.status_type === appStatusType.archived && !this.props.isPreHiringPool) {
      return (
        <div>
          <label>
            <input
              type="checkbox"
              name="school_admin_move_access"
              checked={status.school_admin_move_access}
              onChange={this.handleCheckbox}
              data-testid="archive_candidates_for_school_checkbox"
            />
            &nbsp;&nbsp;Archive candidates for their schools
          </label>
          <label>
            <input
              type="checkbox"
              name="school_admin_move_district"
              checked={status.school_admin_move_district}
              onChange={this.handleCheckbox}
              data-testid="archive_candidates_for_district_checkbox"
            />
            &nbsp;&nbsp;Archive candidates for the district
          </label>
        </div>
      );
    } else if (status.status_type === appStatusType.hiring_pool) {
      return (
        <div>
          <label>
            <input
              type="checkbox"
              checked={true}
              disabled={true} // show but don't allow it to be unchecked
            />
            &nbsp;&nbsp;Move candidates into this status for their schools
          </label>
          <label>
            <input
              type="checkbox"
              name="school_admin_move_district"
              checked={status.school_admin_move_district}
              onChange={this.handleCheckbox}
            />
            &nbsp;&nbsp;Move candidates into this status for the district
          </label>
        </div>
      );
    } else {
      return (
        <label>
          <input
            type="checkbox"
            name="school_admin_move_access"
            checked={status.school_admin_move_access}
            onChange={this.handleCheckbox}
            // Disabled when view access is false.
            disabled={status.school_admin_view_access === false}
            data-testid="school_admin_move_access"
          />
          &nbsp;&nbsp;Move candidates to this status
        </label>
      );
    }
  };

  render() {
    const { show, onHide } = this.props;
    let status = this.state.status;

    return (
      <Modal show={show} onHide={onHide} bsSize="large" dialogClassName="app-status-modal">
        <Modal.Header>
          <h4>{status.id ? 'Edit Status' : 'New Status'}</h4>
          <div className="modal-header-close" onClick={onHide}>
            <p>x</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="app-status-main-content">
            <h4>Candidate Step Name</h4>
            <div className="label-and-color-container mt1">
              <input
                type="text"
                name="label"
                className="standard-input"
                placeholder={`eg. "Phone Interview Request"`}
                value={status.label || ''}
                onChange={this.handleChange}
                maxLength={22}
              />
              <ColorDropdown status={status} handleChange={this.handleChange} />
            </div>

            {/* Send Email template section */}
            <div className="switch-and-text-container">
              <div
                className={`switch-container ${status.email_bool ? 'on' : ''}`}
                onClick={this.handleSwitch}
              >
                <div className={`switch-circle ${status.email_bool ? 'on' : ''}`} />
              </div>
              <div className="switch-text">
                <span className="bold">Send Email</span>
                <span className="subtext">
                  When this is activated, users will be able to automatically send an email once a
                  candidate reaches this status.
                </span>
                {/* Note that email_bool can be true even if the associated template column is null.
                    In this case, a blank email modal will show instead of a template. */}
                {status.email_bool && (
                  <div className="basic-select dropdown">
                    <select
                      name="emailtemplate"
                      value={status.emailtemplate ? status.emailtemplate.id : ''}
                      onChange={this.handleTemplateSelection}
                    >
                      <option disabled value={''}>
                        Select email template
                      </option>
                      {this.props.emailTemplateList.map((e, i) => (
                        <option value={e.id} key={i}>
                          {e.title}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            </div>

            {/* Archived status section */}
            {!status.reserved && (
              <div className="switch-and-text-container">
                <div
                  className={`switch-container ${
                    status.status_type === appStatusType.archived ? 'on' : ''
                  } ${status.id ? 'greyed-out' : ''}`}
                  onClick={!status.id ? this.handleArchivedSwitch : undefined}
                >
                  <div
                    className={`switch-circle ${
                      status.status_type === appStatusType.archived ? 'on' : ''
                    } ${status.id ? 'greyed-out' : ''}`}
                  />
                </div>
                <div className="switch-text">
                  <span className="bold">Archived Status</span>
                  <span className="subtext">
                    When this is activated, this status is considered "end of the road" for that
                    candidate.
                  </span>
                </div>
              </div>
            )}

            {/* Onboarding status section:
              Restrict access to districts that have paid for
              modules
            */}
            {auth.hasOnboardingModule() &&
              status.status_type !== appStatusType.archived &&
              status.status_type !== appStatusType.hiring_pool && (
                <div className="switch-and-text-container" data-testid="onboarding-switch">
                  <div
                    className={`switch-container ${
                      status.status_type === appStatusType.onboarding ? 'on' : ''
                    } ${status.id ? 'greyed-out' : ''}`}
                    onClick={() => {
                      // only allow onboarding to be toggled if this is a new status,
                      // in which case status id would be falsy.
                      if (!status.id) {
                        this.handleChange({
                          target: {
                            name: 'status_type',
                            // if status_type is already onboarding, turn it to default, which is pre_hiring_pool.
                            // if not, turn status_type to onboarding.
                            value:
                              status.status_type === appStatusType.onboarding
                                ? appStatusType.pre_hiring_pool
                                : appStatusType.onboarding,
                          },
                        });
                      }
                    }}
                  >
                    <div
                      className={`switch-circle ${
                        status.status_type === appStatusType.onboarding ? 'on' : ''
                      } ${status.id ? 'greyed-out' : ''}`}
                    />
                  </div>
                  <div className="switch-text">
                    <span className="bold">Onboarding status</span>
                    <span className="subtext">
                      Enable if this status is part of the onboarding process for new hires.
                    </span>
                  </div>
                </div>
              )}

            {/* school admin access section */}
            {status.status_type !== appStatusType.hired &&
              status.status_type !== appStatusType.onboarding && (
                <div>
                  <h4 className="mt2">School admins can...</h4>
                  <label>
                    <input
                      type="checkbox"
                      name="school_admin_view_access"
                      checked={status.school_admin_view_access}
                      onChange={this.handleCheckbox}
                      // Unable to uncheck school admin viewing once in or past SHP
                      disabled={!this.props.isPreHiringPool}
                      // can't figure out how else to get the checkbox for my test... ah well
                      data-testid="school_admin_view_access"
                    />
                    &nbsp;&nbsp;View candidates in this status
                  </label>
                  {this.schoolAdminAccessSection(status)}
                </div>
              )}
          </div>
          <div className="email-template-buttons-div">
            <button
              className="email-template-modal-button email-template-modal-cancel"
              onClick={onHide}
            >
              Cancel
            </button>
            <div className="flex">
              <div style={{ lineHeight: '90px', marginRight: '4px' }}>
                {this.state.errorMessage && <ErrorText message={this.state.errorMessage} />}
              </div>
              <button
                className="email-template-modal-button email-template-modal-save"
                onClick={this.save}
              >
                Save & Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
