import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { AltModal, Button, Input, Label, ConfirmationDialog } from 'ui-kit';

export default function ApplicantSourceModal({
  isOpen,
  onClose,
  onSave,
  onDelete,
  editSource,
  error = '',
  sources,
}) {
  const [source, setSource] = useState(editSource);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => setSource(editSource), [editSource]);

  function cancel() {
    // Cleanup if needed:
    // setSource({ label: '', value: '' });
    setHasError(false);
    onClose();
  }

  function remove() {
    onDelete(source);
  }

  function save() {
    if (validateSource()) {
      // Spec: The user can choose to only have an internal name, check for that;
      const cleanSource = Object.assign({}, source); // Don't mess with state directly.
      if (!cleanSource.label) cleanSource.label = cleanSource.value;
      onSave(cleanSource);
    }
  }

  function confirmRemove(decision) {
    // Are you sure about that?
    setIsConfirmationOpen(false);
    if (decision) remove();
  }

  function validateSource() {
    let isValid = true;
    let foundSource = sources.filter(item => item.label === source.label);
    let foundSourceExists = foundSource.length > 0;

    if (!source.value) {
      // Spec: if there is no internal name, or value, we show an error:
      isValid = false;
      setHasError(true);
      setErrorMessage('Internal name is required');
    } else if (foundSourceExists && foundSource[0].id !== source.id) {
      // Source should have a unique candidate facing label. If Ids match, it is being edited.
      isValid = false;
      setHasError(true);
      setErrorMessage('This candidate-facing source already exists.');
    } else {
      setHasError(false);
      setErrorMessage(false);
    }
    return isValid;
  }

  function onChange(e) {
    const key = e.target.name.slice('source_'.length);
    const value = e.target.value;

    const newSource = {};
    newSource[key] = value;

    validateSource();

    setSource(prevState => {
      return { ...prevState, ...newSource };
    });
  }

  const isEdit = !!editSource.id && !editSource.immutable;

  return isConfirmationOpen ? (
    <ConfirmationDialog
      resultHandler={confirmRemove}
      title="Delete source"
      text="Are you sure you want to delete this source? This action cannot be undone."
      backLabel="Back to Edit"
      confirmLabel="Permanently Delete"
      isOpen={isOpen}
    />
  ) : (
    <AltModal.AltModal isOpen={isOpen} onClose={cancel} closeOnClickOutside={false}>
      <AltModal.Title>New Source</AltModal.Title>
      <AltModal.Body>
        <FormRow>
          <StyledLabel>Internal Name</StyledLabel>
          <Input
            type="text"
            placeholder="This is the name internal administrators will see on the dashboard and candidate profiles"
            name="source_value"
            value={source.value}
            onChange={onChange}
            width="100%"
          />
        </FormRow>
        <FormRow>
          <StyledLabel>Candidate-Facing Name</StyledLabel>
          <Input
            type="text"
            placeholder="This is the name candidates will see in their list when selecting how they found out about your organization"
            name="source_label"
            value={source.label}
            onChange={onChange}
            width="100%"
            disabled={source.immutable}
          />
        </FormRow>
        {hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </AltModal.Body>
      <StyledActions>
        <Button variant="secondary" onClick={cancel}>
          Cancel
        </Button>
        {isEdit && (
          <Button
            border="None"
            backgroundColor="rgba(0,0,0,0)"
            color="#c2c2c2"
            onClick={() => setIsConfirmationOpen(true)}
            padding="0"
          >
            Delete source
          </Button>
        )}
        <SaveButton variant="primary" onClick={save}>
          Save Source
        </SaveButton>
      </StyledActions>
    </AltModal.AltModal>
  );
}

const ErrorMessage = styled.div`
  color: #3de2a3;
  font-size: 15px;
  line-height: 20px;
`;

const FormRow = styled.div`
  margin-bottom: 44px;

  & + ${ErrorMessage} {
    margin-top: -21px;
  }
`;

const StyledActions = styled(AltModal.Actions)`
  display: grid;
  grid-template-columns: 25% 15% 35% 25%;
  padding: 40px;
`;

const SaveButton = styled(Button)`
  grid-column: 4;
`;

const StyledLabel = styled(Label)`
  font-size: 19px;
  line-height: 26px;
  margin-bottom: 14px;
`;
