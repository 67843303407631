import { Component } from 'react';

// District screen
import EmailModal from '../../EmailModal';
import PropTypes from 'prop-types';
import SelectStatus from '../../common/SelectStatus';

export default class StatusSwitcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailModalOpen: false,
      action: 'Invite',
      subject: '',
      message: '',
      statusAfterModal: {},
      emailTemplate: {},
      showTemplates: true,
      interviewTime: '',
    };
  }

  static propTypes = {
    user: PropTypes.object.isRequired,
    application: PropTypes.object.isRequired,
    toggleScreen: PropTypes.func.isRequired,
    statusOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    emailTemplatesList: PropTypes.array.isRequired,
    adminUsers: PropTypes.array.isRequired,
  };

  componentWillUnmount() {
    this.ignoreLastFetch = true;
  }

  onSendEmail = () => {
    this.setState({ emailModalOpen: false });
    this.props.toggleScreen(this.state.statusAfterModal);
  };

  /* MAIN STATUS TOGGLING FUNCTION; DETERMINES WHICH EMAIL MODAL OPENS (IF ANY) */
  toggleStatus = status => {
    /** removing the ability to select an email template since the user can specify which template
     * they want to use for each status in the status sections of the settings page.
     * If we decide to add back, set the following to state: 'showTemplates: true'
     */
    let statusObj = this.props.statusOptions.find(s => s.label === status.label);

    if (statusObj.email_bool) {
      this.setState({
        statusAfterModal: statusObj,
        emailModalOpen: true,
        showTemplates: true,
        subject: '',
        message: '',
      });
      if (statusObj.emailtemplate) {
        this.updateEmailTemplateSelection({
          target: { value: statusObj.emailtemplate.id },
        });
      }
    } else {
      this.props.toggleScreen(statusObj);
    }
  };

  updateEmailTemplateSelection = e => {
    /** selected template will send up id as e.target.value. find in list */
    const emailTemplateProp = this.props.emailTemplatesList.find(
      template => template.id === Number(e.target.value)
    );

    if (emailTemplateProp) {
      let emailTemplate = Object.assign({}, emailTemplateProp);
      if (emailTemplate.content) {
        emailTemplate.content = emailTemplate.content.replace(/\n/g, '<br>');
      }
      this.setState({
        emailTemplate,
        subject: emailTemplate.subject,
        message: emailTemplate.content,
      });
    }
    if (e.target.value === null) {
      this.setState({
        emailTemplate: {},
        subject: '',
        message: '',
      });
    }
  };

  render() {
    const application = this.props.application;

    return (
      <div className="QuickProfile__applicant-details-candidate-status status-view">
        <div className={!this.props.statusOptions.length ? 'not-active' : ''}>
          <SelectStatus
            application={application}
            toggleStatus={this.toggleStatus}
            statusOptions={this.props.statusOptions}
          />
        </div>
        {this.state.emailModalOpen && (
          <EmailModal
            recipientList={[this.props.user]}
            show={this.state.emailModalOpen}
            subject={this.state.subject}
            message={this.state.message}
            skipEmailFn={this.onSendEmail}
            onHide={() => this.setState({ emailModalOpen: false })}
            onSend={this.onSendEmail}
            emailTemplatesList={this.props.emailTemplatesList}
            emailTemplate={this.state.emailTemplate}
            updateEmailTemplateSelection={this.updateEmailTemplateSelection}
            candidate={this.props.user}
            application={application}
            showTemplates={this.state.showTemplates}
            interviewTime={this.state.interviewTime}
          />
        )}
      </div>
    );
  }
}
