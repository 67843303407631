import auth from '../../../../utils/auth';
import { Label, Container, StyledMultiSelect } from './TabsShareComponents';
import Tooltip from '../../../../assets/tooltip.svg';
import ReactTooltip from 'react-tooltip';
import { Category, DropdownSelectOption, HRBPAssignment, Subcategory } from 'types/types';
import LocationsSelect from './sharedComponents/LocationsSelect';
import { AssignmentsTabProps } from '../../types/types';
import HRBPAssignments from '../../../../components/JobEdit/HRBPAssignment';
import JobEditGrades from 'components/JobEdit/jobeditgrades';
import ErrorText from 'components/errortext';
import { useState, useMemo, useEffect } from 'react';
import CategoriesAPI from '../../../../api/categoriesAPI';

export default function AssignmentsTab({
  job,
  setJob,
  errors,
  setErrors,
  updateMultiSelect,
  districtUsers,
  districtAndSuperAdmins,
  schoolsSelected,
  setSchoolsSelected,
  schoolOptions,
}: AssignmentsTabProps): React.ReactElement {
  const [subcategories, setSubcategories] = useState<DropdownSelectOption[]>([]);

  useEffect(() => {
    const user = auth.getUser();
    if (user) {
      const district_id: number = user.profile.district.id;
      getSubcategories(district_id);
    }
  }, []);

  const getSubcategories = (district_id: number) => {
    /** get categories from db, then iterate through and set subcategories. The subcategories
     * are expected to be objects with a value property and label property.
     */
    CategoriesAPI.fetchCategories({ districtId: district_id }).then(r => {
      const categories = r || [];
      const subcats: DropdownSelectOption[] = [];
      categories.forEach((c: Category) => {
        c.subcategories.forEach((s: Subcategory) => {
          subcats.push({
            value: Number(s.id),
            label: s.label,
          });
        });
      });
      setSubcategories(subcats);
    });
  };

  const defaultSubcategories = useMemo(() => {
    return subcategories.filter((s: DropdownSelectOption) => job?.subjects?.includes(s?.value));
  }, [job, subcategories]);
  const districtUserOptions = () => {
    return districtUsers.map(u => {
      return {
        label: u.name,
        value: u.id,
      };
    });
  };

  const districtAdminOptions = () => {
    return districtAndSuperAdmins.map(u => {
      return {
        label: u.name,
        value: u.id,
      };
    });
  };
  return (
    <Container>
      {auth.isDistrictAdmin() && !auth.inPostingOnlyDistrict() && (
        <>
          <LocationsSelect
            job={job}
            setJob={setJob}
            errors={errors}
            setErrors={setErrors}
            schoolOptions={schoolOptions}
            schoolsSelected={schoolsSelected}
            setSchoolsSelected={setSchoolsSelected}
          />
          <div>
            <Label className="inline-block mr1">HR/Onboarding Partner</Label>
            <img
              className="mbhalf"
              src={Tooltip}
              alt="tooltip"
              data-tip={`
                Individual applications can be assigned to <br/>
                Super/District Admin. These assignments are used to<br/>
                filter and view individual team member portfolios.<br/>
              `}
              data-for="hrbp-assignments"
            />
            <ReactTooltip id="hrbp-assignments" effect="solid" multiline />
            <HRBPAssignments
              schools={schoolsSelected}
              districtAndSuperAdmins={districtAndSuperAdmins}
              schoolOptions={schoolOptions}
              job={job}
              hasError={errors.hrbp_assignments}
              hrbpAssignments={job.hrbp_assignments}
              onSave={(h: HRBPAssignment[]) => setJob({ ...job, hrbp_assignments: h })}
            />
          </div>
        </>
      )}
      {districtUsers && districtUsers.length > 0 && !auth.inPostingOnlyDistrict() && (
        <div>
          <Label>District Users</Label>
          <StyledMultiSelect
            options={districtUserOptions()}
            value={districtUserOptions().filter(district_user =>
              job.district_users.includes(district_user.value)
            )}
            onChange={(values: string[]) => updateMultiSelect(values ?? [], 'district_users')}
            placeholder="Start typing to add screeners to this role"
          />
        </div>
      )}
      {!auth.inPostingOnlyDistrict() &&
        districtAndSuperAdmins &&
        districtAndSuperAdmins.length > 0 && (
          <div>
            <Label className="inline-block mr1">Job Owners</Label>
            <img
              className="mbhalf"
              src={Tooltip}
              alt="tooltip"
              data-tip={`
                  Although Super Admins and District Admins can see<br />
                  all candidates for all jobs, you can optionally add<br />
                  them as owners for their core jobs to allow them to<br />
                  opt into notifications for the jobs they own.
                `}
              data-for="job-owners"
            />
            <ReactTooltip id="job-owners" effect="solid" multiline />
            <StyledMultiSelect
              options={districtAdminOptions()}
              value={districtAdminOptions().filter(admin => job.owners.includes(admin.value))}
              onChange={(values: string[]) => updateMultiSelect(values ?? [], 'owners')}
              placeholder="Start typing to add owners to this role"
            />
          </div>
        )}
      {subcategories?.length > 0 && (
        <>
          <Label className="inline-block mr1">Category</Label>
          <img
            className="mbhalf"
            src={Tooltip}
            alt="tooltip"
            data-tip={`
                  These tags will inform the way your jobs are categorized<br />
                  for candidates on the external job posting list. District admins<br />
                  can also filter candidates by job category on the candidate list.
                `}
            data-for="category"
          />
          <ReactTooltip id="category" effect="solid" multiline />
          <StyledMultiSelect
            options={subcategories}
            value={defaultSubcategories}
            onChange={(values: Record<string, unknown>[]) =>
              updateMultiSelect(values ?? [], 'subjects')
            }
          />
          <ErrorText message={errors?.subjects} />
        </>
      )}
      <Label>Grades</Label>
      <JobEditGrades
        grades={job.grades}
        onChange={(values: number[]) => updateMultiSelect(values, 'grades')}
      />
      <ErrorText message={errors.grades} />
    </Container>
  );
}
