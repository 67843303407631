import { BaseNimbleAPI } from './baseAPI';
import axios from 'axios';
const CancelToken = axios.CancelToken;

class ProspectListAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/prospects' };
  }

  async createProspect(data) {
    const url = '/';
    const response = await this.post(url, data);
    return response.data;
  }

  async searchProspects(params) {
    if (this.cancelSearchProspects !== undefined) {
      this.cancelSearchProspects();
    }

    const url = '/';
    const response = await this.get(url, {
      cancelToken: new CancelToken(cancel => {
        this.cancelSearchProspects = cancel;
      }),
      params,
    });
    return response.data;
  }

  async updateProspectStatusInBulk(status, prospects) {
    const response = await this.post('/bulk_status_update/', { status, prospects });
    return response.data;
  }
}

export default new ProspectListAPI();
