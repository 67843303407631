import { createContext, useState } from 'react';

// name of the flags created in the backend must coincide with the names in this object
export const featureTags = {
  CREATE_JOBS: 'create_jobs_v2', //placeholder
  DELETE_JOBS: 'delete_jobs_v2', //placeholder
  TABBED_JOB_POSTING: 'tabbed_job_posting',
  LOGGED_IN_JOB_BOARD: 'logged_in_job_board',
  FLAT_FILE_TRANSFERS: 'flat_file_transfers',
  HAS_TALENT_MARKETPLACE: 'has_talent_marketplace',
  LOCATION_PREFERENCES: 'location_preferences',
  SOURCE_FILTER: 'source_filter',
  JOB_TEMPLATES: 'job_templates',
  APPLICATION_CONFIRMATION_TEMPLATE: 'application_confirmation_template',
  JOB_ACTIVITY_FEED: 'job_activity_feed',
  JOBVIEW_V2: 'jobview_v2',
  JOB_CLOSURE_NOTIFICATIONS: 'job_closure_notifications',
};

export class Features {
  constructor(feature_name, feature_enabled) {
    this.feature_name = feature_name;
    this.feature_enabled = feature_enabled;
  }
}

const Context = createContext({});

export function FeatureContextProvider({ children }) {
  const [features, setFeatures] = useState([]);

  return <Context.Provider value={{ features, setFeatures }}>{children}</Context.Provider>;
}

export default Context;
