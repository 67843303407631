import { BaseNimbleAPI } from './baseAPI';

class ApplicationStatusesAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/application_statuses' };
  }

  async getAll({ districtId, statusTypes }) {
    const params = {
      ...(districtId && { districtId }),
      ...(statusTypes && { status_type: statusTypes })
    };
    const response = await this.get(`/`, { params });
    return response.data;
  }
}

export default new ApplicationStatusesAPI();
