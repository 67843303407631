import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import { Inter } from 'features/MarketplaceV2/features/JobDiscovery/utils';
import { BadgeInterface } from './types';

export const Badge: React.FC<BadgeInterface> = ({ children, sx }) => {
  return (
    <BadgeContainer sx={sx}>
      <BadgeText>{children}</BadgeText>
    </BadgeContainer>
  );
};

const BadgeContainer = styled(Box)(() => ({
  border: 'solid 1px #D0D5DD',
  backgroundColor: '#FFFFFF',
  borderRadius: '6px',
  padding: '2px 6px 2px 6px',
}));

const BadgeText = styled(Typography)(() => ({
  fontFamily: Inter,
  fontWeight: 500,
  fontSize: '12px',
  color: '#344054',
}));
