import { Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import AnnotationCheck from 'assets/connect/connect-annotation-check.png';
import MessageChatCirle from 'assets/connect/connect-message-chat-circle.png';
import NotificationMessage from 'assets/connect/connect-notification-message.png';
import TeacherAndStudent from 'assets/connect/connect-teacher-and-student.png';
import TeacherAndStudents from 'assets/connect/connect-teacher-and-students.png';
import TeachingStaff from 'assets/connect/connect-teaching-staff.png';
import Background from 'assets/connect/connect-value-prop-banner-background.png';
import { ValuePropRow } from './ValuePropBannerGraphicRow';
import { nimbleTheme } from 'theme';

export function ValuePropBannerGraphic(): React.ReactElement {
  const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const iconStyles = {
    height: isLargeScreen ? '27px' : isMobile ? '17.3px' : '20px',
    minWidth: isLargeScreen ? '27px' : isMobile ? '17.3px' : '20px',
  };

  const imageStyles = {
    height: isLargeScreen ? '82px' : '60px',
    width: isLargeScreen ? '82px' : '60px',
    borderRadius: '50%',
    border: `${isLargeScreen ? '2.78px' : '1.71px'} solid #5c7cfa33`,
  };

  return (
    <ValuePropBannerGraphicContainer>
      <ValuePropRow
        valuePropImage={() => <img src={TeacherAndStudent} style={imageStyles} />}
        iconImage={() => <img src={MessageChatCirle} style={iconStyles} />}
        valuePropText={'Connect directly with principals, without tedious job applications'}
        imageLeft={true}
        accentColor="#c6d3ff"
      />
      <ValuePropRow
        valuePropImage={() => <img src={TeachingStaff} style={imageStyles} />}
        iconImage={() => <img src={NotificationMessage} style={iconStyles} />}
        valuePropText={'Fill out your job preferences and let the schools come to you!'}
        imageLeft={false}
        accentColor="#d9f4ee"
      />
      <ValuePropRow
        valuePropImage={() => <img src={TeacherAndStudents} style={imageStyles} />}
        iconImage={() => <img src={AnnotationCheck} style={iconStyles} />}
        valuePropText={'Be the first to know about teaching jobs in your area'}
        imageLeft={true}
        accentColor="#ffe6b0"
      />
    </ValuePropBannerGraphicContainer>
  );
}

const ValuePropBannerGraphicContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#f6f7ff',
  borderRadius: '21px',
  border: '1px solid #5c7cfa33',
  backgroundImage: `url(${Background})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: nimbleTheme.spacing(1),
  width: '576px',

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));
