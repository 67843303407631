import { useRef } from 'react';
import { ButtonBase, CircularProgress, Stack, Typography } from '@mui/material';
import { FileUploadProps } from '../Buttons/types';
import { FileDownloadCompleteIcon, StyledFileUploadContainer } from './styles';
import { styled } from '@mui/material/styles';
import { PrimaryButton } from 'sharedComponents/Buttons';

const ACCEPTED_FILE_TYPES = ['.doc', '.docx', '.pdf', '.jpg', '.jpeg', '.png'];

export const FileUpload: React.FC<FileUploadProps> = ({
  uploadedFileName,
  handleChange,
  isLoading = false,
  errors = [],
  sx,
  inputName,
  fileNameMaxWidth,
}) => {
  const fileInput = useRef(null);

  const handleClick = () => {
    if (isLoading) return;
    fileInput.current.click();
  };

  return (
    <>
      <StyledFileUploadContainer
        sx={{ ...sx, borderColor: errors.length && 'error.main' }}
        onClick={handleClick}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {uploadedFileName ? (
              <UpdateFile uploadedFile={uploadedFileName} fileNameMaxWidth={fileNameMaxWidth} />
            ) : (
              <UploadNewFile />
            )}
            <input
              name={inputName}
              id="file-upload"
              type="file"
              onChange={handleChange}
              ref={fileInput}
              style={{ display: 'none' }}
              accept={ACCEPTED_FILE_TYPES.join(',')}
            />
          </>
        )}
      </StyledFileUploadContainer>
      {errors?.length > 0 && (
        <div>
          {errors.map((error, index) => {
            return (
              <Typography key={index} color="error" variant="body2" sx={{ margin: '3px 14px 0px' }}>
                {error}
              </Typography>
            );
          })}
        </div>
      )}
    </>
  );
};

const UpdateFile = ({ uploadedFile, fileNameMaxWidth }) => {
  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={2}>
      <FileDownloadCompleteIcon />
      <section
        style={{
          textAlign: 'left',
          maxWidth: fileNameMaxWidth,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Typography sx={{ color: 'text.light' }}>Current resume uploaded:</Typography>
        <Typography sx={{ color: 'text.light' }}>{uploadedFile}</Typography>
      </section>
      <PrimaryButton sx={{ maxWidth: 160 }}>Update File</PrimaryButton>
    </Stack>
  );
};

const UploadNewFile = () => {
  const formattedFileTypes =
    ACCEPTED_FILE_TYPES.length > 1
      ? `${ACCEPTED_FILE_TYPES.slice(0, -1).join(', ')} or ${ACCEPTED_FILE_TYPES.slice(-1)}`
      : ACCEPTED_FILE_TYPES[0];

  return (
    <Stack alignItems="center">
      <FlexBox>
        <ClickToUploadButton>
          <Typography sx={{ fontWeight: 600 }}>Click to upload</Typography>
        </ClickToUploadButton>
        {/* Comment back in when drag and drop is implemented */}
        {/* <InstructionsText>or drag and drop</InstructionsText> */}
      </FlexBox>
      <InstructionsText variant="body2">{`${formattedFileTypes} (max. 2mb)`}</InstructionsText>
    </Stack>
  );
};

const FlexBox = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
});

const ClickToUploadButton = styled(ButtonBase)(({ theme }) => ({
  backgroundColor: 'none',
  border: 'none',
  color: theme.palette.green.main,
  whiteSpace: 'nowrap',
  marginRight: theme.spacing(0.5),
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const InstructionsText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.light,
  textAlign: 'center',
  whiteSpace: 'nowrap',
}));
