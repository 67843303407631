import { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { constants } from '../../utils/enums';
import { blankQuestion, questionMapping, JobQuestionModalProps } from './JobQuestionModalConstants';
import { LocationsModalSearchNewStyle } from '../../features/ApplicationQuestionSets/components/SchoolPreferencesQuestion';
import ModalFooterNew from '../../components/common/Modal/ModalFooterNew';

import MultipleChoiceAnswerSection from './MultipleChoiceAnswerSection';

import { Input, Textarea, Modal } from '../../ui-kit';
import { Question, QuestionType, Tag } from '../../types/types';
import auth from 'utils/auth';
import AutoTagsModal from '../../features/AutoTags/Modal/AutoTagsModal';
import { isFeatureEnabled } from '../../hoc/Feature';
import ReactTooltip from 'react-tooltip';
import customTagAPI from 'api/customTagAPI';
import AutoTagsModalOpenButton from '../../features/AutoTags/Modal/AutoTagsModalOpenButton';
import { TagState } from '../../features/AutoTags/Modal/types';

const schoolIdsWithOpenVacancies = (schoolOptions, schools) =>
  schoolOptions
    ? schoolOptions
        .filter(schoolOption =>
          schools.find(s => s.id === schoolOption.id && s.has_unfilled_vacancies)
        )
        .map(({ id }) => id)
    : [];

export function JobQuestionModal({
  questionData,
  show,
  onHide,
  type,
  questionBank,
  onSaveQuestion,
  schoolOptions,
  schools,
  internal_requirements_specified,
  applicationStatuses,
  handleTagModalOpen,
  handleTagModalClose,
  shouldShowTagModal,
}: JobQuestionModalProps): React.ReactElement {
  const [question, setQuestion] = useState<Question>(blankQuestion);
  const [error, setError] = useState<string>('');
  const [authorizedTags, setAuthorizedTags] = useState([]);
  const questionTagsOptions = questionData?.autotags;

  useEffect(() => {
    customTagAPI.fetchCustomTags().then(tags => {
      setAuthorizedTags(tags);
    });
  }, []);

  const questionHasHiddenTags = useMemo(() => {
    if (!questionTagsOptions) return false;
    const questionTags = questionTagsOptions?.map(option => option.tag_id);
    const authorizedTagIds = authorizedTags?.map(tag => tag.id);

    const authorizedTagIdsSet = new Set(authorizedTagIds);

    return !questionTags.every(questionTag => authorizedTagIdsSet.has(questionTag));
  }, [questionTagsOptions, authorizedTags]);

  const resetErrorState = () => setError('');

  useEffect(() => {
    if (questionData) {
      const questionDataCopy = { ...questionData };
      questionDataCopy.text = questionData?.question || questionData?.prompt || questionData?.title;
      questionDataCopy.mc_options = questionData.mc_options?.slice() || null;
      if (questionData.is_automatic_list) {
        questionDataCopy.school_choices = schoolIdsWithOpenVacancies(schoolOptions, schools);
      } else if (!questionData.school_choices || questionData.school_choices.length === 0) {
        questionDataCopy.school_choices = [];
      }
      setQuestion(questionDataCopy);
    } else {
      const blankQuestionCopy = {
        ...blankQuestion,
        school_choices: schoolIdsWithOpenVacancies(schoolOptions, schools),
      };
      setQuestion(blankQuestionCopy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionData]);

  const handleChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    const questionCopy = { ...question, [name]: checked };
    if (name === 'is_required_external' && checked === true) {
      questionCopy['is_visible_external'] = checked;
    }
    if (e.target.name === 'is_required_internal' && checked === true) {
      questionCopy['is_visible_internal'] = checked;
    }
    setQuestion(questionCopy);
    resetErrorState();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setQuestion({ ...question, [name]: value });
    resetErrorState();
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setQuestion({ ...question, [name]: value });
    resetErrorState();
  };

  const handleChangeMultipleChoice = (e: React.ChangeEvent<HTMLInputElement>, index) => {
    if (question.mc_options) {
      const value: string = e.target.value;
      const mc_options = [...question.mc_options];
      mc_options[index] = value;
      setQuestion({ ...question, mc_options: mc_options });
    }
    resetErrorState();
  };
  const handleMultipleChoiceTextChange = (e, index) => {
    if (question.demo_mc_options) {
      const demo_mc_options = [...question.demo_mc_options];
      demo_mc_options[index].optionText = e.target.value;
      setQuestion({ ...question, demo_mc_options: demo_mc_options });
    }
    resetErrorState();
  };

  const handleMultipleChoiceStatusChange = (status, index) => {
    if (question.demo_mc_options) {
      const demo_mc_options = [...question.demo_mc_options];
      demo_mc_options[index].knockoutStatus = status;
      setQuestion({ ...question, demo_mc_options: demo_mc_options });
    }
    resetErrorState();
  };

  const removeOption = index => {
    const demo_mc_options = question.demo_mc_options
      ? [...question.demo_mc_options].filter((_, i) => i !== index)
      : null;
    setQuestion({ ...question, demo_mc_options: demo_mc_options });
    resetErrorState();
  };

  const removeMCOption = index => {
    const mc_options = question.mc_options
      ? [...question.mc_options].filter((_, i) => i !== index)
      : null;
    setQuestion({ ...question, mc_options: mc_options });
    resetErrorState();
  };

  const handleNimbleQuestionChange = value => {
    const nimbleQuestion = questionBank.find(({ question }) => question === value);
    setQuestion({
      ...question,
      nimblequestion_id: nimbleQuestion?.id || null,
      text: nimbleQuestion?.question || '',
    });
    resetErrorState();
  };

  const addAnswer = () => {
    setQuestion(prevQuestion => ({
      ...prevQuestion,
      mc_options: prevQuestion.mc_options ? [...prevQuestion.mc_options, ''] : [''],
    }));
  };

  const addAnswerDemo = () => {
    const demo_mc_options = question.demo_mc_options
      ? [...question.demo_mc_options, { optionText: '', knockoutStatus: {} }]
      : [{ optionText: '', knockoutStatus: {} }];
    setQuestion(prevQuestion => ({
      ...prevQuestion,
      demo_mc_options: demo_mc_options,
    }));
    resetErrorState();
  };

  const saveQuestion = () => {
    if (
      type === QuestionType.multiple_choice &&
      window.location.hostname !== 'demo.hirenimble.com'
    ) {
      // iterate over multiple choice options and make sure none are blank.
      const mc_options = question.mc_options || [];

      for (let i = 0; i < mc_options.length; i++) {
        const option = mc_options[i];

        if (mc_options && option.trim() === '') {
          setError('Each answer must have a value');
          return;
        }
      }
    }

    if (type === QuestionType.schoolPreferences) {
      if (question.is_automatic_list === false && question.school_choices.length === 0) {
        setError('You must select at least one school or use automatic list.');
        return;
      }
      onSaveQuestion(question, type);
    }

    if (question.text === '') {
      if ([QuestionType.nimble, QuestionType.attachment].includes(type)) {
        setError('Please select an option from the dropdown');
        return;
      } else {
        setError('Please enter a question');
        return;
      }
    }

    onSaveQuestion(question, type);
  };

  const onSelectedSchoolChange = (schoolId: number) => {
    const schoolChoices = question.school_choices;
    if (schoolChoices.includes(schoolId)) {
      setQuestion({
        ...question,
        is_automatic_list: false,
        school_choices: schoolChoices.filter(id => id !== schoolId),
      });
    } else {
      setQuestion({
        ...question,
        is_automatic_list: false,
        school_choices: [...schoolChoices, schoolId],
      });
    }
  };

  const onAutomaticListToggle = () => {
    const automaticListOn = !question.is_automatic_list;
    setQuestion({
      ...question,
      is_automatic_list: automaticListOn,
      school_choices: automaticListOn ? schoolIdsWithOpenVacancies(schoolOptions, schools) : [],
    });
  };

  const getQuestionPromptJSX = item => {
    if (type === QuestionType.nimble) {
      return (
        <div className="select-form-field jobedit-select-div mb3">
          <select
            name="text"
            value={question.text || ''}
            onChange={e => handleNimbleQuestionChange(e.target.value)}
          >
            <option value="">{item.placeholder}</option>
            {questionBank.map((obj, i) => (
              <option key={i} value={obj[item.optionText]}>
                {obj[item.optionText]}
              </option>
            ))}
          </select>
        </div>
      );
    } else if (type === QuestionType.attachment) {
      return (
        <div className="relative mb3">
          <Input
            width={1}
            name="text"
            type="text"
            value={question.text || ''}
            placeholder={item.placeholder}
            onChange={handleChange}
            maxLength={55}
          />
        </div>
      );
    } else if (type === QuestionType.schoolPreferences) {
      return (
        <SchoolPreferencesQuestionModalBody
          is_automatic_list={question.is_automatic_list}
          school_choices={question.school_choices}
          schoolOptions={schoolOptions}
          onAutomaticListToggle={onAutomaticListToggle}
          onSelectedSchoolChange={schoolId => onSelectedSchoolChange(schoolId)}
        />
      );
    } else {
      return (
        <div className="relative mb2">
          <Textarea
            name="text"
            cols={100}
            rows={2}
            style={{ width: '100%' }}
            value={question.text || ''}
            placeholder={item.placeholder}
            onChange={handleTextareaChange}
            maxLength={constants.MEDIUM_TEXT_LENGTH}
            autoFocus={true}
          />
        </div>
      );
    }
  };

  const toggleMultiSelect = () =>
    setQuestion({ ...question, multi_select: !question.multi_select });

  const toggleKnockoutQuestion = () =>
    setQuestion({ ...question, is_knockout_question: !question.is_knockout_question });

  const item = questionMapping[type];
  const Icon = item?.icon;

  const authorizedQuestionKinds =
    type === QuestionType.yes_no || type === QuestionType.statementCheckbox;
  const authorizedTagCreators = auth.isDistrictAdmin;

  const hasTagFeatureFlag = isFeatureEnabled('streamlined_screening');

  const shouldShowAutoTagLink =
    authorizedQuestionKinds && authorizedTagCreators && hasTagFeatureFlag;

  const handleTagSave = (tagState: TagState) => {
    const autotags: Tag[] = [];

    if (tagState.Yes) {
      autotags.push({ tag_id: tagState.Yes, option: 'Yes' });
    }

    if (tagState.No) {
      autotags.push({ tag_id: tagState.No, option: 'No' });
    }

    if (tagState.Agree) {
      autotags.push({ tag_id: tagState.Agree, option: 'Agree' });
    }

    if (tagState.Disagree) {
      autotags.push({ tag_id: tagState.Disagree, option: 'Disagree' });
    }

    setQuestion(previousQuestion => ({
      ...previousQuestion,
      autotags: autotags,
    }));
  };

  const shouldShowModal = item && question;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [shouldShowModal]);

  return (
    <>
      {shouldShowModal ? (
        <Modal.Modal isOpen={show} onClose={onHide} className="scorecard-q-modal">
          <Modal.Title>{`${question.localId ? 'Edit' : 'New'} item`}</Modal.Title>
          <Modal.Body>
            <div>
              <HeaderGrid>
                <Icon width="40" height="40" />
                <div>
                  <div className="bold">{item.header}</div>
                  <GreyText>{question?.multi_select ? item.info2 : item.info}</GreyText>
                </div>
                <EndItem>
                  {internal_requirements_specified ? (
                    <CheckboxContainer>
                      <GridItemHeader />
                      <GridItemHeader>visible</GridItemHeader>
                      <GridItemHeader>required</GridItemHeader>
                      <GridItem>external</GridItem>
                      <DivWithAllBorders>
                        <input
                          type="checkbox"
                          name="is_visible_external"
                          style={{ margin: '0' }}
                          value={question.is_visible_external ? 'true' : 'false'}
                          checked={question.is_visible_external}
                          onChange={handleChangeCheckBox}
                          disabled={question.is_required_external}
                        />
                      </DivWithAllBorders>
                      <DivWithNoLeftBorder>
                        <input
                          type="checkbox"
                          name="is_required_external"
                          style={{ margin: '0' }}
                          value={question.is_required_external ? 'true' : 'false'}
                          checked={question.is_required_external}
                          onChange={handleChangeCheckBox}
                        />
                      </DivWithNoLeftBorder>
                      <GridItem>internal</GridItem>
                      <DivWithNoTopBorder>
                        <input
                          type="checkbox"
                          name="is_visible_internal"
                          style={{ margin: '0' }}
                          value={question.is_visible_internal ? 'true' : 'false'}
                          checked={question.is_visible_internal}
                          onChange={handleChangeCheckBox}
                          disabled={question.is_required_internal}
                        />
                      </DivWithNoTopBorder>
                      <DivWithNoTopOrLeftBorder>
                        <input
                          type="checkbox"
                          name="is_required_internal"
                          style={{ margin: '0' }}
                          value={question.is_required_internal ? 'true' : 'false'}
                          checked={question.is_required_internal}
                          onChange={handleChangeCheckBox}
                        />
                      </DivWithNoTopOrLeftBorder>
                    </CheckboxContainer>
                  ) : (
                    <div>
                      <GreyLabel>
                        <input
                          type="checkbox"
                          name="is_required_external"
                          style={{ margin: '0 0.5em 0 0' }}
                          value={question.is_required_external ? 'true' : 'false'}
                          checked={question.is_required_external}
                          onChange={handleChangeCheckBox}
                        />
                        Required
                      </GreyLabel>
                    </div>
                  )}
                </EndItem>
              </HeaderGrid>
              <h5 className="bold mb1">{item.instruction}</h5>
              {getQuestionPromptJSX(item)}
              {shouldShowAutoTagLink && (
                <>
                  <AutoTagsModalOpenButton
                    questionHasHiddenTags={questionHasHiddenTags}
                    hasTagsAvailable={Boolean(authorizedTags.length)}
                    handleTagModalOpen={handleTagModalOpen}
                    hasSetTags={question.autotags?.length > 0}
                  />
                  <>
                    {shouldShowTagModal && (
                      <AutoTagsModal
                        onClose={handleTagModalClose}
                        questionType={type}
                        show={shouldShowTagModal}
                        existingAutoTags={question.autotags ? question.autotags : []}
                        fetchedTags={authorizedTags}
                        onSave={handleTagSave}
                      />
                    )}
                  </>
                </>
              )}

              {type === QuestionType.attachment && (
                <div>
                  <h5 className="bold mb1">Attachment directions</h5>
                  <div className="relative mb3">
                    <Textarea
                      name="description"
                      cols={100}
                      rows={2}
                      style={{ width: '100%' }}
                      value={question.description || ''}
                      placeholder="e.g. in one page, tell us more about why you're interested in this role"
                      onChange={handleTextareaChange}
                      maxLength={constants.MEDIUM_TEXT_LENGTH}
                    />
                  </div>
                </div>
              )}
              {type === QuestionType.videoLink && (
                <div>
                  <h5 className="bold mb1">Directions</h5>
                  <div className="relative mb3">
                    <Textarea
                      name="description"
                      cols={100}
                      rows={2}
                      style={{ width: '100%' }}
                      value={question.description || ''}
                      placeholder='e.g. "Link to a 5-minute teaching demonstration video, that showcases your teaching style"'
                      onChange={handleTextareaChange}
                      maxLength={constants.MEDIUM_TEXT_LENGTH}
                    ></Textarea>
                  </div>
                </div>
              )}
              {type === QuestionType.multiple_choice && (
                <MultipleChoiceAnswerSection
                  addAnswer={addAnswer}
                  addAnswerDemo={addAnswerDemo}
                  mc_options={question.mc_options}
                  demo_mc_options={question.demo_mc_options}
                  multi_select={question.multi_select}
                  toggleMultiSelect={toggleMultiSelect}
                  toggleKnockoutQuestion={toggleKnockoutQuestion}
                  is_knockout_question={question.is_knockout_question}
                  applicationStatuses={applicationStatuses}
                  handleChange={handleChangeMultipleChoice}
                  handleMultipleChoiceTextChange={handleMultipleChoiceTextChange}
                  handleMultipleChoiceStatusChange={handleMultipleChoiceStatusChange}
                  removeOption={removeOption}
                  removeMCOption={removeMCOption}
                />
              )}
            </div>
          </Modal.Body>
          <Modal.Actions>
            <ModalFooterNew
              cancel={onHide}
              errorMessage={error}
              save={saveQuestion}
              saveButtonLabel={question.localId ? 'Save' : 'Add Item'}
              saveDisabled={false}
            />
          </Modal.Actions>
        </Modal.Modal>
      ) : (
        <div data-testid="no-question-type-provided" />
      )}
    </>
  );
}

const HeaderGrid = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 210px;
  align-items: center;
  grid-gap: 1.5rem;
  margin-bottom: 3rem;
`;

const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: 70px repeat(2, 62px);
  grid-template-rows: 30px repeat(2, 40px);

  font-size: 14px;
`;

const GridItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GridItemHeader = styled(GridItem)`
  align-self: start;
`;

const EndItem = styled.div`
  justify-self: end;
`;

const DivWithAllBorders = styled(GridItem)`
  border: 1px solid rgba(57, 60, 73, 0.5);
`;

const DivWithNoLeftBorder = styled(DivWithAllBorders)`
  border-left: 0;
`;

const DivWithNoTopBorder = styled(DivWithAllBorders)`
  border-top: 0;
`;

const DivWithNoTopOrLeftBorder = styled(DivWithAllBorders)`
  border-left: 0;
  border-top: 0;
`;

const GreyLabel = styled.label`
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  color: #999999;
`;

const GreyText = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  color: #999999;
`;

const SchoolPreferencesQuestionModalBody = ({
  is_automatic_list,
  school_choices,
  schoolOptions,
  onAutomaticListToggle,
  onSelectedSchoolChange,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  let schoolsToShow = [...schoolOptions];
  const seenOptions = new Set();

  // remove group headers:
  schoolsToShow = schoolOptions
    ? schoolOptions.filter(s => {
        return !s.isGroupHeader;
      })
    : [];

  // remove duplicates:
  schoolsToShow = schoolsToShow.filter(s => {
    const dupe = seenOptions.has(s.id);
    seenOptions.add(s.id);
    return !dupe;
  });

  // School_choices can also have duplicates which messes up the displayed count:
  const uniq_school_choices = Array.from(new Set(school_choices));

  if (searchTerm) {
    schoolsToShow = schoolOptions.filter(
      school => school.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
    );
  }

  // sort by name:
  schoolsToShow.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

  return (
    <Container>
      <SwitchAndTextContainer className="switch-and-text-container">
        <div
          className={`switch-container ${is_automatic_list ? 'on' : ''} mt0 mb0`}
          onClick={onAutomaticListToggle}
        >
          <div className={`switch-circle ${is_automatic_list ? 'on' : ''}`} />
        </div>
        <div>
          <LargerText>Automatic list</LargerText>
          <GreyText>
            Candidate only sees schools with open vacancies at the time of application
          </GreyText>
        </div>
      </SwitchAndTextContainer>
      <LocationsModalSearchNewStyle
        placeholder="Start typing to find and add schools manually"
        schoolSearchChange={setSearchTerm}
      >
        <SelectedSchools>{`(${uniq_school_choices.length} school${
          uniq_school_choices.length !== 1 ? 's' : ''
        } currently selected)`}</SelectedSchools>
      </LocationsModalSearchNewStyle>
      <SchoolsList>
        {schoolsToShow.map((school, idx) => (
          <div key={idx} className="flex" onClick={() => onSelectedSchoolChange(school.id)}>
            <input
              className="mr1"
              type="checkbox"
              checked={school_choices.includes(school.id)}
              readOnly={true}
            />
            <div className="bold overflow-ellipsis">{school.name}</div>
          </div>
        ))}
      </SchoolsList>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  margin-bottom: 3rem;

  font-size: 14px;
`;

const LargerText = styled.p`
  font-size: 16px;
  font-weight: bold;
`;

const SwitchAndTextContainer = styled.div`
  align-items: center;
  margin-bottom: 3rem;
  margin-top: 0;
`;

const SchoolsList = styled.button`
  margin-top: 3rem;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 1rem;
  }

  // Shrink to single column on small screens
  @supports (display: grid) {
    @media screen and (max-width: 990px) {
      grid-template-columns: minmax(0, 1fr);
    }
  }
`;

const SelectedSchools = styled.div`
  font-size: 12px;
  color: #979797;
  margin: 5px 10px;
`;
