import styled from 'styled-components';
import PropTypes from 'prop-types';

import CandidateDashboardHellosignTaskRow from './CandidateDashboardHellosignTaskRow';
import CandidateDashboardTasksRow from './CandidateDashboardTasksRow';
import { taskStatus } from 'utils/enums';

const dueDateSort = (a, b) => {
  const aIsComplete = isComplete(a);
  const bIsComplete = isComplete(b);
  const bothComplete = aIsComplete && bIsComplete;
  const noneComplete = !aIsComplete && !bIsComplete;

  if (bothComplete || noneComplete) {
    // If both complete or both incomplete, sort by due date.
    let d1 = Date.parse(a.due_date);
    let d2 = Date.parse(b.due_date);
    return d1 - d2;
  } else {
    // One is complete and one is incomplete. Sort incomplete first.
    if (aIsComplete) {
      // Since a is complete, returning 1 will sort it to a higher index.
      return 1;
    } else {
      // a is incomplete, return -1 will sort it to a lower index.
      return -1;
    }
  }
};

const isComplete = task => {
  // We have different models for universal tasks and hellosign tasks,
  // so account for differences in checking whether the task is complete.
  // Universal tasks use an IntEnum, hellosign tasks use a string.
  return task.universal_task ? task.status === taskStatus.complete : task.status_code === 'signed';
};

export default function CandidateDashboardTasks({
  user,
  applications,
  schoolApplications,
  setTaskStatusComplete,
  hellosignSignatureTasks,
  setHellosignTaskStatus,
}) {
  let candidate_tasks = user.tasks.filter(t => t.universal_task.candidate_assigned === true);

  candidate_tasks = candidate_tasks.concat(hellosignSignatureTasks).sort(dueDateSort);

  if (candidate_tasks.length === 0) {
    return null;
  }

  return (
    <>
      <HeaderText>Outstanding tasks</HeaderText>
      <Container>
        {/* The hellosign signing tasks have a slightly different model than the universal
        tasks, so separate them by checking for the universal_task property. */}
        {candidate_tasks.map(task =>
          task.universal_task ? (
            <CandidateDashboardTasksRow
              key={task.id}
              user={user}
              applications={applications}
              schoolApplications={schoolApplications}
              task={task}
              setTaskStatusComplete={setTaskStatusComplete}
            />
          ) : (
            <CandidateDashboardHellosignTaskRow
              key={task.signature_id}
              task={task}
              setHellosignTaskStatus={setHellosignTaskStatus}
            />
          )
        )}
      </Container>
    </>
  );
}

CandidateDashboardTasks.propTypes = {
  user: PropTypes.object.isRequired,
  applications: PropTypes.arrayOf(PropTypes.object).isRequired,
  schoolApplications: PropTypes.arrayOf(PropTypes.object).isRequired,
  setTaskStatusComplete: PropTypes.func.isRequired,
  setHellosignTaskStatus: PropTypes.func.isRequired,
  hellosignSignatureTasks: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const Container = styled.div`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 5px;

  padding: 1.3rem;
`;

const HeaderText = styled.p`
  font-size: 19px;
  color: #444444;
  margin-top: 4rem;
  margin-bottom: 12px;
  margin-top: 2rem;
`;
