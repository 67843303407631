import { CAROUSEL_SLIDE_TYPES } from 'utils/constants';
import ValuePropImage1 from 'assets/connect/connect-value-prop-1.png';
import ValuePropImage2 from 'assets/connect/connect-value-prop-2.png';
import ValuePropImage3 from 'assets/connect/connect-value-prop-3.png';

export const CONNECT_ACTION_LABEL = 'Connect with principal';

export const defaultUserLocation = 'St. Louis, MO';

export const distanceFilterOptions = [
  { value: 'Any', label: 'Any' },
  { value: '5', label: '5 miles' },
  { value: '10', label: '10 miles' },
  { value: '25', label: '25 miles' },
  { value: '50', label: '50 miles' },
  { value: '75', label: '75 miles' },
  { value: '100', label: '100 miles' },
];

export const gradeFilterOptions = [
  { value: 'KG', label: 'Kindergarten' },
  { value: 'PK', label: 'Pre-kindergarten' },
  { value: '1', label: '1st Grade' },
  { value: '2', label: '2nd Grade' },
  { value: '3', label: '3rd Grade' },
  { value: '4', label: '4th Grade' },
  { value: '5', label: '5th Grade' },
  { value: '6', label: '6th Grade' },
  { value: '7', label: '7th Grade' },
  { value: '8', label: '8th Grade' },
  { value: '9', label: '9th Grade' },
  { value: '10', label: '10th Grade' },
  { value: '11', label: '11th Grade' },
  { value: '12', label: '12th Grade' },
];

export const gradePreferencesOptions = [
  { value: 13, label: 'Kindergarten' }, // This is jank, but our API expects 13 for kindergarten 🤷🏾‍♂️
  { value: 0, label: 'Pre-kindergarten' },
  { value: 1, label: '1st Grade' },
  { value: 2, label: '2nd Grade' },
  { value: 3, label: '3rd Grade' },
  { value: 4, label: '4th Grade' },
  { value: 5, label: '5th Grade' },
  { value: 6, label: '6th Grade' },
  { value: 7, label: '7th Grade' },
  { value: 8, label: '8th Grade' },
  { value: 9, label: '9th Grade' },
  { value: 10, label: '10th Grade' },
  { value: 11, label: '11th Grade' },
  { value: 12, label: '12th Grade' },
];

export const subjectFilterOptions = [
  { value: 'art', label: 'Art' },
  { value: 'business_marketing', label: 'Business & Marketing' },
  { value: 'cte', label: 'Career and Technical Education' },
  { value: 'comp_sci', label: 'Computer Science' },
  { value: 'early_childhood', label: 'Early Childhood' },
  { value: 'economics', label: 'Economics' },
  { value: 'elementary_multiple', label: 'Elementary/Multiple Subjects' },
  { value: 'english', label: 'English' },
  { value: 'ell', label: 'English Language Learner (ELL)' },
  { value: 'foreign_language', label: 'Foreign Language' },
  { value: 'geography', label: 'Geography' },
  { value: 'government', label: 'Government' },
  { value: 'history', label: 'History' },
  { value: 'humanities', label: 'Humanities' },
  { value: 'library', label: 'Library' },
  { value: 'math', label: 'Mathematics' },
  { value: 'music', label: 'Music' },
  { value: 'other', label: 'Other' },
  { value: 'physical_education', label: 'Physical Education' },
  { value: 'reading', label: 'Reading/Literacy' },
  { value: 'science', label: 'Science' },
  { value: 'social_science', label: 'Social Science' },
  { value: 'special_education', label: 'Special Education' },
];

export const subjectPreferencesOptions = [
  { value: 3, label: 'Art' },
  { value: 36, label: 'Business & Marketing' },
  { value: 37, label: 'Career & Technical Education' },
  { value: 4, label: 'Computer Science' },
  { value: 38, label: 'Early Childhood' },
  { value: 5, label: 'Economics' },
  { value: 12, label: 'Multiple subjects' },
  { value: 6, label: 'English' },
  { value: 39, label: 'English Language Learner (ELL)' },
  { value: 18, label: 'Foreign Language' },
  { value: 7, label: 'Geography' },
  { value: 8, label: 'Government' },
  { value: 9, label: 'History' },
  { value: 10, label: 'Humanities' },
  { value: 40, label: 'Library' },
  { value: 11, label: 'Mathematics' },
  { value: 13, label: 'Music' },
  { value: 19, label: 'Other' },
  { value: 14, label: 'Physical Education' },
  { value: 41, label: 'Reading & Literacy' },
  { value: 15, label: 'Science' },
  { value: 16, label: 'Social Science' },
  { value: 17, label: 'Special Education' },
];

export const valuePropSlides = [
  {
    type: CAROUSEL_SLIDE_TYPES.QUOTE,
    quote:
      '"I love that there are so many features included - job description, salary scale, map, days in workweek, ability to connect with leadership quickly - all in one place! Awesome time-savers."',
    authorName: 'Julie B.',
    authorJobTitle: 'Teacher in St. Louis, MO',
  },
  {
    type: CAROUSEL_SLIDE_TYPES.IMAGE_WITH_TEXT,
    title: 'Connect directly with principals',
    description:
      "Avoid dozens of long tedious applications. Share your qualifications directly with principals to get quick feedback on whether it's a fit.",
    image: ValuePropImage1,
  },
  {
    type: CAROUSEL_SLIDE_TYPES.IMAGE_WITH_TEXT,
    title: 'Schedule an intro call in just a few days',
    description:
      "Mark yourself as open to contact and fill out your profile - we'll match you with principals whose schools meet your criteria. If it's a good match, the principal will contact you via email.",
    image: ValuePropImage2,
  },
  {
    type: CAROUSEL_SLIDE_TYPES.IMAGE_WITH_TEXT,
    title: 'Be the first to know about teaching jobs',
    description:
      "See all MO teaching jobs in one place. Save schools you're interested in and receive alerts when they post new jobs that meet your criteria.",
    image: ValuePropImage3,
  },
];
