import { Autocomplete, TextField } from '@mui/material';
import { SearchableSelectProps } from './types';

export const SearchableSelect: React.FC<SearchableSelectProps> = ({
  options = [],
  placeholder,
  handleChange,
  disabled = false,
  name,
  value,
  error = false,
  helperText,
  sx,
}) => {
  return (
    <Autocomplete
      fullWidth
      disabled={disabled}
      options={options}
      onChange={handleChange}
      value={value}
      getOptionLabel={option => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={placeholder}
          name={name}
          sx={sx}
          error={error}
          helperText={error && helperText}
          InputProps={{ ...params.InputProps, sx: { borderRadius: '12px' } }}
        />
      )}
      disableClearable
    />
  );
};
