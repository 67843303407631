/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import talentMarketplaceAPI, {
  SearchTalentMarketplaceCandidatesParams,
  SearchTalentMarketplaceCandidatesResponse,
} from 'api/talentMarketplaceAPI';

import { grades, preferenceCategories } from './enums';

import {
  CandidateDistrictInformation,
  UserWithProfile,
  MarketplaceFitStatus,
  SearchTalentData,
} from 'types/types';
import auth from './auth';

export function fetchTalentMarketplaceCandidates(
  this: any,
  startSpinner: boolean,
  paginate = true,
  districtId: string | null = null
): Promise<SearchTalentData[] | void> {
  if (startSpinner) {
    this.startSpinner();
  }

  let page_size = 100;
  let page = 0;
  if (paginate === true) {
    page_size = 15;
    page = this.state.page;
    this.setState({
      fetchCandidatesRunning: true,
    });
  }

  const {
    query,
    currentTab,
    experienceStart,
    experienceEnd,
    credentialOptions,
    job_subject_length,
    orderBy,
    orderDir,
  } = this.state;

  if (!districtId) {
    const user: UserWithProfile = auth.getUser();
    if (user && user.profile) {
      districtId = String(user.profile.district.id);
    }
  }

  // TODO: we can probably update the currentTab to use the title string instead
  // of an integer. Then we can pass that value to the api instead of a switch case.
  const tabsToMarketplaceFitStatus = {
    1: MarketplaceFitStatus.NO_FIT,
    2: MarketplaceFitStatus.POTENTIAL_FIT,
    3: MarketplaceFitStatus.CONVERTED_TO_PROSPECT,
  };
  const marketplaceFitStatus = tabsToMarketplaceFitStatus[currentTab] || null;

  /** Make copies using slice, instead of directly setting equal to state.*/
  let cred_list = this.state.cred_list.slice();
  let job_subject_list = this.state.job_subject_list.slice();
  let multilingual_school = this.state.multilingual_school.slice();
  let title_1_school = this.state.title_1_school.slice();
  let turnaround_school = this.state.turnaround_school.slice();
  let selectedGrades = this.state.grades.slice();
  let preference_categories = this.state.preference_categories.slice();

  /**
   * Optimize filters by comparing the lengths of lists and replacing empty arrays where needed.
   * When all filters are selected, we don't filter on the backend as 'select_all' === 'clear all'
   **/

  if (selectedGrades.length === grades().length) {
    selectedGrades = [];
  }

  if (preference_categories.length === preferenceCategories().length) {
    preference_categories = [];
  }

  if (job_subject_list.length === job_subject_length) {
    job_subject_list = [];
  }

  if (cred_list.length === credentialOptions.length) {
    cred_list = [];
  }

  if (multilingual_school.length === 3) {
    // length of 3 means all options are selected, so no need to send to backend
    multilingual_school = [];
  }
  if (title_1_school.length === 3) {
    // length of 3 means all options are selected, so no need to send to backend
    title_1_school = [];
  }
  if (turnaround_school.length === 3) {
    // length of 3 means all options are selected, so no need to send to backend
    turnaround_school = [];
  }

  // Map the state variables to API parameters
  const params: SearchTalentMarketplaceCandidatesParams = {
    query,
    district_id: districtId,
    marketplace_fit_status: marketplaceFitStatus,
    experience_start: experienceStart,
    experience_end: experienceEnd,
    credential_subjects: cred_list,
    job_subject_list,
    page: page + 1,
    page_size,
    multilingual_school,
    title_1_school,
    turnaround_school,
    grades: selectedGrades,
    preference_categories,
    orderBy: orderBy || 'years_experience',
    orderDir: orderDir || 'desc',
  };

  return talentMarketplaceAPI
    .searchTalentMarketplaceCandidates(params)
    .then((data: SearchTalentMarketplaceCandidatesResponse) => {
      if (this.ignoreLastFetch) return [];

      if (paginate) {
        const res: SearchTalentData[] = data.results;

        const incomingCandidates: CandidateDistrictInformation[] = res.map(row => {
          return {
            user: row,
            marketplace_fit_status: row.marketplace_fit_status,
            categories_of_interest_count: row.categories_of_interest_count,
          };
        });
        const finalListOfCandidates: CandidateDistrictInformation[] = [];
        const finalListOfCandidatesSet: Set<number> = new Set();

        // if page > 1, start with candidates in state & add to it. Otherwise start with empty list
        if (data.page > 1) {
          this.state.candidates.forEach(c => {
            finalListOfCandidatesSet.add(c.id);
            finalListOfCandidates.push(c);
          });
        }

        incomingCandidates.forEach(candidate => {
          if (!finalListOfCandidatesSet.has(candidate.user.id)) {
            finalListOfCandidates.push(candidate);
          }
        });

        this.setState({
          candidates: finalListOfCandidates,
          page: data.page,
          more: data.more,
          fetchCandidatesCompleted: true,
          fetchCandidatesRunning: false,
          totalCandidateCount: data.count,
          elasticSearchError: false,
        });
        this.stopSpinner();
        return undefined;
      } else {
        return data.results;
      }
    })
    .catch((error: any) => {
      if (!talentMarketplaceAPI.isCancel(error)) {
        if (error.response && error.response.data && error.response.data.elasticsearch) {
          this.setState({
            elasticSearchError: true,
          });
          this.stopSpinner();
        }
        Promise.reject(error);
      }
    });
}

export function fetchTalentMarketplaceCandidatesDebounced(this: any) {
  /** Note: This was ported from the candidatesSearchUtil.js on May 17th, 2023
   *
   * this isn't a true debounce. We'll just wait until the spinner is turned off,
   * which means the page is done loading, so the effect is the same. This solves the
   * issue of scrolling while the next candidates are still loading, which causes duplicate
   * data to return.
   */
  if (this.state.fetchCandidatesRunning == true) return;

  this.fetchTalentMarketplaceCandidates(false);
}
