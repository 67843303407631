import { useState } from 'react';

import { blankNotification } from './utils';
import { Box, useTheme } from '@mui/material';
import { EndColumnSection, Heading } from 'components/JobEdit/JobDetails';
import { JobClosureNotificationsProps, LocalJobClosureNotification } from './types';
import { JobClosureModal } from './JobClosureModal';
import {
  useCreateNotificationMutation,
  useDeleteNotificationMutation,
  useEditNotificationMutation,
} from './mutations';
import { useQueryGetJobClosureNotifications } from './queries';
import { SecondaryButton } from 'sharedComponents/Buttons';
import { ATSJobClosureNotificationTestIds } from 'data-testids/ATS';

export const JobClosureNotifications: React.FC<JobClosureNotificationsProps> = ({ jobId }) => {
  const [pendingNotification, setPendingNotification] = useState<LocalJobClosureNotification>(
    blankNotification
  );
  const [isModalActive, setIsModalActive] = useState<boolean>(false);
  const { data, isLoading } = useQueryGetJobClosureNotifications(jobId);
  const hasExistingNotifications: boolean = data?.length > 0;
  const previousAlert = hasExistingNotifications ? data[0] : {};
  const theme = useTheme();

  const handleOpenModal = () => setIsModalActive(true);
  const handleCloseModal = () => setIsModalActive(false);
  const handleThresholdChange = (event: { target: { value: string } }) =>
    setPendingNotification(previousState => ({
      ...previousState,
      threshold: Number(event.target.value),
    }));

  const addNotificationQuery = useCreateNotificationMutation({
    onSuccess: () => {
      setPendingNotification(blankNotification);
      return setIsModalActive(false);
    },
  });

  const editNotificationQuery = useEditNotificationMutation({
    onSuccess: () => {
      setPendingNotification(blankNotification);
      return setIsModalActive(false);
    },
  });

  const deleteNotificationQuery = useDeleteNotificationMutation({
    onSuccess: () => {
      setPendingNotification(blankNotification);
      setIsModalActive(false);
    },
  });

  const handleAdd = () => {
    return addNotificationQuery.mutate({ jobId, pendingNotification });
  };

  const handleEdit = () => {
    const { applicationStatusId, subscribers, threshold } = pendingNotification;
    const { alerts, role_notification_id } = previousAlert;
    const oldAlert = alerts ? alerts[0] : {};

    const updatedNotification: LocalJobClosureNotification = {
      threshold: !threshold ? oldAlert.context.threshold : threshold,
      applicationStatusId: !applicationStatusId
        ? oldAlert.context.application_status_id
        : applicationStatusId,
      subscribers: !subscribers ? previousAlert.subscribers : subscribers,
    };

    return editNotificationQuery.mutate({
      notificationId: role_notification_id,
      jobId,
      pendingNotification: updatedNotification,
    });
  };

  const handleDelete = () => {
    const notificationId = Number(previousAlert?.role_notification_id);
    return deleteNotificationQuery.mutate({ jobId, notificationId });
  };

  return (
    <>
      <EndColumnSection data-testid={ATSJobClosureNotificationTestIds.JOB_CLOSURE_SECTION}>
        <Heading data-testid={ATSJobClosureNotificationTestIds.HEADING}>
          Job closure notification
        </Heading>

        {hasExistingNotifications ? (
          <Box sx={{ display: 'flex' }}>
            <button disabled={isLoading} onClick={handleOpenModal}>
              Edit
            </button>
            <button disabled={isLoading} onClick={handleDelete}>
              Delete
            </button>
          </Box>
        ) : (
          <SecondaryButton
            sx={{ fontWeight: theme.typography.fontWeightBold }}
            dataTestId={ATSJobClosureNotificationTestIds.ADD_NOTIFICATION_BUTTON}
            size="extraLarge"
            onClick={handleOpenModal}
          >
            + Add Notification
          </SecondaryButton>
        )}
      </EndColumnSection>

      {isModalActive && (
        <JobClosureModal
          handleAdd={handleAdd}
          handleCloseModal={handleCloseModal}
          handleEdit={handleEdit}
          handleThresholdChange={handleThresholdChange}
          hasExistingNotifications={hasExistingNotifications}
          isLoading={isLoading}
          isModalActive={isModalActive}
          thresholdValue={
            hasExistingNotifications ? previousAlert.alerts[0].context.threshold : null
          }
        />
      )}
    </>
  );
};
