import styled from 'styled-components';

import { CheckboxVariantProps } from './types';

const CheckboxVariant: React.FC<CheckboxVariantProps> = ({
  fetchedTags,
  handleSetAgreeOptionValue,
  handleSetDisagreeOptionValue,
  agreeOptionValue,
  disagreeOptionValue,
}) => {
  const handleAgreeChange = event => {
    const value = event.target.value;

    if (value === 'agree-answer') {
      handleSetAgreeOptionValue(null);
    } else {
      handleSetAgreeOptionValue(value);
    }
  };

  const handleDisagreeChange = event => {
    const value = event.target.value;

    if (value === 'disagree-answer') {
      handleSetDisagreeOptionValue(null);
    } else {
      handleSetDisagreeOptionValue(value);
    }
  };

  return (
    <Container>
      <IndividualOption>
        If candidate agrees:
        <Select
          onChange={handleAgreeChange}
          value={agreeOptionValue ? agreeOptionValue : 'agree-answer'}
        >
          <Option value="agree-answer">Select a tag</Option>
          {fetchedTags.map(fetchedTag => {
            return (
              <Option value={fetchedTag.id} key={fetchedTag.id}>
                {fetchedTag.label}
              </Option>
            );
          })}
        </Select>
      </IndividualOption>

      <IndividualOption>
        If candidate disagrees:
        <Select
          onChange={handleDisagreeChange}
          value={disagreeOptionValue ? disagreeOptionValue : 'disagree-answer'}
        >
          <Option value="disagree-answer">Select a tag</Option>
          {fetchedTags.map(fetchedTag => (
            <Option value={fetchedTag.id} key={fetchedTag.label}>
              {fetchedTag.label}
            </Option>
          ))}
        </Select>
      </IndividualOption>
    </Container>
  );
};

export default CheckboxVariant;

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const Select = styled('select')({
  border: '1px solid #d7d7d7',
  borderRadius: '3px',
  padding: '14px 16px',
  background: 'white',
  width: '375px',
  marginLeft: '16px',
  flexGrow: 1,
});

const IndividualOption = styled('div')({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '24px',
});

const Option = styled('option')({
  paddingBottom: '8px',
});
