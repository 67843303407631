import { Component } from 'react';

import PropTypes from 'prop-types';

import UniversalTasksModal from './UniversalTasksModal';
import { DeleteButton } from 'sharedComponents/Buttons/DeleteButton';
import { EditButton } from 'sharedComponents/Buttons/EditButton';
import { SettingsRow } from 'components/SettingsRow';
import { Box } from '@mui/material';

export default class UniversalTaskRow extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    task: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    adminUsers: PropTypes.array.isRequired,
    newApplicationStatuses: PropTypes.array.isRequired,
  };

  state = {
    showTaskModal: false,
  };

  saveRow = taskObj => {
    this.setState({ showTaskModal: false });
    this.props.onSave(taskObj, this.props.index);
  };

  renderAssignedUsers = task => {
    if (!task.assigned_users || task.assigned_users.length === 0) {
      return null;
    }
    let str = 'Assigned to ';
    let count = 0;
    // if this is true, render the "... and 4 more" part.
    let extraUsers = false;
    // iterate over assigned_users and add name to string.
    // will eventually end up like: “Assigned to Lauren Dachille, Billy Gray, and 2 more”
    for (let i = 0; i < task.assigned_users.length; i++) {
      count += 1;
      if (count < 3) {
        // first two names go into string normally.
        str += task.assigned_users[i].name + ', ';
      } else {
        // if more than two, have to render "and 2 more" (or however many more there are)
        extraUsers = true;
        break;
      }
    }

    let suffix = extraUsers ? ` and ${count - 2} more` : '';

    let finalString = `${str.slice(0, -2)}${suffix}`;
    finalString = finalString + ' - ';
    return finalString;
  };

  getStatusLabel = task => {
    let status = this.props.newApplicationStatuses.find(s => s.id === task.trigger_status_id);
    return status && status.label;
  };

  deleteTask = e => {
    e.stopPropagation();
    this.props.onDelete(this.props.task.id);
  };

  render() {
    let { task } = this.props;

    return (
      <>
        <SettingsRow
          onClick={() => this.setState({ showTaskModal: true })}
          cta={
            <Box sx={{ display: 'flex' }}>
              <EditButton />
              {!task.zapier_task && <DeleteButton onClick={this.deleteTask} />}
            </Box>
          }
        >
          <div>
            <div className="status-label">{task.title}</div>
            <div className="grey mt0">
              {this.renderAssignedUsers(task)}
              <span>Due {task.trigger_days} days after trigger</span>
              {task.zapier_task ? (
                <span>- This task is triggered by a Zapier integration</span>
              ) : (
                <span>- Triggered when candidate is moved to: {this.getStatusLabel(task)}</span>
              )}
            </div>
          </div>
        </SettingsRow>
        {this.state.showTaskModal && (
          <UniversalTasksModal
            task={task}
            show={this.state.showTaskModal}
            onHide={() => this.setState({ showTaskModal: false })}
            onSave={this.saveRow}
            adminUsers={this.props.adminUsers}
            newApplicationStatuses={this.props.newApplicationStatuses}
          />
        )}
      </>
    );
  }
}
