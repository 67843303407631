import styled from 'styled-components';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { ActivityFeed } from 'features/JobActivityFeed/ActivityFeed';
import { Box } from '@mui/material';
import { BulletPoint } from 'ui-kit/icons';
import { getQuestionText } from '../utils';
import { JobAdditionalDetailsProps } from '../types';
import { JobViewBlock } from './JobViewBlock';
import { makeAllUrlsValid } from 'utils/util';
import { sortQuestionsAndAttachments } from 'utils/roleutils';
import auth from 'utils/auth';
import jobActivityFeed from 'api/jobActivityFeed';

export const JobAdditionalDetails: React.FC<JobAdditionalDetailsProps> = ({
  internalNoteRef,
  isInternalCandidate,
  job,
  onInternalPage,
  shouldShowActivityFeed,
  showJobViewField,
  showInternalInfo,
}) => {
  const [allActivity, setAllActivity] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasActivity, setHasActivity] = useState(false);
  const [hasErrorFetchingActivity, setHasErrorFetchingActivity] = useState(false);
  const combinedQuestions = [...(job.questions ?? []), ...(job.custom_questions ?? [])];

  useEffect(() => {
    if (auth.isDistrictAdmin()) {
      jobActivityFeed
        .getAllActivity(job.id)
        .then(response => {
          setAllActivity(response);
          if (response.length > 0) {
            [setHasActivity(true)];
          }
          setIsLoading(false);
        })
        .catch(() => {
          setHasErrorFetchingActivity(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (job.school_preferences_question) {
    combinedQuestions.push(job.school_preferences_question);
  }

  const getAdditionalInternalInfo = item => {
    if (!item.is_visible_internal) {
      return '(not visible to internal candidates)';
    }
    if (!item.is_visible_external) {
      return '(not visible to external candidates)';
    }
    if (!item.is_required_internal && item.is_required_external) {
      return '(not required for internal candidates)';
    }
    if (!item.is_required_external && item.is_required_internal) {
      return '(not required for external candidates)';
    }
    return '';
  };

  const getHTML = field => {
    if (job[field] === null) {
      return null;
    }

    return { __html: makeAllUrlsValid(job[field]) };
  };

  const onInternalPageOrQuestionIsVisible = question => {
    if (onInternalPage) {
      return true;
    } else {
      if (isInternalCandidate) {
        return question.is_visible_internal;
      } else {
        return question.is_visible_external;
      }
    }
  };

  const isRequired = question => {
    if (onInternalPage) {
      return question.is_required_external || question.is_required_internal;
    } else {
      if (isInternalCandidate) {
        return question.is_required_internal;
      } else {
        return question.is_required_external;
      }
    }
  };

  const sortedAttachments = sortQuestionsAndAttachments(
    job.requiredapplicationattachment_set || []
  );

  const sortedInternalNotes = job.internal_role_notes.sort(function(a, b) {
    return b.id - a.id;
  });

  const sortedQuestions = sortQuestionsAndAttachments(combinedQuestions);

  const shouldShowAdditionalQuestions =
    (showJobViewField('questions', false) ||
      (showJobViewField('custom_questions', false) &&
        sortedQuestions.filter(question => question.included_in_preview).length > 0) ||
      showJobViewField('school_preferences_question', false)) &&
    !job.district.posting_only;

  const filteredSortedAdditionalQuestions = sortedQuestions
    .filter(onInternalPageOrQuestionIsVisible)
    .filter(question => question.included_in_preview);

  return (
    <>
      {shouldShowActivityFeed && (
        <JobViewBlock>
          <ActivityFeed
            hasActivity={hasActivity}
            hasErrorFetchingActivity={hasErrorFetchingActivity}
            jobName={job.title}
            isLoading={isLoading}
            allActivity={allActivity}
          />
        </JobViewBlock>
      )}
      {showJobViewField('description', false) && (
        <JobViewBlock title="Job Description">
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={getHTML('description')}
            data-testid="description"
          />
        </JobViewBlock>
      )}

      {showJobViewField('benefits', false) && (
        <JobViewBlock title="Benefits">
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={getHTML('benefits')}
            data-testid="benefits"
          />
        </JobViewBlock>
      )}

      {shouldShowAdditionalQuestions && (
        <JobViewBlock title="Additional Questions">
          <>
            <Box sx={{ paddingBottom: '28px' }}>
              The following questions will be part of this job application. Those marked with an
              asterisk will be required to submit your application.
            </Box>
            {filteredSortedAdditionalQuestions.map(question => (
              <div key={question.id} className="jobview-card-row">
                <div className={`inline-block ${isRequired(question) ? 'is-required' : ''}`}>
                  <span data-testid="additional-questions">{getQuestionText(question)}</span>
                  {showInternalInfo && (
                    <LighterText> {getAdditionalInternalInfo(question)}</LighterText>
                  )}
                </div>
              </div>
            ))}
          </>
        </JobViewBlock>
      )}

      {showJobViewField('requiredapplicationattachment_set', false) && (
        <JobViewBlock title="Attachments">
          <Box sx={{ paddingBottom: '28px' }}>
            The following attachments will be part of this job application. Those marked with an
            asterisk will be required to submit your application.
          </Box>
          {sortedAttachments.map(attachment => (
            <div key={attachment.id} data-testid="attachment">
              <div className={`inline-block ${isRequired(attachment) ? 'is-required' : ''}`}>
                <span>
                  {`${attachment.title}${
                    attachment.description ? ` - "${attachment.description}"` : ''
                  }`}{' '}
                </span>
                {showInternalInfo && (
                  <LighterText>{getAdditionalInternalInfo(attachment)}</LighterText>
                )}
              </div>
            </div>
          ))}
        </JobViewBlock>
      )}
      {/* Wrapper div ensures that urls go to internal role sections even if not loaded */}
      <div id="internal_notes" ref={internalNoteRef}>
        {showJobViewField('internal_role_notes', true) && (
          <JobViewBlock title="Internal Notes">
            {sortedInternalNotes.map((note, index) => {
              return (
                <LineItem key={index} data-testid="internal-note" className="ql-editor">
                  <BulletPoint />
                  <div
                    style={{
                      lineHeight: '24px',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      <NoteText
                        dangerouslySetInnerHTML={{
                          __html: makeAllUrlsValid(note.text),
                        }}
                      />
                      <Signature data-testid="internal-note-signature">
                        {`${note.created_by?.name || 'Unspecified user'} ${
                          note?.created_at
                            ? moment(note.created_at).format('- MM/DD/YYYY [at] h:mm a')
                            : ''
                        }`}
                        {note.is_edited && ' (edited)'}
                      </Signature>
                    </div>
                  </div>
                </LineItem>
              );
            })}
          </JobViewBlock>
        )}
      </div>
    </>
  );
};

const LighterText = styled.i`
  color: #909090;
  opacity: 0.9;
`;

const Signature = styled.div`
  font-size: 12px;
  color: var(--darkgray);
`;

const LineItem = styled.div`
  display: grid;
  grid-template-columns: 10px 1fr;
  grid-gap: 12px;
  align-items: baseline;
  margin-bottom: 10px;
  background-color: ${props => props.theme.main};
`;

const NoteText = styled.div`
  .mention {
    font-weight: bold;
  }
  li {
    margin-left: 20px;
  }
  a {
    color: #0066cc;
    text-decoration: underline;
  }
`;
