import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import InfoIcon from 'assets/icons/icon_info.svg';
import { USStates } from 'utils/enums';

export const DashboardHeaderContainer = ({ title, applicationCount, districtCount, stateCode }) => {
  const getStateFullName = stateCode => {
    return USStates().find(s => s.label === stateCode)?.title;
  };
  return (
    <DashboardTitleContainer>
      <TitleText>{title}</TitleText>
      <SubtitleText>
        Showing data for {applicationCount}
        {applicationCount === 1 ? ' application' : ' applications'}
        <StyledInfoIcon
          src={InfoIcon}
          alt="info-icon-applications"
          data-tip={`
                  This number represents the total number of <br/>
                  applications handled by the Nimble ATS. Several <br/>
                  graphs in this dashboard depend on application.<br/>
                  data from our internal applicant tracking system <br/>
                  (ATS). The data is therefore not representative of <br/>
                  application data handled in a separate ATS.
                `}
          data-for="dashboard-applications-info"
        />
        <ReactTooltip id="dashboard-applications-info" effect="solid" multiline />
      </SubtitleText>
      <SubtitleText>
        Showing data for {districtCount} {districtCount === 1 ? 'organization' : 'organizations'}
        <StyledInfoIcon
          src={InfoIcon}
          alt="info-icon-organizations"
          data-tip={`
                  This number represents the total number of <br/>
                  organizations using the ${getStateFullName(stateCode)} Department <br/>
                  of Education state job board to post jobs. Several <br/>
                  graphs in this dashboard depend on job posting data <br/>
                  in Nimble that is taken from this state job board. The <br/>
                  data is therefore not representative of postings using <br/>
                  separate job boards. <br/>
                `}
          data-for="dashboard-organizations-info"
        />
        <ReactTooltip id="dashboard-organizations-info" effect="solid" multiline />
      </SubtitleText>
    </DashboardTitleContainer>
  );
};

// Several graphs in this dashboard depend on job
// posting data in Nimble that is taken from this
// state job board.
// The data is therefore not representative of
// postings that are posted using separate job boards.
const DashboardTitleContainer = styled.div``;

const TitleText = styled.div`
  font-size: 36px;
`;

const SubtitleText = styled.div`
  font-size: 16px;
  padding-bottom: 10px;
`;

const StyledInfoIcon = styled.img`
  width: 15px;
  margin-left: 5px;
`;
