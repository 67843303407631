import { Redirect, Route } from 'react-router-dom';

import { getInvalidUserTypeRedirectPathname } from 'utils/userType';
import useCurrentUserPermissions from 'hooks/useCurrentUserPermissions';
import auth from 'utils/auth';

const UnauthenticatedRoute = props => {
  const { hasApplications, userType } = useCurrentUserPermissions();
  const to = getInvalidUserTypeRedirectPathname(userType, hasApplications);
  const user = auth.getUser();

  if (!user) {
    return <Route {...props} />;
  } else if (userType) {
    return <Redirect to={to} />;
  }

  return <Route {...props} />;
};

export default UnauthenticatedRoute;
