import styled from 'styled-components';
import { layout } from 'styled-system';

/**
 * Component used to display a district / jobboard logo.
 *
 * Usage:
 * <Logo maxHeight={200} src="jobboard_logos/nimble.png" alt="" />
 */
export const Logo = styled.img(layout);

Logo.defaultProps = {
  maxHeight: 150,
  height: 'auto',
  width: 'auto'
};
