import { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import DeleteModal from 'components/JobEdit/DeleteModal';
import HelloSignFormModal from './helloSignFormModal';
import HelloSignPacketModal from './helloSignPacketModal';
import HellosignTemplateIFrameModal from 'features/HellosignEmbeddedSigning/HellosignTemplateIFrameModal';
import CandidateFormRow from './CandidateFormRow';
import { showMessage, showWarning } from 'utils/message';
import { openHSiFrame, makeHSClient } from 'utils/helloSignUtil';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from 'sharedComponents/Buttons';

const client = makeHSClient();

export default class CandidateForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      packets: [],
      templates: [],
      packetIDToDelete: null,
      templateIDToDelete: null,
      helloSignFormModalOpen: false,
      hellosignIFrameOpen: false,
      signerOptions: [],
      helloSignEditingTemplate: null,
      helloSignEditingPacket: null,
      helloSignPacketErrors: null,
      isLoading: false,
      // if deleteOnCancel is true, delete the hellosign template from our db when
      // the user clicks "cancel" on the bottom of the modal. This is because the
      // template gets created in our db but not in Hellosign's system when the user
      // closes out of the modal after passing the first page.
      deleteOnCancel: false,
    };
  }

  static propTypes = {
    adminUsers: PropTypes.array.isRequired,
  };

  componentDidMount() {
    this.fetchPackets();
    this.fetchTemplates();
    const signerOptions = this.props.adminUsers.map(a => {
      return {
        label: a.name,
        value: a.id,
      };
    });
    signerOptions.unshift({ value: -1, label: 'Candidate' });
    this.setState({ signerOptions });
  }

  fetchPackets = () => {
    axios.get(`/api/hellosign-packets/`).then(r => {
      this.setState({ packets: r.data });
    });
  };

  fetchTemplates = () => {
    axios.get(`/api/hellosign_templates/`).then(r => {
      this.setState({ templates: r.data });
    });
  };

  sendTemplate = (form, editID = false) => {
    this.setState({
      isLoading: true,
      helloSignFormModalOpen: false,
      hellosignIFrameOpen: true,
    });

    const { title, file, directions, signer_one, signer_two, signer_three, fieldObj } = form;
    const formData = new FormData();
    if (!editID) {
      formData.append('title', title);
      formData.append('file', file, file.name);
      formData.append('signer_one', JSON.stringify(signer_one));
      formData.append('signer_two', JSON.stringify(signer_two));
      formData.append('signer_three', JSON.stringify(signer_three));
    }
    formData.append('directions', directions);
    formData.append('fieldObj', JSON.stringify(fieldObj));

    editID ? this.putTemplate(formData, editID) : this.postTemplate(formData);
  };

  postTemplate = formData => {
    axios.post(`/api/hellosign/`, formData).then(r => {
      const newTemplate = r.data;
      // Insert item at top of templates
      const updateTemplates = this.state.templates;
      updateTemplates.unshift(newTemplate);
      this.setState(
        {
          templates: updateTemplates,
          isLoading: false,
          helloSignEditingTemplate: newTemplate,
          deleteOnCancel: true,
        },
        () =>
          // Open the iframe to finalize
          openHSiFrame(
            client,
            newTemplate.edit_url,
            () => this.deleteTemplate(newTemplate.id),
            this.closeHellosignIFrame
          )
      );
    });
  };

  putTemplate = (formData, editID) => {
    axios.put(`/api/hellosign/${editID}/`, formData).then(r => {
      // Switch out the old template for the updated one
      let updateTemplates = this.state.templates;
      updateTemplates = updateTemplates.filter(t => t.id !== r.data.id);
      updateTemplates.unshift(r.data);
      this.setState(
        {
          templates: updateTemplates,
          isLoading: false,
        },
        () =>
          // Open the iframe to finalize
          openHSiFrame(client, r.data.edit_url, null, this.closeHellosignIFrame)
      );
    });
  };

  deletePacket = packetID => {
    axios
      .delete(`/api/hellosign-packets/${packetID}/`)
      .then(r => {
        const updatePackets = this.state.packets.filter(p => p.id !== Number(packetID));
        this.setState({
          packets: updatePackets,
          packetIDToDelete: null,
        });
        showMessage('Packet deleted.');
      })
      .catch(() =>
        showWarning(
          'There was an error deleting this packet. Please contact support@hirenimble.com for assistance.'
        )
      );
  };

  deleteTemplate = templateID => {
    axios
      .delete(`/api/hellosign/${templateID}/`)
      .then(r => {
        const updateTemplates = this.state.templates.filter(t => t.id !== Number(templateID));
        this.setState({
          templates: updateTemplates,
          templateIDToDelete: null,
        });
        showMessage('Form deleted.');
      })
      .catch(() =>
        showWarning(
          'There was an error deleting this form. Please contact support@hirenimble.com for assistance.'
        )
      );
  };

  setHelloSignEditingPacket = packet =>
    this.setState({
      helloSignEditingPacket: packet,
    });

  startHelloSignPacketCreation = () =>
    this.setHelloSignEditingPacket({
      id: null,
      title: '',
      template_ids: [],
    });

  startHelloSignPacketEditing = packet =>
    this.setHelloSignEditingPacket({
      id: packet.id,
      title: packet.title,
      template_ids: packet.templates.map(template => template.id),
    });

  clearHelloSignEditingPacket = () =>
    this.setState({
      helloSignEditingPacket: null,
      helloSignPacketErrors: null,
    });

  saveHelloSignEditingPacket = () => {
    const editingPacketId = this.state.helloSignEditingPacket.id;

    if (editingPacketId) {
      axios
        .put(`/api/hellosign-packets/${editingPacketId}/`, this.state.helloSignEditingPacket)
        .then(r => {
          this.setState({
            packets: this.state.packets.map(packet =>
              packet.id === editingPacketId ? r.data : packet
            ),
            helloSignEditingPacket: null,
            helloSignPacketErrors: null,
          });
          showMessage('Packet saved.');
        })
        .catch(e => {
          this.setState({ helloSignPacketErrors: e.response?.data });
        });
    } else {
      axios
        .post(`/api/hellosign-packets/`, this.state.helloSignEditingPacket)
        .then(r => {
          this.setState({
            packets: [r.data, ...this.state.packets],
            helloSignEditingPacket: null,
            helloSignPacketErrors: null,
          });
          showMessage('Packet saved.');
        })
        .catch(e => {
          this.setState({ helloSignPacketErrors: e.response?.data });
        });
    }
  };

  openHelloSignForm = template => {
    this.setState({ helloSignFormModalOpen: true, helloSignEditingTemplate: template });
  };

  closeHellosignIFrame = () => {
    client.close();
    this.setState({ hellosignIFrameOpen: false, deleteOnCancel: false });
  };

  onCancelIFrame = () => {
    if (this.state.helloSignEditingTemplate?.id && this.state.deleteOnCancel) {
      this.deleteTemplate(this.state.helloSignEditingTemplate.id);
    }
    this.closeHellosignIFrame();
  };

  render() {
    return (
      <div className="candidate-forms-container mb2" ref="myRef">
        {this.state.helloSignEditingPacket ? (
          <HelloSignPacketModal
            helloSignPacket={this.state.helloSignEditingPacket}
            setHelloSignPacket={this.setHelloSignEditingPacket}
            helloSignTemplates={this.state.templates}
            errors={this.state.helloSignPacketErrors}
            onSave={this.saveHelloSignEditingPacket}
            onClose={this.clearHelloSignEditingPacket}
          />
        ) : null}
        {this.state.hellosignIFrameOpen && (
          <HellosignTemplateIFrameModal
            show={this.state.hellosignIFrameOpen}
            onClose={this.onCancelIFrame}
            editMode={!!this.state.helloSignEditingTemplate?.id}
            isLoading={this.state.isLoading}
          />
        )}
        {this.state.helloSignFormModalOpen && (
          <HelloSignFormModal
            show={this.state.helloSignFormModalOpen}
            onHideHelloSignFormModal={() => this.setState({ helloSignFormModalOpen: false })}
            sendTemplate={this.sendTemplate}
            signerOptions={this.state.signerOptions}
            helloSignEditingTemplate={this.state.helloSignEditingTemplate}
            adminUsers={this.props.adminUsers}
          />
        )}
        {this.state.packetIDToDelete && (
          <DeleteModal
            show={true}
            onHide={() => this.setState({ packetIDToDelete: null })}
            onSave={() => this.deletePacket(this.state.packetIDToDelete)}
            title="Delete Packet"
            message="Are you sure you want to delete this packet?"
            submitLabel="Delete"
            cancelLabel="Cancel"
          />
        )}
        {this.state.templateIDToDelete && (
          <DeleteModal
            show={true}
            onHide={() => this.setState({ templateIDToDelete: null })}
            onSave={() => this.deleteTemplate(this.state.templateIDToDelete)}
            title="Delete Form"
            message="Are you sure you want to delete this form? This form will also be removed from packets."
            submitLabel="Delete"
            cancelLabel="Cancel"
          />
        )}
        <div id="candidateForms">
          <div className="scorecard-list-header-div" style={{ alignItems: 'center' }}>
            <h4 className="bold inline-block" data-testid="candidate-forms">
              Candidate Forms
            </h4>
            <div>
              <SecondaryButton
                className="scorecard-list-header-btn-secondary"
                onClick={this.startHelloSignPacketCreation}
                sx={{ width: '206px', height: '48px', marginRight: '16px' }}
              >
                Create Packet
              </SecondaryButton>
              <PrimaryButton
                className="scorecard-list-header-btn"
                onClick={this.openHelloSignForm}
                sx={{ width: '206px', height: '48px' }}
              >
                + Add Form
              </PrimaryButton>
            </div>
          </div>
        </div>
        <h5 className="bold mt1 mb1">Packets</h5>
        <div>
          {this.state.packets.map(p => {
            return (
              <CandidateFormRow
                key={p.id}
                template={p}
                deleteTemplate={packetID => this.setState({ packetIDToDelete: packetID })}
                editTemplate={this.startHelloSignPacketEditing}
              />
            );
          })}
        </div>
        <h5 className="bold mt1 mb1">Forms</h5>
        <div>
          {this.state.templates.map(t => {
            return (
              <CandidateFormRow
                key={t.id}
                template={t}
                deleteTemplate={templateID => this.setState({ templateIDToDelete: templateID })}
                editTemplate={this.openHelloSignForm}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

const CreatePacketButton = styled('button')({
  backgroundColor: 'transparent',
  border: '1px solid #828282',
  borderRadius: '3px',
  color: '#616161',
  fontWeight: 800,
  height: '50px',
  marginRight: '20px',
  width: '156px',
});
