import { ButtonProps } from './types';
import { StyledSecondaryButton } from './styles';
import { CircularProgress } from '@mui/material';

export const SecondaryButton: React.FC<ButtonProps> = ({
  children,
  disabled,
  href,
  isLoading,
  onClick,
  size,
  startIcon,
  endIcon,
  sx,
  dataTestId,
}: ButtonProps) => {
  return (
    <StyledSecondaryButton
      disabled={disabled}
      onClick={onClick}
      size={size}
      variant="outlined"
      startIcon={!isLoading && startIcon}
      endIcon={!isLoading && endIcon}
      sx={sx}
      href={href}
      data-testid={dataTestId ? dataTestId : 'secondary-button'}
    >
      {isLoading ? <CircularProgress /> : children}
    </StyledSecondaryButton>
  );
};
