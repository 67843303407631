import { useState } from 'react';
import PropTypes from 'prop-types';

import iconUpCaretGreen from 'assets/icons/FilterIcons/icon-up-caret-with-circle-green.svg';
import iconDownCaretGreen from 'assets/icons/FilterIcons/icon-down-caret-with-circle-green.svg';
import iconUpCaret from 'assets/icons/FilterIcons/icon-up-caret-with-circle-grey.svg';
import iconDownCaret from 'assets/icons/FilterIcons/icon-down-caret-with-circle-grey.svg';

import {
  grades as gradesEnum,
  early_childhood_grades,
  childhood_grades,
  middle_grades,
  secondary_grades,
} from 'utils/enums';

const EARLYCHILDHOOD = early_childhood_grades().map(grade => grade.value);
const ELEMENTARY = childhood_grades().map(grade => grade.value);
const MIDDLE = middle_grades().map(grade => grade.value);
const SECONDARY = secondary_grades().map(grade => grade.value);

export default function GradesFilter({ grades, setGrades, setAllGrades, isActive }) {
  const [showOptions, setShowOptions] = useState(false);

  const toggleOptions = () => {
    setShowOptions(prev => !prev);
  };

  const gradesCheckedInSection = section => {
    const isCurrentlySelected = grade => grades.includes(grade);

    // return true if all grades in that section are checked, otherwise return false
    return section.every(isCurrentlySelected);
  };

  const updateGradesInSection = (e, section) => {
    e.stopPropagation();

    let updatedGrades = [...grades];

    if (!e.target.checked) {
      updatedGrades = updatedGrades.filter(grade => !section.includes(grade));
    } else {
      section.forEach(grade => updatedGrades.push(grade));
      // ensure no duplicates
      updatedGrades = [...new Set(updatedGrades)];
    }

    setGrades(updatedGrades);
  };

  const selectAllGrades = () => {
    const allGrades = ['N/A', ...gradesEnum().map(g => g.value)];
    setAllGrades(allGrades);
  };

  const toggleGrade = (e, grade) => {
    let updatedGrades = [...grades];

    if (!e.target.checked) {
      updatedGrades = updatedGrades.filter(item => item !== grade);
    } else {
      updatedGrades.push(grade);
      // ensure no duplicates
      updatedGrades = [...new Set(updatedGrades)];
    }

    setGrades(updatedGrades);
  };

  return (
    <div className={`new-cl-filter ${isActive ? 'cl-active' : ''}`}>
      <div className="cl-filter-header" onClick={toggleOptions}>
        <p className={isActive ? 'cl-active' : ''}>Grade</p>
        <img
          src={
            showOptions && isActive
              ? iconUpCaretGreen
              : !showOptions && isActive
              ? iconDownCaretGreen
              : showOptions
              ? iconUpCaret
              : iconDownCaret
          }
          alt=""
        />
      </div>
      <div className={`selection-list ${showOptions ? 'show' : ''}`}>
        <div className="option select-all">
          <span className="drop-select-all" onClick={selectAllGrades}>
            Select All
          </span>
          <span className="drop-select-all" onClick={() => setGrades([])}>
            Clear All
          </span>
        </div>
        <div className="option">
          <label className="container">
            <input
              type="checkbox"
              checked={grades.includes('N/A')}
              value={'N/A'}
              onChange={e => toggleGrade(e, 'N/A')}
            />
            <span className="checkmark"></span>
            N/A
          </label>
        </div>
        <div className="option">
          <label className="container">
            <input
              type="checkbox"
              checked={grades.includes(EARLYCHILDHOOD[0])}
              value={EARLYCHILDHOOD[0]}
              onChange={e => toggleGrade(e, EARLYCHILDHOOD[0])}
            />
            <span className="checkmark"></span>
            Early Childhood
          </label>
        </div>
        <div className="option">
          <label className="container">
            <input
              type="checkbox"
              checked={gradesCheckedInSection(ELEMENTARY)}
              onChange={e => updateGradesInSection(e, ELEMENTARY)}
            />
            <span className="checkmark"></span>
            All Elementary
          </label>
        </div>
        {childhood_grades().map(grade => (
          <div key={grade.key} className="option indented">
            <label className="container">
              <input
                type="checkbox"
                checked={grades.includes(grade.value)}
                onChange={e => toggleGrade(e, grade.value)}
              />
              <span className="checkmark"></span>
              {grade.label}
            </label>
          </div>
        ))}
        <div className="option">
          <label className="container">
            <input
              type="checkbox"
              checked={gradesCheckedInSection(MIDDLE)}
              onChange={e => updateGradesInSection(e, MIDDLE)}
            />
            <span className="checkmark"></span>
            All Middle School
          </label>
        </div>
        {middle_grades().map(grade => (
          <div key={grade.key} className="option indented">
            <label className="container">
              <input
                type="checkbox"
                checked={grades.includes(grade.value)}
                onChange={e => toggleGrade(e, grade.value)}
              />
              <span className="checkmark"></span>
              {grade.label}
            </label>
          </div>
        ))}
        <div className="option">
          <label className="container">
            <input
              type="checkbox"
              checked={gradesCheckedInSection(SECONDARY)}
              onChange={e => updateGradesInSection(e, SECONDARY)}
            />
            <span className="checkmark"></span>
            All High School
          </label>
        </div>
        {secondary_grades().map(grade => (
          <div key={grade.key} className="option indented">
            <label className="container">
              <input
                type="checkbox"
                checked={grades.includes(grade.value)}
                onChange={e => toggleGrade(e, grade.value)}
              />
              <span className="checkmark"></span>
              {grade.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

GradesFilter.propTypes = {
  grades: PropTypes.array.isRequired,
  setGrades: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};
