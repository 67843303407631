import './bulkemail.css';

import { Component } from 'react';
import PropTypes from 'prop-types';

import iconClock from '../../assets/icon-clock-large.svg';
import iconCheck from '../../assets/icon-check-with-circle.svg';
import iconPersonWithCheck from '../../assets/icon-person-with-check.svg';
import iconPaperAirplane from '../../assets/icon-paper-airplane-white.svg';

const iconMapping = {
  clock: iconClock,
  check: iconCheck,
  person: iconPersonWithCheck,
  airplane: iconPaperAirplane,
};

export default class BulkEmailSendRow extends Component {
  static propTypes = {
    id: PropTypes.oneOf([0, 1, 2]).isRequired,
    actionLabel: PropTypes.string.isRequired,
    subLabel: PropTypes.string.isRequired,
    icon: PropTypes.oneOf(['clock', 'check', 'person', 'airplane']).isRequired,
    onSend: PropTypes.func.isRequired,
  };

  render() {
    const { id, actionLabel, subLabel, icon, onSend } = this.props;
    const activeStyle = id === 2 ? 'active' : '';
    return (
      <div
        className={`bulk-email-send-row-div ${activeStyle}`}
        onClick={() => onSend(id)}
        disabled={this.props.icon === 'check'}
      >
        <div className="send-row-img-div">
          <img src={iconMapping[icon]} alt="" />
        </div>
        {this.props.icon === 'check' ? (
          <h3>Test email sent!</h3>
        ) : (
          <div className="send-row-vertical-div">
            <div className={`send-row-action-label ${activeStyle}`}>{actionLabel}</div>
            <div className={`send-row-sub-label ${activeStyle}`}>{subLabel}</div>
          </div>
        )}
      </div>
    );
  }
}
