import { useState } from 'react';
import useSWR from 'swr';

import SubmitReferenceEditor from './SubmitReferenceEditor';
import paperAirplane from 'assets/icon-paper-airplane-green.svg';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

import LoadingSpinner from 'components/loadingSpinner';

export default function SubmitReferenceContainer() {
  const location = useLocation();
  const [submitted, setSubmitted] = useState(false);

  const fetcher = url =>
    axios
      .get(url)
      .then(response => response.data)
      .catch(error => error.response?.data);

  const queryParams = new URLSearchParams(location.search);
  const signature = queryParams.get('signature');

  const { data } = useSWR(`/api/submit_reference/?signature=${signature}`, fetcher, {
    revalidateOnFocus: false,
  });

  const isLoading = data === undefined;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  // For some reason, I wasn't able to implement proper error handling with swr.
  // I hacked together a solution to return the error instead and it works fine.
  // We'll probably want to add the correct error handling at some point but for
  // now, this will have to do.
  const { candidate, reference_template, role_title, error } = data;

  let statusMessage = null;
  if (submitted) {
    statusMessage = 'Reference submitted!';
  } else if (error === 'Reference already submitted') {
    statusMessage = 'Reference already submitted';
  } else if (error === 'Expired signature') {
    statusMessage = 'Oops, the time limit for this link has expired. Please request a new link.';
  } else if (error === 'Reference not found.') {
    statusMessage = 'This reference request has been removed.';
  } else if (error) {
    statusMessage = 'Invalid scorecard link, please request a new link';
  }

  if (statusMessage) {
    return (
      <center style={{ marginTop: 100 }}>
        {submitted && <img src={paperAirplane} alt="" />}
        <h2 className="mt1">{statusMessage}</h2>
      </center>
    );
  }

  return (
    <div
      style={{
        padding: '50px',
        maxWidth: '990px',
        margin: 'auto',
      }}
    >
      <h3 className="mb2">{`${candidate} - ${role_title}`}</h3>
      <SubmitReferenceEditor referenceTemplate={reference_template} setSubmitted={setSubmitted} />
    </div>
  );
}
