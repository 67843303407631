import { Component } from 'react';
import PropTypes from 'prop-types';
import { handleOutsideClickOnValidForm } from '../../../utils/util';
import IconDownCaret from '../../../assets/icon-down-caret-white.svg';

export default class SingleFilterDrop extends Component {
  static propTypes = {
    bulkStatusUpdate: PropTypes.func.isRequired,
    statusOptions: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
    };
  }

  outsideClickListener = ev => handleOutsideClickOnValidForm(this.node, ev, this.toggleOptions);

  toggleOptions = () => {
    if (!this.state.showOptions) {
      document.addEventListener('click', this.outsideClickListener, { capture: true });
    } else {
      document.removeEventListener('click', this.outsideClickListener, { capture: true });
    }
    this.setState({ showOptions: !this.state.showOptions });
  };

  render() {
    return (
      <div
        className="inline-block relative"
        onClick={this.toggleOptions}
        ref={node => (this.node = node)}
      >
        <button className="bulk-email-btn bulk-status-btn" onClick={this.toggleOptions}>
          Bulk Status Change &nbsp;&nbsp;
          <img className={this.state.showOptions ? 'flipped' : ''} src={IconDownCaret} alt="" />
        </button>
        <div
          className={`bulk-status cl-filter-selection-list ${this.state.showOptions ? 'show' : ''}`}
        >
          {this.props.statusOptions.map((statusObj, i) => (
            <div
              key={i}
              className="cl-filter-option"
              onClick={() => this.props.bulkStatusUpdate(statusObj)}
            >
              {statusObj.label}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
