import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';

import HelloSignIFrameBodyAndFooter from './HelloSignIFrameBodyAndFooter';
import HelloSignModalBody from './HellosignModalBody';
import HellosignRequestConfirmationModal from './HellosignRequestConfirmationModal';

import { AltModal } from 'ui-kit';
import { makeHSClient, openHSiFrame } from 'utils/helloSignUtil';
import useAvailableHellosignTemplates from 'hooks/data/useAvailableHellosignTemplates';
import useHelloSignPackets from 'hooks/data/useHelloSignPackets';
import { showMessage } from '../../utils/message';

const client = makeHSClient();

export default function HelloSignTemplateModal(props) {
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedPackets, setSelectedPackets] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [emailAddressesToCC, setEmailAddressesToCC] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allTemplatesReady, setAllTemplatesReady] = useState(false);
  const [unclaimedDraftState, setUnclaimedDraftState] = useState({
    iFrameOpen: false,
    unclaimedDrafts: [],
    currentIndex: 0,
  });
  const [shouldIncrementIndex, setShouldIncrementIndex] = useState(false);
  const [dueDate, setDueDate] = useState(null);
  const [notify, setNotify] = useState(true);

  const moveToNextTemplate = () => {
    setShouldIncrementIndex(true);
  };

  const { packets, packetsLoading } = useHelloSignPackets();

  const { templateOptions, templateOptionsLoading } = useAvailableHellosignTemplates({
    candidateId: props.candidate.id,
  });

  useEffect(() => {
    if (shouldIncrementIndex) {
      const nextIndex = unclaimedDraftState.currentIndex + 1;

      const atLastTemplate = nextIndex > unclaimedDraftState.unclaimedDrafts.length - 1;
      if (atLastTemplate) {
        // All templates have been submitted.
        closeIFrameAndShowConfirmationPage(nextIndex);
        return;
      }

      const nextTemplate = unclaimedDraftState.unclaimedDrafts[nextIndex];
      if (nextTemplate.edited) {
        // if the user already edited the next template, use the edit_and_resend
        // endpoint to get an updated edit url. Hellosign doesn't allow users to edit
        // the same url more than once.
        editRequest(nextTemplate.signature_request_id, nextIndex);
        return;
      }

      setShouldIncrementIndex(false);
      setUnclaimedDraftState(prevState => {
        // mark the current template as edited
        const unclaimedDrafts = prevState.unclaimedDrafts.map((obj, index) =>
          index === unclaimedDraftState.currentIndex ? { ...obj, edited: true } : obj
        );
        return {
          ...prevState,
          unclaimedDrafts,
          currentIndex: nextIndex,
        };
      });
    }
  }, [shouldIncrementIndex, unclaimedDraftState.currentIndex, unclaimedDraftState.unclaimedDrafts]);

  useEffect(() => {
    const { iFrameOpen, unclaimedDrafts, currentIndex } = unclaimedDraftState;
    if (iFrameOpen && unclaimedDrafts.length > 0) {
      openHSiFrame(client, unclaimedDrafts[currentIndex].claim_url, null, moveToNextTemplate);
    }
  }, [unclaimedDraftState]);

  const closeIFrameAndShowConfirmationPage = nextIndex => {
    setShouldIncrementIndex(false);
    setAllTemplatesReady(true);
    setUnclaimedDraftState(prevState => ({
      ...prevState,
      iFrameOpen: false,
      // Set nextIndex even though it's outside of the array index.
      // This allows us to use the same logic for editing a previous template
      currentIndex: nextIndex,
    }));
  };

  const continueToForms = (bulk = false) => {
    if (!selectedPackets?.length && !selectedTemplates?.length) {
      return setErrorMessage('Please select at least one packet or form to continue');
    }
    if (!bulk) {
      const draftsAlreadyFetched = unclaimedDraftState.unclaimedDrafts.length > 0;
      if (draftsAlreadyFetched) {
        const firstTemplate = unclaimedDraftState.unclaimedDrafts[0];
        const firstIndex = 0;
        editRequest(firstTemplate.signature_request_id, firstIndex);
      } else {
        fetchDrafts();
      }
    } else {
      bulkSend();
    }
  };

  const fetchDrafts = () => {
    setIsLoading(true);

    axios
      .post('/api/hellosign_draft/', {
        user_id: props.candidate.id,
        packet_ids: selectedPackets.map(p => p.id),
        template_ids: selectedTemplates.map(t => t.id),
        role_id: props.application.role.id,
        application_id: props.application.id,
      })
      .then(({ data }) => {
        setUnclaimedDraftState(prevState => ({
          currentIndex: prevState.currentIndex,
          unclaimedDrafts: [...data.unclaimed_drafts],
          iFrameOpen: true,
        }));
        setIsLoading(false);
      });
  };

  const bulkSend = () => {
    setIsLoading(true);
    const dueDateFormatted = dueDate ? moment(dueDate).format('YYYY-MM-DD') : null;
    axios
      .post(
        '/api/hellosign/handle_bulk_signature_request_send/',
        {
          user_id: props.candidate.id,
          packet_ids: selectedPackets.map(p => p.id),
          template_ids: selectedTemplates.map(t => t.id),
          role_id: props.application.role.id,
          application_id: props.application.id,
          due_date: dueDateFormatted,
          notify_candidate: notify,
          application_id: props.application.id,
          cc_email_addresses: emailAddressesToCC.map(e => e.value),
        },
        {
          params: {
            bulk_create: true,
          },
        }
      )
      .then(({ data }) => {
        setAllTemplatesReady(true);
        setIsLoading(false);
        props.onHide();
        showMessage('Your forms are being prepared and will be ready in a few minutes', 8000);
      })
      .catch(({ response }) => {
        setIsLoading(false);
        if (response.status == 400) {
          setErrorMessage(response.data.detail);
        } else {
          setErrorMessage('Something went wrong. Please refresh the page and try again.');
        }
      });
  };

  const onBack = () => {
    // When the user clicks to go back, close the hellosign iFrame.
    setUnclaimedDraftState(prevState => ({
      ...prevState,
      iFrameOpen: false,
    }));

    // From there, if we're past the first form, edit the previous one
    if (unclaimedDraftState.currentIndex > 0) {
      setIsLoading(true);
      editPreviousDraft();
    }
  };

  const editPreviousDraft = () => {
    const previousIndex = unclaimedDraftState.currentIndex - 1;

    // use current Index signature request id to let backend know we're editing
    const signature_request_id =
      unclaimedDraftState.unclaimedDrafts[previousIndex].signature_request_id;

    editRequest(signature_request_id, previousIndex);
  };

  const editRequest = (signature_request_id, newIndex) => {
    axios
      .post('/api/hellosign/edit_request_and_resend/', { signature_request_id })
      .then(({ data }) => {
        // we may be coming from the confirmation page, so make sure
        // allTemplatesReady is false
        setAllTemplatesReady(false);
        setShouldIncrementIndex(false);
        setUnclaimedDraftState(prevState => ({
          ...prevState,
          currentIndex: newIndex,
          iFrameOpen: true,
          unclaimedDrafts: prevState.unclaimedDrafts.map(prevDraft =>
            prevDraft.signature_request_id === signature_request_id
              ? {
                  ...prevDraft,
                  // editing the request gives us a new claim_url and signature_request_id
                  claim_url: data.claim_url,
                  signature_request_id: data.signature_request_id,
                }
              : prevDraft
          ),
        }));
        setIsLoading(false);
      });
  };

  const finishAndSend = () => {
    setIsLoading(true);

    const dueDateFormatted = dueDate ? moment(dueDate).format('YYYY-MM-DD') : null;

    axios
      .post('/api/hellosign/handle_signature_request_send/', {
        signature_requests: unclaimedDraftState.unclaimedDrafts,
        due_date: dueDateFormatted,
        notify: notify,
        application_id: props.application.id,
        cc_email_addresses: emailAddressesToCC.map(e => e.value),
      })
      .finally(() => {
        setIsLoading(false);
        props.onHide();
      });
  };

  const onHide = () => {
    client.close();
    props.onHide();
  };

  if (allTemplatesReady) {
    return (
      <HellosignRequestConfirmationModal
        isOpen={props.show}
        onHide={props.onHide}
        save={finishAndSend}
        selectedPackets={selectedPackets}
        selectedTemplates={selectedTemplates}
        dueDate={dueDate}
        emailAddressesToCC={emailAddressesToCC}
        editPreviousDraft={editPreviousDraft}
        notify={notify}
      />
    );
  }

  return (
    // Render large modal if the hellosign iFrame is open. Our users have mentioned that the
    // hellosign modal is too small
    <AltModal.AltModal isOpen={props.show} onClose={onHide} large={unclaimedDraftState.iFrameOpen}>
      <AltModal.Title>Send forms</AltModal.Title>
      {unclaimedDraftState.iFrameOpen ? (
        <HelloSignIFrameBodyAndFooter onBack={onBack} onHide={onHide} />
      ) : (
        <HelloSignModalBody
          selectedPackets={selectedPackets}
          setSelectedPackets={setSelectedPackets}
          selectedTemplates={selectedTemplates}
          setSelectedTemplates={setSelectedTemplates}
          emailAddressesToCC={emailAddressesToCC}
          setEmailAddressesToCC={setEmailAddressesToCC}
          packetOptions={packets}
          templateOptions={templateOptions}
          dueDate={dueDate}
          setDueDate={setDueDate}
          notify={notify}
          setNotify={setNotify}
          errorMessage={errorMessage}
          adminUsers={props.adminUsers}
          isLoading={isLoading || packetsLoading || templateOptionsLoading}
          onHide={onHide}
          continueToForms={() => continueToForms(false)}
          bulkSend={() => continueToForms(true)}
        />
      )}
    </AltModal.AltModal>
  );
}

HelloSignTemplateModal.propTypes = {
  candidate: PropTypes.object,
  application: PropTypes.object,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  helloSignTemplates: PropTypes.array,
  adminUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
};
