import { Component } from 'react';
import PropTypes from 'prop-types';

export default class LoadingSpinner extends Component {
  static propTypes = {
    additionalClassname: PropTypes.string,
    fontSize: PropTypes.number,
    margin: PropTypes.number,
    message: PropTypes.string,
    additionalStyles: PropTypes.object,
    additionalIconStyles: PropTypes.object,
  };

  render() {
    const margin = this.props.margin !== undefined ? `${this.props.margin}rem` : '10rem';
    const fontSize = this.props.fontSize ? this.props.fontSize : 4;
    const additionalClassname = this.props.additionalClassname
      ? this.props.additionalClassname
      : '';
    let styleObj = {
      textAlign: 'center',
    };
    if (this.props.additionalStyles) {
      styleObj = {
        ...styleObj,
        ...this.props.additionalStyles,
      };
    }
    const iconStylesObj = { margin, ...this.props.additionalIconStyles };

    return (
      <div className={`loading-spinner ${additionalClassname}`} style={styleObj}>
        <i
          className={`fa fa-circle-o-notch fast-spin fa-${fontSize}x fa-fw`}
          style={iconStylesObj}
        />
        <div className="submit-wait-div">
          <p>{this.props.message}</p>
        </div>
      </div>
    );
  }
}
