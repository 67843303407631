import { Component } from 'react';
import styled from 'styled-components';
import { notify } from 'react-notify-toast';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ErrorText from '../errortext';
import { StyledSelect } from 'ui-kit';
import { StyledErrorText } from 'components/Login/LoginForm';
import CustomFieldModalLineItem from './CustomFieldModalLineItem';
import { customFieldPermission, customFieldTypeData } from '../../utils/enums';

const fieldPermission = customFieldPermission().reduce((obj, item) => {
  obj[item.label] = item.value;
  return obj;
}, {});

const fieldPermissionReadable = customFieldPermission().reduce((obj, item) => {
  obj[item.value] = item.label;
  return obj;
}, {});

const mapping = {
  '-1': 'super_admin_access',
  '-2': 'district_admin_access',
  '-3': 'district_user_access',
  '-4': 'school_admin_access',
};

export default class CustomFieldModal extends Component {
  constructor(props) {
    super(props);
    this.show = notify.createShowQueue();
    this.state = {
      fieldObj: Object.assign({}, props.fieldObj),
      error: '',
      user_or_user_type: '',
      permission: '',
      // full list of individual users that can view and/or edit the custom field.
      // corresponds to two different fields on fieldObj:
      // - 'individual_users_edit_and_view'
      // - 'individual_users_view_only'
      // It's easier to keep it in one field here in state so that it can be sorted. Plus,
      // it's rendered as one field in the UI anyway. When saving the object at the end,
      // split the columns up into those two fields again.
      users_view_edit: [],
      format_list: props.fieldObj.field_type === 2 ? customFieldTypeData() : [],
      format_data: props.fieldObj.field_type === 2 ? true : false,
      value_field_type:
        props.fieldObj.id !== '' ? this.getFieldTypes()[props.fieldObj.field_type] : '',
      value_link: '',
      value_data: props.fieldObj.field_type === 2 ? customFieldTypeData()[0].value : '',
      input_file: null,
      error_file: '',
      file_data:
        props.fieldObj.multichoice_options !== null
          ? props.fieldObj.multichoice_options.join('\n')
          : [],
      file_data_csv:
        props.fieldObj.multichoice_options !== null ? props.fieldObj.multichoice_options : [],
    };
    this.formatName = [
      { id: 1, value: 'Select format', label: 'Select format' },
      { id: 2, value: 'Select date format', label: 'Select date format' },
      { id: 3, value: 'Select currency format', label: 'Select currency format' },
      { id: 4, value: 'Select data format', label: 'Select data format' },
      { id: 5, value: 'Enter a valid URL', label: 'Enter a valid URL' },
    ];
    this.formatValue =
      props.fieldObj.field_type === 2 ? this.formatName[3].value : this.formatName[0].value;
  }

  static propTypes = {
    fieldObj: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    adminUsers: PropTypes.array.isRequired,
    index: PropTypes.number,
  };

  componentDidMount() {
    // add a permission property to the user objects, used for rendering (see note in state).
    let fieldObj = this.state.fieldObj;
    let view_users = fieldObj.individual_users_view_only;
    let edit_users = fieldObj.individual_users_edit_and_view;

    view_users.forEach(u => (u.permission = fieldPermission['View only']));
    edit_users.forEach(u => (u.permission = fieldPermission['View & edit']));
    let users_view_edit = view_users
      .concat(edit_users)
      .sort((a, b) => (a.last_name > b.last_name ? 1 : -1));
    this.setState({ users_view_edit });
  }

  handleChange = e => {
    // handle change specifically for fieldObj in state
    let fieldObj = this.state.fieldObj;
    let value = e.target.value;
    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    }
    fieldObj[e.target.name] = value;
    this.setState({ fieldObj, error: '' });
  };

  handleChangeFormat = e => {
    this.setState({
      value_link: e.target.value,
    });
  };

  setPermission = e => {
    // handle change for non fieldObj changes
    this.setState({
      permission: Number(e.target.value),
      error: '',
    });
  };

  setFormat = e => {
    // handle change for non fieldObj changes
    this.setState({
      value_data: Number(e.target.value),
    });
  };

  setFileData = e => {
    let target = e.target.value;
    if (target.slice(-1) === ',') {
      const new_target = target.slice(0, -1);
      target = new_target;
    }
    const list_options = e.target.value !== '' ? e.target.value.split(/[\n,]/) : [];
    this.setState({
      file_data_csv: [...list_options],
      file_data: e.target.value,
    });
  };

  onSave = () => {
    let { fieldObj } = this.state;
    fieldObj.multichoice_options = this.state.file_data_csv;
    // do some error checking
    if (fieldObj.title === '') {
      this.setState({ error: 'Please enter a title' });
      return;
    }

    if (fieldObj.multichoice_options.length > 10000) {
      return;
    }

    fieldObj.individual_users_view_only = [];
    fieldObj.individual_users_edit_and_view = [];
    // merge the individual user fields
    this.state.users_view_edit.forEach(u => {
      if (u.permission === fieldPermission['View only']) {
        fieldObj.individual_users_view_only.push(u);
      } else {
        fieldObj.individual_users_edit_and_view.push(u);
      }
    });

    // set the values about field type
    const f = this.state.value_field_type;
    var elementPos = this.getFieldTypes().findIndex(function(obj) {
      return obj.label === f.label;
    });
    if (elementPos === -1) {
      fieldObj.field_type = 4;
    } else {
      fieldObj.field_type = elementPos;
    }

    fieldObj.multichoice_options = this.state.file_data_csv;
    // run props save
    this.props.onSave(fieldObj);
  };

  getFieldTypes = () => {
    let field_types = [
      { value: 0, label: 'Date' },
      { value: 1, label: 'Currency' },
      { value: 2, label: 'Select from list' },
      { value: 3, label: 'Link' },
      { value: 4, label: 'Text' },
    ];

    return field_types;
  };

  getOptions = () => {
    let user_types = [
      { value: -1, label: 'Super Admins' },
      { value: -2, label: 'District Admins' },
      { value: -3, label: 'District Users' },
      { value: -4, label: 'School Admins' },
    ];
    let users = this.props.adminUsers
      .sort((a, b) => (a.last_name > b.last_name ? 1 : -1))
      .map(a => {
        return { value: a.id, label: a.name };
      });

    return user_types.concat(users);
  };

  handleMultiSelectChange = obj => {
    let user_or_user_type = this.state.user_or_user_type;
    user_or_user_type = obj ? obj.value : '';
    this.setState({ user_or_user_type, error: '' });
  };

  handleSelectFormat = obj => {
    this.setState({
      format_data: false,
      format_list: [],
      file_data: [],
      file_data_csv: [],
    });
    if (obj === null) {
      this.formatValue = this.formatName[0].value;
      this.setState({
        value_field_type: '',
      });
    } else if (obj.label === 'Date') {
      this.formatValue = this.formatName[1].value;
      this.setState({
        value_field_type: obj,
      });
    } else if (obj.label === 'Currency') {
      this.formatValue = this.formatName[2].value;
      this.setState({
        value_field_type: obj,
      });
    } else if (obj.label === 'Select from list') {
      this.formatValue = this.formatName[3].value;
      this.setState({
        format_data: true,
        format_list: customFieldTypeData(),
        value_field_type: obj,
        file_data:
          this.state.fieldObj.multichoice_options === null
            ? []
            : this.state.fieldObj.multichoice_options.join('\n'),
      });
    } else if (obj.label === 'Link') {
      this.formatValue = this.formatName[4].value;
      this.setState({
        value_field_type: obj,
      });
    } else if (obj.label === 'Text') {
      this.setState({
        value_field_type: obj,
      });
    }
    this.forceUpdate();
  };

  addItem = () => {
    let { fieldObj, permission, user_or_user_type, users_view_edit } = this.state;
    if (permission === '' || user_or_user_type === '') {
      // Empty fields
      this.setState({ error: 'Please select a user type and permission' });
      return;
    }
    if (user_or_user_type > 0) {
      // user id in this case
      let user = this.props.adminUsers.find(a => a.id === user_or_user_type);
      if (user) {
        // place in properly sorted location (according to last name)
        for (let i = 0; i < users_view_edit.length; i++) {
          if (users_view_edit[i].id === user_or_user_type) {
            // user already in list, so return
            this.setState({ error: 'That user has already been added' });
            return;
          }
          if (user.last_name < users_view_edit[i].last_name) {
            // insert in alphabetical order, so if user last name comes before
            // current last name, place there.
            users_view_edit.splice(i, 0, { permission, id: user_or_user_type, name: user.name });
            this.setState({ users_view_edit });
            this.resetState();
            return;
          }
        }
        // if get to the end without placing it, 'push' it there.
        users_view_edit.push({ permission, id: user_or_user_type, name: user.name });
        this.setState({ users_view_edit });
      }
    } else {
      let userTypeAccess = mapping[user_or_user_type];

      // make sure user type isn't already in list
      if (fieldObj[userTypeAccess] !== fieldPermission['None']) {
        // item already in list. set error and return.
        this.setState({ error: 'That user type has already been added' });
        return;
      }
      fieldObj[userTypeAccess] = permission;
      this.setState({ fieldObj });
    }

    // clears everything in state except fieldObj and users_view_edit.
    this.resetState();
  };

  resetState = () => {
    this.setState({
      error: '',
      user_or_user_type: '',
      permission: '',
    });
  };

  removeLineItem = id => {
    let { fieldObj, users_view_edit } = this.state;
    let userTypeAccess = mapping[id];
    if (id < 0) {
      // it's a user_type (-1 through -4)
      fieldObj[userTypeAccess] = fieldPermission.None;
    } else {
      // it's a user (id > 0)
      let index = users_view_edit.findIndex(u => u.id === id);
      if (index !== -1) {
        // remove the user
        users_view_edit.splice(index, 1);
      }
    }

    this.setState({ fieldObj, users_view_edit });
  };

  setScrollClass = () => {
    document.getElementsByClassName('fade in modal')[0].classList.add('overflow-y');
  };

  removeScrollClass = () => {
    document.getElementsByClassName('fade in modal')[0].classList.remove('overflow-y');
  };

  render() {
    let { fieldObj } = this.state;

    return (
      <Modal show={this.props.show} bsSize="large" dialogClassName="custom-fields-modal">
        <Modal.Header>
          <div className="copy-app-modal-title">
            <h3>New Profile Field</h3>
          </div>
          <span className="copy-app-modal-close" onClick={this.props.onHide}>
            (X) CLOSE
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="edit-schools-modal-section custom-fields">
            <div className="edit-schools-staging-row">
              <div className="flex-1 select-form-field" style={{ maxWidth: '60%' }}>
                <h4>Title</h4>
                <input
                  type="text"
                  name="title"
                  className="mr1 form-field"
                  style={{ maxWidth: '95%', width: `calc(100vw - 20px)` }}
                  placeholder={`eg. "Start date"`}
                  value={fieldObj.title || ''}
                  onChange={this.handleChange}
                  // max length enforced at db level as well.
                  maxLength={30}
                />
              </div>
              <div className="flex-1 select-form-field format-select" style={{ minWidth: `20%` }}>
                <h4>Field Type</h4>
                <StyledSelect
                  boxShadow="0 2px 2px 0 rgba(0, 0, 0, 0.1)"
                  className="flex-1"
                  options={this.getFieldTypes()}
                  placeholder="Select Field Type"
                  value={this.state.value_field_type || ''}
                  onChange={this.handleSelectFormat}
                  onFocus={this.setScrollClass}
                  onBlur={this.removeScrollClass}
                  autoBlur
                  isClearable
                />
              </div>
            </div>

            {this.state.value_field_type.label === 'Select from list' && (
              <div>
                <h4>Values</h4>
                <textarea
                  name="link-format"
                  className="form-field" //{`form-field  ${this.state.file_data_csv.length > 10000 ? "sbd" : ""}`}
                  placeholder="Type or paste values (1 per line or comma separated)"
                  style={{
                    resize: 'none',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    backgroundColor: this.state.file_data_csv.length > 10000 ? '#FDEDEC' : '#fff',
                    borderColor: this.state.file_data_csv.length > 10000 ? 'red' : '#fff',
                  }}
                  value={'' || this.state.file_data}
                  onChange={this.setFileData}
                ></textarea>
                {this.state.file_data_csv.length > 10000 && (
                  <StyledErrorText>Please enter less than or equal to 10k values</StyledErrorText>
                )}
                {this.state.file_data_csv.length === 0 && this.state.file_data.length === 0 && (
                  <StyledErrorText>Please enter at least 1 option value</StyledErrorText>
                )}
              </div>
            )}

            <h4>View/Edit access</h4>
            <div className="custom-field-line-items">
              {[fieldPermission['View only'], fieldPermission['View & edit']].includes(
                Number(fieldObj.super_admin_access)
              ) && (
                <CustomFieldModalLineItem
                  id={-1}
                  label={'Super Admins'}
                  permission={fieldPermissionReadable[fieldObj.super_admin_access]}
                  removeLineItem={this.removeLineItem}
                />
              )}
              {[fieldPermission['View only'], fieldPermission['View & edit']].includes(
                Number(fieldObj.district_admin_access)
              ) && (
                <CustomFieldModalLineItem
                  id={-2}
                  label={'District Admins'}
                  permission={fieldPermissionReadable[fieldObj.district_admin_access]}
                  removeLineItem={this.removeLineItem}
                />
              )}
              {[fieldPermission['View only'], fieldPermission['View & edit']].includes(
                Number(fieldObj.district_user_access)
              ) && (
                <CustomFieldModalLineItem
                  id={-3}
                  label={'District Users'}
                  permission={fieldPermissionReadable[fieldObj.district_user_access]}
                  removeLineItem={this.removeLineItem}
                />
              )}
              {[fieldPermission['View only'], fieldPermission['View & edit']].includes(
                Number(fieldObj.school_admin_access)
              ) && (
                <CustomFieldModalLineItem
                  id={-4}
                  label={'School Admins'}
                  permission={fieldPermissionReadable[fieldObj.school_admin_access]}
                  removeLineItem={this.removeLineItem}
                />
              )}
              {this.state.users_view_edit.map((u, index) => (
                <CustomFieldModalLineItem
                  id={u.id}
                  key={index}
                  label={u.name}
                  permission={fieldPermissionReadable[u.permission]}
                  removeLineItem={this.removeLineItem}
                />
              ))}
            </div>

            <div className="edit-schools-staging-row">
              <StyledSelect
                boxShadow="0 2px 2px 0 rgba(0, 0, 0, 0.1)"
                className="flex-1 mr1"
                options={this.getOptions()}
                placeholder="Search by name or user type"
                onChange={this.handleMultiSelectChange}
                onFocus={this.setScrollClass}
                onBlur={this.removeScrollClass}
                autoBlur
                isClearable
              />
              <div className="select-form-field jobedit-select-div copy-app-select-role">
                <select
                  type="text"
                  name="permission"
                  value={this.state.permission}
                  onChange={this.setPermission}
                >
                  <option value="" disabled>
                    Select permission
                  </option>
                  {customFieldPermission()
                    .filter(p => p.value !== 0)
                    .map((p, i) => (
                      <option value={p.value} key={i}>
                        {p.label}
                      </option>
                    ))}
                </select>
              </div>
              <AddButton onClick={this.addItem}>Add</AddButton>
            </div>

            <div className="visibility-labels">
              <h4 className="mt2">Users can see this new field on...</h4>
              <label>
                <input
                  type="checkbox"
                  name="visibility_qv"
                  checked={fieldObj.visibility_qv}
                  onChange={this.handleChange}
                />
                &nbsp;&nbsp;Quick candidate view
              </label>
              <label>
                <input
                  type="checkbox"
                  name="visibility_fp"
                  checked={fieldObj.visibility_fp}
                  onChange={this.handleChange}
                />
                &nbsp;&nbsp;Full candidate profile
              </label>
            </div>

            <hr />

            <div className="flex flex-wrap space-between">
              <button className="modal-button" onClick={this.props.onHide}>
                Cancel
              </button>
              <div className="flex-center">
                <div className="mr1">
                  {this.state.error && <ErrorText message={this.state.error} />}
                </div>
                <button
                  disabled={
                    this.state.value_field_type.label === this.getFieldTypes()[2].label &&
                    this.state.file_data.length === 0 &&
                    this.state.file_data_csv.length === 0
                      ? true
                      : false
                  }
                  //className="modal-button save"
                  className={`${
                    this.state.file_data_csv.length === 0 &&
                    this.state.file_data.length === 0 &&
                    this.state.value_field_type.label === this.getFieldTypes()[2].label
                      ? 'modal-button'
                      : 'modal-button save'
                  }`}
                  onClick={this.onSave}
                >
                  Save & Close
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const AddButton = styled.button`
  background-color: #00b88d;
  border-radius: 3px;
  color: white;
  width: 94px;
  font-size: 14px;
  height: 50px;
  border: none;
`;
