import styled from 'styled-components';

import { GreenToggle } from 'ui-kit';

export default function CumulativeScore({
  includeRatingQuestions,
  setIncludeRatingQuestions,
  includeRubricQuestions,
  setIncludeRubricQuestions,
  showTotalOnPreview,
  setShowTotalOnPreview,
}) {
  return (
    <>
      <ToggleContainer>
        <GreenToggle
          toggleIsOn={includeRatingQuestions}
          toggleFunction={() => setIncludeRatingQuestions(prevValue => !prevValue)}
          label="Include rating questions"
        />
      </ToggleContainer>
      <ToggleContainer>
        <GreenToggle
          toggleIsOn={includeRubricQuestions}
          toggleFunction={() => setIncludeRubricQuestions(prevValue => !prevValue)}
          label="Include rubric questions"
        />
      </ToggleContainer>
      <ToggleContainer>
        <GreenToggle
          toggleIsOn={showTotalOnPreview}
          toggleFunction={() => setShowTotalOnPreview(prevValue => !prevValue)}
          label="Show total on preview"
        />
      </ToggleContainer>
    </>
  );
}

const ToggleContainer = styled.div`
  margin-bottom: 17px;

  &:last-child {
    margin-bottom: 0;
  }
`;
