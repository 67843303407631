import { DeleteTemplateModalProps } from './types';
import { PrimaryButton, TextButton } from 'sharedComponents/Buttons';
import { Modal } from 'sharedComponents/Modal';
import { Box, styled } from '@mui/material';

export const DeleteTemplateModal: React.FC<DeleteTemplateModalProps> = ({
  handleCloseDeleteModal,
  handleDeleteTemplate,
  isOpen,
  templateId,
}) => {
  const deleteTemplate = event => handleDeleteTemplate(event, templateId);

  return (
    <Modal
      title="Confirm"
      open={isOpen}
      onClose={e => handleCloseDeleteModal(e)}
      ctaButtons={
        <CTA>
          <TextButton size="extraLarge" onClick={e => handleCloseDeleteModal(e)}>
            Cancel
          </TextButton>
          <PrimaryButton size="extraLarge" onClick={deleteTemplate}>
            Delete
          </PrimaryButton>
        </CTA>
      }
    >
      <p>Are you sure you want to delete this template?</p>
    </Modal>
  );
};

export const CTA = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
});
