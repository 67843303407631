import { ChangeEvent, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import moment, { Moment } from 'moment';

import { DatePicker, TwoButtonSelect } from 'ui-kit';
import {
  ALL_APPLICANTS,
  InternalOnlyStatus,
  INTERNAL_ONLY,
  VisibilitySelectorProps,
} from './types';

const formatDate = (date: Moment | null): string | null => {
  if (!date) {
    return null;
  }
  return date.format('YYYY-MM-DD');
};

export default function VisibilitySelector({
  job,
  updateField,
  district,
}: VisibilitySelectorProps): React.ReactElement {
  const initialButtonActive = job.internal_only ? INTERNAL_ONLY : ALL_APPLICANTS;

  const [buttonActive, setButtonActive] = useState<InternalOnlyStatus>(initialButtonActive);
  const [datePickerFocused, setDatePickerFocused] = useState(false);

  useLayoutEffect(() => {
    setButtonActive(initialButtonActive);
  }, [initialButtonActive]);

  const setExternalDate = (date: Moment | null) => {
    const formattedDate = formatDate(date);
    const payload = { target: { name: 'external_date', value: formattedDate } } as ChangeEvent<
      HTMLInputElement
    >;
    updateField(payload);
  };

  const setInternalOnly = (value: boolean) => {
    const payload = ({ target: { name: 'internal_only', value } } as unknown) as ChangeEvent<
      HTMLInputElement
    >;
    updateField(payload);
  };

  const handleChange = (value: number) => {
    const newButtonState = value === 0 ? ALL_APPLICANTS : INTERNAL_ONLY;
    setButtonActive(newButtonState);
    if (value === 0) {
      setInternalOnly(false);
      // if user selects 'all-applicants', clear the external date on the job
      setExternalDate(null);
    } else {
      setInternalOnly(true);
    }
  };

  const getDate = () => {
    if (job.external_date) {
      return moment(job.external_date);
    } else {
      return null;
    }
  };

  /**
   * If the district is SJUSD (ie. San Jose Unified School District).
   * This district has a hardcoded password for the external jobboard and so we
   * render that instead.
   *
   * Otherwise, we default to returning the district slug.
   *
   * @returns {string} password for district's external jobboard
   */
  const getDistrictPassword = () => (district.id === 10 ? 'SJUSDapply' : district.slug);

  return (
    <GridContainer>
      <Label>Visibility</Label>
      <TwoButtonSelect
        firstButtonLabel="All Applicants"
        firstButtonActive={buttonActive === ALL_APPLICANTS}
        secondButtonLabel="Internal Only"
        secondButtonActive={buttonActive === INTERNAL_ONLY}
        handleChange={handleChange}
      />
      {buttonActive === INTERNAL_ONLY && (
        <>
          <DatePicker
            date={getDate()}
            onDateChange={(date: moment.Moment | null) => setExternalDate(date)}
            focused={datePickerFocused}
            onFocusChange={({ focused }: { focused: boolean }) => setDatePickerFocused(focused)}
            id="job-external-date-picker"
            placeholder="MM/DD/YYYY"
          />
          <LightGreyText>
            <p>Will open to external applicants on the date selected</p>
            <p>Your jobs password is: {getDistrictPassword()}</p>
          </LightGreyText>
        </>
      )}
    </GridContainer>
  );
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 282px 185px 1fr;
  grid-column-gap: 1rem;
  grid-template-rows: 21px 51px;
  grid-row-gap: 0.8rem;

  margin-top: 3rem;
`;

const Label = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #444444;

  grid-column-start: 1;
  grid-column-end: 4;
`;

const LightGreyText = styled.div`
  color: #a6a7ad;
  font-size: 15px;
`;
