import styled from 'styled-components';
import auth from '../../../utils/auth';

import CustomTag from './CustomTag.jsx';
import { validateTagUserType } from '../util';

import { DropdownWithInputFilter } from './DropdownWithInputFilter';

export default function CustomTagSection({
  user,
  fullUser,
  assignTag,
  removeTag,
  districtTags,
  isFullProfile,
}) {
  /**
   * Filters tags according to the specs below:
   *
   * Criteria 1: Users can only see tags relevant to their type
   *
   * Criteria 2: Only candidate/prospect tags are shown for candidate/prospect profiles
   *             (unless both are tagged)
   *
   * Criteria 3: If user is already tagged with tag, it shouldn't appear in list
   *
   * Criteria 4: Tags should be in alphabetical order
   */
  const filteredDistrictTags = districtTags
    .sort((tag1, tag2) => tag1.label.localeCompare(tag2.label))
    .filter(tag => {
      const { id, candidate_type, prospect_type } = tag;
      const candidateType = 'candidate';
      const prospectType = 'prospect';
      const userType = fullUser.prospect_id ? prospectType : candidateType;

      // 1. VALIDATE: tag should not be assigned to user already
      if (fullUser.tags.find(({ tag }) => tag.id === id) !== undefined) {
        // tag is already assigned
        return false;
      }

      if (userType === candidateType && !candidate_type && prospect_type) {
        // 2. VALIDATE: Tag Types
        // tag is only meant for prospects
        return false;
      } else if (userType === prospectType && !prospect_type && candidate_type) {
        // tag is only meant for candidates
        return false;
      }

      // 3. VALIDATE: User Type
      if (!validateTagUserType(user, tag)) {
        // current user can't view this tag
        return false;
      }

      // tag is valid 🕺🏽
      return tag;
    });

  const handleInputChange = tagId => {
    // assign selected tag to the candidate
    assignTag(tagId);
  };

  const isFullUser =
    fullUser.profile || !fullUser.marketplace_fit_status === 'converted_to_prospect';

  return (
    <CustomTagContainer border={isFullUser}>
      {/* Prospects don't have profiles so if user doesn't have one, we assume they're a prospect */}
      <HeaderLabel fontColor={!isFullUser}>
        {isFullUser ? 'Candidate' : 'Prospect'} Tags
      </HeaderLabel>
      <CustomTagRow isFullProfile={isFullProfile}>
        {fullUser.tags.length > 0 &&
          // current user can only view tags assigned to their user_type
          fullUser.tags.map(
            assignedTagData =>
              validateTagUserType(user, assignedTagData.tag) && (
                <CustomTag
                  key={assignedTagData.id}
                  tag={assignedTagData.tag}
                  parentId={assignedTagData.id}
                  removeTag={removeTag}
                  isAssignedView={true}
                />
              )
          )}
      </CustomTagRow>
      {/* Spec: Only super admins and district admins can view the dropdown box */}
      {auth.isDistrictAdmin() && (
        <>
          {fullUser.tags?.length > 0 && <StyledHr isFullProfile={isFullProfile} />}
          <DropDownContainer isFullProfile={isFullProfile}>
            <DropdownWithInputFilter
              data-testid="tag-dropdown"
              placeholder="Add Tag"
              tags={filteredDistrictTags}
              handleChange={handleInputChange}
              value=""
              backgroundColor="#EEEFF2"
              boxShadow={false}
            />
          </DropDownContainer>
        </>
      )}
    </CustomTagContainer>
  );
}

const CustomTagContainer = styled.div`
  width: inherit;
  border: ${props => (props.border ? '1px solid #e0e0e0' : '')};
  border-radius: ${props => (props.border ? '4px' : '')};
  padding: ${props => (props.border ? '16px' : '')};
`;

const HeaderLabel = styled.h4`
  color: ${props => (props.fontColor ? '#777777' : '#444444')};
  font-size: 19px;
  font-weight: 400;
  margin-bottom: 1rem;
`;

const DropDownContainer = styled.div`
  margin-top: 20px;
  width: ${({ isFullProfile }) => (isFullProfile ? '50%' : '100%')};

  // mobile devices
  @media only screen and (max-width: 450px) {
    width: 100%;
  }
`;

// spec: tags can extend passed dropdown when on candidate's full profile
const CustomTagRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
`;

const StyledHr = styled.hr`
  margin: 15px 0;
  border: 1px solid #eeeff2;
  width: ${({ isFullProfile }) => (isFullProfile ? '50%' : '100%')};

  // mobile devices
  @media only screen and (max-width: 450px) {
    width: 100%;
  }
`;
