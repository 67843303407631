import { Component } from 'react';

import { Box, IconButton, styled } from '@mui/material';
import { EmailTemplateRowProps } from './types';
import { VISIBLE_TO } from './utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { SettingsRow } from 'components/SettingsRow';

export default class EmailTemplateRow extends Component<EmailTemplateRowProps> {
  openEmailTemplateModal = id => {
    this.props.openEmailTemplateModal(id);
  };

  getEmailTypeIcon = () => {
    if (this.props.emailTemplate.type === 'Reference') {
      return <PeopleAltIcon fontSize="small" color="inherit" />;
    } else if (this.props.emailTemplate.type === 'Application confirmation') {
      return <CheckCircleIcon fontSize="small" color="inherit" />;
    } else {
      return <EmailIcon fontSize="small" color="inherit" />;
    }
  };

  render() {
    const { emailTemplate } = this.props;
    const templateIsReferenceOrGeneral = emailTemplate.type !== 'Application confirmation';

    return (
      <SettingsRow
        cta={
          <IconButton
            onClick={this.openEmailTemplateModal.bind(this, emailTemplate.id)}
            data-testid="edit-button"
          >
            <EditIcon />
          </IconButton>
        }
      >
        <TextContainer>
          <TitleText>{emailTemplate.title}</TitleText>
          <TypeSubjectAndVisibilityContainer>
            <SubjectAndTypeText>
              {this.getEmailTypeIcon()}
              <ShortenedEllipsisText>&nbsp;{emailTemplate.subject}</ShortenedEllipsisText>
            </SubjectAndTypeText>

            {templateIsReferenceOrGeneral && (
              <VisibilityIconAndText>
                <VisibilityIcon />
                &nbsp;{VISIBLE_TO[emailTemplate.view_permissions]}
              </VisibilityIconAndText>
            )}
          </TypeSubjectAndVisibilityContainer>
        </TextContainer>
      </SettingsRow>
    );
  }
}

const ShortenedEllipsisText = styled(Box)({
  color: '#999',
  maxWidth: '70ch',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const SubjectAndTypeText = styled(Box)({
  alignItems: 'center',
  display: 'flex',
});

const TextContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-around',
  maxWidth: '90%',
});

const TitleText = styled(ShortenedEllipsisText)({
  color: '#5c5e69',
  fontWeight: '600',
});

const TypeSubjectAndVisibilityContainer = styled(Box)({
  color: '#999',
  display: 'flex',
  flexDirection: 'row',
  '@media screen and (max-width: 847px) ': {
    flexDirection: 'column',
    maxWidth: '90%',
  },
});

const VisibilityIconAndText = styled(Box)({
  display: 'flex',
  marginLeft: '1rem',
  '@media screen and (max-width: 847px) ': {
    marginLeft: 0,
  },
});
