import { useState, useEffect, useRef } from 'react';

import { useOnKeyEvent } from 'hooks';
import { Button, Input, Label, Modal } from 'ui-kit';
import { ModalActions } from './components/ModalActions';
import { FormInputError } from './components/FormInputError';

export const AddEditSchoolGroupModal = ({
  isOpen,
  onCloseModalClick,
  onSaveModalClick,
  initialValue = '',
}) => {
  const [formState, setFormState] = useState({
    name: initialValue ?? '',
    error: null,
  });
  const inputRef = useRef(null);

  // Keep form data in sync if the parent changes.
  useEffect(() => {
    setFormState({ name: initialValue ?? '' });
  }, [initialValue]);

  const onSave = () => {
    if (!formState.name) {
      setFormState(prevState => ({ ...prevState, error: 'Please enter a valid name.' }));
    } else {
      onSaveModalClick(formState.name);
      setFormState({ name: '' });
    }
  };

  const onClose = () => {
    onCloseModalClick();
    setFormState({ name: '' });
  };

  // Submit form on enter key
  useOnKeyEvent('Enter', 'keyup', onSave, isOpen);

  return (
    <Modal.Modal isOpen={isOpen} onClose={onClose} focusOnOpenRef={inputRef}>
      <Modal.Title>School Group</Modal.Title>
      <Modal.Body>
        <Label htmlFor="school-group-name">
          Group Name{' '}
          <Input
            id="school-group-name"
            type="text"
            name="name"
            placeholder='Ex: "Elementary"'
            required
            ref={inputRef}
            value={formState.name}
            hasError={formState.error}
            onChange={event => setFormState({ name: event.target.value })}
            marginTop="1em"
          />
          <FormInputError>{formState.error}</FormInputError>
        </Label>
      </Modal.Body>
      <Modal.Actions>
        <ModalActions>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSave}>
            Save & Close
          </Button>
        </ModalActions>
      </Modal.Actions>
    </Modal.Modal>
  );
};
