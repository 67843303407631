import { isEmpty, uniqueId } from 'lodash';
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import connectSubscriptionsAPI from 'api/connectSubscriptionsAPI';
import { ConnectGatedActions } from 'features/MarketplaceV2/utils/connectEnums';
import { useLocation } from 'react-router-dom';
import { nimbleTheme } from 'theme';
import auth from 'utils/auth';
import { updateCandidateProfileResume } from 'utils/fileUpload';
import { ConnectProfileFlowButtonGroup } from './SharedComponents/ConnectProfileFlowButtonGroup';
import {
  checkFileMeetsRequirements,
  formatResumeFileName,
  getGatedActionDisplayInfo,
  MISSOURI_STATE_ID,
  updateConnectFirstProfileSetupDateTime,
} from './utils';

import expressInterestAPI from 'features/MarketplaceV2/api/expressInterestAPI';
import { Alert } from 'sharedComponents/Alert';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { FileUpload } from 'sharedComponents/FileUpload';
import { Grid, GridItem } from 'sharedComponents/Grid';
import { SearchableSelect } from 'sharedComponents/Select/SearchableSelect';
import { Option } from 'sharedComponents/Select/types';
import { HorizontalLinearStepper } from 'sharedComponents/Stepper/HorizontalStepper';
import RadioCardToggle from './SharedComponents/RadioCardToggle';
import { QualificationsFormData } from 'types/marketplaceV2Types';
import { MISSOURI_STATE_CODE } from 'utils/constants';

const empty_certification_input = (hasErrors = false) => {
  return {
    client_id: uniqueId(),
    state: MISSOURI_STATE_ID,
    isHidden: false,
    errors: { hasNameError: hasErrors, hasStatusError: hasErrors },
    subject: null,
    status: null,
  };
};

export const UploadYourResumeForm = (): React.ReactElement => {
  const user = auth.getUser();

  const [credentialOptions, setCredentialOptions] = useState<Option[]>([]);
  const [credentialStatusOptions, setCredentialStatusOptions] = useState<Option[]>([]);
  const [userFormData, setUserFormData] = useState<QualificationsFormData>({
    hasCertification: true,
    // client_id is used to uniquely identify each certification input during CRUD operations
    certifications: [empty_certification_input()],
    resume: '',
  });
  const [resumeUploadErrors, setResumeUploadErrors] = useState<string[]>([]);
  const [userDataIsLoading, setUserDataIsLoading] = useState<boolean>(true);
  const [formIsSubmitting, setFormIsSubmitting] = useState<boolean>(false);
  const [resumeIsUploading, setResumeIsUploading] = useState<boolean>(false);

  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const gatedAction = searchParams.get('action')?.toLowerCase();
  const { flowDisplayInformation, pageDisplayInformation } = getGatedActionDisplayInfo();

  const getStateCertifications = () => {
    return userFormData.certifications.filter(certification => certification.isHidden === false);
  };

  const handleFormChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === 'hasCertification') {
      if (value === 'true' && getStateCertifications().length === 0) {
        setUserFormData({
          ...userFormData,
          hasCertification: true,
          certifications: [...userFormData.certifications, empty_certification_input()],
        });
        return;
      } else if (value === 'false') {
        const updatedCertifications = getStateCertifications().map(certification => {
          const errors = {
            hasNameError: false,
            hasStatusError: false,
          };
          certification.errors = errors;
          return certification;
        });
        setUserFormData({
          ...userFormData,
          hasCertification: true,
          certifications: [...updatedCertifications],
        });
      }

      setUserFormData({ ...userFormData, hasCertification: value === 'true' });
      return;
    }

    setUserFormData({ ...userFormData, [name]: value });
  };

  const uploadResume = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      setResumeIsUploading(true);
      const { fileData, errors } = checkFileMeetsRequirements(event);
      if (isEmpty(errors)) {
        const response = await updateCandidateProfileResume(fileData, user.id);
        setUserFormData({ ...userFormData, resume: formatResumeFileName(response.data.resume) });
        setResumeUploadErrors(
          resumeUploadErrors.filter(error => error !== 'Please upload a resume.')
        );
      } else {
        setResumeUploadErrors(errors);
        console.error('Upload Error Messages', resumeUploadErrors);
      }
    } catch (error) {
      console.error('Error uploading resume', error);
    } finally {
      setResumeIsUploading(false);
    }
  };

  const removeEmptyCertifications = (excludeOutOfStateCredentials = false, certifications = []) => {
    const credentialsToFilter = excludeOutOfStateCredentials
      ? getStateCertifications()
      : certifications;
    return credentialsToFilter.filter(certification => {
      const isOutOfStateCredential = certification.state != MISSOURI_STATE_ID;
      const hasValidStatusAndSubject = !!certification.status?.id && !!certification.subject?.id;
      return hasValidStatusAndSubject || isOutOfStateCredential;
    });
  };

  const certificationsFormHasErrors = (continueButtonIsPressed = false) => {
    let hasErrors = false;
    let stateCertifications = getStateCertifications();

    if (continueButtonIsPressed && !userFormData.hasCertification) {
      stateCertifications = removeEmptyCertifications(true);
    }

    stateCertifications.forEach(certification => {
      const formCertificationIndex = userFormData.certifications.findIndex(
        formCertification => formCertification.client_id === certification.client_id
      );

      const hasNameError = !certification?.subject?.id;
      const hasStatusError = !certification?.status?.id;

      userFormData.certifications[formCertificationIndex].errors = {
        hasNameError,
        hasStatusError,
      };

      if (hasNameError || hasStatusError) {
        hasErrors = true;
      }
    });

    setUserFormData({ ...userFormData, certifications: [...userFormData.certifications] });
    return hasErrors;
  };

  const isFormValid = () => {
    let isFormValid = true;
    const { resume } = userFormData;

    if (!resume && pageDisplayInformation.requiredFields.includes('resume')) {
      setResumeUploadErrors(['Please upload a resume.']);
      isFormValid = false;
    }
    if (certificationsFormHasErrors(true)) {
      isFormValid = false;
    }
    return isFormValid;
  };

  const fetchUserData = async () => {
    try {
      setUserDataIsLoading(true);
      const { resume, credentials } = await expressInterestAPI.fetchUserProfileAndCredentials(
        user.id,
        MISSOURI_STATE_CODE
      );
      if (resume && credentials.length > 0) {
        setUserFormData({
          ...userFormData,
          certifications: credentials,
          resume,
        });
      } else if (resume) {
        setUserFormData({ ...userFormData, resume });
      } else if (credentials.length > 0) {
        setUserFormData({ ...userFormData, certifications: credentials });
      }
    } catch (error) {
      console.error('Error fetching user profile and credentials', error);
    } finally {
      setUserDataIsLoading(false);
    }
  };

  useEffect(() => {
    if (userFormData.hasCertification && getStateCertifications().length === 0) {
      setUserFormData({
        ...userFormData,
        certifications: [...userFormData.certifications, empty_certification_input()],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFormData.certifications, userFormData.hasCertification]);

  const fetchCredentialOptionsAndStatuses = async () => {
    try {
      const {
        credentialOptions,
        credentialStatusOptions,
      } = await expressInterestAPI.fetchCredentialOptionsAndStatuses(MISSOURI_STATE_CODE);
      setCredentialOptions(credentialOptions);
      setCredentialStatusOptions(credentialStatusOptions);
    } catch (error) {
      console.error('Error fetching credential options and statuses', error);
    }
  };

  const handleSaveAndContinue = async () => {
    if (isFormValid()) {
      try {
        setFormIsSubmitting(true);
        let certificationsToSave = userFormData.certifications;
        if (!userFormData.hasCertification) {
          certificationsToSave = userFormData.certifications.filter(
            certification => certification.isHidden
          );
        } else {
          certificationsToSave = removeEmptyCertifications(false, userFormData.certifications);
        }
        await expressInterestAPI.updateUserCredentials(certificationsToSave, user.id);
        if (gatedAction === ConnectGatedActions.ALERT) {
          await connectSubscriptionsAPI.createSubscription();
        }
        history.push(pageDisplayInformation.continueToUrl);
      } catch (error) {
        console.error('Error updating user credentials', error);
      } finally {
        setFormIsSubmitting(false);
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    fetchCredentialOptionsAndStatuses();
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateConnectFirstProfileSetupDateTime(user);
  }, [user]);

  return (
    <Box
      sx={{
        width: 635,
        maxWidth: '100vw',
        margin: '22px auto',
      }}
    >
      <HorizontalLinearStepper
        activeStep={pageDisplayInformation.step}
        steps={flowDisplayInformation.stepper.steps}
        mobileSteps={flowDisplayInformation.stepper.mobileSteps}
      />
      <Paper
        elevation={4}
        sx={{
          borderRadius: '12px',
          margin: '0px 15px',
        }}
      >
        <Box
          component="form"
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            paddingTop: '40px',
            paddingBottom: '16px',
            marginTop: '24px',
            borderRadius: '12px',
          }}
        >
          <Typography
            variant="h1"
            color="#101828"
            fontSize={28}
            fontWeight={400}
            fontFamily={nimbleTheme.typography.fontFamily['Aeonik TRIAL']}
            textAlign="center"
          >
            {pageDisplayInformation.headerText}
          </Typography>
          <Stack spacing={3} px={{ xs: 2, sm: 8 }} py={{ xs: 2, sm: 3 }}>
            <Stack spacing={1}>
              <QuestionLabel required={pageDisplayInformation.requiredFields.includes('resume')}>
                Upload your resume
              </QuestionLabel>
              <FileUpload
                uploadedFileName={userFormData.resume}
                handleChange={uploadResume}
                errors={resumeUploadErrors}
                inputName="resume"
                isLoading={userDataIsLoading || resumeIsUploading}
                fileNameMaxWidth="240px"
              />
            </Stack>
            <div>
              <QuestionLabel
                required={pageDisplayInformation.requiredFields.includes('ceritifications')}
              >
                Do you have a current or expected teaching certification?
              </QuestionLabel>
              <CertificationsFormSection
                isLoading={userDataIsLoading}
                credentialOptions={credentialOptions}
                credentialStatusOptions={credentialStatusOptions}
                handleFormChange={handleFormChange}
                userFormData={userFormData}
                setUserFormData={setUserFormData}
                certificationsFormHasErrors={certificationsFormHasErrors}
                getStateCertifications={getStateCertifications}
                formIsSubmitting={formIsSubmitting}
              />
            </div>
          </Stack>
        </Box>
      </Paper>
      <ConnectProfileFlowButtonGroup
        primaryButton={{
          primaryButtonLabel: pageDisplayInformation?.primaryButtonLabel,
          primaryAction: handleSaveAndContinue,
          disabled: false,
          isLoading: formIsSubmitting,
        }}
        secondaryButton={{
          secondaryButtonLabel: 'Back',
          secondaryAction: () => history.push(pageDisplayInformation.backUrl),
        }}
        hasError={false}
      />
    </Box>
  );
};

const CertificationsFormSection = ({
  isLoading,
  credentialOptions,
  credentialStatusOptions,
  handleFormChange,
  userFormData,
  setUserFormData,
  certificationsFormHasErrors,
  getStateCertifications,
  formIsSubmitting,
}) => {
  const { certifications } = userFormData;

  const handleCertificationInputChange = (
    _event: SyntheticEvent<Element, Event>,
    newValue: Option,
    inputName: string,
    clientID: number = null
  ) => {
    const index = certifications.findIndex(certification => certification.client_id === clientID);
    if (inputName === 'certificationName') {
      const subject = { id: Number(newValue.value), label: newValue.label };

      certifications[index].subject = subject;
      certifications[index].errors.hasNameError = false;
      setUserFormData({ ...userFormData, certifications: [...certifications] });
    }
    if (inputName === 'certificationStatus') {
      const status = { id: Number(newValue.value), label: newValue.label };

      certifications[index].status = status;
      certifications[index].errors.hasStatusError = false;
      setUserFormData({ ...userFormData, certifications: [...certifications] });
    }
  };

  const addCertificationButtonHandler = () => {
    if (getStateCertifications().length === 0 || !certificationsFormHasErrors()) {
      setUserFormData({
        ...userFormData,
        certifications: [...certifications, empty_certification_input()],
      });
    } else {
      setUserFormData({ ...userFormData, certifications: [...certifications] });
    }
  };

  return (
    <>
      <RadioGroup
        aria-labelledby="current-or-expected-certification-radio-buttons-group"
        name="hasCertification"
        value={userFormData.hasCertification}
        onChange={handleFormChange}
      >
        <Stack direction={{ xs: 'row' }} spacing={{ xs: 1, sm: 2 }} sx={{ width: '100%' }}>
          <RadioCardToggle
            text="Yes"
            radioValue={true}
            selectedValue={userFormData.hasCertification}
            width="50%"
            height="54px"
            disabled={formIsSubmitting}
          />
          <RadioCardToggle
            text="No"
            radioValue={false}
            selectedValue={userFormData.hasCertification}
            width="50%"
            height="54px"
            disabled={formIsSubmitting}
          />
        </Stack>
      </RadioGroup>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        userFormData.hasCertification &&
        certifications?.length > 0 &&
        certifications.map(certification => {
          if (!certification.isHidden) {
            return (
              <Grid
                key={certification.client_id}
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={{ xs: 1, sm: 2 }}
                sx={{ marginTop: 3 }}
              >
                <GridItem xs={6}>
                  <QuestionLabel required>Teaching Certification</QuestionLabel>
                  <SearchableSelect
                    name="certificationName"
                    placeholder="Select certification"
                    handleChange={(event, newValue) =>
                      handleCertificationInputChange(
                        event,
                        newValue,
                        'certificationName',
                        certification.client_id
                      )
                    }
                    value={
                      certification?.subject?.id
                        ? {
                            value: certification.subject.id,
                            label: certification.subject.label,
                          }
                        : null
                    }
                    options={credentialOptions}
                    error={certification.errors?.hasNameError}
                    helperText="Please select a certification."
                  />
                </GridItem>
                <GridItem xs={6} sx={{ paddingRight: '0px' }}>
                  <QuestionLabel required>Certification Status</QuestionLabel>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      gap: '10px',
                    }}
                  >
                    <SearchableSelect
                      name="certificationStatus"
                      placeholder="Select certification status"
                      handleChange={(event, newValue) =>
                        handleCertificationInputChange(
                          event,
                          newValue,
                          'certificationStatus',
                          certification.client_id
                        )
                      }
                      value={
                        certification?.status?.id
                          ? {
                              value: certification.status.id,
                              label: certification.status.label,
                            }
                          : null
                      }
                      options={credentialStatusOptions}
                      error={certification.errors?.hasStatusError}
                      helperText="Please select a certification status."
                      sx={{ width: '100%' }}
                    />
                    {getStateCertifications().length > 1 && (
                      <IconButton
                        aria-label="delete-certification"
                        sx={{
                          color: 'text.light',
                          marginTop:
                            (certification.errors?.hasStatusError ||
                              certification.errors?.hasNameError) &&
                            '-14px',
                          padding: 0,
                          '&:hover': {
                            color: '#CC3340',
                            backgroundColor: 'transparent',
                          },
                        }}
                        onClick={() => {
                          const updatedCertifications = certifications.filter(
                            x => x.client_id !== certification.client_id
                          );
                          setUserFormData({
                            ...userFormData,
                            certifications: updatedCertifications,
                          });
                        }}
                        disabled={formIsSubmitting}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </div>
                </GridItem>
              </Grid>
            );
          } else {
            return null;
          }
        })
      )}
      {!isLoading && (
        <div style={{ marginTop: nimbleTheme.spacing(3) }}>
          {userFormData.hasCertification ? (
            <PrimaryButton
              sx={{
                bgcolor: nimbleTheme.palette.periwinkle.mediumDark,
                width: '60%',
                minWidth: 270,
                fontSize: '14px',
                fontWeight: 600,
                borderRadius: '8px',
                padding: '8px 14px',
                '&:hover': {
                  bgcolor: nimbleTheme.palette.periwinkle.medium,
                },
              }}
              onClick={addCertificationButtonHandler}
            >
              <AddCircleOutlineIcon sx={{ marginRight: theme => theme.spacing(1) }} />
              Add more certifications
            </PrimaryButton>
          ) : (
            <Alert>
              {
                "Don't worry, keep going! You may still be a qualified candidate if you are eligible for emergency certifications."
              }
            </Alert>
          )}
        </div>
      )}
    </>
  );
};

const QuestionLabel = styled(InputLabel)(({ theme }) => ({
  textWrap: 'wrap',
  marginBottom: theme.spacing(1),
}));
