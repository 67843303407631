import './emailtemplatemodal.css';
import { Component, LegacyRef } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';

import { defaultErrorState, VISIBLE_TO_VALUE } from './utils';
import { Dialog } from 'sharedComponents/Dialog/Dialog';
import { EmailTemplateModalProps, EmailTemplateModalState } from './types';
import { FormInputs } from './FormInputs';
import { handleVariableClick } from '../../utils/emailutils';
import { ModalCTAButtons } from './ModalCTAButtons';
import { TextEditorAndVariables } from './TextEditorAndVariables';
import { visibleTo } from '../../utils/enums';
import { Box } from '@mui/material';

export default class EmailTemplateModal extends Component<
  EmailTemplateModalProps,
  EmailTemplateModalState
> {
  handleVariableClick: () => void;
  quillRef: LegacyRef<ReactQuill>;

  constructor(props) {
    super(props);
    this.state = {
      emailTemplate: {
        id: '',
        subject: '',
        title: '',
        content: '',
        view_permissions: 0,
        reference_check: false,
        type: 'General',
      },
      content: '',
      hasError: defaultErrorState,
    };
    this.handleVariableClick = handleVariableClick.bind(this);
    this.quillRef;
  }

  componentDidMount = () => {
    const emailTemplate = Object.assign({}, this.props.emailTemplate);

    if (emailTemplate.content) {
      emailTemplate.content = emailTemplate.content.replace(/\n/g, '<br>');
    }

    emailTemplate.type = this.props.emailTemplate.type || 'General';

    this.setState({ emailTemplate });
  };

  componentDidUpdate = prevProps => {
    if (JSON.stringify(prevProps.emailTemplate) !== JSON.stringify(this.props.emailTemplate)) {
      const emailTemplate = Object.assign({}, this.props.emailTemplate);

      if (emailTemplate.content) {
        emailTemplate.content = emailTemplate.content.replace(/\n/g, '<br>');
      }

      this.setState({
        emailTemplate,
      });
    }
  };

  handleChange = event => {
    /** accommodate react-dropdown component which is used for view_permissions dropdown.
     * that change handler will not send an event, so we can check if e.target exists.
     */
    let name;
    let value;

    if (event.target.id === 'title-input') {
      this.setState(previousState => ({
        hasError: {
          ...previousState.hasError,
          name: false,
        },
      }));
    } else if (event.target.id === 'subject-input') {
      this.setState(previousState => ({
        hasError: {
          ...previousState.hasError,
          subject: false,
        },
      }));
    } else if (event.target.id === 'content') {
      this.setState(previousState => ({
        hasError: {
          ...previousState.hasError,
          content: false,
        },
      }));
    }

    if (event.target === undefined) {
      name = 'view_permissions';
      value = VISIBLE_TO_VALUE[event.value];
    } else {
      name = event.target.name;
      value = event.target.value;
    }

    const emailTemplate = this.state.emailTemplate;
    emailTemplate[name] = value;
    this.setState({ emailTemplate });
  };

  handleVisibilityChange = event => {
    this.setState({ hasError: defaultErrorState });
    const emailTemplate = this.state.emailTemplate;

    const permission = visibleTo().find(object => {
      return object.value === event.target.value;
    });

    emailTemplate.view_permissions = permission.key;
    this.setState({ emailTemplate });
  };

  handleQuillChange = value => {
    /** different enough from handle change to justify a separate handler function. */
    const emailTemplate = this.state.emailTemplate;
    emailTemplate.content = value;
    this.setState({ emailTemplate, hasError: defaultErrorState });
  };

  handleTypeChange = value => {
    this.setState({ hasError: defaultErrorState });
    const emailTemplate = this.state.emailTemplate;
    const valueToUse = value.target.value;
    const type = this.props.emailTypes.find(type => type.value === valueToUse);
    emailTemplate.type = type?.label;
    this.setState({ emailTemplate });
  };

  onSave = () => {
    const emailTemplate = this.state.emailTemplate;
    const { title, subject, content } = emailTemplate;
    const userHasDeletedPreviouslySavedContent = content === '<p><br></p>';

    if (!title || !subject || !content || userHasDeletedPreviouslySavedContent) {
      if (!title) {
        this.setState(previousState => ({
          hasError: { ...previousState.hasError, name: true },
        }));
      }

      if (!subject) {
        this.setState(previousState => ({
          hasError: {
            ...previousState.hasError,
            subject: true,
          },
        }));
      }
      if (!content || userHasDeletedPreviouslySavedContent) {
        this.setState(previousState => ({
          hasError: {
            ...previousState.hasError,
            content: true,
          },
        }));
      }

      return;
    }

    /** send email template to the api to save. Also invoke the higher level save function,
     * which will close the modal and update the template list in the UI (so we don't have to
     * wait for an axios.get)
     */
    let method;
    let url;

    if (emailTemplate.id) {
      method = 'put';
      url = `/api/emailtemplate/${emailTemplate.id}/`;
    } else {
      method = 'post';
      url = '/api/emailtemplates/';
    }

    if (emailTemplate.type === 'Application confirmation') {
      emailTemplate.view_permissions = 1;
    }

    axios[method](url, emailTemplate)
      .then(() => {
        this.props.onSave();
        this.props.handleShowSuccessToast();
      })
      .catch(() => this.props.handleShowErrorToast());
    this.props.onHide(); // closes modal on parent component
  };

  handleDeleteTemplate = () => {
    this.props.onDelete();
  };

  render() {
    const { show, onHide } = this.props;
    const emailTemplate = this.state.emailTemplate;

    const visibilityDefaultValue = visibleTo().find(
      object => object.key === emailTemplate.view_permissions
    );

    const typeDefaultValue = this.props.emailTypes.find(object => {
      return object.label === emailTemplate.type;
    });

    const errors = Object.values(this.state.hasError).includes(true);

    const isCreatingGeneralOrReferenceTemplate =
      this.state.emailTemplate.type !== 'Application confirmation';
    const isCreatingOrEditingApplicationConfirmationTemplate =
      this.state.emailTemplate.type === 'Application confirmation' ||
      this.props.emailTemplate.type === 'Application confirmation';

    const shouldTypeSelectBeDisabled = this.props.emailTemplate.type === 'Application confirmation';
    const isEditingGeneralOrReference =
      this.props.emailTemplate.type === 'General' || this.props.emailTemplate.type === 'Reference';

    return (
      <Dialog
        open={show}
        handleClose={onHide}
        maxWidth="md"
        sx={{
          '.MuiPaper-root': {
            width: '85%',
            maxWidth: '900px ',
          },
        }}
        title={emailTemplate?.id === '' ? 'New Email Template' : 'Edit Template'}
        ctaButtons={
          <ModalCTAButtons
            emailTemplateId={this.state.emailTemplate?.id}
            handleDeleteTemplate={this.handleDeleteTemplate}
            hasErrors={errors}
            onHide={onHide}
            onSave={this.onSave}
          />
        }
      >
        <>
          <FormInputs
            allTypes={this.props.emailTypes}
            emailTemplateSubject={emailTemplate.subject || ''}
            handleChange={this.handleChange}
            handleTypeChange={this.handleTypeChange}
            handleVisibilityChange={this.handleVisibilityChange}
            hasApplicationConfirmationTemplate={this.props.hasApplicationConfirmationTemplate}
            hasError={this.state.hasError}
            isCreatingGeneralOrReferenceTemplate={isCreatingGeneralOrReferenceTemplate}
            isDistrictAdmin={this.props.isDistrictAdmin}
            shouldTypeSelectBeDisabled={shouldTypeSelectBeDisabled}
            isEditingGeneralOrReference={isEditingGeneralOrReference}
            titleDefaultValue={emailTemplate.title || ''}
            typeDefaultValue={typeDefaultValue?.value || 'general'}
            visibilityDefaultValue={visibilityDefaultValue?.value}
          />
          <TextEditorAndVariables
            content={emailTemplate.content || ''}
            emailTemplate={this.state.emailTemplate}
            handleQuillChange={this.handleQuillChange}
            handleVariableClick={this.handleVariableClick}
            hasError={this.state.hasError.content}
            quillRef={el => {
              this.quillRef = el;
            }}
          />
          {isCreatingOrEditingApplicationConfirmationTemplate && (
            <Box sx={{ paddingTop: '20px', fontStyle: 'italic' }}>
              Please note: external job board candidates will have their Nimble account information
              appended to the application confirmation email.
            </Box>
          )}
        </>
      </Dialog>
    );
  }
}
