import axios from 'axios';
import { tagColors, user_type } from '../../../utils/enums';

/**
 * Utility function for getting a tag property
 *
 * This function returns a different value depending on the arg provided.
 *
 * If the label is desired, pass the label prop.
 *
 * If the function is used in a color picker dropdown (ie. CustomTag Modal),
 * then pass dropdown prop to recieve the color's hexcode.
 *
 * Else, the function defaults to returning the bgColor property for the tag's
 * value background color (spec wants different colors for the picker vs. actual
 * tag).
 */
export const getTagProperty = (tag, label = false, dropdown = false) => {
  const clr = tagColors().find(color => color.key === tag.label_color);

  if (label) {
    return clr.label;
  } else if (dropdown) {
    return clr.hexCode;
  } else {
    return clr.bgColor;
  }
};

/**
 * Assigns selected tag to the specified candidate/prospect.
 */
export const assignTag = async (tagId, assignedUser) => {
  if (assignedUser.profile) {
    await axios.post(`/api/candidate-tags/`, {
      tag: tagId,
      candidate: assignedUser.id
    });
  } else {
    await axios.post(`/api/prospect-tags/`, {
      tag: tagId,
      prospect: assignedUser.id
    });
  }
};

/**
 * Unassigns tag from the candidate/prospect
 */
export const removeTag = async (tagId, removeFromUser) => {
  if (removeFromUser.profile) {
    await axios.delete(`/api/candidate-tags/${tagId}/`);
  } else {
    await axios.delete(`/api/prospect-tags/${tagId}/`);
  }
};

const USERTYPE = user_type().reduce((obj, item) => {
  obj[item.value] = item.key;
  return obj;
}, {});

/**
 *
 * @param user user object being validated
 * @param tag tag object being validated
 *
 * @return True if user_type is valid, else False
 */
export const validateTagUserType = (user, tag) => {
  const { district_admin_visibility, district_user_visibility, school_admin_visibility } = tag;
  const userType = user.profile.user_type;

  if (userType === USERTYPE.super_admin) {
    // super admins can always view tag
    return true;
  } else if (userType === USERTYPE.district_admin && !district_admin_visibility) {
    // district admins can't view tag
    return false;
  } else if (userType === USERTYPE.district_user && !district_user_visibility) {
    // district users can't view tag
    return false;
  } else if (userType === USERTYPE.school_admin && !school_admin_visibility) {
    // school admins can't view tag
    return false;
  }
  return true;
};
