import styled from 'styled-components';
import ReactQuill from 'react-quill';

export default function DirectionText({ directions, setDirections }) {
  return (
    <>
      <Header>Type directions here:</Header>
      <ReactQuillContainer>
        <ReactQuill
          value={directions || ''}
          onChange={value => {
            setDirections(value);
          }}
          placeholder="e.g. Complete and submit this form within 3 days of your interview"
        />
      </ReactQuillContainer>
    </>
  );
}

const Header = styled.p`
  font-size: 16px;
  color: #444444;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 6px;
`;

const ReactQuillContainer = styled.div`
  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    position: absolute;
    right: 0;
    width: 18px;
    height: 18px;
  }
`;
