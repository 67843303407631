import { BaseNimbleAPI } from './baseAPI';
import { stringify } from 'query-string';
import axios from 'axios';

class StateDashboardMetricsAPI extends BaseNimbleAPI {
  cancelGetChartData = {};

  get config() {
    return {
      baseURL: '/api/dashboard-metrics/state',
      // Comma-separated params, due to length limitations.
      // If still too long in the future, we may need to use on POST to fetch
      paramsSerializer: function(params) {
        return stringify(params, { arrayFormat: 'comma' });
      }
    };
  }

  /**
   * Fetch a state's application count
   * @param {Array} hiringSeasons - Hiring seasons to filter by
   * @param {Array} districts - Districts to filter by
   * @param {string} stateCode - Unique Identifier for the state
   */
  async getApplicationCount(hiringSeasons, districts, stateCode) {
    if (this.cancelGetApplicationCount !== undefined) {
      this.cancelGetApplicationCount();
    }

    const response = await this.get(`/application-count/`, {
      params: {
        ...(hiringSeasons && { hiring_seasons: hiringSeasons }),
        ...(districts && { districts: districts }),
        ...(stateCode && { state: stateCode })
      },
      cancelToken: new axios.CancelToken(cancel => {
        this.cancelGetApplicationCount = cancel;
      })
    });
    return response.data;
  }

  /**
   * Fetches data for a chart based on provided parameters
   */
  async getChartData(
    segmentId,
    chartTypeId,
    filtersSelected,
    grouped,
    stateCode,
    excludePostingOnly,
    graphId
  ) {
    if (this.cancelGetChartData !== undefined && this.cancelGetChartData[graphId] !== undefined) {
      this.cancelGetChartData[graphId]();
    }

    const employmentTypeMap = { 0: 0.5, 1: 0.75, 2: 1.0 };

    const response = await this.get(`/analytics-filter/`, {
      params: {
        segment_id: segmentId,
        chart_type_id: chartTypeId,
        grouped: grouped,
        state: stateCode,
        districts: filtersSelected.districts,
        hiring_seasons: filtersSelected.hiring_seasons,
        certification_area: filtersSelected.certification_area,
        employment_type: filtersSelected.employment_type.map(e => employmentTypeMap[e]),
        gender: filtersSelected.gender,
        epp: filtersSelected.epp,
        nces: filtersSelected.nces,
        grades: filtersSelected.grade,
        race_ethnicity: filtersSelected.race_ethnicity,
        subcategories: filtersSelected.subcategory,
        status: filtersSelected.status,
        exclude_posting_only: excludePostingOnly
      },
      cancelToken: new axios.CancelToken(cancel => {
        this.cancelGetChartData[graphId] = cancel;
      })
    });
    return response.data;
  }
}

export default new StateDashboardMetricsAPI();
