import Moment from 'moment';
import styled, { css } from 'styled-components';

import { theme } from 'ui-kit';
import { formatSalary, formatRoleType, getDeadline } from 'utils/roleutils';
import { startDateType, startDateLabels } from 'utils/enums';

import { Row } from './StateJobBoardSharedComponents';
import { ReactComponent as RightCaret } from 'assets/icon_right_caret_grey.svg';
import { forwardRef } from 'react';

const StateJobRow = forwardRef(({ role }, ref) => {
  const viewRole = () => {
    window.open(`/jobview/${role.id}`, '_blank');
  };

  return (
    <JobRow onClick={viewRole} ref={ref}>
      <div style={{ overflow: 'hidden ' }}>
        <JobTitle>{role.title}</JobTitle>
        <GreySubtext>{role.district.name}</GreySubtext>
      </div>
      <SchoolSectionContainer>
        <SchoolsSection job={role} />
      </SchoolSectionContainer>
      <HideOnMobile>
        <span>{formatSalary(role.salary_min, role.salary_max, role.salary_type)}</span>
      </HideOnMobile>
      <HideOnMobile>
        <span>{formatRoleType(role.fulltime)}</span>
      </HideOnMobile>
      <HideOnMobile>
        <StartDate role={role} />
      </HideOnMobile>
      <Deadline date={role.deadline} />
      <HideOnMobile>
        <StyledRightCaret />
      </HideOnMobile>
    </JobRow>
  );
});

export default StateJobRow;

const Deadline = ({ date }) => {
  if (!date) {
    return <div>Until filled</div>;
  }
  const deadline = getDeadline(date);
  return (
    <div>
      <div>{Moment(deadline).format('l')}</div>
      <GreySubtext>{Moment(deadline).fromNow()}</GreySubtext>
    </div>
  );
};

const StartDate = ({ role }) => {
  if (role.start_date_type === startDateType['Specific date'] && role.start_date) {
    return (
      <div>
        <div>{Moment(role.start_date).format('l')}</div>
        <GreySubtext>{Moment(role.start_date).fromNow()}</GreySubtext>
      </div>
    );
  } else if (role.start_date_type) {
    return startDateLabels[role.start_date_type];
  } else {
    // defensive, don't actually expect to get here
    return 'Not specified';
  }
};

const SchoolsSection = ({ job }) => {
  let word = 'N/A';
  if (job.schools?.length === 1) {
    word = job.schools[0].name;
  } else if (job.schools?.length > 1) {
    word = 'Multiple';
  }

  if (word === 'Multiple') {
    return (
      <TextEllipsisContainer
        data-tip={job.schools.map(school => school.name).join(', ')}
        data-for="state-jobboard-schools"
        data-delay-show="100"
      >
        {word}
      </TextEllipsisContainer>
    );
  } else {
    return <TextEllipsisContainer>{word}</TextEllipsisContainer>;
  }
};

const overflowEllipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: fit-content;
`;

const hideOnMobile = css`
  display: none;
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    display: block;
  }
`;

const TextEllipsisContainer = styled.div`
  ${overflowEllipsis}
`;

const JobRow = styled(Row)`
  cursor: pointer;
  padding: 16px 0;

  @media screen and (min-width: ${theme.breakpoints.xl}) {
    height: 80px;
    padding: 0;
  }
`;

const JobTitle = styled.p`
  ${overflowEllipsis}

  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
`;

const GreySubtext = styled.p`
  ${overflowEllipsis}

  color: #969696;
`;

const StyledRightCaret = styled(RightCaret)`
  display: none;
  @media screen and (min-width: ${theme.breakpoints.xl}) {
    display: block;
  }
`;

const HideOnMobile = styled.div`
  ${hideOnMobile}
`;

const SchoolSectionContainer = styled.div`
  ${hideOnMobile}

  overflow: hidden;
`;
