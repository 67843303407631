import styled from 'styled-components';

export default function RubricViewAnswer({ answer }) {
  return (
    <RubricGridContainer numberOfColumns={answer.rubric_columns.length}>
      <TextCenterDiv>
        <HeaderText>
          {answer.question_text}
          {answer.is_required && <sup>*</sup>}
        </HeaderText>
      </TextCenterDiv>
      {answer.rubric_columns.map(item => (
        <RubricItemContainer key={item.number}>
          <div>
            <HeaderText>{item.number}</HeaderText>
            <HeaderText>{item.label}</HeaderText>
          </div>
          <DescriptionBox
            selected={answer.answer_rubric === item.number}
            value={item.description}
            readOnly
          />
        </RubricItemContainer>
      ))}
    </RubricGridContainer>
  );
}

const TextCenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const RubricItemContainer = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const HeaderText = styled.p`
  font-size: 14px;
  color: #444444;
  font-weight: 800;
  line-height: 19px;
`;

const DescriptionBox = styled.textarea`
  background-color: transparent;
  border: 1px solid ${props => (props.selected ? '#00B88D' : '#D7D7D7')};
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.0778136);
  border-radius: 3px;
  padding: 11px 15px;
  color: #8e8d8d;
  font-size: 11px;
  line-height: 15px;
  margin-top: 11px;
  height: 118px;
  overflow-y: auto;
  text-align: initial;
  resize: none;
`;

const RubricGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (min-width: 991px) {
    grid-template-columns: 100px ${props => `repeat(${props.numberOfColumns}, minmax(0, 1fr))`};
    grid-column-gap: 11px;
  }
`;
