import { Component } from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import { BulletPoint } from 'ui-kit/icons';
import moment from 'moment';
import auth from '../../../utils/auth';
import { MentionsInput, Mention } from 'react-mentions';
import mentionStyles from './mentionStyles.js';

export default class InternalCandidateNotes extends Component {
  constructor(props) {
    super(props);
    this.getUser = auth.getUser();
    this.state = {
      text: '',
      seeMore: false,
      loading: false,
      taggedUsers: [],
    };
    this.addNote = this.addNote.bind(this);
    this.renderNotes = this.renderNotes.bind(this);
  }

  static propTypes = {
    notes: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateNote: PropTypes.func.isRequired,
    adminUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  handleChange = event => {
    const text = event.target.value;
    this.setState({ text });
  };

  async addNote() {
    const { taggedUsers, text } = this.state;
    const regExp = /@\[(.*?)\]/g;
    const finalUserList = text.match(regExp) || [];

    if (taggedUsers.length !== finalUserList.length) {
      // if these lengths are different, a tag was removed. Search
      // for the user(s) not present in finalUserList, and remove.
      let i = taggedUsers.length;
      while (i--) {
        let fullName = `${taggedUsers[i].first_name} ${taggedUsers[i].last_name}`;
        // substring below changes '@[First Name]' to 'First name', for comparison with fullName
        if (!finalUserList.find(item => item.substring(2, item.length - 1) === fullName)) {
          taggedUsers.splice(i, 1);
        }
      }
    }

    const created_by = `${this.getUser.first_name} ${this.getUser.last_name}`;
    const created_at = new Date(moment.now());
    const newNote = { text, created_by, created_at };

    this.props.updateNote(newNote, taggedUsers, text);
    this.clearStaging();
  }

  clearStaging = () => {
    const text = '';
    const taggedUsers = [];
    this.setState({
      text,
      taggedUsers,
    });
  };

  renderNotes() {
    const notes = this.props.notes;
    notes.sort((a, b) => (a.created_at > b.created_at ? -1 : a.created_at < b.created_at ? 1 : 0));

    return (
      <NotesAnimatedContainer extended={this.state.seeMore}>
        {notes.map(note => (
          <LineItem key={note.id}>
            <BulletPoint />
            <div>
              <div style={{ lineHeight: '24px' }}>{note.text}</div>
              <Signature>
                {`${note.created_by} - ${moment(note.created_at).format('MM/DD/YYYY [at] h:mm a')}`}
              </Signature>
            </div>
          </LineItem>
        ))}
      </NotesAnimatedContainer>
    );
  }

  renderSeeNotesButton = () => {
    const notes = this.props.notes;
    let notesCharacterCount = 0;
    for (let i = 0; i < notes.length; i++) {
      notesCharacterCount += notes[i].text.length;
    }
    if (notes.length <= 2 && notesCharacterCount <= 200) {
      return null;
    }
    return (
      <Expand
        onClick={() =>
          this.setState(prevState => {
            return { seeMore: !prevState.seeMore };
          })
        }
      >
        <span className="pointer">{this.state.seeMore ? 'See less' : 'See more'}</span>
      </Expand>
    );
  };

  renderUserSuggestion = (suggestion, search, highlightedDisplay) => {
    return <div>{highlightedDisplay}</div>;
  };

  onAdd = (id, display) => {
    /** callback function that runs when a user is tagged. Add tagged user to state, and email that list
     *  when the note is published (which happens in the addNote function).
     */
    const taggedUsers = this.state.taggedUsers;
    const user = this.props.adminUsers.find(admin => admin.id === Number(id));
    // don't tag same user multiple times, because that will send multiple emails
    if (taggedUsers.includes(user)) {
      return;
    }
    taggedUsers.push(user);
    this.setState({ taggedUsers });
  };

  render() {
    const mappedData = this.props.adminUsers.map(adminUser => ({
      ...adminUser,
      id: String(adminUser.id),
    }));

    return (
      <div>
        {this.renderNotes()}
        {this.renderSeeNotesButton()}
        <MentionsInput
          value={this.state.text}
          onChange={this.handleChange}
          placeholder={"Mention people using '@'"}
          style={mentionStyles}
          markup="@[__display__]"
          displayTransform={(id, display) => `@${display}`}
        >
          <Mention
            trigger="@"
            style={{ backgroundColor: 'var(--gray)' }}
            data={mappedData}
            renderSuggestion={this.renderUserSuggestion}
            onAdd={this.onAdd}
          />
        </MentionsInput>
        <AddNoteButton onClick={this.addNote}>Add Note</AddNoteButton>
        {/* div style=clear keeps the floated button in the document flow */}
        <div style={{ clear: 'both' }} />
      </div>
    );
  }
}

const AddNoteButton = styled.button`
  color: white;
  background-color: #00b88d;
  border-radius: 3px;
  border: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  width: 155px;
  height: 35px;
  float: right;
`;

const Signature = styled.div`
  font-size: 12px;
  color: var(--darkgray);
`;

const Expand = styled.div`
  text-align: center;
  color: var(--green);
  margin: -5px auto 10px;
  font-size: 13px;
`;

const NotesAnimatedContainer = styled.div`
  max-height: 140px;
  overflow: hidden;
  margin-bottom: 10px;
  overflow: auto;
  -webkit-transition: max-height 1s; /* Safari */
  transition: max-height 1s;
  transition-timing-function: ease;

  ${({ extended }) =>
    extended
      ? `
    max-height: 500px;
    -webkit-transition: max-height 1s; /* Safari */
    transition: max-height 1s;
    transition-timing-function: ease;
  `
      : ''}
`;

const LineItem = styled.div`
  display: grid;
  grid-template-columns: 10px 1fr;
  grid-gap: 12px;
  align-items: baseline;
  margin-bottom: 10px;
`;
