import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Input, Modal } from 'ui-kit';
import ModalFooterNew from 'components/common/Modal/ModalFooterNew';

import { addDocument } from '../../utils/fileUpload.js';
import greenCheckmark from '../../assets/icon_check_green.svg';
import paperAirplane from '../../assets/icon-paper-airplane-green.svg';
import ErrorText from '../errortext';
import { UploadFileHelperMessage } from 'components/uploadFileHelperMessage.tsx';

export default class UploadDocumentModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    submitAdditionalDocument: PropTypes.func.isRequired,
    hideInstructions: PropTypes.bool,
    postSubmitCallback: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      submitDisabled: false,
      attachmentName: null,
      documentExists: false,
      uploadErrorMessages: {
        document: '',
      },
      document: {},
      submitErrorMessage: '',
      postSubmission: false,
    };
    this.initialState = Object.assign({}, this.state);

    this.addDocument = addDocument.bind(this);
  }

  handleChange = e => {
    this.setState({
      attachmentName: e.target.value,
      submitErrorMessage: '',
    });
  };

  onSubmit = () => {
    if (this.checkForErrors()) {
      this.setState({ submitDisabled: true });
      let newState = this.initialState;
      this.props
        .submitAdditionalDocument(this.state.attachmentName, this.state.document)
        .then(r => {
          if (r.status === 201) {
            this.setState({ newState });
            this.setState({ postSubmission: true });
            if (this.props.postSubmitCallback) {
              this.props.postSubmitCallback();
            }
          }
        });
    }
  };

  checkForErrors = () => {
    if (!this.state.attachmentName) {
      this.setState({ submitErrorMessage: 'Please name this attachment.' });
      return false;
    } else if (!this.state.documentExists) {
      this.setState({ submitErrorMessage: 'Please select a file.' });
      return false;
    }
    return true;
  };

  clickDocumentInput = () => {
    const uploadErrorMessages = this.state.uploadErrorMessages;
    uploadErrorMessages.resume = '';
    this.setState({ uploadErrorMessages });
    this.documentInput.click();
  };

  changeDocument = event => {
    const file = this.addDocument(event);
    // If there's an error
    if (!file) return;
    this.setState({
      document: file.fileData,
      uploadErrorMessages: file.uploadErrorMessages,
      documentExists: true,
    });
  };

  document = () => {
    if (this.state.documentExists) {
      let nameShowing = '';
      if (this.state.attachmentName) nameShowing = this.state.attachmentName;
      else nameShowing = this.state.document.name;
      return (
        <FileUploadContainer>
          <UploadFlexContainer>
            <img alt="icon-check" src={greenCheckmark} />
            <div className="ml2">
              <FileName>{nameShowing}</FileName>
            </div>
          </UploadFlexContainer>
        </FileUploadContainer>
      );
    } else {
      return (
        <>
          <GreenButton type="button" onClick={this.clickDocumentInput}>
            Select File
          </GreenButton>
          <UploadFileHelperMessage />
        </>
      );
    }
  };

  render() {
    const { show, onHide } = this.props;
    const errorMessage = this.state.submitErrorMessage || this.state.uploadErrorMessages.document;

    return (
      <Modal.Modal
        isOpen={show}
        onClose={() => {
          this.setState(this.initialState);
          onHide();
        }}
        className="additional-document-modal"
      >
        <Modal.Title>Upload New Attachment</Modal.Title>
        <Modal.Body>
          {!this.state.postSubmission ? (
            <div className="modal-section">
              {!this.props.hideInstructions && (
                <div>
                  If you'd like to upload a supplementary attachment to this job application, you
                  may do so here. If you'd like to update a file related to your candidate profile
                  for this and all future applications (for example to update your resume) please do
                  so using the "profile" link in the menu on the top right corner of the page.
                </div>
              )}
              <h5 className="bold">Attachment name</h5>
              <Input
                name="attachmentName"
                ref="attachmentName"
                type="text"
                placeholder="e.g. cover letter"
                pattern="..*"
                width={1}
                marginTop="1rem"
                marginBottom="1rem"
                value={this.state.attachmentName || ''}
                required
                onChange={this.handleChange}
                hasError={errorMessage}
              />
              <input
                name="document"
                ref={input => (this.documentInput = input)}
                type="file"
                style={{ height: '0px', overflow: 'hidden', margin: '0' }}
                onChange={this.changeDocument}
              />

              {this.document()}

              <StyledErrorMessage message={errorMessage} />
            </div>
          ) : (
            <div className="center">
              <img src={paperAirplane} alt="paper-airplane" />
              <p className="mt1">Your file has been submitted!</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Actions>
          {!this.state.postSubmission ? (
            <ModalFooterNew
              cancel={() => {
                this.setState(this.initialState);
                onHide();
              }}
              save={this.onSubmit}
              saveButtonLabel="Submit"
              saveDisabled={this.state.submitDisabled}
            />
          ) : (
            <ModalFooterNew
              save={() => {
                this.setState(this.initialState);
                this.props.onHide();
              }}
              saveButtonLabel="Close"
            />
          )}
        </Modal.Actions>
      </Modal.Modal>
    );
  }
}

const GreenButton = styled.button`
  height: 50px;
  -webkit-appearance: none;
  border-radius: 3px;
  padding: 0px 42px;
  font-size: 16px;
  background-color: #00b88d;
  color: white;
  border: none;
`;

const FileUploadContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 110px;
  padding: 2rem;
  background-color: white;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid #dcdcdc;
  border-radius: 3px;
`;

const StyledErrorMessage = styled(ErrorText)`
  margin-top: 1rem;
`;

const UploadFlexContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-right: 2rem;
  max-width: 80%;
`;

const FileName = styled.p`
  font-size: 14px;
`;
