import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import * as DashboardWidget from './DashboardWidget';
import { Dropdown } from 'ui-kit';
import * as Toggle from './Toggle';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import InfoIcon from 'assets/icons/icon_info.svg';
import {
  ChoiceDropdown,
  useChoiceDropdown,
} from '../../PipelineMetricsDashboard/components/ChoiceDropdown';
import { BarChartComponent } from './BarChart';
import { LineChartComponent } from './LineChart';
import { PieChartComponent } from './PieChart';
import stateDashboardMetricsAPI from '../../../api/stateDashboardMetricsAPI';
import { CHART_COLOR_PALETTES } from '../../PipelineMetricsDashboard/constants';
import {
  defaultFilters,
  groupedSegments,
  getFilterOptions,
  getArrayOfsubItems,
  getLabelText,
} from '../utils';

import { DashboardBaseChartProps, DashboardDataSetItem } from '../types';

import {
  stateDashboardSegmentMap,
  stateDashboardChartTypes,
  stateDashboardSegmentTypes,
} from '../../../utils/enums';
const colors = CHART_COLOR_PALETTES[8];

export const DashboardBaseChart = ({
  stateCode,
  title,
  chartType,
  groupByOptions,
  hiringSeasonsSelected,
  districtsSelected,
  jobSubcategoriesSelected = [],
  graphId,
  useVacancyAsTotal = false,
  vacancyFillRate = false,
  alwaysShowStatus = false,
  titleTooltip = false,
  titleTooltipText = '',
  XAxisLabel = undefined,
  showFilter = false,
  showDropdown = true,
  defaultGroupBySelected = stateDashboardSegmentTypes.none.value,
  showStatusToggle = false,
  excludePostingOnly = false,
  searchBarPlaceholders = {},
}: DashboardBaseChartProps): React.ReactElement => {
  // TODO for dashboard base chart will be to remove graph-specific flags, like vacancyFillRate
  // Instead, each chart should be have with a unique chart_id enum that can be passed to BE to get correct data.

  const [apiError, setApiError] = useState('');
  const [dataset, setDataset] = useState<DashboardDataSetItem[]>([]);
  const [filtersSelected, setFiltersSelected] = useState<any>(defaultFilters);
  const [groupBySelected, setGroupBySelected] = useState(defaultGroupBySelected);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [statusToggleState, setStatusToggleState] = useState({
    modeOneActive: true,
    modeTwoActive: false,
  });
  const [groupedToggleState, setGroupedToggleState] = useState({
    modeOneActive: false,
    modeTwoActive: true,
  });

  const optionDropdownState = useChoiceDropdown({
    choices: filterOptions,
    initialSelectedChoiceIds: [],
  });

  const showGroupedToggle = groupedSegments.includes(groupBySelected);
  const isGrouped = groupedToggleState.modeOneActive && showGroupedToggle;

  const handleStatusToggleChange = () => {
    setStatusToggleState({
      modeOneActive: !statusToggleState.modeOneActive,
      modeTwoActive: !statusToggleState.modeTwoActive,
    });
    setFiltersSelected(f => ({
      ...f,
      status: !statusToggleState.modeTwoActive,
    }));
  };

  const clearDropdown = () => optionDropdownState.onClearAllChoices();

  const handleGroupedToggleChange = () => {
    setGroupedToggleState({
      modeOneActive: !groupedToggleState.modeOneActive,
      modeTwoActive: !groupedToggleState.modeTwoActive,
    });
  };

  const sortLegend = dataset => {
    const sortedDataset = dataset.sort((a, b) => a.name.localeCompare(b.name));
    const noneNames = ['None', 'Other', 'Unspecified', 'Unknown'];
    sortedDataset.sort(
      (a, b) => (noneNames.includes(a.name) ? 0 : 1) - (noneNames.includes(b.name) ? 1 : 0)
    );
    return sortedDataset;
  };

  const fetchChartData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await stateDashboardMetricsAPI.getChartData(
        groupBySelected,
        chartType,
        filtersSelected,
        isGrouped,
        stateCode,
        excludePostingOnly,
        graphId
      );
      useVacancyAsTotal ? setTotal(response.vacancy_total) : setTotal(response.total);

      const sortedDataset = response.results;
      if (alwaysShowStatus) {
        sortedDataset.sort((a, b) => b.value / b.segment_total - a.value / a.segment_total);
      } else if (vacancyFillRate) {
        sortedDataset.sort((a, b) => b.vacancy_fill_rate - a.vacancy_fill_rate);
      } else {
        const sortField = useVacancyAsTotal ? 'vacancy_count' : 'value';
        sortedDataset.sort((a, b) => a[sortField] - b[sortField]);
      }

      sortedDataset.map((item, index) => (item['color'] = colors[index % colors.length]));
      setDataset(sortedDataset);
      setIsLoading(false);
      setApiError('');
    } catch (error) {
      if (axios.isCancel(error)) return console.log('Request cancelled');
      setIsLoading(false);
      if (error instanceof Error) {
        setApiError(error.message);
      }
    }
  }, [
    groupBySelected,
    chartType,
    filtersSelected,
    isGrouped,
    stateCode,
    useVacancyAsTotal,
    alwaysShowStatus,
    vacancyFillRate,
    excludePostingOnly,
    graphId,
  ]);

  useEffect(() => {
    setFiltersSelected(f => ({
      ...f,
      hiring_seasons: hiringSeasonsSelected,
      districts: districtsSelected,
      subcategory: jobSubcategoriesSelected,
      status: alwaysShowStatus ? true : f.status,
    }));
  }, [hiringSeasonsSelected, districtsSelected, jobSubcategoriesSelected, alwaysShowStatus]);

  useEffect(() => {
    getFilterOptions(groupBySelected, stateCode).then(r => setFilterOptions(r));

    // segment type can be grade, subcategory, EPP, etc.
    const segmentKey = stateDashboardSegmentMap[groupBySelected].key;
    const filters = isGrouped
      ? getArrayOfsubItems(optionDropdownState.selectedChoiceIds)
      : optionDropdownState.selectedChoiceIds;

    setFiltersSelected(f => ({
      ...defaultFilters,
      status: f.status,
      hiring_seasons: hiringSeasonsSelected,
      districts: districtsSelected,
      [segmentKey]: filters,
    }));
  }, [
    optionDropdownState.selectedChoiceIds,
    hiringSeasonsSelected,
    districtsSelected,
    groupBySelected,
    isGrouped,
    stateCode,
    chartType,
  ]);

  // update chart data when filters are selected
  useEffect(() => {
    if (groupBySelected !== stateDashboardSegmentTypes.none.value) fetchChartData();
    // TODO: added disable next line to make warnings happy, but this should be revisited.
    // eslint-disable-next-line
  }, [filtersSelected]);

  const notEnoughData = total <= 1;
  const message = { isActive: false, title: '', subtitle: '' };
  if (notEnoughData) {
    message.isActive = true;
    message.title = 'Not enough data';
    message.subtitle = 'Check your filters and try again.';
  }
  if (groupBySelected === stateDashboardSegmentTypes.none.value) {
    message.isActive = true;
    message.title = 'No option selected.';
    message.subtitle = 'Please select attributes from the dropdown.';
  }
  if (apiError) {
    message.isActive = true;
    message.title = 'There was an error processing your request:';
    message.subtitle = apiError;
  }

  return (
    <DashboardWidget.Container>
      <DashboardWidget.HeaderContainer>
        <DashboardWidget.Heading>
          {title}
          {titleTooltip && (
            <>
              <StyledInfoIcon
                src={InfoIcon}
                alt="title-tooltip"
                data-tip={titleTooltipText}
                data-for="dashboard-applications-info"
              />
              <ReactTooltip id="title-toolip" effect="solid" multiline />
            </>
          )}
        </DashboardWidget.Heading>
        <ChoiceDropdownContainer>
          {showDropdown && (
            <Dropdown.Dropdown
              label={stateDashboardSegmentMap[groupBySelected].label}
              isActive={false}
              inputProps={{ height: '56px' }}
              labelProps={{ color: '#444' }}
            >
              {groupByOptions.map(item => {
                return (
                  <Dropdown.ListItem
                    key={item.value}
                    onClick={() => {
                      setGroupBySelected(item.value);
                      clearDropdown();
                    }}
                  >
                    <div style={{ padding: '12px 16px' }}>{item.label}</div>
                  </Dropdown.ListItem>
                );
              })}
            </Dropdown.Dropdown>
          )}
          {showFilter && groupBySelected !== stateDashboardSegmentTypes.none.value && (
            <ChoiceDropdown
              label={getLabelText(filtersSelected[stateDashboardSegmentMap[groupBySelected].key])}
              choices={filterOptions}
              {...optionDropdownState}
              height="56px"
              width="100%"
              searchBarPlaceholder={
                groupBySelected && groupBySelected in searchBarPlaceholders
                  ? searchBarPlaceholders[groupBySelected]
                  : ''
              }
            />
          )}
        </ChoiceDropdownContainer>
      </DashboardWidget.HeaderContainer>
      <DashboardWidget.Body>
        <DashboardWidget.BodyActions>
          {showStatusToggle && groupBySelected !== stateDashboardSegmentTypes.none.value ? (
            <Toggle.Wrapper>
              <Toggle.ToggleItem
                isActive={statusToggleState.modeOneActive}
                onClick={handleStatusToggleChange}
              >
                Applied
              </Toggle.ToggleItem>
              <Toggle.ToggleItem
                isActive={statusToggleState.modeTwoActive}
                onClick={handleStatusToggleChange}
              >
                Hired
              </Toggle.ToggleItem>
            </Toggle.Wrapper>
          ) : showGroupedToggle ? (
            <Toggle.Wrapper>
              <Toggle.ToggleItem
                isActive={groupedToggleState.modeOneActive}
                onClick={handleGroupedToggleChange}
              >
                Group
              </Toggle.ToggleItem>
              <Toggle.ToggleItem
                isActive={groupedToggleState.modeTwoActive}
                onClick={handleGroupedToggleChange}
              >
                Ungroup
              </Toggle.ToggleItem>
            </Toggle.Wrapper>
          ) : (
            <div style={{ height: '28px' }}></div>
          )}
        </DashboardWidget.BodyActions>
        <DashboardWidget.BodyContent isLoading={isLoading}>
          {message.isActive ? (
            <MessageContainer>
              <MessageTitle>{message.title}</MessageTitle>
              <MessageSubtitle>{message.subtitle}</MessageSubtitle>
            </MessageContainer>
          ) : (
            <ChartContainer>
              {chartType === stateDashboardChartTypes.bar && (
                <BarChartComponent
                  dataset={dataset}
                  XAxisLabel={XAxisLabel}
                  total={total}
                  vacancyFillRate={vacancyFillRate}
                  alwaysShowStatus={alwaysShowStatus}
                />
              )}
              {chartType === stateDashboardChartTypes.pie && (
                <PieChartComponent
                  dataset={dataset}
                  total={total}
                  useVacancyAsTotal={useVacancyAsTotal}
                />
              )}
              {chartType === stateDashboardChartTypes.line && (
                <LineChartComponent dataset={dataset} />
              )}
              <ScrollableWrapper>
                <Legend
                  theme={{
                    isSingleColumned: dataset.length <= 3 || dataset.find(i => i.name.length > 10),
                  }}
                >
                  {sortLegend([...dataset]).map(entry => (
                    <LegendLineItem key={entry.name}>
                      <LegendCircle color={entry.color} />
                      <LegendPercent>
                        {Math.ceil(
                          (vacancyFillRate
                            ? entry.vacancy_fill_rate
                            : useVacancyAsTotal
                            ? entry.vacancy_count / total
                            : alwaysShowStatus
                            ? entry.value / entry.segment_total
                            : entry.value / total) * 1000
                        ) / 10}
                        %
                      </LegendPercent>
                      <LegendName>{entry.name}</LegendName>
                    </LegendLineItem>
                  ))}
                </Legend>
              </ScrollableWrapper>
            </ChartContainer>
          )}
        </DashboardWidget.BodyContent>
      </DashboardWidget.Body>
    </DashboardWidget.Container>
  );
};

const ScrollableWrapper = styled.div`
  max-height: 100px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

const ChoiceDropdownContainer = styled.div`
  padding-right: 40px;
  padding-left: 30px;
  padding-bottom: 18px;
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 10px;
`;

const Legend = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.theme.isSingleColumned ? '100%' : '50% 50%')};
  grid-column-gap: 1rem;
  padding-top: 5px;
  margin-left: ${props => (props.theme.isSingleColumned ? '25%' : '0.5em')};
  align-items: center;
  color: #777;
  font-size: 16px;
`;

const LegendCircle = styled.div`
  background: ${props => props.color};
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-top: 4px;
  margin-right: 4px;
`;

const LegendPercent = styled.div`
  text-align: right;
  font-weight: 600;
  font-size: 15px;
  color: #444;
  margin-right: 5px;
`;

const LegendName = styled.div`
  color: #777777;
  font-weight: 400;
  font-size: 15px;
`;
const LegendLineItem = styled.div`
  display: flex;
  padding-bottom: 10px;
  margin-left: 20px;
`;

const MessageContainer = styled.div`
  flex: 1;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MessageTitle = styled.div`
  font-weight: 600px;
  font-size: 15px;
  color: #444444;
`;

const MessageSubtitle = styled.div`
  font-weight: 400px;
  font-size: 15px;
  color: #777777;
`;

const StyledInfoIcon = styled.img`
  width: 15px;
  margin-left: 5px;
  margin-bottom: 4px;
`;
