export default function TextQuestion({ questionText, setQuestionText }) {
  return (
    <>
      <h4 className="bold">Type text question here:</h4>
      <input
        className="form-field full-width mb3 mt16px box-style"
        type="text"
        placeholder="e.g. Describe the candidate's pedagogical approach"
        value={questionText}
        onChange={e => setQuestionText(e.target.value)}
      />
    </>
  );
}
