import styled from 'styled-components';

function GreenToggleTag({ toggleIsOn, toggleFunction, label, subtext, disabled = false }) {
  return (
    <>
      <OuterContainer>
        <FlexColumn shrink>
          <LabelText>{label}</LabelText>
        </FlexColumn>
        <FlexColumn>
          <SwitchContainer
            // spec: super admin should be "greyed" and always selected
            disabled={disabled}
            on={toggleIsOn ? 'true' : undefined}
            name={label}
            onClick={() =>
              // NOTE: we need to manually pass custom event object due to styled-component
              toggleFunction({
                target: {
                  name: label,
                  value: disabled ? toggleIsOn : !toggleIsOn,
                },
              })
            }
          >
            <SwitchCircle disabled={disabled} on={toggleIsOn ? 'true' : undefined} name={label} />
          </SwitchContainer>
        </FlexColumn>
      </OuterContainer>
    </>
  );
}

export default GreenToggleTag;

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0;
  vertical-align: middle;

  & > p {
    color: #444444;
  }
`;

const SwitchContainer = styled.div`
  width: 50px;
  min-width: 50px;
  height: 30px;
  border-radius: 15px;
  border: 2px solid;
  margin-left: 20px;
  background-color: ${props => props.disabled && '#EEEFF2'};
  border-color: ${props => (props.on ? '#00B88D' : '#dbdbdb')};
  position: relative;
  cursor: pointer;
`;

const SwitchCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  background-color: ${props => (props.on ? '#00B88D' : '#dbdbdb')};
  left: ${props => (props.on ? '23px' : '3px')};
  top: 3px;
  transition: left 300ms;
`;

const LabelText = styled.p`
  font-size: 15px;
  color: #444444;
`;

const FlexColumn = styled.div`
  flex: ${props => (props.shrink ? '0 1 27%' : '1')};
`;
