import { useState } from 'react';
import styled from 'styled-components';

import { DatePicker, GreenToggle, AltModal, MultiSelect, StyledCreatableSelect } from 'ui-kit';

import ModalFooterNew from 'components/common/Modal/ModalFooterNew';
import LoadingSpinner from 'components/loadingSpinner';
import { isFeatureEnabled } from '../../hoc/Feature';

export default function HelloSignModalBody({
  selectedPackets,
  setSelectedPackets,
  selectedTemplates,
  setSelectedTemplates,
  emailAddressesToCC,
  setEmailAddressesToCC,
  packetOptions,
  templateOptions,
  dueDate,
  setDueDate,
  notify,
  setNotify,
  errorMessage,
  adminUsers,
  isLoading,
  onHide,
  continueToForms,
  bulkSend,
}) {
  const [datePickerFocused, setDatePickerFocused] = useState(false);

  return (
    <>
      <ModalBody>
        {isLoading ? (
          <>
            <div>
              Please do not refresh the window while your forms are loading. This may take a few
              minutes.
            </div>
            <LoadingSpinner />
          </>
        ) : (
          <>
            <RelativeSection>
              <HeaderText>Select packet(s) to send</HeaderText>
              <MultiSelect
                value={selectedPackets}
                options={packetOptions}
                getOptionValue={p => p.id}
                getOptionLabel={p => p.title}
                onChange={setSelectedPackets}
                placeholder="Start typing or use drop down to select one or more packets"
              />
            </RelativeSection>
            <RelativeSection>
              <HeaderText>Select form(s) to send</HeaderText>
              <MultiSelect
                value={selectedTemplates}
                options={templateOptions}
                getOptionValue={t => t.id}
                getOptionLabel={t => t.title}
                onChange={setSelectedTemplates}
                placeholder="Start typing or use drop down to select one or more forms"
              />
              {errorMessage && <RedText>{errorMessage}</RedText>}
            </RelativeSection>
            <SmallSection>
              <HeaderText>Select due date</HeaderText>
              <DatePicker
                date={dueDate}
                onDateChange={date => setDueDate(date)}
                focused={datePickerFocused}
                onFocusChange={({ focused }) => setDatePickerFocused(focused)}
                id="signature-due-date-picker"
              />
            </SmallSection>
            <Section>
              <HeaderText>CC someone</HeaderText>
              <StyledCreatableSelect
                isMulti
                value={emailAddressesToCC}
                options={adminUsers.map(u => ({ value: u.email, label: u.name }))}
                onChange={values => setEmailAddressesToCC(values ?? [])}
                placeholder="Type name or email address to notify recipients and copy them on completed forms"
                formatCreateLabel={userInput => `Add "${userInput}"`}
                // user can add option only if it looks at least somewhat like an email address
                isValidNewOption={inputValue => /^\S+@\S+$/.test(inputValue)}
                closeMenuOnSelect={false}
              />
            </Section>
            <GreenToggle
              toggleIsOn={notify}
              toggleFunction={() => setNotify(prevValue => !prevValue)}
              label="Notify candidate of outstanding form(s)"
            />
          </>
        )}
      </ModalBody>
      {!isLoading &&
        (isFeatureEnabled('bulk_forms') ? (
          <AltModal.Actions>
            <ModalFooterNew
              cancel={onHide}
              altSave={continueToForms}
              altSaveLabel="Or edit forms before sending"
              save={bulkSend}
              saveButtonLabel="Send Forms"
            />
          </AltModal.Actions>
        ) : (
          <AltModal.Actions>
            <ModalFooterNew
              cancel={onHide}
              save={continueToForms}
              saveButtonLabel="Continue to forms"
            />
          </AltModal.Actions>
        ))}
    </>
  );
}

const ModalBody = styled(AltModal.Body)`
  font-size: 16px;
`;

const HeaderText = styled.p`
  font-weight: 400;
  font-size: 19px;
  line-height: 22px;
  color: #444444;

  margin-bottom: 8px;
`;

const Section = styled.div`
  margin-bottom: 2.5rem;
`;

const RelativeSection = styled(Section)`
  position: relative;
`;

const SmallSection = styled(Section)`
  width: 275px;
`;

const RedText = styled.p`
  position: absolute;
  bottom: -24px;
  left: 0;

  color: #ef5675;
  font-weight: 400;
  font-size: 14px;
`;
