import { Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { default as Grid, default as GridItem } from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ExpressInterestAPI from 'features/MarketplaceV2/api/expressInterestAPI';
import GeolocationAPI from 'features/MarketplaceV2/api/geolocationAPI';
import { useMarketplaceFilterOptions } from 'hooks/data/useMarketplaceV2FilterOptions';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from 'sharedComponents/Alert';
import { BasicSelect, MultiSelectWithSearch } from 'sharedComponents/Select';
import { Option } from 'sharedComponents/Select/types';
import { HorizontalLinearStepper } from 'sharedComponents/Stepper/HorizontalStepper';
import { nimbleTheme } from 'theme';
import { PreferencesFormData } from 'types/marketplaceV2Types';
import auth from 'utils/auth';
import { ConnectProfileFlowButtonGroup } from './SharedComponents/ConnectProfileFlowButtonGroup';
import { getGatedActionDisplayInfo, updateConnectFirstProfileSetupDateTime } from './utils';

export function PreferencesForm(): React.ReactElement {
  const user = auth.getUser();

  const {
    isLoadingFilterOptions,
    gradeOptions,
    subjectOptions,
    distanceOptions,
  } = useMarketplaceFilterOptions();

  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('sm') || theme.breakpoints.down('xs')
  );

  const getInitialSubjects = () => {
    if (user.preference.categories) {
      return subjectOptions.filter(subject => user.preference.categories.includes(subject.value));
    }
    return [];
  };

  const getInitialGrades = () => {
    if (user.preference.grades) {
      return gradeOptions.filter(grade => user.preference.grades.includes(Number(grade.value)));
    }
    return [];
  };

  const isUserFormDataEmpty = () => {
    const { userLocation, subjects, grades } = userFormData;
    return !userLocation && !subjects.length && !grades.length;
  };

  const [userFormData, setUserFormData] = useState<PreferencesFormData>({
    distanceFromSchool: user.preference.miles_within || 'Any',
    userLocation: user.preference.location,
    subjects: getInitialSubjects(),
    grades: getInitialGrades(),
  });
  const [formIsSubmitting, setFormIsSubmitting] = useState<boolean>(false);
  const [canUserSkipForm, setCanUserSkipForm] = useState<boolean>(isUserFormDataEmpty());
  const [error, setError] = useState({
    distance: null,
    subject: null,
    grade: null,
  });
  const history = useHistory();
  const { flowDisplayInformation, pageDisplayInformation } = getGatedActionDisplayInfo();

  const getPrimaryButtonText = () => {
    if (isMobile) {
      return pageDisplayInformation.primaryButtonLabelMobile;
    } else if (canUserSkipForm) {
      return 'Skip & Finish';
    }
    return pageDisplayInformation.primaryButtonLabel;
  };

  const resetErrorState = () =>
    setError({
      distance: null,
      subject: null,
      grade: null,
    });

  const handleUserLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (error) resetErrorState();

    setUserFormData({
      ...userFormData,
      userLocation: event.target.value,
    });
  };

  const handleDistanceChange = (selectedOption: string) =>
    setUserFormData({
      ...userFormData,
      distanceFromSchool: selectedOption,
    });

  const handleGradeChange = (selectedOptions: Option[]) => {
    setError({ ...error, grade: null });
    setUserFormData({
      ...userFormData,
      grades: selectedOptions,
    });
  };
  const handleSubjectChange = (selectedOptions: Option[]) => {
    setError({ ...error, subject: null });
    setUserFormData({
      ...userFormData,
      subjects: selectedOptions,
    });
  };

  const validateForm = async () => {
    const { distanceFromSchool, userLocation, grades, subjects } = userFormData;
    let hasError = false;
    const errorState = {
      distance: null,
      subject: null,
      grade: null,
    };

    if (userLocation) {
      const response = await GeolocationAPI.getMapUrl({
        address: userLocation,
        radius: distanceFromSchool,
      });

      if (response[0] !== 200) {
        errorState.distance = 'Please provide a valid address or zip code.';
        hasError = true;
      }
    } else if (pageDisplayInformation.requiredFields.includes('distance')) {
      errorState.distance = 'Please enter a location.';
      hasError = true;
    }

    if (grades.length === 0 && pageDisplayInformation.requiredFields.includes('grade')) {
      errorState.grade = true;
      hasError = true;
    }

    if (subjects.length === 0 && pageDisplayInformation.requiredFields.includes('subject')) {
      errorState.subject = true;
      hasError = true;
    }

    if (hasError) {
      setError(errorState);
      return false;
    }
    resetErrorState();
    return true;
  };

  const handleSaveAndContinue = async () => {
    if ((await validateForm()) === false) return;

    const { distanceFromSchool, userLocation, grades, subjects } = userFormData;

    const userPreferences = {
      subjects: subjects.map(subject => Number(subject.value)),
      // the api gets mad when we send an empty array for grades 🤷🏾‍♂️
      grades: grades.length ? grades.map(grade => Number(grade.value)) : null,
      distanceFromSchool,
      userLocation,
    };

    try {
      setFormIsSubmitting(true);
      await ExpressInterestAPI.updateUserPreferences(user.id, userPreferences);
      const updatedUser = await auth.getUserAsync();
      auth.setUser(updatedUser);
      history.push(pageDisplayInformation.continueToUrl);
    } catch (error) {
      console.error('Error updating user profile', error);
    } finally {
      setFormIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (isLoadingFilterOptions) return;

    const initialSubjects = getInitialSubjects();
    const initialGrades = getInitialGrades();

    setUserFormData(currentFormData => ({
      ...currentFormData,
      subjects: initialSubjects,
      grades: initialGrades,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingFilterOptions]);

  useEffect(() => {
    if (isLoadingFilterOptions) return;
    if (pageDisplayInformation.requiredFields.includes('distance')) {
      setCanUserSkipForm(false);
    } else {
      setCanUserSkipForm(isUserFormDataEmpty);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFormData]);

  useEffect(() => {
    updateConnectFirstProfileSetupDateTime(user);
  }, [user]);

  return (
    <Box
      sx={{
        maxWidth: 620,
        margin: {
          xs: '15px',
          sm: '22px auto',
        },
      }}
    >
      <HorizontalLinearStepper
        activeStep={pageDisplayInformation.step}
        steps={flowDisplayInformation.stepper.steps}
        mobileSteps={flowDisplayInformation.stepper.mobileSteps}
      />
      <Paper elevation={4} sx={{ borderRadius: '12px' }}>
        <Box
          component="form"
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            paddingTop: '20px',
            marginTop: '24px',
            borderRadius: '12px',
          }}
        >
          <Typography
            variant="h1"
            color="#101828"
            fontSize={28}
            fontWeight={400}
            fontFamily={nimbleTheme.typography.fontFamily['Aeonik TRIAL']}
            textAlign="center"
          >
            {pageDisplayInformation.headerText}
          </Typography>
          {isLoadingFilterOptions ? (
            <CircularProgress />
          ) : (
            <Stack
              spacing={{
                xs: 2,
                sm: 4,
              }}
              px={{ xs: 2, sm: 8 }}
              paddingBottom={{ xs: 2, sm: 6 }}
            >
              <Grid container rowSpacing={{ xs: 3 }}>
                <GridItem item>
                  <Alert>
                    We&apos;ll use this information to show you the best matches for schools in your
                    area.
                  </Alert>
                </GridItem>
                <GridItem item xs={12} sm={6}>
                  <QuestionLabel
                    required={pageDisplayInformation.requiredFields.includes('distance')}
                    htmlFor="user-location"
                  >
                    Where do you want to teach?
                  </QuestionLabel>
                  <TextField
                    id="user-location"
                    name="userLocation"
                    hiddenLabel
                    placeholder="Enter a city, state, or zip"
                    fullWidth
                    defaultValue={userFormData.userLocation}
                    onChange={handleUserLocationChange}
                    error={error?.distance}
                    helperText={error?.distance}
                  />
                </GridItem>
                <GridItem item xs={12} sm={6} pl={{ sm: 1 }}>
                  <QuestionLabel
                    required={pageDisplayInformation.requiredFields.includes('distance')}
                    htmlFor="distance-from-school"
                  >
                    Preferred distance from school?
                  </QuestionLabel>
                  <BasicSelect
                    id="distance-from-school"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    handleChange={handleDistanceChange}
                    options={distanceOptions}
                    size="medium"
                    values={[userFormData.distanceFromSchool]}
                    defaultValue={userFormData.distanceFromSchool}
                  />
                </GridItem>
                <GridItem item xs={12}>
                  <QuestionLabel
                    required={pageDisplayInformation.requiredFields.includes('subject')}
                  >
                    What subject(s) do you want to teach?
                  </QuestionLabel>
                  <MultiSelectWithSearch
                    hiddenLabel
                    handleChange={handleSubjectChange}
                    displayName=""
                    placeholder="Enter your preferred subject(s) to teach"
                    options={subjectOptions}
                    values={userFormData.subjects}
                  />
                </GridItem>
                <GridItem item xs={12}>
                  <QuestionLabel required={pageDisplayInformation.requiredFields.includes('grade')}>
                    Preferred grade level(s)
                  </QuestionLabel>
                  <MultiSelectWithSearch
                    hiddenLabel
                    handleChange={handleGradeChange}
                    displayName=""
                    placeholder="Enter your preferred grade level(s) to teach"
                    options={gradeOptions}
                    values={userFormData.grades}
                  />
                </GridItem>
              </Grid>
            </Stack>
          )}
          <Box p={{ xs: 1, sm: 2 }} sx={{ width: '100%' }}>
            {(error.grade || error.subject) && (
              <Typography color="error" textAlign="center" gutterBottom>
                Please fill out at least one grade and subject preference to continue.
              </Typography>
            )}
          </Box>
        </Box>
      </Paper>
      <ConnectProfileFlowButtonGroup
        primaryButton={{
          primaryButtonLabel: getPrimaryButtonText(),
          primaryAction: handleSaveAndContinue,
          disabled: error.distance || error.grade || error.subject,
          isLoading: formIsSubmitting,
        }}
        secondaryButton={{
          secondaryButtonLabel: 'Back',
          secondaryAction: () => history.push(pageDisplayInformation.backUrl),
        }}
        hasError={false}
      />
    </Box>
  );
}

const QuestionLabel = styled(InputLabel)(() => ({
  textWrap: 'wrap',
}));
