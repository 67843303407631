import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import SearchAPI from 'api/searchAPI';
import UsersAPI from 'api/usersAPI';
import { StyledAsyncSelect, Button } from 'ui-kit';

import IconChainLink from 'assets/icons/icon-chain-link.svg';

export default function LinkProfileSection({ user }) {
  const [selectBuffer, setSelectBuffer] = useState([]);
  const [linkedProfiles, setLinkedProfiles] = useState([]);
  // Seems redundant, but this flags saves us from saving a wrong state
  // while the component is mounting and pulling state from props.
  const [shouldSave, setShouldSave] = useState(false);

  useEffect(() => {
    setLinkedProfiles(user.linked_users || []);
  }, [user]);

  const searchDistrictUsers = inputValue => {
    // This will allow for the initial request to populate the list
    if (inputValue && inputValue.length < 2) {
      return [];
    }
    return SearchAPI.searchDistrictUsers({ search: inputValue }).then(data => {
      // Remove already linked users:
      const linked_user_ids = linkedProfiles.map(u => u.id);
      const results = data.results.reverse().filter(u => !linked_user_ids.includes(u.id));

      return results.map(u => {
        return {
          label: (
            <StyledOption>
              <span>{u.name}</span>
              <span>{u.email}</span>
              <span>{u.user_type}</span>
            </StyledOption>
          ),
          value: u.id,
          ...u,
        };
      });
    });
  };

  const onSelectChange = (inputValue, eventType) => {
    if (eventType.action === 'select-option' || eventType.action === 'remove-value') {
      if (!inputValue) {
        setSelectBuffer([]);
      } else {
        setSelectBuffer([...inputValue]);
      }
    }
  };

  const saveLinkedProfiles = useCallback(() => {
    if (shouldSave) {
      UsersAPI.updateUser(user.id, { linked_users: linkedProfiles.map(u => u.id) });
      setShouldSave(false);
    }
  }, [shouldSave, user, linkedProfiles]);

  const onLinkClick = () => {
    const newLinkedProfiles = [...selectBuffer, ...linkedProfiles];
    setLinkedProfiles(newLinkedProfiles);
    setSelectBuffer([]);
    setShouldSave(true);
  };

  const onUnlinkClick = id => {
    setLinkedProfiles(linkedProfiles.filter(u => u.id !== id));
    setShouldSave(true);
  };

  useEffect(() => {
    saveLinkedProfiles();
  }, [saveLinkedProfiles]);

  return (
    <>
      <SectionHeader>Privacy</SectionHeader>
      <SectionSubHeader>
        Link admin account(s){' '}
        <InformationIcon
          data-for="link-profile-section"
          data-tip="Prevents admin from seeing their own candidate profile details, including notes and scorecards"
        />
      </SectionSubHeader>

      <SelectContainer>
        <StyledAsyncSelect
          isMulti
          value={selectBuffer}
          defaultOptions
          loadOptions={searchDistrictUsers}
          placeholder="Start typing admin name to prevent them from seeing this profile"
          onChange={onSelectChange}
        />
        <Button variant="primary" onClick={onLinkClick}>
          Link accounts
        </Button>
      </SelectContainer>

      {!!linkedProfiles.length && (
        <LinkedAccounts>
          <p>Linked accounts</p>

          {linkedProfiles.map(profile => (
            <LinkedAccount key={profile.id}>
              <div>
                <p>{profile.name}</p>
                <p>{profile.email}</p>
              </div>

              <img alt="chain-link-icon" src={IconChainLink} />
              <button onClick={() => onUnlinkClick(profile.id)}>Unlink</button>
            </LinkedAccount>
          ))}
        </LinkedAccounts>
      )}

      <ReactTooltip id="link-profile-section" />
    </>
  );
}

const SectionHeader = styled.h3`
  color: #444;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 26px;
  margin-bottom: 15px;
`;

const SectionSubHeader = styled.h4`
  font-width: 600;
  font-size: 16px;
  line-height: 22px;
  margin: 15px 0;
`;

const InformationIcon = styled.div`
  font-size: 16px;

  position: relative;
  top: 4px;

  display: inline-block;
  height: 18px;
  width: 18px;

  border-radius: 50%;
  border: 1px solid ${props => props.theme.uiColors.lightgray};

  &::after {
    display: block;
    height: 18px;
    width: 18px;

    position: absolute;
    left: 5px;
    top: -3px;

    content: '?';
    color: ${props => props.theme.uiColors.lightgray};
  }
`;

const SelectContainer = styled.div`
  display: grid;
  grid-template-columns: 80% 1fr;
  column-gap: 20px;
`;

const StyledOption = styled.div`
  display: grid;
  grid-template-columns: 30% 40% 1fr;

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const LinkedAccount = styled.div`
  border: 1px solid ${props => props.theme.uiColors.notquitewhite};
  border-radius: 3px;
  padding: 1rem;
  margin: 1rem 0;
  width: 50%;

  display: grid;
  grid-template-columns: 90% 10%;

  > div > p:first-child {
    font-weight: 600;
  }

  > img {
    padding: 8px;
  }

  > button {
    display: none;
    background: none;
    border: 0;
  }

  &:hover {
    > img {
      display: none;
    }

    > button {
      display: block;
      text-decoration: underline;
      padding: 10px;
      position: relative;
      right: 20px;
      cursor: pointer;
    }
  }
`;

export const LinkedAccounts = styled.div`
  padding: 1rem;
`;
