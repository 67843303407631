import { Component } from 'react';
import { AltModal } from 'ui-kit';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { flexbox, grid } from 'styled-system';

import dedent from 'dedent-js';
import auth from '../../../utils/auth';
import _ from 'lodash';
import moment from 'moment';

import { DropdownWithInputFilter } from 'ui-kit';
import SelectRecipients from './SelectRecipients';

import { ReactComponent as GrayArrow } from 'assets/icon_right_caret_grey.svg';
import { ReactComponent as WhiteCheckmark } from 'assets/icons/icon-white-checkmark.svg';
import { isValidEmail } from 'utils/emailutils';

export default class ScorecardNewRequest extends Component {
  static propTypes = {
    sendRequest: PropTypes.func.isRequired,
    showRequestSection: PropTypes.func.isRequired,
    customScorecards: PropTypes.arrayOf(PropTypes.object).isRequired,
    user: PropTypes.object.isRequired,
    emailTemplatesList: PropTypes.array,
    application: PropTypes.object.isRequired,
    removeCheckmark: PropTypes.func.isRequired,
    requestSuccessful: PropTypes.bool.isRequired,
    requestSendingSpinner: PropTypes.bool.isRequired,
    onBack: PropTypes.func.isRequired,
    adminUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  constructor(props) {
    super(props);
    this.sender = auth.getUser();
    this.candidate = props.user;
    this.application = props.application;
    this.state = {
      error: '',
      customScorecardSelected: {},
      customMessage: '',
      recipients: [this.newRecipient()],
    };
  }

  newRecipient = () => ({
    name: '',
    email: '',
    editable: true,
  });

  requestScorecard = () => {
    try {
      this.checkForErrors();
    } catch (error) {
      this.setState({ error: error.message });
      return;
    }

    this.setState({ error: '' });

    this.props.sendRequest({
      recipients: this.state.recipients,
      custom_scorecard_id: this.state.customScorecardSelected.id,
      custom_message: this.state.customMessage,
      is_reminder: false,
    });

    this.setState({ recipients: [this.newRecipient()], customMessage: '' });
  };

  checkForErrors = () => {
    if (!this.state.recipients.length) {
      throw new Error('Please select at least one recipient');
    }

    this.state.recipients.forEach(recipient => {
      if (!recipient?.email) {
        throw new Error('Please add a recipient for each line item');
      } else if (recipient.email && recipient.email.length && !isValidEmail(recipient.email)) {
        throw new Error('At least one email address provided is invalid');
      }
    });

    if (!this.state.customScorecardSelected?.id) {
      throw new Error('Please select a scorecard');
    }

    if (!this.state.customMessage) {
      throw new Error('Please enter a message');
    }
  };

  updateScorecardSelection = customScorecardId => {
    this.props.removeCheckmark();
    const customScorecard = this.props.customScorecards.find(cs => cs.id === customScorecardId);
    if (customScorecard) {
      this.setState({
        customScorecardSelected: customScorecard,
        error: '',
      });
    } else {
      this.setState({
        customScorecardSelected: {},
        error: '',
      });
    }
  };

  /**
   * Takes a template variable, as documented above, and returns the dynamic value.
   * @param input e.g. '{Sender first name}'
   * @return value e.g. 'Frank' or '()' if the lookup does not exist or '[]' if the given input
   * is not a valid substitution.
   */
  dynamicSub = input => {
    /**
     * mapping from human readable variable names to names that fit our data models.
     * Edit 4/23/19: change candidate fields to 'Candidate ...', but maintaining support
     * for old fields name, hence the duplicates.
     */
    const variableMapping = {
      'Candidate first name': 'candidate.first_name',
      'First name': 'candidate.first_name',
      'Candidate last name': 'candidate.last_name',
      'Last name': 'candidate.last_name',
      'Candidate phone': 'candidate.profile.phone_cell',
      'Phone number': 'candidate.profile.phone_cell',
      'Job title': 'application.role.title',
      'Sender first name': 'sender.first_name',
      'Sender last name': 'sender.last_name',
      'School name': 'sender.profile.school.name',
      'District name': 'sender.profile.district.name',
      'Interview date & time': 'application.interview.when',
    };

    if (!_.startsWith(input, '{') || !_.endsWith(input, '}')) {
      return '[]';
    }

    input = _.trim(input, '{}');
    input = variableMapping[input];
    let fields = _.split(input, '.'); // eg. ['candidate', 'first_name'] or ['sender', 'last_name']

    let obj = this[fields[0]]; // this.candidate, this.application, or this.sender
    fields = _.drop(fields, 1);
    for (let field of fields) {
      if (obj && field in obj) {
        // Special case where interview date is saved in the prior modal and passed down as props.
        // In this case, use props.interviewTime instead of application.interview.when.
        if (field === 'when' && this.props.interviewTime) {
          obj = this.props.interviewTime;
        } else {
          // In normal cases, move down nested object until you reach the desired field.
          obj = obj[field];
        }
      } else {
        return '';
      }
    }
    // if the variable evaluates to a date, make it human readable.
    if (obj instanceof Date) {
      obj = moment.utc(obj).format('dddd, MMMM Do YYYY, h:mm a');
    }
    // defensive
    if (!obj) {
      obj = '';
    }
    return obj;
  };

  /**
   * Calls dynamicSub on the parts of text that need updating
   * @param text e.g. Hello from {Sender district}"
   * @return text e.g. Hello from ABC District
   */
  processText = text => {
    text = dedent(text);
    text = text.replace(/\{.*?\}/g, str => this.dynamicSub(str));
    return text;
  };

  addRecipient = () => {
    const recipients = [...this.state.recipients];
    recipients.push(this.newRecipient());
    this.setState({
      recipients,
      error: '',
    });
  };

  updateRecipient = (value, index) => {
    const recipients = [...this.state.recipients];
    recipients[index].email = value;
    this.setState({
      recipients,
      error: '',
    });
  };

  clearRecipient = index => {
    const recipients = [...this.state.recipients];
    recipients[index] = this.newRecipient();
    this.setState({ recipients });
  };

  selectRecipient = (recipientId, index) => {
    const recipients = [...this.state.recipients];
    const recipient = this.props.adminUsers.find(user => user.id === recipientId);
    recipient.editable = false;
    recipients[index] = recipient;
    this.setState({
      recipients,
      error: '',
    });
  };

  removeRecipient = index => {
    const recipients = [...this.state.recipients];
    recipients.splice(index, 1);
    this.setState({ recipients });
  };

  setReadOnly = index => {
    const recipients = [...this.state.recipients];
    recipients[index].editable = false;
    this.setState({ recipients });
  };

  render() {
    return (
      <>
        <ModalBody>
          <BackButtonContainer onClick={this.props.onBack}>
            <BackButtonIcon />
            Return to scorecard summary
          </BackButtonContainer>
          <HeaderText>Select scorecard</HeaderText>
          <DropdownWithInputFilter
            options={this.props.customScorecards.map(cs => ({ value: cs.id, label: cs.title }))}
            value={this.state.customScorecardSelected?.id ?? ''}
            handleChange={this.updateScorecardSelection}
            placeholder="Type or select scorecard"
            boxShadow={false}
            onClear={this.updateScorecardSelection}
          />
          <SelectRecipients
            addRecipient={this.addRecipient}
            updateRecipient={this.updateRecipient}
            selectRecipient={this.selectRecipient}
            removeRecipient={this.removeRecipient}
            clearRecipient={this.clearRecipient}
            setReadOnly={this.setReadOnly}
            recipients={this.state.recipients}
            adminUsers={this.props.adminUsers}
          />
          <HeaderText>Add a note</HeaderText>
          <CustomMessageTextarea
            placeholder="Tell the interviewers more about this feedback request"
            value={this.state.customMessage}
            onChange={e =>
              this.setState({
                customMessage: e.target.value,
                error: '',
              })
            }
          />
          <Subtext>
            {"Note: The candidate's attachments will be sent along with this scorecard request."}
          </Subtext>
        </ModalBody>
        <FooterContainer gridTemplateColumns={['1fr', '1fr', '126px 464px']}>
          <CancelButton onClick={this.props.onBack}>Cancel</CancelButton>
          <SubmitContainer gridTemplateColumns={['1fr', '1fr', '270px 186px']}>
            <ErrorText justifySelf={['start', 'start', 'end']}>{this.state.error}</ErrorText>
            <SaveButton
              onClick={this.requestScorecard}
              disabled={this.props.requestSendingSpinner || this.props.requestSuccessful}
            >
              {this.props.requestSendingSpinner || this.props.requestSuccessful ? (
                <>
                  <WhiteCheckmark width={'19px'} height={'14px'} />
                  &nbsp;&nbsp;Sent!
                </>
              ) : (
                'Send Request'
              )}
            </SaveButton>
          </SubmitContainer>
        </FooterContainer>
      </>
    );
  }
}

const ModalBody = styled(AltModal.Body)`
  position: relative;
`;

const BackButtonContainer = styled.div`
  color: var(--darkgray) !important;
  font-size: 16px !important;
  cursor: pointer;
  display: flex;
  align-items: center;

  width: fit-content;
  position: absolute;
  top: 20px;
`;

const BackButtonIcon = styled(GrayArrow)`
  transform: rotate(180deg);
  margin-right: 7px;
`;

const HeaderText = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #444444;

  margin-bottom: 14px;
  margin-top: 30px;
`;

const Subtext = styled.p`
  font-size: 14px;
  line-height: 19px;
  margin-top: 6px;

  color: #999999;
`;

const FooterContainer = styled(AltModal.Actions)(
  {
    display: 'grid',
    gridGap: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',

    fontSize: '16px',
  },
  grid
);

const FooterButton = styled.button({
  height: '50px',
  width: '100%',
  borderRadius: '3px',
});

const CancelButton = styled(FooterButton)({
  backgroundColor: '#FFFFFF',
  border: '1px solid #CACACA',
  color: '#CACACA',
});

const SaveButton = styled(FooterButton)({
  backgroundColor: '#00B88D',
  border: '1px solid #00B88D',
  color: '#FFFFFF',
  fontWeight: 'bold',
});

const CustomMessageTextarea = styled.textarea`
  height: 220px;
  width: 100%;
  background: #ffffff;
  border: 0.5px solid #d7d7d7;
  border-radius: 3px;
  padding: 20px;

  &::placeholder {
    color: rgba(57, 60, 73, 0.3);
  }
`;

const SubmitContainer = styled.div(
  {
    display: 'grid',
    gridGap: '8px',
    alignItems: 'center',
  },
  grid
);

const ErrorText = styled.p(
  {
    color: '#ff7700',
    fontSize: '0.85em',
  },
  flexbox
);
