import { useState, useEffect, useMemo } from 'react';
import shortid from 'shortid';

import { Modal } from 'ui-kit';

import Attachment from './components/Attachment';
import MultipleChoice from './components/MultipleChoice';
import NimbleQuestion from './components/NimbleQuestion';
import { SchoolPreferencesQuestion } from './components/SchoolPreferencesQuestion';
import StatementCheckbox from './components/StatementCheckbox';
import TextQuestion from './components/TextQuestion';
import VideoLink from './components/VideoLink';
import YesNoQuestion from './components/YesNoQuestion';
import DirectionText from './components/DirectionText';
import ModalFooterNew from 'components/common/Modal/ModalFooterNew';

import { questionType as type } from '../../utils/enums';

import schoolsAPI from 'api/schoolsAPI';
import { AddItemModalProps } from './types';
import customTagAPI from 'api/customTagAPI';
import auth from 'utils/auth';
import { isFeatureEnabled } from '../../hoc/Feature';

const AddItemModal: React.FC<AddItemModalProps> = ({
  isOpen,
  onClose,
  addNewItem,
  questionType,
  questionBank,
  internalRequirementsSpecified,
}) => {
  const [questionText, setQuestionText] = useState('');
  const [nimbleQuestionId, setNimbleQuestionId] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  // handles state for required & visibility checkboxes
  const [itemMatrix, setItemMatrix] = useState({
    isRequiredExternal: false,
    isRequiredInternal: false,
    isVisibleExternal: true,
    isVisibleInternal: true,
  });

  const [multipleChoiceOptions, setMultipleChoiceOptions] = useState(
    questionType === type.multiple_choice ? ['', ''] : null
  );
  const [multiSelect, setMultiSelect] = useState(
    questionType === type.multiple_choice ? false : null
  );
  const [attachmentDirections, setAttachmentDirections] = useState(
    questionType === type.attachment ? '' : null
  );
  const [description, setDescription] = useState('');

  const [isAutomaticList, setIsAutomaticList] = useState(true);
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [autoTags, setAutoTags] = useState(null);
  const [authorizedTags, setAuthorizedTags] = useState([]);

  useEffect(() => {
    schoolsAPI.fetchActiveSchools().then(schoolOptions => setSchoolOptions(schoolOptions));
  }, []);

  const onSave = () => {
    try {
      checkForErrors();
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
      return;
    }

    addNewItem({
      draggable_id: shortid.generate(),
      question_type: questionType,
      nimblequestion_id: nimbleQuestionId,
      prompt: questionText,
      title: questionText, // required attachments prompts are stored as title
      is_required_external: itemMatrix.isRequiredExternal,
      is_required_internal: itemMatrix.isRequiredInternal,
      is_visible_internal: itemMatrix.isVisibleInternal,
      is_visible_external: itemMatrix.isVisibleExternal,
      mc_options: multipleChoiceOptions,
      multi_select: multiSelect,
      attachment_directions: attachmentDirections,
      description: description,
      is_automatic_list: isAutomaticList,
      school_choices: selectedSchools,
      autotags: autoTags,
    });
  };

  const checkForErrors = () => {
    if (questionType === type.multiple_choice) {
      // iterate over multiple choice options and make sure none are blank.
      for (let i = 0; i < multipleChoiceOptions.length; i++) {
        if (multipleChoiceOptions[i].trim() === '') {
          throw new Error('Each answer must have a value');
        }
      }
    } else if (questionTextMissing) {
      throw new Error('Question prompt cannot be blank');
    } else if (questionType === type.videoLink && !questionText) {
      throw new Error('Video name cannot be blank');
    }
  };

  const questionTextMissing =
    questionType !== type.schoolPreferences &&
    questionType !== type.nimble &&
    questionType !== type.videoLink &&
    questionText.trim() === '';

  const handleMultipleChoiceTextChange = (e, index) => {
    const newOptions = [...multipleChoiceOptions];
    newOptions[index] = e.target.value;
    setMultipleChoiceOptions(newOptions);
  };

  const addAnswer = () => {
    // push in an empty answer
    const newOptions = [...multipleChoiceOptions];
    newOptions.push('');
    setMultipleChoiceOptions(newOptions);
  };

  const removeOption = index => {
    const newOptions = [...multipleChoiceOptions];
    newOptions.splice(index, 1);
    setMultipleChoiceOptions(newOptions);
  };

  const handleItemMatrixChange = e => {
    const { name, checked } = e.target;

    if (name === 'isRequiredInternal' && checked === true) {
      setItemMatrix({ ...itemMatrix, isRequiredInternal: true, isVisibleInternal: true });
    } else if (name === 'isRequiredExternal' && checked === true) {
      setItemMatrix({ ...itemMatrix, isRequiredExternal: true, isVisibleExternal: true });
    } else {
      // update specified item
      setItemMatrix({ ...itemMatrix, [name]: checked });
    }
  };

  const handleSetAutoTags = autoTags => {
    setAutoTags(autoTags);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const existingAutoTags = autoTags || [];

  useEffect(() => {
    customTagAPI.fetchCustomTags().then(tags => {
      setAuthorizedTags(tags);
    });
  }, []);

  const questionHasHiddenTags = useMemo(() => {
    if (auth.isSuperAdminUser()) return false;
    const questionTags = existingAutoTags?.map(option => option.tag_id);
    const authorizedTagIds = authorizedTags?.map(tag => tag.id);

    const authorizedTagIdsSet = new Set(authorizedTagIds);

    return !questionTags.every(questionTag => authorizedTagIdsSet.has(questionTag));
  }, [existingAutoTags, authorizedTags]);

  const hasTagFeatureFlag = isFeatureEnabled('streamlined_screening');

  const renderItemBody = () => {
    switch (questionType) {
      case type.direction_text:
        return (
          <DirectionText
            directions={questionText}
            setDirections={setQuestionText}
            itemMatrix={itemMatrix}
            handleItemMatrixChange={handleItemMatrixChange}
            internalRequirementsSpecified={internalRequirementsSpecified}
          />
        );
      case type.open_response:
        return (
          <TextQuestion
            questionText={questionText}
            setQuestionText={setQuestionText}
            itemMatrix={itemMatrix}
            handleItemMatrixChange={handleItemMatrixChange}
            internalRequirementsSpecified={internalRequirementsSpecified}
          />
        );
      case type.multiple_choice:
        return (
          <MultipleChoice
            questionText={questionText}
            setQuestionText={setQuestionText}
            addAnswer={addAnswer}
            mc_options={multipleChoiceOptions}
            multi_select={multiSelect}
            setMultiSelect={setMultiSelect}
            handleMultipleChoiceTextChange={handleMultipleChoiceTextChange}
            removeOption={removeOption}
            itemMatrix={itemMatrix}
            handleItemMatrixChange={handleItemMatrixChange}
            internalRequirementsSpecified={internalRequirementsSpecified}
          />
        );
      case type.nimble:
        return (
          <NimbleQuestion
            nimbleQuestionId={nimbleQuestionId}
            setNimbleQuestionId={setNimbleQuestionId}
            questionBank={questionBank}
            itemMatrix={itemMatrix}
            handleItemMatrixChange={handleItemMatrixChange}
            internalRequirementsSpecified={internalRequirementsSpecified}
          />
        );
      case type.attachment:
        return (
          <Attachment
            questionText={questionText}
            setQuestionText={setQuestionText}
            attachmentDirections={attachmentDirections}
            setAttachmentDirections={setAttachmentDirections}
            itemMatrix={itemMatrix}
            handleItemMatrixChange={handleItemMatrixChange}
            internalRequirementsSpecified={internalRequirementsSpecified}
          />
        );
      case type.schoolPreferences:
        return (
          <SchoolPreferencesQuestion
            isAutomaticList={isAutomaticList}
            selectedSchools={selectedSchools}
            schoolOptions={schoolOptions}
            onAutomaticListToggle={() => {
              setIsAutomaticList(prevIsAutomaticList => {
                const isAutomaticList = !prevIsAutomaticList;

                // We need to reset the automatically selected ids.
                if (isAutomaticList) {
                  setSelectedSchools([]);
                }

                return isAutomaticList;
              });
            }}
            onSelectedSchoolChange={selectedSchool => {
              setSelectedSchools(prevSelectedSchoolIds => {
                if (prevSelectedSchoolIds.includes(selectedSchool.id)) {
                  return prevSelectedSchoolIds.filter(s => s !== selectedSchool.id);
                } else {
                  return [...prevSelectedSchoolIds, selectedSchool.id];
                }
              });
              setIsAutomaticList(false);
            }}
            itemMatrix={itemMatrix}
            handleItemMatrixChange={handleItemMatrixChange}
            internalRequirementsSpecified={internalRequirementsSpecified}
          />
        );
      case type.statementCheckbox:
        return (
          <StatementCheckbox
            authorizedTags={authorizedTags}
            existingAutoTags={existingAutoTags}
            handleItemMatrixChange={handleItemMatrixChange}
            handleSetAutoTags={handleSetAutoTags}
            internalRequirementsSpecified={internalRequirementsSpecified}
            itemMatrix={itemMatrix}
            questionText={questionText}
            setQuestionText={setQuestionText}
            questionHasHiddenTags={questionHasHiddenTags}
            hasTagsAvailable={Boolean(authorizedTags.length)}
            shouldShowAutoTagLink={hasTagFeatureFlag}
          />
        );
      case type.videoLink:
        return (
          <VideoLink
            videoLinkName={questionText}
            setVideoLinkName={setQuestionText}
            description={description}
            setDescription={setDescription}
            itemMatrix={itemMatrix}
            handleItemMatrixChange={handleItemMatrixChange}
            internalRequirementsSpecified={internalRequirementsSpecified}
          />
        );
      case type.yes_no:
        return (
          <YesNoQuestion
            authorizedTags={authorizedTags}
            existingAutoTags={existingAutoTags}
            handleItemMatrixChange={handleItemMatrixChange}
            handleSetAutoTags={handleSetAutoTags}
            internalRequirementsSpecified={internalRequirementsSpecified}
            itemMatrix={itemMatrix}
            questionText={questionText}
            setQuestionText={setQuestionText}
            questionHasHiddenTags={questionHasHiddenTags}
            hasTagsAvailable={Boolean(authorizedTags.length)}
            shouldShowAutoTagLink={hasTagFeatureFlag}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal.Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Title>New Item</Modal.Title>
      {renderItemBody()}
      <Modal.Actions>
        <ModalFooterNew
          cancel={onClose}
          cancelButtonLabel="Cancel"
          deleteFunction={() => {}}
          deleteButtonLabel="Delete item"
          errorMessage={errorMessage}
          save={onSave}
          saveButtonLabel="Save item"
        />
      </Modal.Actions>
    </Modal.Modal>
  );
};

export default AddItemModal;
