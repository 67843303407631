import { Component } from 'react';

import PropTypes from 'prop-types';
import EditCategoryModal from './EditCategoryModal';
import DeleteModal from '../JobEdit/DeleteModal';
import auth from '../../utils/auth';
import { EditButton } from 'sharedComponents/Buttons/EditButton';
import { DeleteButton } from 'sharedComponents/Buttons/DeleteButton';

export default class SubcategoriesRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editModalOpen: false,
      deleteModalOpen: false,
    };
  }
  static propTypes = {
    subcategory: PropTypes.object.isRequired,
    subcategoryCount: PropTypes.number.isRequired,
    editCategory: PropTypes.func.isRequired,
    deleteCategory: PropTypes.func.isRequired,
  };

  deleteRow = e => {
    e.stopPropagation();
    if (this.props.subcategoryCount > 1) {
      const subcat = this.props.subcategory;
      this.props.deleteCategory(subcat.categoryId, subcat.id);
    } else {
      // Show the warning dialog.
      this.setState({ deleteModalOpen: true });
    }
  };

  closeDeleteModal = () => {
    this.setState({ deleteModalOpen: false });
  };

  render() {
    return (
      <div className="subcategory-row-container">
        <div className="bold">{this.props.subcategory.label}</div>
        {/* Remove edit/delete if district belongs to Indiana (IN) */}
        {!auth.getUser().profile.district.indiana_district && (
          <div>
            <EditButton
              data-testid="subcategory-edit-icon"
              onClick={() => this.setState({ editModalOpen: true })}
            />
            <DeleteButton onClick={this.deleteRow} />
          </div>
        )}
        {this.state.editModalOpen && (
          <EditCategoryModal
            label={this.props.subcategory.label}
            entityName={'Subcategory'}
            show={this.state.editModalOpen}
            onHide={() => this.setState({ editModalOpen: false })}
            onSave={label =>
              this.props.editCategory(
                this.props.subcategory.categoryId,
                this.props.subcategory.id,
                label
              )
            }
            onDelete={() =>
              this.props.deleteCategory(
                this.props.subcategory.categoryId,
                this.props.subcategory.id
              )
            }
          />
        )}
        {this.state.deleteModalOpen && (
          <DeleteModal
            show={this.state.deleteModalOpen}
            onHide={this.closeDeleteModal}
            onSave={this.closeDeleteModal}
            title="Cannot delete last subcategory"
            message="Please note, you must have at least one subcategory. These help candidates find the jobs they’re looking for and help you filter and analyze your hiring data."
            submitLabel="Got it!"
          />
        )}
      </div>
    );
  }
}
