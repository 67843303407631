import { Box, CircularProgress, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Inter } from '../utils';
import connectCircles from './../../../../../assets/illustration-empty-search.svg';

export const NoResultsFoundCard: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  return (
    <NoResultsFoundContainer>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <div style={{ height: '48px' }}></div>
          <Typography
            sx={{
              color: '#101828',
              textAlign: 'center',
              fontFamily: Inter,
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '28px',
            }}
          >
            No results found for your search criteria. Try expanding your search radius or adjusting
            your filters.
          </Typography>
          <Typography
            sx={{
              color: '#475467',
              textAlign: 'center',
              fontFamily: Inter,
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '28px',
            }}
          >
            Please{' '}
            <Link
              href="mailto:support@hirenimble.com"
              style={{ color: '#00A37D' }}
              underline={'always'}
            >
              contact us
            </Link>{' '}
            if you need help finding teaching jobs in your area.
          </Typography>
        </>
      )}
    </NoResultsFoundContainer>
  );
};

const NoResultsFoundContainer = styled(Box)(() => ({
  display: 'flex',
  padding: '24px',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '24px',
  justifyContent: 'center',
  borderRadius: '12px',
  border: '1px solid  #EAECF0',
  backgroundImage: `url(${connectCircles})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
}));
