import PropTypes from 'prop-types';

import { DropdownWithInputFilter } from 'ui-kit';

function SchoolSelection({ schoolroleId, handleSchoolChange, schoolroles }) {
  function schoolOptions() {
    return schoolroles.map(schoolrole => ({
      value: schoolrole.id,
      label: schoolrole.school,
    }));
  }

  return (
    <DropdownWithInputFilter
      options={schoolOptions()}
      value={schoolroleId || ''}
      handleChange={handleSchoolChange}
      placeholder="Type or select name of school"
      boxShadow={false}
      onClear={() => handleSchoolChange(null)}
    />
  );
}

SchoolSelection.propTypes = {
  schoolroleId: PropTypes.number,
  handleSchoolChange: PropTypes.func.isRequired,
  schoolroles: PropTypes.array.isRequired,
};

export default SchoolSelection;
