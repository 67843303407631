import styled from 'styled-components';

interface buttonProps {
  status: string;
  isSelected: boolean;
}

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 30px);
  grid-gap: 12px;
  height: 18px;
  flex: 17;
  align-content: center;
`;

export const BaseActionButton = styled.button<buttonProps>`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid
    ${({ status }) =>
      status === 'no-fit'
        ? '#CC0000'
        : status === 'potential-fit'
        ? '#E46B00'
        : 'var(--green-backgorund-one)'};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({ status, isSelected }) =>
    status === 'no-fit' && isSelected === true
      ? 'rgba(204, 0, 0, 0.25)'
      : status === 'potential-fit' && isSelected === true
      ? 'rgba(228, 107, 0, 0.25)'
      : status === 'converted-to-prospect' && isSelected === true
      ? 'rgba(0, 163, 125, 0.25)'
      : 'white'};
`;

export const CategoriesOfInterest = styled.div`
  flex: 20;
`;

export const LastLogin = styled.div`
  flex: 14;
`;

export const Name = styled.div`
  flex: 25;
`;

export const Row = styled.div`
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  align-items: center;
  border-bottom: 1px solid #ddd;
  color: rgba(57, 60, 73, 0.8);
  display: grid;
  font-size: 15px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  height: 48px;
  min-width: 500px;
  padding: 0 8px;
  width: 100%;

  @media screen and (max-width: 768px) {
    height: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  & > div {
    padding-right: 8px !important;
  }
`;

export const ViewProfileButton = styled.button`
  color: #4f4f4f;
  border: none;
  background-color: transparent;
  font-weight: 600;
  text-align: left;
  padding: 0;
  flex: 21;
`;

export const YOE = styled.div`
  flex: 8;
`;
