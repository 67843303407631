import {
  CartesianGrid,
  ResponsiveContainer,
  LineChart,
  Line,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';

import * as DashboardWidget from './components/DashboardWidget';
import * as Toggle from './components/Toggle';
import { HIRING_SEASONS } from 'utils/enums';
import { ChoiceDropdown, useChoiceDropdown } from './components/ChoiceDropdown';
import { useApplicantVolumeMetrics } from './useApplicantVolumeMetrics';
import { CHART_COLOR_PALETTES, DIVERSITY_CHOICES } from './constants';

const ApplicantVolumeWidget = ({ originalHiringSeasons, schools, subcategories }) => {
  const toggleState = Toggle.useToggleState();
  const diversityDropdownState = useChoiceDropdown({
    choices: DIVERSITY_CHOICES,
    initialSelectedChoiceIds: DIVERSITY_CHOICES.map(choice => choice.id),
  });

  let hiringSeasons;
  if (originalHiringSeasons.length === 0) {
    // The chart should show the same data when all hiring seasons are selected vs
    // when none are selected. So if none are selected, set them all.
    hiringSeasons = Object.keys(HIRING_SEASONS);
  } else {
    hiringSeasons = [...originalHiringSeasons];
  }

  const { data, isLoading } = useApplicantVolumeMetrics({
    hiringSeasons,
    schools,
    subcategories,
    genders: DIVERSITY_CHOICES.filter(choice => choice.group === 'gender')
      .filter(choice => !choice.isGroupHeader)
      .filter(choice => diversityDropdownState.selectedChoiceIds.includes(choice.id))
      .map(choice => choice.value),
    ethnicities: DIVERSITY_CHOICES.filter(choice => choice.group === 'enthnicity')
      .filter(choice => !choice.isGroupHeader)
      .filter(choice => diversityDropdownState.selectedChoiceIds.includes(choice.id))
      .map(choice => choice.value),
  });

  const dataset = toggleState.modeOneIsActive
    ? data.applications_by_month
    : data.candidates_by_month;

  const diversityFilterIsOn =
    diversityDropdownState.selectedChoiceIds.length !== 0 &&
    diversityDropdownState.selectedChoiceIds.length !== DIVERSITY_CHOICES.length;

  let notEnoughData = false;
  if (diversityFilterIsOn) {
    // look through each hiring season, if any value for any month is 1 or fewer,
    // don't show the chart.
    Object.entries(HIRING_SEASONS).forEach(([hiringSeasonKey, hiringSeasonValue]) => {
      dataset.forEach(monthData => {
        if (monthData[hiringSeasonValue] <= 1) {
          notEnoughData = true;
        }
      });
    });
  }

  let message;
  if (notEnoughData) {
    message = 'Not enough data. Check your filters and try again.';
  }

  let colors = CHART_COLOR_PALETTES[8];
  if (hiringSeasons.length <= 4) {
    colors = CHART_COLOR_PALETTES[4];
  } else if (hiringSeasons.length <= 6) {
    colors = CHART_COLOR_PALETTES[6];
  }

  return (
    <DashboardWidget.Container>
      <DashboardWidget.Heading>Applicant volume</DashboardWidget.Heading>
      <DashboardWidget.Body>
        <DashboardWidget.BodyActions>
          <ChoiceDropdown
            label="Demographics"
            choices={DIVERSITY_CHOICES}
            {...diversityDropdownState}
          />
          <DashboardWidget.BodyActionPulledRight>
            <Toggle.Wrapper>
              <Toggle.ToggleItem
                isActive={toggleState.modeOneIsActive}
                onClick={toggleState.setModeOneIsActive}
              >
                Apps
              </Toggle.ToggleItem>
              <Toggle.ToggleItem
                isActive={toggleState.modeTwoIsActive}
                onClick={toggleState.setModeTwoIsActive}
              >
                People
              </Toggle.ToggleItem>
            </Toggle.Wrapper>
          </DashboardWidget.BodyActionPulledRight>
        </DashboardWidget.BodyActions>
        <DashboardWidget.BodyContent isLoading={isLoading}>
          {message ? (
            <Placeholder>{message}</Placeholder>
          ) : (
            <ResponsiveContainer width="99%" height="100%">
              <LineChart data={dataset} margin={{ right: 25, top: 10 }}>
                <CartesianGrid vertical={false} strokeWidth="0.5" />
                <XAxis dataKey="month_abbr" interval={0} tickLine={false} />
                <YAxis tickLine={false} />
                <Tooltip />
                {hiringSeasons.map((season, i) => {
                  return (
                    <Line
                      key={season}
                      dataKey={HIRING_SEASONS[season]}
                      name={HIRING_SEASONS[season]}
                      stroke={colors[i % hiringSeasons.length]}
                      strokeWidth={2}
                      dot={false}
                      type="monotone"
                      activeDot={{ strokeWidth: 3, r: 6 }}
                    />
                  );
                })}
                <Legend />
              </LineChart>
            </ResponsiveContainer>
          )}
        </DashboardWidget.BodyContent>
      </DashboardWidget.Body>
    </DashboardWidget.Container>
  );
};

export default ApplicantVolumeWidget;

const Placeholder = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
