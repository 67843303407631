import { useEffect, useRef } from 'react';

const loadScript = src =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve();
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => resolve();
    script.onerror = err => reject(err);
    document.body.appendChild(script);
  });

const GoogleLogin = props => {
  const googleButton = useRef(null);

  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client';
    // TODO: foloow up ticket has been created to move this and
    // probably more variables into a general React Context
    const id = '951631884860-733u4aj58ariplgkg508lhds6hbf1cgi.apps.googleusercontent.com';

    loadScript(src)
      .then(() => {
        /*global google*/
        google.accounts.id.initialize({
          client_id: id,
          callback: handleCredentialResponse,
        });
        try {
          google.accounts.id.renderButton(googleButton.current, {
            theme: 'outline',
            width: 368,
            logo_alignment: 'center',
            locale: 'en_US',
          });
        } catch (googleError) {
          //Render the google button without width styling.
          google.accounts.id.renderButton(googleButton.current, {
            theme: 'outline',
            logo_alignment: 'center',
            locale: 'en_US',
          });
          throw new Error(googleError);
        }
      })
      .catch(console.error);

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`);
      if (scriptTag) document.body.removeChild(scriptTag);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleCredentialResponse(response) {
    props.googleSignin(response.credential);
  }

  return (
    <div>
      <div ref={googleButton} />
    </div>
  );
};

export default GoogleLogin;
