import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Input, AltModal } from 'ui-kit';

import { addDocument, getFileNameFromPath } from '../../utils/fileUpload.js';
import greenCheckmark from '../../assets/icon_check_green.svg';
import DownloadIcon from 'ui-kit/icons/svg/download-green.svg';
import HotGarbageIcon from 'assets/icon-hot-garbage.svg';
import GreenArrowsIcon from 'ui-kit/icons/svg/green-cycle-arrows.svg';
import PlainFileIcon from 'assets/icon-plain-file.svg';
import ErrorText from '../errortext';
import { UploadFileHelperMessage } from 'components/uploadFileHelperMessage.tsx';

import LoadingSpinner from '../../components/loadingSpinner';

export default class CandidateDocumentModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    submitAdditionalDocument: PropTypes.func.isRequired,
    replaceRequiredAttachment: PropTypes.func,
    deleteApplicationAttachment: PropTypes.func,
    attachments: PropTypes.arrayOf(PropTypes.object),
    handleAttachmentChanges: PropTypes.func.isRequired,
    handleReplaceCandidateProfileResume: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      submitDisabled: false,
      attachmentName: null,
      documentName: '',
      requiredFileName: '',
      documentExists: false,
      uploadErrorMessages: {
        document: '',
      },
      document: {},
      submitErrorMessage: '',
      postSubmission: false,
      postDeletion: false,
      isAddingDocument: false,
    };
    this.initialState = Object.assign({}, this.state);

    this.addDocument = addDocument.bind(this);
    this.getFileNameFromPath = getFileNameFromPath.bind(this);
  }

  handleChange = e => {
    this.setState({
      attachmentName: e.target.value,
      submitErrorMessage: '',
    });
  };

  onSubmit = () => {
    if (this.checkForErrors()) {
      this.setState({ submitDisabled: true });
      let newState = this.initialState;

      this.props
        .submitAdditionalDocument(this.state.attachmentName, this.state.document)
        .then(r => {
          if (r.status === 201) {
            // TODO: this reset isn't working properly
            this.setState({ newState });

            // add new attachment to state with temp fields
            const newAttachments = [...this.props.attachments, r.data].map(attachment => {
              attachment.isDeleting = false;
              attachment.isReplacing = false;
              attachment.postSubmission = false;
              return attachment;
            });

            this.props.handleAttachmentChanges(newAttachments);

            // reset document container
            this.setState({
              isAddingDocument: false,
              attachmentName: '',
              documentExists: false,
              document: {},
              postSubmission: true,
            });
          }
        });
    }
  };

  onReplace = (fileName, attachmentID, requiredAttachmentID) => {
    // validate document
    if (!this.state.documentExists) {
      this.setState({ submitErrorMessage: 'Please select a file.' });
      return;
    }

    this.setState({ submitDisabled: true });
    let newState = this.initialState;

    // FIXME: replace resume found in user.profile
    if (attachmentID === -1) {
      this.props.handleReplaceCandidateProfileResume(this.state.document).then(resume => {
        this.setState({ newState });
        // add new resume to state and reset document container
        const updatedAttachments = this.props.attachments.filter(
          attachment => attachment.id !== attachmentID
        );
        updatedAttachments.push(resume);

        // ensure attachments have temporary fields
        this.props.handleAttachmentChanges(
          updatedAttachments.map(attachment => {
            attachment.isDeleting = false;
            // fields below are true for attachment that is being replaced.
            // This ensures "file successfully submitted" is displayed
            attachment.isReplacing = attachment.id === attachmentID ? true : false;
            attachment.postSubmission = attachment.id === attachmentID ? true : false;
            return attachment;
          })
        );

        this.setState({
          isAddingDocument: false,
          documentExists: false,
          document: {},
        });
      });
      return;
    }

    // standard replace attachment flow
    this.props
      .replaceRequiredAttachment(this.state.document, fileName, attachmentID, requiredAttachmentID)
      .then(r => {
        if (r.status === 200) {
          this.setState({ newState });
          // add new attachment to state and reset document container
          const updatedAttachments = this.props.attachments.filter(
            attachment => attachment.id !== attachmentID
          );
          updatedAttachments.push(r.data);

          // ensure attachments have temporary fields
          this.props.handleAttachmentChanges(
            updatedAttachments.map(attachment => {
              attachment.isDeleting = false;
              // fields below are true for attachment that is being replaced.
              // This ensures "file successfully submitted" is displayed
              attachment.isReplacing = attachment.id === attachmentID ? true : false;
              attachment.postSubmission = attachment.id === attachmentID ? true : false;
              return attachment;
            })
          );

          this.setState({
            isAddingDocument: false,
            documentExists: false,
            document: {},
          });
        }
      });
  };

  checkForErrors = () => {
    if (!this.state.attachmentName) {
      this.setState({ submitErrorMessage: 'Please name this attachment.' });
      return false;
    } else if (!this.state.documentExists) {
      this.setState({ submitErrorMessage: 'Please select a file.' });
      return false;
    }
    return true;
  };

  clickDocumentInput = () => {
    const uploadErrorMessages = this.state.uploadErrorMessages;
    uploadErrorMessages.resume = '';
    this.setState({ uploadErrorMessages });
    this.documentInput.click();
  };

  changeDocument = event => {
    const file = this.addDocument(event);
    // If there's an error
    if (!file) return;
    this.setState({
      document: file.fileData,
      uploadErrorMessages: file.uploadErrorMessages,
      documentExists: true,
    });
  };

  // determine which attachment and which file section type (replace or delete) to open
  handleShowAttachmentFileContainer = (e, attachmentId) => {
    const buttonId = e.target.id;

    const newAttachments = this.props.attachments.map(attachment => {
      if (attachment.id === attachmentId) {
        // determine what action to perform
        if (buttonId.includes('replace')) {
          // toggle replace attachment section
          attachment.isReplacing = !attachment.isReplacing;
          // reset postSubmission if user closes the replacement container
          attachment.postSubmission = !attachment.isReplacing && false;
        } else if (buttonId.includes('delete')) {
          // toggle delete attachment section
          attachment.isDeleting = !attachment.isDeleting;
        }
      }
      return attachment;
    });

    this.props.handleAttachmentChanges(newAttachments);
  };

  document = () => {
    const documentExists = this.state.documentExists;

    return (
      <>
        <FileContainerActions>
          <OutlinedGreenButton type="button" onClick={this.clickDocumentInput}>
            Select File
          </OutlinedGreenButton>
          {documentExists && (
            <UploadedFileContainer>
              <UploadedFileText>{this.state.document.name}</UploadedFileText>
              <GreenButton type="button" onClick={this.onSubmit}>
                Upload File
              </GreenButton>
            </UploadedFileContainer>
          )}
        </FileContainerActions>
        <UploadFileHelperMessage />
      </>
    );
  };

  replaceDocument = attachmentID => {
    const foundAttachment = this.props.attachments.find(
      attachment => attachment.id === attachmentID
    );

    return (
      <>
        <FileContainerActions>
          <OutlinedGreenButton type="button" onClick={this.clickDocumentInput}>
            Select File
          </OutlinedGreenButton>
          <UploadedFileContainer>
            <UploadedFileText>
              {/* display's current filename, else show filename of replacing document */}
              {this.state.documentExists
                ? this.state.document.name
                : this.getFileNameFromPath(foundAttachment.attachment)}
            </UploadedFileText>
            <GreenButton
              type="button"
              onClick={() =>
                this.onReplace(
                  foundAttachment.required_application_attachment.title,
                  attachmentID,
                  foundAttachment.required_application_attachment.id
                )
              }
            >
              Upload Files
            </GreenButton>
          </UploadedFileContainer>
        </FileContainerActions>
        <UploadFileHelperMessage />
      </>
    );
  };

  deleteDocument = documentID => {
    const document = this.props.attachments.find(document => document.id === documentID);

    return (
      <DeleteDocumentContainer>
        <DeleteText>Are you sure you want to remove this file?</DeleteText>
        <DeleteButton
          onClick={async () => await this.props.deleteApplicationAttachment(document.id)}
        >
          Yes, Delete File
        </DeleteButton>
        <DeleteNoButton
          id="delete-btn-no"
          onClick={e => this.handleShowAttachmentFileContainer(e, document.id)}
        >
          No
        </DeleteNoButton>
      </DeleteDocumentContainer>
    );
  };

  /**
   * Helper method that determines whether an attachment is required or not.
   *
   * @param {*} doc an attachment
   * @returns {boolean} - true if required, else false
   */
  isRequiredDocument = doc => {
    // document doesn't exist and is therefore not required
    if (doc === null) return false;
    // true if document is marked required for either candidate type
    else if (doc.is_required_external || doc.is_required_internal) return true;
    // doc not required
    else return false;
  };

  render() {
    if (this.props.isLoading)
      return (
        <LoadingSpinner
          margin={0}
          fontSize={2}
          additionalStyles={{
            position: 'absolute',
            top: '16px',
            right: '8px',
          }}
        />
      );

    const { show, onHide } = this.props;
    const errorMessage = this.state.submitErrorMessage || this.state.uploadErrorMessages.document;
    const attachments = this.props.attachments;

    return (
      <AltModal.AltModal
        isOpen={show}
        onClose={() => {
          this.setState(this.initialState);
          onHide();
        }}
        noBottom={true}
        className="additional-document-modal"
      >
        <AltModal.Title>Upload Documents</AltModal.Title>
        <AltModal.Body style={{ backgroundColor: '#FAFAFA', padding: '50px' }}>
          {!this.state.isAddingDocument ? (
            <AddNewFileButton onClick={() => this.setState({ isAddingDocument: true })}>
              <ButtonIcon src={PlainFileIcon} alt="plain file" width="20px" height="20px" />
              Add New Document
            </AddNewFileButton>
          ) : (
            <FileInputContainer>
              <Input
                name="attachmentName"
                ref="attachmentName"
                type="text"
                placeholder="e.g. cover letter"
                pattern="..*"
                width={1}
                marginTop="1rem"
                marginBottom="1rem"
                value={this.state.attachmentName || ''}
                required
                onChange={this.handleChange}
                hasError={errorMessage}
              />
              <input
                name="document"
                ref={input => (this.documentInput = input)}
                type="file"
                style={{ height: '0px', overflow: 'hidden', margin: '0' }}
                onChange={this.changeDocument}
              />
              {this.document()}
              <StyledErrorMessage message={errorMessage} />
            </FileInputContainer>
          )}
          {attachments?.length > 0 && (
            <AttachmentsContainer>
              {attachments.map(
                ({
                  id,
                  attachment,
                  title,
                  required_application_attachment,
                  hellosign_template,
                  isReplacing,
                  isDeleting,
                  postSubmission,
                }) => (
                  <AttachmentRow key={id} ref={input => (this.documentInputReplace = input)}>
                    <AttachmentHeader>
                      <AttachmentTitle>
                        {/* if user defined title doesn't exist, its required doc */}
                        {title || required_application_attachment.title}
                        {(id === -1 ||
                          this.isRequiredDocument(required_application_attachment)) && (
                          <RequiredBadge>Required</RequiredBadge>
                        )}
                      </AttachmentTitle>
                      {/* controls for updating or removing attachments */}
                      <AttachmentActions>
                        {/* hellosign documents can be downloaded but not replaced or deleted */}
                        {hellosign_template ? (
                          <DownloadButton href={attachment} target="_blank" download>
                            <ButtonIcon src={DownloadIcon} alt="Download file" />
                            Download
                          </DownloadButton>
                        ) : id === -1 ||
                          this.isRequiredDocument(required_application_attachment) ? (
                          <>
                            {/* non-hellosign documents can be replaced or deleted */}
                            <DownloadButton href={attachment} target="_blank" download>
                              <ButtonIcon src={DownloadIcon} alt="Download file" />
                              Download
                            </DownloadButton>
                            <ActionButton
                              id={`replace-btn-${id}`}
                              onClick={e => this.handleShowAttachmentFileContainer(e, id)}
                              isReplacing={isReplacing}
                            >
                              <ButtonIcon
                                id="replace-icon"
                                src={GreenArrowsIcon}
                                alt="Replace file"
                              />
                              Replace
                            </ActionButton>
                          </>
                        ) : (
                          <>
                            {/* non-hellosign documents can be replaced or deleted */}
                            <DownloadButton href={attachment} target="_blank" download>
                              <ButtonIcon src={DownloadIcon} alt="Download file" />
                              Download
                            </DownloadButton>
                            <ActionButton>
                              <ButtonIcon
                                id={`delete-btn-${id}`}
                                src={HotGarbageIcon}
                                alt="Delete file"
                                onClick={e => this.handleShowAttachmentFileContainer(e, id)}
                              />
                            </ActionButton>
                          </>
                        )}
                      </AttachmentActions>
                    </AttachmentHeader>
                    {/* user clicked replace button */}
                    {isReplacing && (
                      <div>
                        {!postSubmission ? (
                          <div className="mt2">
                            <input
                              name="document"
                              ref={input => (this.documentInput = input)}
                              type="file"
                              style={{
                                height: '0px',
                                overflow: 'hidden',
                                margin: '0',
                              }}
                              onChange={this.changeDocument}
                            />
                            {/* open replace file container */}
                            {this.replaceDocument(id)}
                          </div>
                        ) : (
                          <DocumentBottomSection>
                            {/* file has been replaced */}
                            <FileSuccessContainer>
                              <Icon
                                src={greenCheckmark}
                                alt="green checkmark"
                                style={{ flexBasis: '30px' }}
                              />
                              <p>File replaced successfully.</p>
                            </FileSuccessContainer>
                          </DocumentBottomSection>
                        )}
                      </div>
                    )}
                    {/* user clicked delete button */}
                    {isDeleting && (
                      <DocumentBottomSection isDeleteDocument={true}>
                        {this.deleteDocument(id)}
                      </DocumentBottomSection>
                    )}
                  </AttachmentRow>
                )
              )}
            </AttachmentsContainer>
          )}
        </AltModal.Body>
      </AltModal.AltModal>
    );
  }
}

const GreenButton = styled.button`
  height: 50px;
  -webkit-appearance: none;
  border-radius: 4px;
  padding: 0px 42px;
  font-size: 16px;
  background-color: #00b88d;
  color: white;
  border: none;
`;

const OutlinedGreenButton = styled(GreenButton)`
  border: 1px solid #00b88d;
  border-radius: 4px;
  background-color: #fff;
  color: #00b88d;
`;

const UploadedFileText = styled.span`
  color: #aaaaaa;
  font-size: 15px;
  line-height: 20px;
  margin: 0 15px;
  width: 180px;
  max-width: 180px;
`;

const UploadedFileContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AddNewFileButton = styled.button`
  border: 1px solid #00b88d;
  border-radius: 4px;
  background: #fff;
  padding: 10px;
  margin: 0 5px;
  width: 100%;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #00b88d;
  font-size: 13px;
  font-weight: 600px;
  line-height: 18px;
  text-align: center;
`;

const ButtonIcon = styled.img.attrs(props => ({
  width: props.width || '16px',
  height: props.height || '16px',
}))`
  margin-right: 10px;
`;

const FileInputContainer = styled.div`
  background-color: #f2f2f2;
  border-radius: 4px;
  padding: 5px 15px 20px 15px;
`;

const StyledErrorMessage = styled(ErrorText)`
  margin-top: 1rem;
`;

const FileContainerActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AttachmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fafafa;
`;

const AttachmentRow = styled.div`
  flex: 1;
  padding: 20px;
  margin: 10px 0 10px 5px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  background-color: #fff;
`;

const AttachmentHeader = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 532px) {
    flex-direction: column;
  }
`;

const DocumentBottomSection = styled.div`
  margin-top: 15px;
  background-color: ${({ isDeleteDocument }) => (isDeleteDocument ? '#fbf4f6' : '#ebf9f6')};
  border-radius: 4px;
  text-align: center;
`;

const AttachmentTitle = styled.p`
  flex: 1;
  justify-self: start;

  font-size: 15px;
  color: #444444;
  line-height: 20px;
  display: flex;

  @media (max-width: 532px) {
    margin-bottom: 4px;
    align-items: center;
  }
`;

const RequiredBadge = styled.span`
  background: #ffffff;
  border: 0.5px solid #7b5196;
  border-radius: 3px;
  padding: 2px 5px;
  margin-left: 10px;

  font-size: 7.72727px;
  text-transform: uppercase;
  line-height: 11px;
  color: #7b5196;
`;

const AttachmentActions = styled.div`
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: end;
`;

const ActionButton = styled.button`
  color: #00b88d;
  font-size: 13px;
  border: none;
  background-color: ${({ isReplacing }) => (isReplacing ? '#EBF9F6' : '#FFF')};
  border-radius: 4px;
  padding: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
`;

const DownloadButton = styled.a`
  color: #00b88d;
  font-size: 13px;
  border: none;
  background-color: ${({ isReplacing }) => (isReplacing ? '#EBF9F6' : '#FFF')};
  border-radius: 4px;
  padding: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  &:hover,
  &:focus,
  &:active {
    color: #00b88d;
  }
`;

const Icon = styled.img`
  width: 10px;
  height: 10px;
`;

const DeleteDocumentContainer = styled.div`
  background-color: #fbf4f6;
  border-radius: 4px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);

  height: 79px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const DeleteText = styled.span`
  color: #444444;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  margin-right: 15px;
`;

const DeleteButton = styled.button`
  border: 1px solid #de3255;
  border-radius: 3px;
  background-color: #fff;
  padding: 5px 15px;
  margin: 0 10px;

  color: #de3255;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
`;

const DeleteNoButton = styled.button`
  border: 1px solid #de3255;
  border-radius: 3px;
  background-color: transparent;
  padding: 5px 15px;
  color: #de3255;
  text-transform: uppercase;

  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
`;

const FileSuccessContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;
