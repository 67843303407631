import { useHistory, useLocation } from 'react-router-dom';

import EditIcon from 'assets/icons/icon_edit.svg';
import { JobAlertsContainerProps } from './types';
import TrashIcon from 'assets/icons/icon_trash.svg';
import {
  ActionContainer,
  ActionText,
  BulletPoint,
  ConfirmDeleteContainer,
  InfoAndActionContainer,
  InfoContainer,
  PrimaryDeleteButton,
  SecondaryDeleteButton,
  SelectionContainer,
  StyledEditIcon,
  StyledTrashIcon,
  SubscriptionCategoriesContainer,
} from './JobAlertsContainer.styles';

export const JobAlertsContainer: React.FC<JobAlertsContainerProps> = ({
  confirmDelete,
  emailToUse,
  handleSetIsSubmessageVisible,
  jobAlerts,
  onConfirmDeleteChange,
  onDistrictSelection,
  onEditingIdChange,
  onSubcategorySelection,
  onUnsubscribe,
  handleSetIsSuccessAlertVisible,
}) => {
  const { search: queryString } = useLocation();
  const history = useHistory();

  const handleEditURLParameterChange = () => {
    const parameters = new URLSearchParams(queryString);
    parameters.set('openModalType', 'edit');
    history.push({ search: parameters.toString() });
  };

  return (
    <>
      {jobAlerts.map((jobAlert, index) => (
        <SelectionContainer key={jobAlert.id}>
          <InfoAndActionContainer>
            <InfoContainer>
              <div>{emailToUse}</div>
              <SubscriptionCategoriesContainer>
                <div>
                  {jobAlert?.subcategories.length}{' '}
                  {jobAlert?.subcategories.length === 1 ? 'category' : 'categories'}
                </div>
                <BulletPoint />
                <div>
                  {jobAlert?.districts.length}{' '}
                  {jobAlert?.districts.length === 1 ? 'organization' : 'organizations'}
                </div>
              </SubscriptionCategoriesContainer>
            </InfoContainer>
            <ActionContainer>
              <ActionText
                onClick={() => {
                  onEditingIdChange(jobAlert?.id);
                  onSubcategorySelection(
                    jobAlert?.subcategories.map(s => parseInt(s.state_id)) || []
                  );
                  onDistrictSelection(
                    jobAlert?.districts.map(d =>
                      typeof d.id === 'string' ? parseInt(d.id) : d.id
                    ) || []
                  );
                  handleSetIsSuccessAlertVisible(true);
                  handleSetIsSubmessageVisible(true);
                  handleEditURLParameterChange();
                }}
              >
                <StyledEditIcon src={EditIcon} alt="edit" />
              </ActionText>
              <ActionText>
                {' '}
                <StyledTrashIcon
                  src={TrashIcon}
                  alt="delete"
                  onClick={() => onConfirmDeleteChange([...confirmDelete, index])}
                />
              </ActionText>
            </ActionContainer>
          </InfoAndActionContainer>
          {confirmDelete.includes(index) && (
            <ConfirmDeleteContainer>
              <div>Are you sure you want to remove this alert?</div>
              <div>
                <PrimaryDeleteButton onClick={() => onUnsubscribe(jobAlert?.id, index)}>
                  YES, DELETE ALERT
                </PrimaryDeleteButton>
                <SecondaryDeleteButton
                  onClick={() => onConfirmDeleteChange(confirmDelete.filter(id => id !== index))}
                >
                  NO
                </SecondaryDeleteButton>
              </div>
            </ConfirmDeleteContainer>
          )}
        </SelectionContainer>
      ))}
    </>
  );
};
