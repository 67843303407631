import { nimbleTheme } from 'theme';
import { activeStatusByValue, roleStatusByValue, roleStatusIdsByName } from 'utils/enums';

export const calculateStatusColor = (status, active_status) => {
  switch (status) {
    case 0: // draft
      return nimbleTheme.palette.text.primary;
    case 20:
      return active_status === 1
        ? nimbleTheme.palette.primary.dark // active open
        : '#EC7729'; // active closed
    default:
      return nimbleTheme.palette.error.dark;
  }
};

export const getStatusText = (status, active_status) => {
  switch (status) {
    case roleStatusByValue.draft:
      return 'Draft';
    case roleStatusByValue.submitted:
      return 'Pending';
    case roleStatusByValue.active:
      return active_status === activeStatusByValue.activeOpen ? 'Active, Open' : 'Active, Closed';
    default:
      return 'Archived';
  }
};

const sortJobByStatus = (a, b) => {
  const statusOrder = [
    roleStatusIdsByName.active,
    roleStatusIdsByName.draft,
    roleStatusIdsByName.archived,
  ];

  const aIndex = statusOrder.indexOf(a.status);
  const bIndex = statusOrder.indexOf(b.status);

  if (aIndex === bIndex) {
    return b.active_status - a.active_status;
  } else {
    return aIndex - bIndex;
  }
};

export const sortJobSearchResults = results => {
  return results.sort((a, b) => {
    return sortJobByStatus(a, b);
  });
};
