import { useState, useRef, useCallback, useEffect } from 'react';

// hooks
import { useStateFilterOptions } from 'hooks/data/useStateFilterOptions';
import { useStateWideJobs } from 'hooks/data/useStateWideJobs';

// components
import PageNotFound from 'components/PageNotFound';
import { StateJobBoardHeader } from './StateJobBoardHeader';
import StateJobBoardFilterDropDowns from './StateJobBoardFilterDropDowns';
import StateJobsList from './StateJobsList';
import JobAlerts from './JobAlerts/index';

import { grades as gradesEnum, USStates } from 'utils/enums';
import { useRouteMatch } from 'react-router-dom';
import auth from '../../utils/auth';
import { getOrSetPreferenceFlag } from 'utils/preferenceConfig';
import StateTalentModal from './StateTalentModal';

export default function StateJobBoard(props) {
  const match = useRouteMatch('/jobs/state/:id');
  const rawStateCode = match?.params.id;
  const stateCode = rawStateCode?.toUpperCase();

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');
  // Filters that aren't dynamic get initialized with values.
  // The others get initialized in the filter hook.
  const [grades, setGrades] = useState(gradesEnum().map(g => g.value));
  const [fulltime, setFulltime] = useState([0, 1]);
  const [schoolIds, setSchoolIds] = useState([]);
  const [subcategoryStateIds, setSubcategoryStateIds] = useState([]);
  const [districtIds, setDistrictIds] = useState([]);
  const [startDate, setStartDate] = useState(['immediate', 'next_school_year']);
  // The start-date dropdown has options for selecting a specific
  // date that the start date should come before or after. The
  // startDate property corresponds to the outer levels ("Immediate",
  // "Next school year", "Before", "After") and startDateBefore /
  // startDateAfter correspond to the before and after dates, respectively.
  // They are null by default.
  const [startDateBefore, setStartDateBefore] = useState(null);
  const [startDateAfter, setStartDateAfter] = useState(null);
  // Keep track of which filters are active. When filters are inactive,
  // don't send them as query params to the search API, because
  // we count having all filters selected as the filter being inactive.
  // For example, if all grade levels are selected, we want all jobs returned,
  // even those that don't have any grades selected on the job. So in that
  // case the filter would show all grades selected, but we wouldn't send
  // a grades query param to the backend because activeFilters.grades = false.
  const [activeFilters, setActiveFilters] = useState({
    grades: false,
    fulltime: false,
    schoolIds: false,
    subcategoryStateIds: false,
    districtIds: false,
    startDate: false,
  });
  const [showModal, setShowModal] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [useDebounce, setUseDebounce] = useState(false);

  const filterValues = {
    stateCode,
    page,
    query,
    grades,
    fulltime,
    schoolIds,
    subcategoryStateIds,
    districtIds,
    startDate,
    startDateBefore,
    startDateAfter,
  };

  const {
    results,
    count,
    hasMore,
    error,
    fetchingNextPage,
    setFetchingNextPage,
  } = useStateWideJobs({ ...filterValues }, activeFilters, setIsLoading, useDebounce);

  // Attach a ref to an element in the job list, and when
  // that element comes into view, start fetching the next
  // page. I'm not 100% sure how this works but Can't
  // Argue with Success (CAWS)
  const observer = useRef();
  const observedElementRef = useCallback(
    node => {
      if (isLoading) return;
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setFetchingNextPage(true);
          setPage(prevPage => prevPage + 1);
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoading, hasMore, setFetchingNextPage]
  );

  const filterOptions = useStateFilterOptions(stateCode);

  const [loggedIn, setLoggedIn] = useState(false);
  const [dropdownProps, setDropdownProps] = useState({
    logout: () => {},
    getUser: () => {
      return { id: 0, first_name: '', is_hijacked: false };
    },
    releaseHijack: () => {},
    showChecklist: () => {},
    inPostingOnlyDistrict: false,
    isDistrictAdmin: false,
    isDistrictUser: false,
    showDropdown: false,
    jobBoardStyling: false,
    combinedPreferenceAndProfile: false,
  });

  const logout = () => {
    auth.logout().then(() => window.location.assign('/login'));
  };

  const releaseHijack = () => {
    auth.releaseHijack().then(() => window.location.assign('/admin/core/user/'));
  };

  function showChecklist() {
    // checklist tab in the dropdown is only displayed if the checklist exists,
    // the admin is not in a posting-only district, and at least one of the
    // following: the admin is a legacy user OR the admin has completed the
    // checklist.
    return (
      auth.getUser().checklist !== null &&
      !auth.inPostingOnlyDistrict() &&
      (auth.getUser().is_legacy_user || auth.getUser().checklist.completed)
    );
  }

  useEffect(() => {
    async function fetchConfig() {
      const combinedPreferenceAndProfileFlag = await getOrSetPreferenceFlag();

      auth.checkAuthenticated().then(r => {
        if (!r || r === undefined) return;

        if (Object.keys(r).length === 0) {
          setLoggedIn(false);
        } else {
          setLoggedIn(true);
          setDropdownProps({
            ...dropdownProps,
            logout: logout,
            getUser: auth.getUser,
            releaseHijack: releaseHijack,
            showChecklist: showChecklist,
            inPostingOnlyDistrict: auth.inPostingOnlyDistrict(),
            isDistrictAdmin: auth.isDistrictAdmin(),
            isDistrictUser: auth.isDistrictUser(),
            showDropdown: false,
            jobBoardStyling: true,
            combinedPreferenceAndProfile: combinedPreferenceAndProfileFlag,
          });
        }
      });
    }

    fetchConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  // Once we've fetched options for our dynamic filters, set
  // their default values (all options selected)
  useEffect(() => {
    if (filterOptions.subcategoryStateOptions) {
      const values = [];
      filterOptions.subcategoryStateOptions.forEach(o => {
        o.subOptions.forEach(s => values.push(s.value));
      });
      setSubcategoryStateIds(values);
    }
  }, [filterOptions.subcategoryStateOptions]);

  const resetResults = () => {
    // Whenever we change a filter or query, set the page back to 1
    // since we're fetching a new list of results
    setPage(1);
    setFetchingNextPage(false);
    setIsLoading(true);
  };

  const actions = {
    setQuery: query => {
      setQuery(query);
      setUseDebounce(false);
      resetResults();
    },
    setGrades: grades => {
      setGrades(grades);
      setUseDebounce(true);
      resetResults();
    },
    setFulltime: fulltime => {
      setFulltime(fulltime);
      setUseDebounce(false);
      resetResults();
    },
    setSchoolIds: ids => {
      setSchoolIds(ids);
      setUseDebounce(true);
      resetResults();
    },
    setSubcategoryStateIds: ids => {
      setSubcategoryStateIds(ids);
      setUseDebounce(true);
      resetResults();
    },
    setDistrictIds: ids => {
      setDistrictIds(ids);
      setUseDebounce(true);
      resetResults();
    },
    setStartDate: startDate => {
      setStartDate(startDate);
      setUseDebounce(false);
      resetResults();
    },
    setStartDateBefore: startDateBefore => {
      setStartDateBefore(startDateBefore);
      setUseDebounce(false);
      resetResults();
    },
    setStartDateAfter: startDateAfter => {
      setStartDateAfter(startDateAfter);
      setUseDebounce(false);
      resetResults();
    },
    setActiveFilters: activeFilters => {
      setActiveFilters(activeFilters);
      resetResults();
    },
    setDefaultSubcategoryStateIds: () => {
      const values = [];
      filterOptions.subcategoryStateOptions.forEach(o => {
        o.subOptions.forEach(s => values.push(s.value));
      });
      setSubcategoryStateIds(values);
      setActiveFilters(prev => ({ ...prev, subcategoryStateIds: false }));
      resetResults();
    },
    setDefaultDistrictIds: () => {
      setDistrictIds([]);
      setActiveFilters(prev => ({ ...prev, districtIds: false }));
      resetResults();
    },
    setDefaultGrades: () => {
      setGrades(filterOptions.gradeOptions.map(o => o.value));
      setActiveFilters(prev => ({ ...prev, grades: false }));
      resetResults();
    },
    setDefaultEmploymentTypes: () => {
      setFulltime(filterOptions.employmentTypeOptions.map(o => o.value));
      setActiveFilters(prev => ({ ...prev, fulltime: false }));
      resetResults();
    },
    removeStartDateFilterValue: fieldName => {
      let isActive = true;
      setStartDate(prev => {
        const updatedValues = prev.filter(i => i !== fieldName);
        if (updatedValues.includes('immediate') && updatedValues.includes('next_school_year')) {
          isActive = false;
        }
        return updatedValues;
      });
      if (fieldName === 'before') {
        setStartDateBefore(null);
      }
      if (fieldName === 'after') {
        setStartDateAfter(null);
      }
      setActiveFilters(prev => ({ ...prev, startDate: isActive }));
      resetResults();
    },
    setDefaultSchoolIds: () => {
      setSchoolIds([]);
      setActiveFilters(prev => ({ ...prev, schoolIds: false }));
      resetResults();
    },
  };

  const onNoThanksClose = () => {
    saveToLocalStorage();
    setShowModal(false);
  };

  const onMaybeClose = () => {
    setShowModal(false);
  };

  const saveToLocalStorage = () => {
    window.localStorage.setItem('hasSeenModal', 'true');
  };

  const checkLocalStorage = () => {
    if (window.localStorage.getItem('hasSeenModal')) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    checkLocalStorage();
  }, []);

  return (
    <>
      <JobAlerts
        filterOptions={filterOptions}
        jobboardName={
          USStates().find(s => s.label === stateCode)?.title + ' Department of Education'
        }
        stateCode={stateCode}
        editPreferences={false}
      />
      <StateJobBoardHeader
        actions={actions}
        filterValues={filterValues}
        activeFilters={activeFilters}
        error={error}
        isloggedIn={loggedIn}
        dropdownProps={dropdownProps}
        count={count}
      />
      {showModal && (
        <StateTalentModal onNoThanksClose={onNoThanksClose} onMaybeClose={onMaybeClose} />
      )}
      <StateJobBoardFilterDropDowns
        filterValues={filterValues}
        actions={actions}
        filterOptions={filterOptions}
        activeFilters={activeFilters}
        setIsLoading={setIsLoading}
        setUseDebounce={setUseDebounce}
        error={error}
      />
      <StateJobsList
        isLoading={isLoading}
        results={results}
        count={count}
        hasMore={hasMore}
        setPage={setPage}
        ref={observedElementRef}
        fetchingNextPage={fetchingNextPage}
      />
    </>
  );
}
