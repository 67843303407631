import styled from 'styled-components';
import * as DashboardWidgetV2 from '../../StateDataDashboards/components/V2Components/DashboardWidgetV2';
import { ColumnActionType, FlatFileTransferPropsInterface } from '../types/types';
import { StrictMode } from 'react';

export const FlatFileTransferDashboardHeader: React.FC<FlatFileTransferPropsInterface> = ({
  title,
  isLoading,
  dispatch,
  areColumnsIncomplete,
}) => {
  const addColumn = (): void => {
    dispatch({ type: ColumnActionType.ADD_COLUMN });
  };

  return (
    <StrictMode>
      <DashboardContainer>
        <ResponsiveWidthHeader>
          <TitleText>{title}</TitleText>
          <DashboardWidgetV2.BodyContentV2 isLoading={isLoading}></DashboardWidgetV2.BodyContentV2>
          <ButtonContainer>
            <NewColumnButton onClick={() => addColumn()} disabled={areColumnsIncomplete}>
              {' '}
              + New Column
            </NewColumnButton>
          </ButtonContainer>
        </ResponsiveWidthHeader>
      </DashboardContainer>
    </StrictMode>
  );
};

export const DashboardContainer = styled.div`
  padding: 24px 78px 24px 0px;
  font-size: 15px;

  @media screen and (max-width: 768px) {
    padding: 0 10px;
  }
`;

const ResponsiveWidthHeader = styled.div`
  max-width: 90vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 40px;

  @media screen and (max-width: 1535px) {
    width: 1318px;
  }

  @media screen and (max-width: 1440px) {
    width: 1246px;
    max-width: 100vw;
  }

  @media screen and (max-width: 1330px) {
    max-width: 90vw;
  }

  @media screen and (min-width: 1536px) {
    width: 1400px;
    max-width: 100vw;
  }

  @media screen and (min-width: 1900px) {
    width: 1760px;
    max-width: 100vw;
  }
`;

const TitleText = styled.div`
  font-size: 24px;
  margin-left: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 235px;
`;
const NewColumnButton = styled.button`
  padding: 8px 24px;
  gap: 8px;
  width: 161px;
  height: 48px;
  background: transparent;
  border: 1px solid ${props => (props.disabled ? '#BDBDBD' : '#4F4F4F')};
  border-radius: 2px;
  color: ${props => (props.disabled ? '#BDBDBD' : '#4F4F4F')};
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  &:hover {
    background: ${props => (props.disabled ? 'transparent' : '#ffffff')};
    border: 1px solid ${props => (props.disabled ? '#BDBDBD' : '#333333')};
    color: ${props => (props.disabled ? '#BDBDBD' : '#333333')};
  }
`;
