import { Component } from 'react';

import PropTypes from 'prop-types';

import { certificates } from '../../../utils/enums';

const CERTIFICATES = certificates().map(cert => {
  return {
    value: cert.value,
    label: cert.label,
  };
});

export default class Certificates extends Component {
  static propTypes = {
    certificates_held: PropTypes.arrayOf(Object).isRequired,
    simpleList: PropTypes.bool,
    other_certificate_explanation: PropTypes.string,
  };

  getItem(list, value) {
    value = parseInt(value, 10);
    const option = list.find(o => o.value === value);
    return option && option.label;
  }

  // 70 is the enum for Other certificate
  certificateIsOther(certEnum) {
    return certEnum === 70;
  }

  render() {
    const certificates_held = this.props.certificates_held;

    if (this.props.simpleList) {
      return (
        <span>
          {certificates_held.map((row, i) => (
            <span key={i}>
              {this.getItem(CERTIFICATES, row)}
              {i !== certificates_held.length - 1 ? ', ' : ''}
            </span>
          ))}
        </span>
      );
    }

    return (
      <div>
        {certificates_held.map((row, i) => (
          <div className="added-row" key={i}>
            <span className="flex-2">
              {this.getItem(CERTIFICATES, row)}
              {this.certificateIsOther(row) && this.props?.other_certificate_explanation
                ? ` - ${this.props.other_certificate_explanation}`
                : ''}
            </span>
          </div>
        ))}
      </div>
    );
  }
}
