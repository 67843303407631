import { useEffect, useState } from 'react';

import {
  ActivityFeedContainer,
  ButtonContainer,
  HeaderContainer,
  LoadingBox,
  ViewMoreButton,
} from './styles';
import { ActivityFeedProps } from './types';
import { ActivityFeedRow } from './ActivityFeedRow';
import { activityOptions } from './utils';
import { Box, CircularProgress, Typography } from '@mui/material';
import { MultiSelectWithCheckmarks } from 'sharedComponents/Select/MultiSelectWithCheckmarks';
import { nimbleTheme } from 'theme';

export const ActivityFeed: React.FC<ActivityFeedProps> = ({
  allActivity,
  hasActivity,
  hasErrorFetchingActivity,
  isLoading,
  jobName,
}) => {
  const [activityTypeFilters, setActivityTypeFilters] = useState<string[]>(activityOptions);
  const [sortedActivity, setSortedActivity] = useState(allActivity);
  const [endingIndex, setEndingIndex] = useState(3);

  const slicedOptions = sortedActivity?.slice(0, endingIndex);
  const viewMoreIncrement = 3;
  const hasSameNumberOptionsToRenderAsAllFetchedActivity =
    slicedOptions?.length === allActivity?.length;
  const hasNoActiveFilters = activityTypeFilters.length === 0;
  const hasMoreSortedActivityThanEndingIndex = sortedActivity.length <= endingIndex;
  const shouldScroll = slicedOptions.length > 3;

  const shouldViewMoreButtonBeDisabled =
    hasSameNumberOptionsToRenderAsAllFetchedActivity ||
    hasNoActiveFilters ||
    hasMoreSortedActivityThanEndingIndex;

  useEffect(() => {
    sortActivityFeedByType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityTypeFilters, hasActivity, allActivity]);

  const sortActivityFeedByType = () => {
    const nonChronologicalActivities = [];

    for (let i = 0; i < activityTypeFilters?.length; i++) {
      const option = activityTypeFilters[i].toLowerCase();

      for (let j = 0; j < allActivity?.length; j++) {
        const activity = allActivity[j];
        const activityVerb = allActivity[j].verb;

        if (activityVerb === option) {
          nonChronologicalActivities.push(activity);
        }
      }
    }

    const sortedActivityFeedByLatest = nonChronologicalActivities.sort((a, b) => b.id - a.id);
    setSortedActivity(sortedActivityFeedByLatest);
  };

  const viewMoreOnClick = () => setEndingIndex(endingIndex + viewMoreIncrement);

  return (
    <>
      <HeaderContainer>
        <Typography
          data-testid="activity-feed-title"
          variant="subtitle"
          sx={{ color: nimbleTheme.palette.text.primary }}
        >{`Activity Feed (${jobName})`}</Typography>
        <MultiSelectWithCheckmarks
          options={activityOptions}
          selectedOptions={activityTypeFilters}
          setSelectedOptions={setActivityTypeFilters}
          placeholder="All Activity Types"
          sx={{
            borderRadius: nimbleTheme.shape.borderRadius * 0.5,
            height: nimbleTheme.spacing(4),
          }}
        />
      </HeaderContainer>

      <Box data-testid="activity-feed">
        <ActivityFeedContainer shouldScroll={shouldScroll}>
          {isLoading && (
            <LoadingBox>
              <CircularProgress />
            </LoadingBox>
          )}
          {!hasActivity && <p data-testid="no-activity-messaging">No activities yet.</p>}
          {hasErrorFetchingActivity && (
            <p data-testid="error-messaging">An error occurred. Please refresh the page.</p>
          )}
          {slicedOptions?.map(activity => (
            <ActivityFeedRow activity={activity} key={activity.id} />
          ))}
          <ButtonContainer>
            <ViewMoreButton
              disabled={shouldViewMoreButtonBeDisabled}
              onClick={viewMoreOnClick}
              data-testid="view-more-button"
            >
              View more activity
            </ViewMoreButton>
          </ButtonContainer>
        </ActivityFeedContainer>
      </Box>
    </>
  );
};
