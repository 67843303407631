import styled from 'styled-components';

import QuestionBox from '../common/QuestionBox';

import { questionType } from 'utils/enums';

export default function AddIndividualItems({
  unchosenNimbleQuestions,
  openQuestionModal,
  jobQuestionsAndAttachmentsAndSets,
}) {
  return (
    <>
      <HeaderText>Add Individual Items</HeaderText>
      <div className="question-type-container mb4">
        <QuestionBox
          handleClick={openQuestionModal}
          label="Direction Text"
          type={questionType.direction_text}
        />
        {unchosenNimbleQuestions.length > 0 && (
          <QuestionBox
            handleClick={openQuestionModal}
            label="Nimble Question"
            type={questionType.nimble}
          />
        )}
        <QuestionBox
          handleClick={openQuestionModal}
          label="Text Question"
          type={questionType.open_response}
        />
        <QuestionBox
          handleClick={openQuestionModal}
          label="Yes/No Question"
          type={questionType.yes_no}
        />
        <QuestionBox
          handleClick={openQuestionModal}
          label="Multiple Choice Question"
          type={questionType.multiple_choice}
        />
        <QuestionBox
          handleClick={openQuestionModal}
          label="Attachment"
          type={questionType.attachment}
        />
        {/* Only show button if no school preferences question exists */}
        {!jobQuestionsAndAttachmentsAndSets.find(
          q => q.question_type === questionType.schoolPreferences
        ) && (
          <QuestionBox
            handleClick={openQuestionModal}
            label="School Preferences"
            type={questionType.schoolPreferences}
          />
        )}
        <QuestionBox
          handleClick={openQuestionModal}
          label="Video Link"
          type={questionType.videoLink}
        />
        <QuestionBox
          handleClick={openQuestionModal}
          label="Statement Checkbox"
          type={questionType.statementCheckbox}
        />
      </div>
    </>
  );
}

const HeaderText = styled.p`
  margin: 3rem 0 0.8rem;
  font-size: 16px;
  font-weight: 600;
  color: #444444;
`;
