import styled from 'styled-components';
import PropTypes from 'prop-types';

import CandidateTasksRow from './CandidateTasksRow';
import CandidateSignerTasks from 'features/HellosignEmbeddedSigning/CandidateSignerTasks';

export default function CandidateTasks({ user, updateCandidateTask }) {
  return (
    <div
      className="candidate-tasks-container"
      style={{ border: '1px solid #e0e0e0', borderRadius: '4px', padding: '16px' }}
    >
      <HeaderLabel>Tasks</HeaderLabel>
      {user.tasks.map(task => (
        <CandidateTasksRow
          key={task.id}
          task={task}
          user={user}
          updateCandidateTask={updateCandidateTask}
        />
      ))}
      <CandidateSignerTasks candidate={user} />
    </div>
  );
}

CandidateTasks.propTypes = {
  user: PropTypes.object.isRequired,
  updateCandidateTask: PropTypes.func.isRequired,
};

const HeaderLabel = styled.h4`
  color: #444444;
  font-size: 19px;
  font-weight: 400;
  margin-bottom: 1rem;
`;
