import auth from '../../../../utils/auth';
import EditJobSelectScorecard from '../../../../components/JobEdit/EditJobSelectScorecard';
import InternalRoleNotes from '../../../../components/JobEdit/InternalRoleNotes';
import { SubLabelText } from './TabsShareComponents';
import { CustomScoreCard, InternalRoleNote, User } from 'types/types';
import { ScorecardsTabProps } from '../../types/types';
import InternalRoleNotesAPI from '../../../../api/internalRoleNotesAPI';
import { Input } from 'ui-kit';
import styled from 'styled-components';
import { IInput } from './StartTab';

export default function ScorecardsTab({
  job,
  setJob,
  customScorecards,
  customScorecardsSelected,
  setCustomScorecardsSelected,
  districtAndSuperAdmins,
  setUnsubmittedInternalNote,
  updateField,
}: ScorecardsTabProps): React.ReactElement {
  const saveSelectedScorecards = (customScorecardsSelected: CustomScoreCard[]) => {
    setCustomScorecardsSelected(customScorecardsSelected);
  };

  const adminUsers: User[] = [];

  districtAndSuperAdmins.forEach(userObj => {
    userObj['display'] = `${userObj['first_name']} ${userObj['last_name']}`;
    adminUsers.push(userObj);
  });

  const addNote = (newNote: InternalRoleNote) => {
    if (!Object.prototype.hasOwnProperty.call(newNote, 'id')) {
      const jobCopy = { ...job };
      jobCopy.internal_role_notes = [
        ...(job.internal_role_notes ? job.internal_role_notes : []),
        newNote,
      ];
      setJob(jobCopy);
    }
  };

  const deleteNote = (note: InternalRoleNote) => {
    InternalRoleNotesAPI.destroy(note, job.id, job.statuses_available).catch(err =>
      console.log(err)
    );
    return;
  };

  const updateNote = (note: InternalRoleNote, index: number) => {
    const internalRoleNotesCopy = job.internal_role_notes;
    // if new note has "is_deleted" flag, splice it out locally
    // if the deleted note has an "id" meaning it has already been posted, patch delete as well.
    if (note?.is_deleted) {
      internalRoleNotesCopy.splice(+index, 1);
      if (Object.prototype.hasOwnProperty.call(note, 'id')) {
        deleteNote(note);
      }
    } else {
      internalRoleNotesCopy[+index] = note;
    }
    setJob({ ...job, internal_role_notes: internalRoleNotesCopy });
    return;
  };

  const isPostingOnly = auth.inPostingOnlyDistrict();

  return (
    <Container isPostingOnly={isPostingOnly}>
      {isPostingOnly && (
        <div data-testid="where-to-apply-section">
          <Label>Where to apply</Label>
          <SubLabelText>
            In the field below, provide the candidate with the URL to this job posting{' '}
            <strong>or to your careers page, or an email address</strong> where they can learn more
            and apply for this job
          </SubLabelText>
          <StyledInput
            id="jobedit_link_to_ats"
            name="link_to_ats"
            type="text"
            placeholder="Where the candidate will apply"
            value={job.link_to_ats}
            onChange={updateField}
            width={1}
          />
        </div>
      )}
      <div>
        {!isPostingOnly && (
          <EditJobSelectScorecard
            customScorecards={customScorecards}
            customScorecardsSelected={customScorecardsSelected}
            onSave={saveSelectedScorecards}
          />
        )}
        {!job.is_template && (
          <>
            <Label>Internal Notes</Label>
            <div className="form-field jobedit-textarea" id="internal_notes">
              <InternalRoleNotes
                notes={job.internal_role_notes || []}
                setUnsubmittedInternalNote={(n: InternalRoleNote) => setUnsubmittedInternalNote(n)}
                addNote={addNote}
                updateNote={updateNote}
                adminUsers={adminUsers}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  );
}

const StyledInput = styled(Input)<IInput>``;

interface ContainerProps {
  isPostingOnly: boolean;
}

const Container = styled.div<ContainerProps>`
  padding: ${props => (props.isPostingOnly ? '0 48px 48px 48px' : '0px 48px 48px')};
  max-width: 1200px;
  margin: auto;
`;

export const Label = styled.h5`
  font-size: 16px;
  font-weight: 600;
  color: #444444;
  margin: 0 0 0.8em;
`;
