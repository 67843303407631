import PropTypes from 'prop-types';

import Dropdown from 'react-dropdown';
import { prospectStatus } from '../../utils/prospectenums';

const prospectStatusObject = prospectStatus().reduce((obj, item) => {
  obj[item.value] = {
    color: item.color,
    label: item.label,
  };
  return obj;
}, {});

const ProspectStatusSwitcher = ({ candidate, updateProspectStatus }) => {
  const statusObject = prospectStatusObject[candidate.status];

  const updateStatus = option => {
    if (option.value === statusObject.value) {
      return;
    } else {
      updateProspectStatus(option, candidate.prospect_id);
    }
  };

  return (
    <div className="state-toggle status-select">
      <Dropdown
        options={prospectStatus()}
        onChange={option => updateStatus(option)}
        value={statusObject.label}
        className={`new-status-dropdown ${statusObject.color}`}
      />
    </div>
  );
};

ProspectStatusSwitcher.propTypes = {
  candidate: PropTypes.object.isRequired,
  updateProspectStatus: PropTypes.func.isRequired,
};

export default ProspectStatusSwitcher;
