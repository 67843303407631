import { useEffect, useState } from 'react';
// import districtsAPI from 'api/districtsAPI';
// import schoolsAPI from 'api/schoolsAPI';
import { FilterOptions } from './types';
import {
  distanceFilterOptions,
  gradeFilterOptions,
  gradePreferencesOptions,
  subjectFilterOptions,
  subjectPreferencesOptions,
} from 'features/MarketplaceV2/features/JobDiscovery/constants';

interface FilterAndLoadingOptions extends FilterOptions {
  isLoadingFilterOptions: boolean;
}

export const useMarketplaceFilterOptions = (): FilterAndLoadingOptions => {
  // const [districtOptions, setDistrictOptions] = useState([]);
  // const [schoolOptions, setSchoolOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [distanceOptions, setDistanceOptions] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const path = window.location.pathname;
  const isUserPreferences = path.includes('/preferences');

  useEffect(() => {
    // districtsAPI.fetchDistrictsByState(stateCode).then(districts => {
    //   setDistrictOptions(districts.map(d => ({ value: d.id, label: d.name })));
    // });

    // schoolsAPI.fetchSchoolsByState(stateCode).then(schools => {
    //   setSchoolOptions(schools.map(s => ({ value: s.id, label: s.name })));
    // });

    setDistanceOptions(distanceFilterOptions);
    setSubjectOptions(isUserPreferences ? subjectPreferencesOptions : subjectFilterOptions);
    setGradeOptions(isUserPreferences ? gradePreferencesOptions : gradeFilterOptions);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    // districtOptions,
    // schoolOptions,
    subjectOptions,
    distanceOptions,
    gradeOptions,
    isLoadingFilterOptions: isLoading,
  };
};
