import { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import paperClip from '../../../assets/icon-paperclip.svg';

const propTypes = {
  file: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.element,
};

class FileViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  close = () => {
    this.setState({ showModal: false });
  };

  open = () => {
    this.setState({ showModal: true });
  };

  render() {
    let file = this.props.file;
    if (!file) {
      return (
        <div className="file-line-item">
          <span>No File Uploaded</span>
        </div>
      );
    }

    let fileSuffix = file.split('.').pop();
    const imageBool =
      fileSuffix.slice(0, 3) === 'jpg' ||
      fileSuffix.slice(0, 3) === 'png' ||
      fileSuffix.slice(0, 4) === 'jpeg';

    let fileUrl = imageBool ? file : encodeURIComponent(file);

    // doc and docx have to be viewed with office software
    if (fileSuffix.slice(0, 3) === 'doc') {
      file = `https://view.officeapps.live.com/op/view.aspx?src=${fileUrl}&wdAccPdf=0`;
    }

    return (
      <Container>
        <a href={file} rel="noopener noreferrer" target="_blank">
          <div className="file">
            <div className="icon-and-file-name">
              {this.props.icon || <img alt="icon-paperclip" src={paperClip} />}
              {this.props.title ? `View ${this.props.title}` : 'View File Upload'}
            </div>
          </div>
        </a>
      </Container>
    );
  }
}

// eslint-disable-next-line
FileViewer.propTypes = propTypes;

export default FileViewer;

const Container = styled.div`
  min-height: 37px;
  padding: 9px 1rem;
  margin-bottom: 6px;
  background-color: #eeeff2;
  color: #777777;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  .icon-and-file-name {
    display: flex;
    align-items: center;
  }

  div {
    color: #777777;
  }

  img,
  svg,
  span {
    margin-right: 8px;
    width: 18px;
  }
`;
