import { useState } from 'react';
import styled from 'styled-components';

import useForm from '../../../../hooks/useForm';
import signupForm from './SignupFormValidationRules';
import { ATSSignUpDataTestIds } from '../../../../data-testids/ATS';

function SignupForm({ onRegister }) {
  const [globalError, setGlobalError] = useState(null);

  const onSubmit = async () => {
    const district = '';
    try {
      await onRegister(fname.value, lname.value, district, email.value, password.value);
    } catch (error) {
      setGlobalError(error);
    }
  };

  const { isFormValid, values, handleChange, handleSubmit } = useForm(signupForm, onSubmit);
  const { fname, lname, email, emailConfirm, password, passwordConfirm } = values;

  return (
    <div className="flex form-name-container" style={{ flexWrap: 'wrap' }}>
      <div className="form-entry half">
        <label>
          <input
            name="fname"
            type="text"
            data-testid={ATSSignUpDataTestIds.FIRST_NAME_INPUT}
            placeholder="First name"
            value={fname.value || ''}
            required
            onChange={handleChange}
            className="first-name"
          />
        </label>
        <StyledErrorText>
          {fname.errorMessage && !fname.valid && fname.errorMessage}
        </StyledErrorText>
      </div>
      <div className="form-entry half">
        <label>
          <input
            name="lname"
            type="text"
            data-testid={ATSSignUpDataTestIds.LAST_NAME_INPUT}
            placeholder="Last name"
            value={lname.value || ''}
            required
            onChange={handleChange}
            className="last-name"
          />
        </label>
        <StyledErrorText>
          {lname.errorMessage && !lname.valid && lname.errorMessage}
        </StyledErrorText>
      </div>
      <div className="form-entry">
        <label>
          <input
            name="email"
            type="email"
            data-testid={ATSSignUpDataTestIds.EMAIL_INPUT}
            placeholder="Email address"
            value={email.value || ''}
            required
            onChange={handleChange}
            className="email-address"
          />
        </label>
      </div>
      <div className="form-entry">
        <label>
          <input
            name="emailConfirm"
            type="email"
            data-testid={ATSSignUpDataTestIds.EMAIL_CONFIRM_INPUT}
            placeholder="Confirm email address"
            value={emailConfirm.value || ''}
            required
            onChange={handleChange}
            className="email-address"
          />
          <StyledErrorText>
            {(email.errorMessage && !email.valid && email.errorMessage) ||
              (emailConfirm.errorMessage && !emailConfirm.valid && emailConfirm.errorMessage)}
          </StyledErrorText>
        </label>
      </div>
      <div className="form-entry">
        <label>
          <input
            name="password"
            type="password"
            data-testid={ATSSignUpDataTestIds.PASSWORD_INPUT}
            placeholder="Password"
            value={password.value || ''}
            pattern=".{6,}"
            required
            onChange={handleChange}
            className="password"
          />
        </label>
      </div>
      <div className="form-entry">
        <label>
          <input
            name="passwordConfirm"
            type="password"
            data-testid={ATSSignUpDataTestIds.PASSWORD_CONFIRM_INPUT}
            placeholder="Confirm password"
            value={passwordConfirm.value || ''}
            required
            onChange={handleChange}
            className="password-confirm"
          />
          <StyledErrorText>
            {(password.errorMessage && !password.valid && password.errorMessage) ||
              (passwordConfirm.errorMessage &&
                !passwordConfirm.valid &&
                passwordConfirm.errorMessage)}
          </StyledErrorText>
        </label>
      </div>
      <StyledInput
        className={`btn ${isFormValid() ? 'btn-success' : 'btn-disabled'}`}
        type="submit"
        data-testid={ATSSignUpDataTestIds.SUBMIT_BUTTON}
        onClick={handleSubmit}
        value="Sign up"
        disabled={!isFormValid()}
      />
      {globalError && (
        <GlobalError data-testid={ATSSignUpDataTestIds.ERROR_TEXT}>{globalError}</GlobalError>
      )}
    </div>
  );
}

export default SignupForm;

const StyledErrorText = styled.p`
  margin-left: 8px;
  font-size: 12px;
  height: 1.5em;
  font-weight: normal;
  color: #00b88d;
`;

const StyledInput = styled.input`
  margin-bottom: 1em;
`;

const GlobalError = styled.p`
  margin: -18px 8px 0px 8px;
  font-size: 12px;
  margin-bottom: 1em;
  font-weight: normal;
  color: #00b88d;
`;
