import { Component } from 'react';

import axios from 'axios';
import auth from '../utils/auth';

import { MultiSelect } from 'ui-kit';
import JobEditGrades from '../components/JobEdit/jobeditgrades';
import ThreeButtonSelect from '../components/common/ThreeButtonSelect';
import PageNotFound from '../components/PageNotFound';
import { PreferencesV2 } from '../features/ProfilePreference/components/Preferences';
import ProfilePreferenceBar from '../features/ProfilePreference/components/ProfilePreferenceProgressBar';
import { employmentTypePreferences, preferenceCategories, preferenceOptions } from '../utils/enums';
import { withRouter } from 'react-router-dom';
import BasicLocationPreferences from '../components/LocationPreferences/basicLocationPreferences';
import LocationPreferences from '../components/LocationPreferences';
import { showTotalFailure } from '../utils/message';
import locationPreferencesAPI from '../api/locationPreferencesAPI';
import { getOrSetPreferenceFlag } from '../utils/preferenceConfig';
import { ATSCandidatePreferencesDataTestIds } from '../data-testids/ATS';

const preferenceMap = preferenceOptions().reduce((obj, item) => {
  obj[item.key] = item.value;
  return obj;
}, {});

const employmentTypeMap = employmentTypePreferences().reduce((obj, item) => {
  obj[item.key] = item.value;
  return obj;
}, {});

class Preferences extends Component {
  constructor(props) {
    super(props);
    let combinedPreferenceAndProfile = false;
    this.state = {
      combinedPreferenceAndProfile,
      preference: {},
      display404Page: false,
      saveSpinner: false,
      checked: false,
      showMap: false,
      showMapInputError: false,
      showMapSelectError: false,
      mapUrl: '',
      isDirty: false,
    };
    this.getUser = auth.getUser();
  }

  async componentDidMount() {
    this.fetchPreference();
    const combinedPreferenceAndProfile = await getOrSetPreferenceFlag();
    this.setState({ combinedPreferenceAndProfile });
    document.body.classList.add('preferences');
  }

  componentWillUnmount() {
    document.body.classList.remove('preferences');
  }

  fetchPreference = () => {
    axios
      .get(`/api/preferences/${this.getUser.id}/`)
      .then(r => {
        this.setState({ preference: r.data, checked: r.data.visible_outside_radius });
        if (r.data.location && r.data.miles_within) {
          this.fetchMapUrl(
            {
              address: r.data.location,
              radius: r.data.miles_within,
            },
            true
          );
        }
      })
      .catch(err => {
        if (err.response && err.response.status === 404) {
          this.setState({ display404Page: true });
        } else {
          showTotalFailure(err.response && err.response.statusText);
        }
      });
  };

  submit = () => {
    this.setState({ saveSpinner: true });
    axios
      .put(`/api/preferences/${this.getUser.id}/`, this.state.preference)
      .then(this.redirect)
      .catch(err => showTotalFailure(err.response && err.response.statusText));
  };

  handleCheckboxChange = async () => {
    const data = {
      visible_outside_radius: !this.state.checked,
    };
    const response = await locationPreferencesAPI.updatePreferences(data, this.getUser.id);
    if (response[0] === 200) {
      this.setState({ checked: response[1].visible_outside_radius });
      let preference = Object.assign({}, this.state.preference);
      preference.visible_outside_radius = response[1].visible_outside_radius;
      this.setState({ preference });
    }
  };

  handleChange = e => {
    let preference = Object.assign({}, this.state.preference);
    preference[e.target.name] = e.target.value;

    // special case for hours per week. if the candidate chooses "full-time", the hours
    // per week dropdown should default to 'N/A'
    if (e.target.name === 'employment_type' && e.target.value === employmentTypeMap.full_time) {
      preference.hours_per_week = 'N/A';
    }
    if (e.target.name === 'miles_within') {
      if (e.target.value !== '') {
        this.setState({ showMapSelectError: false });
      } else {
        this.setState({ showMapSelectError: true });
      }
    }
    this.setState({ preference: preference, isDirty: true });
  };

  handleAddressSearch = async (event, address, radius) => {
    const data = {
      address: address,
      radius: radius,
    };

    if (this.state.isDirty) {
      this.fetchMapUrl(data, false);
      this.setState({ isDirty: false });
    }
  };

  handleAddressSearchOnEnter = async (event, address, radius) => {
    if (event.key === 'Enter') {
      this.handleAddressSearch(event, address, radius);
    }
  };

  fetchMapUrl = async (data, initial) => {
    const response = await locationPreferencesAPI.getMapUrl(data);
    if (response[0] === 200) {
      const showMap = response[1]?.map_url ? true : false;
      this.setState({
        showMapInputError: false,
        showMapSelectError: false,
        mapUrl: response[1].map_url,
        showMap: showMap,
      });
    } else {
      const showErrorInput =
        response[1].error === 'invalid address' || response[1].error === 'address is required'
          ? true
          : false;
      const showErrorSelect = data.radius === '' ? true : false;
      if (!initial) {
        this.setState({
          showMapInputError: showErrorInput,
          showMapSelectError: showErrorSelect,
          showMap: false,
          mapUrl: '',
        });
      }
    }
  };

  updateMultiSelect = (values, fieldName) => {
    let preference = Object.assign({}, this.state.preference);
    preference[fieldName] = values.map(value => value.value);
    this.setState({ preference });
  };

  redirect = () => {
    if (this.state.combinedPreferenceAndProfile) {
      this.props.history.push(`/applicant-profile/edit-profile`);
    } else {
      this.props.history.push('candidate-dashboard?preferences=t');
    }
  };

  render() {
    if (this.state.display404Page) {
      return <PageNotFound />;
    }

    let { preference } = this.state;

    return (
      <>
        {this.state.combinedPreferenceAndProfile ? (
          <PreferencesV2
            preference={this.state.preference}
            setPreference={p => this.setState({ preference: p })}
            onSubmit={this.submit}
            combinedPreferenceAndProfile={this.state.combinedPreferenceAndProfile}
            handleCheckboxChange={this.handleCheckboxChange}
            showMap={this.state.showMap}
            setShowMap={showMap => this.setState({ showMap })}
            showMapInputError={this.state.showMapInputError}
            setShowMapInputError={showMapInputError => this.setState({ showMapInputError })}
            showMapSelectError={this.state.showMapSelectError}
            setShowMapSelectError={showMapSelectError => this.setState({ showMapSelectError })}
            mapUrl={this.state.mapUrl}
            setMapUrl={mapUrl => this.setState({ mapUrl })}
          />
        ) : (
          <div className="preferences-container">
            <div className="section">
              <h4 data-testid={ATSCandidatePreferencesDataTestIds.TITLE_TEXT}>Job Preferences</h4>
            </div>
            <LocationPreferences
              {...{
                milesWithin: preference.miles_within,
                location: preference.location,
                checked: this.state.checked,
                handleChange: this.handleChange,
                handleChangeSelect: this.handleChange,
                showMap: this.state.showMap,
                showMapErrorInput: this.state.showMapInputError,
                showMapErrorSelect: this.state.showMapSelectError,
                mapUrl: this.state.mapUrl,
                combinedPreferenceAndProfile: this.state.combinedPreferenceAndProfile,
                hadleSearchButton: this.handleAddressSearch,
                handleAddressSearchOnEnter: this.handleAddressSearchOnEnter,
                handleCheckboxChange: this.handleCheckboxChange,
              }}
            />
            <div className="section">
              <div className="three-button">
                <p className="flex-50">
                  <b>Do you prefer to work in a multilingual school?</b>
                </p>
                <ThreeButtonSelect
                  object={preference}
                  fieldName={'multilingual_school'}
                  enumObject={preferenceMap}
                  handleChange={this.handleChange}
                  firstButton={{
                    label: 'No Preference',
                    enumField: 'no_preference',
                  }}
                  secondButton={{
                    label: 'Yes',
                    enumField: 'yes',
                  }}
                  thirdButton={{
                    label: 'No',
                    enumField: 'no',
                  }}
                />
              </div>
              <div className="three-button">
                <p className="flex-50">
                  <b>Do you prefer to work in a title 1 school?</b>
                </p>
                <ThreeButtonSelect
                  object={preference}
                  fieldName={'title_1_school'}
                  enumObject={preferenceMap}
                  handleChange={this.handleChange}
                  firstButton={{
                    label: 'No Preference',
                    enumField: 'no_preference',
                  }}
                  secondButton={{
                    label: 'Yes',
                    enumField: 'yes',
                  }}
                  thirdButton={{
                    label: 'No',
                    enumField: 'no',
                  }}
                />
              </div>
              <div className="three-button">
                <p className="flex-50">
                  <b>Do you prefer to work in a turnaround school?</b>
                </p>
                <ThreeButtonSelect
                  object={preference}
                  fieldName={'turnaround_school'}
                  enumObject={preferenceMap}
                  handleChange={this.handleChange}
                  firstButton={{
                    label: 'No Preference',
                    enumField: 'no_preference',
                  }}
                  secondButton={{
                    label: 'Yes',
                    enumField: 'yes',
                  }}
                  thirdButton={{
                    label: 'No',
                    enumField: 'no',
                  }}
                />
              </div>
            </div>
            <div className="section">
              <div className="three-button">
                <p className="flex-50">
                  <b>Do you prefer full-time or part-time positions?</b>
                </p>
                <ThreeButtonSelect
                  object={preference}
                  fieldName={'employment_type'}
                  enumObject={employmentTypeMap}
                  handleChange={this.handleChange}
                  firstButton={{
                    label: 'No Preference',
                    enumField: 'no_preference',
                  }}
                  secondButton={{
                    label: 'Full Time',
                    enumField: 'full_time',
                  }}
                  thirdButton={{
                    label: 'Part Time',
                    enumField: 'part_time',
                  }}
                />
              </div>
              <div className="three-button hours-per-week">
                <p className="flex-50">
                  <b>How many hours per week?</b>
                </p>
                <div className="select-form-field">
                  <select
                    type="text"
                    className="pointer"
                    name="hours_per_week"
                    value={preference.hours_per_week}
                    onChange={this.handleChange}
                    // can't select hours if employment type is "full-time"
                    disabled={preference.employment_type === employmentTypeMap.full_time}
                  >
                    <option value="">Select</option>
                    {['0-10', '10-15', '15-25', '25-30', '30-35', 'N/A'].map((i, key) => (
                      <option value={i} key={key}>
                        {i}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="section">
              <h4 className="mb2">Categories of Interest</h4>
              <MultiSelect
                boxShadow="0 2px 2px 0 rgba(0, 0, 0, 0.1)"
                options={preferenceCategories()}
                value={preferenceCategories().filter(category =>
                  preference.categories?.includes(category.value)
                )}
                onChange={values => this.updateMultiSelect(values ?? [], 'categories')}
              />
            </div>
            <div className="section">
              <h4 className="mb2">Grade Levels of Interest</h4>
              <JobEditGrades
                grades={preference.grades || []}
                onChange={values => this.updateMultiSelect(values, 'grades')}
              />
              <div className="buttons-div">
                {this.state.saveSpinner && (
                  <i className="fa fa-circle-o-notch fa-3x fa-fw fast-spin" />
                )}
                <button className="cancel" onClick={this.redirect}>
                  Cancel
                </button>
                <button className="save" onClick={this.submit} disabled={this.state.saveSpinner}>
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(Preferences);
