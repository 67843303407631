import { SxProps } from '@mui/system';
import { Inter } from '../../JobDiscovery/utils';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

const RadioCardToggle: React.FC<{
  text: string;
  radioValue: string | boolean;
  selectedValue: string | boolean;
  width?: number | string;
  height?: number | string;
  fontSize?: number | string;
  disabled?: boolean;
  sx?: SxProps;
}> = ({ text, radioValue, selectedValue, width, height, fontSize, disabled = false, sx }) => {
  const defaultSx = {
    width: width || 242,
    height: height || 82,
    border: `2px solid ${selectedValue === radioValue ? '#45C3A5' : '#EAECF0'}`,
    borderRadius: '12px',
    padding: '0px 8px',
    color: '#101828',
    fontFamily: Inter,
    fontSize: fontSize || '1rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
  };

  return (
    <FormControlLabel
      value={radioValue}
      label={text}
      control={<Radio />}
      disabled={disabled}
      sx={{ ...defaultSx, ...sx }}
    />
  );
};

export default RadioCardToggle;
