import styled from 'styled-components';

import ReactTooltip from 'react-tooltip';
import greenCheckMark from 'assets/icons/ReferenceIcons/green_check.svg';
import remindIcon from 'assets/icons/icon_remind.svg';
import { ReferenceRequestsProps } from './types';

const ReferenceRequests: React.FC<ReferenceRequestsProps> = ({ onRequest, requests }) => {
  return (
    <Container>
      <h4 className="mb1">{`${requests.length} Pending Request${
        requests.length === 1 ? '' : 's'
      }`}</h4>
      <ul className="list-unstyled">
        {requests.map(request => {
          return (
            <li className="mbhalf" key={request.id}>
              <img src={greenCheckMark} alt="" />
              &nbsp;&nbsp;Request for {request.email} sent on{' '}
              {new Date(request.created).toLocaleString()}{' '}
              <span className="hover-icon-set">
                <span
                  onClick={() =>
                    onRequest({
                      recipients: [{ email: request.email }],
                      reference_template_id: request.reference_template_id,
                      message: null,
                      subject: null,
                      is_reminder: true,
                      reference_id: request.reference_id,
                    })
                  }
                  data-tip
                  data-for="remind"
                  data-place="bottom"
                  data-delay-show="100"
                >
                  <img src={remindIcon} alt="edit" />
                  <ReactTooltip id="remind" effect="solid">
                    <span>Remind</span>
                  </ReactTooltip>
                </span>
              </span>
            </li>
          );
        })}
      </ul>
    </Container>
  );
};

export default ReferenceRequests;

const Container = styled.div`
  margin-top: 2rem;
`;
