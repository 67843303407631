import useSWR from 'swr';

import dashboardMetricsAPI from 'api/dashboardMetricsAPI';

const ENDPOINT = '/api/dashboard-metrics/hiring-conversions/';

const defaultData = {
  prospects: {
    pass_rate: {
      sourced: 0,
      contacted: 0,
      converted: 0
    },
    pass_time: {
      outreach: 0.0,
      contacted: 0.0,
      converted: 0.0
    }
  },
  applications: {
    pass_rate: {
      applied: 0,
      hiring_pool: 0,
      hired: 0
    },
    pass_time: {
      screened: 0.0,
      hiring_pool: 0.0,
      hired: 0.0
    }
  }
};

/**
 * Data management wrapper for fetching/caching conversion metrics.
 *
 * Usage: const { data, isLoading, error } = useHiringConversionMetrics(filters);
 */
export const useHiringConversionMetrics = ({
  hiringSeasons,
  schools = [],
  subcategories = [],
  genders = [],
  ethnicities = []
}) => {
  // Using ref/memo doesn't work for this library... need to stringify our deps.
  const key = [
    ENDPOINT,
    hiringSeasons.toString(),
    schools.toString(),
    subcategories.toString(),
    genders.toString(),
    ethnicities.toString()
  ];
  const options = { dedupingInterval: 30000, focusThrottleInterval: 30000 };

  const { data, error } = useSWR(
    key,
    () =>
      dashboardMetricsAPI.getHiringConversionMetrics({
        hiringSeasons,
        schools,
        subcategories,
        genders,
        ethnicities
      }),
    options
  );

  const isLoading = data === undefined;

  return { data: data ?? defaultData, isLoading, error };
};
