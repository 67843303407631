import styled from 'styled-components';
import EditIcon from 'assets/icons/AccountManagementIcons/edit-icon.svg';
import DraggableIcon from 'assets/icon-draggable.svg';
import JobQuestionRow from './JobQuestionRow';
import QuestionSetBadge from './components/QuestionSetBadge';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as DeleteIcon } from 'assets/icons/icon_trash.svg';
import { sortQuestionsAndAttachments } from '../../utils/roleutils';
import auth from '../../utils/auth';

export default function QuestionSetContainer({
  questionSet,
  internalRequirementsSpecified,
  deleteQuestionRow,
  openQuestionSetModal,
  isShowingQuestionSetEditOptions,
  handleQuestionSetEditOptions,
  isTemplate,
}) {
  /**
   * If the question set is global and the user hits the 'edit this job' option,
   * we know that they intend to create a Local Question Set instance.
   */
  const handleEditSingleQuestionSet = () => {
    if (questionSet.uuid && !questionSet.is_role_qs) {
      // user intends to create role question set
      openQuestionSetModal(questionSet.uuid, true);
      return;
    }
    openQuestionSetModal(questionSet.uuid);
  };

  return (
    <Container>
      <HeaderContainer>
        <Title>{questionSet.title}</Title>
        <ActionButtonContainer>
          <QuestionSetBadge questionSet={questionSet} />
          <div style={{ padding: isTemplate ? '16px' : '0px' }}>
            <DeleteButton
              onClick={deleteQuestionRow}
              title="Delete Button"
              data-tip
              data-for="remove-question-set"
            />
          </div>

          <ReactTooltip id="remove-question-set" effect="solid">
            <span>Delete</span>
          </ReactTooltip>
          {!isTemplate && (
            <EditContainer>
              <EditButton
                onClick={() => handleQuestionSetEditOptions(questionSet.uuid)}
                alt="Edit Button"
              >
                <StyledEditIcon
                  alt="edit-icon"
                  src={EditIcon}
                  data-tip
                  data-for="edit-question-set"
                />
                <ReactTooltip id="edit-question-set" effect="solid">
                  <span>Edit</span>
                </ReactTooltip>
              </EditButton>
              {isShowingQuestionSetEditOptions && (
                <IconDropdown>
                  <EditOption
                    onClick={handleEditSingleQuestionSet}
                    key={questionSet.uuid + '-edit-job'}
                    firstRow={true}
                  >
                    <EditText>Edit this job</EditText>
                  </EditOption>
                  {/* Spec: only show 'edit all' to super admin users AND when a question set is global */}
                  {auth.isSuperAdminUser() && !questionSet.is_role_qs && (
                    <EditOption
                      onClick={() => openQuestionSetModal(questionSet.uuid)}
                      key={questionSet.uuid + '-edit-all-jobs'}
                      firstRow={false}
                    >
                      <EditText>Edit all jobs</EditText>
                    </EditOption>
                  )}
                </IconDropdown>
              )}
            </EditContainer>
          )}
          <DraggableButton src={DraggableIcon} data-tip data-for="reorder-question-set" />
          <ReactTooltip id="reorder-question-set" effect="solid">
            <span>Reorder</span>
          </ReactTooltip>
        </ActionButtonContainer>
      </HeaderContainer>
      {sortQuestionsAndAttachments(questionSet.items).map((question, index) => (
        <div style={{ padding: '8px 0' }} key={index}>
          <JobQuestionRow
            obj={question}
            type={question.question_type}
            internal_requirements_specified={internalRequirementsSpecified}
          />
        </div>
      ))}
    </Container>
  );
}

const Container = styled.div`
  background: #eeeeee;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 16px;
`;

const Title = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
`;

const EditButton = styled.div`
  cursor: pointer;
  background: none;
  color: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  min-height: 15px;
  min-width: 17px;
`;

const DraggableButton = styled.img`
  cursor: pointer;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  padding-bottom: 8px;
`;

const EditContainer = styled.div`
  position: relative;
  margin: 0px 16px;
`;

const EditText = styled.div`
  margin-left: 5px;
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0px;
`;

const EditOption = styled.div`
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #616161;
  width: 82px;
  height: 24px;
  border-top: ${props => (props.firstRow ? '0px' : '1px solid rgba(0, 0, 0, 0.1)')};
`;

const StyledEditIcon = styled.img`
  height: 100%;
`;

const IconDropdown = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 5px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  white-space: nowrap;
  cursor: pointer;
`;

const DeleteButton = styled(DeleteIcon)`
  grid-area: delete-button;
  justify-self: end;
  margin-right: 5px;
  margin-left: 16px;
  margin-top: 2px;
  cursor: pointer;
  min-width: 13px;
`;
