import { useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import ModalFooter from 'components/common/Modal/ModalFooter';
import { DirectionTextIcon } from 'ui-kit/icons';

export default function EditItemEditorModal({ show, onHide, saveQuestion, question }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [directions, setDirections] = useState(question.prompt);

  const onSave = () => {
    try {
      checkForErrors();
    } catch (error) {
      setErrorMessage(error.message);
      return;
    }
    question.is_required_external = false;
    saveQuestion({ ...question }, 'question_type/direction_text');
  };

  const checkForErrors = () => {
    if (directions.length === 0 || directions === '<p><br></p>') {
      throw new Error('Direction text cannot be blank');
    }
  };

  const questionTypeMapping = {
    key: 'direction_text',
    value: 5,
    label: 'Direction Text',
    icon: DirectionTextIcon,
    info: 'Gives the candidate clear directions on how to fill out this application',
  };
  const QuestionTypeIcon = questionTypeMapping.icon;

  const renderQuestionTypeSection = () => {
    return (
      <>
        <Header>Type directions here:</Header>
        <ReactQuillContainer>
          <ReactQuill
            value={directions || ''}
            onChange={value => {
              setDirections(value);
            }}
            placeholder="e.g. Complete and submit this form within 3 days of your interview"
          />
        </ReactQuillContainer>
      </>
    );
  };

  return (
    <Modal show={show} onHide={onHide} bsSize="large">
      <ModalHeader>
        <Title>Edit Item</Title>
        <Cancel onClick={onHide}>×</Cancel>
      </ModalHeader>
      <Modal.Body>
        <Body>
          <HeaderGrid>
            <QuestionTypeIcon width="40" height="40" />
            <div>
              <div className="bold">{questionTypeMapping.label}</div>
              <GreyText>{questionTypeMapping.info}</GreyText>
            </div>
          </HeaderGrid>
          {renderQuestionTypeSection()}
        </Body>
      </Modal.Body>
      <ModalFooter
        cancel={onHide}
        save={onSave}
        errorMessage={errorMessage}
        deleteButtonLabel="Delete item"
        cancelButtonLabel="Cancel"
        saveButtonLabel="Save item"
      />
    </Modal>
  );
}

EditItemEditorModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  saveQuestion: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
};

const Header = styled.p`
  font-size: 16px;
  color: #444444;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 6px;
`;

const ReactQuillContainer = styled.div`
  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    position: absolute;
    right: 0;
    width: 18px;
    height: 18px;
  }
`;

const ModalHeader = styled.div`
  height: 60px;
  border-bottom: 1px solid #e5e5e5;
  font-size: 17px;

  display: grid;
  grid-template-columns: 30px auto 30px 30px;
  align-items: center;
`;

const Title = styled.p`
  font-size: 17px;
  color: #444444;

  grid-column: 2 / span 1;
`;

const Cancel = styled.div`
  grid-column: 3 / span 2;
  height: 100%;

  color: #999999;

  border-left: 1px solid #d8d8d8;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

const HeaderGrid = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 210px;
  align-items: center;
  grid-gap: 1.5rem;
  margin-bottom: 3rem;
`;

const GreyText = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  color: #999999;
`;

const Body = styled.div`
  position: relative;
  background-color: #ffffff;

  padding: 30px;

  .quill {
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    border-color: #dcdcdc;
  }
`;
