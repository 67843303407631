import axios from 'axios';
import { showWarning } from './message';
import { getQueryStringValue } from 'utils/util';

/** Utitily functions for universal tasks */

export function fetchDistrictTasks(on_filter_page = false) {
  /** get list of tasks for request user's district.
   * If onFilterPage is true, only the title and id of the tasks will be returned. In that case, also
   * add a 'complete' and 'incomplete' option for each task.
   * @returns {object[]} (array of objects)
   */
  axios
    .get(`/api/tasks/`, { params: { on_filter_page } })
    .then(r => {
      // if a task has candidate_assigned equal to true, add in a placeholder object
      // for display purposes
      let universal_tasks = r.data;
      let task_list = [];
      if (!on_filter_page) {
        universal_tasks.forEach(task => {
          if (task.candidate_assigned === true) {
            task.assigned_users.unshift({ id: 0, name: 'Candidate' });
          }
        });
      } else {
        universal_tasks.forEach(t => {
          task_list.push(String(t.id));
          task_list.push(`-${t.id}`);
        });
      }

      this.setState({ universal_tasks });
      const f_T = getQueryStringValue('f_T');
      if (!f_T) {
        this.setState({ task_list });
      }
    })
    .catch(() =>
      showWarning(
        "Oops, there was a problem retrieving tasks. If you have any questions, please email support@hirenimble.com and we'll help you out right away.",
        20000
      )
    );
}
