import { BaseNimbleAPI } from './baseAPI';
import { stringify } from 'query-string';

class DashboardMetricsAPI extends BaseNimbleAPI {
  get config() {
    return {
      baseURL: '/api/dashboard-metrics',
      // Comma-separated params, due to length limitations.
      // If still too long in the future, we may need to use on POST to fetch
      paramsSerializer: function(params) {
        return stringify(params, { arrayFormat: 'comma' });
      }
    };
  }

  /**
   * Fetch a district's conversion metrics
   */
  async getHiringConversionMetrics({
    hiringSeasons,
    schools,
    subcategories,
    genders,
    ethnicities
  }) {
    const response = await this.get(`/hiring-conversions/`, {
      params: {
        ...(hiringSeasons && { hiring_seasons: hiringSeasons }),
        ...(schools && { schools }),
        ...(subcategories && { subcategories }),
        ...(genders && { genders }),
        ...(ethnicities && { ethnicities })
      }
    });
    return response.data;
  }

  /**
   * Fetch a district's conversion metrics
   */
  async getOnboardingConversionMetrics({
    hiringSeasons,
    schools,
    subcategories,
    genders,
    ethnicities
  }) {
    const response = await this.get(`/onboarding-conversions/`, {
      params: {
        ...(hiringSeasons && { hiring_seasons: hiringSeasons }),
        ...(schools && { schools }),
        ...(subcategories && { subcategories }),
        ...(genders && { genders }),
        ...(ethnicities && { ethnicities })
      }
    });
    return response.data;
  }

  /**
   * Fetch a district's applicant source metrics
   */
  async getApplicantSourceMetrics({
    hiringSeasons,
    schools,
    subcategories,
    genders,
    ethnicities,
    statuses
  }) {
    const response = await this.get(`/applicant-source/`, {
      params: {
        ...(hiringSeasons && { hiring_seasons: hiringSeasons }),
        ...(schools && { schools }),
        ...(subcategories && { subcategories }),
        ...(genders && { genders }),
        ...(ethnicities && { ethnicities }),
        ...(statuses && { statuses })
      }
    });
    return response.data;
  }

  /**
   * Fetch a district's applicant source metrics
   */
  async getApplicantDiversityMetrics({ hiringSeasons, schools, subcategories, statuses }) {
    const response = await this.get(`/applicant-diversity/`, {
      params: {
        ...(hiringSeasons && { hiring_seasons: hiringSeasons }),
        ...(schools && { schools }),
        ...(subcategories && { subcategories }),
        ...(statuses && { statuses })
      }
    });
    return response.data;
  }

  /**
   * Fetch a district's applicant volume metrics
   */
  async getApplicantVolumeMetrics({ hiringSeasons, schools, subcategories, genders, ethnicities }) {
    const response = await this.get(`/applicant-volume/`, {
      params: {
        ...(hiringSeasons && { hiring_seasons: hiringSeasons }),
        ...(schools && { schools }),
        ...(subcategories && { subcategories }),
        ...(genders && { genders }),
        ...(ethnicities && { ethnicities })
      }
    });
    return response.data;
  }

  /**
   * Fetch a district's applications per vacancy metrics
   */
  async getApplicationsPerVacancyMetrics({ hiringSeasons, schools, subcategories }) {
    const response = await this.get(`/applications-per-vacancy/`, {
      params: {
        ...(hiringSeasons && { hiring_seasons: hiringSeasons }),
        ...(schools && { schools }),
        ...(subcategories && { subcategories })
      }
    });
    return response.data;
  }

  async downloadPipelineDashboardData() {
    const response = await this.get('/download-pipeline-dashboard-data');
    return response;
  }

  async downloadVacancyDashboardData() {
    const response = await this.get('/download-vacancy-dashboard-data');
    return response;
  }
}

export default new DashboardMetricsAPI();
