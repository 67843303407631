import { TextField } from '@mui/material';
import { InputProps } from './types';

export const Input: React.FC<InputProps> = ({
  disabled,
  hasError,
  errorText,
  onChange,
  onKeyPress,
  placeholder,
  label,
  value,
  size,
  sx,
  inputLabelProps,
  multiline,
  InputProps,
  rows,
  name,
  testid,
}) => {
  return (
    <TextField
      label={label}
      name={name}
      value={value}
      disabled={disabled}
      error={hasError}
      fullWidth
      onChange={onChange}
      onKeyPress={onKeyPress}
      placeholder={placeholder}
      variant="outlined"
      size={size}
      sx={sx}
      InputLabelProps={inputLabelProps}
      multiline={multiline}
      rows={rows}
      helperText={hasError && errorText}
      InputProps={InputProps}
      inputProps={{
        'data-testid': testid || 'mui-input',
      }}
    />
  );
};
