export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getItemStyle = (_, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: '8px 0',

  // styles we need to apply on draggables
  ...draggableStyle
});

export function getQuestionCounts(questions, mapping) {
  /**
   * Count the number of each question type within a scorecard or reference template.
   * @param {object[]} questions: list of questions, each having a type
   * @param {object} mapping: mapping from question type to label
   * @returns {string}
   */
  if (questions.length === 0) {
    return 'No questions selected';
  }
  const reducer = (accumulator, currentQuestion) => {
    if (accumulator[currentQuestion.question_type]) {
      accumulator[currentQuestion.question_type] += 1;
    } else {
      accumulator[currentQuestion.question_type] = 1;
    }
    return accumulator;
  };

  const questionCounts = questions.reduce(reducer, {});

  const countsList = [];

  for (const questionType in questionCounts) {
    countsList.push(`${questionCounts[questionType]} ${mapping[questionType].label}`);
  }

  return countsList.join(', ');
}
