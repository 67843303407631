import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import { DownloadIconGrey, UploadIconGrey } from 'ui-kit/icons';
import FileViewer from 'components/Profile/FileViewer';
import {
  FileViewerContainer,
  MarketplaceAttachmentsHeaderContainer,
  MarketplaceCredentialContainer,
  MarketplaceHeaderLabel,
} from 'components/Profile/Attachments';
import { Attachment } from 'types/types';

interface ProspectAttachmentsProps {
  attachments?: Attachment[];
  openDocumentUploadModal: () => void;
  resume?: string;
  userId: number;
}

const ProspectAttachments: React.FC<ProspectAttachmentsProps> = ({
  attachments,
  openDocumentUploadModal,
  resume,
  userId,
}) => {
  return (
    <MarketplaceCredentialContainer style={{ paddingRight: '19px' }}>
      <MarketplaceAttachmentsHeaderContainer style={{ paddingBottom: '16px' }}>
        <MarketplaceHeaderLabel style={{ marginBottom: 0 }}>Attachments</MarketplaceHeaderLabel>
        <UploadDownloadContainer>
          {attachments.length > 0 && (
            <DownloadIconGrey
              style={{ marginRight: '8px' }}
              className="pointer"
              onClick={() => window.open(`/api/attachments/${userId}?uid=${userId}`, '_blank')}
              data-tip
              data-for="download"
              data-place="bottom"
              data-delay-show="100"
            />
          )}
          <UploadIconGrey
            className="pointer"
            data-tip
            data-for="upload"
            data-place="bottom"
            data-delay-show="100"
            onClick={openDocumentUploadModal}
          />
          <ReactTooltip id="download" effect="solid">
            <span style={{ color: 'white' }}>Download files</span>
          </ReactTooltip>
          <ReactTooltip id="upload" effect="solid">
            <span style={{ color: 'white' }}>Upload files</span>
          </ReactTooltip>
        </UploadDownloadContainer>
      </MarketplaceAttachmentsHeaderContainer>

      {resume && (
        <FileViewerContainer>
          <FileViewer file={resume} title={'Resume'} />
        </FileViewerContainer>
      )}
      {attachments &&
        attachments.map(file =>
          file.attachment ? (
            <FileViewerContainer key={file.id} style={{ paddingRight: 0 }}>
              <FileViewer key={file.id} file={file.attachment} title={file.title} />
            </FileViewerContainer>
          ) : (
            ''
          )
        )}
    </MarketplaceCredentialContainer>
  );
};

export default ProspectAttachments;

const UploadDownloadContainer = styled.div`
  display: flex;
`;
