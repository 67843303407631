export default function NimbleQuestion({ questionText, setQuestionText, questionBank }) {
  return (
    <>
      <h5 className="bold">Select a Nimble question here:</h5>
      <div className="select-form-field jobedit-select-div mt16px mb3">
        <select value={questionText} onChange={e => setQuestionText(e.target.value)}>
          <option value="">Select a question</option>
          {questionBank.map(item => (
            <option key={item.id} value={item.question}>
              {item.question}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
