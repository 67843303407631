import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import { theme } from 'ui-kit';
import { forwardRef } from 'react';

const colourStyles = props => ({
  control: styles => ({
    ...styles,
    backgroundColor: 'white',
    border: props.hasErrors
      ? '1px solid red'
      : props.border || `1px solid ${theme.uiColors.notquitewhite}`,
    boxShadow: props.boxShadow || 'none',
    borderRadius: '3px',
    minHeight: '50px',
    '&:hover': {
      border: props.hasErrors ? '1px solid red' : `1px solid ${theme.uiColors.notquitewhite}`,
    },
  }),
  option: (styles, state) => ({
    ...styles,
    background: state.isFocused
      ? theme.uiColors.greens.medium
      : state.isSelected
      ? theme.uiColors.greens.full
      : styles.background,
    color: state.isFocused || state.isSelected ? theme.uiColors.white : styles.color,
  }),
  multiValue: styles => {
    return {
      ...styles,
      backgroundColor: '#b1ecdb',
      border: '1px solid #00b88d',
    };
  },
  multiValueLabel: styles => ({
    ...styles,
    padding: '0px 8px 1px',
    color: '#555555 !important',
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: '#00b88d',
  }),
});

/**
 * A multi-select field.
 *
 * Wraps react-select so we can consistently style and override it in one place.
 */
export const MultiSelect = forwardRef((props, ref) => (
  <Select isMulti ref={ref} closeMenuOnSelect={false} styles={colourStyles(props)} {...props} />
));

export const StyledCreatableSelect = props => (
  <CreatableSelect styles={colourStyles(props)} {...props} />
);

export const StyledSelect = props => <Select styles={colourStyles(props)} {...props} />;

export const StyledCreatableAsyncSelect = props => (
  <AsyncCreatableSelect styles={colourStyles(props)} {...props} />
);

export const StyledAsyncSelect = props => <AsyncSelect styles={colourStyles(props)} {...props} />;
