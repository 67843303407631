import './upcominginterviews.css';

import { Component } from 'react';

import { Link } from 'react-router-dom';
import auth from '../../utils/auth';
import axios from 'axios';
import moment from 'moment';

import { HeaderLabel, HomepageContentContainer } from 'components/Home/common';

export default class UpcomingInterviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interviews: [],
    };
    this.isSchoolUser = auth.isSchoolUser();
    this.urlPrefix = this.isSchoolUser ? 'school' : 'district';
  }

  componentDidMount() {
    axios.get('/api/interviews/').then(r => {
      if (!this.ignoreLastFetch) {
        this.setState({ interviews: r.data });
      }
    });
  }

  componentWillUnmount() {
    this.ignoreLastFetch = true;
  }

  render() {
    return (
      <HomepageContentContainer>
        <HeaderLabel>Scheduled interviews</HeaderLabel>
        <div className="content">
          {this.state.interviews.length === 0 ? (
            <div style={{ padding: '14px 23px' }}>
              <i style={{ color: '#A6A7AD' }}>No recent activity</i>
            </div>
          ) : (
            <>
              {this.state.interviews.map((interview, index) => (
                <div className="upcoming-interviews" key={index}>
                  <div className="upcoming-interviews-name">
                    <Link
                      to={`/${this.urlPrefix}/profile/${interview.candidate.id}/`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {interview.candidate.name}
                    </Link>
                  </div>
                  <div className="upcoming-interviews-date">
                    <p>{moment(interview.when).format('M/D/YY [at] h:mm a')}</p>
                    <p>{interview.calendar_name}</p>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </HomepageContentContainer>
    );
  }
}
