import { Component } from 'react';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { checkInternal } from '../utils/util';
import auth from '../utils/auth.js';
import { noVisibleAttachments } from '../utils/roleutils';
import ApplicationProgressBar from '../components/Application/ApplicationProgressBar';
import Header from '../features/ProfilePreference/components/Header';
import WorkExperienceReminderModal from '../components/Application/WorkExperienceReminderModal';
import RequiredSectionModal from '../components/Application/RequiredSectionModal';
import Subfooter from '../components/subfooter_poweredby';
import LoadingSpinner from '../components/loadingSpinner';

import WorkExperienceInputs from '../components/Application/WorkExperienceInputs';
import EducationInputs from '../components/Application/EducationInputs';
import CredentialInputs from '../components/Application/CredentialInputs';
import LanguageInputs from '../components/Application/LanguageInputs';
import ReferenceInputs from '../components/Application/ReferenceInputs';
import ExamInputs from '../components/Application/ExamInputs';

import { certificates, USStates } from '../utils/enums';
import { showTotalFailure, showWarning } from '../utils/message';
import { getOrSetPreferenceFlag } from '../utils/preferenceConfig';

import { Logo, MultiSelect, Input } from 'ui-kit';
import UsersAPI from 'api/usersAPI';
import ApplicationsAPI from 'api/applicationsAPI';
import CandidateAPI from 'api/candidateAPI';

const CERTIFICATES = certificates().map(cert => {
  return {
    value: cert.value,
    label: cert.label,
  };
});

const categoryNames = {
  languages: 'languages',
  experiences: 'experiences',
  education: 'education',
  additionalReferences: 'additionalReferences',
  exams: 'exams',
};

class ApplicationContainer extends Component {
  constructor(props) {
    super(props);
    this.getUser = auth.getUser();
    let combinedPreferenceAndProfile = false;
    let editProfileMode = this.props.match.params.id === 'edit-profile' ? true : false;
    let editApplicationMode = window.location.search.indexOf('ea') !== -1 ? true : false;
    this.state = {
      showSpinner: false,
      job_id: this.props.match.params.id,
      combinedPreferenceAndProfile,
      editProfileMode,
      editApplicationMode,
      user: {
        profile: {},
      },
      application: {
        explanation: '',
        source: '',
        role: {
          district: {},
        },
      },
      role: {
        requiredapplicationattachment_set: [],
        custom_questions: [],
        questions: [],
      },
      backToProfileText: 'Previous Step',
      questions: [],
      showFullPageSpinner: false,
      experiences: {
        id: 0,
        list: [],
        addNew: this.freshExperiences(),
        isAdding: false,
        error: null,
      },
      additionalReferences: {
        id: 6,
        list: [],
        addNew: this.freshReferences(),
        isAdding: false,
      },
      educations: {
        id: 1,
        list: [],
        addNew: this.freshEducations(),
        isAdding: false,
      },
      credentials: [],
      languages: {
        id: 3,
        list: [],
        addNew: this.freshLanguages(),
        isAdding: false,
      },
      exams: {
        id: 4,
        list: [],
        addNew: this.freshExams(),
        isAdding: false,
      },
      skipDocumentationPage: false,
      // when form is submitted, the user might still have the last work experience card
      // open. Check props for submitted=true and close card in that case.
      spn: null,
      hasSpnError: false,
      submitted: false,
      workExperienceReminderModalOpen: false,
      totalWorkExperienceYears: 0,
      requiredSectionModalVisible: false,
      requiredSectionModalSection: [],
      // Default this to false; will be set when /role/ call completes
      isInternalCandidate: false,
      // This will determine whether or not to make an event on the backend
      user_made_edits: false,
      sources: [],
      // This will determine whether to (re)calculate Nimble score
      work_experience_edited: false,
    };
    this.checkInternal = checkInternal.bind(this);
    this.noVisibleAttachments = noVisibleAttachments.bind(this);
  }

  freshExperiences = () => {
    return {
      organization: '',
      title: '',
      start_date: '',
      end_date: '',
      reason_for_leaving: '',
      description: '',
      presently_employed: false,
      reference_name: '',
      reference_title: '',
      reference_phone: '',
      reference_email: '',
      do_not_contact: false,
      district_county_office: '',
      subjects: [],
      grades: [],
      experience_types: [],
    };
  };

  freshEducations = () => {
    return {
      school: '',
      degree: '',
      major: '',
      major_enum: '',
      gpa: '',
      gpa_out_of: '',
      start_date: '',
      end_date: '',
      university: '',
    };
  };

  freshLanguages = () => {
    // add defaults for django serializer's enum fields
    return {
      language: -1,
      status: -1,
    };
  };

  freshReferences = () => {
    return {
      salutation: -1,
      first_name: '',
      last_name: '',
      title: '',
      phone: '',
      email: '',
      relationship: '',
      years_known: '',
      status: 0,
      organization: '',
    };
  };

  freshExams = () => {
    return {
      type: null,
      praxis_core_reading: null,
      praxis_core_writing: null,
      praxis_core_math: null,
      praxis_plt_score: null,
      praxis_subject: null,
      praxis_subject_score: null,
      sat_new_ebrw: null,
      sat_new_math: null,
      sat_new_composite: null,
      sat_old_reading: null,
      sat_old_writing: null,
      sat_old_math: null,
      sat_old_composite: null,
      act_composite: null,
      act_english: null,
      act_math: null,
      act_reading: null,
      act_science: null,
      gre_composite: null,
      gre_verbal: null,
      gre_quantitative: null,
      gre_writing: null,
      cbest_reading: null,
      cbest_math: null,
      cbest_writing: null,
      other_exam_name: null,
      other_exam_score: null,
      other_exam_possible_score: null,
    };
  };

  getCategoryEntryMethod = categoryName => {
    switch (categoryName) {
      case categoryNames.languages:
        return this.freshLanguages;
      case categoryNames.experiences:
        return this.freshExperiences;
      case categoryNames.education:
        return this.freshEducations;
      case categoryNames.additionalReferences:
        return this.freshReferences;
      case categoryNames.exams:
        return this.freshExams;
      default:
        return null;
    }
  };

  componentDidMount() {
    // If coming back from Step 2, reload to get any changed data
    if (window.location.search.indexOf('back') !== -1) {
      // Remove the query string, add the editing query string if necessary, and reload the page
      const editApplicationString = window.location.search.indexOf('ea') !== -1 ? '?ea=t' : '';
      const location = window.location.href.split('?')[0] + editApplicationString;
      window.location.href = location;
    }

    document.body.classList.add('applicant-pages');
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.checkIfWillSkipDocumentationPage();

    this.setState({ showFullPageSpinner: true });
    this.loadInitialData();
  }

  loadInitialData = async () => {
    try {
      const [fetchedUser, fetchedApplication] = await Promise.all([
        this.fetchUser(),
        this.fetchApplication(),
      ]);

      const additionalStateUpdates = this.computeAdditionalStateFromUserAndApplication(fetchedUser);

      const combinedPreferenceAndProfileFlag = await getOrSetPreferenceFlag();
      const combinedPreferenceAndProfile =
        this.props.match.params.id === 'edit-profile' && combinedPreferenceAndProfileFlag;

      let candidateSources = [];
      if (!this.state.editProfileMode && fetchedApplication) {
        candidateSources = await this.fetchCandidateSources(fetchedApplication.role.district.id);
      }

      this.setState({
        user: fetchedUser,
        spn: fetchedUser.profile.spn,
        application: fetchedApplication,
        sources: candidateSources,
        combinedPreferenceAndProfile,
        showFullPageSpinner: false,
        ...additionalStateUpdates,
      });
    } catch (error) {
      Sentry.captureException(error);
      showTotalFailure(error);
    }
  };

  fetchUser = async () => {
    try {
      const currentUser = this.getUser;
      const fetchedUser = await UsersAPI.fetchUser(currentUser.id);
      return fetchedUser;
    } catch (error) {
      Sentry.captureException(error);
      showWarning(error);
    }
  };

  fetchApplication = async () => {
    if (this.state.job_id === 'edit-profile') return null;

    try {
      const fetchedApplication = await ApplicationsAPI.getCandidateApplicationByRoleId(
        this.state.job_id
      );
      if (!fetchedApplication) {
        throw new Error(
          `No application found for Candidate ${this.getUser.id} and Role ${this.state.job_id}`
        );
      }

      if (fetchedApplication.source && fetchedApplication.source.id) {
        fetchedApplication.source = fetchedApplication.source.id;
      }

      return fetchedApplication;
    } catch (error) {
      Sentry.captureException(error);
      showWarning(error);
    }
  };

  fetchCandidateSources = async districtId => {
    try {
      const requestQueryParameters = { params: { district_id: districtId } };
      const candidateSources = await CandidateAPI.getCandidateSources(requestQueryParameters);
      return candidateSources;
    } catch (error) {
      Sentry.captureException(error);
      showWarning(error);
    }
  };

  checkIfWillSkipDocumentationPage = async () => {
    // Only try to get the role if it's no in edit profile mode
    if (!this.state.editProfileMode) {
      let role = await axios.get(`/api/role/${this.props.match.params.id}/`);
      role = role.data;
      let skipDocumentationPage = false;
      // If there are no addtl. questions or attachments we're going to skip the documentation page (page 3)
      // Addition 3/1/2019: or if it's an internal candidate and there are no questions/attachments required
      // for internal candidates
      if (this.noVisibleAttachments(role)) {
        skipDocumentationPage = true;
      }

      const role_sections = role.role_sections;
      this.setState(
        { skipDocumentationPage, role_sections, district: role.district.id, role: role },
        () => {
          // Can only determine if it's an internal candidate after we know the district
          this.checkInternal(this.getUser, this.state.district);
        }
      );
    }
  };

  computeAdditionalStateFromUserAndApplication = user => {
    const additionalStateUpdates = {};

    additionalStateUpdates.credentials = user.credentials;
    additionalStateUpdates.experiences = this.normalizeCategoryEntries(
      categoryNames.experiences,
      user.experiences
    );
    additionalStateUpdates.languages = this.normalizeCategoryEntries(
      categoryNames.languages,
      user.languages
    );
    additionalStateUpdates.educations = this.normalizeCategoryEntries(
      categoryNames.education,
      user.education
    );
    additionalStateUpdates.additionalReferences = this.normalizeCategoryEntries(
      categoryNames.additionalReferences,
      user.additional_references
    );
    additionalStateUpdates.exams = this.normalizeCategoryEntries(categoryNames.exams, user.exams);

    const totalWorkExperienceYears = this.calculateTotalWorkExperienceYears(
      additionalStateUpdates.experiences.list
    );
    additionalStateUpdates.totalWorkExperienceYears = totalWorkExperienceYears;

    return additionalStateUpdates;
  };

  normalizeCategoryEntries = (category, entries) => {
    const addNewCategoryEntryMethod = this.getCategoryEntryMethod(category);
    if (!entries?.length) return { list: [], addNew: addNewCategoryEntryMethod() };

    if (category === categoryNames.education) {
      entries.forEach(entry => {
        if (entry.school && entry.school.id) {
          entry.school = entry.school.name;
        }
      });
    } else if (category === categoryNames.experiences) {
      entries.forEach(entry => {
        if (entry.organization && entry.organization.id) {
          entry.organization = entry.organization.name;
        }
      });
    }

    const freshData = {
      languages: this.freshLanguages(),
      experiences: this.freshExperiences(),
      educations: this.freshEducations(),
      additionalReferences: this.freshReferences(),
      exams: this.freshExams(),
    };

    const normalizedEntries = entries.map(entry => ({
      ...freshData[category],
      ...entry,
    }));

    return {
      list: normalizedEntries,
      addNew: freshData[category],
      isAdding: false,
      error: null,
    };
  };

  // HACK: transform dates from 'YYYY-MM-DD' to a JS date object.
  // TODO: update backend serializer to accept 'YYYY-MM-DD' format
  // TODO: this needs to be timezone aware, right now the user is entering UTC whether they know it or not.
  dateify = (obj, fields) => {
    for (const f of fields) {
      if (f in obj && obj[f] != null && obj[f] !== '') {
        obj[f] = new Date(obj[f]);
      }
    }
  };

  // MAIN FUNCTION THAT RUNS ON PAGE SUBMITS
  handleSubmit = async (e, autosave) => {
    if (!autosave) {
      e.preventDefault();
      this.startSpinner();

      const roleSectionVisible = this.state.role_sections && this.showRoleSection(0) !== 'hidden';
      const credentialSectionVisible =
        this.state.role_sections && this.showRoleSection(2) !== 'hidden';
      if (roleSectionVisible) {
        try {
          this.checkWorkHistoryDates();
        } catch (e) {
          this.stopSpinner();
          if (e.message === 'Error -- Work History') {
            // put the work experience section into view
            this.scrollToTopOfPage();
            return;
          } else {
            // unexpected error: halt and catch fire
            throw new Error(e.message);
          }
        }
      }
      const indianaId = USStates().find(s => s.title === 'Indiana').id;
      if (
        (credentialSectionVisible || this.state.editProfileMode) &&
        this.state.credentials.find(c => +c.state === indianaId)
      ) {
        try {
          this.checkSpnIsValid(this.state.spn, true);
        } catch (e) {
          this.stopSpinner();
          if (e.message === 'Error -- SPN') {
            return;
          } else {
            throw new Error(e.message);
          }
        }
      }

      // How did you hear about us is required:
      if (
        !this.state.editProfileMode &&
        (this.state.application.source === '' ||
          this.state.application.source === 'none' ||
          this.state.application.source === null)
      ) {
        this.setState({
          requiredSectionModalVisible: true,
          requiredSectionModalSection: [{ section: 'source' }],
        });

        this.stopSpinner();
        return;
      }
    }

    // ---- USER SECTION OF SUBMIT ------ //
    let user_data = Object.assign({}, this.state.user);
    user_data.email = user_data.username;

    // append extra data to the user update request
    user_data.experiences = this.state.experiences.list;
    user_data.experiences.forEach(e => this.dateify(e, ['start_date', 'end_date']));

    user_data.education = this.state.educations.list;
    user_data.education.forEach(e => this.dateify(e, ['start_date', 'end_date']));
    user_data.credentials = this.state.credentials;
    // add default dates for django's credential serializer
    user_data.credentials.forEach(e => {
      this.dateify(e, ['issue_date', 'expiration_date']);
    });

    user_data.languages = this.state.languages.list;

    user_data.exams = this.state.exams.list;

    user_data.additionalReferences = this.state.additionalReferences.list;

    // sanitize linked_users list if needed:
    if (user_data.linked_users) {
      user_data.linked_users = this.state.user.linked_users.map(linked_user => {
        if (linked_user.id) {
          return linked_user.id;
        }
        return linked_user;
      });
    }

    if (user_data.profile.has_advanced_degree === null) {
      user_data.profile.has_advanced_degree = false;
    }

    if (this.state.work_experience_edited) {
      // Work experience was edited, so we have to calculate both work experience
      // scores and application scores for all applications belonging to this
      // candidate that are eligible for scoring.
      user_data['work_experience_edited'] = true;
    }

    // At this point, profile is complete, so update profile_complete boolean in db
    if (!autosave) {
      user_data.profile_complete = true;

      if (this.state.work_experience_edited === false && this.state.editProfileMode === false) {
        // If the candidate edited her work experience, all of her applications will be
        // re-scored, so we only need to send this application_to_score flag if work experience
        // was not edited. The flag tells us which single application to re-score.
        // Don't need to re-score in edit profile mode because there's no associated application.
        user_data['application_to_score'] = this.state.application.id;
      }
    }
    // Determines proper event creation on the backend
    if (!autosave && this.state.user_made_edits) {
      if (this.state.editProfileMode) {
        user_data['user_edited_profile'] = true;
      }
    }

    // Update user data
    const userUpdatePromise = new Promise((resolve, reject) => {
      axios
        .patch(`/api/user/${this.getUser.id}/`, user_data)
        .then(r => {
          if (user_data.work_experience_edited === true) {
            // update went through and nimble score calculation should have
            // been kicked off, so refresh work_experience_edited state
            this.setState({ work_experience_edited: false });
          }
          resolve(r);
        })
        .catch(error => reject(error));
    });

    // ---- END USER SECTION OF SUBMIT ------ //

    // Can't update an application if in editProfile mode (only can update the user)
    if (!this.state.editProfileMode) {
      // build the application from react state, then apply for the role
      const application = Object.assign({}, this.state.application);
      application.autosave = autosave;

      if (application.prior_district_employee === null) {
        application.prior_district_employee = false;
      }

      /*
      If user has already created an application, they will send a PUT to update it
      Otherwise, send a post to create new application db entry
      */
      let sentApp;
      if (this.state.application.id) {
        // Determines proper event creation on the backend
        if (!autosave && this.state.user_made_edits && this.state.skipDocumentationPage) {
          application['user_edited_application_role_id'] = Number(this.state.job_id);
        }
        try {
          sentApp = await axios.put(
            `/api/applications/${this.state.application.id}/update_application/`,
            application
          );
          sentApp.data = this.state.application;
        } catch (error) {
          // Collect some info to see how often and why this error block is being encountered
          Sentry.captureException(error);

          let errorMessage = `Oops, there was a problem with your application. Please email support@hirenimble.com
            and we'll help you out right away. In the meantime, please keep your browser open to help us preserve your data.`;
          this.stopSpinner();
          this.showWarningMessage(errorMessage);
          return;
        }
      } else {
        try {
          // using var to keep sentApp in lexical scope for the next try block
          sentApp = await axios.post(`/api/role/${this.state.job_id}/apply_for_role/`, application);
        } catch (error) {
          /**
           * NOTE: once this endpoint is successfully hit, the application cannot be resent. Trying to do so will result in the
           * error message below.
           */
          let errorMessage;
          if (
            error.response.data.role &&
            error.response.data.role[0] === 'Application already exists for this role'
          ) {
            errorMessage = `Oops, our records show that you've already applied for this role. If you have any questions,
            please email support@hirenimble.com and we'll help you out right away.
            In the meantime, please keep your browser open to help us preserve your data.`;
          } else {
            errorMessage = `Oops, there was a problem with your application. Please email support@hirenimble.com
            and we'll help you out right away. In the meantime, please keep your browser open to help us preserve your data.`;
          }
          this.stopSpinner();
          this.showWarningMessage(errorMessage);
          return;
        }
      }
    }
    // Don't move on to next page until user update returns
    // Fun fact: because this `then` statement is below the update application `await`
    // it is blocked by that response returning too!
    userUpdatePromise
      .then(s => {
        if (!autosave) {
          this.stopSpinner();
          // Check if they didn't enter info for a required section
          const emptyRequiredSections = !this.state.editProfileMode
            ? this.checkRequiredSections()
            : false;

          if (emptyRequiredSections) {
            this.setState({
              requiredSectionModalVisible: true,
              requiredSectionModalSection: emptyRequiredSections,
            });
          } else if (
            // Check if they entered any workexperience
            // Don't show workexperience reminder if the section is not visible
            !this.state.editProfileMode &&
            this.state.experiences.list.length === 0 &&
            ((this.state.role_sections.find(rs => rs.section === 0).visible &&
              !this.state.isInternalCandidate) ||
              (this.state.role_sections.find(rs => rs.section === 0).visible_internal &&
                this.state.isInternalCandidate))
          ) {
            this.setState({ workExperienceReminderModalOpen: true });
          } else if (this.state.editProfileMode && this.state.experiences.list.length === 0) {
            this.setState({ workExperienceReminderModalOpen: true });
          } else {
            this.moveToNextPage();
          }
        }
      })
      .catch(error => {
        console.log(error);
        let errorMessage;
        let response = error.response;
        if (response && response.data && response.data.credential) {
          errorMessage = `Oops, there was a problem with the credential section.`;
        } else if (response && response.data && response.data.languageSkill) {
          errorMessage = `Oops, there was a problem with the language section.`;
        } else {
          errorMessage = `Oops, there was a problem with some of your personal information.`;
        }
        let errorMessageEnding = ` Please ensure all fields are filled out and try again. If you have
        any questions, please email support@hirenimble.com and we'll help you out right away.`;
        this.stopSpinner();
        this.showWarningMessage(errorMessage + errorMessageEnding);
      });
  };

  checkSpnIsValid = (spn, showError = false) => {
    const acceptedSpnRegexOne = /^\d{8}$/;
    const acceptedSpnRegexTwo = /^P-\d{8}$/;
    const isValidSpn = acceptedSpnRegexOne.test(spn) || acceptedSpnRegexTwo.test(spn);
    if (showError && !isValidSpn) {
      this.setState({ hasSpnError: true });
      throw new Error('Error -- SPN');
    } else if (showError && isValidSpn) {
      this.setState({ hasSpnError: false });
    }
    return isValidSpn;
  };
  checkWorkHistoryDates = () => {
    // We now check for bad data before saving each individual entry, but historically
    // we did not, so there are still old entries that have missing data. If a candidate
    // with a bad WorkExperience entry applies to another job, she may not edit that entry
    // so the individual entry error checking wouldn't catch it. This procedure handles
    // that by checking all of the candidate's entries for missing/bad data and if necessary,
    // shows an error message that tells them which entries to fix.
    const today = new Date();
    const hasBadData = job => {
      const startDateAfterEndDate = job.end_date && job.start_date > job.end_date;
      const startDateInFuture = job.start_date > today;
      const endDateInFuture = job.end_date > today;
      return (
        !job.reason_for_leaving ||
        !job.organization ||
        !job.title ||
        !job.description ||
        !job.start_date ||
        !(job.end_date || job.presently_employed) ||
        startDateAfterEndDate ||
        startDateInFuture ||
        endDateInFuture
      );
    };

    let anyErrors = false;
    const experiencesList = this.state.experiences.list.map(job => {
      if (hasBadData(job)) {
        anyErrors = true;
        return { ...job, hasError: true };
      } else {
        return job;
      }
    });

    if (anyErrors) {
      // set experiences with hasError flag from above
      this.setState(prevState => ({
        experiences: {
          ...prevState.experiences,
          list: experiencesList,
          error: true,
        },
      }));
      throw new Error('Error -- Work History');
    }
  };

  moveToNextPage = autosave => {
    if (this.state.editProfileMode) {
      this.props.history.push(`/candidate-dashboard?fromEdit=true`);
    } else {
      let queryString = this.state.editApplicationMode ? `?ea=t` : '';
      if (!this.state.skipDocumentationPage) {
        this.props.history.push(
          `/application-documentation/${this.state.job_id}/${window.location.search}`
        );
      } else {
        queryString = queryString.replace('?', '&');
        this.props.history.push(
          `/applicationconfirmation/?job_id=${this.state.job_id}${queryString}`
        );
      }
    }
  };

  handleMultiSelectChange = (values, fieldName) => {
    let newUserCopy = Object.assign({}, this.state.user);
    newUserCopy['profile'][fieldName] = values.map(value => value.value);
    this.setState({ user: newUserCopy, user_made_edits: true }, () => {
      // Autosave
      this.debouncedHandleSubmit();
    });
  };

  startSpinner = () => {
    this.setState({ showSpinner: true });
  };

  stopSpinner = () => {
    this.setState({ showSpinner: false });
  };

  showWarningMessage = message => {
    showWarning(
      `${message} To help us help you, please let us know which
       position you were applying for and the time (e.g. 12:15pm) you received this error.
      Thank you!`
    );
  };

  /* When autosaving, delay ten seconds before sending another POST */
  debouncedHandleSubmit = _.debounce(() => {
    this.setState({ user_made_edits: true });
    this.handleSubmit(null, true);
  }, 10000);

  // ------ Begin handle change functions ------ //

  handleChange = event => {
    let new_value = null;
    if (event.target.type === 'radio') {
      if (typeof event.target.value === 'string') {
        if (event.target.value === 'false') {
          new_value = false;
        } else if (event.target.value === 'true') {
          new_value = true;
        }
      }
    } else {
      new_value = event.target.value;
    }
    this.setState(
      { [event.target.name]: new_value, user_made_edits: true },
      this.debouncedHandleSubmit
    );
  };

  handleApplicationChange = event => {
    const application = { ...this.state.application };

    if (event.target.name === 'source') {
      const foundSource = this.state.sources.find(
        source => source.id === parseInt(event.target.value, 10)
      );
      application.source = foundSource ? foundSource.id : '';
      application.source_id = application.source;
    }

    application[event.target.name] = event.target.value;
    this.setState(
      {
        application,
        user_made_edits: true,
      },
      this.debouncedHandleSubmit
    );
  };

  handleProfileChange = event => {
    let new_value = event.target.value;
    if (event.target.type === 'radio') {
      if (typeof event.target.value === 'string') {
        if (event.target.value === 'false') {
          new_value = false;
        } else if (event.target.value === 'true') {
          new_value = true;
        }
      }
    } else if (event.target.type === 'checkbox') {
      new_value = event.target.checked;
    }
    let newUserCopy = Object.assign({}, this.state.user);
    newUserCopy['profile'][event.target.name] = new_value;
    this.setState({ user: newUserCopy, user_made_edits: true }, this.debouncedHandleSubmit);
  };

  calculateTotalWorkExperienceYears = experiences => {
    let totalYearsExperience = experiences.reduce((total, current) => {
      let currentYears = 0;
      if (current.start_date) {
        const end_date = current.end_date || Date.now();
        currentYears =
          Math.round((moment(end_date).diff(current.start_date, 'days') / 365) * 10) / 10;
      }
      return total + currentYears;
    }, 0);

    totalYearsExperience =
      totalYearsExperience % 1 === 0 ? totalYearsExperience : totalYearsExperience.toFixed(1);

    return totalYearsExperience;
  };

  setTotalWorkExperienceYears = () => {
    const totalYearsExperience = this.calculateTotalWorkExperienceYears(
      this.state.experiences.list
    );

    this.setState(prevState => ({
      totalWorkExperienceYears: totalYearsExperience,
      experiences: {
        ...prevState.experiences, // TODO: not sure why we need experiences here but i'm too scared to remove them. Thanks WG.
        error: null,
      },
    }));
  };

  hideRequiredSectionModal = () => {
    this.setState({ requiredSectionModalVisible: false });
  };

  resetCertificates = () => {
    let newUserCopy = Object.assign({}, this.state.user);
    newUserCopy['profile']['certificates_held'] = [];
    newUserCopy['profile']['other_certificate_explanation'] = null;
    this.setState({ user: newUserCopy, user_made_edits: true });
  };

  handleSpnChange = spn => {
    this.setState({ spn: spn });

    const spnIsValid = this.checkSpnIsValid(spn);
    if (spnIsValid) {
      let newUserCopy = Object.assign({}, this.state.user);
      newUserCopy['profile']['spn'] = spn;
      this.setState({ user: newUserCopy, user_made_edits: true });
    } else {
      //Do not raise error until user save. Save here is for silent autosave if spn is valid.
      return;
    }
  };

  scrollToTopOfPage = () => {
    this.setState({ workExperienceReminderModalOpen: false });
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 300);
  };
  // ----- End handle change functions ------- //

  showRoleSection = sectionValue => {
    const roleSection = this.state.role_sections.find(rs => rs.section === sectionValue);
    let className = '';
    const visibleKey =
      this.state.isInternalCandidate && this.state.role.internal_requirements_specified
        ? 'visible_internal'
        : 'visible';
    const requiredKey =
      this.state.isInternalCandidate && this.state.role.internal_requirements_specified
        ? 'required_internal'
        : 'required';

    if (this.state.editProfileMode || (this.state.role_sections && roleSection[visibleKey])) {
      className += roleSection[requiredKey] ? 'required-section' : '';
    } else {
      className += 'hidden';
    }

    return className;
  };

  checkRequiredSections = () => {
    /*
     ** Check if any required sections are blank; show modal if so.
     ** Returns the first section they didn't enter
     */
    // Keeps track of required sections that user hasn't filled out
    const allEmptyRequiredSection = [];
    const requiredKey =
      this.state.isInternalCandidate && this.state.role.internal_requirements_specified
        ? 'required_internal'
        : 'required';
    const requiredSections = this.state.role_sections.filter(
      section => section[requiredKey] === true
    );
    // Check the "card" sections and then certificates and credentials
    const emptySections = [
      this.state.experiences,
      this.state.educations,
      this.state.languages,
      this.state.exams,
      this.state.additionalReferences,
    ].filter(section => section.list.length === 0);
    // Traditional for-loop b/c originally we wanted to exit whenever we find an empty required section
    for (let x = 0; x < emptySections.length; x++) {
      const emptyRequiredSection = requiredSections.find(s => s.section === emptySections[x].id);
      if (emptyRequiredSection) {
        allEmptyRequiredSection.push(requiredSections.find(s => s.section === emptySections[x].id));
      }
    }
    // Check the certificates section is required and whether it has has_no_certificates bool or is not empty
    const certificate_section = this.state.role_sections.find(s => s.section === 5);
    const credential_section = this.state.role_sections.find(s => s.section === 2);
    if (
      certificate_section[requiredKey] &&
      !this.state.user.profile.has_no_certificates &&
      this.state.user.profile.certificates_held.length === 0
    ) {
      allEmptyRequiredSection.push(certificate_section);
    }
    if (
      credential_section[requiredKey] &&
      (!this.state.credentials || !this.state.credentials.length) &&
      !this.state.user.profile.has_no_credentials
    ) {
      allEmptyRequiredSection.push(credential_section);
    }
    return allEmptyRequiredSection.length > 0 ? allEmptyRequiredSection : false;
  };

  renderLogo = () => {
    if (this.state.job_id === 'edit-profile') {
      return (
        <div className="logo-container-inner">
          <img
            onError={e => {
              e.target.onerror = null;
              e.target.style.display = 'none';
            }}
            src="/jobboard_logos/nimble-logo-round.png"
            alt="logo"
            className="nimble-logo"
          />
        </div>
      );
    } else if (this.state.application.id && this.state.application?.role?.jobboards[0]?.logo) {
      return <Logo src={this.state.application.role.jobboards[0].logo} maxHeight={54} />;
    }
  };

  render() {
    if (this.state.showFullPageSpinner) {
      return <LoadingSpinner />;
    }

    const orgName = this.state.application?.role.jobboards?.length
      ? this.state.application.role.jobboards[0].title
      : this.state.district?.name;

    return (
      <div className="application-step-two-container">
        {this.state.combinedPreferenceAndProfile ? (
          <Header step={4} />
        ) : (
          <div className="header-section">
            <div className="logo-container-outer">{this.renderLogo()}</div>
            <h2 className="darker-text">Complete your profile</h2>
            <div className="header-section-long-text">
              You'll only have to complete this information once, and it will be saved for each job
              you apply to! Be sure to complete it thoroughly, to help ensure you're selected for
              the right opportunities. All fields marked with an asterisk are required.
            </div>
            <ApplicationProgressBar
              step={2}
              editProfileMode={this.state.editProfileMode}
              skipDocumentationPage={this.state.skipDocumentationPage}
            />
          </div>
        )}
        <div className="flex application-container">
          <form className="flex-3" encType="multipart/form-data" onSubmit={this.handleSubmit}>
            <div
              className={
                'application-section experiences ' +
                (this.state.role_sections ? this.showRoleSection(0) : '')
              }
            >
              <h4 id="experience" className="section-title">
                Work Experience -{' '}
                {this.state.totalWorkExperienceYears === 1
                  ? `${this.state.totalWorkExperienceYears} year`
                  : `${this.state.totalWorkExperienceYears} years`}
              </h4>
              {this.state.experiences.error ? (
                <ErrorMessage>
                  Please complete all required fields in the highlighted entries below
                </ErrorMessage>
              ) : (
                <p className="subtext">
                  Hiring managers find this information especially important
                </p>
              )}
              <WorkExperienceInputs
                experiences={this.state.experiences}
                freshObject={this.freshExperiences}
                autosave={this.debouncedHandleSubmit}
                submitted={this.state.submitted}
                setTotalWorkExperienceYears={this.setTotalWorkExperienceYears}
                setWorkExperienceEdited={() => this.setState({ work_experience_edited: true })}
              />
              <WorkExperienceReminderModal
                show={this.state.workExperienceReminderModalOpen}
                onHide={() => this.setState({ workExperienceReminderModalOpen: false })}
                moveToNextPage={this.moveToNextPage}
                scrollToTopOfPage={this.scrollToTopOfPage}
              />
            </div>

            <div
              className={
                'application-section education-inputs-container ' +
                (this.state.role_sections && this.showRoleSection(1))
              }
            >
              <h4 id="education" className="section-title">
                Education
              </h4>
              <div className="application-row flex">
                <label className="flex-1">
                  <input
                    type="checkbox"
                    className="application-checkbox"
                    name="has_ged"
                    value={this.state.user.profile.has_ged || false}
                    checked={this.state.user.profile.has_ged || false}
                    onChange={this.handleProfileChange}
                  />{' '}
                  I have a GED
                </label>
              </div>
              <EducationInputs
                educations={this.state.educations}
                freshObject={this.freshEducations}
                autosave={this.debouncedHandleSubmit}
              />
            </div>

            <div
              className={
                'application-section credentials ' +
                (this.state.role_sections && this.showRoleSection(2))
              }
            >
              <h4 id="credentials" className="section-title">
                Credentials
              </h4>
              <CredentialInputs
                credentials={this.state.credentials || []}
                setCredentials={credentials =>
                  this.setState({ credentials: credentials, has_no_credentials: false })
                }
                // Hacky :( but simulating event for handleProfileChange
                setCredentialNone={boolean =>
                  this.handleProfileChange({
                    target: { name: 'has_no_credentials', value: boolean, type: 'text' },
                  })
                }
                spn={this.state.spn}
                handleSpnChange={s => this.handleSpnChange(s)}
                hasSpnError={this.state.hasSpnError}
                hasNoCredentials={this.state.user.profile.has_no_credentials}
                autosave={this.debouncedHandleSubmit}
              />
            </div>

            <div
              id="language"
              className={
                'application-section ' + (this.state.role_sections && this.showRoleSection(3))
              }
            >
              <h4 className="section-title">Languages</h4>
              <LanguageInputs
                languages={this.state.languages}
                freshObject={this.freshLanguages}
                autosave={this.debouncedHandleSubmit}
              />
            </div>

            <div
              className={
                'application-section ' + (this.state.role_sections && this.showRoleSection(4))
              }
            >
              <h4 id="exams" className="section-title">
                Exams
              </h4>
              <ExamInputs
                exams={this.state.exams}
                freshObject={this.freshExams}
                autosave={this.debouncedHandleSubmit}
              />
            </div>

            <div
              className={
                'application-section ' + (this.state.role_sections && this.showRoleSection(5))
              }
            >
              <h4 className="section-title">Certificates</h4>
              {!this.state.user.profile.has_no_certificates && (
                <div className="basic application-row flex">
                  <MultiSelect
                    className="flex-1"
                    options={CERTIFICATES}
                    value={CERTIFICATES.filter(certificate =>
                      this.state.user.profile.certificates_held?.includes(certificate.value)
                    )}
                    placeholder="eg. BCC, BCLAD..."
                    onChange={values =>
                      this.handleMultiSelectChange(values ?? [], 'certificates_held')
                    }
                  />
                </div>
              )}
              {/* Show input if Other Credential (70) is Selected */}
              {this.state.user.profile.certificates_held?.includes(70) && (
                <Input
                  width={1}
                  name="other_certificate_explanation"
                  type="text"
                  placeholder='Describe "Other" Certificate (Optional)'
                  defaultValue={this.state.user.profile.other_certificate_explanation}
                  onChange={this.handleProfileChange}
                  hasError={false}
                />
              )}
              <div>
                <label className="flex-1">
                  <input
                    type="checkbox"
                    className="application-checkbox"
                    name="has_no_certificates"
                    value={this.state.user.profile.has_no_certificates}
                    checked={this.state.user.profile.has_no_certificates}
                    onChange={evt => {
                      this.resetCertificates();
                      this.handleProfileChange(evt);
                    }}
                  />{' '}
                  I don't have any certificates
                </label>
              </div>
            </div>

            <div
              className={
                'application-section references ' +
                (this.state.role_sections && this.showRoleSection(6))
              }
            >
              <h4 id="references" className="section-title">
                References
              </h4>
              <ReferenceInputs
                additionalReferences={this.state.additionalReferences}
                freshObject={this.freshReferences}
                autosave={this.debouncedHandleSubmit}
              />
            </div>

            {!this.state.editProfileMode && (
              <div className="application-section">
                <h4 className="section-title">How did you hear about us?</h4>
                <div className="select-form-field dropdown">
                  <select
                    id="application-source-select"
                    name="source"
                    value={this.state.application.source ? this.state.application.source : 'none'}
                    onChange={this.handleApplicationChange}
                  >
                    <option
                      key="none"
                      value="none"
                      selected={!this.state.application.source}
                      disabled
                    >
                      How did you hear about us?
                    </option>
                    {this.state.sources.map(source => (
                      <option key={source.id} value={source.id}>
                        {source.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            {!this.state.editProfileMode && orgName && (
              <div className="application-section">
                <h4 className="section-title">
                  {`Are you a current or former employee of ${orgName}?`}
                </h4>
                <div className="yesno">
                  <label>
                    <input
                      id="current-former-employee-yes-select-radio"
                      type="radio"
                      value={true}
                      checked={this.state.application.prior_district_employee}
                      onChange={() =>
                        this.handleApplicationChange({
                          target: { name: 'prior_district_employee', value: true },
                        })
                      }
                    />
                    &nbsp;&nbsp;Yes
                  </label>
                  <label>
                    <input
                      type="radio"
                      value={false}
                      checked={!this.state.application.prior_district_employee}
                      onChange={() =>
                        this.handleApplicationChange({
                          target: { name: 'prior_district_employee', value: false },
                        })
                      }
                    />
                    &nbsp;&nbsp;No
                  </label>
                </div>
              </div>
            )}

            {!this.state.editProfileMode && (
              <div className="application-section">
                <h4 className="section-title">
                  Do you hold an advanced degree (masters or above)?
                </h4>
                <div className="yesno">
                  <label>
                    <input
                      id="has-advance-degree-yes-select-radio"
                      type="radio"
                      name="has_advanced_degree"
                      value={true}
                      checked={this.state.user.profile.has_advanced_degree ?? false}
                      onChange={this.handleProfileChange}
                    />
                    &nbsp;&nbsp;Yes
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="has_advanced_degree"
                      value={false}
                      checked={!this.state.user.profile.has_advanced_degree}
                      onChange={this.handleProfileChange}
                    />
                    &nbsp;&nbsp;No
                  </label>
                </div>
              </div>
            )}

            {this.state.combinedPreferenceAndProfile ? (
              <ButtonContainer>
                <SecondaryButton
                  className={`submit btn-lrg`}
                  type="submit"
                  value={'Previous Step'}
                  onClick={() => this.props.history.push(`/applicant-profile/edit-profile`)}
                  disabled={this.state.showSpinner}
                />
                <input
                  id="submit"
                  className={`submit btn-lrg`}
                  type="submit"
                  value={'Save & Complete'}
                  onClick={() => this.setState({ submitted: true })}
                  disabled={this.state.showSpinner}
                />
                <RequiredSectionModal
                  show={this.state.requiredSectionModalVisible}
                  onHide={() => this.setState({ requiredSectionModalVisible: false })}
                  hideRequiredSectionModal={this.hideRequiredSectionModal}
                  requiredSectionModalSection={this.state.requiredSectionModalSection}
                />
              </ButtonContainer>
            ) : (
              <div className="application-section bottom-section step-2">
                <div className="bottom-section-content">
                  <h4 className="section-title">You’re almost there!</h4>
                  <div className="mt2">
                    <input
                      id="go-back"
                      className="submit disabled btn-lrg go-back pointer mr2"
                      type="submit"
                      value={this.state.backToProfileText}
                      onClick={() => {
                        const queryString = `?${window.location.search.substr(1)}&back`;
                        this.props.history.push(
                          `/applicant-profile/${this.state.job_id}/${queryString}`
                        );
                      }}
                      onChange={() =>
                        this.props.history.push(`/applicant-profile/${this.state.job_id}`)
                      }
                    />
                    <input
                      id="save-and-next-step-button"
                      className={`submit btn-lrg`}
                      type="submit"
                      value={this.state.editProfileMode ? 'Save' : 'Save & next step'}
                      onClick={() => this.setState({ submitted: true })}
                      disabled={this.state.showSpinner}
                    />

                    {this.state.showSpinner && (
                      <LoadingSpinner
                        margin={0}
                        fontSize={2}
                        message="This may take a few seconds."
                        additionalClassname="bottom"
                      />
                    )}
                    <RequiredSectionModal
                      show={this.state.requiredSectionModalVisible}
                      onHide={() => this.setState({ requiredSectionModalVisible: false })}
                      hideRequiredSectionModal={this.hideRequiredSectionModal}
                      requiredSectionModalSection={this.state.requiredSectionModalSection}
                    />
                  </div>
                </div>
                <Subfooter />
              </div>
            )}
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(ApplicationContainer);

const ErrorMessage = styled.p`
  color: #cc0033;
`;

const SecondaryButton = styled.input`
  color: #616161;
  border-color: #616161;
  background-color: transparent;
`;

const ButtonContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
`;
