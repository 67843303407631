import PropTypes from 'prop-types';

export default function ScorecardActivity({ events }) {
  return (
    <ul>
      {events.map(event => (
        // eslint-disable-next-line react/jsx-key
        <li>{event.label}</li>
      ))}
    </ul>
  );
}

ScorecardActivity.propTypes = {
  events: PropTypes.array.isRequired,
};
