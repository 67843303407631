import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { formatPhoneNumber } from 'utils/util';
import { ReactComponent as PhoneIcon } from '../../../assets/icon-phone-white.svg';
import { useOnClickOutside } from 'hooks';

import prospectDetailAPI from 'api/prospectDetailAPI';

export default function PhoneSection({ onProspectList, partialUser, fullUser, fetchProspect }) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [phoneNumberChanged, setPhoneNumberChanged] = useState(false);

  useEffect(() => {
    setPhoneNumber(fullUser.phone_number);
  }, [fullUser]);

  const savePhoneNumber = () => {
    setIsEditing(false);
    if (phoneNumberChanged) {
      const updateObject = { phone_number: phoneNumber };
      prospectDetailAPI
        .updateProspectPartial(partialUser.prospect_id, updateObject)
        .then(fetchProspect);
    }
    setPhoneNumberChanged(false);
  };

  const onClickOutside = useOnClickOutside(savePhoneNumber, isEditing);

  const prospectPhoneSection = () => {
    if (isEditing) {
      return (
        <PhoneInput
          ref={onClickOutside}
          type="text"
          value={phoneNumber}
          onChange={e => {
            setPhoneNumber(e.target.value);
            setPhoneNumberChanged(true);
          }}
        />
      );
    } else if (!phoneNumber) {
      return <AddPhoneButton onClick={() => setIsEditing(true)}>+ add phone</AddPhoneButton>;
    } else {
      return (
        <div className="contact" onClick={() => setIsEditing(true)}>
          {formatPhoneNumber(phoneNumber)}
        </div>
      );
    }
  };

  const candidatePhoneSection = () => {
    let phone_number;

    if (partialUser.profile?.phone_cell) {
      phone_number = partialUser.profile.phone_cell;
    } else if (partialUser.profile?.phone_work) {
      phone_number = partialUser.profile.phone_work;
    } else if (fullUser?.district_phone) {
      phone_number = fullUser.district_phone;
    } else {
      phone_number = '';
    }

    if (phone_number) {
      return <div className="contact">{formatPhoneNumber(phone_number)}</div>;
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <PhoneIcon />
      &nbsp;&nbsp;
      {onProspectList ? prospectPhoneSection() : candidatePhoneSection()}
    </div>
  );
}

const AddPhoneButton = styled.button`
  color: var(--green);
  background-color: #ffffff;
  border: none;
  padding-left: 0;
`;

const PhoneInput = styled.input`
  margin-top: -2px;
`;
