import styled from 'styled-components';

import ModalFooterNew from 'components/common/Modal/ModalFooterNew';
import LoadingSpinner from 'components/loadingSpinner';
import { makeAllUrlsValid } from '../../../utils/util';
import { AltModal } from 'ui-kit';

const CandidateHellosignModalBody = ({ isLoading, task, continueToSign, onHide }) => {
  return (
    <>
      <AltModal.Body>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Label>Directions:</Label>
            <DirectionsDiv
              dangerouslySetInnerHTML={{ __html: makeAllUrlsValid(task.directions) }}
            />
          </>
        )}
      </AltModal.Body>
      <AltModal.Actions>
        <ModalFooterNew save={continueToSign} saveButtonLabel="Continue to sign" cancel={onHide} />
      </AltModal.Actions>
    </>
  );
};

export default CandidateHellosignModalBody;

const Label = styled.p`
  font-size: 19px;
  margin-bottom: 1rem;
`;

const DirectionsDiv = styled.div`
  font-size: 15px;

  a {
    color: ${props => props.theme.colors.primary.light};

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`;
