import { BaseNimbleAPI } from './baseAPI';

class RoleSearchAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/search/districtroles' };
  }

  async fetchAllRolesInDistrict() {
    const url = '/';
    const response = await this.get(url);
    return response.data;
  }

  async fetchActiveDistrictRoles() {
    const url = '/';
    const params = { job_status_list: 'active_open,active_closed' };
    const response = await this.get(url, { params });
    return response.data;
  }

  async searchDistrictRoles(query) {
    const url = '/';
    const params = {
      query,
      page_size: 20,
      job_status_list: 'active_open,active_closed,draft,archived',
    };
    const response = await this.get(url, { params });
    return response.data;
  }

  async searchTemplates(query) {
    const url = '/';
    const params = { is_template: true, query, page_size: 20 };
    const response = await this.get(url, { params });
    return response.data;
  }

  async searchSharableRoles(query) {
    const url = '/';
    const params = {
      query,
      page_size: 20,
      job_status_list: 'active_open',
    };
    const response = await this.get(url, { params });
    return response.data;
  }
}

export default new RoleSearchAPI();
