import { Grid, Modal as MuiModal } from '@mui/material';
import { BlankModalProps } from './types';
import { ModalContainer } from './styles';

export const BlankModal: React.FC<BlankModalProps> = ({
  dataTestId,
  header,
  body,
  open,
  onClose,
  sx,
}) => {
  return (
    <MuiModal sx={sx} open={open} onClose={onClose} data-testid={dataTestId}>
      <ModalContainer sx={sx}>
        <Grid container direction={'column'}>
          <Grid item>{header}</Grid>
          <Grid item>{body}</Grid>
        </Grid>
      </ModalContainer>
    </MuiModal>
  );
};
