export interface DistrictJobBoardDropDownProps {
  values?: any | undefined;
  setValues?: (options) => void;
  updateMultiSelect?: (options, type: string) => void;
  options?: DistrictJobBoardDropdownOptionsPropsInterface[];
  showOptions?: boolean;
  displayName?: string;
  isActive?: boolean;
  setIsActive?: (isActive: boolean) => void;
  mainColor?: string;
  highlightColor?: string;
  showSearchInput?: boolean;
  showSelectAllButtons?: boolean;
  displayNameIsDynamic?: boolean;
  optionNamePlural?: string;
  error?: any;
}

export interface DistrictJobBoardDropdownOptionsPropsInterface {
  value?: StringNumber;
  label?: string;
}

export interface RoleFilterBarProps {
  district_id?: number;
  query: any;
  setCurrentQuery: (query: any) => void;
  resetRoles?: () => void;
  grades?: number[];
  subjects?: number[];
  schools?: DistrictJobBoardDropdownOptionsPropsInterface[];
  categories?: number[];
  candidate_source_list?: string[];
  updateMultiSelect?: (selection: any) => void;
  isRequisition?: boolean;
  awaitingRefresh?: boolean;
  refresh?: () => void;
  actions?: () => void;
}

export interface GetDisplayNameOptionInterface {
  value?: StringNumber;
  label?: string;
  subOptions?: GetDisplayNameOptionInterface[];
}

export type EmploymentTypeOption = {
  value: number;
  label: 'Part-time' | 'Full-time';
};

export const employmentTypeOptions: EmploymentTypeOption[] = [
  {
    value: 0,
    label: 'Part-time',
  },
  {
    value: 1,
    label: 'Full-time',
  },
];

export type StringNumber = string | number;
