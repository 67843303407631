import styled from 'styled-components';

export default function Footer({
  onSubmit,
  saveText,
}: {
  onSubmit: () => void;
  saveText: string;
}): React.ReactElement {
  return (
    <Container>
      <ButtonContainer>
        <input
          id="submit"
          className={`submit btn-lrg`}
          type="submit"
          value={saveText}
          onClick={() => onSubmit()}
          disabled={false}
        />
      </ButtonContainer>
    </Container>
  );
}

const Container = styled.div`
  padding-top: 40px;
  max-width: 1200px;
  margin: auto;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
