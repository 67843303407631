import { BaseNimbleAPI } from './baseAPI';

class scorecardAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/scorecards' };
  }

  async create(scorecard) {
    const response = await this.post('/', scorecard);
    return response.data;
  }

  async fetch(scorecardId) {
    const response = await this.get(`/${scorecardId}/`);
    return response.data;
  }

  async update(scorecard) {
    const response = await this.put(`/${scorecard.id}/`, scorecard);
    return response.data;
  }

  async destroy(scorecardId) {
    const response = await this.delete(`/${scorecardId}/`);
    return response.data;
  }

  async createAttachment(scorecardId, formData) {
    const response = await this.post(`/${scorecardId}/upload_attachment/`, formData);
    return response.data;
  }

  async setAnswerOrder(scorecard) {
    const data = {
      order: scorecard.answers.map(a => a.id)
    };
    const response = await this.post(`/${scorecard.id}/set_answer_order/`, data);
    return response.data;
  }
}

export default new scorecardAPI();
