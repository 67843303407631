import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import { Inter } from 'features/MarketplaceV2/features/JobDiscovery/utils';
import { ConnectGatedActions } from 'features/MarketplaceV2/utils/connectEnums';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { Actions, School } from 'types/marketplaceV2Types';
import auth from 'utils/auth';
import { ExpressInterestButtonProps } from '../types';
import { CONNECT_ACTION_LABEL } from 'features/MarketplaceV2/features/JobDiscovery/constants';
import {
  checkUserProfileCompletion,
  useConnectActions,
} from 'features/MarketplaceV2/features/ExpressInterest/utils';
import { nimbleTheme } from 'theme';
import { ConnectActionButtonsDataTestIds } from 'data-testids/ConnectDataTestIds';

export const ExpressInterestButton: React.FC<ExpressInterestButtonProps> = ({
  school,
  actions,
  sx,
  buttonText = CONNECT_ACTION_LABEL,
  fontSize = '14px',
}) => {
  const history = useHistory();
  const user = auth.getUser();
  const { handleConnectUserAction } = useConnectActions();

  const hasExpressedInterest = () => {
    return actions.hasSchoolBeenContacted(school.nces_id) || school?.hasExpressedInterest;
  };

  const expressInterestOnClickHandler = async (school: School, actions: Actions): Promise<void> => {
    if (!user) {
      history.push(
        `/connect/signup?school=${school.nces_id}&action=${ConnectGatedActions.EXPRESS_INTEREST}`
      );
    } else if (!user?.preference?.nimble_connect_profile?.is_completed) {
      if (
        !checkUserProfileCompletion(user, history, school, ConnectGatedActions.EXPRESS_INTEREST)
      ) {
        return;
      }
    } else {
      actions.setReadyToConnectModalIsOpen(false); // ensure modal is closed to prevent double render bug
      actions.setExpressInterestModalIsOpen(true);
      actions.setSelectedSchool(school);
    }
  };

  const expressInterestCallbackFunction = async () => {
    await expressInterestOnClickHandler(school, actions);
  };

  return (
    <PrimaryButton
      size="small"
      disabled={hasExpressedInterest()}
      startIcon={hasExpressedInterest() && <CheckCircleIcon />}
      dataTestId={ConnectActionButtonsDataTestIds.EXPRESS_INTEREST_BUTTON}
      sx={{
        background: nimbleTheme.palette.primary.main,
        borderRadius: '8px',
        height: '44px',
        padding: '14px',
        ...sx,
      }}
      onClick={async () => {
        await handleConnectUserAction(
          user,
          ConnectGatedActions.EXPRESS_INTEREST,
          expressInterestCallbackFunction,
          school
        );
      }}
    >
      <Typography noWrap style={{ fontFamily: Inter, fontSize: fontSize, fontWeight: 600 }}>
        {hasExpressedInterest() ? 'Expressed Interest' : buttonText}
      </Typography>
    </PrimaryButton>
  );
};
