import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import axios from 'axios';

import iconMinus from '../../../assets/icon-minus.svg';
import iconPlus from '../../../assets/icon-plus.svg';
import { handleOutsideClickOnValidForm } from '../../../utils/util';
import { salutation } from '../../../utils/enums';
import ErrorText from '../../errortext';
import { isValidEmail } from 'utils/emailutils';

const salutationReadable = salutation().reduce((obj, item) => {
  obj[item.value] = item.label;
  return obj;
}, {});

export default class ReferenceInputs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      additionalReferences: props.additionalReferences,
      addReference: props.freshObject(),
      isAdding: false,
      insertPosition: undefined,
      errorMessage: '',
    };

    this.freshReference = this.props.freshObject;
  }

  static propTypes = {
    additionalReferences: PropTypes.object.isRequired,
    freshObject: PropTypes.func.isRequired,
    autosave: PropTypes.func.isRequired,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ additionalReferences: nextProps.additionalReferences });
  }

  outsideClickListener = ev => {
    // If the element clicked is an SVG we will get an error if we access `indexOf`
    let className = '';
    if (ev.target instanceof SVGElement) {
      className = ev.target.getAttribute('class');
    } else {
      className = ev.target.className;
    }

    // Remove the listener if canceling out
    if (className?.indexOf('add-btn') !== -1) {
      document.removeEventListener('click', this.outsideClickListener, { capture: true });
    }
    // Don't need to handle the click if canceling out of the card
    if (className?.indexOf('add-btn') === -1) {
      handleOutsideClickOnValidForm(this.node, ev, this.toggleAdd);
    }
  };

  toggleAdd = () => {
    if (!this.state.isAdding) {
      document.addEventListener('click', this.outsideClickListener, { capture: true });
    } else {
      let ref = this.state.addReference;
      if (!ref.first_name || !ref.last_name) {
        this.setState({
          errorMessage: 'First name and last name are required',
        });
        return;
      }
      if (!this.state.addReference.email) {
        this.setState({
          errorMessage: 'Email address is required',
        });
        return;
      } else if (!isValidEmail(this.state.addReference.email)) {
        this.setState({
          errorMessage: 'Please enter a valid email for your reference',
        });
        return;
      }

      document.removeEventListener('click', this.outsideClickListener, { capture: true });
      this.handleAddRow();
    }
    this.setState({ isAdding: !this.state.isAdding, errorMessage: '' });
  };

  updateField = e => {
    var updateObject = this.state.addReference;
    updateObject[e.target.name] = e.target.value;
    // if the user edits a reference that already exists (which we can tell by whether
    // or not it has an id), add an edited flag so we can track the date of the change
    // and display it to the district.
    if (updateObject.id) {
      updateObject.edited = true;
    }
    this.setState({ addReference: updateObject, errorMessage: '' });
  };

  handleAddRow = () => {
    let copyApplication = Object.assign({}, this.state.additionalReferences);

    // insert at certain position if specified, otherwise add to end
    if (this.state.insertPosition !== undefined) {
      copyApplication.list.splice(this.state.insertPosition, 0, this.state.addReference);
    } else {
      copyApplication.list.push(this.state.addReference);
    }

    this.setState(
      {
        additionalReferences: copyApplication,
        addReference: this.freshReference(),
      },
      () => {
        this.props.autosave();
      }
    );
  };

  handleEditRow = (i, e) => {
    let copyApplication = Object.assign({}, this.state.additionalReferences);
    let addNew = Object.assign({}, copyApplication.list[i]);
    copyApplication.list.splice(i, 1);
    // i represents the location of the (now editing) experience. Set it in state so we can use for insertion
    this.setState(
      { addReference: addNew, additionalReferences: copyApplication, insertPosition: i },
      () => {
        this.toggleAdd();
      }
    );
  };

  handleRemoveRow = (i, e) => {
    e.stopPropagation();
    let copyApplication = Object.assign({}, this.state.additionalReferences);
    if (copyApplication.list[i].id) {
      axios.delete(`/api/candidate_reference/${copyApplication.list[i].id}/`);
    }
    copyApplication.list.splice(i, 1);
    this.setState({ additionalReferences: copyApplication }, () => {
      this.props.autosave();
    });
  };

  handleClick = (i, e) => {
    if (this.state.isAdding) {
      this.toggleAdd();
    } else {
      this.handleEditRow(i, e);
    }
  };

  // when adding a new row, insert at end of current list
  resetInsertPosition = () => {
    const insertPosition = this.state.additionalReferences.length;
    this.setState({ insertPosition });
  };

  render() {
    return (
      <div ref={node => (this.node = node)}>
        <div>
          {this.state.additionalReferences.list.map((row, i) => (
            <div className="added-row" key={i} onClick={e => this.handleClick(i, e)}>
              <span className="flex-2">
                <h4>
                  {`${
                    salutationReadable[row['salutation']]
                      ? salutationReadable[row['salutation']] + ' '
                      : ''
                  }${row['first_name']} ${row['last_name']}`}
                </h4>
                <p>{row['title']}</p>
              </span>
              <span className="flex-2">
                <p>{row['email']}</p>
                <p>{row['phone']}</p>
              </span>
              <div className="pointer">
                <span className="mr1">Edit</span>
                <span onClick={e => this.handleRemoveRow(i, e)}>Delete</span>
              </div>
            </div>
          ))}
        </div>
        {this.state.isAdding && (
          <div className="reference-inputs">
            <div className="basic application-row flex">
              <div className="basic-select dropdown salutation">
                <select
                  name="salutation"
                  className={`salutation ${
                    this.state.addReference.salutation >= 0 ? 'black' : 'placeholder'
                  }`}
                  value={this.state.addReference.salutation || ''}
                  onChange={this.updateField}
                >
                  <option value={-1}>Sal.</option>
                  {salutation().map((item, i) => (
                    <option key={i} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <input
                className="form-field flex-1"
                id="reference-first-name-input"
                name="first_name"
                type="text"
                placeholder="Reference first name"
                value={this.state.addReference.first_name}
                onChange={this.updateField}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    this.updateField(e);
                    this.handleAddRow();
                  }
                }}
                required
              />
              <div className="nj-input-asterisk">*</div>
              <input
                className="form-field flex-1"
                id="reference-last-name-input"
                name="last_name"
                type="text"
                placeholder="Reference last name"
                value={this.state.addReference.last_name}
                onChange={this.updateField}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    this.updateField(e);
                    this.handleAddRow();
                  }
                }}
                required
              />
              <div className="nj-input-asterisk">*</div>
            </div>
            <div className="basic application-row flex">
              <input
                className="form-field flex-1"
                id="reference-job-title-input"
                name="title"
                type="text"
                placeholder="Reference job title"
                value={this.state.addReference.title}
                onChange={this.updateField}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    this.updateField(e);
                    this.handleAddRow();
                  }
                }}
              />
              <input
                className="form-field flex-1"
                id="reference-organization-name-input"
                name="organization"
                type="text"
                placeholder="Organization name"
                value={this.state.addReference.organization}
                onChange={this.updateField}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    this.updateField(e);
                    this.handleAddRow();
                  }
                }}
              />
              <input
                className="form-field flex-1"
                id="reference-relationship-input"
                name="relationship"
                type="text"
                placeholder="Relationship (e.g. Supervisor)"
                value={this.state.addReference.relationship}
                onChange={this.updateField}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    this.updateField(e);
                    this.handleAddRow();
                  }
                }}
              />
            </div>
            <div className="basic application-row flex">
              <input
                name="years_known"
                type="number"
                style={{ maxWidth: '20%' }}
                className="form-field flex-1"
                placeholder="Years known"
                min="0"
                value={this.state.addReference.years_known}
                onChange={this.updateField}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    this.updateField(e);
                    this.handleAddRow();
                  }
                }}
              />
              <input
                className="form-field flex-1"
                id="reference-phone-number-input"
                name="phone"
                type="text"
                placeholder="Phone number"
                value={this.state.addReference.phone}
                onChange={this.updateField}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    this.updateField(e);
                    this.handleAddRow();
                  }
                }}
              />
              <input
                className="form-field flex-1"
                id="reference-email-input"
                name="email"
                type="text"
                placeholder="Email address"
                value={this.state.addReference.email}
                onChange={this.updateField}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    this.updateField(e);
                    this.handleAddRow();
                  }
                }}
                required
              />
              <div className="nj-input-asterisk">*</div>
            </div>
          </div>
        )}
        {this.state.isAdding ? (
          <div className="flex add-card-container">
            {this.state.insertPosition === undefined && (
              <div
                className="add-btn cancel"
                onClick={() => {
                  this.setState({
                    isAdding: false,
                    addReference: this.props.freshObject(),
                    errorMessage: '',
                  });
                }}
              >
                Cancel
              </div>
            )}
            <div
              className="add-btn save"
              id="reference-save-button"
              onClick={() => {
                this.toggleAdd();
                this.resetInsertPosition();
              }}
            >
              Save Entry
            </div>
          </div>
        ) : (
          <div
            className="add-btn add-entry"
            id="reference-add-button"
            onClick={() => {
              this.toggleAdd();
              this.resetInsertPosition();
            }}
          >
            <img src={this.state.isAdding ? iconMinus : iconPlus} alt="Icon" />
            Add Entry
          </div>
        )}
        <StyledErrorText>
          {this.state.errorMessage && <ErrorText message={this.state.errorMessage} />}
        </StyledErrorText>
      </div>
    );
  }
}

const StyledErrorText = styled.div`
  margin-top: 1rem;
`;
