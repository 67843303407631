import { Component } from 'react';

import PropTypes from 'prop-types';
import { fetchDistrictTasks } from '../../utils/universalTasksUtil';
import UniversalTasksRow from './UniversalTasksRow';
import UniversalTasksModal from './UniversalTasksModal';
import { showWarning } from '../../utils/message';
import axios from 'axios';
import { AnchorTagHeader } from 'sharedComponents/Header/AnchorTagHeader';

export default class UniversalTasksList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      universal_tasks: [],
      showTaskModal: false,
    };
    this.fetchDistrictTasks = fetchDistrictTasks.bind(this);
  }

  static propTypes = {
    newApplicationStatuses: PropTypes.array.isRequired,
    adminUsers: PropTypes.array.isRequired,
  };

  componentDidMount() {
    this.fetchDistrictTasks();
  }

  freshTask = () => {
    return {
      id: '',
      title: '',
      trigger_days: '',
      trigger_status_id: '',
      assigned_users: [],
    };
  };

  onSave = (task, index = null) => {
    let universal_tasks = this.state.universal_tasks;
    let method;
    let url;
    if (index !== null) {
      // Updating an existing task.
      universal_tasks[index] = task;
      method = 'put';
      url = `/api/task/${universal_tasks[index].id}/`;
    } else {
      // Saving a new task.
      universal_tasks.push(task);
      method = 'post';
      url = '/api/tasks/';
    }

    let taskCopy = Object.assign({}, task);
    // backend expects a list of user pks for assigned users.
    // if candidate_assigned is true, there will be an object with
    // id of 0 as a placeholder generic candidate. That's only used for
    // display purposes in the modal Select component (third party).
    // We can remove that id here before sending to backend.
    taskCopy.assigned_users = task.assigned_users.map(u => u.id).filter(id => id !== 0);

    axios[method](url, taskCopy)
      .then(() => this.fetchDistrictTasks())
      .catch(() =>
        showWarning(
          'There was an error updating this task. Please contact support@hirenimble.com for assistance.',
          20000
        )
      );

    this.setState({ universal_tasks, showTaskModal: false });
  };

  onDelete = id => {
    axios
      .delete(`/api/task/${id}/`)
      .catch(() =>
        showWarning(
          'There was an error deleting this task. Please contact support@hirenimble.com for assistance.',
          20000
        )
      );

    // make update on front end instantly.
    let universal_tasks = this.state.universal_tasks;
    let index = universal_tasks.findIndex(t => t.id === id);
    if (index !== -1) {
      universal_tasks.splice(index, 1);
    }
    this.setState({ universal_tasks });
  };

  render() {
    return (
      <div className="application-status-container mb2">
        <div className="mt2">
          <AnchorTagHeader
            id="tasks"
            title="Tasks"
            buttonText="+ Task"
            onClick={() => this.setState({ showTaskModal: true })}
          />
          {this.state.universal_tasks.map((task, i) => (
            <UniversalTasksRow
              key={i}
              index={i}
              task={task}
              onSave={this.onSave}
              onDelete={this.onDelete}
              adminUsers={this.props.adminUsers}
              newApplicationStatuses={this.props.newApplicationStatuses}
            />
          ))}
        </div>
        {this.state.showTaskModal && (
          <UniversalTasksModal
            task={this.freshTask()}
            show={this.state.showTaskModal}
            onHide={() => this.setState({ showTaskModal: false })}
            onSave={this.onSave}
            adminUsers={this.props.adminUsers}
            newApplicationStatuses={this.props.newApplicationStatuses}
          />
        )}
      </div>
    );
  }
}
