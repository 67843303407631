import useSWR from 'swr';
import axios from 'axios';

export default function useHelloSignPackets() {
  const { data } = useSWR(`/api/hellosign-packets/`, url =>
    axios.get(url).then(response => response.data)
  );

  const packetsLoading = data === undefined;

  return { packets: data ?? [], packetsLoading };
}
