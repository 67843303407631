export default function ErrorText(props) {
  if (!props.message) {
    return null;
  }
  const color = props.color ? props.color : '#CC0000';
  return (
    <p
      className={`error-text ${props.className}`}
      style={{ color: color }}
      data-testid="error-text"
    >
      {props.message}
    </p>
  );
}
