import { Component } from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import { roleSections } from '../../utils/enums';

export default class JobSectionSelection extends Component {
  static propTypes = {
    job: PropTypes.object.isRequired,
    updateJobSectionSettings: PropTypes.func.isRequired,
    updateField: PropTypes.func.isRequired,
  };

  checkbox = (sectionValue, setting) => {
    const roleSection = this.props.job.role_sections?.find(r => r.section === sectionValue);

    if (!roleSection) {
      return null;
    }

    return (
      <input
        type="checkbox"
        className="nimble-checkbox"
        name="job-section-settings"
        value={roleSection[setting]}
        checked={roleSection[setting]}
        onChange={event => {
          this.props.updateJobSectionSettings(event, sectionValue, setting);
        }}
      />
    );
  };

  renderSections = () => {
    return roleSections().map(item => {
      return (
        <div className={`flex checkbox-row ${item.value}`} key={item.value}>
          <div className="col bold shaded">{item.label}</div>
          <div className="col checkbx">{this.checkbox(item.value, 'visible')}</div>
          <div className="col checkbx">{this.checkbox(item.value, 'required')}</div>
          {this.props.job.internal_requirements_specified && (
            <div className="col checkbx">{this.checkbox(item.value, 'visible_internal')}</div>
          )}
          {this.props.job.internal_requirements_specified && (
            <div className="col checkbx">{this.checkbox(item.value, 'required_internal')}</div>
          )}
        </div>
      );
    });
  };

  render() {
    return (
      <div className="job-section-selection-component">
        <div className="switch-and-text-container">
          <div
            className={`switch-container ${
              this.props.job.internal_requirements_specified ? 'on' : ''
            }`}
            onClick={() => {
              this.props.updateField({
                target: {
                  name: 'internal_requirements_specified',
                  value: !this.props.job.internal_requirements_specified,
                },
              });
            }}
          >
            <div
              className={`switch-circle ${
                this.props.job.internal_requirements_specified ? 'on' : ''
              }`}
            />
          </div>
          <div className="switch-text">Specify application experience for internal candidates</div>
        </div>
        <div>
          <Label className="bold">Application Sections Settings</Label>
          <div className="flex flex-col checkbox-container">
            {this.props.job.internal_requirements_specified && (
              <div className="flex internal-external">
                <div className="">External candidates</div>
                <div className="">Internal candidates</div>
              </div>
            )}
            <div className="flex checkbox-row row-headers">
              <div className="col" />
              <div className="col shaded">Visible</div>
              <div className="col shaded">Required</div>
              {this.props.job.internal_requirements_specified && (
                <div className="col shaded">Visible</div>
              )}
              {this.props.job.internal_requirements_specified && (
                <div className="col shaded">Required</div>
              )}
            </div>

            {this.renderSections()}
          </div>
        </div>
      </div>
    );
  }
}

const Label = styled.h5`
  margin: 3rem 0 0.8rem;
  font-size: 16px;
  font-weight: 600;
  color: #444444;
`;
