import styled from 'styled-components';
import { MultipleChoiceAnswerProps } from './types';

const MultipleChoiceAnswer: React.FC<MultipleChoiceAnswerProps> = ({
  answer,
  updateMultipleChoiceField,
  index,
}) => {
  return (
    <div>
      {answer.multi_select && <MultiSelectText>Select as many as apply.</MultiSelectText>}
      <GridContainer>
        {answer.mc_options.map((option, i) => (
          <Option key={i}>
            <input
              type="checkbox"
              name="multiple_choice"
              value={option}
              checked={answer.mc_answer?.includes(option) ?? false}
              onChange={e => updateMultipleChoiceField(e, index, answer.multi_select)}
            />
            &nbsp;&nbsp;{option}
          </Option>
        ))}
      </GridContainer>
    </div>
  );
};

export default MultipleChoiceAnswer;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 3px;
`;

const MultiSelectText = styled.div`
  fontsize: 12px;
  margintop: 4px;
`;

const Option = styled.label`
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;

  color: #777777;
`;
