export default {
  control: {
    backgroundColor: '#fff',
    marginBottom: '15px',
    border: '1px solid #D7D7D7',
    borderRadius: '3px'
  },

  highlighter: {
    overflow: 'hidden'
  },

  '&multiLine': {
    input: {
      padding: 9,
      minHeight: 80,
      outline: 0,
      border: 0
    }
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      width: 225,
      maxHeight: 223,
      overflow: 'auto',
      position: 'absolute',
      bottom: 14,
      left: -19,
      boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.1)'
    },

    item: {
      padding: '8px',
      '&focused': {
        backgroundColor: '#f5f5f5'
      }
    }
  }
};
