import { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

export default class ApplicationsNote extends Component {
  static propTypes = {
    userFirstName: PropTypes.string.isRequired,
    applications: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  render() {
    return (
      <div className="applications-note">
        <div className="applications-note-text">
          Note: {this.props.userFirstName} has applied for{' '}
          <span className="underline" data-tip data-for="applications">
            {this.props.applications.length} job
            {this.props.applications.length === 1 ? '' : 's'}
          </span>{' '}
          on Nimble.
        </div>
        <ReactTooltip id="applications" effect="solid" place="top">
          <span>
            {this.props.applications.map((application, idx) => (
              <div key={idx}>
                {application['role__title']} ({application['new_status__label']})
              </div>
            ))}
          </span>
        </ReactTooltip>
        <ul className="applications-printed-list hidden-unless-printing ">
          {this.props.applications.map((application, idx) => {
            return (
              <li key={idx}>
                {application['role__title']} ({application['new_status__label']})
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
