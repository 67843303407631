import {
  CartesianGrid,
  Cell,
  BarChart,
  Bar,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';

import * as DashboardWidget from './components/DashboardWidget';
import * as Toggle from './components/Toggle';
import { CHART_COLOR_PALETTES } from './constants';
import { useApplicationsPerVacancyMetrics } from './useApplicationsPerVacancyMetrics';
import auth from 'utils/auth';
import { PROD_URL, STAGING_URL } from '../../utils/constants';

const ApplicationsPerVacancyWidget = ({ hiringSeasons, schools, subcategories }) => {
  const categorySchoolToggleState = Toggle.useToggleState();
  const groupedUngroupToggleState = Toggle.useToggleState();

  // hardcode all the large district ids that could fail
  // APS?
  let slowDistrictsIDs = [];
  if (window.location.hostname === STAGING_URL) {
    // 149 - Hartford Public Schools,
    // 17 - Saint Louis Public Schools,
    // 63 - Austin Achieve Public Schools
    // 61 - Indianapolis Public Schools
    slowDistrictsIDs = [149, 17, 63, 61];
  } else if (window.location.hostname === PROD_URL) {
    // 142 - Hartford,
    // 48 - Saint Louis Public Schools,
    // 126 - Austin Achieve Public Schools
    // 124 - Indianapolis Public Schools
    slowDistrictsIDs = [142, 48, 126, 124];
  }

  // get the user's district id
  const userDistrictId = auth.getUser().profile?.district?.id;
  const userInSlowDistrict = slowDistrictsIDs.includes(userDistrictId);

  const { data, isLoading } = useApplicationsPerVacancyMetrics({
    hiringSeasons,
    schools,
    subcategories,
    userInSlowDistrict,
  });

  let dataset;
  let placeholderLabel;
  if (groupedUngroupToggleState.modeOneIsActive) {
    dataset = categorySchoolToggleState.modeOneIsActive ? data.by_category : data.by_school_group;
    placeholderLabel = categorySchoolToggleState.modeOneIsActive ? 'categories' : 'school groups';
  } else {
    dataset = categorySchoolToggleState.modeOneIsActive ? data.by_subcategory : data.by_school;
    placeholderLabel = categorySchoolToggleState.modeOneIsActive ? 'subcategories' : 'schools';
  }

  dataset = dataset.map(metric => {
    let value = 0;
    const schoolVacancies = metric.school_vacancies;
    // schools and school groups will not have district_vacancies, so add a fallback of 0
    const districtVacancies = metric.district_vacancies ?? 0;
    const totalVacancies = schoolVacancies + districtVacancies;

    if (totalVacancies > 0) {
      value = metric.applications / totalVacancies;
    }
    return { ...metric, value };
  });
  const maxValue = Math.ceil(Math.max(...dataset.map(item => item.value)));
  const COLORS = CHART_COLOR_PALETTES[8];
  const height = Math.max(dataset.length > 0 ? dataset.length * 50 : 300, 300);

  return (
    <DashboardWidget.Container>
      <DashboardWidget.Heading>Applications per vacancy</DashboardWidget.Heading>
      <DashboardWidget.Body>
        <DashboardWidget.BodyActions>
          <Toggle.Wrapper>
            <Toggle.ToggleItem
              isActive={groupedUngroupToggleState.modeOneIsActive}
              onClick={groupedUngroupToggleState.setModeOneIsActive}
            >
              Group
            </Toggle.ToggleItem>
            <Toggle.ToggleItem
              isActive={groupedUngroupToggleState.modeTwoIsActive}
              onClick={groupedUngroupToggleState.setModeTwoIsActive}
            >
              Ungroup
            </Toggle.ToggleItem>
          </Toggle.Wrapper>
          <DashboardWidget.BodyActionPulledRight>
            <Toggle.Wrapper>
              <Toggle.ToggleItem
                isActive={categorySchoolToggleState.modeOneIsActive}
                onClick={categorySchoolToggleState.setModeOneIsActive}
              >
                Category
              </Toggle.ToggleItem>
              <Toggle.ToggleItem
                isActive={categorySchoolToggleState.modeTwoIsActive}
                onClick={categorySchoolToggleState.setModeTwoIsActive}
              >
                School
              </Toggle.ToggleItem>
            </Toggle.Wrapper>
          </DashboardWidget.BodyActionPulledRight>
        </DashboardWidget.BodyActions>
        <DashboardWidget.BodyContent isLoading={isLoading}>
          {userInSlowDistrict && (
            <Placeholder>
              We are currently making some improvements to this chart. You can still download the
              raw data using the button in the top right corner of the page. For further assistance,
              contact <a href={'mailto:support@hirenimble.com'}>support@hirenimble.com.</a>
            </Placeholder>
          )}
          {(dataset.length === 0 && !userInSlowDistrict && (
            <Placeholder>{`No ${placeholderLabel} found. You can add some under settings.`}</Placeholder>
          )) || (
            <ResponsiveContainer width="99%" height={height}>
              <BarChart data={dataset} layout="vertical">
                <CartesianGrid horizontal={false} />
                <YAxis
                  type="category"
                  dataKey="name"
                  interval={0}
                  tickLine={false}
                  width={175}
                  tick={<TickLabel />}
                />
                <XAxis
                  type="number"
                  domain={[0, maxValue]}
                  tickLine={false}
                  tickFormatter={value => (value > 0 ? `${value}:1` : value)}
                />
                <Tooltip separator="" formatter={value => [`${formatValue(value)} : 1`, '']} />
                <Bar dataKey="value" fill="#00B88D">
                  {dataset.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )}
        </DashboardWidget.BodyContent>
      </DashboardWidget.Body>
    </DashboardWidget.Container>
  );
};

export default ApplicationsPerVacancyWidget;

const TickLabel = ({ payload, ...passthroughProps }) => (
  <Text {...passthroughProps} style={{ fontSize: '11' }}>
    {payload.value}
  </Text>
);

const formatValue = value => {
  return value.toLocaleString('en-US', {
    style: 'decimal',
    maximumFractionDigits: 2,
  });
};

const Placeholder = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
