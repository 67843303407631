import styled from 'styled-components';
import { YesNoVariantProps } from './types';

const YesNoVariant: React.FC<YesNoVariantProps> = ({
  yesOptionValue,
  noOptionValue,
  fetchedTags,
  handleSetYesOptionValue,
  handleSetNoOptionValue,
}) => {
  const handleYesChange = event => {
    const value = event.target.value;

    if (value === 'yes-answer') {
      handleSetYesOptionValue(null);
    } else {
      handleSetYesOptionValue(value);
    }
  };

  const handleNoChange = event => {
    const value = event.target.value;

    if (value === 'yes-answer') {
      handleSetNoOptionValue(null);
    } else {
      handleSetNoOptionValue(value);
    }
  };

  return (
    <Container>
      <IndividualOption>
        If candidate responds &quot;Yes&quot;:
        <Select onChange={handleYesChange} value={yesOptionValue ? yesOptionValue : 'yes-answer'}>
          <Option value={'yes-answer'}>Select a tag</Option>
          {fetchedTags.map(fetchedTag => {
            return (
              <Option value={fetchedTag.id} key={fetchedTag.id} placeholder="Select a tag">
                {fetchedTag.label}
              </Option>
            );
          })}
        </Select>
      </IndividualOption>

      <IndividualOption>
        If candidate responds &quot;No&quot;:
        <Select onChange={handleNoChange} value={noOptionValue ? noOptionValue : 'no-answer'}>
          <Option value="no-answer">Select a tag</Option>
          {fetchedTags.map(fetchedTag => (
            <Option value={fetchedTag.id} key={fetchedTag.label} placeholder="Select a tag">
              {fetchedTag.label}
            </Option>
          ))}
        </Select>
      </IndividualOption>
    </Container>
  );
};

export default YesNoVariant;

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const Select = styled('select')({
  background: 'white',
  border: '1px solid #d7d7d7',
  borderRadius: '3px',
  marginLeft: '16px',
  padding: '14px 16px',
  width: '375px',
});

const IndividualOption = styled('div')({
  alignItems: 'center',
  display: 'flex',
  paddingBottom: '24px',
});

const Option = styled('option')({
  paddingBottom: '8px',
});
