import { RefObject, useEffect, useRef } from 'react';

const useClickOutside = <T extends HTMLElement>(callback: () => void): RefObject<T> => {
  const ref = useRef<T>();
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handleClickOutside = ({ target }) => {
      const currentElement = ref.current;

      if (currentElement && !currentElement.contains(target)) {
        callbackRef.current();
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return ref;
};

export default useClickOutside;
