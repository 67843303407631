import { Body } from 'ui-kit/Modal';
import { TextIcon } from 'ui-kit/icons';
import { Textarea } from 'ui-kit';
import { ModalBodySectionHeader } from '../styledComponents';

import HeaderInformation from './HeaderInformation';

export default function TextQuestion({
  questionText,
  setQuestionText,
  internalRequirementsSpecified,
  handleItemMatrixChange,
  itemMatrix,
}) {
  return (
    <Body>
      <HeaderInformation
        Icon={TextIcon}
        title="Text Question"
        subtext="Candidate can respond in free form text"
        internalRequirementsSpecified={internalRequirementsSpecified}
        handleItemMatrixChange={handleItemMatrixChange}
        itemMatrix={itemMatrix}
      />
      <ModalBodySectionHeader>Type text question here:</ModalBodySectionHeader>
      <Textarea
        placeholder="e.g. what is your pedagogical approach"
        value={questionText}
        onChange={e => setQuestionText(e.target.value)}
        width={1}
      />
    </Body>
  );
}
