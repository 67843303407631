import { useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

import GoButton from 'components/Home/goButton';
import StatusIcon from 'components/Home/statusIcon';
import {
  ContentRow,
  HeaderLabel,
  HeaderRow,
  HomepageContentContainer,
} from 'components/Home/common';
import useHelloSignSignatureRequests from 'hooks/data/useHelloSignSignatureRequests';

export default function HelloSignSignatureRequests({ Wrapper }) {
  const signatureRequests = useHelloSignSignatureRequests();

  const sortedSignatureRequests = useMemo(
    () => _.sortBy(signatureRequests, ['due_date', 'candidate_last_name']),
    [signatureRequests]
  );

  if (!signatureRequests?.length) {
    return null;
  }

  return (
    <Wrapper>
      <HomepageContentContainer>
        <HeaderLabel>Outstanding forms</HeaderLabel>
        <Container>
          <HeaderRow>
            <span>Name</span>
            <span>Candidate name</span>
            <span>Job title</span>
            <span>Due</span>
          </HeaderRow>
          {sortedSignatureRequests.map(signature => {
            const isComplete = signature.status_code === 'signed';
            const isOverdue = signature.due_date && moment(Date.now()).isAfter(signature.due_date);
            const dueDateText = signature.due_date
              ? moment(signature.due_date).format('M/D/YYYY')
              : '';

            return (
              <ContentRow key={signature.signature_id} isOverdue={!isComplete && isOverdue}>
                <Cell>
                  <StatusIcon isComplete={isComplete} isOverdue={isOverdue} />
                  {signature.title}
                </Cell>
                <Cell>{signature.candidate_name}</Cell>
                <Cell>{signature.job_title}</Cell>
                <Cell>{dueDateText}</Cell>
                <GoButton
                  url={`/hellosign-embedded-sign/${signature.signature_id}`}
                  caption={'Sign >'}
                />
              </ContentRow>
            );
          })}
        </Container>
      </HomepageContentContainer>
    </Wrapper>
  );
}

const Container = styled.div`
  padding: 20px 0;

  background-color: #ffffff;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 10%);
`;

const Cell = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
