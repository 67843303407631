import { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import SchoolApplicationsModal from './SchoolApplicationsModal';
import Pencil from '../../../assets/icon-edit-pencil.svg';

export default class SchoolapplicationsNote extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    role: PropTypes.object.isRequired,
    schoolapplications: PropTypes.array.isRequired,
    newApplicationStatuses: PropTypes.array.isRequired,
    isEditable: PropTypes.bool.isRequired,
    replaceCandidateInState: PropTypes.func.isRequired,
    fetchEvents: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      schoolsModalOpen: false,
    };
  }

  render() {
    return (
      <div className="applications-note edit-schools">
        <div className="applications-note-text">
          This application is visible to{' '}
          <span className="underline" data-tip data-for="schools">
            {this.props.schoolapplications.length} school
            {this.props.schoolapplications.length === 1 ? '' : 's'}
          </span>
          .
        </div>
        {this.props.schoolapplications.length > 0 && (
          <ReactTooltip
            id="schools"
            effect="solid"
            place="bottom"
            clickable
            delayHide={500}
            delayUpdate={500}
          >
            <StyledTooltip>
              {this.props.schoolapplications.map(schoolapp => {
                return (
                  <TooltipRow key={schoolapp.id}>
                    {`${schoolapp.school.name} (${schoolapp.new_status.label})`}
                  </TooltipRow>
                );
              })}
            </StyledTooltip>
          </ReactTooltip>
        )}

        {this.props.isEditable && (
          <img
            onClick={() => this.setState({ schoolsModalOpen: true })}
            style={{ cursor: 'pointer' }}
            src={Pencil}
            alt="edit"
          />
        )}

        {this.state.schoolsModalOpen && (
          <SchoolApplicationsModal
            show={this.state.schoolsModalOpen}
            onHide={() => this.setState({ schoolsModalOpen: false })}
            replaceCandidateInState={this.props.replaceCandidateInState}
            fetchEvents={this.props.fetchEvents}
            schoolapplications={this.props.schoolapplications}
            role={this.props.role}
            user={this.props.user}
            newApplicationStatuses={this.props.newApplicationStatuses}
          />
        )}
      </div>
    );
  }
}

const TooltipRow = styled.div`
  color: #fff !important;
`;

const StyledTooltip = styled.div`
  max-height: 400px;
  overflow-y: auto;
  color: #fff !important;
`;
