import { useEffect, useState } from 'react';

import { Body } from 'ui-kit/Modal';
import { YesNoIcon } from 'ui-kit/icons';
import { Textarea } from 'ui-kit';
import { ModalBodySectionHeader } from '../styledComponents';

import HeaderInformation from './HeaderInformation';
import ReactTooltip from 'react-tooltip';

import AutoTagsModalOpenButton from '../../AutoTags/Modal/AutoTagsModalOpenButton';
import AutoTagsModal from '../../AutoTags/Modal/AutoTagsModal';
import { QuestionType, Tag } from 'types/types';
import { YesNoQuestionProps } from '../types';
import { TagState } from '../../AutoTags/Modal/types';

const YesNoQuestion: React.FC<YesNoQuestionProps> = ({
  existingAutoTags,
  handleItemMatrixChange,
  handleSetAutoTags,
  internalRequirementsSpecified,
  itemMatrix,
  questionText,
  setQuestionText,
  authorizedTags,
  questionHasHiddenTags,
  hasTagsAvailable,
  shouldShowAutoTagLink,
}) => {
  const [shouldShowTagModal, setShouldShowTagModal] = useState(false);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const handleTagSave = (tagState: TagState) => {
    const autotags: Tag[] = [];

    if (tagState.Yes) {
      autotags.push({ tag_id: tagState.Yes, option: 'Yes' });
    }

    if (tagState.No) {
      autotags.push({ tag_id: tagState.No, option: 'No' });
    }

    handleSetAutoTags(autotags);
  };

  const handleTagModalOpen = () => setShouldShowTagModal(true);
  const handleTagModalClose = () => setShouldShowTagModal(false);

  return (
    <Body>
      <HeaderInformation
        Icon={YesNoIcon}
        title="Yes/No Question"
        subtext='Candidate can select "yes" or "no"'
        internalRequirementsSpecified={internalRequirementsSpecified}
        handleItemMatrixChange={handleItemMatrixChange}
        itemMatrix={itemMatrix}
      />
      <ModalBodySectionHeader>Type yes/no question here:</ModalBodySectionHeader>
      <Textarea
        placeholder="e.g. Do you like working with kids?"
        value={questionText}
        onChange={e => setQuestionText(e.target.value)}
        width={1}
      />
      {shouldShowAutoTagLink && (
        <AutoTagsModalOpenButton
          handleTagModalOpen={handleTagModalOpen}
          hasSetTags={existingAutoTags?.length > 0}
          questionHasHiddenTags={questionHasHiddenTags}
          hasTagsAvailable={hasTagsAvailable}
        />
      )}
      {shouldShowTagModal && (
        <AutoTagsModal
          onClose={handleTagModalClose}
          questionType={QuestionType.yes_no}
          show={shouldShowTagModal}
          existingAutoTags={existingAutoTags}
          fetchedTags={authorizedTags}
          onSave={handleTagSave}
        />
      )}
    </Body>
  );
};

export default YesNoQuestion;
