import { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import axios from 'axios';
import debounce from 'lodash/debounce';

export const useStateWideJobs = (
  {
    stateCode,
    page,
    query,
    grades,
    fulltime,
    schoolIds,
    subcategoryStateIds,
    districtIds,
    startDate,
    startDateBefore,
    startDateAfter,
  },
  activeFilters,
  setIsLoading,
  useDebounce
) => {
  const [fetchingNextPage, setFetchingNextPage] = useState(false);
  const [error, setError] = useState(false);
  const [count, setCount] = useState(0);
  const [results, setResults] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  const searchParams = {
    stateCode,
    page,
    query,
    grades,
    fulltime,
    schoolIds,
    subcategoryStateIds,
    districtIds,
    startDate,
    startDateBefore,
    startDateAfter,
  };

  // If a filter is not active, remove it from searchParams.
  // This handles the case where a filter has all items selected.
  // In that case, the filter is considered inactive and we don't
  // want to exclude any results.
  for (const [key, value] of Object.entries(searchParams)) {
    if (value === null || activeFilters[key] === false) {
      delete searchParams[key];
    }
  }

  if (searchParams.startDateBefore) {
    // Use substring to trim down to date only
    searchParams.startDateBefore = moment(searchParams.startDateBefore).toISOString();
  }

  if (searchParams.startDateAfter) {
    // Use substring to trim down to date only
    searchParams.startDateAfter = moment(searchParams.startDateAfter).toISOString();
  }

  const usp = new URLSearchParams(searchParams);

  // Build a stable queryString and re-fetch when it changes
  usp.sort();
  const queryString = '?' + usp.toString();

  const getStateRoles = (searchParams, loadMoreRoles = false) => {
    let cancel;
    // When only a few schools or orgs are deselected from the filter, the url becomes too long.
    // In most cases the server returns a 414 HTTP error, and all is well.  Of course
    // Heroku had to be different, so they just ignore this request at their router and
    // it never reaches our django server.
    if (searchParams.schoolIds?.length > 100 || searchParams.districtIds?.length > 100) {
      const e = new Error('URL Too Long');
      e.response = { status: 414 };
      e.filterName = searchParams.schoolIds?.length > 100 ? 'schoolIds' : 'districtIds';
      setError(e);
      cancel = () => {}; // no-op
    } else {
      setError(false);
      axios({
        method: 'GET',
        url: '/api/search/stateroles/',
        params: searchParams,
        cancelToken: new axios.CancelToken(c => (cancel = c)),
      })
        .then(res => {
          if (fetchingNextPage || loadMoreRoles) {
            setResults(prev => [...prev, ...res.data.results]);
          } else {
            setResults(res.data.results);
          }
          setCount(res.data.count);
          setHasMore(res.data.more);
          setIsLoading(false);
          setFetchingNextPage(false);
        })
        .catch(e => {
          if (axios.isCancel(e)) return;
          setError(e);
        });
    }
    return () => cancel();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getStateRolesDebounced = useCallback(
    debounce((searchParams, loadMoreRoles) => getStateRoles(searchParams, loadMoreRoles), 2000),
    []
  );

  useEffect(() => {
    if (useDebounce) {
      getStateRolesDebounced(searchParams, fetchingNextPage);
    } else {
      getStateRoles(searchParams);
    }
    // eslint-disable-next-line
  }, [queryString]);

  return { error, results, count, hasMore, fetchingNextPage, setFetchingNextPage };
};
