import styled from 'styled-components';
import { color, border } from 'styled-system';

import * as DashboardWidget from './components/DashboardWidget';
import * as Toggle from './components/Toggle';
import { ReactComponent as RightArrow } from '../../assets/right-arrow.svg';
import { ChoiceDropdown, useChoiceDropdown } from './components/ChoiceDropdown';
import { DIVERSITY_CHOICES } from './constants';
import { useOnboardingConversionMetrics } from './useOnboardingConversionMetrics';
import { appStatusType } from 'utils/enums';
import { theme } from 'ui-kit';

const OnboardingConversionMetricsWidget = ({
  hiringSeasons,
  schools,
  subcategories,
  applicationStatuses,
}) => {
  const toggleState = Toggle.useToggleState();

  const diversityDropdownState = useChoiceDropdown({
    choices: DIVERSITY_CHOICES,
    initialSelectedChoiceIds: DIVERSITY_CHOICES.map(choice => choice.id),
  });

  const { data, isLoading } = useOnboardingConversionMetrics({
    hiringSeasons,
    schools,
    subcategories,
    genders: DIVERSITY_CHOICES.filter(choice => choice.group === 'gender')
      .filter(choice => !choice.isGroupHeader)
      .filter(choice => diversityDropdownState.selectedChoiceIds.includes(choice.id))
      .map(choice => choice.value),
    ethnicities: DIVERSITY_CHOICES.filter(choice => choice.group === 'enthnicity')
      .filter(choice => !choice.isGroupHeader)
      .filter(choice => diversityDropdownState.selectedChoiceIds.includes(choice.id))
      .map(choice => choice.value),
  });

  const hiredStatus = applicationStatuses.find(
    status => status.status_type === appStatusType.hired
  );
  const onboardingStatuses = applicationStatuses.filter(
    status => status.status_type === appStatusType.onboarding
  );

  const applicationsDataset = toggleState.modeOneIsActive
    ? data.hired_applications.pass_rate
    : data.hired_applications.pass_time;

  let hiredApplicationsCount;
  if (applicationsDataset) {
    hiredApplicationsCount = applicationsDataset[hiredStatus.label];
  }

  let message;
  if (onboardingStatuses.length === 0 || !applicationsDataset) {
    message = 'To add onboarding statuses, visit your settings page.';
  }

  let colors = [
    { ...theme.uiColors.yellows },
    { ...theme.uiColors.reds },
    { ...theme.uiColors.purples },
    { ...theme.uiColors.blues },
    { ...theme.uiColors.greens },
  ];
  let colorsIndex = 1; // 0 is used before the loop on the first time around

  return (
    <>
      <DashboardWidget.Heading data-testid="onboarding-header">
        Onboarding conversions
      </DashboardWidget.Heading>
      <MetricsWidgetBody>
        <DashboardWidget.BodyActions>
          <ChoiceDropdown
            label="Demographics"
            choices={DIVERSITY_CHOICES}
            {...diversityDropdownState}
          />
          <DashboardWidget.BodyActionPulledRight>
            <Toggle.Wrapper>
              <Toggle.ToggleItem
                isActive={toggleState.modeOneIsActive}
                onClick={toggleState.setModeOneIsActive}
              >
                Pass %
              </Toggle.ToggleItem>
              <Toggle.ToggleItem
                isActive={toggleState.modeTwoIsActive}
                onClick={toggleState.setModeTwoIsActive}
              >
                Days
              </Toggle.ToggleItem>
            </Toggle.Wrapper>
          </DashboardWidget.BodyActionPulledRight>
        </DashboardWidget.BodyActions>
        <DashboardWidget.BodyContent isLoading={isLoading}>
          {message ? (
            <Placeholder>{message}</Placeholder>
          ) : (
            <MetricItemsContainer>
              <MetricItems>
                {toggleState.modeOneIsActive && (
                  <MetricItemWrapper>
                    <MetricItem
                      color={colors[0].full}
                      bg={colors[0].xlight}
                      border={`2px solid ${colors[0].full}`}
                    >
                      <MetricItemValue>{hiredApplicationsCount}</MetricItemValue>
                      <MetricItemLabel>
                        {hiredStatus?.label ?? 'Applications hired'}
                      </MetricItemLabel>
                    </MetricItem>
                    <RightArrow />
                  </MetricItemWrapper>
                )}
                {onboardingStatuses.map((onboardingStatus, i) => {
                  if (colorsIndex >= colors.length - 1) {
                    colorsIndex = 0;
                  } else {
                    colorsIndex += 1;
                  }

                  return (
                    <MetricItemWrapper key={onboardingStatus.id}>
                      <MetricItem
                        color={colors[colorsIndex].full}
                        bg={colors[colorsIndex].xlight}
                        border={`2px solid ${colors[colorsIndex].full}`}
                      >
                        <MetricItemValue>
                          {applicationsDataset[onboardingStatus.label]}
                        </MetricItemValue>

                        <MetricItemLabel>{onboardingStatus.label}</MetricItemLabel>
                        {toggleState.modeOneIsActive && (
                          <MetricItemSmallText>
                            {hiredApplicationsCount > 0 && (
                              <>
                                {'('}
                                {(
                                  applicationsDataset[onboardingStatus.label] /
                                  hiredApplicationsCount
                                ).toLocaleString('en-US', {
                                  style: 'percent',
                                  minimumFractionDigits: 1,
                                })}
                                {')'}
                              </>
                            )}
                          </MetricItemSmallText>
                        )}
                      </MetricItem>
                      {onboardingStatus.id !==
                        onboardingStatuses[onboardingStatuses.length - 1].id && <RightArrow />}
                    </MetricItemWrapper>
                  );
                })}
              </MetricItems>
            </MetricItemsContainer>
          )}
        </DashboardWidget.BodyContent>
      </MetricsWidgetBody>
    </>
  );
};

export default OnboardingConversionMetricsWidget;

// Allow height to grow to contain wrapping items
const MetricsWidgetBody = styled(DashboardWidget.Body)`
  height: 100%;
  min-height: 290px;
`;

// Allow items to wrap on small screens
const MetricItemsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  padding-bottom: 20px;

  width: 100%;
`;

const MetricItems = styled.div`
  display: flex;
  flex-flow: row wrap;

  margin: 10px 0 0;
`;

const MetricItemWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;

  & > svg {
    width: 10px;
    height: 10px;

    fill: #444444;

    margin-top: 36px;
    margin-left: 6px;
    margin-right: 6px;
  }
`;

const metricItemHeight = '152px';
const MetricItem = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  position: relative;

  width: 120px;
  height: ${metricItemHeight};
  padding: 10px;
  border-radius: 6px;

  @media screen and (max-width: 768px) {
    padding: 5px;
    width: 72px;
  }

  ${color}
  ${border}
`;

const MetricItemValue = styled.div`
  font-size: 40px;
  font-weight: 600;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

const MetricItemLabel = styled.div`
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
`;

const MetricItemSmallText = styled.div`
  position: absolute;
  top: calc(${metricItemHeight} + 5px);
  font-size: 20px;
  color: #999;
`;

const Placeholder = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
