import { useEffect, useReducer } from 'react';

import { useLocation } from 'react-router';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import MarketplaceSchoolsAPI from 'features/MarketplaceV2/api/marketplaceSchoolsAPI';
import { MoreInfoSlider } from 'features/MarketplaceV2/components/MoreInfoSlider/MoreInfoSlider';
import { ExpressInterestModal } from 'features/MarketplaceV2/features/ExpressInterest/ExpressInterestModal';
import { useMarketplaceDiscovery } from 'hooks/data/useMarketplaceDiscovery';
import { useMarketplaceFilterOptions } from 'hooks/data/useMarketplaceV2FilterOptions';
import { LocationFilterInterface, School } from 'types/marketplaceV2Types';
import { JobDiscoveryFilterContainer } from './JobDiscoveryFilters';
import { JobDiscoverySchoolsList } from './JobDiscoverySchoolsList';
import { initialState, reducer } from './utils';

import { ConnectGatedActions } from 'features/MarketplaceV2/utils/connectEnums';
import { MISSOURI_STATE_CODE } from 'utils/constants';
import { ExpressInterestExitModal } from '../ExpressInterest/ExpressInterestExitModal';
import { ActionTypes, ConnectFilterName } from 'features/MarketplaceV2/utils/connectEnums';
import auth from 'utils/auth';

export function JobDiscovery(): React.ReactElement {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const selectedSchoolId = searchParams.get('school');
  const gatedAction = searchParams.get('action');
  const vacancyId = searchParams.get('vacancy');
  const authenticatedUser = auth.getUser();

  const [state, dispatch] = useReducer(reducer, initialState);

  const filterValues = {
    ...state,
  };

  const filterOptions = useMarketplaceFilterOptions();

  const actions = {
    setPage: (page: number) =>
      dispatch({
        type: ActionTypes.SET_PAGE,
        payload: page,
      }),
    setQuery: (query: string) =>
      dispatch({
        type: ActionTypes.SET_QUERY,
        payload: query,
      }),
    setSelectedSubjectIds: (ids: number[]) => {
      dispatch({
        type: ActionTypes.SET_FILTER,
        filterName: ConnectFilterName.SUBCATEGORIES,
        payload: ids,
      });
    },
    setSelectedDistance: (distance: string) => {
      dispatch({
        type: ActionTypes.SET_FILTER,
        filterName: ConnectFilterName.DISTANCE_RADIUS_MILES,
        payload: distance,
      });
    },
    setSelectedLocation: (locationFilters: LocationFilterInterface) => {
      dispatch({
        type: ActionTypes.SET_FILTER,
        filterName: ConnectFilterName.LOCATION,
        payload: locationFilters,
      });
    },
    setSelectedGrades: (grades: number[]) =>
      dispatch({
        type: ActionTypes.SET_FILTER,
        filterName: ConnectFilterName.GRADES,
        payload: grades,
      }),
    setSelectedSchoolIds: (ids: number[]) =>
      dispatch({
        type: ActionTypes.SET_FILTER,
        filterName: ConnectFilterName.SCHOOL_UUID,
        payload: ids,
      }),
    setSelectedDistrictIds: (ids: number[]) =>
      dispatch({
        type: ActionTypes.SET_FILTER,
        filterName: ConnectFilterName.DISTRICT_UUID,
        payload: ids,
      }),
    setFilterIsActive: (filterName: string, isActive: boolean) =>
      dispatch({
        type: ActionTypes.SET_FILTER_IS_ACTIVE,
        filterName,
        payload: isActive,
      }),
    setIsLoading: (isLoading: boolean) =>
      dispatch({
        type: ActionTypes.SET_IS_LOADING,
        payload: isLoading,
      }),
    setUseDebounce: (useDebounce: boolean) =>
      dispatch({
        type: ActionTypes.SET_USE_DEBOUNCE,
        payload: useDebounce,
      }),
    setExpressInterestModalIsOpen: (expressInterestModalIsOpen: boolean) => {
      if (expressInterestModalIsOpen) {
        dispatch({
          type: ActionTypes.SET_EXPRESS_INTEREST_EXIT_MODAL_IS_OPEN,
          payload: false,
        });
      }
      dispatch({
        type: ActionTypes.SET_EXPRESS_INTEREST_MODAL_IS_OPEN,
        payload: expressInterestModalIsOpen,
      });
    },
    setExpressInterestExitModalIsOpen: (expressInterestExitModalIsOpen: boolean) => {
      if (expressInterestExitModalIsOpen) {
        dispatch({
          type: ActionTypes.SET_EXPRESS_INTEREST_MODAL_IS_OPEN,
          payload: false,
        });
      }
      dispatch({
        type: ActionTypes.SET_EXPRESS_INTEREST_EXIT_MODAL_IS_OPEN,
        payload: expressInterestExitModalIsOpen,
      });
    },
    setSelectedSchool: (selectedSchool: School) =>
      dispatch({
        type: ActionTypes.SET_SELECTED_SCHOOL,
        payload: selectedSchool,
      }),
    setMoreInfoSliderIsOpen: (moreInfoSliderIsOpen: boolean) =>
      dispatch({
        type: ActionTypes.SET_MORE_INFO_SLIDER_IS_OPEN,
        payload: moreInfoSliderIsOpen,
      }),
    setSelectedVacancyId: (selectedVacancyId: string) =>
      dispatch({
        type: ActionTypes.SET_SELECTED_VACANCY_ID,
        payload: selectedVacancyId,
      }),
    setSchools: (schools: School[]) =>
      dispatch({
        type: ActionTypes.SET_SCHOOLS,
        payload: schools,
      }),
    setReadyToConnectModalIsOpen: (readyToConnectModalIsOpen: boolean) =>
      dispatch({
        type: ActionTypes.SET_READY_TO_CONNECT_MODAL_IS_OPEN,
        payload: readyToConnectModalIsOpen,
      }),
    setContactedSchoolIds: (schoolId: string) =>
      dispatch({
        type: ActionTypes.SET_CONTACTED_SCHOOL_IDS,
        payload: schoolId,
      }),
    setIsLoadingInitialFilterResults: (isLoadingInitialFilterResults: boolean) =>
      dispatch({
        type: ActionTypes.SET_IS_LOADING_INITIAL_FILTER_RESULTS,
        payload: isLoadingInitialFilterResults,
      }),
    hasSchoolBeenContacted: (schoolId: string): boolean => {
      return !!state.contactedSchoolIds[schoolId];
    },
    toggleSavedSchoolIds: (schoolId: string) =>
      dispatch({
        type: ActionTypes.TOGGLE_SAVED_SCHOOL_IDS,
        payload: schoolId,
      }),
    hasSchoolBeenSaved: (schoolId: string): boolean => {
      return !!state.savedSchoolIds[schoolId];
    },
    setIsUserAuthenticated: (userIsAuthenticated: boolean) =>
      dispatch({
        type: ActionTypes.SET_IS_USER_AUTHENTICATED,
        payload: userIsAuthenticated,
      }),
  };

  const {
    results,
    hasMore,
    count,
    isFetchingNextPage,
    setIsFetchingNextPage,
  } = useMarketplaceDiscovery(
    { ...filterValues },
    state.activeFilters,
    actions.setIsLoading,
    actions.setIsLoadingInitialFilterResults,
    state.useDebounce
  );

  const handleMoreInfoSliderOpen = () => {
    actions.setMoreInfoSliderIsOpen(true);
    actions.setExpressInterestModalIsOpen(false);
  };

  const handleMoreInfoSliderClose = () => {
    actions.setMoreInfoSliderIsOpen(false);
    actions.setSelectedSchool(null);
    actions.setSelectedVacancyId('0');
  };

  const handleUpdateContactedSchools = (contactedSchool: School) => {
    const updatedSchools = state.schools.map(school => {
      if (school.nces_id === contactedSchool.nces_id) {
        return {
          ...school,
          hasExpressedInterest: true,
        };
      }
      return school;
    });
    actions.setSchools(updatedSchools);
  };

  useEffect(() => {
    const fetchSchoolData = async () => {
      actions.setIsLoading(true);
      try {
        const school = await MarketplaceSchoolsAPI.getSchoolByNcesId({
          stateId: MISSOURI_STATE_CODE.toUpperCase(),
          ncesId: selectedSchoolId,
        });
        actions.setSelectedSchool(school);
        if (vacancyId) {
          actions.setSelectedVacancyId(vacancyId);
          actions.setMoreInfoSliderIsOpen(true);
          actions.setReadyToConnectModalIsOpen(true);
        } else if (gatedAction === ConnectGatedActions.EXPRESS_INTEREST) {
          actions.setExpressInterestModalIsOpen(true);
        }
      } catch (error) {
        console.error(error);
      } finally {
        actions.setIsLoading(false);
      }
    };

    if (results && selectedSchoolId && !state.selectedSchool) {
      fetchSchoolData();
    }
    actions.setSchools(results);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results, selectedSchoolId, vacancyId]);

  useEffect(() => {
    if (authenticatedUser) {
      actions.setIsUserAuthenticated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticatedUser]);

  return (
    <JobDiscoveryContainer>
      {state.expressInterestModalIsOpen && (
        <ExpressInterestModal
          school={state.selectedSchool}
          updateContactedSchools={handleUpdateContactedSchools}
          expressInterestModalIsOpen={state.expressInterestModalIsOpen}
          actions={actions}
        />
      )}
      {state.expressInterestExitModalIsOpen && (
        <ExpressInterestExitModal isOpen={state.expressInterestExitModalIsOpen} actions={actions} />
      )}
      {state.moreInfoSliderIsOpen && (
        <MoreInfoSlider
          closeHandler={handleMoreInfoSliderClose}
          openHandler={handleMoreInfoSliderOpen}
          isOpen={state.moreInfoSliderIsOpen}
          isReadyToConnectModalOpen={state.readyToConnectModalIsOpen}
          anchor={'right'}
          actions={actions}
          school={state.selectedSchool}
          vacancyId={state.selectedVacancyId}
        />
      )}
      <Box maxWidth={'1216px'} width={'100%'}>
        <JobDiscoveryFilterContainer
          filterValues={filterValues}
          actions={actions}
          filterOptions={filterOptions}
          isUserAuthenticated={state.isUserAuthenticated}
        />
      </Box>
      <Box maxWidth={'1216px'} width={'100%'}>
        <JobDiscoverySchoolsList
          schools={state.schools}
          isUserAuthenticated={state.isUserAuthenticated}
          dispatch={dispatch}
          hasMore={hasMore}
          count={count}
          page={state.page}
          isLoading={state.isLoading}
          isFetchingNextPage={isFetchingNextPage}
          isLoadingInitialFilterResults={state.isLoadingInitialFilterResults}
          setIsFetchingNextPage={setIsFetchingNextPage}
          actions={actions}
        />
      </Box>
    </JobDiscoveryContainer>
  );
}

const JobDiscoveryContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '48px 16px',
  flexDirection: 'column',
  alignItems: 'center',
  background: '#ffffff',

  [theme.breakpoints.down('sm')]: {
    padding: '0px 16px 20px',
  },
}));
