import { Component } from 'react';

import PropTypes from 'prop-types';
import { PrimaryButton, SecondaryButton } from 'sharedComponents/Buttons';
import { Input } from 'sharedComponents/Input';

export default class AddSubcategoryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInput: false,
      label: '',
    };
  }
  static propTypes = {
    addCategory: PropTypes.func.isRequired,
    category: PropTypes.object.isRequired,
  };

  handleChange = e => {
    this.setState({ label: e.target.value });
  };

  addSubcategory = () => {
    this.props.addCategory(this.props.category.id, this.state.label);
    this.setState({ label: '', showInput: false });
  };

  toggleShowInput = () => {
    this.setState({ showInput: !this.state.showInput });
  };

  render() {
    return (
      <div className="add-subcategory-container-div">
        {this.state.showInput ? (
          <div>
            <Input
              sx={{ width: '400px' }}
              value={this.state.label || ''}
              onChange={this.handleChange}
            />
            <PrimaryButton
              sx={{ width: '90px', height: '52px', margin: '0 15px' }}
              onClick={this.addSubcategory}
            >
              Add
            </PrimaryButton>
            <SecondaryButton sx={{ width: '90px', height: '52px' }} onClick={this.toggleShowInput}>
              Cancel
            </SecondaryButton>
          </div>
        ) : (
          <PrimaryButton sx={{ width: '250px', height: '52px' }} onClick={this.toggleShowInput}>
            + New Subcategory
          </PrimaryButton>
        )}
      </div>
    );
  }
}
