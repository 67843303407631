import styled from 'styled-components';
import moment from 'moment';

import { AltModal } from 'ui-kit';
import SelectedFormTag from './SelectedFormTag';
import ConfirmationModalFooter from './ConfirmationModalFooter';

export default function HellosignRequestConfirmationModal({
  isOpen,
  onHide,
  save,
  dueDate,
  selectedPackets,
  selectedTemplates,
  emailAddressesToCC,
  editPreviousDraft,
  notify,
}) {
  const showCCSection = emailAddressesToCC.length > 0;
  const dueDateFormatted = dueDate ? moment(dueDate).format('M/D/YYYY') : 'None selected';

  return (
    <AltModal.AltModal isOpen={isOpen} onClose={onHide}>
      <AltModal.Title>Confirmation</AltModal.Title>
      <AltModal.Body>
        <Section>
          <Label>The following packets will be sent:</Label>
          <TemplatesContainer>
            {selectedPackets.map(packet => (
              <SelectedFormTag key={packet.id} title={packet.title} />
            ))}
          </TemplatesContainer>
        </Section>
        <Section>
          <Label>The following forms will be sent:</Label>
          <TemplatesContainer>
            {selectedTemplates.map(form => (
              <SelectedFormTag key={form.id} title={form.title} />
            ))}
          </TemplatesContainer>
        </Section>
        <Section>
          <Label>Due date:</Label>
          <GreyText>{dueDateFormatted}</GreyText>
        </Section>
        {showCCSection && (
          <Section>
            <Label>The following recipients will be CCd:</Label>
            <GreyText>{emailAddressesToCC.map(email => email.label).join(', ')}</GreyText>
          </Section>
        )}
        <Label>
          {notify ? 'Candidate will be notified via email' : 'Candidate will not be notified'}
        </Label>
      </AltModal.Body>
      <ConfirmationModalFooter save={save} onHide={onHide} editPreviousDraft={editPreviousDraft} />
    </AltModal.AltModal>
  );
}

const Section = styled.div`
  margin-bottom: 2.5rem;
`;

const Label = styled.p`
  font-weight: 400;
  font-size: 19px;
  color: #444444;
`;

const TemplatesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
`;

const GreyText = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;

  color: #999999;
  margin-top: 6px;
`;
