import { useState } from 'react';
import styled from 'styled-components';
import { grid } from 'styled-system';

import PropTypes from 'prop-types';
import TaskDirectionsModal from './TaskDirectionsModal';

import moment from 'moment';
import { taskStatus } from 'utils/enums';
import CompleteIcon from 'assets/icons/TaskIcons/green-circle-checkmark.svg';
import IncompleteIcon from 'assets/icon-check-with-circle.svg';

export default function CandidateDashboardTasksRow({
  task,
  user,
  applications,
  schoolApplications,
  setTaskStatusComplete,
}) {
  const [directionsModal, setDirectionsModal] = useState(false);

  const isComplete = task.status === taskStatus.complete;
  const isOverdue = moment(Date.now()).isAfter(task.due_date);

  let job;
  let location;

  if (task.application) {
    const foundApplication = applications.find(application => application.id === task.application);
    if (foundApplication) {
      job = foundApplication.role.title;
      location = foundApplication.role.jobboards.length
        ? foundApplication.role.jobboards[0].title
        : foundApplication.role.district.name;
    }
  } else if (task.schoolapplication) {
    const foundSchoolApplication = schoolApplications.find(
      schoolApplication => schoolApplication.id === task.school_application
    );
    if (foundSchoolApplication) {
      job = foundSchoolApplication.district_role.title;
      location = foundSchoolApplication.school.name;
    }
  } else {
    job = null;
    location = null;
  }

  return (
    <TaskRow
      gridTemplateColumns={[
        '19px 1fr 90px',
        '19px 1fr 90px',
        '19px 1fr minmax(90px, 0.5fr) repeat(4, 1fr)',
      ]}
    >
      <Icon
        style={{
          height: isComplete ? '18px' : '16px',
          width: isComplete ? '18px' : '16px',
        }}
        src={isComplete ? CompleteIcon : IncompleteIcon}
        alt=""
      />
      <Title isComplete={isComplete}>{task.universal_task.title} </Title>
      <StartTaskButton isComplete={isComplete} onClick={() => setDirectionsModal(true)}>
        Start task
      </StartTaskButton>
      <JobSection
        gridColumn={['1 / span 3', '1 / span 3', '4']}
        gridRow={['2', '2', '1']}
        isComplete={isComplete}
      >
        {job}
      </JobSection>
      <LocationSection
        gridColumn={['1 / span 3', '1 / span 3', '5']}
        gridRow={['3', '3', '1']}
        isComplete={isComplete}
      >
        {location}
      </LocationSection>
      <GridItem
        gridColumn={['1 / span 3', '1 / span 3', '6']}
        gridRow={['4', '4', '1']}
        isComplete={isComplete}
      >
        {`Due ${moment(task.due_date).format('MMMM D, YYYY')}`}
      </GridItem>
      <DueDateFromNow
        gridColumn={['1 / span 3', '1 / span 3', '7']}
        gridRow={['5', '5', '1']}
        isComplete={isComplete}
        isOverdue={isOverdue}
      >
        {isComplete
          ? `Completed ${moment(task.completed_by_date).format('MM/DD/YYYY')}`
          : moment(task.due_date).fromNow()}
      </DueDateFromNow>
      {directionsModal && (
        <TaskDirectionsModal
          show={directionsModal}
          onHide={() => setDirectionsModal(false)}
          setTaskStatusComplete={setTaskStatusComplete}
          task={task}
        />
      )}
    </TaskRow>
  );
}

const TaskRow = styled.div`
  ${grid}

  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 4px;
  align-items: center;

  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Icon = styled.img`
  grid-column-start: 1;
`;

const GridItem = styled.div`
  ${grid}

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: ${({ isComplete }) => (isComplete ? '#A6A7AD' : '#393C49')};
`;

const Title = styled(GridItem)`
  grid-column-start: 2;
`;

const StartTaskButton = styled.button`
  grid-column-start: 3;

  border: none;
  background: #fff;
  color: #00b88d;
  text-decoration: underline;

  ${({ isComplete }) => (isComplete ? 'display: none;' : '')}
`;

const DueDateFromNow = styled(GridItem)`
  color: ${({ isComplete, isOverdue }) => {
    if (isComplete) return '#A6A7AD';
    if (isOverdue) return '#EF5675';
    return '#393C49';
  }};
`;

const JobSection = styled(GridItem)``;
JobSection.displayName = 'JobSection';

const LocationSection = styled(GridItem)``;
LocationSection.displayName = 'LocationSection';

CandidateDashboardTasksRow.propTypes = {
  task: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  setTaskStatusComplete: PropTypes.func.isRequired,
};
