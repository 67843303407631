import { useState, useEffect } from 'react';
import { questionTypeModelMap } from 'utils/enums';
import questionSetsAPI from 'api/questionSetsAPI';
import nimbleQuestionsAPI from 'api/nimbleQuestionsAPI';

import { reorder } from './utils';
import { AltModal, Button } from 'ui-kit';
import AddItemModal from './AddItemModal';
import EditItemModal from './EditItemModal';

import {
  QuestionSetItemsInput,
  QuestionSetTitleInput,
  QuestionSetAddItemsInput,
  QuestionSetActions,
} from './components/shared';
import { QuestionSetActionsContainer } from './styledComponents';
import LoadingSpinner from '../../components/loadingSpinner';

export default function QuestionSetModal({
  isOpen,
  onClose,
  questionSetToEdit,
  internalRequirementsSpecified,
  questionSets,
  updateQuestionSetData, // to be used after add and edit
  createLocalQuestionSet = false,
  roleTitle,
  setQuestionSets,
}) {
  const roleTitleToUse = roleTitle || null;
  // Each Pane represents one of the modes of this modal, and by setting the `modalPane`
  // state variable, we can toggle modes on render.  This is used to show the item modals
  // as if they are the same as this modal.  (See how we change the return JSX depending
  // on what Pane is set as the modalpane)
  const Panes = {
    QuestionSetEdit: 0,
    ItemAdd: 1,
    ItemEdit: 2,
    Confirm: 3,
  };
  const [modalPane, setModalPane] = useState(Panes.QuestionSetEdit);
  const [actionToConfirm, setActionToConfirm] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('');
  const [hideConfirmButton, setHideConfirmButton] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  // Question set state:
  const [questionSet, setQuestionSet] = useState(
    questionSetToEdit
      ? questionSetToEdit
      : {
          title: '',
          items: [],
          is_qs: true,
        }
  );
  const [itemToEdit, setItemToEdit] = useState(null);
  const [questionBank, updateQuestionBank] = useState([]);
  const [questionType, updateQuestionType] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Effect caused by mounting the modal
  useEffect(() => {
    nimbleQuestionsAPI.getAll().then(data => updateQuestionBank(data));
  }, [questionSetToEdit]);

  /**
   * This effect handles the creation of new global question sets and
   * loading data for global question sets and role question sets.
   *
   * If the user is editing a role question set, we can skip the api call
   * and use the data contained in 'questionToEdit'.
   */
  useEffect(() => {
    setIsLoading(true);
    if (questionSetToEdit && createLocalQuestionSet) {
      const school_preferences_question = questionSetToEdit.school_preferences_question.map(
        ({ question_set, ...rest_of_the_question }) => rest_of_the_question
      );
      setQuestionSet({
        ...questionSetToEdit,
        school_preferences_question,
        title: `${questionSetToEdit.title} (local) ${roleTitleToUse ? '+ ' + roleTitleToUse : ''}`,
      });
      setIsLoading(false);
    } else if (
      questionSetToEdit &&
      questionSetToEdit.uuid &&
      !questionSetToEdit?.isNotCreatedSet &&
      !questionSetToEdit.is_role_qs
    ) {
      questionSetsAPI.retrieve(questionSetToEdit.uuid).then(data => {
        setQuestionSet(data);
        setIsLoading(false);
      });
    } else if (questionSetToEdit) {
      setQuestionSet(questionSetToEdit);
      setIsLoading(false);
    }

    if (!questionSetToEdit) {
      setIsLoading(false);
    }
  }, [createLocalQuestionSet, questionSetToEdit, roleTitleToUse]);

  // Reset state on close:
  const onCloseAll = () => {
    setModalPane(Panes.QuestionSetEdit);
    setIsLoading(false);
    setQuestionSet({ title: '', items: [], is_qs: true });
    setErrorMessage('');
    setConfirmMessage('');
    setHideConfirmButton(false);
    onClose();
  };

  /**
   * This function has several paths.
   *
   * 1. When a user selects "Edit this job" for a global QuestionSet, they intend to create
   *    a Local QuestionSet (ie. RoleQuestionSet instance) that is specific to the Role.
   *    Thus, we should set it to a local questionSet.
   *
   * 2. When a user selects "Edit this job" for a Local QuestionSet, they intend to update
   *    a RoleQuestionSet instance (created or not we use the same approach).
   *
   * 3. When a user selects "Edit all jobs", they intend for changes to be applied to all
   *    related QuestionSet instances. Thus, we hit "/api/question_sets/{uuid}"
   */
  const saveQuestionSet = () => {
    if (questionSetToEdit && createLocalQuestionSet) {
      // NOTE: creation of RoleQuestionSet is handled once user submits the new role
      updateQuestionSetData({
        ...questionSet,
        is_role_qs: true,
        isNotCreatedSet: true, // this is used to edit the set before its created on backend
      });
    } else if (
      (questionSetToEdit && questionSetToEdit.isNotCreatedSet) ||
      questionSetToEdit?.is_role_qs
    ) {
      // NOTE: update of RoleQuestionSet is handled once user submits the edited role
      updateQuestionSetData({
        ...questionSet,
      });
    } else {
      // handle QuestionSet (global) flow
      questionSetsAPI[questionSetToEdit ? 'update' : 'create'](questionSet)
        .then(r => {
          updateQuestionSetData({
            ...questionSet,
            ...r,
          });
          if (setQuestionSets) {
            const questionSetsCopy = [...questionSets];
            const updatedQsIndex = questionSets.findIndex(q => q.uuid === questionSet.uuid);
            questionSetsCopy[updatedQsIndex] = r;
            setQuestionSets(questionSetsCopy);
          }
        })
        .catch(err => {
          console.error(err);
          setErrorMessage(
            `Oops, there was a problem with the submission.
          Please contact support@hirenimble.com for assistance.`
          );
        });
    }
    // ensure modal state is closed after changes
    onCloseAll();
  };
  const populateQuestionFields = questionSet => {
    const items = questionSet.items.map((i, index) => (i = { ...i, order: index }));
    let questionSetCopy = { ...questionSet, items: items };
    for (let key of Object.keys(questionTypeModelMap)) {
      questionSetCopy[key] = questionSetCopy.items.filter(q =>
        questionTypeModelMap[key].includes(q.question_type)
      );
    }
    return questionSetCopy;
  };
  // Question set actions:
  const handleTitleChange = e => {
    const newTitle = e.target.value;
    setQuestionSet({ ...questionSet, title: newTitle });
    if (errorMessage.includes('title')) setErrorMessage('');
    setIsDirty(true);
  };

  const handleItemChange = updatedItem => {
    const newQuestionSet = { ...questionSet };
    // some existing items don't have uuids, so find those by their draggable_id.
    const idField = updatedItem.id ? 'id' : 'draggable_id';
    const index = newQuestionSet.items.findIndex(item => item[idField] === updatedItem[idField]);

    // insert it into items array at index, replacing the old item.
    newQuestionSet.items.splice(index, 1, updatedItem);
    setQuestionSet(populateQuestionFields(newQuestionSet));
    setIsDirty(true);
  };

  const handleDeleteItemByIndex = index => {
    updateQuestionSet(newQuestionSet => {
      newQuestionSet.items.splice(index, 1);
      newQuestionSet = populateQuestionFields(newQuestionSet);
      return newQuestionSet;
    })();
  };

  const updateQuestionSet = updateHandler => any => {
    let newQuestionSet = { ...questionSet };
    newQuestionSet = updateHandler(newQuestionSet);
    setQuestionSet(newQuestionSet);
    setIsDirty(true);
  };

  const handleDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      questionSet.items,
      result.source.index,
      result.destination.index
    );

    questionSet.items = reorderedItems;
    setQuestionSet(populateQuestionFields(questionSet));
    setIsDirty(true);
  };

  const handleAddNewItem = item => {
    questionSet.items.push(item);
    setQuestionSet(populateQuestionFields(questionSet));
    setModalPane(Panes.QuestionSetEdit);
    if (errorMessage.includes('item')) setErrorMessage('');
    setIsDirty(true);
  };

  const handleConfirmClick = () => {
    if (actionToConfirm === 'save') {
      saveQuestionSet();
    }
    if (actionToConfirm === 'close') {
      setIsDirty(false);
      onCloseAll();
    }
  };

  const handleCloseClick = () => {
    if (modalPane === Panes.QuestionSetEdit) {
      // if a user is on the main pane, the modal should always close
      onCloseAll();
    } else if (isDirty) {
      // if a user is on the confirmation pane, they're taken back to the main pane
      setActionToConfirm('close');
      setModalPane(Panes.QuestionSetEdit);
      return;
    }
    onCloseAll();
  };

  const handleSaveClick = () => {
    // We need to check for a valid title, aka not blank:
    if (!questionSet?.title || questionSet.title === '') {
      return setErrorMessage('Please add a title');
    }
    if (questionSet.title.length > 200) {
      return setErrorMessage('Title must be fewer that 200 characters');
    }
    // We also want to make sure there is at least 1 item in the set:
    if (questionSet.items.length === 0) {
      return setErrorMessage('Please add at least one item');
    }
    const questionTitleExists = questionSets.find(q => q.title === questionSet?.title);
    //We also want to check if the question set title is being used.
    if (questionTitleExists && createLocalQuestionSet) {
      return setErrorMessage('Title must be unique');
    }

    setActionToConfirm('save');
    // If the question set is in use elsewhere, warn about changing it
    if (questionSet.roles?.length) {
      setConfirmMessage('This question set is being used, are you sure you want to continue?');
    }
    setModalPane(Panes.Confirm);
  };

  if ([Panes.QuestionSetEdit, Panes.Confirm].includes(modalPane)) {
    return (
      <AltModal.AltModal isOpen={isOpen} onClose={handleCloseClick} closeOnClickOutside={false}>
        <AltModal.Title>{!questionSetToEdit ? 'Create new' : 'Edit'} Question Set</AltModal.Title>
        <AltModal.Body>
          {isLoading && <LoadingSpinner margin={8} />}
          {modalPane === Panes.QuestionSetEdit && !isLoading && (
            <>
              {QuestionSetTitleInput(questionSet, handleTitleChange)}
              {QuestionSetItemsInput(
                questionSet,
                handleItemChange,
                handleDeleteItemByIndex,
                handleDragEnd,
                questionBank,
                item => {
                  setItemToEdit(item);
                  setModalPane(Panes.ItemEdit);
                }
              )}
              {QuestionSetAddItemsInput(updateQuestionType, () => setModalPane(Panes.ItemAdd))}
            </>
          )}

          {modalPane === Panes.Confirm && (
            <>{confirmMessage === '' ? 'Are you sure you want to continue?' : confirmMessage}</>
          )}
        </AltModal.Body>
        <AltModal.Actions>
          {modalPane === Panes.QuestionSetEdit && QuestionSetActions(handleSaveClick, errorMessage)}

          {modalPane === Panes.Confirm && (
            <QuestionSetActionsContainer>
              <Button variant="secondary" onClick={() => setModalPane(Panes.QuestionSetEdit)}>
                Cancel
              </Button>
              {!hideConfirmButton && (
                <Button variant="primary" onClick={handleConfirmClick}>
                  Yes I'm sure
                </Button>
              )}
            </QuestionSetActionsContainer>
          )}
        </AltModal.Actions>
      </AltModal.AltModal>
    );
  }

  if (modalPane === Panes.ItemAdd) {
    return (
      <AddItemModal
        isOpen={true}
        onClose={() => setModalPane(Panes.QuestionSetEdit)}
        questionType={questionType}
        questionBank={questionBank}
        addNewItem={handleAddNewItem}
        internalRequirementsSpecified={internalRequirementsSpecified}
        existingAutoTags={questionSet.autotags}
      />
    );
  } else if (modalPane === Panes.ItemEdit) {
    return (
      <EditItemModal
        isOpen={true}
        onClose={() => setModalPane(Panes.QuestionSetEdit)}
        item={itemToEdit}
        onSave={updatedItem => {
          handleItemChange(updatedItem);
          setModalPane(Panes.QuestionSetEdit);
        }}
        onDelete={() => {
          handleDeleteItemByIndex(itemToEdit.order);
          setModalPane(Panes.QuestionSetEdit);
        }}
        questionBank={questionBank}
        internalRequirementsSpecified={internalRequirementsSpecified}
      />
    );
  } else {
    return <div />;
  }
}
