import axios from 'axios';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import CandidateHeaderSectionQuickProfile from './CandidateHeaderSectionQuickProfile';
import OverviewSectionQuickProfile from './OverviewSectionQuickProfile';
import Attachments from 'components/Profile/Attachments';
import CustomTagSection from '../../CustomTag/CustomTagProfile/CustomTagSection';

import CandidateTasks from './CandidateTasks';
import Certification from '../../Profile/Basics/Certification';
import CopyAppModal from './CopyAppModal';
import EmailModal from '../../EmailModal';
import HelloSignTemplateModal from 'features/HellosignEmbeddedSigning/helloSignTemplateModal';
import LinkedQuickProfile from '../LinkedQuickProfile';

import { handleOutsideClickOnValidForm } from 'utils/util';
import InternalCandidateNotes from '../../Profile/InternalCandidateNotes';
import QuickProfileApplicationSection from '../../Profile/ProfileApplicationSection/QuickProfileApplicationSection';
import auth from 'utils/auth';
import ScorecardModal from '../../ScorecardModal';
import StatusModal from '../../StatusModal';
import { appStatusType } from 'utils/enums';
import UploadDocumentModal from 'components/CandidateDashboardApplicationsList/uploadDocumentModal';
import CustomCandidateFields from '../../CustomCandidateFields';
import { submitAdditionalDocument } from 'utils/profileUtil';
import { uploadFileToUser } from 'utils/fileUpload';

import { assignTag, removeTag, validateTagUserType } from '../../CustomTag/util';

import usersAPI from 'api/usersAPI';
import quickProfileAPI from 'api/quickProfileAPI';
import customTagAPI from 'api/customTagAPI';

import rightCaretMint from 'assets/icon-right-caret-mint.svg';
import draftApplicationPic from 'assets/draft-application.png';
import { BoxContainer } from './QuickView.styles';
import { getATSCredentials } from 'utils/candidateUtils';

const isNotDraft = application => {
  return application.new_status.status_type !== appStatusType.draft;
};

export default class DistrictApplication extends Component {
  constructor(props) {
    super(props);

    /* Initial state objects
    ====================================================================== */
    // partialUser and partialApplication are props that are copied into state.
    // It's not best practice to do that, but it helps QuickProfile open up with
    // some data already filled in so we don't have to wait for the fetch to complete
    // before we render some meaningful information. When we used to fetch everything
    // in candidatelist it was too slow, and the opposite approach of rendering
    // nothing from props makes the quick profile seem too slow, so I think this is
    // a good compromise.

    // CandidatesList passes down a User object with limited fields in order
    // to load the list faster. When this component is mounted, we fetch a
    // more complete version of the candidate and store it in fullUser.
    const {
      id,
      name,
      first_name,
      email,
      profile,
      applications,
      scorecard_count,
      note_count,
    } = props.partialUser;
    // Copy in the data we already have from partialApplication so that these
    // details are rendered on the QuickProfile immediately. The rest of the
    // data is displayed once the fullUser is fetched from the server.
    const {
      role,
      new_status,
      updated,
      updated_by,
      top_candidate,
      high_retention,
    } = props.partialApplication;
    const fullUser = {
      id,
      name,
      first_name,
      email,
      profile,
      // The "applications" property is used to show how many jobs the candidate has applied to in
      // the district. We don't want to count draft applications. When they're fetched for the
      // quick profile they're excluded on the backend, but they're present in this initial props
      // value because we display draft applications on the candidates list.
      applications: applications.filter(isNotDraft) || [],
      scorecard_count,
      note_count,
      attachments: [],
      credentials: [],
      tasks: [],
      internal_candidate_notes: [],
      next_interview: null,
      exams: [],
      experiences: [],
      education: [],
      preference: {},
      answers: [],
      custom_fields: [],
      tags: [],
      // Application currently being quick viewed. Some data is copied in from
      // props.partialApplication so it can be rendered immediately.
      application: {
        id: props.partialApplication.id,
        top_candidate,
        high_retention,
        is_popular: false,
        preferenced_school: false,
        flexible_school_preferences: false,
        is_in_shortage_area: false,
        new_status,
        updated,
        updated_by,
        role: {
          ...role,
          requiredapplicationattachment_set: [],
          statuses_available: [],
        },
        schoolapplications: [],
        applicationattachment_set: [],
        applicationattachmentvideourl_set: [],
        school_preferences_answer: {
          selected_schools: [],
        },
        custom_answers: [],
      },
      district_references: [],
    };

    // Which modals or dropdowns are open/closed
    const uiElements = {
      scorecardModalOpen: false,
      statusModalOpen: false,
      showActionDropdown: false,
      copyAppModalOpen: false,
      emailModalOpen: false,
      templateEmailModal: false,
      helloSignTemplateModalOpen: false,
      uploadDocumentModalOpen: false,
      conversionModalOpen: false,
    };

    // State related to updating an application or schoolapplication
    // status. Some statuses have a related email that the user composes
    // so we keep track of the statusUpdate while they fill out that
    // email in the modal. If changing this section, also change the
    // onHide method below which resets this state after a status update
    // is completed.
    const statusUpdate = {
      desiredStatus: {},
      applicationIdToUpdateInModal: null,
      emailTemplate: {},
      subject: '',
      message: '',
      recipientList: [fullUser],
      application_to_update: null,
      statusObj: {},
      archiveOtherApps: null,
      schoolappsToModify: null,
    };

    // Initialize state. Events are fetched separately because they're
    // a nightmare to work with. Each event type uses a separate serializer
    // and queryset and you can't filter by user on the abstract model itself.
    this.state = {
      fullUser,
      districtTags: [],
      events: [],
      ...uiElements,
      ...statusUpdate,
    };

    this.isSchoolUser = auth.isSchoolUser();
    this.isDistrictUser = auth.isDistrictUser();
    this.isDistrictAdmin = auth.isDistrictAdmin();
    this.getUser = auth.getUser();
    this.hasOnboardingModule = auth.hasOnboardingModule();
    this.urlPrefix = this.isSchoolUser ? 'school' : 'district';
    this.submitAdditionalDocument = submitAdditionalDocument.bind(this);
  }

  static propTypes = {
    partialUser: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    toggleVisibility: PropTypes.func.isRequired,
    adminUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
    partialApplication: PropTypes.object,
    emailTemplatesList: PropTypes.array.isRequired,
    sources: PropTypes.array.isRequired,
    incrementScorecardCount: PropTypes.func,
    newApplicationStatuses: PropTypes.arrayOf(PropTypes.object).isRequired,
    // For larger updates like updating schoolapplications, do a full user fetch
    // on CandidatesList and setstate with the return data.
    refreshCandidateInList: PropTypes.func.isRequired,
  };

  /* Lifecycle methods
  ================================================================================== */
  componentDidMount() {
    // Don't show footer on district/candidates when quickview open
    document.body.classList.add('quick-profile-open');

    // check for new tags
    customTagAPI.fetchCustomTags().then(tags => this.setState({ districtTags: tags }));

    this.fetchFullUser();
    this.fetchEvents();
  }

  componentWillUnmount() {
    // Revert to prior classes
    document.body.classList.remove('quick-profile-open');
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }
  /* =============================================================================== */

  render() {
    const isDraft = this.state.fullUser.application.new_status.status_type === appStatusType.draft;

    const { ssn_required, birthday_required } = this.getUser.profile.district;
    const newApplicationStatuses = this.props.newApplicationStatuses.filter(s =>
      this.state.fullUser.application.role.statuses_available.includes(s.id)
    );

    const isLinkedToAuthUser = auth.getUser().linked_users.includes(this.state.fullUser.id);
    if (isLinkedToAuthUser) {
      return (
        <LinkedQuickProfile
          show={this.props.show}
          toggleVisibility={this.props.toggleVisibility}
          fullUser={this.state.fullUser}
        />
      );
    }

    const atsCredentials = getATSCredentials(this.state.fullUser.credentials);

    return (
      <div>
        <div
          className="quick-profile-fade"
          hidden={!this.props.show}
          onClick={this.props.toggleVisibility}
        />
        {this.props.show && (
          <div className="quick-profile-container">
            <div className="flex-1 quick-profile-inside">
              <CloseButton onClick={this.props.toggleVisibility}>×</CloseButton>
              <div className="default-card">
                <CandidateHeaderContainerDiv>
                  <CandidateHeaderSectionQuickProfile
                    fullUser={this.state.fullUser}
                    application={this.state.fullUser.application}
                    schoolapplications={this.state.fullUser.application.schoolapplications}
                    events={this.state.events}
                    isDraft={isDraft}
                    urlPrefix={this.urlPrefix}
                    openEmailModal={() => this.setState({ emailModalOpen: true })}
                    openCopyApplicationModal={() => this.setState({ copyAppModalOpen: true })}
                    openScorecardModal={() => this.setState({ scorecardModalOpen: true })}
                    updateUserStatus={this.updateUserStatus}
                    updateSchoolApp={this.updateSchoolApp}
                    viewStatusModal={this.viewStatusModal}
                    emailTemplatesList={this.props.emailTemplatesList}
                    adminUsers={this.props.adminUsers}
                    newApplicationStatuses={newApplicationStatuses}
                    districtApplicationBeingViewedBySchoolAdmin={this.isSchoolUser}
                  />
                </CandidateHeaderContainerDiv>

                <StyledLine />

                <Section>
                  <Link
                    className="go-profile"
                    to={`/${this.urlPrefix}/profile/${this.state.fullUser.id}/?application=${this.state.fullUser.application.id}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <ViewFullApplicationContainer>
                      {this.state.fullUser.first_name ? (
                        <p>View {`${this.state.fullUser.first_name}'s `}Full Application</p>
                      ) : (
                        <p>View Full Application</p>
                      )}
                      <img src={rightCaretMint} alt="right arrow" />
                    </ViewFullApplicationContainer>
                  </Link>
                </Section>

                {isDraft ? (
                  <div className="draft-application-div">
                    <div>
                      <img src={draftApplicationPic} alt="draft application" />
                    </div>
                    <p>Looks like this candidate is still working on their application.</p>
                    <div className="profile-section">
                      <HeaderLabel className="section-header mb1 mr1 inline-block">
                        Internal Notes
                      </HeaderLabel>
                      <InternalCandidateNotes
                        notes={this.state.fullUser.internal_candidate_notes}
                        updateNote={this.updateInternalCandidateNote}
                        adminUsers={this.props.adminUsers}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <Section>
                      <OverviewSectionQuickProfile
                        isDistrictAdmin={this.isDistrictAdmin}
                        isDistrictUser={this.isDistrictUser}
                        fullUser={this.state.fullUser}
                        application={this.state.fullUser.application}
                        schoolapplications={this.state.fullUser.application.schoolapplications}
                        districtRole={this.state.fullUser.application.role}
                        newApplicationStatuses={newApplicationStatuses}
                        updateFullUser={this.updateFullUser}
                        fetchEvents={this.fetchEvents}
                        urlPrefix={this.urlPrefix}
                        sources={this.props.sources}
                        replaceCandidateInState={this.replaceCandidateInState}
                        refreshCandidateInList={this.props.refreshCandidateInList}
                        isCandidateList
                      />
                    </Section>
                    {/*
                      Spec: Hide section if district hasn't created any candidate tags.

                      Spec: If there are no tags present in the district that the current
                      user can view, the section should be hidden.
                    */}
                    {this.state.districtTags.length > 0 &&
                      // check if current user_type can view any tags in district
                      this.state.districtTags.find(tag =>
                        validateTagUserType(auth.getUser(), tag)
                      ) && (
                        <Section>
                          <CustomTagSection
                            user={this.getUser}
                            fullUser={this.state.fullUser}
                            assignTag={this.addTag}
                            removeTag={this.deleteTag}
                            districtTags={this.state.districtTags}
                          />
                        </Section>
                      )}

                    {(atsCredentials.length > 0 ||
                      this.state.fullUser.profile.has_no_credentials) && (
                      <Section>
                        <Certification
                          subjects={atsCredentials}
                          hasNoCredentials={this.state.fullUser.profile.has_no_credentials}
                          isCandidateList
                        />
                      </Section>
                    )}

                    <Section>
                      <Attachments
                        fullUser={this.state.fullUser}
                        districtApplicationBeingQuickviewed={this.state.fullUser.application}
                        openDocumentUploadModal={districtApplicationId =>
                          this.setState({
                            uploadDocumentModalOpen: districtApplicationId,
                          })
                        }
                        openHellosignTemplateModal={() =>
                          this.setState({ helloSignTemplateModalOpen: true })
                        }
                        showDownloadIcon={
                          this.state.fullUser.profile.resume ||
                          this.state.fullUser.application.applicationattachment_set.length > 0
                        }
                        helloSignTemplateModalOpen={this.state.helloSignTemplateModalOpen}
                        isCandidateList
                      />
                    </Section>

                    <Section>
                      <BoxContainer>
                        <HeaderLabel>Internal Notes</HeaderLabel>
                        <InternalCandidateNotes
                          notes={this.state.fullUser.internal_candidate_notes}
                          updateNote={this.updateInternalCandidateNote}
                          adminUsers={this.props.adminUsers}
                        />
                      </BoxContainer>
                    </Section>

                    <Section>
                      <CustomCandidateFields
                        ssn_required={ssn_required}
                        birthday_required={birthday_required}
                        user={this.state.fullUser}
                        onBirthdayChange={this.onBirthdayChange}
                      />
                    </Section>

                    {this.state.fullUser.tasks.length > 0 && (
                      <Section>
                        <CandidateTasks
                          user={this.state.fullUser}
                          updateCandidateTask={this.updateCandidateTask}
                        />
                      </Section>
                    )}

                    {!isDraft && (
                      <Section>
                        <QuickProfileApplicationSection
                          user={this.state.fullUser}
                          districtApplication={this.state.fullUser.application}
                          isCandidateList
                        />
                      </Section>
                    )}
                  </>
                )}
              </div>
            </div>
            {this.state.copyAppModalOpen && (
              <CopyAppModal
                application={this.state.fullUser.application}
                user={this.state.fullUser}
                show={this.state.copyAppModalOpen}
                onHide={() => this.setState({ copyAppModalOpen: false })}
                updateCandidatePostSave={() => {
                  this.fetchFullUser();
                  this.props.refreshCandidateInList(this.state.fullUser.id);
                }}
                newApplicationStatuses={newApplicationStatuses}
              />
            )}
            {this.state.emailModalOpen && (
              <EmailModal
                recipientList={this.state.recipientList}
                show={this.state.emailModalOpen}
                subject={this.state.subject}
                message={this.state.message}
                onHide={this.onHide}
                onSend={this.onSend}
                emailTemplatesList={this.props.emailTemplatesList}
                emailTemplate={this.state.emailTemplate}
                updateEmailTemplateSelection={this.updateEmailTemplateSelection}
                candidate={this.state.fullUser}
                application={this.state.fullUser.application}
                showTemplates={true}
                footer={this.state.sendNotification ? this.getSkipEmailButton() : null}
                templateEmailModal={this.state.templateEmailModal}
              />
            )}
            {this.state.helloSignTemplateModalOpen && (
              <HelloSignTemplateModal
                show={this.state.helloSignTemplateModalOpen}
                onHide={cb =>
                  this.setState({ helloSignTemplateModalOpen: false }, () => {
                    if (typeof cb === 'function') cb();
                  })
                }
                onSend={this.onSend}
                candidate={this.state.fullUser}
                application={this.state.fullUser.application}
                adminUsers={this.props.adminUsers}
              />
            )}
            {this.state.uploadDocumentModalOpen && (
              <UploadDocumentModal
                // this.state.uploadDocumentModalOpen will be either the application id
                // or false so coerce to a boolean
                show={this.state.uploadDocumentModalOpen ? true : false}
                onHide={() => this.setState({ uploadDocumentModalOpen: false })}
                submitAdditionalDocument={this.submitAdditionalDocument}
                hideInstructions={true}
                postSubmitCallback={() => {
                  this.fetchFullUser();
                  this.fetchEvents();
                }}
              />
            )}
            {this.state.scorecardModalOpen && (
              <ScorecardModal
                user={this.state.fullUser}
                application={this.state.fullUser.application}
                show={this.state.scorecardModalOpen}
                onHide={() => this.setState({ scorecardModalOpen: false })}
                emailTaggedUsers={this.emailTaggedUsers}
                adminUsers={this.props.adminUsers}
              />
            )}

            {this.state.statusModalOpen && (
              <StatusModal
                show={this.state.statusModalOpen}
                onHide={() => this.setState({ statusModalOpen: false })}
                schoolapplications={this.state.fullUser.application.schoolapplications}
                updateSchoolApp={this.updateSchoolApp}
                desiredStatus={this.state.desiredStatus}
                canUpdateStatus={this.isDistrictUser}
                updateUserStatus={this.updateUserStatus}
                user={this.state.fullUser}
                applicationBeingQuickviewed={this.state.fullUser.application}
              />
            )}
          </div>
        )}
        <ReactTooltip id="last-updated" effect="solid" place="bottom" html />
      </div>
    );
  }

  /* Component methods
  ============================================================================= */

  fetchFullUser = () => {
    quickProfileAPI
      .fetchFullUser(this.state.fullUser.id, this.state.fullUser.application.id)
      .then(fullUser => {
        this.setState({ fullUser });
      });
  };

  onSend = () => {
    if (this.state.schoolappsToModify) {
      this.handleSchoolAppUpdate();
    }
    return this.onHide();
  };

  handleSchoolAppUpdate = () => {
    // there are more school apps left to be updated, so update the school apps iteratively.
    // If we have multiple schoolapplications updated we have a different event logged on the
    // backend so we have to designate this for the server
    let multipleSchoolapplicationUpdate = false;
    if (this.state.schoolappsToModify.length > 1) {
      multipleSchoolapplicationUpdate = true;
    }
    this.state.schoolappsToModify.forEach((a, index) => {
      if (multipleSchoolapplicationUpdate && index === this.state.schoolappsToModify.length - 1) {
        multipleSchoolapplicationUpdate = this.state.schoolappsToModify.length;
      }
      this.updateSchoolApp({
        candidate_id: this.state.fullUser.id,
        application_id: a.id,
        target_status: this.state.statusObj.id,
        archiveOtherApps: this.state.archiveOtherApps,
        multipleSchoolapplicationUpdate,
      });
    });
  };

  onHide = () => {
    this.setState({
      emailModalOpen: false,
      desiredStatus: {},
      applicationIdToUpdateInModal: null,
      emailTemplate: {},
      subject: '',
      message: '',
      recipientList: [this.state.fullUser],
      application_to_update: null,
      statusObj: {},
      archiveOtherApps: null,
      schoolappsToModify: null,
    });
  };

  fetchEvents = () => {
    axios.get(`/api/events/${this.state.fullUser.id}/`).then(r => {
      if (!this.ignoreLastFetch) {
        this.setState({
          events: r.data,
        });
      }
    });
  };

  openModal = modal => {
    this.setState({ [modal]: true });
  };

  viewStatusModal = statusObj => {
    this.setState({
      statusModalOpen: true,
      desiredStatus: statusObj,
    });
  };

  updateInternalCandidateNote = async (newNote, recipientList, message) => {
    const fullUser = { ...this.state.fullUser };
    fullUser.internal_candidate_notes.unshift(newNote); // add new note to beginning of notes array
    this.setState({ fullUser });

    axios
      .patch(`/api/user/${this.state.fullUser.id}/`, {
        internalCandidateNote: newNote,
      })
      .then(() => {
        this.emailTaggedUsers(recipientList, message, 'note');
      });
  };

  emailTaggedUsers = (recipientList, message, type, id) => {
    // Type is either 'note' or 'scorecard'. For display purposes.
    const tagObjects = [];

    recipientList.forEach(recipient => {
      const url = this.getUrl(recipient, type, id);
      const tagObj = {
        user_id: recipient.id,
        location: `in a ${type}`,
        note_url: url,
        note_copy: message,
        candidate_name: this.state.fullUser.name,
      };
      tagObjects.push(tagObj);
    });

    this.sendTagNotifications(tagObjects);
  };

  getUrl = (recipient, type, id) => {
    const host = window.location.hostname;
    const colon = host.indexOf('local') !== -1 ? ':' : '';
    const port = window.location.port;
    const location = `${window.location.protocol}//${host}${colon}${port}`;

    const recipientUrlPrefix = `${recipient.profile.user_type >= 30 ? 'district' : 'school'}`;
    const urlApplication = `?application=${this.state.fullUser.application.id}`;
    // If a param is present, either the scorecard modal or the reference modal will open
    // when the user visits the page. The reference or scorecard that matches the param id
    // will be the one that is opened.
    let extraParam = '';
    if (type === 'scorecard' || type === 'reference') {
      extraParam = `&${type}=${id}`;
    }

    return `${location}/${recipientUrlPrefix}/profile/${this.state.fullUser.id}/${urlApplication}${extraParam}`;
  };

  sendTagNotifications = tagObjects => {
    return axios.post('/api/send_tag_notifications/', tagObjects);
  };

  outsideClickListener = ev =>
    handleOutsideClickOnValidForm(this.node, ev, this.toggleApplicationCopyButton);

  toggleApplicationCopyButton = () => {
    if (!this.state.showActionDropdown) {
      document.addEventListener('click', this.outsideClickListener, { capture: true });
    } else {
      document.removeEventListener('click', this.outsideClickListener, false);
    }
    const showActionDropdown = !this.state.showActionDropdown;
    this.setState({ showActionDropdown });
  };

  updateEmailTemplateSelection = e => {
    /** selected template will send up id as e.target.value. find in list */
    const emailTemplateProp = this.props.emailTemplatesList.find(
      template => template.id === Number(e.target.value)
    );

    if (emailTemplateProp) {
      let emailTemplate = Object.assign({}, emailTemplateProp);
      if (emailTemplate.content) {
        emailTemplate.content = emailTemplate.content.replace(/\n/g, '<br>');
      }
      this.setState({
        emailTemplate,
        subject: emailTemplate.subject,
        message: emailTemplate.content,
      });
    }
    if (e.target.value === null) {
      this.setState({
        emailTemplate: {},
        subject: '',
        message: '',
      });
    }
  };

  submitUserAttachment = async (file, fileName) => {
    return uploadFileToUser(this.state.fullUser.id, file, fileName);
  };

  getSkipEmailButton = () => (
    <button className="email-modal-button" onClick={this.onSend}>
      Skip Email
    </button>
  );

  updateCandidateTask = (task, method) => {
    let newStatus = Number(!task.status); // flips 1 to 0 or 0 to 1. Corresponds to enum.
    let newTask = { ...task };
    newTask.status = newStatus;
    const fullUser = { ...this.state.fullUser };
    let taskIndex = fullUser.tasks.findIndex(t => t.id === newTask.id);
    if (taskIndex !== -1) {
      if (method === 'put') {
        fullUser.tasks[taskIndex] = newTask;
      } else {
        fullUser.tasks.splice(taskIndex, 1);
        newTask.visible = false;
      }
      this.setState({ fullUser });
    }

    axios.put(`/api/candidate_task/${newTask.id}/`, newTask);
  };

  saveSchoolApps = (schoolsList, role_id) => {
    const schoolapplications = this.state.fullUser.application.schoolapplications;

    /* remove schoolapplications that no longer exist */
    const removeSchoolApps = schoolapplications.filter(
      schoolapp => !schoolsList.map(s => s.school_id).includes(schoolapp.school.id)
    );

    let schoolsToAdd = schoolsList.filter(s => {
      /** search candidate's existing schoolapplications to see if she already has
       * a schoolapplication in given status for given school. If so, no need to
       * send to backend.
       */
      let index = schoolapplications.findIndex(
        sa => sa.school.id === Number(s.school_id) && sa.new_status.id === Number(s.app_status_id)
      );
      return index === -1;
    });

    // send over the rest to update or create
    if (schoolsToAdd.length > 0 || removeSchoolApps.length > 0) {
      axios
        .put('/api/schoolapplications/manual_update_school_apps/', {
          candidate_id: this.state.fullUser.id,
          schools_to_add: schoolsToAdd,
          schools_to_delete: removeSchoolApps.map(s => s.id),
          district_role_id: role_id,
          quick_profile: true,
        })
        .then(r => {
          let updatedCandidate = r.data;
          this.setState({ fullUser: updatedCandidate });
          this.props.refreshCandidateInList(updatedCandidate.id);
          this.fetchEvents();
        });
    }
  };

  updateFullUser = (fieldName, value) => {
    const fullUser = { ...this.state.fullUser };
    fullUser[fieldName] = value;
    this.setState({ fullUser });
  };

  replaceCandidateInState = fullUser => {
    this.setState({ fullUser });
  };

  onBirthdayChange = birthday => {
    const userId = this.state.fullUser.id;

    let previousValue;

    this.setState(prevState => {
      previousValue = prevState.fullUser.profile.birthday;

      return {
        fullUser: {
          ...prevState.fullUser,
          profile: { ...prevState.fullUser.profile, birthday },
        },
      };
    });

    return usersAPI
      .updateUser(userId, { birthday })
      .then(data => {
        this.setState(prevState => ({
          fullUser: {
            ...prevState.fullUser,
            ...data,
          },
        }));
      })
      .catch(error => {
        console.log(error);
        this.setState(prevState => ({
          fullUser: {
            ...prevState.fullUser,
            profile: { ...prevState.fullUser.profile, birthday: previousValue },
          },
        }));
      });
  };

  updateUserStatus = updateObj => {
    const { candidate_id, target_status, application_to_update, archiveOtherApps } = updateObj;
    axios
      .put('/api/applications/update_candidate_status/', {
        candidate_id,
        target_status,
        application_to_update,
        archive_other_apps_when_hired: archiveOtherApps,
        quick_profile: true,
      })
      .then(r => {
        let updatedCandidate = r.data;
        // Update user here in QuickProfile state so schoolapplications are updated
        this.setState({ fullUser: updatedCandidate });
        // Update user in container state so the status change is reflected on the
        // candidateslist line item.
        this.props.refreshCandidateInList(updatedCandidate.id);
        this.fetchEvents();
      });
  };

  updateSchoolApp = updateObj => {
    const {
      candidate_id,
      application_id,
      target_status,
      archiveOtherApps = true,
      multipleSchoolapplicationUpdate,
    } = updateObj;

    // find status in state
    const status = this.props.newApplicationStatuses.find(s => s.id === target_status);
    const fullUser = { ...this.state.fullUser };
    const schoolappIdx = fullUser.application.schoolapplications.findIndex(
      sa => sa.id === application_id
    );
    fullUser.application.schoolapplications[schoolappIdx].new_status = status;
    this.setState({ fullUser });

    axios
      .put(`/api/schoolapplications/${application_id}/update_status/`, {
        target_status,
        archive_other_apps_when_hired: archiveOtherApps,
        multipleSchoolapplicationUpdate,
        candidate_id,
        quick_profile: true,
      })
      .then(() => {
        this.fetchFullUser();
        this.fetchEvents();
        this.props.refreshCandidateInList(candidate_id);
      });
  };

  /**
   * Assign selected tag to the specified candidate.
   */
  addTag = async tagId => {
    await assignTag(tagId, this.state.fullUser);
    this.fetchFullUser();
  };

  /**
   * Remove tag from the candidate
   */
  deleteTag = async tagId => {
    await removeTag(tagId, this.state.fullUser);
    this.fetchFullUser();
  };
}

/* Styles
========================================================================= */

const CloseButton = styled.span`
  position: absolute;
  top: 22px;
  right: 28px;
  font-size: 28px
  font-weight: bold;
  cursor: pointer;
  color: #A3A1B3 !important;

  @media screen and (max-width: 768px) {
    top: 10px;
    right: 8px;
    font-size: 24px;
  }
`;

const StyledLine = styled.hr`
  border-top: 1px solid #dbdbdb;
  margin: 12px -28px 30px;
`;

const Section = styled.div`
  padding: 1.5rem 1rem;
`;

const CandidateHeaderContainerDiv = styled.div`
  padding: 18px 1rem 0;
`;

const ViewFullApplicationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 1.5rem;
  color: var(--mint-green);
  border: 1px solid var(--mint-green);
  border-radius: 5px;
  height: 60px;
  font-size: 15px;
  font-weight: 400;

  p {
    color: var(--mint-green);
  }
`;

ViewFullApplicationContainer.displayName = 'ViewFullApplicationContainer';

const HeaderLabel = styled.h4`
  color: #444444;
  font-size: 19px;
  font-weight: 400;
  margin-bottom: 1rem;
`;
