import { Component } from 'react';

import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

export default class WorkExperienceReminderModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    moveToNextPage: PropTypes.func.isRequired,
    scrollToTopOfPage: PropTypes.func.isRequired,
  };

  render() {
    const { show, onHide } = this.props;

    return (
      <Modal
        show={show}
        onHide={onHide}
        bsSize="large"
        dialogClassName="work-experience-reminder-modal"
      >
        <Modal.Header>
          <div className="modal-title">
            <h3>Friendly Reminder</h3>
          </div>
          <span className="copy-app-modal-close" onClick={this.props.onHide}>
            (X) CLOSE
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-section">
            <div>Are you sure you want to move forward without entering your work experience?</div>
            <div className="mt1">
              Even if you have 20 years' experience, if you don't enter it recruiters will see you
              as "Jane Doe - 0 years experience."
            </div>
          </div>
          <div className="modal-footer">
            <div>
              <button className="mr2" onClick={this.props.moveToNextPage}>
                Ignore & Proceed
              </button>
              <button className="mr3" onClick={() => this.props.scrollToTopOfPage(true)}>
                Go to work experience
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
