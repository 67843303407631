import axios from 'axios';

export function addDocument(event) {
  const fileData = event.target.files[0];
  const uploadErrorMessages = Object.assign({}, this.state.uploadErrorMessages);

  /* ensure no empty files are uploaded */
  if (typeof fileData === 'undefined' || fileData.size === 0) {
    uploadErrorMessages.document = 'Upload is empty, please try again';
    this.setState({ uploadErrorMessages });
    return;
  }
  /* Limit uploads to ~7 MB in size. This is sort of arbitrarily chosen.
   * The limit used to be 5 MB but some users wanted to upload onboarding
   * packets that were 5.5 MB. HelloSign allows templates up to 40MB so
   * there should be some room to go up if we need it.
   */
  if (fileData.size > 7 * 1048576) {
    uploadErrorMessages.document = 'File must be smaller than 7 MB';
    this.setState({ uploadErrorMessages });
    return;
  }
  /* only proper file types can be uploaded */
  if (
    !['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png'].includes(
      fileData.name
        .split('.')
        .pop()
        .toLowerCase()
    )
  ) {
    uploadErrorMessages.document =
      'Please ensure your file ends in one of the following: .pdf, .doc, .docx, .jpg, .png';
    this.setState({ uploadErrorMessages });
    return;
  }
  /**
   * ~ 30 characters are prepended to file name (/resumes/None/(timestamp)), and max_length
   * is 255, so ensure input file name is no more than 215 characters, to be safe.
   */
  if (fileData.name.length > 215) {
    uploadErrorMessages.document = 'Please ensure your file name is fewer than 215 characters';
    this.setState({ uploadErrorMessages });
    return;
  }
  /* if this point is reached, resume is valid, so remove error messages if they exist */
  uploadErrorMessages.document = '';
  return { fileData, uploadErrorMessages };
}

export function uploadFile(file, application_id, attachmentName) {
  /*
   ** Uploads a file tied to a user's application
   */
  const formData = new FormData();
  formData.append('attachment', file, file.name);
  formData.append('application_id', application_id);
  formData.append('attachmentName', attachmentName);

  try {
    return axios
      .post(`/api/applications/${application_id}/upload_additional_attachment/`, formData)
      .then(r => r);
  } catch (error) {
    console.log(error);
    return error;
    // let errorMessage = `Oops, there was a problem with some of your additional attachments. Please email
    // support@hirenimble.com and we'll help you out right away.
    // In the meantime, please keep your browser open to help us preserve your data.`;
    // this.showWarningMessage(errorMessage);
    // return;
  }
}

export async function replaceRequiredFile(
  file,
  applicationID,
  attachmentName,
  attachmentID,
  requiredAttachmentID
) {
  /*
   ** Uploads a file tied to a user's application
   */
  const formData = new FormData();
  formData.append('attachment', file, file.name);
  formData.append('attachmentName', attachmentName);
  formData.append('attachment_id', attachmentID);
  formData.append('required_attachment_id', requiredAttachmentID);

  return await axios.put(
    `/api/applications/${applicationID}/candidate_replace_required_attachment/`,
    formData
  );
}

export function uploadFileToUser(user_id, file, fileName) {
  const fileData = new FormData();
  fileData.append('file', file, fileName);

  return axios
    .post(`/api/upload_user_attachment/${user_id}/`, fileData)
    .then(r => r)
    .catch(error => {
      console.log(error);
      return Promise.reject(error);
    });
}

/**
 *
 * This is a helper function that will upload/update a resume file to
 * the current user's profile.
 *
 * @param {*} resume new file that replace resume
 */
export const updateCandidateProfileResume = async (resume, userId) => {
  const fileData = new FormData();
  fileData.append('file', resume, resume.name);

  return await axios.post('/api/candidate_dashboard/uploadresume/', fileData);
};

/**
 * Utility function that retrieves a filename (and extension) from a given
 * filepath/url.
 *
 * @param {string} filePath a URL in string format
 * @returns {string} a filename with extension like 'example.pdf'
 */
export const getFileNameFromPath = filePath => {
  if (typeof filePath !== 'string') return 'Please ensure given file is in string format!';

  // remove invalid characters from string
  const temp = filePath
    .split('\\')
    .pop()
    .split('/')
    .pop()
    .split('?');

  // returns filename from array of strings
  return temp[0];
};
