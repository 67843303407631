import { BaseNimbleAPI } from './baseAPI';

class CustomScorecardListAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/customscorecards' };
  }

  async createScorecard(scorecard) {
    const response = await this.post('/', scorecard);
    return response.data;
  }

  async fetchCustomScorecards() {
    const response = await this.get('/');
    return response.data;
  }

  async fetchEditableCustomScorecards() {
    const response = await this.get('/editable/');
    return response.data;
  }

  async fetchCustomScorecardsByRole(role_id, for_selection = false) {
    const response = await this.get('/by_role/', {
      params: { role_id: role_id, for_selection: for_selection }
    });
    return response.data;
  }

  async updateScorecardRoles(role_id, scorecard_ids) {
    const response = await this.put('/update_roles/', {
      role_id: role_id,
      scorecard_ids: scorecard_ids
    });
    return response.data;
  }

  async updateScorecardTemplates(template_id, scorecard_ids) {
    const response = await this.put('/update_templates/', {
      template_id: template_id,
      scorecard_ids: scorecard_ids
    });
    return response.data;
  }
}

export default new CustomScorecardListAPI();
