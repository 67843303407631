import { useState, ChangeEvent, useLayoutEffect } from 'react';
import styled from 'styled-components';
import auth from '../../utils/auth';
import RoleHeaderBar from './components/RoleHeaderBar';
import StartTab from './components/tabs/StartTab';
import AssignmentsTab from './components/tabs/AssignmentsTab';
import DetailsTab from './components/tabs/DetailsTab';
import QuestionsTab from './components/tabs/QuestionsTab';
import ScorecardsTab from './components/tabs/ScorecardsTab';
import Footer from './components/Footer';
import { MergeRoleModal } from '../../components/DistrictJobsList/MergeRoleModal';
import { salarytypeByLabel, employmentType } from '../../utils/enums';
import { isValidEmail, isValidUrl, scrollToElementById } from '../../utils/util';
import { generateId } from '../../utils/util';
import { sortQuestionsAndAttachments } from '../../utils/roleutils';
import { withFeatures } from '../../hoc/Feature';
import JobEdit from '../../components/JobEdit';
import { featureTags } from '../../context/featureContext';
import {
  Job,
  JobErrors,
  School,
  Question,
  QuestionSet,
  DropdownSelectOption,
  HRBPAssignment,
  InternalRoleNote,
} from '../../types/types';
import { RoleFormProps, MultiSelectField, TextAreaField, UpdateFieldInfo } from './types/types';
import { jobErrorsDefault } from './defaults';
import { updateStringField, updateBooleanField, updateNumberField } from './utils';
import { Prompt, useRouteMatch } from 'react-router-dom';

const getActiveHRBPAssignments = (
  selectedSchools,
  roleOrTemplate,
  isApplyingTemplate,
  schoolOptions,
  districtAndSuperAdmins
) => {
  const hrbpAssignments = roleOrTemplate?.hrbp_assignments;
  const activeSchoolIds = schoolOptions?.filter(s => !s.isGroupHeader).map(s => s.id);
  const activeAdminIds = districtAndSuperAdmins?.map(a => a.id);
  const isSchoolLevelRole = selectedSchools.length > 0;
  const activeHRBPS: HRBPAssignment[] = [];

  hrbpAssignments?.forEach(h => {
    const activeAdminOnHRBP = h.admin_users.filter(a => activeAdminIds.includes(a));
    const activeSchoolsOnHRBP = h.schools.filter(s => activeSchoolIds.includes(s));

    // Only display HRBPs on schoolsroles with active admin/schools
    if (isSchoolLevelRole && activeSchoolsOnHRBP.length > 0 && activeAdminOnHRBP.length > 0) {
      const hrbpAssignment = {
        admin_users: activeAdminOnHRBP,
        schools: activeSchoolsOnHRBP,
        role: roleOrTemplate?.id,
      };

      if (!isApplyingTemplate) {
        hrbpAssignment['id'] = h?.id;
      }
      activeHRBPS.push(hrbpAssignment);
      // Only display HRBPs on roles with active admin
    } else if (!isSchoolLevelRole && activeAdminOnHRBP.length > 0 && activeHRBPS.length === 0) {
      const hrbpAssignment = {
        admin_users: activeAdminOnHRBP,
        schools: [],
        role: roleOrTemplate?.id,
      };

      if (!isApplyingTemplate) {
        hrbpAssignment['id'] = h?.id;
      }

      activeHRBPS.push(hrbpAssignment);
    }
  });

  if (activeHRBPS.length > 0) {
    return activeHRBPS;
  } else {
    return [{ admin_users: [], schools: [], role: roleOrTemplate?.id }];
  }
};

const getRoleTypeFromOnet = (onet: string | undefined): string =>
  onet?.startsWith('25') ? 'teaching' : 'non-teaching';

function RoleForm({
  isEditing,
  activelySubmitting,
  apiResponseErrors,
  jobData,
  jobBoardOptions,
  schools,
  schoolOptions,
  applicationStatuses,
  clearAPIResponseErrors,
  districtUsers,
  districtAndSuperAdmins,
  questionBank,
  customScorecardsSelected,
  setCustomScorecardsSelected,
  customScorecards,
  onDelete,
  onSaveRoleForm,
  onSubmitRoleForm,
  onSubmitRoleTemplateForm,
  onSaveRoleTemplateForm,
  isLoadingSchoolOptions,
}: RoleFormProps) {
  let unsubmittedInternalNote: InternalRoleNote = { text: '<p><br></p>', tagged_users: [] };
  const setUnsubmittedInternalNote = (n: InternalRoleNote) => {
    unsubmittedInternalNote = n;
  };
  const [job, setJob] = useState<Job>(jobData);
  const [errors, setErrors] = useState<JobErrors>(jobErrorsDefault);
  const [roleType, setRoleType] = useState<string>(getRoleTypeFromOnet(job?.onet_occupation_code));
  const [jobQuestionsAndAttachmentsAndSets, setJobQuestionsAttachmentsAndSets] = useState<
    (Question | QuestionSet)[]
  >([]);
  const [mergeRoleModalOpen, setMergeRoleModalOpen] = useState<boolean>(false);
  const [schoolsSelected, setSchoolsSelected] = useState<School[]>(schools);
  const [deleteRoleModalOpen, setDeleteRoleModalOpen] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (schoolOptions.length === 0 && districtAndSuperAdmins.length === 0) return;

    // loading state is needed to know whether schoolOptions is empty or the network request hasn't been completed yet
    if (!isLoadingSchoolOptions) {
      setJob(previousJob => {
        const hrbps = getActiveHRBPAssignments(
          schoolsSelected,
          previousJob,
          false,
          schoolOptions,
          districtAndSuperAdmins
        );

        const jobCopy: Job = {
          ...previousJob,
          hrbp_assignments: hrbps,
        };

        combineQuestionsAttachmentsAndSet(jobCopy);
        return jobCopy;
      });
    }
  }, [schoolsSelected, schoolOptions, districtAndSuperAdmins, isLoadingSchoolOptions]);

  const jobCreateMatch = useRouteMatch('/district/jobcreate');
  const jobEditMatch = useRouteMatch('/district/jobedit');
  const isDeleting = Boolean(deleteRoleModalOpen);
  const isEditingOrCreatingJob = Boolean(jobCreateMatch || jobEditMatch);

  const handlePromptMessage: React.ComponentProps<typeof Prompt>['message'] = ({
    pathname: nextPath,
  }) => {
    const isNavigatingToPreviewOrEditPage =
      nextPath.includes('district/jobpreview') || nextPath.includes('district/jobedit');

    if (isEditingOrCreatingJob && !isNavigatingToPreviewOrEditPage && !isDeleting && isDirty) {
      return 'Leave page? Unsaved changes will be lost.';
    } else {
      return true;
    }
  };

  const isEmptyHTMLString = (text: string) => {
    return text === '<p><br></p>';
  };
  const combineQuestionsAttachmentsAndSet = (job: Job) => {
    const questionsAndAttachments: (Question | QuestionSet)[] = [];

    if (job.questions?.length > 0) {
      const nimbleQuestions = job.questions;
      nimbleQuestions.forEach(q => questionsAndAttachments.push(q));
    }

    if (job?.custom_questions?.length > 0) {
      const customQuestions = job.custom_questions;
      customQuestions.forEach(q => {
        questionsAndAttachments.push(q);
      });
    }

    if (job?.requiredapplicationattachment_set?.length > 0) {
      const requiredApplicationAttachments = job.requiredapplicationattachment_set;
      requiredApplicationAttachments.forEach(q => questionsAndAttachments.push(q));
    }

    if (job.question_sets?.length > 0) {
      const questionSets = job.question_sets.map(q => ({
        ...q,
        order: job.question_sets_order?.find(qs => qs.uuid === q.uuid)?.order,
        isShowingEditOptions: false,
      }));
      questionSets.forEach(q => questionsAndAttachments.push(q));
    }

    if (job.role_question_sets?.length > 0 && !job.is_template) {
      const roleQuestionSets = job.role_question_sets.map(q => ({
        ...q,
        order: job.question_sets_order?.find(qs => qs.uuid === q.uuid)?.order,
        isShowingEditOptions: false,
      }));
      roleQuestionSets.forEach(q => questionsAndAttachments.push(q));
    }

    if (job.school_preferences_question) {
      questionsAndAttachments.push(job.school_preferences_question);
    }

    // Generate a local id so we can later find update targets
    questionsAndAttachments.forEach(i => (i['localId'] = generateId()));

    setJobQuestionsAttachmentsAndSets(sortQuestionsAndAttachments(questionsAndAttachments));
  };

  const isNotBlankInternalRoleNote = () => {
    return !isEmptyHTMLString(unsubmittedInternalNote.text);
  };

  const onSaveDeletion = () => {
    if (onDelete !== undefined) {
      onDelete();
    }
  };

  const updateField = (e: UpdateFieldInfo | ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const name = e.target.name;
    setJob(prevJob => {
      const jobCopy: Job = { ...prevJob };
      if (typeof value === 'string') {
        if (
          name === 'requisition_number' ||
          name === 'external_date' ||
          name === 'title' ||
          name === 'contact' ||
          name === 'onet_occupation_code' ||
          name === 'link_to_ats'
        ) {
          updateStringField(name, value, jobCopy);
        }
      } else if (typeof value === 'boolean') {
        if (name === 'internal_only' || name === 'internal_requirements_specified') {
          updateBooleanField(name, value, jobCopy);
        }
      } else if (typeof value === 'number') {
        if (
          name === 'fulltime' ||
          name === 'hiring_season' ||
          name === 'salary_min' ||
          name === 'salary_max' ||
          name === 'salary_type'
        ) {
          updateNumberField(name, value, jobCopy);
        } else if (name === 'start_date_type') {
          updateNumberField(name, value, jobCopy);
          jobCopy['start_date'] = null;
        }
      }
      return jobCopy;
    });
    setIsDirty(true);
    clearAPIResponseErrors();
    setErrors(jobErrorsDefault);
  };

  const setDefaults = () => {
    const jobCopy = { ...job };
    if (jobCopy.salary_min || jobCopy.salary_max) {
      if (!jobCopy.salary_type) {
        jobCopy.salary_type = salarytypeByLabel['per year'];
      }
    }
    if (!jobCopy.fulltime) {
      jobCopy.fulltime = employmentType['Full-Time'];
    }
    setJob(jobCopy);
  };

  const updateHRBPsAndInternalNotes = job => {
    const filteredHrbps = job.hrbp_assignments?.filter(hrbp => hrbp.admin_users.length > 0);

    const internalRoleNotesWithDraft = job.internal_role_notes;

    if (isNotBlankInternalRoleNote() && !auth.isDistrictAdmin()) {
      internalRoleNotesWithDraft.push(unsubmittedInternalNote);
    }

    return {
      ...job,
      hrbp_assignments: filteredHrbps,
      internal_role_notes: internalRoleNotesWithDraft,
    };
  };

  const saveDraft = () => {
    checkForErrorsDraft();
    setDefaults();
    setJob(previousJob => {
      const updatedJob = updateHRBPsAndInternalNotes(previousJob);

      if (updatedJob.is_template) {
        onSaveRoleTemplateForm(updatedJob, jobQuestionsAndAttachmentsAndSets, schoolsSelected);
      } else {
        onSaveRoleForm(updatedJob, jobQuestionsAndAttachmentsAndSets, schoolsSelected);
      }
      return updatedJob;
    });
    setIsDirty(false);
  };

  const publishJob = () => {
    checkForErrorsPublish();
    setDefaults();

    setJob(previousJob => {
      const updatedJob = updateHRBPsAndInternalNotes(previousJob);

      if (updatedJob.is_template) {
        onSubmitRoleTemplateForm(updatedJob, jobQuestionsAndAttachmentsAndSets, schoolsSelected);
      } else {
        onSubmitRoleForm(updatedJob, jobQuestionsAndAttachmentsAndSets, schoolsSelected);
      }
      return updatedJob;
    });
  };

  const checkJobTitle = () => {
    if (!job.title || job.title === '') {
      setErrors({ ...errors, title: true });
      throw new Error('Must provide a job title.');
    }
  };

  const checkHRBPAssignment = () => {
    // If the user is a districtAdmin and there are school roles + assignments including empty values, throw error.
    if (auth.isDistrictAdmin() && schoolsSelected.length > 0 && job.hrbp_assignments.length > 0) {
      job.hrbp_assignments.forEach(assignment => {
        if (
          job.hrbp_assignments.length === 1 &&
          (assignment.admin_users.length === 0 || assignment.schools.length === 0) &&
          assignment.admin_users.length !== assignment.schools.length
        ) {
          throw new Error('Must provide user and school in HRBP Assignment section.');
        }
        if (
          job.hrbp_assignments.length > 1 &&
          (assignment.admin_users.length === 0 || assignment.schools.length === 0)
        ) {
          throw new Error('Must provide user and school in HRBP Assignment section.');
        }
      });
    }
  };

  const checkJobDescription = () => {
    if (auth.isDistrictAdmin() && (!job.description || isEmptyHTMLString(job.description))) {
      throw new Error('Please add a job description.');
    }
  };

  const checkRoleType = () => {
    if (roleType === 'teaching' && !job.onet_occupation_code) {
      throw new Error('Please select a Role Type from the dropdown.');
    }
  };

  const checkLinkToATS = () => {
    if (!job.link_to_ats) {
      throw new Error('Please add a link to the ATS or email address.');
    }

    const tempValue = job.link_to_ats as string;

    if (isValidEmail(tempValue)) {
      return;
    }

    if (!isValidUrl(tempValue)) {
      throw new Error('The link should be a valid URL or email address.');
    }
  };

  const checkForErrorsDraft = () => {
    const errorsCopy: JobErrors = { ...errors, job_description: false };

    try {
      checkJobTitle();
      errorsCopy['title'] = false;
    } catch (error) {
      setErrors({ ...errorsCopy, title: true });
      if (error instanceof Error) {
        throw error;
      }
    }

    if (!auth.inPostingOnlyDistrict()) {
      try {
        checkRoleType();
        errorsCopy['role_type'] = false;
      } catch (error) {
        setErrors({ ...errorsCopy, role_type: true });
        if (error instanceof Error) {
          throw error;
        }
      }
      try {
        checkHRBPAssignment();
        errorsCopy['hrbp_assignments'] = false;
      } catch (error) {
        setErrors({ ...errorsCopy, hrbp_assignments: true });
        if (error instanceof Error) {
          throw error;
        }
      }
    }

    setErrors(errorsCopy);
    return errorsCopy;
  };

  const checkForErrorsPublish = () => {
    const errorsCopy = checkForErrorsDraft();
    try {
      checkJobDescription();
      errorsCopy['job_description'] = false;
    } catch (error) {
      setErrors({ ...errorsCopy, job_description: true });
      if (error instanceof Error) {
        throw error;
      }
    }
    if (auth.inPostingOnlyDistrict()) {
      try {
        checkLinkToATS();
        errorsCopy['link_to_ats'] = false;
      } catch (error) {
        if (error instanceof Error) {
          setErrors({ ...errorsCopy, link_to_ats: error.message });
          scrollToElementById('jobedit_link_to_ats');
          throw error;
        }
      }
    }
  };

  const updateMultiSelect = (values: DropdownSelectOption[], fieldName: MultiSelectField) => {
    setJob(previousState => ({
      ...previousState,
      [fieldName]: values.map(value => value.value),
    }));
    setIsDirty(true);
  };

  const handleTextareaChange = (value: string, fieldName: TextAreaField) => {
    setJob(previousState => ({ ...previousState, [fieldName]: value }));
    setIsDirty(true);
  };

  return (
    <Container>
      <Prompt message={handlePromptMessage} />
      <RoleContainer>
        <RoleHeaderBar
          isEditing={isEditing}
          job={job}
          activelySubmitting={activelySubmitting}
          selectedTabIndex={0}
          setMergeRoleModalOpen={setMergeRoleModalOpen}
          publishJob={publishJob}
          saveDraft={saveDraft}
          errors={errors}
          apiResponseErrors={apiResponseErrors}
        />
        <Content>
          <StartTab
            updateField={updateField}
            job={job}
            jobBoardOptions={jobBoardOptions}
            applicationStatuses={applicationStatuses}
            errors={errors}
            setErrors={setErrors}
            schoolsSelected={schools}
            setSchoolsSelected={setSchoolsSelected}
            schoolOptions={schoolOptions}
            setJob={setJob}
            roleType={roleType}
            setRoleType={setRoleType}
            apiResponseErrors={apiResponseErrors}
          />

          <AssignmentsTab
            updateMultiSelect={updateMultiSelect}
            job={job}
            setJob={setJob}
            errors={errors}
            setErrors={setErrors}
            districtAndSuperAdmins={districtAndSuperAdmins}
            districtUsers={districtUsers}
            schoolsSelected={schoolsSelected}
            setSchoolsSelected={setSchoolsSelected}
            schoolOptions={schoolOptions}
          />

          <DetailsTab
            errors={errors}
            updateField={updateField}
            job={job}
            setJob={setJob}
            handleTextareaChange={handleTextareaChange}
          />

          {!auth.inPostingOnlyDistrict() && (
            <QuestionsTab
              job={job}
              questionBank={questionBank}
              jobQuestionsAndAttachmentsAndSets={jobQuestionsAndAttachmentsAndSets}
              setJobQuestionsAttachmentsAndSets={setJobQuestionsAttachmentsAndSets}
              applicationStatuses={applicationStatuses}
              schoolsSelected={schoolsSelected}
              schoolOptions={schoolOptions}
            />
          )}

          <ScorecardsTab
            job={job}
            setJob={setJob}
            setUnsubmittedInternalNote={setUnsubmittedInternalNote}
            districtAndSuperAdmins={districtAndSuperAdmins}
            customScorecards={customScorecards}
            customScorecardsSelected={customScorecardsSelected}
            setCustomScorecardsSelected={setCustomScorecardsSelected}
            updateField={updateField}
          />

          {mergeRoleModalOpen && (
            <MergeRoleModal
              shouldShow={mergeRoleModalOpen}
              onHide={() => setMergeRoleModalOpen(false)}
              roleBeingMerged={job}
            />
          )}
        </Content>
        <Footer
          job={job}
          activelySubmitting={activelySubmitting}
          setDeleteRoleModalOpen={setDeleteRoleModalOpen}
          deleteRoleModalOpen={deleteRoleModalOpen}
          onSaveDeletion={onSaveDeletion}
          saveDraft={saveDraft}
          publishJob={publishJob}
        />
      </RoleContainer>
    </Container>
  );
}

export default withFeatures({ featureName: featureTags.JOB_TEMPLATES, fallback: JobEdit })(
  RoleForm
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 747px) {
    flex-direction: column;
    height: max-content;
    max-width: 100%;
  }
`;

const Content = styled.div`
  width: 100%;
`;

const RoleContainer = styled.div`
  width: 100%;
`;
