import { Component } from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';
import { StyledSelect } from 'ui-kit';
import ThreeButtonSelect from '../../../common/ThreeButtonSelect';
import VacancyTypeDropdown from './VacancyTypeDropdown';
import ApplicableToSection from './ApplicableTo';
import EditFTES from './EditFTES';
import ModalFooter from '../../../common/Modal/ModalFooter';

import {
  currentHiringSeason,
  hiringSeasonByKey,
  hiringSeasons,
  roleStatusByValue,
  vacancyTypeByValue,
} from 'utils/enums';

export default class AddVacancyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiringSeasonFilter: currentHiringSeason(),
      selectedJobId: null,
      vacancyApplicableToEntireDistrict: true,
      vacancySchoolId: null,
      vacancyType: vacancyTypeByValue.approved,
      vacancyFTEs: 1.0,
      didNotSelectAJob: false,
      didNotSelectASchool: false,
      ftesOutsideAcceptableRange: false,
    };
  }

  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    roleOptions: PropTypes.arrayOf(PropTypes.object),
    schoolOptions: PropTypes.arrayOf(PropTypes.object),
    postVacancyThenCloseModal: PropTypes.func.isRequired,
  };

  handleChange = event => {
    let value = event.target.value;
    if (['true', 'false'].includes(value)) {
      value = this.formatBooleans(value);
    }
    this.setSchoolToNullIfDistrictSelected(event, value);
    this.setState({ [event.target.name]: value });
  };

  formatBooleans = value => {
    if (value === 'true') {
      return true;
    }
    if (value === 'false') {
      return false;
    }
  };

  setSchoolToNullIfDistrictSelected = (event, value) => {
    if (event.target.name === 'vacancyApplicableToEntireDistrict' && value === true) {
      this.setState({
        vacancySchoolId: null,
      });
    }
  };

  renderHiringSeasonFilter = () => {
    // filter will be null when 'All' is selected in UI
    hiringSeasonByKey['all'] = null;
    const currentSeason = hiringSeasons().find(s => s.value === currentHiringSeason());
    const previousSeason = hiringSeasons().find(s => s.value === currentHiringSeason() - 1);

    return (
      <div className="flex vacancy-modal-section">
        <div className="mr2">
          <h4 className="vacancy-modal-section-header">Hiring Season</h4>
          <ThreeButtonSelect
            object={this.state}
            fieldName={'hiringSeasonFilter'}
            enumObject={hiringSeasonByKey}
            handleChange={this.handleChange}
            firstButton={{
              label: previousSeason.label_new,
              enumField: previousSeason.key,
            }}
            secondButton={{
              label: currentSeason.label_new,
              enumField: currentSeason.key,
            }}
            thirdButton={{
              label: 'All',
              enumField: 'all',
            }}
          />
        </div>
      </div>
    );
  };

  renderRolesSelect = () => (
    <div className="vacancy-modal-section">
      <h4 className="vacancy-modal-section-header">Corresponding Job</h4>
      <StyledSelect
        boxShadow="0 2px 2px 0 rgba(0, 0, 0, 0.1)"
        options={this.getFilteredRoles()}
        placeholder="Search Job by Name"
        onChange={selectedObject => this.handleSelectChange(selectedObject, 'selectedJobId')}
        isClearable
      />
    </div>
  );

  getFilteredRoles = () => {
    let roles = [...this.props.roleOptions];
    if (this.state.hiringSeasonFilter !== null) {
      roles = this.filterRolesByHiringSeason(roles);
    }
    roles = this.filterToActiveRoles(roles);
    return roles;
  };

  filterRolesByHiringSeason = roles => {
    return roles.filter(role => role.hiring_season === this.state.hiringSeasonFilter);
  };

  filterToActiveRoles = roles => {
    return roles.filter(role => role.status === roleStatusByValue.active);
  };

  handleSelectChange = (selectedObject, fieldName) => {
    let value;
    if (selectedObject === null) {
      value = null;
    } else {
      value = selectedObject.value;
    }
    this.setState({
      [fieldName]: value,
      didNotSelectAJob: false,
      didNotSelectASchool: false,
      ftesOutsideAcceptableRange: false,
    });
  };

  renderVacancyTypeDropdown = () => {
    return (
      <div className="vacancy-modal-section">
        <h4 className="vacancy-modal-section-header">Vacancy Type</h4>
        <VacancyTypeDropdown
          handleChange={this.handleChange}
          vacancyType={this.state.vacancyType}
        />
      </div>
    );
  };

  getFTEvalue = () => {
    if (typeof this.state.vacancyFTEs === 'number') {
      return this.state.vacancyFTEs.toFixed(1);
    } else {
      return this.state.vacancyFTEs;
    }
  };

  decrementFTES = event => {
    event.preventDefault();
    this.setState(prevState => {
      if (prevState.vacancyFTEs >= 0.1) {
        return { vacancyFTEs: Number(prevState.vacancyFTEs) - 0.1 };
      }
    });
  };

  incrementFTES = event => {
    event.preventDefault();
    this.setState(prevState => {
      if (prevState.vacancyFTEs <= 0.9) {
        return { vacancyFTEs: Number(prevState.vacancyFTEs) + 0.1 };
      }
    });
  };

  getSchoolOptions = () => {
    // remove the Non-school option that is included for the filter in a different component
    return this.props.schoolOptions.filter(option => option.value !== -1);
  };

  save = () => {
    if (!this.hasErrors()) {
      this.formatAndPostVacancy();
    }
  };

  hasErrors = () => {
    if (this.state.selectedJobId === null) {
      this.setState({ didNotSelectAJob: true });
      return true;
    }
    if (this.vacancyRelatedToSchoolButNoSchoolSelected()) {
      this.setState({ didNotSelectASchool: true });
      return true;
    }
    if (this.ftesNotInAcceptableRange()) {
      this.setState({ ftesOutsideAcceptableRange: true });
      return true;
    }
    return false;
  };

  vacancyRelatedToSchoolButNoSchoolSelected = () => {
    return (
      this.state.vacancyApplicableToEntireDistrict === false && this.state.vacancySchoolId === null
    );
  };

  ftesNotInAcceptableRange = () => {
    return !this.ftesWithinAcceptableRange();
  };

  ftesWithinAcceptableRange = () => {
    return this.state.vacancyFTEs >= 0 && this.state.vacancyFTEs <= 1;
  };

  formatAndPostVacancy = () => {
    const newVacancy = {
      district_role_id: this.state.selectedJobId,
      school_id: this.state.vacancySchoolId,
      vacancy_type: this.state.vacancyType,
      ftes: this.state.vacancyFTEs,
    };
    this.props.postVacancyThenCloseModal(newVacancy);
  };

  getErrorMessage = () => {
    if (this.state.didNotSelectAJob) {
      return 'Please select a corresponding job.';
    } else if (this.state.didNotSelectASchool) {
      return 'Please select a school or choose "Entire District."';
    } else if (this.state.ftesOutsideAcceptableRange) {
      return 'Please select an FTE value between 0 and 1.';
    } else {
      return '';
    }
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        bsSize="large"
        dialogClassName="add-vacancy-modal"
      >
        <Modal.Header>
          <h4>Add New Vacancy</h4>
          <div className="modal-header-close" onClick={this.props.onHide}>
            <p>x</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          {this.renderHiringSeasonFilter()}
          {this.renderRolesSelect()}
          {this.renderVacancyTypeDropdown()}
          <EditFTES
            decrementFTES={this.decrementFTES}
            fteValue={this.getFTEvalue()}
            handleChange={this.handleChange}
            incrementFTES={this.incrementFTES}
          />
          <ApplicableToSection
            handleChange={this.handleChange}
            vacancyApplicableToEntireDistrict={this.state.vacancyApplicableToEntireDistrict}
            schoolOptions={this.getSchoolOptions()}
            vacancySchoolId={this.state.vacancySchoolId}
            handleSelectChange={this.handleSelectChange}
          />
        </Modal.Body>
        <ModalFooter
          errorMessage={this.getErrorMessage()}
          cancel={this.props.onHide}
          save={this.save}
          deleteFunction={this.deleteVacancy}
          deleteButtonLabel={'Delete vacancy'}
        />
      </Modal>
    );
  }
}
