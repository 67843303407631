import PropTypes from 'prop-types';

import { momentOr } from 'utils/util';
import { workExperienceType, grades } from 'utils/enums';

const TYPES = workExperienceType().reduce((obj, item) => {
  obj[item.value] = item.label;
  return obj;
}, {});

const GRADES = grades().reduce((obj, item) => {
  obj[item.value] = item.label;
  return obj;
}, {});

export default function WorkExperienceSection({ experiences }) {
  const renderLabels = (arr, mapping) => {
    /** take an array of enums and render as a string of labels according to the mapping */
    return arr.map(label => mapping[label]).join(', ');
  };

  return (
    <div className="simple-card-container">
      {experiences.map((row, i) => (
        <div className="simple-card mb1" key={i}>
          {/* title section */}
          <div>
            {row['title'] || ''}, {row['organization']['name'] || ''}
          </div>

          {/* employment dates */}
          <div>
            <span>
              {row['start_date']
                ? `${momentOr(row['start_date'], 'M/D/YYYY', '')} - ${momentOr(
                    row['end_date'],
                    'M/D/YYYY',
                    'Present'
                  )}`
                : `${momentOr(row['end_date'], 'M/D/YYYY', 'Present')}`}
            </span>
          </div>

          {/* type of position */}
          {row.experience_types.length > 0 && (
            <div>Type: {renderLabels(row.experience_types, TYPES)}</div>
          )}

          {/* grades taught */}
          {row.grades.length > 0 && <div>Grade level: {renderLabels(row.grades, GRADES)}</div>}

          {/* reference information */}
          {row['reference_name'] ? (
            <div>
              <div>Supervisor: {row['reference_name']}</div>
              <div>
                {row['reference_phone'] || ''}{' '}
                <span className="ml1">{row['reference_email'] || ''}</span>
              </div>
            </div>
          ) : null}

          {/* Job description */}
          {row['description'] ? (
            <div className="">Job description: {row['description']}</div>
          ) : null}

          {/* reason for leaving */}
          {row['reason_for_leaving'] ? (
            <div className="">Reason for leaving job: {row['reason_for_leaving']}</div>
          ) : null}
          {row.do_not_contact && <div className="bold">Please do not contact</div>}
        </div>
      ))}
    </div>
  );
}

WorkExperienceSection.propTypes = {
  experiences: PropTypes.arrayOf(Object).isRequired,
};
