import styled from 'styled-components';

export default function QuestionSetBadge({ questionSet }) {
  return (
    <StyledBadge isRoleQuestionSet={questionSet.is_role_qs}>
      {questionSet.is_role_qs ? 'Local' : 'All jobs'}
    </StyledBadge>
  );
}

const StyledBadge = styled.div`
  width: 101px;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 8px;
  background: ${props => (props.isRoleQuestionSet ? '#9B51E0' : '#2D9CDB')};
  border-radius: 3px;
  color: #ffffff;
`;
