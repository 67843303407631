import { Component } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import ReferenceEdit from '../components/CustomReferences/ReferenceEdit';
import AddReferenceQuestionModal from '../components/CustomReferences/AddReferenceQuestionModal';
import CopyReferenceModal from 'components/CustomReferences/CopyReferenceModal';
import LoadingSpinner from '../components/loadingSpinner';

import auth from '../utils/auth';

import nimbleQuestionsAPI from 'api/nimbleQuestionsAPI';
import ReferenceTemplateDetailAPI from 'api/referenceTemplateDetailAPI';
import ReferenceTemplateListAPI from 'api/referenceTemplateListAPI';

import { scorecardQType } from 'utils/enums';
import { withRouter } from 'react-router-dom';

class EditReferenceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reference: {
        title: '',
        questions: [],
        view_permissions: 'me_only',
      },
      loading: false,
      showQuestionModal: false,
      showCopyReferenceModal: false,
      editQuestionType: '',
      questionBank: [],
      errorMessage: '',
    };
  }

  static propTypes = {
    params: PropTypes.object.isRequired,
  };

  componentDidMount() {
    // if no id in params, the user is creating a new reference, so no axios call is needed yet.
    if (this.props.match.params.id) {
      this.setState({ loading: true });
      ReferenceTemplateDetailAPI.fetchReference(this.props.match.params.id)
        .then(reference => {
          if (!this.ignoreLastFetch) {
            this.setState({
              reference,
              loading: false,
            });
          }
        })
        .catch(err => console.error(err));
      this.setState({ loading: false });
    }
    this.fetchNimbleQuestions();
  }

  componentWillUnmount() {
    this.ignoreLastFetch = true;
  }

  fetchNimbleQuestions = () => {
    return nimbleQuestionsAPI
      .getAll()
      .then(questionBank => {
        if (!this.ignoreLastFetch) {
          this.setState({ questionBank });
        }
      })
      .catch(err => {
        console.error(err.message);
      });
  };

  openNewQuestionModal = type => {
    if (type !== null) {
      this.setState({ editQuestionType: type }, this.openModal);
    }
  };

  openModal = () => {
    this.setState({ showQuestionModal: true });
  };

  updateField = (e, id) => {
    const name = e.target.name;
    const reference = this.state.reference;
    if (e.target.type === 'checkbox') {
      reference.questions[id][name] = e.target.checked;
    } else {
      reference[name] = e.target.value;
    }
    this.setState({ reference, errorMessage: '' });
  };

  setQuestionOrder = newQuestionsList => {
    const reference = this.state.reference;
    reference.questions = newQuestionsList;
    this.setState({ reference });
  };

  deleteQuestion = index => {
    const reference = this.state.reference;
    if (index !== '') {
      reference.questions.splice(index, 1);
    }
    this.setState({
      reference,
      showQuestionModal: false,
    });
  };

  saveQuestion = question => {
    const reference = { ...this.state.reference };

    // new questions don't have ids, so find them by their draggable id.
    let idField = question.id ? 'id' : 'draggable_id';

    let index = reference.questions.findIndex(item => item[idField] === question[idField]);
    // insert it into questions array at index, replacing the old question.
    reference.questions.splice(index, 1, question);
    this.setState({
      reference,
      showQuestionModal: false,
    });
  };

  addQuestion = question => {
    const reference = { ...this.state.reference };
    reference.questions.push(question);
    this.setState({
      reference,
      showQuestionModal: false,
    });
  };

  saveReference = () => {
    try {
      this.checkForErrors();
    } catch (error) {
      this.setState({ errorMessage: error.message });
      return;
    }

    this.setState({ loading: true });

    const reference = this.state.reference;

    if (reference.id) {
      ReferenceTemplateDetailAPI.updateReference(reference)
        .then(this.directToSettingsPage)
        .catch(err => {
          console.error(err);
          this.setState({
            loading: false,
            errorMessage: `Oops, there was a problem with the submission.
          Please contact support@hirenimble.com for assistance.`,
          });
        });
    } else {
      ReferenceTemplateListAPI.createReference(reference)
        .then(this.directToSettingsPage)
        .catch(err => {
          console.error(err);
          this.setState({
            loading: false,
            errorMessage: `Oops, there was a problem with the submission.
          Please contact support@hirenimble.com for assistance.`,
          });
        });
    }
  };

  checkForErrors = () => {
    if (!this.state.reference.title) {
      throw new Error('Please add a title');
    }

    // Cannot add more than 1 cumulative score question
    // Cannot add more than 1 final recommendation question
    let hasCumulativeScoreItem = false;
    let hasFinalRecommendationItem = false;
    this.state.reference.questions.forEach(question => {
      if (question.question_type === scorecardQType.cumulative_score) {
        if (hasCumulativeScoreItem) {
          throw new Error('You can only add one Cumulative Score item per reference form');
        } else {
          hasCumulativeScoreItem = true;
        }
      }
      if (question.question_type === scorecardQType.final_recommendation) {
        if (hasFinalRecommendationItem) {
          throw new Error('You can only add one Final Recommendation item per reference form');
        } else {
          hasFinalRecommendationItem = true;
        }
      }
    });
  };

  directToSettingsPage = () => {
    let prefix = auth.isSchoolUser() ? 'school' : 'district';
    this.props.history.push(`/${prefix}/settings/`);
  };

  deleteReference = () => {
    this.setState({ loading: true });
    ReferenceTemplateDetailAPI.deleteReference(this.state.reference.id).then(
      this.directToSettingsPage
    );
  };

  copyReference = reference => {
    const newReference = { ...this.state.reference };
    reference.questions.forEach(question => {
      newReference.questions.push({
        // copy everything except id (and create a new draggable_id)
        draggable_id: shortid.generate(),
        question_type: question.question_type,
        question_text: question.question_text,
        is_required: question.is_required,
        scale: question.scale,
        mc_options: question.mc_options,
        multi_select: question.multi_select,
        include_rating_questions: question.include_rating_questions,
        include_rubric_questions: question.include_rubric_questions,
        show_total_on_preview: question.show_total_on_preview,
        attachment_directions: question.attachment_directions,
        include_maybe_option: question.include_maybe_option,
        show_answer_on_preview: question.show_answer_on_preview,
        directions: question.directions,
        rubric_columns: question.rubric_columns,
      });
    });
    this.setState({
      reference: newReference,
      showCopyReferenceModal: false,
    });
  };

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />;
    }

    return (
      <div>
        {this.state.reference && (
          <ReferenceEdit
            reference={this.state.reference}
            openNewQuestionModal={this.openNewQuestionModal}
            updateField={this.updateField}
            setQuestionOrder={this.setQuestionOrder}
            saveReference={this.saveReference}
            deleteReference={this.deleteReference}
            saveQuestion={this.saveQuestion}
            deleteQuestion={this.deleteQuestion}
            questionBank={this.state.questionBank}
            openCopyReferenceModal={() => {
              this.setState({ showCopyReferenceModal: true });
            }}
            errorMessage={this.state.errorMessage}
          />
        )}
        {this.state.showQuestionModal && (
          <AddReferenceQuestionModal
            show={this.state.showQuestionModal}
            onHide={() => {
              this.setState({ showQuestionModal: false });
            }}
            questionType={this.state.editQuestionType}
            addQuestion={this.addQuestion}
            questionBank={this.state.questionBank}
          />
        )}
        {this.state.showCopyReferenceModal && (
          <CopyReferenceModal
            isOpen={this.state.showCopyReferenceModal}
            onClose={() => {
              this.setState({ showCopyReferenceModal: false });
            }}
            copyReference={this.copyReference}
          />
        )}
      </div>
    );
  }
}

export default withRouter(EditReferenceContainer);
