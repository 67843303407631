import { useState } from 'react';
import styled from 'styled-components';

import SignerRow from './SignerRow';
import { ConfirmationDialog } from 'ui-kit';

import { ReactComponent as PlusIconWithCircle } from 'assets/icon-plus-with-circle-grey.svg';
import { ReactComponent as MinusIconWithCircle } from 'assets/icon-minus-with-circle-grey.svg';
import { ItemContainer, GridContainer, AttachmentIcon, LightText } from '.';

export default function PendingHellosignFormRow({ pendingForm, handleCancellation }) {
  const [expanded, setExpanded] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const toggleExpanded = () => {
    setExpanded(prevValue => !prevValue);
  };

  const openConfirmationDialog = e => {
    e.stopPropagation();
    setConfirmationDialogOpen(true);
  };

  const resultHandler = proceed => {
    if (proceed) {
      handleCancellation(pendingForm.signature_request_id);
    }

    setConfirmationDialogOpen(false);
  };

  return (
    <>
      <ItemContainer>
        <>
          <ClickableGridContainer onClick={toggleExpanded}>
            <AttachmentIcon />
            <LightText>{`${pendingForm.title} (Awaiting Signature)`}</LightText>

            <CancelButton onClick={openConfirmationDialog}>cancel form</CancelButton>
            {expanded ? <CloseIcon /> : <OpenIcon />}
          </ClickableGridContainer>
          {expanded && (
            <>
              {pendingForm.signatures.map(signer => (
                <SignerRow key={signer.signature_id} signer={signer} />
              ))}
            </>
          )}
        </>
      </ItemContainer>
      <ConfirmationDialog
        resultHandler={resultHandler}
        title="Delete form request"
        text="Are you sure you want to cancel this form request? The form and related tasks will be deleted."
        backLabel="Cancel"
        confirmLabel="Delete Form"
        isOpen={confirmationDialogOpen}
      />
    </>
  );
}

const ClickableGridContainer = styled.div`
  min-height: 42px;
  width: 100%;

  display: grid;
  grid-template-columns: 18px 1fr 85px 25px;
  grid-gap: 8px;
  align-items: center;

  cursor: pointer;
`;

const OpenIcon = styled(PlusIconWithCircle)`
  justify-self: end;
  cursor: pointer;
`;

const CloseIcon = styled(MinusIconWithCircle)`
  justify-self: end;
  cursor: pointer;

  margin-bottom: 3px;
`;

const CancelButton = styled.button`
  text-decoration: underline;
  border: none;
  background-color: #fff;
  font-size: 13px;

  &:hover {
    color: #666;
    text-decoration: none;
  }
`;
