import { ReactNode } from 'react';
import { Box, MenuItem, Select, SxProps } from '@mui/material';
import { Label } from 'sharedComponents/Label';

export interface BasicSelectAndLabelProps {
  defaultValue?: any;
  displayName?: string;
  label: string;
  handleShouldItemBeDisabled?: (option: any) => boolean;
  onChange: any;
  options: any[];
  shouldSelectBeDisabled?: boolean;
  sx?: SxProps;
  testid?: string;
  tooltip?: ReactNode;
}

export const BasicSelectAndLabel: React.FC<BasicSelectAndLabelProps> = ({
  defaultValue,
  displayName,
  label,
  handleShouldItemBeDisabled,
  onChange,
  options,
  shouldSelectBeDisabled,
  sx,
  testid,
  tooltip,
}) => {
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
        <Label labelText={label} sx={{ margin: 0 }} />
        &nbsp;
        {tooltip}
      </Box>

      <Select
        displayEmpty
        value={!defaultValue ? null : defaultValue}
        data-testid={testid ? testid : 'basic-select'}
        label={displayName}
        sx={sx}
        disabled={shouldSelectBeDisabled ? shouldSelectBeDisabled : null}
        onChange={(event, value) => {
          onChange(event, value);
        }}
      >
        {options.map(option => (
          <MenuItem
            key={option.label || option.name}
            value={option.value}
            disabled={handleShouldItemBeDisabled ? handleShouldItemBeDisabled(option) : false}
          >
            {option.label || option.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
