import { Body } from 'ui-kit/Modal';
import { VideoIcon } from 'ui-kit/icons';
import { Input, Textarea } from 'ui-kit';
import { ModalBodySectionHeader } from '../styledComponents';
import { constants } from 'utils/enums';

import HeaderInformation from './HeaderInformation';

export default function VideoLink({
  videoLinkName,
  setVideoLinkName,
  description,
  setDescription,
  internalRequirementsSpecified,
  handleItemMatrixChange,
  itemMatrix,
}) {
  return (
    <Body>
      <HeaderInformation
        Icon={VideoIcon}
        title="Video Link Question"
        subtext="Candidate can add a link to an interview video"
        internalRequirementsSpecified={internalRequirementsSpecified}
        handleItemMatrixChange={handleItemMatrixChange}
        itemMatrix={itemMatrix}
      />
      <ModalBodySectionHeader>Video name</ModalBodySectionHeader>
      <Input
        type="text"
        value={videoLinkName}
        placeholder='e.g. "Teaching demonstration video"'
        onChange={e => setVideoLinkName(e.target.value)}
        width={1}
      />
      <ModalBodySectionHeader>Directions to the candidate</ModalBodySectionHeader>
      <Textarea
        value={description}
        placeholder='e.g. "Link to a 5-minute teaching demonstration video, that showcases your teaching style"'
        onChange={e => setDescription(e.target.value)}
        maxLength={constants.MEDIUM_TEXT_LENGTH}
        width={1}
      />
    </Body>
  );
}
