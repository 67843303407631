import { Chip } from 'sharedComponents/Chip';
import { ConnectBadgeLabels } from 'features/MarketplaceV2/utils/connectEnums';

export const DistrictWideBadge: React.FC = () => {
  return (
    <Chip
      label={ConnectBadgeLabels.DISTRICTWIDE}
      variant="filled"
      sx={{ maxWidth: '100px', marginLeft: '8px' }}
    />
  );
};
