import { useEffect, useState } from 'react';
import districtsAPI from 'api/districtsAPI';
import categoriesAPI from 'api/categoriesAPI';
import schoolsAPI from 'api/schoolsAPI';

import {
  early_childhood_grades,
  childhood_grades,
  middle_grades,
  secondary_grades,
} from 'utils/enums';

export type GradeLabel =
  | 'Early Childhood'
  | 'All Elementary'
  | 'All Middle School'
  | 'All High School';

export type Option = {
  value: number;
  label: string;
  key?: string;
  subOptions?: boolean | Option[];
};

export type GradeOption = {
  value: number;
  label: GradeLabel;
  subOptions: Option[];
};

export type DistrictOption = {
  value: number;
  label: string;
};

export type EmploymentTypeOption = {
  value: number;
  label: 'Part-time' | 'Full-time';
};

const gradeOptions: GradeOption[] = [
  {
    value: 1,
    label: 'Early Childhood',
    subOptions: early_childhood_grades(),
  },
  {
    value: 2,
    label: 'All Elementary',
    subOptions: childhood_grades(),
  },
  {
    value: 3,
    label: 'All Middle School',
    subOptions: middle_grades(),
  },
  {
    value: 4,
    label: 'All High School',
    subOptions: secondary_grades(),
  },
];

const employmentTypeOptions: EmploymentTypeOption[] = [
  {
    value: 0,
    label: 'Part-time',
  },
  {
    value: 1,
    label: 'Full-time',
  },
];

export interface FilterOption {
  employmentTypeOptions: EmploymentTypeOption[];
  schoolOptions: Option[];
  gradeOptions: GradeOption[];
  subcategoryStateOptions: Option[];
  districtOptions: DistrictOption[];
}

export const useStateFilterOptions = (stateCode: string): FilterOption => {
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [subcategoryStateOptions, setSubcategoryStateOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);

  useEffect(() => {
    schoolsAPI.fetchSchoolsByState(stateCode).then(schools => {
      setSchoolOptions(
        schools.map(s => {
          return { value: s.id, label: s.name };
        })
      );
    });
  }, [stateCode]);

  useEffect(() => {
    categoriesAPI.fetchCategoriesByState(stateCode).then(categories => {
      // Translate categories array into filter format
      // (id -> value, subcategories -> subOptions)
      const options = [];
      categories.forEach(category => {
        const option = {
          value: category.id,
          label: category.label,
          subOptions: [],
        };
        category.subcategories.forEach(s => {
          option.subOptions.push({ value: Number(s.state_id), label: s.label });
        });
        options.push(option);
      });
      setSubcategoryStateOptions(options);
    });
  }, [stateCode]);

  useEffect(() => {
    districtsAPI.fetchDistrictsByState(stateCode).then(districts => {
      setDistrictOptions(districts.map(d => ({ value: d.id, label: d.name })));
    });
  }, [stateCode]);

  return {
    employmentTypeOptions,
    schoolOptions,
    gradeOptions,
    subcategoryStateOptions,
    districtOptions,
  };
};
