import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ChildrenContainer = styled(Box)({
  marginTop: '24px',
  width: '100%',
});

export const ModalContainer = styled(Box)((props) => ({
  [props.theme.breakpoints.up('xs')]: {
    width: '90%',
  },
  [props.theme.breakpoints.up('md')]: {
    width: '552px',
  },
  backgroundColor: 'white',
  borderRadius: '2px',
  left: '50%',
  maxWidth: '552px',
  padding: '24px',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 100,
}));

export const Title = styled('h1')({
  color: '#1A1A1A',
  fontSize: '24px',
  fontWeight: 400,
  padding: 0,
});

export const Top = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
});