import { createContext, useContext, useState, useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
import { ErrorSnackbar } from 'sharedComponents/Snackbar';

interface NetworkRequestStatusContextType {
  handleNetworkRequestStatus: (response: AxiosResponse) => void;
}

const NetworkRequestStatusContext = createContext<NetworkRequestStatusContextType | undefined>(
  undefined
);

export const useNetworkRequestStatus = () => {
  const context = useContext(NetworkRequestStatusContext);
  if (!context) {
    throw new Error('useNetworkRequestStatus must be used within a NetworkRequestStatusProvider');
  }
  return context;
};

export const NetworkRequestStatusProvider: React.FC = ({ children }) => {
  const [statusMessage, setStatusMessage] = useState<string | null>(null);

  const handleNetworkRequestStatus = (response: AxiosResponse) => {
    const status = response.status;
    if (status < 200 || status >= 400) {
      let errorMessage = `Sorry, there was an error. Please refresh your page. \n Network Error: ${status}`;
      if (response.data && response.data.error) {
        errorMessage = response.data.error;
      }
      setStatusMessage(errorMessage);
    } else {
      setStatusMessage(null);
    }
  };

  const handleClose = () => {
    setStatusMessage(null);
  };

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(config => {
      return config;
    });

    const responseInterceptor = axios.interceptors.response.use(
      response => {
        handleNetworkRequestStatus(response);
        return response;
      },
      error => {
        handleNetworkRequestStatus(error.response);
        throw error;
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return (
    <NetworkRequestStatusContext.Provider value={{ handleNetworkRequestStatus }}>
      {children}
      {statusMessage && (
        <ErrorSnackbar
          open={true}
          duration={6000}
          onClose={handleClose}
          message={statusMessage}
          position={{ vertical: 'bottom', horizontal: 'left' }}
        />
      )}
    </NetworkRequestStatusContext.Provider>
  );
};
