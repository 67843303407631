import PropTypes from 'prop-types';
import styled from 'styled-components';

import ErrorText from '../../errortext';

/**
 * To be used with ui-kit/Modal.jsx instead of bootstrap Modal.
 * Usage:
 *  <Modal.Modal isOpen={isOpen} onClose={onClose}>
 *    <Modal.Title>...</Modal.Title>
 *    <Modal.Body>...</Modal.Body>
 *    <Modal.Actions>
 *      <ModalFooterNew />
 *    </Modal.Actions>
 *  </Modal.Modal>
 */
function ModalFooterNew({
  cancel,
  save,
  deleteFunction,
  errorMessage,
  // use errorMessages instead of errorMessage to display more than one error
  errorMessages,
  deleteButtonLabel,
  cancelButtonLabel,
  saveButtonLabel,
  saveDisabled = false,
  altSave,
  altSaveLabel,
}) {
  return (
    <FooterContainer>
      <CenterFlexDiv>
        {cancel && <ModalButton onClick={cancel}>{cancelButtonLabel || 'Cancel'}</ModalButton>}
        {deleteFunction && (
          <ModalDeleteButton onClick={deleteFunction}>
            {deleteButtonLabel || 'Delete'}
          </ModalDeleteButton>
        )}
      </CenterFlexDiv>
      <CenterFlexDiv>
        <ErrorTextContainer>
          {errorMessage && <ErrorText message={errorMessage} />}
          {errorMessages &&
            errorMessages.map((message, i) => {
              return <ErrorText message={message} key={i} />;
            })}
        </ErrorTextContainer>
        <SaveContainer>
          {save && (
            <ModalSaveButton onClick={save} disabled={saveDisabled} data-testid="modal-save-button">
              {saveButtonLabel || 'Save'}
            </ModalSaveButton>
          )}
          {altSave && <StyledLink onClick={altSave}>{altSaveLabel}</StyledLink>}
        </SaveContainer>
      </CenterFlexDiv>
    </FooterContainer>
  );
}

ModalFooterNew.propTypes = {
  errorMessage: PropTypes.string,
  errorMessages: PropTypes.arrayOf(PropTypes.string),
  cancel: PropTypes.func,
  save: PropTypes.func,
  deleteFunction: PropTypes.func,
  deleteButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  saveButtonLabel: PropTypes.string,
  saveDisabled: PropTypes.bool,
  altSave: PropTypes.function,
  altSaveLabel: PropTypes.string,
};

export default ModalFooterNew;

const FooterContainer = styled.div`
  height: 100%;
  text-align: right;
  display: flex;
  flex-wrap: ${props => (props.errorMessage ? 'wrap' : 'nowrap')};
  justify-content: space-between;
`;

const CenterFlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const ModalButton = styled.button`
  height: 50px;
  -webkit-appearance: none;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 0px 42px;
  background-color: white;
  color: #c2c2c2;
  font-size: 16px;
  max-width: 340px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ModalSaveButton = styled(ModalButton)`
  background-color: ${props => (props.disabled ? props.theme.uiColors.grey : '#00b88d')};
  color: white;
  border: none;
  min-width: 170px;
`;

const ModalDeleteButton = styled(ModalButton)`
  margin-left: 1rem;
`;

const ErrorTextContainer = styled.div`
  margin-right: 1rem;
  text-align: ${props => (props.errorMessage ? 'right' : 'left')};
  margin-left: ${props => (props.errorMessage ? '0px' : '16px')};
`;

const StyledLink = styled.button`
  display: flex;
  font-size: 14px;
  text-decoration-line: underline;
  background-color: transparent;
  border: none;

  &:hover {
    color: #008060;
    text-decoration: underline;
  }
`;

const SaveContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
