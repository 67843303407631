import { useState, useEffect } from 'react';

import Header from './Header';
import List from './List';
import QuestionSetModal from './QuestionSetModal';

import useApplicationQuestionSets from './useApplicationQuestionSets';

export default function Settings() {
  const { data, isLoading } = useApplicationQuestionSets();

  const [questionSets, setQuestionSets] = useState([]);
  const [questionSetToEdit, setQuestionSetToEdit] = useState(null);
  const [isQuestionSetModalOpen, setIsQuestionSetModalOpen] = useState(false);

  // this effect fetches the initial question set list
  useEffect(() => {
    if (!data) return;
    setQuestionSets(
      data.sort((a, b) => {
        if (a.title < b.title) return -1;
        if (a.title < b.title) return 1;
        return 0;
      })
    );
  }, [data]);

  const openQuestionSetModal = () => {
    setIsQuestionSetModalOpen(true);
  };
  const closeQuestionSetModal = () => {
    setIsQuestionSetModalOpen(false);
  };

  // handles updates to questionSet state
  const updateQuestionSetData = (questionSet, remove) => {
    // Remove:
    if (remove) {
      setQuestionSets(questionSets.filter(qs => qs.uuid !== questionSet.uuid));
      return;
    }

    // Edit:
    let added = false;
    let newQuestionSets = questionSets.map(qs => {
      if (qs.uuid === questionSet.uuid) {
        added = true;
        return questionSet;
      }
      return qs;
    });

    // Add:
    if (!added) {
      newQuestionSets = [...questionSets, questionSet].sort((a, b) => {
        if (a.title < b.title) return -1;
        if (a.title < b.title) return 1;
        return 0;
      });
    }

    setQuestionSets(newQuestionSets);
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Header
        setQuestionSetToEdit={setQuestionSetToEdit}
        openQuestionSetModal={openQuestionSetModal}
      />
      <List
        data={questionSets}
        setQuestionSetToEdit={setQuestionSetToEdit}
        openQuestionSetModal={openQuestionSetModal}
      />
      <QuestionSetModal
        questionSets={questionSets}
        isOpen={isQuestionSetModalOpen}
        onClose={closeQuestionSetModal}
        questionSetToEdit={questionSetToEdit}
        updateQuestionSetData={updateQuestionSetData}
        // Spec: this is always true when user is on the settings page
        internalRequirementsSpecified={true}
      />
    </>
  );
}
