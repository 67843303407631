import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { AltModal, Input, MultiSelect } from 'ui-kit';
import ModalFooterNew from 'components/common/Modal/ModalFooterNew';

export default function HelloSignPacketModal({
  helloSignPacket,
  setHelloSignPacket,
  helloSignTemplates,
  errors,
  onSave,
  onClose,
}) {
  const templatesById = useMemo(
    () => Object.fromEntries(helloSignTemplates.map(template => [template.id, template])),
    [helloSignTemplates]
  );

  const handleNameChange = useCallback(
    e =>
      setHelloSignPacket({
        ...helloSignPacket,
        title: e.target.value,
      }),
    [helloSignPacket, setHelloSignPacket]
  );

  const handleTemplateSelectionChange = useCallback(
    selectedOptions =>
      setHelloSignPacket({
        ...helloSignPacket,
        template_ids: selectedOptions?.map(template => template.id) ?? [],
      }),
    [helloSignPacket, setHelloSignPacket]
  );

  const modalTitle = helloSignPacket.id ? 'Edit Packet' : 'Create Packet';

  return (
    <AltModal.AltModal isOpen={true} onClose={onClose}>
      <AltModal.Title>{modalTitle}</AltModal.Title>
      <AltModal.Body>
        <Section>
          <Label>Name</Label>
          <Input
            width={1}
            name="title"
            value={helloSignPacket.title}
            onChange={handleNameChange}
            placeholder="e.g. Onboarding packet"
          />
          {errors?.title?.map((errorText, index) => (
            <ErrorMessage key={index}>{errorText}</ErrorMessage>
          ))}
        </Section>
        <Section>
          <Label>Add Form(s)</Label>
          <MultiSelect
            options={helloSignTemplates}
            getOptionValue={template => template.id}
            getOptionLabel={template => template.title}
            value={helloSignPacket.template_ids.map(template_id => templatesById[template_id])}
            placeholder="Start typing or use drop down to select one or more forms"
            onChange={handleTemplateSelectionChange}
          />
          {errors?.template_ids?.map((errorText, index) => (
            <ErrorMessage key={index}>{errorText}</ErrorMessage>
          ))}
        </Section>
      </AltModal.Body>
      <AltModal.Actions>
        <ModalFooterNew cancel={onClose} save={onSave} />
      </AltModal.Actions>
    </AltModal.AltModal>
  );
}

const Section = styled.div`
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }

  .file-upload-container {
    box-shadow: none;
    border: 1px solid #d7d7d7;
    border-radius: 3px;
  }
`;

const Label = styled.p`
  font-size: 16px;
  margin-bottom: 6px;
`;

const ErrorMessage = styled.div`
  font-size: 13px;
  line-height: 24px;
  color: #c03;
  margin-top: 4px;
`;
