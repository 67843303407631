import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useOnClickOutside } from 'hooks';

/*
  Dropdown for making tags, used for emails right now.

  Example usage:
    <EmailSelect
      initialOptions={[options]}
      loadOptions={function(value, callback(options))}
      onChange={function(options)}
      isDisabled={Boolean}
      placeholder={String}
      validTestRe={Regex}
    />

  More info:

  options look like:
    [{label: string, value: string, isValid: bool}]
  (isValid is applied by the validation)

  loadOptions gets:
    the current value as the first argument,
    also receives a second argument for a callback function.

  onChange is:
    called every time a new selection is made.
    it's only argument is the array of selected options.

  placeholder should be:
    a string to show when there is nothing selected or typed.
    optional.

  validTestRe is a:
    regular expression that represents a valid value for an option.
    optional, defaults to checking for X@X.X
*/

export const EmailSelect = ({
  loadOptions,
  initialOptions,
  onChange,
  isDisabled = false,
  placeholder = 'Type the name of a Nimble user or any email address',
  validTestRe = /\S+@\S+\.\S+/,
}) => {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [usedInitialOptions, setUsedInitialOptions] = useState(false);
  const inputElement = useRef(null);
  const showMenu = options?.length > 0;
  const closeMenuOnClickOutside = useOnClickOutside(() => {
    // add the value:
    const createdOptions = parseValue(inputElement.current.value);
    setSelectedOptions([...selectedOptions, ...createdOptions]);
    // close menu:
    setValue('');
    setOptions([]);
  }, showMenu);

  // if there are default/initial options.
  if (initialOptions && !usedInitialOptions) {
    setSelectedOptions(
      initialOptions.map(option => {
        option.isValid = validTestRe.test(option.value);
        return option;
      })
    );
    setUsedInitialOptions(true);
  }

  useEffect(() => {
    if (onChange) onChange(selectedOptions);
    focus();
  }, [selectedOptions, onChange]);

  function parseValue(currentInputValue) {
    // Split on commas and spaces, then filter out empty (blank '') items, and maps the emails into options.
    // Also applies validation to at least look like an email.
    const parsedOptions = currentInputValue
      .split(/[ ,]+/)
      .filter(Boolean)
      .map(segment => {
        const isValid = validTestRe.test(segment);
        return {
          label: segment,
          value: segment,
          isValid: isValid,
        };
      });
    return parsedOptions;
  }

  function focus() {
    inputElement.current.focus();
  }

  function handleChange(ev) {
    const currentInputValue = ev.target.value;

    if (!currentInputValue) {
      // user has cleared the field
      setValue('');
      setOptions([]);
    } else {
      // The delimiters for the tag list are ',' and ' '.
      // Only create a tag on space, if the input looks like an email
      const lastCharacter = currentInputValue[currentInputValue.length - 1];
      if (
        (lastCharacter === ' ' && validTestRe.test(currentInputValue.trim())) ||
        lastCharacter === ','
      ) {
        // a completed un-validated value sits there
        const createdOptions = parseValue(currentInputValue);
        setSelectedOptions([...selectedOptions, ...createdOptions]);
        setValue('');
        setOptions([]);
      } else {
        // if there is loadOptions, then call it with the value:
        if (loadOptions) {
          loadOptions(currentInputValue, results => {
            setOptions(results || []);
          });
        }
        setValue(currentInputValue);
      }
    }
  }

  function handleKeyDown(ev) {
    const [ENTER, ARROWUP, ARROWDOWN] = [13, 38, 40]; // Key codes..
    const key = ev.which;
    const currentInputValue = ev.target.value;
    const highlightedOptionIdx = options.findIndex(o => o.isHighlighted);

    if ([ENTER, ARROWUP, ARROWDOWN].includes(key)) ev.preventDefault();

    if (key === ENTER) {
      // if there is a highlighted option add it
      if (highlightedOptionIdx >= 0) {
        setSelectedOptions([...selectedOptions, options[highlightedOptionIdx]]);
      } else {
        //else add the value:
        const createdOptions = parseValue(currentInputValue);
        setSelectedOptions([...selectedOptions, ...createdOptions]);
      }
      setValue('');
      setOptions([]);
    }

    if (key === ARROWUP) {
      const nextIdx = highlightedOptionIdx === 0 ? 0 : highlightedOptionIdx - 1;
      highlightOption(options[nextIdx]);
    }

    if (key === ARROWDOWN) {
      const previousIdx =
        highlightedOptionIdx === options.length - 1 ? options.length - 1 : highlightedOptionIdx + 1;
      highlightOption(options[previousIdx]);
    }
  }

  function handleSelect(option) {
    setSelectedOptions([...selectedOptions, option]);
    setValue('');
    setOptions([]);
  }

  function handleRemoveSelection(option) {
    if (isDisabled) return;
    const filteredOptions = selectedOptions.filter(selectedOption => {
      return selectedOption.label !== option.label && selectedOption.value !== option.value;
    });
    setSelectedOptions(filteredOptions);
  }

  function highlightOption(highlightedOption) {
    setOptions(
      options.map(option => {
        if (
          option?.label === highlightedOption?.label && // Sometimes there are no labels.
          option.value === highlightedOption.value
        ) {
          option.isHighlighted = true;
        } else {
          option.isHighlighted = false;
        }
        return option;
      })
    );
  }

  return (
    // Refactor to use class names, less code.
    <EmailSelectContainer ref={closeMenuOnClickOutside}>
      {selectedOptions.map(selectedOption => {
        return (
          <SelectedOption
            key={selectedOption.label}
            className={isDisabled ? 'disabled' : !selectedOption.isValid ? 'invalid' : ''}
          >
            {selectedOption.label}
            <SelectedOptionDismiss onClick={() => handleRemoveSelection(selectedOption)}>
              X
            </SelectedOptionDismiss>
          </SelectedOption>
        );
      })}
      <SelectInput
        ref={inputElement}
        type="text"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={value}
        placeholder={selectedOptions.length ? '' : placeholder}
        disabled={isDisabled}
      />
      {showMenu && (
        <SelectList>
          {options.map(option => (
            <SelectListItem
              key={option.value}
              onClick={() => handleSelect(option)}
              onMouseOver={() => highlightOption(option)}
              className={option.isHighlighted ? 'highlighted' : ''}
            >
              {option.label}
            </SelectListItem>
          ))}
        </SelectList>
      )}
    </EmailSelectContainer>
  );
};

const EmailSelectContainer = styled.div`
  margin: 0 5px 0 36px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SelectedOptionDismiss = styled.span`
  cursor: pointer;
  display: inline-block;
  color: #00b88d;
  width: 15px;
  text-align: center;
`;

const SelectedOption = styled.span`
  color: #777;
  font-size: 12px;
  height: 24px;
  display: inline-block;
  padding: 2px;
  margin: 0 5px 5px;
  background-color: #b1ecdb;
  border: 1px solid #00b88d;
  border-radius: 2px;

  &.disabled {
    background-color: #eeeff2 !important;
    border-color: #a6a7ad;

    > ${SelectedOptionDismiss} {
      display: none;
    }
  }

  &.invalid {
    background-color: #fff;
    border-color: #ef5675;
    color: #ef5675;

    > ${SelectedOptionDismiss} {
      color: #ef5675;
    }
  }
`;

const SelectInput = styled.input`
  border: none;
  background: #fff;
  padding: 0;
  margin: 0 0 0 5px;
  height: 24px;
  flex: 2;
  width: 360px;
  min-width: 0;

  &::placeholder {
    color: rgba(57, 60, 73, 0.3);
  }

  &:disabled {
    display: none;
  }
`;

const SelectList = styled.ul`
  position: absolute;
  width: 100%;

  font-size: 14px;

  color: rgba(0, 0, 0, 0.6);
  background-color: #ffffff;
  border: 1px solid #cccccc;

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  list-style-type: none;
  z-index: 10;
`;

const SelectListItem = styled.li`
  padding: 6px 12px;
  cursor: pointer;

  &:hover,
  &.highlighted {
    background-color: var(--gray);
  }

  &:first-child {
    padding-top: 12px;
  }

  &:last-child {
    padding-bottom: 12px;
  }
`;
