import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { JobDiscoveryFilterContainerProps } from 'types/marketplaceV2Types';
import { JobDiscoveryLocationFilter } from './JobDiscoveryLocationFilter';
import defaultMap from 'assets/connect/connect-default-map.png';
import { Filters } from './JobDiscoveryFilters';
import { Header } from '../JobDiscoveryHeader';
import { ConnectFilterName } from 'features/MarketplaceV2/utils/connectEnums';
import { nimbleTheme } from 'theme';

export const JobDiscoveryFilterContainer: React.FC<JobDiscoveryFilterContainerProps> = ({
  filterValues,
  actions,
  filterOptions,
  isUserAuthenticated,
}) => {
  const [selectedSubjectOptions, setSelectedSubjectOptions] = useState([]);
  const [selectedGradeOptions, setSelectedGradeOptions] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [mapUrl, setMapUrl] = useState<string>();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.between('xs', 'lg'));

  const getSelectedOptions = selectedOptions => {
    if (!Array.isArray(selectedOptions)) {
      return [];
    }
    return selectedOptions.map(option => option.value);
  };

  const updateSelectedFilterOptions = (
    selectedValues,
    filterOptions,
    updateSelectedValues,
    setFilterIsActive,
    filtername
  ) => {
    actions.setIsLoadingInitialFilterResults(true);
    const selectedOptions = getSelectedOptions(selectedValues);

    if (selectedValues?.length === filterOptions?.length || selectedValues?.length === 0) {
      updateSelectedValues([]);
      setFilterIsActive(filtername, false);
    } else {
      updateSelectedValues(selectedOptions);
      setFilterIsActive(filtername, true);
    }
  };

  useEffect(() => {
    updateSelectedFilterOptions(
      selectedSubjectOptions,
      filterOptions.selectedSubjectIds,
      actions.setSelectedSubjectIds,
      actions.setFilterIsActive,
      ConnectFilterName.SUBCATEGORIES
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubjectOptions]);

  useEffect(() => {
    updateSelectedFilterOptions(
      selectedGradeOptions,
      filterOptions.selectedGrades,
      actions.setSelectedGrades,
      actions.setFilterIsActive,
      ConnectFilterName.GRADES
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGradeOptions]);

  useEffect(() => {
    setShowMap(mapUrl?.length > 0);
  }, [mapUrl]);

  return (
    <>
      <Header isUserAuthenticated={isUserAuthenticated} />
      <Hidden mdUp>
        <MapContainer>
          {showMap ? (
            <Map src={mapUrl} alt="locations" />
          ) : (
            <Map src={defaultMap} alt="default-location" />
          )}
        </MapContainer>
      </Hidden>
      <Grid container spacing={1.5} justifyContent={'space-between'}>
        <Grid item xs={12} lg={7}>
          <Filters
            filterValues={filterValues}
            filterOptions={filterOptions}
            isMobile={isMobile}
            setSelectedGradeOptions={setSelectedGradeOptions}
            setSelectedSubjectOptions={setSelectedSubjectOptions}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <JobDiscoveryLocationFilter
            distanceDisplayName="Distance"
            locationDisplayName="Enter address or zip code"
            filterValues={filterValues}
            locationChangeHandler={actions.setSelectedLocation}
            filterOptions={filterOptions.distanceOptions}
            actions={actions}
            setMapUrl={setMapUrl}
            setShowMap={setShowMap}
            mapUrl={mapUrl}
          />
        </Grid>
      </Grid>
      <Hidden mdDown>
        <MapContainer>
          {showMap ? (
            <Map src={mapUrl} alt="locations" />
          ) : (
            <Map src={defaultMap} alt="default-mobile-locations" />
          )}
        </MapContainer>
      </Hidden>
    </>
  );
};

const MapContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  border: `solid 1px ${nimbleTheme.palette.gray.darker}`,
  borderRadius: '12px',
  margin: `${nimbleTheme.spacing(1.5)} 0px 0px`,
  [theme.breakpoints.down('md')]: {
    height: '148px',
    margin: `${nimbleTheme.spacing(4)} 0px`,
  },
}));

const Map = styled('img')(({ theme }) => ({
  background: 'url(${props => props.src}) lightgray 50% / cover no-repeat',
  alignSelf: 'center',
  backgroundPosition: 'center',
  width: ' 100%',
  height: '224px',
  borderRadius: '12px',
  border: `solid 1px ${nimbleTheme.palette.gray.darker}`,
  objectFit: 'cover',
  [theme.breakpoints.down('md')]: {
    height: '100%',
  },
}));

// below is the school and district filters that are temporaily disabled

// const [selectedDistrictOptions, setSelectedDistrictOptions] = useState([]);
// const [selectedSchoolOptions, setSelectedSchoolOptions] = useState([]);

// useEffect(() => {
//   updateSelectedFilterOptions(
//     selectedDistrictOptions,
//     filterOptions.DistrictIds,
//     actions.setSelectedDistrictIds
//   );
// }, [selectedDistrictOptions]);

// useEffect(() => {
//   updateSelectedFilterOptions(
//     selectedSchoolOptions,
//     filterOptions.selectedSchoolIds,
//     actions.setSelectedSchoolIds
//   );
// }, [selectedSchoolOptions]);

{
  /* <GridItem xs={12} sm={3}>
            <MultiSelectWithSearchMUI
              displayName="Districts"
              handleChange={setSelectedDistrictOptions}
              options={filterOptions.districtOptions}
              showCheckbox={true}
              showSelectAll={true}
              values={filterValues.districtOptions}
            />
          </GridItem>
          <GridItem xs={12} sm={3}>
            <MultiSelectWithSearchMUI
              displayName="Schools"
              handleChange={setSelectedSchoolOptions}
              options={filterOptions.schoolOptions}
              showCheckbox={true}
              showSelectAll={true}
              values={filterValues.schoolOptions}
            />
          </GridItem> */
}
