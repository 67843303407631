import { Component } from 'react';

import JobEdit from '../components/JobEdit';
import axios from 'axios';
import { roleStatuses, getJobStatusColor } from '../utils/enums';
import _ from 'lodash';
import auth from '../utils/auth';
import SchoolrolesAPI from 'api/schoolrolesAPI';
import nimbleQuestionsAPI from 'api/nimbleQuestionsAPI';
import { withRouter } from 'react-router-dom';

const statuses = _.keyBy(roleStatuses(), 'value');

const initialErrorState = {
  link_to_ats: '',
};

class SchoolJobEditContainer extends Component {
  state = {
    job: null,
    statusColor: '',
    questionBank: [],
    apiResponseErrors: { ...initialErrorState },
  };

  componentDidMount() {
    axios.get(`/api/schoolrole/${this.props.match.params.id}/`).then(r => {
      if (!this.ignoreLastFetch) {
        // Schoolroles in draft status don't have a district role and can't pull
        // in its questions. The child components expect an array, so set a default here.
        // A more permanent solution is to remove that field from the backend since questions
        // are never used on schoolroles.
        const job = r.data;
        if (!job.questions) {
          job.questions = [];
        }
        this.setState({ job }, this.setStatusColor);
      }
    });
    nimbleQuestionsAPI.getAll().then(questionBank => {
      if (!this.ignoreLastFetch) {
        this.setState({ questionBank });
      }
    });
  }

  setStatusColor = () => {
    this.setState({ statusColor: getJobStatusColor(this.state.job.status) });
  };

  onDelete = () => {
    return axios.delete(`/api/schoolrole/${this.state.job.id}/`).then(r => {
      if (!this.ignoreLastFetch) {
        this.props.history.push('/school/jobslist');
      }
    });
  };

  onSave = putJob => {
    putJob.status = statuses.draft.key;

    SchoolrolesAPI.updateSchoolrole(putJob).then(() => {
      if (!this.ignoreLastFetch) {
        this.props.history.push('/school/jobslist?f_JS=draft');
      }
    });
  };

  onSubmit = putJob => {
    putJob.status = statuses.submitted.key;
    putJob.submitted_by = auth.getUser().id;
    SchoolrolesAPI.updateSchoolrole(putJob).then(() => {
      if (!this.ignoreLastFetch) {
        this.props.history.push('/school/jobslist?f_JS=pending');
      }
    });
  };

  componentWillUnmount() {
    this.ignoreLastFetch = true;
  }

  clearAPIResponseErrors = () => {
    this.setState({ apiResponseErrors: { ...initialErrorState } });
  };

  render() {
    return (
      this.state.job && (
        <JobEdit
          job={this.state.job}
          statusColor={this.state.statusColor}
          questionBank={this.state.questionBank}
          onDelete={this.onDelete}
          onSave={this.onSave}
          onSubmit={this.onSubmit}
          onSubmitLabel="Submit to Admin"
          pageTitle={`Edit Job at ${auth.getUser().profile.school.name}`}
          apiResponseErrors={this.state.apiResponseErrors}
          clearAPIResponseErrors={this.clearAPIResponseErrors}
        />
      )
    );
  }
}

export default withRouter(SchoolJobEditContainer);
