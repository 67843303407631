import { Component } from 'react';
import styled from 'styled-components';
import { flexbox, grid } from 'styled-system';
import PropTypes from 'prop-types';

import { AltModal, Input } from 'ui-kit';
import { salutation } from 'utils/enums';
import ReactQuill from 'react-quill';
import dedent from 'dedent-js';
import auth from 'utils/auth';
import _ from 'lodash';
import moment from 'moment';

import { DropdownWithInputFilter } from 'ui-kit';

import { ReactComponent as GrayArrow } from 'assets/icon_right_caret_grey.svg';
import { ReactComponent as WhiteCheckmark } from 'assets/icons/icon-white-checkmark.svg';

const salutationReadable = salutation().reduce((obj, item) => {
  obj[item.value] = item.label;
  return obj;
}, {});

export default class ReferenceNewRequest extends Component {
  static propTypes = {
    sendRequest: PropTypes.func.isRequired,
    showRequestSection: PropTypes.func.isRequired,
    referenceTemplates: PropTypes.arrayOf(PropTypes.object).isRequired,
    user: PropTypes.object.isRequired,
    emailTemplatesList: PropTypes.array,
    application: PropTypes.object.isRequired,
    removeCheckmark: PropTypes.func.isRequired,
    requestSuccessful: PropTypes.bool.isRequired,
    requestSendingSpinner: PropTypes.bool.isRequired,
    references: PropTypes.array.isRequired,
    selectedDistrictReference: PropTypes.object,
    onBack: PropTypes.func.isRequired,
    adminUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  constructor(props) {
    super(props);
    this.sender = auth.getUser();
    this.candidate = props.user;
    this.application = props.application;
    this.state = {
      error: '',
      referenceTemplateSelected: '',
      customMessage: '',
      customSubject: '',
      emailTemplate: {},
      reference: this.freshReference(),
    };
  }

  componentDidMount() {
    if (this.props.selectedDistrictReference) {
      this.setState({ reference: this.props.selectedDistrictReference });
    }
  }

  freshReference = () => ({
    name: '',
  });

  requestReference = () => {
    try {
      this.checkForErrors();
    } catch (error) {
      this.setState({ error: error.message });
      return;
    }

    this.setState({ error: '' });

    this.props.sendRequest({
      recipients: [this.state.reference],
      reference_template_id: this.state.referenceTemplateSelected.id,
      message: this.state.customMessage,
      subject: this.state.customSubject,
      is_reminder: false,
      reference_id: this.state.reference.id,
    });

    // don't reset body, so the user can send the same email
    // to different recipients.
    this.setState({ reference: this.freshReference() });
  };

  checkForErrors = () => {
    if (!this.state.reference?.id) {
      throw new Error('Please select a reference');
    }

    if (!this.state.referenceTemplateSelected?.id) {
      throw new Error('Please select a reference form');
    }

    if (!this.state.customSubject) {
      throw new Error('Please enter a subject');
    }

    if (!this.state.customMessage) {
      throw new Error('Please enter a message');
    }
  };

  updateReferenceTemplateSelection = referenceTemplateId => {
    this.props.removeCheckmark();

    if (!referenceTemplateId) {
      return this.setState({
        referenceTemplateSelected: {},
      });
    }
    const referenceTemplate = this.props.referenceTemplates.find(
      template => template.id === referenceTemplateId
    );
    if (referenceTemplate) {
      this.setState({
        referenceTemplateSelected: referenceTemplate,
        error: '',
      });
    }
  };

  /**
   * Takes a template variable, as documented above, and returns the dynamic value.
   * @param input e.g. '{Sender first name}'
   * @return value e.g. 'Frank' or '()' if the lookup does not exist or '[]' if the given input
   * is not a valid substitution.
   */
  dynamicSub = input => {
    /**
     * mapping from human readable variable names to names that fit our data models.
     * Edit 4/23/19: change candidate fields to 'Candidate ...', but maintaining support
     * for old fields name, hence the duplicates.
     */
    const variableMapping = {
      'Candidate first name': 'candidate.first_name',
      'First name': 'candidate.first_name',
      'Candidate last name': 'candidate.last_name',
      'Last name': 'candidate.last_name',
      'Candidate phone': 'candidate.profile.phone_cell',
      'Phone number': 'candidate.profile.phone_cell',
      'Job title': 'application.role.title',
      'Sender first name': 'sender.first_name',
      'Sender last name': 'sender.last_name',
      'School name': 'sender.profile.school.name',
      'District name': 'sender.profile.district.name',
      'Interview date & time': 'application.interview.when',
      'Reference salutation': 'reference.salutation',
      'Reference first name': 'reference.first_name',
      'Reference last name': 'reference.last_name',
      'Reference email': 'reference.email',
      'Reference phone': 'reference.phone',
    };

    if (!_.startsWith(input, '{') || !_.endsWith(input, '}')) {
      return '[]';
    }

    input = _.trim(input, '{}');
    input = variableMapping[input];
    let fields = _.split(input, '.'); // eg. ['candidate', 'first_name'] or ['sender', 'last_name']
    // special case for salutation enum
    if (fields[1] === 'salutation') {
      return salutationReadable[this.state.reference.salutation] || '';
    }

    // special case for reference first name
    if (fields[0] === 'reference' && fields[1] === 'first_name') {
      let str = this.state.reference.name;
      return str.substr(0, str.indexOf(' '));
    }

    // special case for reference last name
    if (fields[0] === 'reference' && fields[1] === 'last_name') {
      let str = this.state.reference.name;
      return str.substr(str.indexOf(' ') + 1);
    }

    // reference is contained in this.state, the others in 'this'.
    let outerObj = fields[0] === 'reference' ? this.state : this;
    let obj = outerObj[fields[0]]; // this.candidate, this.application, or this.sender
    fields = _.drop(fields, 1);
    for (let field of fields) {
      if (obj && field in obj) {
        // Special case where interview date is saved in the prior modal and passed down as props.
        // In this case, use props.interviewTime instead of application.interview.when.
        if (field === 'when' && this.props.interviewTime) {
          obj = this.props.interviewTime;
        } else {
          // In normal cases, move down nested object until you reach the desired field.
          obj = obj[field];
        }
      } else {
        return '';
      }
    }
    // if the variable evaluates to a date, make it human readable.
    if (obj instanceof Date) {
      obj = moment.utc(obj).format('dddd, MMMM Do YYYY, h:mm a');
    }
    // defensive
    if (!obj) {
      obj = '';
    }
    return obj;
  };

  /**
   * Calls dynamicSub on the parts of text that need updating
   * @param text e.g. Hello from {Sender district}"
   * @return text e.g. Hello from ABC District
   */
  processText = text => {
    text = dedent(text);
    text = text.replace(/\{.*?\}/g, str => this.dynamicSub(str));
    return text;
  };

  updateEmailTemplate = templateId => {
    this.props.removeCheckmark();

    if (!templateId) {
      return this.setState({
        customSubject: '',
        customMessage: '',
        emailTemplate: {},
      });
    }

    let customSubject = '';
    let customMessage = '';
    const template = this.props.emailTemplatesList.find(form => form.id === templateId);
    if (template) {
      customSubject = this.processText(template.subject);
      customMessage = this.processText(template.content);
      this.setState({
        customSubject,
        customMessage,
        emailTemplate: template,
        error: '',
      });
    }
  };

  handleReferenceSelection = referenceId => {
    this.props.removeCheckmark();

    if (!referenceId) {
      return this.setState({
        reference: this.freshReference(),
      });
    }

    const reference = this.props.references.find(reference => reference.id === referenceId);
    if (reference) {
      this.setState({ reference, error: '' }, () => {
        // **** change evaluated variables here too in case emailTemplate is selected ****
        this.updateEmailTemplate(this.state.emailTemplate.id);
      });
    }
  };

  render() {
    return (
      <>
        <ModalBody>
          <BackButtonContainer onClick={this.props.onBack}>
            <BackButtonIcon />
            Return to scorecard summary
          </BackButtonContainer>
          <HeaderText>Select reference</HeaderText>
          <DropdownWithInputFilter
            options={this.props.references.map(reference => ({
              value: reference.id,
              label: `${
                salutationReadable[reference.salutation]
                  ? salutationReadable[reference.salutation] + ' '
                  : ''
              }${reference.name}`,
            }))}
            value={this.state.reference?.id ?? ''}
            handleChange={this.handleReferenceSelection}
            placeholder="Type or select reference"
            boxShadow={false}
            onClear={this.handleReferenceSelection}
          />
          <HeaderText>Select reference form</HeaderText>
          <DropdownWithInputFilter
            options={this.props.referenceTemplates.map(form => ({
              value: form.id,
              label: form.title,
            }))}
            value={this.state.referenceTemplateSelected?.id ?? ''}
            handleChange={this.updateReferenceTemplateSelection}
            placeholder="Type or select reference form"
            boxShadow={false}
            onClear={this.updateReferenceTemplateSelection}
          />
          <HeaderText>Select email template</HeaderText>
          <DropdownWithInputFilter
            options={this.props.emailTemplatesList
              .filter(e => e.reference_check)
              .map(form => ({
                value: form.id,
                label: form.title,
              }))}
            value={this.state.emailTemplate?.id ?? ''}
            handleChange={this.updateEmailTemplate}
            placeholder="Type or select email template"
            boxShadow={false}
            onClear={this.updateEmailTemplate}
          />
          <Input
            placeholder="Email subject"
            width={1}
            value={this.state.customSubject}
            onChange={e =>
              this.setState({
                customSubject: e.target.value,
                error: '',
              })
            }
            marginTop="1rem"
            marginBottom="1rem"
          />
          <ReactQuillStyled
            placeholder="Email message"
            value={this.state.customMessage}
            onChange={value =>
              this.setState({
                customMessage: value,
                error: '',
              })
            }
          />
          <Subtext>
            A link to complete the reference check will be included at the bottom of your email.
          </Subtext>
        </ModalBody>
        <FooterContainer gridTemplateColumns={['1fr', '1fr', '126px 464px']}>
          <CancelButton onClick={this.props.onBack}>Cancel</CancelButton>
          <SubmitContainer gridTemplateColumns={['1fr', '1fr', '270px 186px']}>
            <ErrorText justifySelf={['start', 'start', 'end']}>{this.state.error}</ErrorText>
            <SaveButton
              onClick={this.requestReference}
              disabled={this.props.requestSendingSpinner || this.props.requestSuccessful}
            >
              {this.props.requestSendingSpinner || this.props.requestSuccessful ? (
                <>
                  <WhiteCheckmark width={'19px'} height={'14px'} />
                  &nbsp;&nbsp;Sent!
                </>
              ) : (
                'Send Request'
              )}
            </SaveButton>
          </SubmitContainer>
        </FooterContainer>
      </>
    );
  }
}

const ModalBody = styled(AltModal.Body)`
  position: relative;
`;

const BackButtonContainer = styled.div`
  color: var(--darkgray) !important;
  font-size: 16px !important;
  cursor: pointer;
  display: flex;
  align-items: center;

  width: fit-content;
  position: absolute;
  top: 20px;
`;

const BackButtonIcon = styled(GrayArrow)`
  transform: rotate(180deg);
  margin-right: 7px;
`;

const HeaderText = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #444444;

  margin-bottom: 14px;
  margin-top: 30px;
`;

const Subtext = styled.p`
  font-size: 14px;
  line-height: 19px;
  margin-top: 6px;

  color: #999999;
`;

const FooterContainer = styled(AltModal.Actions)(
  {
    display: 'grid',
    gridGap: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',

    fontSize: '16px',
  },
  grid
);

const FooterButton = styled.button({
  height: '50px',
  width: '100%',
  borderRadius: '3px',
});

const CancelButton = styled(FooterButton)({
  backgroundColor: '#FFFFFF',
  border: '1px solid #CACACA',
  color: '#CACACA',
});

const SaveButton = styled(FooterButton)({
  backgroundColor: '#00B88D',
  border: '1px solid #00B88D',
  color: '#FFFFFF',
  fontWeight: 'bold',
});

const SubmitContainer = styled.div(
  {
    display: 'grid',
    gridGap: '8px',
    alignItems: 'center',
  },
  grid
);

const ErrorText = styled.p(
  {
    color: '#ff7700',
    fontSize: '0.85em',
  },
  flexbox
);

const ReactQuillStyled = styled(ReactQuill)`
  border: 0.5px solid #d7d7d7;
  border-radius: 3px;

  > div.ql-toolbar.ql-snow {
    border-radius: 3px 3px 0 0;
  }

  > div.ql-container.ql-snow {
    border-radius: 0 0 3px 3px;

    > .ql-editor.ql-blank::before {
      color: rgba(57, 60, 73, 0.3);
    }
  }
`;
