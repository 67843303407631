import { Chip } from 'sharedComponents/Chip';
import { ConnectBadgeLabels } from 'features/MarketplaceV2/utils/connectEnums';

import { nimbleTheme } from 'theme';

export const RecommendedBadge: React.FC = () => {
  return (
    <Chip
      label={`● ${ConnectBadgeLabels.RECOMMENDED}`}
      variant="filled"
      sx={{
        color: nimbleTheme.palette.purple.accents.purple40,
        fontWeight: nimbleTheme.typography.fontWeightMedium,
        marginLeft: nimbleTheme.spacing(1),
        border: `1px solid ${nimbleTheme.palette.purple.accents.purple20}`,
        background: nimbleTheme.palette.purple.accents.purple10,
      }}
    />
  );
};
