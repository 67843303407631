import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { SxProps } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export interface MultiSelectWithCheckmarksProps {
  options: string[];
  placeholder: string;
  sx?: SxProps;
  selectedOptions: string[];
  setSelectedOptions: (options: string[]) => void;
}

export const MultiSelectWithCheckmarks: React.FC<MultiSelectWithCheckmarksProps> = ({
  options,
  sx,
  selectedOptions,
  setSelectedOptions,
  placeholder,
}) => {
  const handleChange = (event: SelectChangeEvent<typeof selectedOptions>) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <>
      <FormControl sx={{ m: 1, width: 300 }}>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          displayEmpty
          sx={sx}
          multiple
          value={selectedOptions}
          onChange={handleChange}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
          renderValue={selected => {
            if (selected.length === 0 || selected.length === options.length) {
              return <b data-testid="multiselect-placeholder">{placeholder}</b>;
            }

            return selected.join(', ');
          }}
        >
          {options.map(option => (
            <MenuItem
              key={option}
              value={option}
              data-testid={`multiselect-menu-item-${option.toLowerCase()}`}
              role="menuitemcheckbox"
            >
              <Checkbox
                data-testid="multiselect-checkbox"
                checked={selectedOptions.indexOf(option) > -1}
              />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
