import { StrictMode, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  ChoiceDropdown,
  useChoiceDropdown,
} from '../PipelineMetricsDashboard/components/ChoiceDropdown';
import { DashboardHeaderContainer } from './components/DashboardHeaderContainer.jsx';
import { HIRING_SEASON_CHOICES } from '../PipelineMetricsDashboard/constants';
import { setQueryStringValue, getQueryStringValue } from '../../utils/util';
import stateDashboardMetricsAPI from 'api/stateDashboardMetricsAPI';
import districtsAPI from 'api/districtsAPI';
import axios from 'axios';

export const DataDashboardContainer = ({
  title,
  stateCode,
  setDistrictsSelected,
  setHiringSeasonsSelected,
}) => {
  const [applicationCount, setApplicationCount] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);

  const CHOICE_DROPDOWN_HEIGHT = '56px';

  const initialSelectedHiringSeasons =
    getQueryStringValue('f_HS')
      ?.split(',')
      .map(o => parseInt(o, 10))
      .filter(o => !Number.isNaN(o)) || [];
  const initialSelectedDistricts =
    getQueryStringValue('f_D')
      ?.split(',')
      .map(o => parseInt(o, 10))
      .filter(o => !Number.isNaN(o)) || [];

  const districtDropdownChoices = districtOptions;
  const hiringSeasonDropdownState = useChoiceDropdown({
    choices: HIRING_SEASON_CHOICES,
    initialSelectedChoiceIds: initialSelectedHiringSeasons,
  });

  const districtDropdownState = useChoiceDropdown({
    choices: districtDropdownChoices,
    initialSelectedChoiceIds: initialSelectedDistricts,
  });

  useEffect(() => {
    districtsAPI.fetchDistrictsByState(stateCode).then(districts => {
      setDistrictOptions(districts.map(d => ({ id: d.id, value: d.id, label: d.name })));
    });
  }, [stateCode]);

  useEffect(() => {
    stateDashboardMetricsAPI
      .getApplicationCount(
        hiringSeasonDropdownState.selectedChoiceIds,
        districtDropdownState.selectedChoiceIds,
        stateCode
      )
      .then(response => {
        setApplicationCount(response.total);
        setQueryStringValue('districts', districtDropdownState.selectedChoiceIds);
        setDistrictsSelected(districtDropdownState.selectedChoiceIds);
        setQueryStringValue('hiring_seasons', hiringSeasonDropdownState.selectedChoiceIds);
        setHiringSeasonsSelected(hiringSeasonDropdownState.selectedChoiceIds);
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          return console.log('request cancelled');
        } else if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          window.location.href = '/login';
        }
      });
  }, [
    stateCode,
    districtDropdownState,
    hiringSeasonDropdownState,
    setDistrictsSelected,
    setHiringSeasonsSelected,
  ]);

  const getDistrictCount = () => {
    if (districtDropdownState.selectedChoiceIds.length > 0) {
      return districtDropdownState.selectedChoiceIds.length;
    } else {
      return districtDropdownChoices.length;
    }
  };

  return (
    <StrictMode>
      <DashboardContainer>
        <DashboardHeaderContainer
          title={title}
          applicationCount={applicationCount}
          districtCount={getDistrictCount()}
          stateCode={stateCode}
        />
        <DashboardFilters>
          <ChoiceDropdown
            label="Hiring Season"
            choices={HIRING_SEASON_CHOICES}
            {...hiringSeasonDropdownState}
            height={CHOICE_DROPDOWN_HEIGHT}
            searchBarPlaceholder="Search hiring season"
          />
          <ChoiceDropdown
            label="Organization"
            choices={districtDropdownChoices}
            {...districtDropdownState}
            height={CHOICE_DROPDOWN_HEIGHT}
            searchBarPlaceholder="Search organization"
          />
        </DashboardFilters>
      </DashboardContainer>
    </StrictMode>
  );
};

export const DashboardContainer = styled.main`
  max-width: 1160px;
  margin: 20px auto 40px;
  padding: 0 30px;

  font-size: 15px;

  // Reduce side padding for small screens
  @media screen and (max-width: 768px) {
    padding: 0 10px;
  }
`;

const DashboardFilters = styled.div`
  margin-top: 29px;
  margin-bottom: 14px;

  display: grid;
  grid-template-columns: 24% 25% 25% 25%;

  & > *:not(:last-child) {
    margin-right: 18px;
  }

  // Collapse dropdowns into a column for small screens.
  @media screen and (max-width: 768px) {
    grid-template-columns: 100%;

    & > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 14px;
    }
  }
`;
