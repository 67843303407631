import useSWR from 'swr';

import dashboardMetricsAPI from 'api/dashboardMetricsAPI';

const ENDPOINT = '/api/dashboard-metrics/applicant-diversity';

const defaultData = {
  applicants_by_gender: [],
  applicants_by_ethnicity: [],
  total: 0
};

/**
 * Data management wrapper for fetching/caching applicant diversity metrics.
 *
 * Usage: const { data, isLoading, error } = useApplicantDiversityMetrics(filters);
 */
export const useApplicantDiversityMetrics = ({
  hiringSeasons,
  schools = [],
  subcategories = [],
  statuses = []
}) => {
  // Using ref/memo doesn't work for this library... need to stringify our deps.
  const key = [
    ENDPOINT,
    hiringSeasons.toString(),
    schools.toString(),
    subcategories.toString(),
    statuses.toString()
  ];
  const options = { dedupingInterval: 30000, focusThrottleInterval: 30000 };

  const { data, error } = useSWR(
    key,
    () =>
      dashboardMetricsAPI.getApplicantDiversityMetrics({
        hiringSeasons,
        schools,
        subcategories,
        statuses
      }),
    options
  );

  const isLoading = data === undefined;

  return { data: data ?? defaultData, isLoading, error };
};
